import React from "react";
import { Link } from "react-router-dom"
import moment from "moment";
import { getCurrencyFormat } from "../../../common/data/currencyFormat";


const Id = (cell) => {
  return cell.value ? cell.value : '';
};

const LinkCell = (cell) => {
  return (
    <Link className="text-dark fw-medium" to="#">{cell.value}</Link>
  );
};

const Date = (cell) => {
  return cell.value ? moment(cell.value).format("MMM DD, YYYY") : '';
};

const CaseTypeName = (cell) => {
  return cell.value;
};

const CommonCell = (cell) => {
  return cell.value ? cell.value : '';
};

const Amount = (cell) => {
  return getCurrencyFormat(cell.value)
};


export {
  CommonCell,
  Date,
  CaseTypeName,
  Amount
};