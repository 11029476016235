import {
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,
  GET_INVOICE_DETAIL_SUCCESS,
  GET_INVOICE_DETAIL_FAIL,
  GET_INVOICE_SETTINGS_SUCCESS,
  GET_INVOICE_SETTINGS_FAIL,
  GET_CREATE_INVOICE_SETTINGS_SUCCESS,
  GET_CREATE_INVOICE_SETTINGS_FAIL,
  GET_QUICKBOOK_CALLBACK_SUCCESS,
  GET_QUICKBOOK_CALLBACK_FAIL,
  GET_QUICKBOOK_SYNC_INVOICE_SUCCESS,
  GET_QUICKBOOK_SYNC_INVOICE_FAIL,
  GET_QUICKBOOK_REFRESH_SUCCESS,
  GET_QUICKBOOK_REFRESH_FAIL,
  CONNECT_QUICKBOOKS_SUCCESS,
  CONNECT_QUICKBOOKS_FAIL,
  UPDATE_INVOICE_DETAIL_FAIL,
  UPDATE_INVOICE_DETAIL_SUCCESS,
  UPDATE_INVOICE_DETAIL,
  DELETE_INVOICE_DETAIL_FAIL,
  DELETE_INVOICE_DETAIL_SUCCESS,
  DELETE_INVOICE_DETAIL,
  ADD_INVOICE_DETAIL_SUCCESS,
  ADD_INVOICE_DETAIL_FAIL,
  UPLOAD_INVOICE_ATTACHMENT_SUCCESS,
  UPLOAD_INVOICE_ATTACHMENT_FAIL,
  SEND_INVOICE_SUCCESS,
  SEND_INVOICE_FAIL,
  RECORD_PAYMENT_SUCCESS,
  RECORD_PAYMENT_FAIL,
  UPDATE_INVOICE_PDF_SUCCESS,
  UPDATE_INVOICE_PDF_FAIL,
  GET_INVOICE_TIMESHEET_DETAIL_SUCCESS,
  GET_INVOICE_TIMESHEET_DETAIL_FAIL,
  UPDATE_INVOICE_SETTINGS_SUCCESS,
  UPDATE_INVOICE_SETTINGS_FAIL,
  GET_QUICKBOOK_STATUS_SUCCESS,
  GET_QUICKBOOK_STATUS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  invoices: [],
  invoiceDetail: {},
  createInvoiceDetail: {},
  updateinvoiceDetail:{},
  invoicesettings: {},
  createinvoicesettings: {},
  quickbookcallbackstatus: false,
  quickbooksyncstatus: false,
  quickbookrefreshstatus: false,
  updateinvoicesettings: {},
  connectQuickbookURL: '',
  uploadedinvoiceattachments: {},
  sendInvoiceDetail: {},
  recordPaymentDetail: {},
  updateinvoicepdf: {},
  error: {},
  quickbookerror: {},
  invoiceTimesheetDetails: [],
  quickbookstatus:{},
}

const Invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUICKBOOK_STATUS_SUCCESS:
      return {
        ...state,
        quickbookstatus: action.payload,
      }

    case GET_QUICKBOOK_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_QUICKBOOK_REFRESH_SUCCESS:
      return {
        ...state,
        quickbookrefreshstatus: action.payload,
      }

    case GET_QUICKBOOK_REFRESH_FAIL:
      return {
        ...state,
        quickbookrefresherror: action.payload,
      }
    case GET_QUICKBOOK_SYNC_INVOICE_SUCCESS:
      return {
        ...state,
        quickbooksyncstatus: action.payload,
      }

    case GET_QUICKBOOK_SYNC_INVOICE_FAIL:
      return {
        ...state,
        quickbooksyncstatuserror: action.payload,
      }
    case GET_QUICKBOOK_CALLBACK_SUCCESS:
      return {
        ...state,
        quickbookcallbackstatus: action.payload,
      }

    case GET_QUICKBOOK_CALLBACK_FAIL:
      return {
        ...state,
        quickbookerror: action.payload,
      }

    case GET_CREATE_INVOICE_SETTINGS_SUCCESS:
      return {
        ...state,
        createinvoicesettings: action.payload,
      }

    case GET_CREATE_INVOICE_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_INVOICE_SETTINGS_SUCCESS:
      return {
        ...state,
        invoicesettings: action.payload,
      }

    case GET_INVOICE_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_INVOICE_SETTINGS_SUCCESS:
      return {
        ...state,
        updateinvoicesettings: action.payload,
      }

    case UPDATE_INVOICE_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CONNECT_QUICKBOOKS_SUCCESS:
      return {
        ...state,
        connectQuickbookURL: action.payload,
      }

    case CONNECT_QUICKBOOKS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
      }

    case GET_INVOICES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        invoiceDetail: action.payload,
      }

    case GET_INVOICE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        createInvoiceDetail: action.payload,
      }

    case ADD_INVOICE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case SEND_INVOICE_SUCCESS:
      return {
        ...state,
        sendInvoiceDetail: action.payload,
      }

    case SEND_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RECORD_PAYMENT_SUCCESS:
      return {
        ...state,
        recordPaymentDetail: action.payload,
      }

    case RECORD_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_INVOICE_TIMESHEET_DETAIL_SUCCESS:
      return {
        ...state,
        invoiceTimesheetDetails: action.payload,
      }

    case GET_INVOICE_TIMESHEET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_INVOICE_PDF_SUCCESS:
      return {
        ...state,
        updateinvoicepdf: action.payload,
      }

    case UPDATE_INVOICE_PDF_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPLOAD_INVOICE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        uploadedinvoiceattachments: action.payload,
      }

    case UPLOAD_INVOICE_ATTACHMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        invoices: state.Invoices.filter((item) => item.id !== action.payload),
      };

    case DELETE_INVOICE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        updateinvoiceDetail: action.payload,
      };

    case UPDATE_INVOICE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state
  }
}

export default Invoices
