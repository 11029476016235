/* ATTORNEYS */
export const GET_ATTORNEYS = "GET_ATTORNEYS"
export const GET_ATTORNEYS_SUCCESS = "GET_ATTORNEYS_SUCCESS"
export const GET_ATTORNEYS_FAIL = "GET_ATTORNEYS_FAIL"

export const GET_ATTORNEY_DETAIL = "GET_ATTORNEY_DETAIL"
export const GET_ATTORNEY_DETAIL_SUCCESS = "GET_ATTORNEY_DETAIL_SUCCESS"
export const GET_ATTORNEY_DETAIL_FAIL = "GET_ATTORNEY_DETAIL_FAIL"

export const ADD_ATTORNEY_DETAIL = "ADD_ATTORNEY_DETAIL"
export const ADD_ATTORNEY_DETAIL_SUCCESS = "ADD_ATTORNEY_DETAIL_SUCCESS"
export const ADD_ATTORNEY_DETAIL_FAIL = "ADD_ATTORNEY_DETAIL_FAIL"

export const DELETE_ATTORNEY_DETAIL = "DELETE_ATTORNEY_DETAIL"
export const DELETE_ATTORNEY_DETAIL_SUCCESS = "DELETE_ATTORNEY_DETAIL_SUCCESS"
export const DELETE_ATTORNEY_DETAIL_FAIL = "DELETE_ATTORNEY_DETAIL_FAIL"

export const UPDATE_ATTORNEY_DETAIL = "UPDATE_ATTORNEY_DETAIL"
export const UPDATE_ATTORNEY_DETAIL_SUCCESS = "UPDATE_ATTORNEY_DETAIL_SUCCESS"
export const UPDATE_ATTORNEY_DETAIL_FAIL = "UPDATE_ATTORNEY_DETAIL_FAIL"
