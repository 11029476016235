/* QNNRTYPES */
export const GET_EMPLOYERS = "GET_EMPLOYERS"
export const GET_EMPLOYERS_SUCCESS = "GET_EMPLOYERS_SUCCESS"
export const GET_EMPLOYERS_FAIL = "GET_EMPLOYERS_FAIL"

export const GET_EMPLOYER_DETAILS = "GET_EMPLOYER_DETAILS"
export const GET_EMPLOYER_DETAILS_SUCCESS = "GET_EMPLOYER_DETAILS_SUCCESS"
export const GET_EMPLOYER_DETAILS_FAIL = "GET_EMPLOYER_DETAILS_FAIL"

export const UPDATE_EMPLOYER_DETAILS = "UPDATE_EMPLOYER_DETAILS"
export const UPDATE_EMPLOYER_DETAILS_SUCCESS = "UPDATE_EMPLOYER_DETAILS_SUCCESS"
export const UPDATE_EMPLOYER_DETAILS_FAIL = "UPDATE_EMPLOYER_DETAILS_FAIL"
  
