import classnames from 'classnames';
import React, { useEffect, useState } from "react";
import {
    Link, useNavigate
} from "react-router-dom";
import {
    Card, CardBody,
    Col,
    Container,
    NavItem, NavLink,
    Row,
    TabContent, TabPane,
    Toast, ToastBody, UncontrolledTooltip
} from "reactstrap";
import "../../../../assets/scss/pages/_questionnaire.scss";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import CasePartsGeneration from "../../../../components/Common/DynamicForm/Form/CasePartsGeneration";
import { checkValidation, getCurrentProgressValue } from "../../../../components/Common/DynamicForm/Shared/utility";
import { generateForm, generateFormDetail, saveCaseFormTemplateData } from "../../../../store/actions";

import { getDateFormatYMDMDY } from "../../../../common/data/dateFormat";
//redux
import { useDispatch, useSelector } from "react-redux";
import { changePreloader } from "../../../../store/actions";

const ViewForm = () => {

    //meta title
    document.title = "Cases | Immidock ";
    var userType = window.localStorage.getItem("user_type")

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const case_id = queryParams.get('case_id'); // Read the 'id' query parameter from the URL
    const case_form_id = queryParams.get('case_form_id'); // Read the 'id' query parameter from the URL
    const master_form_id = queryParams.get('form_id'); // Read the 'id' query parameter from the URL
    const caseNumber = queryParams.get('caseNumber'); // Read the 'id' query parameter from the URL
    const [savePrintApiSuccess, setSavePrintApiSuccess] = useState(false);
    const [mainPageDescription, setMainPageDescription] = useState("Please Complete Application tab basic information");


    const { generateform } = useSelector((state) => ({
        generateform: state.AttorneyCases.generateform || [],
    }));
    const { generateformDetail, caseformtemplatedata } = useSelector((state) => ({
        generateformDetail: state.AttorneyCases.generateformDetail || [],
        caseformtemplatedata: state.AttorneyCases.caseformtemplatedata || {},
    }));
    useEffect(() => {
        if (Object.keys(caseformtemplatedata).length > 0 && savePrintApiSuccess) {
            if (caseformtemplatedata?.pdf_url) {
                setSavePrintApiSuccess(false);
                window.open(caseformtemplatedata.pdf_url, '_blank', 'noreferrer')
            }
        }
    }, [caseformtemplatedata]);

    // const [formaction, setFormAction] = useState("save");
    // useEffect(() => {
    //  console.log(formaction)
    // }, [formaction]);
    const [controls, setControls] = useState({});
    const [attributeSetDependency, setAttributeSetDependency] = useState([]);
    const checkAttributeSetDependency = (sets) => {
        let isDependent_block_check_status = sets?.value?.is_dependent_set == "1" ? false : true;
        attributeSetDependency && attributeSetDependency.every(function (dept, deptincx) {
            isDependent_block_check_status = sets?.value?.is_dependent_set == "1" &&
                dept && dept.includes(sets?.value?.slug)
                ? true
                : sets?.value?.is_dependent_set == "1"
                    ? false
                    : true;
            // console.log(isDependent_block_check_status, "isDependent_block_check_status", sets?.value?.slug, "=Selected=", dept, "==dept==", dept.includes(sets?.value?.slug))

            if (isDependent_block_check_status) {
                return false;
            } else return true;
        });
        return isDependent_block_check_status;
    }
    const allAttributeSets = (updatedControls) => {
        setAttributeSetDependency([])

        for (const updatedControldata in updatedControls) {
            if (updatedControls[updatedControldata] && updatedControls[updatedControldata].sets) {
                for (const set of updatedControls[updatedControldata].sets) {
                    var isDependent_block_check = checkAttributeSetDependency(set);
                    if (isDependent_block_check) {
                        if (set?.attributes)
                            set.attributes.map((attribute, attributeindex) => {
                                if (attribute?.content?.is_any_dependent_yes == true) {
                                    if (attribute?.content?.dependent_ids_yes && attribute?.value) {
                                        var attribute_dependent_set_value = attribute?.content?.dependent_ids_yes.replace(/'/g, '"')
                                        attribute_dependent_set_value = JSON.parse(attribute_dependent_set_value);
                                        if (attribute_dependent_set_value[attribute?.value]) {
                                            setAttributeSetDependency(attributeSetDependency => [...attributeSetDependency, attribute_dependent_set_value[attribute?.value]]);
                                        }
                                    }
                                }
                            })
                    }
                }
            }
        }

        // updatedControls.map((asdf, setindex) => {
        //     if (asdf.sets && asdf.sets.length > 0) {
        //         asdf.sets.map((set, setindex) => {


        //         });
        //     }
        // })
    }


    useEffect(() => {
        if (!_.isEmpty(controls)) {
            allAttributeSets(controls);
        }
    }, [controls]);


    useEffect(() => {
        if (!_.isEmpty(generateformDetail)) {
            setControls(generateformDetail);
        }
    }, [generateformDetail]);


    const [checkBoxrequired, setCheckBoxrequired] = useState(false);
    const [toggleTabs, settoggleTabs] = useState({
        activeTabProgress: 1,
        progressValue: 10,
        validationError: '',
        toast: false
    });

    const groupsTabLength = Object.keys(generateform).length ? generateform.tab.length : "";
    const qnnrProgress = Object.keys(generateformDetail).length && generateformDetail.tab?.qnnrProgress;
    useEffect(() => {
        dispatch(changePreloader(true));
        dispatch(generateForm({
            case_id: case_id,
            case_form_id: case_form_id,
        }));
        settoggleTabs({ ...toggleTabs, progressValue: getCurrentProgressValue(1 * 100 / groupsTabLength, qnnrProgress) })
    }, [dispatch]);

    const toggleTabProgress = (tab, formaction) => {
        const groupInfo = generateform.tab.find(group => group.id === tab);
        let isValidForm = true;
        const updatedControls = { ...controls };
        for (const updatedControldata in updatedControls) {
            if (controls?.groupId == "application") {
                if (updatedControls[updatedControldata] && updatedControls[updatedControldata].sets) { //if (parts.sets.hasOwnProperty(key)) {
                    for (const set of updatedControls[updatedControldata].sets) {
                        var isDependent_block_check = checkAttributeSetDependency(set);
                        if (isDependent_block_check) {
                            for (const attribute of set.attributes) {
                                const validationObj = checkValidation(attribute?.value, attribute?.content, attribute?.content?.label);
                                attribute.isValid = validationObj.isValid;
                                attribute.invalidMessage = validationObj.invalidMessage;
                                attribute.selectedOption = false;
                                if (!validationObj.isValid) {
                                    isValidForm = false;
                                }
                            }
                        }
                    }
                }
            }
            setControls(updatedControls);
        }
        // if (formaction == "saveAndPrint" && !checkBoxrequired) 
        if (isValidForm && !checkBoxrequired && typeof formaction != "undefined" && updatedControls?.groupId == 'main') {
            alert('Please accept the checkbox to confirm the changes')
        } else {
            dispatch(changePreloader(true));

            if (toggleTabs.activeTabProgress !== tab && isValidForm == true) {
                //if (isValidForm == true) {
                settoggleTabs({ ...toggleTabs, activeTabProgress: tab, progressValue: getCurrentProgressValue(tab * 100 / groupsTabLength, updatedControls?.qnnrProgress), validationError: '', toast: false })
                //Pre Form post data

                // if (updatedControls.sets && updatedControls.sets.length > 0) {
                let postData = {
                    "action": formaction,
                    "case_id": case_id,
                    "tab": updatedControls?.groupId,
                    "form_group": generateformDetail?.formInfo?.form_group,
                    case_form_id: case_form_id,
                    // "qnnr_progress": (tab - 1) * 100 / groupsTabLength,
                }
                let contentObj = {};
                for (const updatedControldata in updatedControls) {
                    if (updatedControls[updatedControldata] && updatedControls[updatedControldata].sets) { //if (parts.sets.hasOwnProperty(key)) {
                        for (const set of updatedControls[updatedControldata].sets) {
                            for (const attribute of set.attributes) {
                                let name = attribute?.content?.name;
                                let type = attribute?.type;
                                name = name ? name.split('#')[0] : name;
                                contentObj[name] = attribute?.value
                                if (type == "date") {
                                    var dateValue = attribute?.selected ?? attribute?.value;
                                    contentObj[name] = (dateValue) ? getDateFormatYMDMDY(dateValue) : "";
                                }
                            }
                        }
                    }
                }
                if (!_.isEmpty(contentObj) && typeof formaction != "undefined") {
                    postData = { ...postData, content: JSON.parse(JSON.stringify(contentObj)) };
                    setMainPageDescription("Preparing Case Form ...")
                    dispatch(saveCaseFormTemplateData(postData));
                }
                setTimeout(() => {
                    setCheckBoxrequired(false);
                    dispatch(generateFormDetail({
                        case_id: case_id,
                        case_form_id: case_form_id,
                        "tab": groupInfo?.value ? groupInfo?.value : '',
                        "master_form_id": master_form_id,
                    }));
                }, 1000);
            }
            else if (toggleTabs.activeTabProgress === tab && isValidForm == true) {
                let postData = {
                    "action": formaction,
                    "case_id": case_id,
                    "tab": generateformDetail?.groupId,
                    "form_group": generateformDetail?.formInfo?.form_group,
                    case_form_id: case_form_id,
                }
                let contentObj = {};
                for (const updatedControldata in updatedControls) {
                    if (updatedControls[updatedControldata] && updatedControls[updatedControldata].sets) { //if (parts.sets.hasOwnProperty(key)) {
                        for (const set of updatedControls[updatedControldata].sets) {
                            for (const attribute of set.attributes) {
                                let name = attribute?.content?.name;
                                let type = attribute?.type;
                                name = name ? name.split('#')[0] : name;
                                contentObj[name] = attribute?.value
                                if (type == "date") {
                                    var dateValue = attribute?.selected ?? attribute?.value;
                                    contentObj[name] = (dateValue) ? getDateFormatYMDMDY(dateValue) : "";
                                }
                            }
                        }
                    }
                }
                if (!_.isEmpty(contentObj) && typeof formaction != "undefined") {
                    postData = { ...postData, content: JSON.parse(JSON.stringify(contentObj)) };
                    //console.log(postData)
                    dispatch(saveCaseFormTemplateData(postData));
                    setSavePrintApiSuccess(true);
                    setMainPageDescription("Preparing Case Form ...")
                    if (updatedControls?.groupName && (updatedControls.groupName).toLowerCase() == "main") {
                        if (formaction != 'saveAndPrint') {
                            setCheckBoxrequired(false);
                            // setTimeout(() => {
                            //     navigate(`/${userType}/view-case?activetab=forms&caseId=${case_id}`, { replace: true });
                            // }, 1000);
                        }
                    }
                }

            }
            else {
                settoggleTabs({ ...toggleTabs, validationError: 'Please fill required fields', toast: true })
            }

        }
    };

    const setToast = () => {
        settoggleTabs({ ...toggleTabs, toast: false, validationError: false })
    }
    const title = "View Form";
    const btitle = " ";
    const BreadcrumbData = [
        { name: 'Cases', url: '/attorney/Cases' },
        { name: 'View Case', url: '/attorney/view-case?caseId=' + case_id },
        { name: title, url: '#' }
    ]
    return (
        <React.Fragment>
            <div className="page-content case_view_form">
                <Container>
                    <Col lg={12}>

                        <Breadcrumbs breadcrumbItem={btitle} BreadcrumbData={BreadcrumbData} />
                        <Row>
                            <div className="col-10 offset-1">
                                <div className="align-items-start mt-3 mt-sm-0">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <h5 className="font-size-15 text-truncate">
                                            <span className="text-dark">Form:</span> {generateformDetail?.formInfo?.form_name}
                                        </h5>
                                        <h5 className="font-size-15 text-truncate">
                                            <span className="text-dark">Beneficiary Name:</span> {generateform?.employee_name}
                                        </h5>
                                        <h5 className="font-size-15 text-truncate">
                                            <span className="text-dark">Case Number:</span> <a target="_blank" rel="noreferrer" href={"/attorney/view-case?caseId=" + case_id}>{generateform?.case_number}</a>
                                        </h5>
                                        <h5 className="font-size-15 text-truncate">
                                            <span className="text-dark">Case Type:</span> {generateform?.case_type}
                                        </h5>
                                        <h5 className="font-size-15 text-truncate">
                                            <span className="text-dark">Questionnaire Type:</span> {generateform?.qnnr_type}
                                        </h5>
                                    </div>

                                </div>
                            </div>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody style={{ padding: "0px" }}>
                                        <div className="notify-errors">
                                            <Toast isOpen={toggleTabs.toast} id="borderedToast1" className="toast-border-primary overflow-hidden mt-3">
                                                <ToastBody className='bg-danger text-white'>
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-shrink-0 me-2">
                                                            <i className="ri-user-smile-line align-middle"></i>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h6 className="mb-0 text-white">{toggleTabs.validationError}</h6>
                                                        </div>
                                                        <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                                                            aria-label="Close" onClick={() => setToast()}></button>
                                                    </div>
                                                </ToastBody>
                                            </Toast>
                                        </div>
                                        <div id="progrss-wizard" className="twitter-bs-wizard">
                                            <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills"
                                                style={
                                                    {
                                                        width: "40%",
                                                        margin: "auto"
                                                    }
                                                }>
                                                {groupsTabLength && generateform.tab.map((item) => (
                                                    <NavItem key={item.key}>
                                                        <NavLink className={classnames({ active: toggleTabs.activeTabProgress === item.id })} onClick={() => {
                                                            toggleTabProgress(item.id);
                                                        }} >
                                                            <div className="step-icon" data-bs-toggle="tooltip" id={'Qnnr' + item.id}>
                                                                <i className="bx bx-list-ul"></i>
                                                                <UncontrolledTooltip placement="top" target={'Qnnr' + item.id}>
                                                                    {item.key}
                                                                </UncontrolledTooltip>
                                                            </div>
                                                            <div className="text-dark capitalize">{item.key}</div>
                                                        </NavLink>
                                                    </NavItem>
                                                ))}

                                            </ul>


                                            <TabContent activeTab={toggleTabs.activeTabProgress} className="twitter-bs-wizard-tab-content paddingtopzero">
                                                <TabPane tabId={toggleTabs.activeTabProgress} key={toggleTabs.activeTabProgress}>
                                                    {
                                                        (toggleTabs.activeTabProgress == "1" || (toggleTabs.activeTabProgress == "2" && generateformDetail?.formInfo?.application_tab_status == 1)) ?
                                                            (
                                                                <div className={toggleTabs.activeTabProgress == "2" ? 'form-content col-10 offset-1' : 'form-content col-10 offset-1'} style={{ marginTop: "0px", marginBottom: "0px" }}>
                                                                    {/* {toggleTabs.activeTabProgress == "2" && ( */}
                                                                    <>
                                                                        <table width="100%" border="0" cellSpacing="1">
                                                                            <tbody>
                                                                                {controls?.groupId == "main" && generateformDetail?.formInfo?.omb_number && (
                                                                                    <>                                                                        <tr>
                                                                                        <td width="8%" style={{ backgroundColor: '#fff' }}>&nbsp;</td>
                                                                                        <td width="76%" align="center" style={{ backgroundColor: '#fff' }} className="header"><h4>   </h4>
                                                                                            <h5>{generateformDetail?.formInfo?.head_title} </h5>
                                                                                            <h5>{generateformDetail?.formInfo?.title} </h5>
                                                                                            <h5>{generateformDetail?.formInfo?.sub_title}</h5></td>
                                                                                        <td width="16%" align="center" style={{ backgroundColor: '#fff' }} className="header">
                                                                                            <h6>USCIS</h6>
                                                                                            <h6>Form {generateformDetail?.formInfo?.display_name} </h6>
                                                                                            <h6>OMB No. {generateformDetail?.formInfo?.omb_number}</h6>
                                                                                            <h6>Expires {generateformDetail?.formInfo?.expires}</h6></td>
                                                                                    </tr>

                                                                                        <tr>
                                                                                            <td height="2" colSpan="3"></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td height="8" colSpan="3" style={{ backgroundColor: '#000' }}></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td height="2" colSpan="3"></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td height="2" colSpan="3" style={{ backgroundColor: '#000' }}></td>
                                                                                        </tr>
                                                                                    </>

                                                                                )}
                                                                                {controls?.groupId == "application" &&
                                                                                    (
                                                                                        <>
                                                                                            <tr>
                                                                                                <td width="8%" style={{ backgroundColor: '#fff' }}>&nbsp;</td>
                                                                                                <td width="76%" align="center" style={{ backgroundColor: '#fff' }} className="header">
                                                                                                    <h5>Form {generateformDetail?.formInfo?.display_name} Basic Information</h5>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td height="2" colSpan="3"></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td height="8" colSpan="3" style={{ backgroundColor: '#000' }}></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td height="2" colSpan="3"></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td height="2" colSpan="3" style={{ backgroundColor: '#000' }}></td>
                                                                                            </tr>
                                                                                        </>
                                                                                    )}
                                                                            </tbody>
                                                                        </table>
                                                                    </>
                                                                    {/* )}*/}

                                                                    <form id="cfviewform">
                                                                        <div className="text-left mb-4">
                                                                            <p className="card-title-desc" style={{ color: 'red' }}>{toggleTabs.validationError}</p>
                                                                        </div>

                                                                        {<CasePartsGeneration
                                                                            controls={controls.length ? controls : Object.keys(generateformDetail).length ? generateformDetail : { sets: [{ attributes: [] }] }}
                                                                            setControls={setControls}
                                                                            checkAttributeSetDependency={checkAttributeSetDependency}
                                                                            checkBoxrequired={checkBoxrequired}
                                                                            setCheckBoxrequired={setCheckBoxrequired}
                                                                            activeTabProgress={toggleTabs.activeTabProgress}
                                                                        />}
                                                                        <ul className="pager wizard twitter-bs-wizard-pager-link">

                                                                            <li className={toggleTabs.activeTabProgress == groupsTabLength ? "next mb-4" : "next  mb-4"}>
                                                                                <Link onClick={() => navigate(-1)}
                                                                                    className={toggleTabs.activeTabProgress == groupsTabLength ? "btn btn-secondary" : "btn btn-secondary"}
                                                                                >Cancel

                                                                                </Link>
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <span className="mt-4">
                                                                                    <Link
                                                                                        className={toggleTabs.activeTabProgress == groupsTabLength ? "btn btn-primary" : "btn btn-primary"}
                                                                                        to={window.location.href}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            // setFormAction("save");
                                                                                            let nexttab = toggleTabs.activeTabProgress;
                                                                                            if (toggleTabs.activeTabProgress < groupsTabLength) {
                                                                                                nexttab = toggleTabs.activeTabProgress + 1;
                                                                                            }
                                                                                            toggleTabProgress(nexttab, "save");
                                                                                        }}
                                                                                    >
                                                                                        {controls?.groupId == "application" ? (<>Save & Next <i className='bx bx-chevron-right ms-1'></i></>) : (<>Save</>)}


                                                                                    </Link>

                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                    {controls?.groupId == "main" || groupsTabLength == 0 ? (
                                                                                        <Link
                                                                                            className={toggleTabs.activeTabProgress == groupsTabLength ? "btn btn-primary" : "btn btn-primary"}
                                                                                            to={window.location.href}
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                // setFormAction("saveAndPrint");
                                                                                                let nexttab = toggleTabs.activeTabProgress;
                                                                                                if (toggleTabs.activeTabProgress < groupsTabLength) {
                                                                                                    nexttab = toggleTabs.activeTabProgress + 1;
                                                                                                }
                                                                                                toggleTabProgress(nexttab, "saveAndPrint");
                                                                                            }}
                                                                                        >
                                                                                            <>Save & Print</>

                                                                                        </Link>) : ""}
                                                                                </span>
                                                                            </li>

                                                                            {/* <li className={toggleTabs.activeTabProgress === 1 ? "previous disabled" : "previous"}>
    <Link
        className={toggleTabs.activeTabProgress === 1 ? "btn btn-primary disabled" : "btn btn-primary"}
        to={window.location.href}
        onClick={() => {
            toggleTabProgress(toggleTabs.activeTabProgress - 1);
        }}
    >
        <i className="bx bx-chevron-left me-1"></i> Previous
    </Link>
</li> 

<li className={toggleTabs.activeTabProgress == groupsTabLength ? "next" : "next"}>
    <Link
        className={toggleTabs.activeTabProgress == groupsTabLength ? "btn btn-primary" : "btn btn-primary"}
        to={window.location.href}
        onClick={() => {
            let nexttab = toggleTabs.activeTabProgress;
            if (toggleTabs.activeTabProgress < groupsTabLength) {
                nexttab = toggleTabs.activeTabProgress + 1;
            }
            toggleTabProgress(nexttab);
        }}
    >
        {toggleTabs.activeTabProgress == groupsTabLength || groupsTabLength == 0 ? (<>Save & Print</>) : (
            <>
                Save & Next <i className='bx bx-chevron-right ms-1'></i>
            </>
        )}

    </Link>
</li> */}
                                                                        </ul>
                                                                    </form>

                                                                    {toggleTabs.activeTabProgress == "2" && (
                                                                        <>
                                                                            <table width="100%" className="mt-4" border="0" cellSpacing="0">
                                                                                <tbody><tr>
                                                                                    <td height="2" colSpan="2" style={{ backgroundColor: '#525252' }}></td>
                                                                                </tr>
                                                                                    <tr>
                                                                                        <td><p>Form  {generateformDetail?.formInfo?.display_name} Edition {generateformDetail?.formInfo?.edition}
                                                                                        </p></td>
                                                                                        <td><p style={{ "float": "right" }}>Page 1 of 36</p></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <CardBody>
                                                                    <Row className="mb-2 mt-4">
                                                                        <Col sm="4">
                                                                        </Col>
                                                                        <Col sm="6 font-size-18">
                                                                            {mainPageDescription}
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            )
                                                    }
                                                </TabPane>
                                            </TabContent>


                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default ViewForm;
