import { GET_CASEFORMS_SUCCESS, GET_CASEFORMS_FAIL,
        ADD_CASEFORMS_SUCCESS, ADD_CASEFORMS_FAIL, GET_CASE_FORM, GET_CASE_FORM_SUCCESS, GET_CASE_FORM_FAIL, UPDATE_CASE_FORM_SUCCESS, UPDATE_CASE_FORM_FAIL } from "./actionTypes"

const INIT_STATE = {
  CaseFormsList: [],
  CaseFormsSelectList: [],
  caseformdetail: {},
  updatecaseform: {},
  error: {},
}

const CaseForms = (state = INIT_STATE, action) => { 
  switch (action.type) {
    case GET_CASEFORMS_SUCCESS:
      return {
        ...state,
        CaseFormsList: action.payload,
        CaseFormsSelectList:action.payload.map(k => ({
          value: `${k.form_id}`,
          label: `${k.name}`
        }))
      }

    case GET_CASEFORMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case GET_CASE_FORM_SUCCESS:
        return {
          ...state,
          caseformdetail: action.payload,
        }
  
      case GET_CASE_FORM_FAIL:
        return {
          ...state,
          error: action.payload,
        }

        case UPDATE_CASE_FORM_SUCCESS:
          return {
            ...state,
            updatecaseform: action.payload,
          }
    
        case UPDATE_CASE_FORM_FAIL:
          return {
            ...state,
            error: action.payload,
          }

    case ADD_CASEFORMS_SUCCESS:
      return {
        ...state,
        CaseFormsList: [...state.CaseFormsList, action.payload],
      }

    case ADD_CASEFORMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default CaseForms
