import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_EMPLOYER_ESIGN,
  UPLOAD_EMPLOYER_ESIGN
} from "./actionTypes"
import {
  getEmployerEsignFail,
  getEmployerEsignSuccess,
  uploadEmployerEsignSuccess
} from "./actions"
import { changePreloader } from "../../../store/actions"

//Include Both Helper File with needed methods
import { getEmployerEsignApi, uploadEmployerEsign, uploadEmployerEsignApi } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"

function* fetchEmployerEsign({ payload: user }) {
  try {
    const response = yield call(getEmployerEsignApi, user)
    yield put(getEmployerEsignSuccess(response.data))
  } catch (error) {
    yield put(getEmployerEsignFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* uploadEmployerEsignInfo({ payload: user }) {
  try {
    const response = yield call(uploadEmployerEsignApi, user)
    yield put(uploadEmployerEsignSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}



function* EmployerEsignsaga() {
  yield takeEvery(GET_EMPLOYER_ESIGN, fetchEmployerEsign)
  yield takeEvery(UPLOAD_EMPLOYER_ESIGN, uploadEmployerEsignInfo)
}

export default EmployerEsignsaga
