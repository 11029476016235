import {
  GENERATE_FORM,
  GENERATE_FORM_FAIL,
  GENERATE_FORM_SUCCESS,
  GET_EMPLOYERCASES,
  GET_EMPLOYERCASES_FAIL,
  GET_EMPLOYERCASES_SUCCESS,
  GET_EMPLOYER_PAF,
  GET_EMPLOYER_PAF_SUCCESS,
  GET_EMPLOYER_PAF_FAIL,

  SEND_PAF_EMPLOYEE_NOTIFICATION,
  SEND_PAF_EMPLOYEE_NOTIFICATION_SUCCESS,
  SEND_PAF_EMPLOYEE_NOTIFICATION_FAIL,

  CREATE_PAF_BUNDLE,
  CREATE_PAF_BUNDLE_SUCCESS,
  CREATE_PAF_BUNDLE_FAIL,

  GET_EMPLOYER_PAF_FORM,
  GET_EMPLOYER_PAF_FORM_SUCCESS,
  GET_EMPLOYER_PAF_FORM_FAIL,
  GET_EMPLOYER_PAF_FORMS,
  GET_EMPLOYER_PAF_FORMS_SUCCESS,
  GET_EMPLOYER_PAF_FORMS_FAIL,
  UPDATE_EMPLOYER_PAF_FORM,
  UPDATE_EMPLOYER_PAF_FORM_SUCCESS,
  UPDATE_EMPLOYER_PAF_FORM_FAIL,
  GET_EMPLOYER_PAFINFO,
  GET_EMPLOYER_PAFINFO_SUCCESS,
  GET_EMPLOYER_PAFINFO_FAIL,
  UPDATE_EMPLOYER_PAFINFO,
  UPDATE_EMPLOYER_PAFINFO_SUCCESS,
  UPDATE_EMPLOYER_PAFINFO_FAIL,
  GET_EMPLOYERCASE_DETAIL,
  GET_EMPLOYERCASE_DETAIL_FAIL,
  GET_EMPLOYERCASE_DETAIL_SUCCESS,
  UPDATE_EMPLOYERCASE_DETAIL_FAIL,
  UPDATE_EMPLOYERCASE_DETAIL_SUCCESS,
  UPDATE_EMPLOYERCASE_DETAIL,
  DELETE_EMPLOYERCASE_DETAIL_FAIL,
  DELETE_EMPLOYERCASE_DETAIL_SUCCESS,
  DELETE_EMPLOYERCASE_DETAIL,
  ADD_EMPLOYERCASE_DETAIL_SUCCESS,
  ADD_EMPLOYERCASE_DETAIL,
  ADD_EMPLOYERCASE_DETAIL_FAIL,
  GET_EMPLOYERQNNRTYPES_SUCCESS,
  GET_EMPLOYERCASETYPES,
  GET_EMPLOYERQNNRTYPES_FAIL,
  GET_EMPLOYERQNNRTYPES,
  GET_EMPLOYERCASETYPES_SUCCESS,
  GET_EMPLOYERCASETYPES_FAIL,


  UPDATE_PAF_TEMPLATE,
  UPDATE_PAF_TEMPLATE_SUCCESS,
  UPDATE_PAF_TEMPLATE_FAIL,

  UPLOAD_EMPLOYER_LCA,
  UPLOAD_EMPLOYER_LCA_SUCCESS,
  UPLOAD_EMPLOYER_LCA_FAIL,
  
  GET_PAF_TEMPLATE_VARIABLES_SUCCESS,
  GET_PAF_TEMPLATE_VARIABLES_FAIL,
  GET_PAF_TEMPLATE_VARIABLES,

} from "./actionTypes"

// export const generateForm = (data) => ({
//   type: GENERATE_FORM,
//   payload:data
// })

// export const generateFormSuccess = EmployerCases => ({
//   type: GENERATE_FORM_SUCCESS,
//   payload: EmployerCases,
// })

// export const generateFormFail = error => ({
//   type: GENERATE_FORM_FAIL,
//   payload: error,
// })

export const getEmployerCases = (data) => ({
  type: GET_EMPLOYERCASES,
  payload: data
})

export const getEmployerCasesSuccess = EmployerCases => ({
  type: GET_EMPLOYERCASES_SUCCESS,
  payload: EmployerCases,
})

export const getEmployerCasesFail = error => ({
  type: GET_EMPLOYERCASES_FAIL,
  payload: error,
})

export const getEmployerPaf = (data) => ({
  type: GET_EMPLOYER_PAF,
  payload: data
})

export const getEmployerPafSuccess = EmployerPaf => ({
  type: GET_EMPLOYER_PAF_SUCCESS,
  payload: EmployerPaf,
})

export const getEmployerPafFail = error => ({
  type: GET_EMPLOYER_PAF_FAIL,
  payload: error,
})

export const getEmployerPafInfo = (data) => ({
  type: GET_EMPLOYER_PAFINFO,
  payload: data
})

export const getEmployerPafInfoSuccess = EmployerPaf => ({
  type: GET_EMPLOYER_PAFINFO_SUCCESS,
  payload: EmployerPaf,
})

export const getEmployerPafInfoFail = error => ({
  type: GET_EMPLOYER_PAFINFO_FAIL,
  payload: error,
})

export const updateEmployerPafInfo = (data) => ({
  type: UPDATE_EMPLOYER_PAFINFO,
  payload: data
})

export const updateEmployerPafInfoSuccess = EmployerPaf => ({
  type: UPDATE_EMPLOYER_PAFINFO_SUCCESS,
  payload: EmployerPaf,
})

export const updateEmployerPafInfoFail = error => ({
  type: UPDATE_EMPLOYER_PAFINFO_FAIL,
  payload: error,
})

export const createPafBundle = (data) => ({
  type: CREATE_PAF_BUNDLE,
  payload: data
})

export const createPafBundleSuccess = EmployerPaf => ({
  type: CREATE_PAF_BUNDLE_SUCCESS,
  payload: EmployerPaf,
})

export const createPafBundleFail = error => ({
  type: CREATE_PAF_BUNDLE_FAIL,
  payload: error,
})

export const sendPafEmployeeNotification = (data) => ({
  type: SEND_PAF_EMPLOYEE_NOTIFICATION,
  payload: data
})

export const sendPafEmployeeNotificationSuccess = EmployerPaf => ({
  type: SEND_PAF_EMPLOYEE_NOTIFICATION_SUCCESS,
  payload: EmployerPaf,
})

export const sendPafEmployeeNotificationFail = error => ({
  type: SEND_PAF_EMPLOYEE_NOTIFICATION_FAIL,
  payload: error,
})

export const getEmployerPafForm = (data) => ({
  type: GET_EMPLOYER_PAF_FORM,
  payload: data
})

export const getEmployerPafFormSuccess = EmployerPaf => ({
  type: GET_EMPLOYER_PAF_FORM_SUCCESS,
  payload: EmployerPaf,
})

export const getEmployerPafFormFail = error => ({
  type: GET_EMPLOYER_PAF_FORM_FAIL,
  payload: error,
})

export const getEmployerPafForms = (data) => ({
  type: GET_EMPLOYER_PAF_FORMS,
  payload: data
})

export const getEmployerPafFormsSuccess = EmployerPaf => ({
  type: GET_EMPLOYER_PAF_FORMS_SUCCESS,
  payload: EmployerPaf,
})

export const getEmployerPafFormsFail = error => ({
  type: GET_EMPLOYER_PAF_FORMS_FAIL,
  payload: error,
})

export const updateEmployerPafForm = (data) => ({
  type: UPDATE_EMPLOYER_PAF_FORM,
  payload: data
})

export const updateEmployerPafFormSuccess = EmployerPaf => ({
  type: UPDATE_EMPLOYER_PAF_FORM_SUCCESS,
  payload: EmployerPaf,
})

export const updateEmployerPafFormFail = error => ({
  type: UPDATE_EMPLOYER_PAF_FORM_FAIL,
  payload: error,
})


export const getEmployerCaseDetail = EmployerCaseId => ({
  type: GET_EMPLOYERCASE_DETAIL,
  EmployerCaseId,
})

export const getEmployerCaseDetailSuccess = EmployerCases => ({
  type: GET_EMPLOYERCASE_DETAIL_SUCCESS,
  payload: EmployerCases,
})

export const getEmployerCaseDetailFail = error => ({
  type: GET_EMPLOYERCASE_DETAIL_FAIL,
  payload: error,
})

export const addEmployerCaseDetail = (data) => ({
  type: ADD_EMPLOYERCASE_DETAIL,
  payload: data
})

export const addEmployerCasedetailSuccess = (data) => ({
  type: ADD_EMPLOYERCASE_DETAIL_SUCCESS,
  payload: data
})

export const addEmployerCasedetailFail = (error) => ({
  type: ADD_EMPLOYERCASE_DETAIL_FAIL,
  payload: error
})

export const EmployerCaseDelete = (data) => ({
  type: DELETE_EMPLOYERCASE_DETAIL,
  payload: data
})

export const EmployerCaseDeleteSuccess = (data) => ({
  type: DELETE_EMPLOYERCASE_DETAIL_SUCCESS,
  payload: data
})

export const EmployerCaseDeleteFail = (error) => ({
  type: DELETE_EMPLOYERCASE_DETAIL_FAIL,
  payload: error
})

export const EmployerCaseUpdate = (data) => ({
  type: UPDATE_EMPLOYERCASE_DETAIL,
  payload: data
})

export const EmployerCaseUpdateSuccess = (data) => ({
  type: UPDATE_EMPLOYERCASE_DETAIL_SUCCESS,
  payload: data
})

export const EmployerCaseUpdateFail = (error) => ({
  type: UPDATE_EMPLOYERCASE_DETAIL_FAIL,
  payload: error
})


export const getEmployerCaseTypes = () => ({
  type: GET_EMPLOYERCASETYPES,
})

export const getEmployerCaseTypesSuccess = EmployerCaseTypes => ({
  type: GET_EMPLOYERCASETYPES_SUCCESS,
  payload: EmployerCaseTypes,
})

export const getEmployerCaseTypesFail = error => ({
  type: GET_EMPLOYERCASETYPES_FAIL,
  payload: error,
})


export const getEmployerQnnrtypes = (data) => ({
  type: GET_EMPLOYERQNNRTYPES,
  payload: data
})

export const getEmployerQnnrtypesSuccess = EmployerQnnrtypes => ({
  type: GET_EMPLOYERQNNRTYPES_SUCCESS,
  payload: EmployerQnnrtypes,
})

export const getEmployerQnnrtypesFail = error => ({
  type: GET_EMPLOYERQNNRTYPES_FAIL,
  payload: error,
})

export const updatePafTemplate = (data) => ({
  type: UPDATE_PAF_TEMPLATE,
  payload: data
})

export const updatePafTemplateSuccess = EmployerPaf => ({
  type: UPDATE_PAF_TEMPLATE_SUCCESS,
  payload: EmployerPaf,
})

export const updatePafTemplateFail = error => ({
  type: UPDATE_PAF_TEMPLATE_FAIL,
  payload: error,
})

export const uploadEmployerLca = (data) => ({
  type: UPLOAD_EMPLOYER_LCA,
  payload: data
})

export const uploadEmployerLcaSuccess = EmployerPaf => ({
  type: UPLOAD_EMPLOYER_LCA_SUCCESS,
  payload: EmployerPaf,
})

export const uploadEmployerLcaFail = error => ({
  type: UPLOAD_EMPLOYER_LCA_FAIL,
  payload: error,
})

export const getPafTemplateVariables = (data) => ({
  type: GET_PAF_TEMPLATE_VARIABLES,
  payload: data
})

export const getPafTemplateVariablesSuccess = EmployerPaf => ({
  type: GET_PAF_TEMPLATE_VARIABLES_SUCCESS,
  payload: EmployerPaf,
})

export const getPafTemplateVariablesFail = error => ({
  type: GET_PAF_TEMPLATE_VARIABLES_FAIL,
  payload: error,
})






