import React, { Component, createRef, memo, useEffect, useState } from "react";
import $ from "jquery";
import { PureComponent } from "react";

import Select, { components } from "react-select"

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");
import { Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input, FormGroup, Button } from "reactstrap"
import { useNavigate } from "react-router-dom";
import { getCaseFormsList } from "../../../store/actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";


const FormBuilderDataUpdate = (props) => {
    const navigate = useNavigate();
    let fb = React.createRef();
    let formBuilder;
    const dispatch = useDispatch();

    const { CaseFormsList } = useSelector((state) => ({
        CaseFormsList: state.CaseFormsList.CaseFormsList,
    }));
    const { CaseFormsSelectList } = useSelector((state) => ({
        CaseFormsSelectList: state.CaseFormsList.CaseFormsSelectList,
    }));
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [form_id, setFormData] = useState(props.caseForm_id);
    const selectedOptionsUpdate = (options) => {
        var form_ids = options.map((opt) => opt.value).toString();
        setFormData(form_ids);
        setSelectedOptions(options.map((opt) => opt.value));
    }
    const InputOption = ({
        getStyles,
        Icon,
        isDisabled,
        isFocused,
        isSelected,
        children,
        innerProps,
        ...rest
    }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);

        // styles
        let bg = "transparent";
        if (isFocused) bg = "#eee";
        if (isActive) bg = "#B2D4FF";

        const style = {
            alignItems: "center",
            backgroundColor: bg,
            color: "inherit",
            display: "flex "
        };

        // prop assignment
        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            style
        };

        return (
            <components.Option
                {...rest}
                isDisabled={isDisabled}
                isFocused={isFocused}
                isSelected={isSelected}
                getStyles={getStyles}
                innerProps={props}
            >
                <input type="checkbox" checked={isSelected} /> &nbsp;
                {children}
            </components.Option>
        );
    };


    useEffect(() => {
        dispatch(getCaseFormsList());
    }, [dispatch]);

    useEffect(() => {
        if (props.caseId) {
            if (document.getElementById('fb-editor').children.length > 0) {
                document.getElementById('fb-editor').children[0].remove()
            }
            const fbEditor = document.getElementById("fb-editor");
            formBuilder = $(fbEditor).formBuilder({ ...props.options, defaultFields: props?.caseData ? [JSON.parse(props?.caseData)] : [] });
        }
        else {
            const fbEditor = document.getElementById("fb-editor");
            formBuilder = $(fbEditor).formBuilder({ ...props.options, defaultFields: props?.caseData ? [JSON.parse(JSON.parse(props?.caseData))] : [] });

        }
        return () => {
            // formBuilder.actions.clearFields();
        };
    }, [props.caseId]);

    useEffect(() => {
        if (props.caseForm_id) {
            setFormData(props.caseForm_id)
        }

    }, [props.caseForm_id]);




    const handleClickSave = (event) => {
        props.getChildFormData($('#fb-editor').formBuilder('getData'), form_id);
        //this.props.getChildCaseForm(this.caseFormVal)
    }

    const handleClickReset = (event) => {
        navigate('/admin/case-form-attributes');
    }

    const handleCaseFormChange = event => {
        form_id = event.target.value;
    }
    const caseFormData = () => {
        //alert("===");
    }


    return (
        <div className={`${props.caseId}`}>
            <Form onSubmit={caseFormData()}>
                <Row className="mb-4">
                    <Label
                        htmlFor="horizontal-firstname-input"
                        className="col-sm-2 col-form-label"
                    >
                        Case Form:
                    </Label>
                    <Col sm={5}>

                        <Select
                            defaultValue={[]}
                            value={CaseFormsSelectList.filter((value, index) => {
                                if (form_id) {
                                    let selected = form_id.split(",")
                                    return selected.includes(value.value)
                                }
                            })}
                            isMulti
                            // className={selectedOptions.length === 0 ? 'is-invalid':''}
                            name="form_id"
                            closeMenuOnSelect={false}
                            hideSelectedOptions={true}
                            selected={CaseFormsSelectList.filter(({ value }) =>
                                form_id ?
                                    (form_id.split(",")).includes(value) : ''
                            )}
                            // onChange={handleChange}
                            onChange={(options) => {
                                if (Array.isArray(options)) {
                                    selectedOptionsUpdate(options)
                                }
                            }}
                            options={CaseFormsSelectList}
                            components={{
                                Option: InputOption
                            }}
                        />
                        {/* <Input
                            id="form_id"
                            name="form_id"
                            type="select"
                            onChange={handleCaseFormChange}
                            multiple={true}
                        // onChange={validation.handleChange}
                        // onBlur={validation.handleBlur}
                        // value={validation.values.status || ""}
                        // invalid={
                        //   validation.touched.status && validation.errors.status ? true : false
                        // }
                        >
                            <option value="">Select Case Form</option>
                            {CaseFormsList && CaseFormsList.map(row => (
                                <option key={row.form_id} value={row.form_id} selected={row.form_id == props.caseForm_id}>
                                    {row.form_name || row.name}
                                </option>
                            ))}
                        </Input> */}

                        {/* {validation.touched.status && validation.errors.status ? (
                <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
              ) : null} */}

                    </Col>
                </Row>
            </Form>
            <Row className="mb-4">
                <Col sm={12}>
                    <div id="fb-editor" ref={fb} />
                </Col>

            </Row>
            <Row className="justify-content-middle">

                <Col sm={2}>
                    <FormGroup className="mb-0">
                        <div>
                            <Button type="button" id="trash" color="secondary" onClick={handleClickReset}>
                                Cancel
                            </Button>
                            <Button type="button" id="show-data" color="primary" className="ms-1" onClick={handleClickSave}>
                                Submit
                            </Button>{" "}

                        </div>
                    </FormGroup>

                </Col>
            </Row>
        </div>)
}
export const MemorizedFormBuilderDataUpdate = React.memo(FormBuilderDataUpdate, (prevProps, nextProps) => {
    if ((prevProps.caseId === nextProps.caseId) && typeof prevProps !== 'undefined') {
        return true; // props are equal
    }
    return false; // props are not equal -> update the component
}
);