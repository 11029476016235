import React from "react";

import EmployerTimesheetView from "../../Timesheets/TimesheetView";
import EmployerBasicSuite from "../../Basic";

const MainTimesheetViewComponent = () => {

const getLoggedInPlanType = () => {
    return localStorage.getItem("plan_type");
  }

  if(getLoggedInPlanType()==1) {
    return <EmployerTimesheetView/>
  } else {
    return <EmployerBasicSuite componentName="Timesheet View"/>
  }
};

export default MainTimesheetViewComponent;
