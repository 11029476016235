import {
  GET_ATTORNEY_DASHBOARD_FAIL,
  GET_ATTORNEY_DASHBOARD_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  attorneydashboard: [],
  attorneydashboardDetail: {},
  error: {},
}

const AttorneyDashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ATTORNEY_DASHBOARD_SUCCESS:
      return {
        ...state,
        attorneydashboards: action.payload,
      }

    case GET_ATTORNEY_DASHBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    default:
      return state
  }
}

export default AttorneyDashboard
