/* TIMESHEETS */
export const ADD_TIMESHEET = "ADD_TIMESHEET"
export const ADD_TIMESHEET_SUCCESS = "ADD_TIMESHEET_SUCCESS"
export const ADD_TIMESHEET_FAIL= "ADD_TIMESHEET_FAIL"
export const VALIDATE_TIMESHEET_BY_PERIOD = "VALIDATE_TIMESHEET_BY_PERIOD"
export const VALIDATE_TIMESHEET_BY_PERIOD_SUCCESS = "VALIDATE_TIMESHEET_BY_PERIOD_SUCCESS"
export const VALIDATE_TIMESHEET_BY_PERIOD_FAIL= "VALIDATE_TIMESHEET_BY_PERIOD_FAIL"
export const GET_EMPLOYEETIMESHEETS = "GET_EMPLOYEETIMESHEETS"
export const GET_EMPLOYEETIMESHEETS_SUCCESS = "GET_EMPLOYEETIMESHEETS_SUCCESS"
export const GET_EMPLOYEETIMESHEETS_FAIL= "GET_EMPLOYEETIMESHEETS_FAIL"
export const GET_PROJECTS = "GET_PROJECTS"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_PROJECTS_FAIL= "GET_PROJECTS_FAIL"
export const CREATE_PROJECT = "CREATE_PROJECT"
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS"
export const CREATE_PROJECT_FAIL= "CREATE_PROJECT_FAIL"