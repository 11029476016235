import React, { useEffect, useState } from "react";
import {
    Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input,
    FormGroup, FormFeedback,
} from "reactstrap"

import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { getCommonUsers, addCommonUserDetail, getCommonUserDetail, userCommonUpdate, getEmployerContacts as getEmployerContacts, getEmployerContactDetail, addEmployerContactDetail, EmployerContactUpdate, userCommonDelete } from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";

const CreateUser = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId'); // Read the 'id' query parameter from the URL

    const [initialValues, setInitialValues] = useState(false);
    const handleActionSubmit = (e) => {
        validation.handleSubmit();
    };

    const { userDetail, createUserDetail, users } = useSelector((state) => ({
        userDetail: state.Users.userDetail || {},
        users: state.Users.users || {},
        createUserDetail: state.Users.createUserDetail || {},
    }));

    useEffect(() => {
        if (userId)
            dispatch(getCommonUserDetail({ user_id: userId }));
    }, [userId, dispatch]);

    const iniValues = {
        user_id: userId,
        first_name: "",
        last_name: "",
        email: "",
        designation: "",
        role_id: "",
    };
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: (userId && (Object.keys(userDetail).length > 0)) ? userDetail : iniValues,

        validationSchema: Yup.object({
            first_name: Yup.string().required("Please Enter First Name").nullable(),
            last_name: Yup.string().required("Please Enter Last Name").nullable(),
            email: Yup.string().required("Please Enter Email address").nullable(),
            designation: Yup.string().required("Please Enter Designation").nullable(),
            role_id: Yup.string().required("Please Select Role").nullable(),
        }),
        onSubmit: (values) => {
            if (Number(userId) > 0) {
                values.user_id = values.id;
                dispatch(userCommonUpdate(values));
            } else {
                dispatch(addCommonUserDetail(values));
            }
            setTimeout(() => {
                dispatch(getCommonUsers({ 'status': 'Active' }));
                navigate("/attorney/contacts");
            }, 1000);

        }
    });


    let title = userId ? 'Update User' : 'Create User';
    //meta title
    document.title = title + " | Immidock ";
    var userType = window.localStorage.getItem("user_type")
    const BreadcrumbData = [
        { name: 'Dashboard', url: '/' + userType + '/dashboard' },
        { name: title, url: '#' }
    ]
    return (
        <React.Fragment>
            <>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                        <Col xs={12}>
                            <Card>

                                <CardHeader>
                                    <h4 className="card-title">User Details</h4>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <FormikProvider value={validation}>
                                        <Form className="needs-validation"
                                            onSubmit={(values) => {
                                                values.preventDefault();
                                                // console.log(validate(values));
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >

                                            <Row>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom01">First name *</Label>
                                                        <Input
                                                            name="first_name"
                                                            placeholder="First name"
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom01"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.first_name || ""}
                                                            invalid={
                                                                validation.touched.first_name && validation.errors.first_name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.first_name && validation.errors.first_name ? (
                                                            <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">Last name *</Label>
                                                        <Input
                                                            name="last_name"
                                                            placeholder="Last name"
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.last_name || ""}
                                                            invalid={
                                                                validation.touched.last_name && validation.errors.last_name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.last_name && validation.errors.last_name ? (
                                                            <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">Email Address *</Label>
                                                        <Input
                                                            name="email"
                                                            placeholder="Email Address"
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            invalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>


                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="Designation">Designation *</Label>
                                                        <Input
                                                            name="designation"
                                                            placeholder="Designation"
                                                            type="text"
                                                            className="form-control"
                                                            id="Designation"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.designation || ""}
                                                            invalid={
                                                                validation.touched.designation && validation.errors.designation ? true : false
                                                            }
                                                        />
                                                        {validation.touched.designation && validation.errors.designation ? (
                                                            <FormFeedback type="invalid">{validation.errors.designation}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="role_id">Role</Label>
                                                        <Input
                                                            name="role_id"
                                                            placeholder="Role"
                                                            type="select"
                                                            className="form-control"
                                                            id="role_id"
                                                            // onChange={validation.handleChange}
                                                            onChange={(e) => {
                                                                validation.handleChange(e);
                                                            }}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.role_id || ""}
                                                            invalid={
                                                                validation.touched.role_id && validation.errors.role_id ? true : false
                                                            }
                                                        >
                                                            <option value="">Select Role</option>
                                                            <option value="1">Admin</option>
                                                            <option value="2">Team</option>
                                                        </Input>
                                                        {validation.touched.role_id && validation.errors.role_id ? (
                                                            <FormFeedback type="invalid">{validation.errors.role_id}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Row style={{ float: "right" }}>
                                                <Col >
                                                    <span className="mt-4">
                                                        <button type="reset" className="btn btn-secondary" color="secondary" onClick={() => navigate(-1)}>
                                                            Cancel
                                                        </button>
                                                    </span>
                                                    &nbsp;&nbsp;
                                                    <span className="mt-4">
                                                        <button type="submit" className="btn btn-primary">
                                                            Save
                                                        </button>
                                                    </span>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </FormikProvider>
                                </CardBody>

                            </Card>
                        </Col>
                    </Container>
                </div>
            </>
        </React.Fragment >
    );
};

export default CreateUser;