import {
  GET_ATTRIBUTES_FAIL,
  GET_ATTRIBUTES_SUCCESS,
  GET_ATTRIBUTE_DETAIL_SUCCESS,
  GET_ATTRIBUTE_DETAIL_FAIL,
  UPDATE_ATTRIBUTE_DETAIL_FAIL,
  UPDATE_ATTRIBUTE_DETAIL_SUCCESS,
  UPDATE_ATTRIBUTE_DETAIL,
  DELETE_ATTRIBUTE_DETAIL_FAIL,
  DELETE_ATTRIBUTE_DETAIL_SUCCESS,
  DELETE_ATTRIBUTE_DETAIL,
  ADD_ATTRIBUTE_DETAIL_SUCCESS,
  ADD_ATTRIBUTE_DETAIL,
  ADD_ATTRIBUTE_DETAIL_FAIL
} from "./actionTypes"

const QttributesIT_STATE = {
  attributes: [],
  attributesFilter: [],
  attributeDetail: {},
  error: {},
}

const Qttributes = (state = QttributesIT_STATE, action) => {
  switch (action.type) {
    case GET_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        attributes: action.payload,
        attributesFilter: action.payload.map(k => ({
          id: `${k.id}`,
          name: `${k.name}`
        })),
      }

    case GET_ATTRIBUTES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ATTRIBUTE_DETAIL_SUCCESS:
      return {
        ...state,
        attributeDetail: action.payload,
      }

    case GET_ATTRIBUTE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ATTRIBUTE_DETAIL_SUCCESS:
      return {
        ...state,
        attributes: action.payload,
      }

    case ADD_ATTRIBUTE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ATTRIBUTE_DETAIL_SUCCESS:
      return {
        ...state,
        attributes: state.attributes.filter((item) => item.id !== action.payload),
      };

    case DELETE_ATTRIBUTE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_ATTRIBUTE_DETAIL_SUCCESS:
      return {
        ...state,
        attributes: action.payload,
      };

    case UPDATE_ATTRIBUTE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state
  }
}

export default Qttributes
