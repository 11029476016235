import { GET_CASEFORMATTRIBUTETEMPLATES_SUCCESS,
        GET_CASEFORMATTRIBUTETEMPLATES_FAIL,
        ADD_CASEFORMATTRIBUTETEMPLATE_SUCCESS,
        ADD_CASEFORMATTRIBUTETEMPLATE_FAIL, 
        GET_CASEFORMATTR_TEMPLATE_DETAIL_FAIL,
        GET_CASEFORMATTR_TEMPLATE_DETAIL_SUCCESS,
        UPDATE_CASEFORMATTRIBUTE_TEMPLATES_SUCCESS,
        UPDATE_CASEFORMATTRIBUTE_TEMPLATES_FAIL,
        DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL_FAIL,
        DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL_SUCCESS} from "./actionTypes"

const INIT_STATE = {
  caseformattributetemplates: [],
  caseformattributetemplatesDetail:{},
  error: {},
}

const Caseformattributetemplates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CASEFORMATTRIBUTETEMPLATES_SUCCESS:
      return {
        ...state,
        caseformattributetemplates: action.payload,
      }

    case GET_CASEFORMATTRIBUTETEMPLATES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    
    case ADD_CASEFORMATTRIBUTETEMPLATE_SUCCESS:
      return {
        ...state,
        caseformattributetemplates: action.payload
      }

    case ADD_CASEFORMATTRIBUTETEMPLATE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      case GET_CASEFORMATTR_TEMPLATE_DETAIL_SUCCESS:
        return {
          ...state,
          caseformattributetemplatesDetail: action.payload,
        }
  
      case GET_CASEFORMATTR_TEMPLATE_DETAIL_FAIL:
        return {
          ...state,
          error: action.payload,
        }
        case UPDATE_CASEFORMATTRIBUTE_TEMPLATES_SUCCESS:
          return {
            ...state,
            caseformattributetemplates:action.payload,
          }
    
        case UPDATE_CASEFORMATTRIBUTE_TEMPLATES_FAIL:
          return {
            ...state,
            error: action.payload,
          } 
        case DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL_SUCCESS:
        return {
          ...state,
          caseformattributetemplates: state.caseformattributetemplates.filter((item) => item.id !== action.payload)
        };
  
      case DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL_FAIL:
        return {
          ...state,
          error: action.payload,
        };
    default:
      return state
  }
}

export default Caseformattributetemplates
