import React, { useEffect, useMemo, useState, useLayoutEffect } from "react";
import DatatableContainer from "../../../components/Common/DatatableContainer";
import {
  Link,useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

import { getCaseFormAttributes } from "../../../store/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import {
//     CommonCell,Date,CaseTypeName
// } from "./caseTypeListCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";
import { EMPTY } from "draft-js/lib/CharacterMetadata";
import { caseFormAttributeDelete, getCaseFormAttributeDetail } from "../../../store/admin/caseformattributes/actions";
import { useAlert } from "../../../components/Common/Alert";


const CaseFormAttributeList = () => {

  //meta title
  // document.getElementById("preloader").style.display = "block";
  // setTimeout(function () {
  //   document.getElementById("preloader").style.display = "none";
  // }, 2500);
  document.title = "Case Form Attributes | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [order, setOrder] = useState(null);

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);

  const { caseformattributes } = useSelector((state) => ({ 
    caseformattributes: state.CaseFormAttributes.caseformattributes,
  }));


  
  useEffect(() => {
    dispatch(getCaseFormAttributes());
  }, [dispatch]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {
    dispatch(getCaseFormAttributeDetail());
    navigate(`/admin/add-case-form-attributes`);
  };

  const onUpdateData = (data) => {
    if (data.id) {
      dispatch(getCaseFormAttributeDetail(data.id));
    }
    navigate(`/admin/add-case-form-attributes?id=${data.id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };
  
  const handleDeleteInvoice = () => {
    if (order.id) {
      dispatch(caseFormAttributeDelete(order.id));
      setDeleteModal(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Case Form Attribute",
        accessor: "name",
        filterable: true,
        disableFilters: true,
        // Cell: (cellProps) => {
        //   return <CaseTypeName {...cellProps} />;
        // },
      },
	  {
        Header: "Case Form",
        accessor: "form_name",
        filterable: true,
        disableFilters: true,
        // Cell: (cellProps) => {
        //   return <CaseTypeName {...cellProps} />;
        // },
      },
      
      {
        Header: "Content",
        accessor: "content",
        filterable: true,
        disableFilters: true,
        // Cell: (casetype) => {
        //   console.log(casetype.value,'=======status');
        //   return (
        //     <div className={""}>
        //       {casetype.value}
        //     </div>
        //   )
        // },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        // Cell: (cellProps) => {
        //   return <Date {...cellProps} />;
        // },
      },
      
     
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onUpdateData(cellProps.row.original)}>Edit</DropdownItem>
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  const BreadcrumbData = [
    {name:'Dashboard', url:'/admin/dashboard'},
    { name: 'Case Form Attributes', url: '/admin/case-form-attributes' }
  ]
  let title = 'Case Form Attributes';
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteInvoice}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={caseformattributes}
                    isGlobalFilter={true}
                    isAddCaseFormAttribute={true}
                    isFilterDisable={true}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                  />
                </CardBody>
                
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CaseFormAttributeList;
