import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input, FormGroup, Button, FormFeedback } from "reactstrap"
import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { getQnnrtypeDetail, addQnnrtypeDetail, qnnrtypeUpdate, getCasetypes } from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "../../../components/Common/Alert";

const FormLayoutsBasic = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL

  useEffect(() => {
    dispatch(getQnnrtypeDetail(editId));
    dispatch(getCasetypes());
  }, [dispatch, editId]);

  const { qnnrtypeDetail } = useSelector((state) => ({
    qnnrtypeDetail: state.qnnrtypes.qnnrtypeDetail,
  }));

  const { casetypes } = useSelector((state) => ({
    casetypes: state.casetypes.casetypes
  }));
  var userType = window.localStorage.getItem("user_type")

  const backToListing = () => {
    navigate(`/${userType}/questionnaire-types`);
  }
  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: editId ? qnnrtypeDetail : {
      "id": editId,
      "case_type_id": "",
      "qnnr_type": "",
      "emp_receipt_number_visibility": "",
      "receipt_number_visibility": "",
      "lca_details_visibility": "",
      "status": ""
    },
    validationSchema: Yup.object({
      case_type_id: Yup.string().required("Please select Case Type Name").nullable(),
      qnnr_type: Yup.string().required("Please Enter Qnnr Type Name").nullable(),
      emp_receipt_number_visibility: Yup.string().required("Please Enter Emp Receitpt Number Visibility ").nullable(),
      status: Yup.string().required("Please select Status").nullable(),
    }),
    onSubmit: (values) => {
      if (Number(editId) > 0) {
        dispatch(qnnrtypeUpdate(values));

      } else {
        dispatch(addQnnrtypeDetail(values));

      }
      navigate("/admin/questionnaire-types");
    }
  });
  let title = editId ? 'Update Questionnaire Type' : 'Add Questionnaire Type';
  const BreadcrumbData = [
    { name: 'Questionnaire Types', url: '/admin/questionnaire-types' },
    { name: title, url: '/admin/add-questionnaire-types' }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

          <Row>
            <Col xs={12}>
              <Card>

                <CardBody className="p-4">

                  <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {/* import TextualInputs */}
                    <div className="mt-4 mt-lg-0">



                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          Questionnaire Type Name:
                        </Label>
                        <Col sm={5}>
                          <Input
                            type="text"
                            name="qnnr_type"
                            className="form-control"
                            id="horizontal-firstname-input"
                            placeholder="Questionnaire Type Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.qnnr_type || ""}
                            invalid={
                              validation.touched.qnnr_type && validation.errors.qnnr_type ? true : false
                            }
                          />
                          {validation.touched.qnnr_type && validation.errors.qnnr_type ? (
                            <FormFeedback type="invalid">{validation.errors.qnnr_type}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-email-input"
                          className="col-sm-2 col-form-label"
                        >
                          Case Type
                        </Label>
                        <Col sm={5}>

                          <Input
                            id="validationCustom02"
                            name="case_type_id"
                            type="select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.case_type_id || ""}
                            invalid={
                              validation.touched.case_type_id && validation.errors.case_type_id ? true : false
                            }
                          >
                            <option value="">Select Case Type</option>
                            {casetypes.map(row => (
                              <option key={row.id} value={row.id}>
                                {row.case_type}
                              </option>
                            ))}
                          </Input>

                          {validation.touched.case_type_id && validation.errors.case_type_id ? (
                            <FormFeedback type="invalid">{validation.errors.case_type_id}</FormFeedback>
                          ) : null}


                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          Emp Receitpt Number Visibility:
                        </Label>
                        <Col sm={5}>
                          <Input
                            type="text"
                            name="emp_receipt_number_visibility"
                            className="form-control"
                            id="horizontal-firstname-input"
                            placeholder="Emp Receitpt Number Visibility"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.emp_receipt_number_visibility || ""}
                            invalid={
                              validation.touched.emp_receipt_number_visibility && validation.errors.emp_receipt_number_visibility ? true : false
                            }
                          />
                          {validation.touched.emp_receipt_number_visibility && validation.errors.emp_receipt_number_visibility ? (
                            <FormFeedback type="invalid">{validation.errors.emp_receipt_number_visibility}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          Receitpt Number Visibility:
                        </Label>
                        <Col sm={5}>
                          <Input
                            type="text"
                            name="receipt_number_visibility"
                            className="form-control"
                            id="horizontal-firstname-input"
                            placeholder="Receitpt Number Visibility"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.receipt_number_visibility || ""}

                          />

                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          LCA Details Visibility:
                        </Label>
                        <Col sm={5}>
                          <Input
                            type="text"
                            name="lca_details_visibility"
                            className="form-control"
                            id="horizontal-firstname-input"
                            placeholder="LCA Details Visibility"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lca_details_visibility || ""}

                          />

                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          Status:
                        </Label>
                        <Col sm={5}>

                          <Input
                            id="validationCustom02"
                            name="status"
                            type="select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status || ""}
                            invalid={
                              validation.touched.status && validation.errors.status ? true : false
                            }
                          >
                            <option >select status</option>
                            <option value="Active">Active</option>
                            <option value="InActive">InActive</option>
                          </Input>

                          {validation.touched.status && validation.errors.status ? (
                            <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      <Row className="justify-content-middle" >
                        <Col sm={4}></Col>
                        <Col sm={7} >
                          <FormGroup className="mb-0">
                            <div>
                              <Button type="reset" color="secondary" onClick={() => navigate(-1)}>
                                Cancel
                              </Button>{" "}
                              <Button type="submit" className="ms-1" color="primary">
                                Save
                              </Button>
                            </div>
                          </FormGroup>

                        </Col>
                      </Row>

                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};
export default FormLayoutsBasic
