import {
  GET_EMPLOYER_PAYROLL,
  GET_EMPLOYER_PAYROLL_SUCCESS,
  GET_EMPLOYER_PAYROLL_FAIL,
  GET_EMPLOYER_PAYROLLS,
  GET_EMPLOYER_PAYROLLS_SUCCESS,
  GET_EMPLOYER_PAYROLLS_FAIL,
  UPDATE_EMPLOYER_PAYROLL,
  UPDATE_EMPLOYER_PAYROLL_SUCCESS,
  UPDATE_EMPLOYER_PAYROLL_FAIL,
  ADD_EMPLOYER_PAYROLL,
  ADD_EMPLOYER_PAYROLL_SUCCESS,
  ADD_EMPLOYER_PAYROLL_FAIL,
  DELETE_EMPLOYER_PAYROLL,
  DELETE_EMPLOYER_PAYROLL_SUCCESS,
  DELETE_EMPLOYER_PAYROLL_FAIL,
  REFRESH_EMPLOYER_PAYROLL,
  REFRESH_EMPLOYER_PAYROLL_SUCCESS,
  REFRESH_EMPLOYER_PAYROLL_FAIL,
  REFRESH_EMPLOYEE_PAYROLL,
  REFRESH_EMPLOYEE_PAYROLL_SUCCESS,
  REFRESH_EMPLOYEE_PAYROLL_FAIL,
} from "./actionTypes"

export const getEmployerPayrolls = (data) => ({
  type: GET_EMPLOYER_PAYROLLS,
  payload:data
})

export const getEmployerPayrollsSuccess = data => ({
  type: GET_EMPLOYER_PAYROLLS_SUCCESS,
  payload: data,
})

export const getEmployerPayrollsFail = error => ({
  type: GET_EMPLOYER_PAYROLLS_FAIL,
  payload: error,
})

export const getEmployerPayroll = payroll => ({
  type: GET_EMPLOYER_PAYROLL,
  payload:payroll,
})

export const getEmployerPayrollSuccess = payroll => ({
  type: GET_EMPLOYER_PAYROLL_SUCCESS,
  payload: payroll,
})

export const getEmployerPayrollFail = error => ({
  type: GET_EMPLOYER_PAYROLL_FAIL,
  payload: error,
})

export const  addEmployerPayroll = (data) => ({
  type: ADD_EMPLOYER_PAYROLL,
  payload: data
})

export const addEmployerPayrollSuccess = (data) => ({
  type: ADD_EMPLOYER_PAYROLL_SUCCESS,
  payload: data
})

export const addEmployerPayrollFail = (error) => ({
  type: ADD_EMPLOYER_PAYROLL_FAIL,
  payload: error
})

export const employerPayrollUpdate = (data) => ({
  type: UPDATE_EMPLOYER_PAYROLL,
  payload: data
})

export const employerPayrollUpdateSuccess = (data) => ({
  type: UPDATE_EMPLOYER_PAYROLL_SUCCESS,
  payload: data
})

export const employerPayrollUpdateFail = (error) => ({
  type: UPDATE_EMPLOYER_PAYROLL_FAIL,
  payload: error
})

export const deleteEmployerPayroll = (data) => ({
  type: DELETE_EMPLOYER_PAYROLL,
  payload: data
})

export const deleteEmployerPayrollSuccess = (data) => ({
  type: DELETE_EMPLOYER_PAYROLL_SUCCESS,
  payload: data
})

export const deleteEmployerPayrollFail = (error) => ({
  type: DELETE_EMPLOYER_PAYROLL_FAIL,
  payload: error
})


export const refreshEmployerPayroll = (data) => ({
  type: REFRESH_EMPLOYER_PAYROLL,
  payload: data
})

export const refreshEmployerPayrollSuccess = (data) => ({
  type: REFRESH_EMPLOYER_PAYROLL_SUCCESS,
  payload: data
})

export const refreshEmployerPayrollFail = (error) => ({
  type: REFRESH_EMPLOYER_PAYROLL_FAIL,
  payload: error
})


export const refreshEmployeePayroll = (data) => ({
  type: REFRESH_EMPLOYEE_PAYROLL,
  payload: data
})

export const refreshEmployeePayrollSuccess = (data) => ({
  type: REFRESH_EMPLOYEE_PAYROLL_SUCCESS,
  payload: data
})

export const refreshEmployeePayrollFail = (error) => ({
  type: REFRESH_EMPLOYEE_PAYROLL_FAIL,
  payload: error
})