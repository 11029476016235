import $ from "jquery";
import React, { Component, createRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");
import { Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input, FormGroup, Button } from "reactstrap"


const FormBuilderDataUpdate = (props) =>{
    const navigate = useNavigate();
    let fb = React.createRef();
    let formBuilder;
    let form_id;
    useEffect(() => {
        if(props.attrid)
        {
            if(document.getElementById('fb-editor').children.length > 0)
            {
                document.getElementById('fb-editor').children[0].remove()
            }
            const fbEditor = document.getElementById("fb-editor");
            formBuilder = $(fbEditor).formBuilder({...props.options,defaultFields:props?.attributeDetailData ? [JSON.parse(props?.attributeDetailData)] : []});
        }
        else
        {
            const fbEditor = document.getElementById("fb-editor");
            formBuilder = $(fbEditor).formBuilder({...props.options,defaultFields:props?.attributeDetailData ? [JSON.parse(JSON.parse(props?.attributeDetailData))] : []});

        }
        return () => {
            // formBuilder.actions.clearFields();
        };
    }, [props.attrid]);

    
    const handleClickSave = (event) => {
        props.getChildFormData($('#fb-editor').formBuilder('getData'), props.attForm_id);
        //this.props.getChildCaseForm(this.caseFormVal)
    }

    const handleClickReset = (event) => {
        navigate('/admin/questionnaire-attributes')
    }

    const handleCaseFormChange = event => {
        form_id= event.target.value;
     }
    const caseFormData = () =>{
        //alert("===");
    }


    return (
        <div className={`${props.attrid}`}>
        <Row className="mb-4">
            <Col sm={12}>
                <div id="fb-editor" ref={fb} />
            </Col>

        </Row>
        <Row className="justify-content-middle">

            <Col sm={2}>
                <FormGroup className="mb-0">
                    <div>
                    <Button type="button" id="trash" color="secondary" onClick={handleClickReset}>
                            Cancel
                        </Button>
                        <Button type="button" id="show-data" color="primary" className="ms-1" onClick={handleClickSave}>
                            Submit
                        </Button>{" "}                        
                    </div>
                </FormGroup>

            </Col>
        </Row>
    </div>)
}
export const MemorizedFormBuilderDataUpdate = React.memo(FormBuilderDataUpdate, (prevProps, nextProps) => {
    if (prevProps.attrid === nextProps.attrid && typeof prevProps !== 'undefined' ) {
        return true; // props are equal
    }
    return false; // props are not equal -> update the component
}
);


// class FormBuilder extends Component {
//     fb = createRef();
//     constructor(props) {
//         super(props);

//         this.handleClickSave = this.handleClickSave.bind(this);
//         this.handleClickReset = this.handleClickReset.bind(this);

//     }
	
//     componentDidMount() {
// 		//console.log('helo12');
//         $(this.fb.current).formBuilder({...options,defaultFields:this.props?.attributeDetailData ? [JSON.parse(JSON.parse(this.props?.attributeDetailData))] : []});
//     }

//     handleClickSave(event) {
//         this.props.getChildFormData($('#fb-editor').formBuilder('getData'))
//     }

//     handleClickReset(event) {
//         $('#fb-editor').formBuilder('clearFields')
//     }

//     render() {
//         return (
//             <div>
//                 <Row className="mb-4">
//                     <Col sm={12}>
//                         <div id="fb-editor" ref={this.fb} />
//                     </Col>

//                 </Row>
//                 <Row className="justify-content-middle">

//                     <Col sm={2}>
//                         <FormGroup className="mb-0">
//                             <div>
//                                 <Button type="button" id="show-data" color="primary" className="ms-1" onClick={this.handleClickSave}>
//                                     Submit
//                                 </Button>{" "}
//                                 <Button type="reset" id="trash" color="secondary" onClick={this.handleClickReset}>
//                                     Reset
//                                 </Button>
//                             </div>
//                         </FormGroup>

//                     </Col>
//                 </Row>
//             </div>
//         );
//     }
// }

// export default FormBuilder;
