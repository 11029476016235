import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from "react-dropzone";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    Card,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import { getCurrencyFormat } from "../../../common/data/currencyFormat";
import { getDateFormatDMonY, getDateFormatYMD } from "../../../common/data/dateFormat";

// Formik validation
import classnames from "classnames";
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

import { setAlertMessage } from "../../../store/common/actions";

import { addInvoiceDetail, changePreloader, getCreateInvoiceSettings, getCustomers, getEmployerCompanyDetail, getEmployerContacts, getInvoiceDetail, getInvoices, getInvoiceTimesheetDetails, getProfile, invoiceUpdate, uploadInvoiceAttachment } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";


const CreateInvoice = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formikRef = useRef();

    const queryParams = new URLSearchParams(location.search);
    const invoiceId = queryParams.get('invoiceId'); // Read the 'invoiceId' query parameter from the URL

    const [sendInvoice, setSendInvoice] = useState(true)
    const [apiSuccess, setApiSuccess] = useState(false);
    const [createIntial, setCreateIntial] = useState(true)
    const [activeTab1, setactiveTab1] = useState("invoice");
    const toggle1 = (tab) => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };

    const [subTotal, setSubTotal] = useState("0.00");
    const [discount, setDiscount] = useState("0.00");
    const [grandTotal, setGrandTotal] = useState("0.00");

    let total = () => {
        let val = 0;
        if ((Object.keys(validation?.values?.invoice_items).length > 0)) {
            validation?.values?.invoice_items.map((item, index) => (
                item.hours * item.rate ? val += parseFloat((item.hours * item.rate)) : ""
            ))
        }
        return parseFloat(val).toFixed(2);
    };

    let calculateddiscount = () => {
        let calculated_discount = 0;
        if (validation?.values?.is_discount_amount_enable) {
            calculated_discount = parseFloat(validation?.values?.discount_amount);
        }
        if (validation?.values?.is_discount_percentage_enable) {
            calculated_discount = (validation?.values?.discount_percentage / 100) * total();;
        }
        return parseFloat(calculated_discount).toFixed(2);
    };

    let grandtotal = () => {
        return parseFloat(total() - calculateddiscount()).toFixed(2);
    }

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState("");

    function handleAcceptedFiles(files) {
        files.map((file, index) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            }
            )
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onloadend = () => {
                const binaryStr = reader.result
                // console.log(binaryStr)
                dispatch(uploadInvoiceAttachment({
                    name: file.name,
                    invoice_attach_file: binaryStr,
                }));
            }
            reader.readAsArrayBuffer(file)

        });

    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const [contactList, setContactList] = useState({ "status": "All", "emp_type": "All" });
    const [billedTo, setBilledTo] = useState([]);
    const moreactions = [{ 'label': 'Save', 'action': 'save' }, { 'label': 'Save and send', 'action': 'saveandsend' }]
    const handleActionSubmit = (e) => {
        // setSendInvoice(true) 
        validation.handleSubmit();
    };
    const handleInvoiceTimesheets = () => {

        if (validation?.values?.customer_id && validation?.values?.employee_id && validation?.values?.invoice_from_date && validation?.values?.invoice_to_date) {
            dispatch(getInvoiceTimesheetDetails(
                {
                    "customer_id": validation?.values?.customer_id,
                    "employee_id": validation?.values?.employee_id,
                    "invoice_from_date": getDateFormatYMD(validation?.values?.invoice_from_date),
                    "invoice_to_date": getDateFormatYMD(validation?.values?.invoice_to_date)
                }
            ));
        }
    };

    const { updateinvoiceDetail, invoiceTimesheetDetails, invoiceDetail, customers, createInvoiceDetail, employercompanyDetail, invoices, employercontactDetail, employercontacts, createinvoicesettings, uploadedinvoiceattachments } = useSelector((state) => ({
        invoiceTimesheetDetails: state.Invoices.invoiceTimesheetDetails,
        invoiceDetail: state.Invoices.invoiceDetail,
        employercontacts: state.EmployerContacts.employercontacts || [],
        // createinvoicesettings: {},
        createinvoicesettings: state.Invoices.createinvoicesettings || {},
        invoices: state.Invoices.invoices || {},
        createInvoiceDetail: state.Invoices.createInvoiceDetail || {},
        updateinvoiceDetail: state.Invoices.updateinvoiceDetail || {},
        uploadedinvoiceattachments: state.Invoices.uploadedinvoiceattachments || {},
        customers: state.Customers.customers || {},
        employercompanyDetail: state.EmployerCompanyInfo.employercompanyDetail,
    }));

    const [apiInvoiceSettingSuccess, setApiInvoiceSettingSuccess] = useState(false);

    useEffect(() => {
        if (_.isEmpty(invoiceId)) {
            dispatch(getCreateInvoiceSettings({}));
            setApiInvoiceSettingSuccess(true);
        }
        dispatch(getEmployerContacts(contactList));
        dispatch(getCustomers({ 'status': 'Active' }));
        dispatch(getProfile('employer'));
        dispatch(getEmployerCompanyDetail());
        dispatch(changePreloader(true));
    }, [dispatch]);


    useEffect(() => {
        if (_.isEmpty(invoiceId)) {
            var invoice_timesheet_details = [{ description: '', hours: '0', rate: '0', id: '' }];
            if (Object.keys(invoiceTimesheetDetails).length > 0) {
                invoice_timesheet_details = invoiceTimesheetDetails.map((timesheet, index) => {
                    return { description: timesheet.activity, hours: timesheet.hours, rate: timesheet.rate, id: '' };
                });
                var uploaded_timesheet_attachment = [];
                $.each(invoiceTimesheetDetails, function (i, timesheet_attachment) {
                    if (timesheet_attachment.add_document_proof) {
                        uploaded_timesheet_attachment.push({ 'url': timesheet_attachment.add_document_proof, 'name': timesheet_attachment.attachment_name, 'size': 0, 'checked': true });
                    }
                    return false;
                });
                // setSelectedFiles(currentfiles => [...currentfiles, ...uploaded_invoice_attachment]);
                // setSelectedFiles(uploaded_timesheet_attachment);
                validation.setFieldValue(
                    "attachments",
                    uploaded_timesheet_attachment
                );
            }
            // validation?.values?.invoice_items = invoice_timesheet_details;
            validation.setFieldValue(
                "invoice_items",
                invoice_timesheet_details
            );
        }
    }, [invoiceTimesheetDetails]);

    useEffect(() => {
        if (invoiceId) {
            dispatch(getInvoiceDetail({ invoice_id: invoiceId }));
        }
    }, [invoiceId]);

    // useEffect(() => {
    //     dispatch(changePreloader(false));
    // }, [createInvoiceDetail]);

    useEffect(() => {
        if (invoiceDetail.customer_id) {
            if (!_.isEmpty(invoiceDetail?.customer_details))
                setBilledTo(invoiceDetail?.customer_details)
        }
    }, [invoiceDetail]);

    useEffect(() => {
        if (Object.keys(uploadedinvoiceattachments).length > 0) {
            uploadedinvoiceattachments.checked = true;
            let uploaded_invoice_attachment = [(JSON.parse(JSON.stringify(uploadedinvoiceattachments)))];
            setSelectedFiles(currentfiles => [...currentfiles, ...uploaded_invoice_attachment]);
            validation.values.invoice_attachments = selectedFiles;
        }
    }, [uploadedinvoiceattachments])

    // const initialInvoiceSettings = Object.keys(createinvoicesettings).length > 0 ? createinvoicesettings : {};
    const iniValues = {
        invoice_items: [{ description: '', hours: '0', rate: '0', id: '' }],
        invoice_attachments: [],
        attachments: [],
        id: invoiceId,
        invoice_action: '',
        customer_id: '',
        employee_id: '',
        invoice_date: null,
        invoice_from_date: null,
        invoice_to_date: null,
        invoice_due_date: null,
        job_title: '',
        service_name: '',
        total_amount: 0,
        invoice_amount: 0,
        total_discount_amount: 0,
        discount_percentage: 0,
        discount_amount: 0,
        is_company_logo_enable: false,
        is_payment_terms_enable: false,
        is_payment_due_enable: false,
        is_invoice_period_enable: false,
        is_candidate_name_enable: false,
        is_job_title_enable: false,
        is_service_enable: false,
        is_discount_percentage_enable: false,
        is_discount_amount_enable: false,
        is_footer_enable: false,
        footer_description: '',
        is_color_theme_enable: false,
        // ...initialValues
    };
    var userType = window.localStorage.getItem("user_type")

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        innerRef: { formikRef },
        initialValues: (invoiceId && (Object.keys(invoiceDetail).length > 0)) ? invoiceDetail : iniValues,
        validationSchema: Yup.object({
            invoice_action: Yup.string().nullable(),
            invoice_attachments: Yup.array().nullable(),
            attachments: Yup.array().nullable(),
            customer_id: Yup.string().required("Please Select Customer").nullable(),
            employee_id: Yup.string().required("Please Select Beneficiary").nullable(),
            invoice_date: Yup.string().required("Please Enter Invoice Date").nullable(),
            invoice_from_date: Yup.string().required("Please Enter Invoice From Date").nullable(),
            invoice_to_date: Yup.string().required("Please Enter Invoice To Date").nullable(),
            invoice_due_date: Yup.string().required("Please Enter Invoice Due Date").nullable(),
            service_name: Yup.string().required("Please Enter Service Name").nullable(),
            job_title: Yup.string().nullable(),
            emp_name: Yup.string().nullable(),
            is_company_logo_enable: Yup.boolean(),
            is_payment_terms_enable: Yup.boolean(),
            is_payment_due_enable: Yup.boolean(),
            is_invoice_period_enable: Yup.boolean(),
            is_candidate_name_enable: Yup.boolean(),
            is_job_title_enable: Yup.boolean(),
            is_service_enable: Yup.boolean(),
            is_discount_percentage_enable: Yup.boolean(),
            is_discount_amount_enable: Yup.boolean(),
            is_footer_enable: Yup.boolean(),
            // is_color_theme_enable: Yup.boolean(),
            discount_percentage: Yup.number().when('is_discount_percentage_enable', {
                is: (val) => val === true, //&& val.includes("1"),
                then: Yup.number().min(0).default(0).positive("Must be a positive value").transform((value) => Number.isNaN(value) ? null : value).typeError('Percentage must be a number')
                    .required("Please Enter Discount percentage").nullable(),
                otherwise: () => Yup.number().default(0).nullable(),
            }),
            discount_amount: Yup.number().when('is_discount_amount_enable', {
                is: (val) => val === true, //&& val.includes("1"),
                then: Yup.number().min(0).default(0).positive("Must be a positive value").transform((value) => Number.isNaN(value) ? null : value).typeError('Amount must be a number')
                    .required("Please Enter Discount amount").nullable(),
                otherwise: () => Yup.number().default(0).nullable(),
            }),
            invoice_items: Yup.array()
                .of(
                    Yup.object().shape({
                        description: Yup.string().required("Please Enter Description"),
                        hours: Yup.number().min(0).default(0).positive("Must be a positive value").transform((value) => Number.isNaN(value) ? null : value).required("Please Enter Hours").typeError('Hours must be a number'),
                        rate: Yup.number().min(0).default(0).positive("Must be a positive value").transform((value) => Number.isNaN(value) ? null : value).required("Please Enter Rate").typeError('Rate must be a number'),
                    })
                )
                .required('Must filled the item details'),

        }),
        validate: values => {
            const errors = {};
            // Validation logic here 
            return errors;
        },
        onSubmit: (values) => {
            values.invoice_attachments && values.invoice_attachments.map((invoice_attachment, index) => {
                if (typeof invoice_attachment === 'string')
                    invoice_attachment = JSON.parse(invoice_attachment);
                values.invoice_attachments[index] = invoice_attachment;
            });

            validation.values.total_amount = grandtotal();
            validation.values.total_discount_amount = calculateddiscount();
            validation.values.invoice_amount = total();

            if (values.invoice_action) {
                values = { ...values, invoice_date: getDateFormatYMD(values.invoice_date), invoice_from_date: getDateFormatYMD(values.invoice_from_date), invoice_to_date: getDateFormatYMD(values.invoice_to_date), invoice_due_date: getDateFormatYMD(values.invoice_due_date), };

                if (_.isEmpty(invoiceId)) {
                    dispatch(addInvoiceDetail(values));
                } else {
                    values.invoice_id = invoiceDetail.invoice_id;
                    dispatch(invoiceUpdate(values));
                }
                setApiSuccess(true);

                if (validation?.values?.invoice_action == 'saveandsend') {
                    validation.values.invoice_action = '';
                    validation.resetForm();
                } else {
                    validation.values.invoice_action = '';
                    validation.resetForm();
                    dispatch(getInvoices({ invoice_type: 'All' }));
                    setTimeout(() => {
                        navigate(`/${userType}/invoices`, { replace: true });
                    }, 2000);
                }
            }

        }
    });

    useEffect(() => {
        if (Object.keys(createInvoiceDetail).length > 0) {
            if (_.isEmpty(createInvoiceDetail?.id) && apiSuccess && sendInvoice) {
                dispatch(getInvoiceDetail({ invoice_id: createInvoiceDetail?.id }));
                setApiSuccess(false);;
                setTimeout(() => {
                    navigate(`/${userType}/view-invoice?invoiceId=${createInvoiceDetail?.id}&sendinvoice=true`, { replace: true });
                }, 2000);
            }
        }
        if (Object.keys(updateinvoiceDetail).length > 0) {
            if (_.isEmpty(updateinvoiceDetail?.id) && apiSuccess && sendInvoice) {
                dispatch(getInvoiceDetail({ invoice_id: updateinvoiceDetail?.id }));
                setApiSuccess(false);;
                setTimeout(() => {
                    navigate(`/${userType}/view-invoice?invoiceId=${updateinvoiceDetail?.id}&sendinvoice=true`, { replace: true });
                }, 2000);
            }
        }
    }, [createInvoiceDetail, updateinvoiceDetail]);


    useEffect(() => {
        if (Object.keys(createinvoicesettings).length > 0) {
            if (_.isEmpty(invoiceId) && apiInvoiceSettingSuccess) {
                validation.setFieldValue("service_name", createinvoicesettings?.service);
                validation.setFieldValue("total_amount", (createinvoicesettings?.total_amount) !== 0 ? createinvoicesettings?.total_amount : 0);
                validation.setFieldValue("invoice_amount", (createinvoicesettings?.invoice_amount) !== 0 ? createinvoicesettings?.invoice_amount : 0);
                validation.setFieldValue("total_discount_amount", (createinvoicesettings?.total_discount_amount) !== 0 ? createinvoicesettings?.total_discount_amount : 0);
                validation.setFieldValue("discount_percentage", (createinvoicesettings?.discount_percentage) !== 0 ? createinvoicesettings?.discount_percentage : 0);
                validation.setFieldValue("discount_amount", (createinvoicesettings?.discount_amount) !== 0 ? createinvoicesettings?.discount_amount : 0);
                validation.setFieldValue("is_company_logo_enable", (createinvoicesettings?.is_company_logo_enable) == 1 ? true : false);
                validation.setFieldValue("is_payment_terms_enable", (createinvoicesettings?.is_payment_terms_enable) == 1 ? true : false);
                validation.setFieldValue("is_payment_due_enable", (createinvoicesettings?.is_payment_due_enable) == 1 ? true : false);
                validation.setFieldValue("is_invoice_period_enable", (createinvoicesettings?.is_invoice_period_enable) == 1 ? true : false);
                validation.setFieldValue("is_candidate_name_enable", (createinvoicesettings?.is_candidate_name_enable) == 1 ? true : false);
                validation.setFieldValue("is_job_title_enable", (createinvoicesettings?.is_job_title_enable) == 1 ? true : false);
                validation.setFieldValue("is_service_enable", (createinvoicesettings?.is_service_enable) == 1 ? true : false);
                validation.setFieldValue("is_discount_percentage_enable", (createinvoicesettings?.is_discount_percentage_enable) == 1 ? true : false);
                validation.setFieldValue("is_discount_amount_enable", (createinvoicesettings?.is_discount_amount_enable) == 1 ? true : false);
                validation.setFieldValue("is_footer_enable", (createinvoicesettings?.is_footer_enable) == 1 ? true : false);
                validation.setFieldValue("footer_description", createinvoicesettings?.footer_description);
            }
            dispatch(changePreloader(false));
        }
    }, [createinvoicesettings]);

    const validateform = () => {
        if (_.size(validation.errors) > 0) {
            const errorObj = { "response": { "status": "400", "data": { "message": "Error - Please fill required form fields" } } }
            dispatch(setAlertMessage({ message: errorObj, type: "danger" }));
            validation.setFieldValue(
                "invoice_action", ""
            );
        }
    }
    let title = invoiceId ? 'Update Invoice' : 'Create Invoice';
    //meta title
    document.title = title + " | Immidock ";

    return (
        <React.Fragment>
            <>
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">{title}</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Invoices</a></li>
                                            <li className="breadcrumb-item active">Invoice Detail</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <FormikProvider value={validation}>
                            <Form className="needs-validation"
                                onSubmit={(values, { validate }) => {
                                    values.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <div className="row">
                                    <div className="col-3">

                                        <div className="card">
                                            <div className="card-body">

                                                <Nav pills className="navtab-bg nav-justified">
                                                    <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: activeTab1 === "invoice",
                                                            })}
                                                            onClick={() => {
                                                                toggle1("invoice");
                                                            }}
                                                        >
                                                            Invoice
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: activeTab1 === "settings",
                                                            })}
                                                            onClick={() => {
                                                                toggle1("settings");
                                                            }}
                                                        >
                                                            Settings
                                                        </NavLink>
                                                    </NavItem>

                                                </Nav>

                                                <TabContent activeTab={activeTab1} className="text-muted">
                                                    <TabPane tabId="invoice">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <Col md="12">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="customer_id">Bill To *</Label>
                                                                        <Input
                                                                            name="customer_id"
                                                                            placeholder="Bill To"
                                                                            type="select"
                                                                            className="form-control"
                                                                            id="customer_id"
                                                                            // onChange={validation.handleChange}
                                                                            onChange={(e) => {
                                                                                var data = [];
                                                                                if (e.target[e.target.selectedIndex].getAttribute('data-new') != '') {
                                                                                    data = JSON.parse(e.target[e.target.selectedIndex].getAttribute('data-new'));
                                                                                }
                                                                                setBilledTo(data);
                                                                                validation.handleChange(e);
                                                                                validation.setFieldValue("customer_id", e.target.value);
                                                                                handleInvoiceTimesheets();
                                                                            }}
                                                                            onBlur={validation.handleBlur}
                                                                            value={validation?.values?.customer_id || ""}
                                                                            invalid={
                                                                                validation.touched.customer_id && validation.errors.customer_id ? true : false
                                                                            }
                                                                        >  <option value="" data-new="">Select Customer</option>
                                                                            {customers && customers.map((customer_detail, index) => (
                                                                                <option key={customer_detail.id + customer_detail.cmp_name + index} value={customer_detail.id} data-new={JSON.stringify(customer_detail)}>
                                                                                    {customer_detail.cmp_name}
                                                                                </option>
                                                                            ))}
                                                                        </Input>
                                                                        {validation.touched.customer_id && validation.errors.customer_id ? (
                                                                            <FormFeedback type="invalid">{validation.errors.customer_id}</FormFeedback>
                                                                        ) : null}
                                                                    </FormGroup>
                                                                </Col>


                                                                <Col md="12">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="employee_id">Beneficiary *</Label>
                                                                        <Input
                                                                            name="employee_id"
                                                                            placeholder="Beneficiary"
                                                                            type="select"
                                                                            className="form-control"
                                                                            id="employee_id"
                                                                            onChange={(e) => {
                                                                                var data = [];
                                                                                if (e.target[e.target.selectedIndex].getAttribute('data-new') != '') {
                                                                                    data = JSON.parse(e.target[e.target.selectedIndex].getAttribute('data-new'));
                                                                                }
                                                                                validation.handleChange(e);
                                                                                validation.setFieldValue("emp_name", data?.first_name + " " + data?.last_name)
                                                                                validation.setFieldValue("job_title", data?.job_title)
                                                                                validation.setFieldValue("employee_id", e.target.value)
                                                                                handleInvoiceTimesheets();
                                                                            }}
                                                                            onBlur={validation.handleBlur}
                                                                            value={validation?.values?.employee_id || ""}
                                                                            invalid={
                                                                                validation.touched.employee_id && validation.errors.employee_id ? true : false
                                                                            }
                                                                        >  <option value="" data-new="">Select Beneficiary</option>
                                                                            {employercontacts.map((contact, index) => (
                                                                                <option key={index + "-employee"} value={contact.employee_id} data-new={JSON.stringify(contact)}>
                                                                                    {contact.first_name} {contact.last_name}
                                                                                </option>
                                                                            ))}
                                                                        </Input>
                                                                        {validation.touched.employee_id && validation.errors.employee_id ? (
                                                                            <FormFeedback type="invalid">{validation.errors.employee_id}</FormFeedback>
                                                                        ) : null}
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md="12">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="invoice_date">Invoice Date *</Label>
                                                                        <ReactDatePicker
                                                                            autoComplete="off"
                                                                            showYearDropdown={true}
                                                                            showIcon
                                                                            placeholderText="MM/dd/yyyy"
                                                                            className={`form-control ${validation.touched.invoice_date && validation.errors.invoice_date ? 'is-invalid' : ''}`}
                                                                            id="invoice_date"
                                                                            name="invoice_date"
                                                                            dateFormat="MM/dd/yyyy"
                                                                            onChange={(date) => {
                                                                                validation.setFieldValue('invoice_date', date);
                                                                                handleInvoiceTimesheets();
                                                                            }
                                                                            }
                                                                            selected={validation?.values?.invoice_date ? new Date(validation?.values?.invoice_date) : ''}

                                                                            onBlur={validation.handleBlur}
                                                                            value={validation?.values?.invoice_date || ""}
                                                                            invalid={
                                                                                validation.touched.invoice_date && validation.errors.invoice_date ? true : false
                                                                            }
                                                                        />

                                                                        {validation.touched.invoice_date && validation.errors.invoice_date ? (
                                                                            <FormFeedback type="invalid">{validation.errors.invoice_date}</FormFeedback>
                                                                        ) : null}
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md="12">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="invoice_from_date">Invoice From Date *</Label>

                                                                        <FormGroup className="mb-3">
                                                                            <ReactDatePicker
                                                                                autoComplete="off"
                                                                                showYearDropdown={true}
                                                                                showIcon
                                                                                placeholderText="MM/dd/yyyy"
                                                                                className={`form-control ${validation.touched.invoice_from_date && validation.errors.invoice_from_date ? 'is-invalid' : ''}`}
                                                                                id="invoice_from_date"
                                                                                name="invoice_from_date"
                                                                                dateFormat="MM/dd/yyyy"
                                                                                onChange={(date) => {
                                                                                    validation.values.invoice_from_date = date;
                                                                                    validation.setFieldValue('invoice_from_date', date);
                                                                                    handleInvoiceTimesheets();
                                                                                }
                                                                                }
                                                                                selected={validation?.values?.invoice_from_date ? new Date(validation?.values?.invoice_from_date) : ''}

                                                                                onBlur={validation.handleBlur}
                                                                                value={validation?.values?.invoice_from_date || ""}
                                                                                invalid={
                                                                                    validation.touched.invoice_from_date && validation.errors.invoice_from_date ? true : false
                                                                                }
                                                                            />

                                                                            {validation.touched.invoice_from_date && validation.errors.invoice_from_date ? (
                                                                                <FormFeedback type="invalid">{validation.errors.invoice_from_date}</FormFeedback>
                                                                            ) : null}
                                                                        </FormGroup>

                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md="12">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="invoice_to_date">Invoice To Date *</Label>
                                                                        <ReactDatePicker
                                                                            autoComplete="off"
                                                                            showYearDropdown={true}
                                                                            showIcon
                                                                            placeholderText="MM/dd/yyyy"
                                                                            className={`form-control ${validation.touched.invoice_to_date && validation.errors.invoice_to_date ? 'is-invalid' : ''}`}
                                                                            id="invoice_to_date"
                                                                            name="invoice_to_date"
                                                                            dateFormat="MM/dd/yyyy"
                                                                            onChange={(date) => {
                                                                                validation.values.invoice_to_date = date;
                                                                                validation.setFieldValue('invoice_to_date', date);
                                                                                handleInvoiceTimesheets();
                                                                            }
                                                                            }
                                                                            selected={validation?.values?.invoice_to_date ? new Date(validation?.values?.invoice_to_date) : ''}

                                                                            onBlur={validation.handleBlur}
                                                                            value={validation?.values?.invoice_to_date || ""}
                                                                            invalid={
                                                                                validation.touched.invoice_to_date && validation.errors.invoice_to_date ? true : false
                                                                            }
                                                                        />

                                                                        {validation.touched.invoice_to_date && validation.errors.invoice_to_date ? (
                                                                            <FormFeedback type="invalid">{validation.errors.invoice_to_date}</FormFeedback>
                                                                        ) : null}
                                                                    </FormGroup>
                                                                </Col>

                                                                {/* <div className="mb-3">
                                                            <Label htmlFor="example-date-input" className="form-Label">Invoice Period</Label>
                                                            <Input className="form-control" type="date" defaultValue="10/02/2023" id="example-date-input" />
                                                        </div> */}

                                                                <Col md="12">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="invoice_due_date">Invoice Due Date *</Label>
                                                                        <ReactDatePicker
                                                                            autoComplete="off"
                                                                            showYearDropdown={true}
                                                                            showIcon
                                                                            placeholderText="MM/dd/yyyy"
                                                                            className={`form-control ${validation.touched.invoice_due_date && validation.errors.invoice_due_date ? 'is-invalid' : ''}`}
                                                                            id="invoice_due_date"
                                                                            name="invoice_due_date"
                                                                            dateFormat="MM/dd/yyyy"
                                                                            onChange={(date) => {
                                                                                validation.setFieldValue('invoice_due_date', date);
                                                                                handleInvoiceTimesheets();
                                                                            }
                                                                            }
                                                                            selected={validation?.values?.invoice_due_date ? new Date(validation?.values?.invoice_due_date) : ''}

                                                                            onBlur={validation.handleBlur}
                                                                            value={validation?.values?.invoice_due_date || ""}
                                                                            invalid={
                                                                                validation.touched.invoice_due_date && validation.errors.invoice_due_date ? true : false
                                                                            }
                                                                        />

                                                                        {validation.touched.invoice_due_date && validation.errors.invoice_due_date ? (
                                                                            <FormFeedback type="invalid">{validation.errors.invoice_due_date}</FormFeedback>
                                                                        ) : null}
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col md="12">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="job_title">Job Title</Label>
                                                                        <Input
                                                                            name="job_title"
                                                                            placeholder="Job Title"
                                                                            type="text"
                                                                            className="job_title-control"
                                                                            id="service_name"
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            value={validation?.values?.job_title || ""}
                                                                            invalid={
                                                                                validation.touched.job_title && validation.errors.job_title ? true : false
                                                                            }
                                                                        />
                                                                        {validation.touched.job_title && validation.errors.job_title ? (
                                                                            <FormFeedback type="invalid">{validation.errors.job_title}</FormFeedback>
                                                                        ) : null}
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md="12">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="service_name">Service Name</Label>
                                                                        <Input
                                                                            name="service_name"
                                                                            placeholder="Service Name"
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="service_name"
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            value={validation?.values?.service_name || ""}
                                                                            invalid={
                                                                                validation.touched.service_name && validation.errors.service_name ? true : false
                                                                            }
                                                                        />
                                                                        {validation.touched.service_name && validation.errors.service_name ? (
                                                                            <FormFeedback type="invalid">{validation.errors.service_name}</FormFeedback>
                                                                        ) : null}
                                                                    </FormGroup>
                                                                </Col>

                                                                <FieldArray
                                                                    name="invoice_items"
                                                                    render={arrayHelpers => (
                                                                        <div>
                                                                            {validation?.values?.invoice_items && validation?.values?.invoice_items.map((item, index) => (
                                                                                <div key={index + "item"} className='mb-3'>

                                                                                    <div className="row">
                                                                                        <Col md="12">
                                                                                            <FormGroup className="mb-3">
                                                                                                <Input type="hidden" value={item.id ? item.id : ''} name={`invoice_items.${index}.id`}></Input>
                                                                                                <Label htmlFor={`invoice_items.${index}.description`}>
                                                                                                    <span className="col-md-11">
                                                                                                        Description *
                                                                                                    </span>
                                                                                                    {validation?.values?.invoice_items.length > 1 ? (
                                                                                                        <>
                                                                                                            <span className="col-md-1 remove-item">
                                                                                                                <>
                                                                                                                    <i className="bx bx-x bx-sm " onClick={() => arrayHelpers.remove(index)} ></i>
                                                                                                                </>
                                                                                                            </span>

                                                                                                        </>
                                                                                                    ) : ''}
                                                                                                </Label>

                                                                                                <Field type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Description Name"
                                                                                                    name={`invoice_items.${index}.description`} />

                                                                                                <ErrorMessage
                                                                                                    name={`invoice_items.${index}.description`}
                                                                                                    component="div"
                                                                                                    className="text-danger"
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </Col>

                                                                                        <Col md="6">
                                                                                            <FormGroup className="mb-3">

                                                                                                <Label htmlFor={`invoice_items.${index}.hours`}>Hours *</Label>
                                                                                                <Field type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Hours"
                                                                                                    onChange={(e) => {
                                                                                                        validation.handleChange(e);
                                                                                                    }}
                                                                                                    onBlur={validation.handleBlur}
                                                                                                    name={`invoice_items.${index}.hours`} />
                                                                                                <ErrorMessage
                                                                                                    name={`invoice_items.${index}.hours`}
                                                                                                    component="div"
                                                                                                    className="text-danger"
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                        <Col md="6">
                                                                                            <FormGroup className="mb-3">
                                                                                                <Label htmlFor={`invoice_items.${index}.rate`}>Rate *</Label>
                                                                                                <Field type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Rate"
                                                                                                    onChange={(e) => {
                                                                                                        validation.handleChange(e);
                                                                                                    }}
                                                                                                    onBlur={validation.handleBlur}
                                                                                                    name={`invoice_items.${index}.rate`} />
                                                                                                <ErrorMessage
                                                                                                    name={`invoice_items.${index}.rate`}
                                                                                                    component="div"
                                                                                                    className="text-danger"
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                            <Col md="12">
                                                                                <button
                                                                                    className="mb-3 ms-1 btn btn-primary add-more-button-right"
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.push({ description: '', hours: '0', rate: '0', id: '' })}
                                                                                >
                                                                                    + Add More
                                                                                </button>
                                                                            </Col>
                                                                        </div>
                                                                    )}
                                                                />

                                                            </div>
                                                        </div>
                                                        <Row>
                                                            <Col className="col-12">
                                                                <Dropzone
                                                                    onDrop={(acceptedFiles) => {
                                                                        handleAcceptedFiles(acceptedFiles);
                                                                    }}
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div className="dropzone">
                                                                            <div
                                                                                className="dz-message needsclick"
                                                                                {...getRootProps()}
                                                                            >
                                                                                <input {...getInputProps()} accept=".png, .jpg, .jpeg,.pdf" />
                                                                                <div className="mb-2">
                                                                                    <i className="display-4 text-muted bx bx-cloud-upload" />
                                                                                </div>
                                                                                <h4>Drop Invoice files here or click to upload.</h4>
                                                                                {/* <div className="mb-3">
                                                                                    <i className="display-6 text-muted mdi mdi-attachment" /> Add attachment
                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                                <div className="dropzone-previews mt-3" id="file-previews">
                                                                    {selectedFiles.length > 0 && selectedFiles.map((f, i) => {
                                                                        return (
                                                                            <Card
                                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                key={i + "-file"}
                                                                            >
                                                                                <div className="p-2">
                                                                                    <Row className="align-items-center">
                                                                                        <Col md="2">
                                                                                            <FormGroup className="mb-3">
                                                                                                <div className="hstack gap-3">
                                                                                                    {/* <Field type="checkbox" name="invoice_attachments" value="Two" /> */}
                                                                                                    <Input
                                                                                                        name="invoice_attachments"
                                                                                                        type="checkbox"
                                                                                                        className="form-control"
                                                                                                        // id={`validationCustom${i}`}
                                                                                                        onChange={(e) => {
                                                                                                            validation.handleChange(e);
                                                                                                        }}
                                                                                                        value={JSON.stringify(f)}
                                                                                                        // invalid={
                                                                                                        //     validation.touched.invoice_attachments && validation.errors.invoice_attachments ? true : false
                                                                                                        // }
                                                                                                        defaultChecked={f.checked === true}
                                                                                                    />
                                                                                                </div>
                                                                                                {/* {console.log("invoice_attachments", validation?.values?.invoice_attachments)} */}
                                                                                            </FormGroup>

                                                                                        </Col>
                                                                                        {/* <Col className="col-auto">
                                                                                            <img
                                                                                                data-dz-thumbnail=""
                                                                                                height="80"
                                                                                                className="avatar-sm rounded bg-light"
                                                                                                alt={f.name}
                                                                                                src={f.preview}
                                                                                            />
                                                                                        </Col> */}
                                                                                        <Col>
                                                                                            <Link
                                                                                                to="#"
                                                                                                className="text-muted font-weight-bold"
                                                                                            >
                                                                                                {f.name}
                                                                                            </Link>
                                                                                            <p className="mb-0">
                                                                                                <strong>{f.size ? formatBytes(f.size) : '-'}</strong>
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Card>
                                                                        );
                                                                    })}
                                                                </div>
                                                                {validation?.values?.attachments && validation?.values?.attachments.map((f, i) => {
                                                                    return (
                                                                        <Card
                                                                            className="mt-1 mb-0"
                                                                            key={i + "-timesheet_attach_file"}
                                                                        >
                                                                            <div className="p-2">
                                                                                <Row className="align-items-center">
                                                                                    <Col md="2">
                                                                                        <FormGroup className="mb-3">
                                                                                            <div className="hstack gap-3">
                                                                                                <Input
                                                                                                    readOnly
                                                                                                    name="attachments"
                                                                                                    type="checkbox"
                                                                                                    className="form-control"
                                                                                                    onChange={(e) => {
                                                                                                        validation.handleChange(e);
                                                                                                    }}
                                                                                                    value={JSON.stringify(f)}
                                                                                                    defaultChecked={f.checked === true}
                                                                                                />
                                                                                            </div>
                                                                                        </FormGroup>

                                                                                    </Col>

                                                                                    <Col>
                                                                                        <Link
                                                                                            to="#"
                                                                                            className="text-muted font-weight-bold"
                                                                                        >
                                                                                            {f.name}
                                                                                        </Link>
                                                                                        <p className="mb-0">
                                                                                            <strong>{f.size ? formatBytes(f.size) : '-'}</strong>
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Card>
                                                                    );
                                                                })}
                                                                |
                                                            </Col>
                                                        </Row>

                                                    </TabPane>
                                                    <TabPane tabId="settings">
                                                        <div className="row">
                                                            <div className="col-lg-12">

                                                                <div className="col-lg-12">
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_company_logo_enable"
                                                                                onChange={(e) => {
                                                                                    validation.handleChange(e);
                                                                                }}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="is_company_logo_enable"
                                                                                checked={validation?.values?.is_company_logo_enable}
                                                                            />
                                                                            <span className="font-size-16">Display Company Logo</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_payment_terms_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation?.values?.is_payment_terms_enable}
                                                                            />
                                                                            <span className="font-size-16">Payment Terms</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_payment_due_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation?.values?.is_payment_due_enable}
                                                                            />
                                                                            <span className="font-size-16">Payment Due</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_invoice_period_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation?.values?.is_invoice_period_enable}
                                                                            />
                                                                            <span className="font-size-16">Invoice Period</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_candidate_name_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation?.values?.is_candidate_name_enable}
                                                                            />
                                                                            <span className="font-size-16">Candidate Name</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_job_title_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation?.values?.is_job_title_enable}
                                                                            />
                                                                            <span className="font-size-16">Job Title</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_service_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation?.values?.is_service_enable}
                                                                            />
                                                                            <span className="font-size-16">Service</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_discount_percentage_enable"
                                                                                onChange={(e) => {
                                                                                    if (!validation?.values?.is_discount_percentage_enable == true)
                                                                                        validation.values.is_discount_amount_enable = false;
                                                                                    validation.handleChange(e);
                                                                                }}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation?.values?.is_discount_percentage_enable}
                                                                            />
                                                                            <span className="font-size-16">Provide Discount %</span>
                                                                        </div>
                                                                    </div>
                                                                    {validation?.values?.is_discount_percentage_enable && (
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="validationCustom01">Discount Percentage</Label>
                                                                            <Input
                                                                                name="discount_percentage"
                                                                                placeholder="Discount Percentage"
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="validationCustom01"
                                                                                onChange={validation.handleChange}
                                                                                onBlur={validation.handleBlur}
                                                                                value={validation?.values?.discount_percentage || 0}
                                                                                invalid={
                                                                                    validation.touched.discount_percentage && validation.errors.discount_percentage ? true : false
                                                                                }
                                                                            />
                                                                            {validation.touched.discount_percentage && validation.errors.discount_percentage ? (
                                                                                <FormFeedback type="invalid">{validation.errors.discount_percentage}</FormFeedback>
                                                                            ) : null}
                                                                        </FormGroup>
                                                                    )}
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_discount_amount_enable"
                                                                                onChange={(e) => {
                                                                                    if (!validation?.values?.is_discount_amount_enable == true)
                                                                                        validation.values.is_discount_percentage_enable = false;
                                                                                    validation.handleChange(e);
                                                                                }}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation?.values?.is_discount_amount_enable}
                                                                            />
                                                                            <span className="font-size-16">Provide Discount $</span>
                                                                        </div>
                                                                    </div>

                                                                    {validation?.values?.is_discount_amount_enable && (
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="discount_amount">Discount Amount</Label>
                                                                            <Input
                                                                                name="discount_amount"
                                                                                placeholder="Discount Amount"
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="discount_amount"
                                                                                onChange={validation.handleChange}
                                                                                onBlur={validation.handleBlur}
                                                                                value={validation?.values?.discount_amount || 0}
                                                                                invalid={
                                                                                    validation.touched.discount_amount && validation.errors.discount_amount ? true : false
                                                                                }
                                                                            />
                                                                            {validation.touched.discount_amount && validation.errors.discount_amount ? (
                                                                                <FormFeedback type="invalid">{validation.errors.discount_amount}</FormFeedback>
                                                                            ) : null}
                                                                        </FormGroup>
                                                                    )}
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_footer_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation?.values?.is_footer_enable}
                                                                            />
                                                                            <span className="font-size-16">Footer</span>
                                                                        </div>
                                                                    </div>
                                                                    {validation?.values?.is_footer_enable && (
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="example-text-input" className="form-Label">Footer Description</Label>
                                                                            <Input
                                                                                rows={5}
                                                                                type="textarea"
                                                                                id="footer_description"
                                                                                name="footer_description"
                                                                                value={validation.values.footer_description || ""}
                                                                                onChange={(e) => {
                                                                                    validation.handleChange(e);
                                                                                }}
                                                                            ></Input>&nbsp;
                                                                        </div>
                                                                    )}
                                                                    {/* <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_color_theme_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation?.values?.is_color_theme_enable}
                                                                            />
                                                                            <span className="font-size-16">Color Theme</span>
                                                                        </div>
                                                                    </div> */}


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </TabPane>
                                                </TabContent>
                                                {/* 
                                                <Row className="justify-content-middle">
                                                    <Col sm={12}>
                                                        <FormGroup className="mb-0">
                                                            <div>
                                                                <Button type="submit" color="primary" className="ms-1">
                                                                    Save
                                                                </Button>{" "}
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row> */}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-9">
                                        <div className="row">
                                            <Col md="9"></Col>
                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Input
                                                        name="invoice_action"
                                                        placeholder="More Actions"
                                                        type="select"
                                                        className="form-control"
                                                        id="invoice_action"
                                                        onChange={(e) => {
                                                            validation.handleChange(e);
                                                            if (e.target.value == "saveandsend") {
                                                                setSendInvoice(true);
                                                            } else {
                                                                setSendInvoice(false);
                                                            }
                                                            handleActionSubmit(e);
                                                            validateform();
                                                        }}
                                                        value={validation?.values?.invoice_action || ""}
                                                    >
                                                        <option value="">More Actions</option>
                                                        {moreactions.map((moreaction, index) => (
                                                            <option key={moreaction.action + index} value={moreaction.action}>
                                                                {moreaction.label}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                    {validation.touched.invoice_action && validation.errors.invoice_action ? (
                                                        <FormFeedback type="invalid">{validation.errors.invoice_action}</FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">

                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="invoice-title">
                                                            <div className="d-flex align-items-start">
                                                                <div className="flex-grow-1">
                                                                    <div className="mb-4">
                                                                        {validation?.values?.is_company_logo_enable && invoiceDetail?.employer_details?.company_logo && (
                                                                            <span className="logo-sm">
                                                                                <img src={invoiceDetail?.employer_details?.company_logo} alt="" width="150" height="45" />
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <div className="mb-4">
                                                                        <h4 className="font-size-16">Invoice # {validation?.values?.invoice_number ? validation?.values?.invoice_number : 'INV000XXXX'}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h5>{employercompanyDetail?.company_name}</h5>
                                                            <p className="mb-1">{employercompanyDetail?.current_city}, {employercompanyDetail?.current_state}, {employercompanyDetail?.current_country}, {employercompanyDetail?.current_zipcode}</p>
                                                            <p className="mb-1"><i className="mdi mdi-email align-middle me-1"></i> {employercompanyDetail?.email}</p>
                                                            <p><i className="mdi mdi-phone align-middle me-1"></i> {employercompanyDetail?.phone}</p>
                                                        </div>
                                                        <hr className="my-4"></hr>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <div>
                                                                    <h5 className="font-size-15 mb-3">Billed To:</h5>
                                                                    {(Object.keys(billedTo).length > 0) && (
                                                                        <>
                                                                            <h5 className="font-size-14 mb-2">{billedTo.cmp_name || ""}</h5>
                                                                            <p className="mb-1">{billedTo.inv_email || ""}</p>
                                                                            <p className="mb-1">{billedTo.cmp_street || ""} {billedTo.state || ""} {billedTo.cmp_city || ""} {billedTo.cmp_country || ""} {billedTo.zipcode || ""}</p>
                                                                            <p>{billedTo.cmp_phone || ""}</p>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div>
                                                                    {validation?.values?.is_candidate_name_enable && (
                                                                        <div>
                                                                            <h5 className="font-size-15">Candidate Name:
                                                                                <span className="text-muted"> {" "}  {validation?.values?.emp_name}</span>
                                                                            </h5>
                                                                        </div>
                                                                    )}

                                                                    {validation?.values?.is_job_title_enable && (
                                                                        <div>
                                                                            <h5 className="font-size-15">Job Title:
                                                                                <span className="text-muted"> {" "} {validation?.values?.job_title}</span>
                                                                            </h5>
                                                                        </div>
                                                                    )}

                                                                    {validation?.values?.is_service_enable && (
                                                                        <div>
                                                                            <h5 className="font-size-15">Service :
                                                                                <span className="text-muted"> {" "} {validation?.values?.service_name}</span>
                                                                            </h5>
                                                                        </div>
                                                                    )}

                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div>
                                                                    <div>
                                                                        <h5 className="font-size-15">Invoice Date  :
                                                                            {(validation?.values?.invoice_date != '') && (
                                                                                <span className="text-muted"> {" "}  {getDateFormatDMonY(validation?.values?.invoice_date)}</span>
                                                                            )}
                                                                        </h5>
                                                                    </div>

                                                                    {validation?.values?.is_payment_due_enable && (
                                                                        <div className="">
                                                                            <h5 className="font-size-15">Payment Due:
                                                                                {(validation?.values?.invoice_due_date != '') && (
                                                                                    <span className="text-muted"> {" "} {getDateFormatDMonY(validation?.values?.invoice_due_date)}</span>
                                                                                )}
                                                                            </h5>
                                                                        </div>
                                                                    )}

                                                                    {validation?.values?.is_invoice_period_enable && (

                                                                        <div>
                                                                            <h5 className="font-size-15">Invoice Period :
                                                                                {(validation?.values?.invoice_from_date != '' && validation?.values?.invoice_to_date != '') && (
                                                                                    <span className="text-muted"> {" "}  {getDateFormatDMonY(validation?.values?.invoice_from_date)} - {getDateFormatDMonY(validation?.values?.invoice_to_date)}</span>
                                                                                )}
                                                                            </h5>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="py-2 mt-3">
                                                            <h5 className="font-size-15">Order summary</h5>
                                                        </div>
                                                        <div className="p-4 border rounded">
                                                            <div className="table-responsive">
                                                                <table className="table table-nowrap align-middle mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ width: "70px" }}>S.No.</th>
                                                                            <th className="text-start" style={{ width: "120px" }}>Description</th>
                                                                            <th className="text-end" style={{ width: "120px" }}>Hours</th>
                                                                            <th className="text-end" style={{ width: "120px" }}>Rate</th>
                                                                            <th className="text-end" style={{ width: "120px" }}>Amount</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {validation?.values?.invoice_items && validation?.values?.invoice_items.map((item, index) => (
                                                                            <tr key={index + "itemdisplay"}>
                                                                                <th scope="row">{index + 1}</th>
                                                                                <td>
                                                                                    <h5 className="font-size-15 mb-1">{item.description}</h5>
                                                                                    {/* <p className="font-size-13 text-muted mb-0">item desc </p> */}
                                                                                </td>
                                                                                <td className="text-end">{item.hours}</td>
                                                                                <td className="text-end">{getCurrencyFormat(item.rate)}</td>
                                                                                <td className="text-end">{(item.hours && item.rate) ? getCurrencyFormat(item.hours * item.rate) : ""}</td>
                                                                            </tr>
                                                                        ))}


                                                                        <tr>
                                                                            <th scope="row" colSpan="4" className="text-end">Sub Total</th>
                                                                            <td className="text-end">
                                                                                {getCurrencyFormat(total())}
                                                                            </td>
                                                                        </tr>
                                                                        {(validation?.values?.is_discount_percentage_enable || validation?.values?.is_discount_amount_enable) && (
                                                                            <tr>
                                                                                <td scope="row" colSpan="4" className="text-end">Discount {validation?.values?.is_discount_percentage_enable ? "(" + validation?.values?.discount_percentage + "%)" : ""}</td>
                                                                                < td className="text-end">
                                                                                    {getCurrencyFormat(calculateddiscount())}
                                                                                </td>
                                                                            </tr>
                                                                        )}

                                                                        {/* validation?.values?.is_payment_terms_enable ? 'ues':'sxxxxxd' */}
                                                                        {/* <tr>
                                                                    <th scope="row" colSpan="4" className="border-0 text-end">
                                                                        Tax</th>
                                                                    <td className="border-0 text-end">$12.00</td>
                                                                </tr> */}
                                                                        <tr>
                                                                            <th scope="row" colSpan="4" className="border-0 text-end">Total</th>
                                                                            <td className="border-0 text-end"><h4 className="m-0">{getCurrencyFormat(grandtotal())}</h4></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        {validation?.values?.is_footer_enable && (
                                                            <div className="py-2 mt-3">
                                                                <h5 className="font-size-15">Notes:</h5>
                                                                <p><div dangerouslySetInnerHTML={{ __html: validation?.values?.footer_description }} /></p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>



                            </Form>
                        </FormikProvider>
                    </div>
                </div>
            </>
        </React.Fragment >
    );
};

export default CreateInvoice;