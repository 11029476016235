import React, { useEffect, useMemo, useState } from "react";
import DatatableContainer from "../../../components/Common/DatatableContainer";
import {
  Link, useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Progress,
} from "reactstrap";

import { getEmployeeCases as getEmployeeCases, deleteEmployeeCase, setAlertFlagInfo, getEmployeeStates, getEmployeeCountrys } from "../../../store/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  CommonCell, Date, CaseTypeName
} from "./caseTypeListCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";

const CaseTypesList = () => {

  //meta title
  document.title = "Cases | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [order, setOrder] = useState(null);

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);

  const { employeecase, countrys } = useSelector((state) => ({
    employeecase: state.EmployeeCases.employeecase || [],
    countrys: state.EmployeeCases.countrys || [],
  }));

  useEffect(() => {
    dispatch(getEmployeeCases());
  }, [dispatch]);


  useEffect(() => {
    dispatch(getEmployeeCountrys());
  }, []);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {
    navigate(`/admin/add-case-types`);
  };

  const onUpdateData = (data) => {
    navigate(`/admin/add-case-types?id=${data.id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };

  const handleDeleteInvoice = () => {
    if (order.id) {
      dispatch(CasetypeDelete(order.id));
      setDeleteModal(false);
    }
  };

  const handleQuestionaireClick = (data) => {
    //console.log('data',data);
    navigate(`/employee/questionnaire?caseId=${data.case_id}&caseNumber=${data.case_number}`);
  };
  const handleQuestionaireDocuments = (data) => {
    navigate(`/employee/documents?caseId=${data.case_id}`);
  };
  const handleQuestionaireForms = (data) => {
    navigate(`/employee/forms?caseId=${data.case_id}`);
  };
  const handleQuestionaireMessages = (data) => {
    navigate(`/employee/messages?caseId=${data.case_id}`);
  };
  let title = 'Cases';
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/employee/dashboard' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteInvoice}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>

            {employeecase.map((item) => (

              <Col lg="6" key={item.case_number}>
                <Card className="text-center">
                  <CardHeader className="bg-transparent">
                    <h5 className="my-0 text-primary"><i className="mdi mdi-briefcase me-3"></i>{item.qnnr_type}</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg="6" className="mb-3 text-end w-50">
                        <div className="d-flex flex-column font-size-15 text-dark">Case Number: </div>
                        <div className="d-flex flex-column font-size-15 text-dark">Reciept Number: </div>
                        <div className="d-flex flex-column font-size-15 text-dark">Created Date: </div>
                        <div className="d-flex flex-column font-size-15 text-dark">Case Status: </div>
                        <div className="d-flex flex-column font-size-15 text-dark">Shipment Tracking: </div>
                        <div className="d-flex flex-column font-size-15 text-dark">Approved Until: </div>
                      </Col>
                      <Col lg="6" className="mb-3 text-start w-50">
                        <div className="d-flex flex-column font-size-15 text-secondary">{item.case_number}</div>
                        <div className="d-flex flex-column font-size-15 text-secondary">NA</div>
                        <div className="d-flex flex-column font-size-15 text-secondary">{item.created_at}</div>
                        <div className="d-flex flex-column font-size-15 text-secondary">{item.processing_status}</div>
                        <div className="d-flex flex-column font-size-15 text-secondary">NA</div>
                        <div className="d-flex flex-column font-size-15 text-secondary">NA</div>
                      </Col>
                      <Col lg="11" className="mb-4 text-center">
                        <div className="custom-progess m-4">
                          <Progress
                            value={50}
                            color="primary"
                            style={{ height: '1px' }}
                          ></Progress>
                          <div className="position-absolute top-0 start-0">
                            <button className="translate-middle btn btn-sm btn-primary rounded-pill" style={{ width: "2rem", height: "2rem" }}>
                              <i className="bx bx-check"></i>
                            </button>
                            <div className="text-dark" style={{ "margin-top": "-20px" }}>Initiated</div>
                          </div>
                          <div className="position-absolute top-0 start-50">
                            <button className=" translate-middle btn btn-sm btn-primary rounded-pill" style={{ width: "2rem", height: "2rem" }}>
                              <i className="bx bx-check"></i>
                            </button>
                            <div className="text-dark text-center" style={{ "margin-top": "-20px" }}>Filed</div>
                          </div>
                          <div className="position-absolute top-0 start-100">
                            <button className="translate-middle btn btn-sm btn-secondary rounded-pill" style={{ width: "2rem", height: "2rem" }}>
                              <i className="bx bx-x"></i>
                            </button>
                            <div className="text-dark text-end" style={{ "margin-top": "-20px" }}>Approved</div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <a className="btn btn-primary waves-effect waves-light" onClick={() => handleQuestionaireClick(item)}>Questionnaire</a>
                    <a className="btn btn-primary waves-effect waves-light" onClick={() => handleQuestionaireDocuments(item)} style={{ "margin-left": "5px" }}>Documents</a>
                    <a className="btn btn-primary waves-effect waves-light" onClick={() => handleQuestionaireForms(item)} style={{ "margin-left": "5px" }}>Forms</a>
                    <a className="btn btn-primary waves-effect waves-light" onClick={() => handleQuestionaireMessages(item)} style={{ "margin-left": "5px" }}>Messages</a>
                  </CardBody>
                </Card>
              </Col>

            ))}

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CaseTypesList;
