import React from 'react';
import Label from "./Label";
import {
    FormGroup, Input
} from "reactstrap";
import { checkValidityClass } from "../Shared/utility";
const InputType = ({ name, label, type, placeholder, className, required, invalidMessage, value, content, onChanged }) => {



    return (
        <FormGroup className="mb-3">
            <Label
                label={content.label}
                name={content.name}
                isRequired={content.required}
            />
            <Input
                name={content.name}
                type={content.type}
                className={`${checkValidityClass(invalidMessage)} ${className ? 'form-control is-invalid' : 'form-control'}`}
                id={content.name}
                placeholder={content.placeholder}
                required={content.required}
                value={value}
                aria-invalid="true"
                aria-describedby={`invalid_${content.name}`}
                onChange={onChanged}
                minLength={content?.minlength ?? ''}
                maxLength={content?.maxlength ?? ''}
            />
            <div type="invalid" className="invalid-feedback">{invalidMessage}</div>
        </FormGroup>
    );
};


export default InputType