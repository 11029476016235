import React, { useState, useEffect } from 'react';

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input, FormGroup, Button, FormFeedback,
  Nav,
  NavItem,
  NavLink, TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik, Field, FieldArray, FormikProvider, ErrorMessage } from "formik";
import { updateEmployeeJobDetails, getEmployerContactDetail, createCustomer, getCustomers } from "../../../../store/actions";
import { useAlert } from "../../../../components/Common/Alert";
import { useNavigate, useLocation } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import { getDateFormatYMD } from '../../../../common/data/dateFormat';


export const Job = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  document.title = "E | Immidock ";
  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id');
  const [sendInvoice, setSendInvoice] = useState(true)
  const [showCreateVendor, setShowCreateVendor] = useState(false);

  const { employercontactDetail } = useSelector((state) => ({
    employercontactDetail: state.EmployerContacts.employercontactDetail,
  }));

  const { customers } = useSelector((state) => ({
    customers: state.Customers.customers,
    //customerDetail: state.Customers.customerDetail,
  }));

  useEffect(() => {
    //dispatch(getCustomers({ status: activeTab }));
    dispatch(getCustomers({ status: 'All' }));
  }, [dispatch]);

  // const onReloadData = (event) => {
  //   var tabInfo = event.target.value;
  //   setactiveTab(tabInfo);
  //   dispatch(getCustomers({ status: tabInfo }));
  // }

  const moreactions = [{ 'label': 'Save', 'action': 'saveandsend' }]
  const handleActionSubmit = (e) => {
    // setSendInvoice(true)
    validation.handleSubmit();
  };
  // Form validation 
  var empJobDetails = [];
  if (_.isEmpty(employercontactDetail.personJobDetails)) {
    empJobDetails.push({
      job_id: '', work_location: '', client_id: '', vendor_id: '',
      report_to: '', job_start_date: '', job_end_date: ''
    })
  } else {
    employercontactDetail.personJobDetails.map((item, index) => {
      empJobDetails.push({
        job_id: item.id, work_location: item.work_location, client_id: item.client_id, vendor_id: item.vendor_id,
        report_to: item.report_to, job_start_date: item.job_start_date, job_end_date: item.job_end_date
      })
    })
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      employee_id: employercontactDetail.employee_id,
      employee_id_number: employercontactDetail.employee_id_number,
      emp_type: employercontactDetail.emp_type,
      status: employercontactDetail.status,
      job_title: employercontactDetail.job_title,
      hire_date: employercontactDetail.hire_date,
      job_start_date: employercontactDetail.job_start_date,
      personJobDetails: empJobDetails
      //assignment_details: [{ job_id: '', work_location: '', client_id: '', vendor_id: '', report_to: '', job_start_date: '', job_end_date: '' }],
    },
    validationSchema: Yup.object({
      employee_id_number: Yup.string().required("Please Enter Beneficiary ID").nullable(),
      emp_type: Yup.string().required("Please Select Employement Type"),
      status: Yup.string().required("Please Select Employement Status"),
      //job_title: Yup.string().required("Please Enter Job Title"),
      //hire_date: Yup.string().required("Please Select Hire Date"),
    }),
    onSubmit: (values) => {
      //if (values.employerEmployee_action) {
      dispatch(updateEmployeeJobDetails({ ...values, hire_date: getDateFormatYMD(values.hire_date), job_start_date: getDateFormatYMD(values.job_start_date), job_end_date: getDateFormatYMD(values.job_end_date), job_start_date: getDateFormatYMD(values.job_start_date) }));
      //}
      //navigate("/employer/employeelist");

    }
  });

  // Form validation 
  const addVendorvalidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      create_vendor_name: '',
    },
    validationSchema: Yup.object({
      create_vendor_name: Yup.string().required("Please Enter Vendor name"),
    }),
    onSubmit: (values) => {
      dispatch(createCustomer({ cmp_name: addVendorvalidation.values.create_vendor_name }));
      setShowCreateVendor(false);
      setTimeout(() => {
        dispatch(getEmployerContactDetail({ employee_id: editId }));
      }, 2000);
      //  setMoreActions('');
      addVendorvalidation.resetForm();

    }
  });

  const createNewContact = (e) => {
    if (e.target.value === "createnewcontact") {
      //setShowCreateVendor(true);
      navigate("/employer/create-customer?createCustomer=fromJob&employeeId=" + editId);
    }
  }

  return (
    <React.Fragment>
      <div className="">
        <Modal isOpen={showCreateVendor}>
          <ModalHeader toggle={() => { addVendorvalidation.resetForm(); setShowCreateVendor(false) }} tag="h4">
            Add Vendor
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                addVendorvalidation.handleSubmit();
                return false;
              }}
            >
              <Row form>
                <Col className="col-12 mb-3">
                  <Label className="form-label">Vendor Name *</Label>
                  <Input
                    name="create_vendor_name"
                    type="text"
                    placeholder="Enter Input"
                    onChange={addVendorvalidation.handleChange}
                    onBlur={addVendorvalidation.handleBlur}
                    //value={addVendorvalidation.values.create_vendor_name || ""}
                    invalid={
                      addVendorvalidation.touched.create_vendor_name &&
                        addVendorvalidation.errors.create_vendor_name
                        ? true
                        : false
                    }
                  />
                  {addVendorvalidation.touched.create_vendor_name &&
                    addVendorvalidation.errors.create_vendor_name ? (
                    <FormFeedback type="invalid">
                      {addVendorvalidation.errors.create_vendor_name}
                    </FormFeedback>
                  ) : null}
                </Col>

              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="btn btn-primary save-event"
                    >
                      Create
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>

        <Row>

          <Col xs={12}>
            <Card>

              <CardBody className="p-4">
                <div className="row">
                  <Col md="9"></Col>
                  <Col md="3">
                    {/* <FormGroup className="mb-3">
                <Input
                  name="employerEmployee_action"
                  placeholder="More Actions"
                  type="select"
                  className="form-control"
                  id="employerEmployee_action"
                  onChange={(e) => {
                    validation.handleChange(e);
                    if (e.target.value == "saveandsend") {
                      setSendInvoice(true);
                    }
                    handleActionSubmit(e);
                  }}
                  value={validation.values.employerEmployee_action || ""}
                >
                  <option value="">More Actions</option>
                  {moreactions.map((moreaction, index) => (
                    <option key={moreaction.action + index} value={moreaction.action}>
                      {moreaction.label}
                    </option>
                  ))}
                </Input>
              </FormGroup> */}
                  </Col>

                </div>
                <FormikProvider value={validation}>
                  <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >

                    {/* import TextualInputs */}
                    <div className="mt-4 mt-lg-0">
                      <h4>Employment Details</h4><br />
                      <Row>


                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="employee_id_number">Beneficiary ID *</Label>
                            <Input
                              name="employee_id_number"
                              placeholder="Employment ID"
                              type="text"
                              className="form-control"
                              id="employee_id_number_number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.employee_id_number || ""}
                              invalid={
                                validation.touched.employee_id_number && validation.errors.employee_id_number ? true : false
                              }
                            />
                            {validation.touched.employee_id_number && validation.errors.employee_id_number ? (
                              <FormFeedback type="invalid">{validation.errors.employee_id_number}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor='emp_type'>Employment Type *</Label>
                            <Input
                              name="emp_type"
                              placeholder="Employment Type"
                              type="select"
                              className="form-control"
                              id="emp_type"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.emp_type && validation.errors.emp_type ? true : false
                              }
                            >
                              <option value="">Select employment type</option>
                              <option selected={validation.values.emp_type == "Full Time"} value="Full Time">Full Time</option>
                              <option selected={validation.values.emp_type == "Part Time"} value="Part Time">Part Time</option>
                              <option selected={validation.values.emp_type == "Internship"} value="Internship">Internship</option>
                              <option selected={validation.values.emp_type == "1099"} value="1099">1099</option>
                              <option selected={validation.values.emp_type == "C2C"} value="C2C">C2C</option>
                            </Input>


                            {validation.touched.emp_type && validation.errors.emp_type ? (
                              <FormFeedback type="invalid">{validation.errors.emp_type}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="status">Employement Status *</Label>
                            <Input
                              name="status"
                              placeholder="Employment Status"
                              type="select"
                              className="form-control"
                              id="status"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.status || ""}
                              invalid={
                                validation.touched.status && validation.errors.status ? true : false
                              }
                            >  <option value="">Select Status</option>
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                            </Input>


                            {validation.touched.status && validation.errors.status ? (
                              <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="job_title">Job Title</Label>
                            <Input
                              name="job_title"
                              placeholder="Job Title"
                              type="text"
                              className="form-control"
                              id="job_title"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.job_title || ""}
                              invalid={
                                validation.touched.job_title && validation.errors.job_title ? true : false
                              }
                            />
                            {validation.touched.job_title && validation.errors.job_title ? (
                              <FormFeedback type="invalid">{validation.errors.job_title}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4">

                          <FormGroup className="mb-3">
                            <Label htmlFor="hire_date">Hire Date</Label>
                            <ReactDatePicker
                              showYearDropdown={true}
                              showIcon
                              placeholderText="MM/dd/yyyy"
                              className="form-control"
                              id="hire_date"
                              name="hire_date"
                              dateFormat="MM/dd/yyyy"
                              onChange={(date) => {
                                validation.setFieldValue('hire_date', date)
                              }
                              }
                              selected={validation?.values?.hire_date ? new Date(validation.values.hire_date) : ''}
                            />

                            {validation.touched.hire_date && validation.errors.hire_date ? (
                              <FormFeedback type="invalid">{validation.errors.hire_date}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="job_start_date">Start Date</Label>
                            <ReactDatePicker
                              showYearDropdown={true}
                              showIcon
                              placeholderText="MM/dd/yyyy"
                              className="form-control"
                              id="job_start_date"
                              name="job_start_date"
                              dateFormat="MM/dd/yyyy"
                              onChange={(date) => {
                                validation.setFieldValue('job_start_date', date)
                              }
                              }
                              selected={validation?.values?.job_start_date ? new Date(validation.values.job_start_date) : ''}
                            />
                            {validation.touched.job_start_date && validation.errors.job_start_date ? (
                              <FormFeedback type="invalid">{validation.errors.job_start_date}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>

                      </Row>

                    </div>
                    <div className="mt-4 mt-lg-0">
                      <br /><h4>Assignment Details</h4><br />
                      <FieldArray
                        name="personJobDetails"
                        render={arrayHelpers => (
                          <div>
                            {validation.values.personJobDetails.map((item, index) => (
                              <div key={index + "item"} className='more-item-box'>
                                <div className="remove-item">
                                  <Col sm={1}>
                                    {validation.values.personJobDetails.length > 1 ? (
                                      <>
                                        <i className="bx bx-x bx-sm" onClick={() => arrayHelpers.remove(index)} ></i>
                                      </>
                                    ) : ''}
                                  </Col>
                                </div>
                                <div className="row more-items">
                                  {/* <Input 
                                  id="job_id"
                                  type="text"
                                  name={`item.${index}.job_id`}
                                  readOnly
                                >
                                </Input> */}
                                  <Col md="4">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor={`personJobDetails.${index}.client_id`}>Client</Label>

                                      <Input
                                        id={`personJobDetails.${index}.client_id`}
                                        type="select"
                                        name={`personJobDetails.${index}.client_id`}
                                        onChange={(e) => { createNewContact(e), validation.setFieldValue(`personJobDetails.${index}.client_id`, e.target.value) }}
                                      >
                                        <option value="">Select Client</option>
                                        <option value="createnewcontact">Create a new Client</option>
                                        {customers && customers.map((data, cusIndex) => (
                                          <option key={cusIndex} selected={validation.values.personJobDetails[index].client_id == data.id} value={data.id}>{data.cmp_name} </option>
                                        ))}

                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col md="4">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor={`item.${index}.vendor_id`}>Vendor</Label>

                                      <Input
                                        id={`personJobDetails.${index}.vendor_id`}
                                        name={`personJobDetails.${index}.vendor_id`}
                                        type='select'
                                        onChange={(e) => { createNewContact(e), validation.setFieldValue(`personJobDetails.${index}.vendor_id`, e.target.value) }}
                                      >
                                        <option value="">Select Vendor</option>
                                        <option value="createnewcontact">Create a new Vendor</option>
                                        {customers && customers.map((data, vendorIndex) => (
                                          <option key={vendorIndex} selected={validation.values.personJobDetails[index].vendor_id == data.id} value={data.id}>{data.cmp_name} </option>
                                        ))}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col md="4">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor={`personJobDetails.${index}.work_location`}>Work Location</Label>

                                      <Field type="text"
                                        className="form-control"
                                        placeholder="Work Location"
                                        name={`personJobDetails.${index}.work_location`}
                                      />

                                      <ErrorMessage
                                        name={`personJobDetails.${index}.work_location`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="4">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor={`personJobDetails.${index}.report_to`}>Manager</Label>
                                      <Field type="text"
                                        className="form-control"
                                        placeholder="Manager"
                                        name={`personJobDetails.${index}.report_to`}
                                      />
                                      {/* <Input
                                      id="report_to"
                                      type="select"
                                      name={`personJobDetails.${index}.report_to`}
                                    >
                                      <option value="" data-new="">Select Manager</option>
                                      {employercontactDetail.personJobDetails && employercontactDetail.personJobDetails.map((job_detail, index) => (
                                          <option key={index} value={job_detail.report_to}>
                                              {job_detail.report_to}
                                          </option>
                                      ))}
                                    </Input> */}
                                    </FormGroup>
                                  </Col>
                                  <Col md="4">

                                    <FormGroup className="mb-3">
                                      <Label htmlFor={`personJobDetails.${index}.job_start_date`}>Start Date</Label>
                                      <ReactDatePicker
                                        showYearDropdown={true}
                                        showIcon
                                        placeholderText="MM/dd/yyyy"
                                        className="form-control"
                                        id={`personJobDetails.${index}.job_start_date`}
                                        name={`personJobDetails.${index}.job_start_date`}
                                        dateFormat="MM/dd/yyyy"
                                        onChange={(date) => {
                                          validation.setFieldValue(`personJobDetails.${index}.job_start_date`, date)
                                        }
                                        }
                                        selected={validation?.values?.personJobDetails[index].job_start_date && validation?.values?.personJobDetails[index].job_start_date != "0000-00-00" ? new Date(validation.values.personJobDetails[index].job_start_date) : ''}
                                      />
                                      <ErrorMessage
                                        name={`personJobDetails.${index}.job_start_date`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="4">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor={`personJobDetails.${index}.job_end_date`}>End Date</Label>

                                      <ReactDatePicker
                                        showYearDropdown={true}
                                        showIcon
                                        placeholderText="MM/dd/yyyy"
                                        className="form-control"
                                        id={`personJobDetails.${index}.job_end_date`}
                                        name={`personJobDetails.${index}.job_end_date`}
                                        dateFormat="MM/dd/yyyy"
                                        onChange={(date) => {
                                          validation.setFieldValue(`personJobDetails.${index}.job_end_date`, date)
                                        }
                                        }
                                        selected={validation.values?.personJobDetails[index].job_end_date && validation.values?.personJobDetails[index].job_end_date != "0000-00-00" ? new Date(validation.values.personJobDetails[index].job_end_date) : ''}
                                      />
                                      <ErrorMessage
                                        name={`personJobDetails.${index}.job_end_date`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Row className="justify-content-middle">
                                    <Col sm={10}></Col>
                                    <Col sm={2} style={{ padding: "0 0 16px 0" }}>
                                      {validation.values.personJobDetails.length === index + 1 ? (
                                        <Button
                                          color="primary add-more-button-right" className="ms-1"
                                          type="button"
                                          onClick={() => arrayHelpers.push({ client_id: '', vendor_id: '', report_to: '', })}
                                        >
                                          Add more
                                        </Button>
                                      ) : ''}
                                    </Col>

                                  </Row>
                                </div>

                              </div>
                            ))}

                          </div>
                        )}
                      />
                    </div>
                    <Row style={{ float: "right", marginTop: "18px" }}>
                      <Col >
                        <span className="mt-4">
                          <button type="reset" className="btn btn-secondary" color="secondary" onClick={() => navigate(-1)}>
                            Cancel
                          </button>
                        </span>
                        &nbsp;&nbsp;
                        <span className="mt-4">
                          <button type="submit" className="btn btn-primary">
                            Save
                          </button>
                        </span>
                      </Col>
                    </Row>
                  </Form>
                </FormikProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>



      </div>
    </React.Fragment>
  )
}

