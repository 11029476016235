import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className={props.fontsize??"mb-0 font-size-18" }>{props.breadcrumbItem}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              { props.BreadcrumbData && props.BreadcrumbData.map((item, index) => (
                <BreadcrumbItem key={index} active={(props.BreadcrumbData.length === index+1 || props.BreadcrumbData.length ===1)}>
                  {(props.BreadcrumbData.length === index+1 || props.BreadcrumbData.length ===1) ? (
                    <span>{item.name}</span>
                  ) : 
                    <Link to={item.url}>{item.name}</Link> 
                  }
                </BreadcrumbItem>
              ))}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
