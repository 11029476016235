import {
  GET_ATTORNEY_COMPANY_FAIL,
  GET_ATTORNEY_COMPANY_SUCCESS,
  GET_ATTORNEY_COMPANY_DETAIL_SUCCESS,
  GET_ATTORNEY_COMPANY_DETAIL_FAIL,
  UPDATE_ATTORNEY_COMPANY_DETAIL_FAIL,
  UPDATE_ATTORNEY_COMPANY_DETAIL_SUCCESS,
  DELETE_ATTORNEY_COMPANY_DETAIL_FAIL,
  DELETE_ATTORNEY_COMPANY_DETAIL_SUCCESS,
  ADD_ATTORNEY_COMPANY_DETAIL_SUCCESS,
  ADD_ATTORNEY_COMPANY_DETAIL_FAIL,
  GET_ATTORNEY_ELIGIBILITY_STATUS_SUCCESS,
  GET_ATTORNEY_ELIGIBILITY_STATUS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  attorneycompany: [],
  attorneycompanyDetail: {},
  attorneyEligibileStatus:[],
  error: {},
}

const AttorneyContacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ATTORNEY_COMPANY_SUCCESS:
      return {
        ...state,
        attorneycompany: action.payload,
      }

    case GET_ATTORNEY_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ATTORNEY_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        attorneycompanyDetail: action.payload,
      }

    case GET_ATTORNEY_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ATTORNEY_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        attorneycompany: [...state.attorneycompany, action.payload],
      }

    case ADD_ATTORNEY_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ATTORNEY_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        attorneycompany: state.attorneycompany.filter((item) => item.id !== action.payload),
      };

    case DELETE_ATTORNEY_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_ATTORNEY_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        attorneycompany: action.payload,
      };

    case UPDATE_ATTORNEY_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ATTORNEY_ELIGIBILITY_STATUS_SUCCESS:
        return {
          ...state,
          attorneyEligibileStatus: action.payload,
        }
  
    case GET_ATTORNEY_ELIGIBILITY_STATUS_FAIL:
        return {
          ...state,
          error: action.payload,
        }  

    default:
      return state
  }
}

export default AttorneyContacts
