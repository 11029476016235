import React from "react";
import { Link } from "react-router-dom"
import moment from "moment";


const Id = (cell) => {
  return cell.value ? cell.value : '';
};

const LinkCell = (cell) => {
  return (
    <Link className="text-dark fw-medium" to="#">{cell.value}</Link>
  );
};

const Date = (cell) => {
  return cell.value ? moment(cell.value).format("MMM DD, YYYY") : '';
};

const CaseTypeName = (cell) => {
  return cell.value;
};
const CommonCell = (cell) => {
  return cell.value ? cell.value : '';
};

const Amount = (cell) => {
  var value = cell.value ?? 0;
  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export {
  CommonCell,
  Date,
  CaseTypeName,
  Amount

};