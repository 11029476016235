import React from 'react';
import { Col, Row } from 'reactstrap';

//redux
import { useSelector } from "react-redux";



const Timeline = () => {
  //meta title
  document.title = "Timeline | Immidock ";

  const { caseTimelines, caseDetail } = useSelector((state) => ({
    caseDetail: state.AttorneyCases.caseDetail || [],
    caseTimelines: state.AttorneyCases.caseTimelines || [],
  }));
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Row className="justify-content-center">
            <Col xl={10}>
              <div className="timeline">
                <div className="timeline-container">
                  <div className="timeline-end">
                    <p>Start</p>
                  </div>
                  <div className="timeline-continue">
                    {caseTimelines.map((timeline, idx) => (
                      <React.Fragment key={idx}>
                        <Row className={idx % 2 === 0 ? "timeline-left" : "timeline-right"}>
                          <Col md={6} className={idx % 2 === 0 ? "d-md-none d-block" : ""}>
                            <div className="timeline-icon">
                              <i className="bx bx-pie-chart-alt text-primary h2 mb-0"></i>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="timeline-box">
                              <div className="timeline-date bg-primary text-center rounded">
                                {/* <h3 className="text-white mb-0">{timeline.created_at}</h3> */}
                                <p className="mb-0 text-white">{timeline.created_at}</p>
                                {/* <h3 className="text-white mb-0">{(new Date(timeline.created_at)).getDay()}</h3>
                                <p className="mb-0 text-white-50">{(new Date(timeline.created_at)).toLocaleString('default', { month: 'short' })}</p> */}
                              </div>
                              <div className="event-content">
                                <div className="timeline-text">
                                  <h3 className="font-size-18">{timeline.name}</h3>
                                  <p className="mb-0 mt-2 pt-1 text-muted">{timeline.title}</p>
                                </div>
                              </div>
                            </div>
                          </Col>
                          {idx % 2 === 0 ?
                            (<Col md={6} className="d-md-block d-none">
                              <div className="timeline-icon">
                                <i className="bx bx-pie-chart-alt text-primary h2 mb-0"></i>
                              </div>
                            </Col>) : ''}
                        </Row>
                      </React.Fragment>

                    ))}

                  </div>
                  {["Approved", "Rejected", "Not Required", "Duplicate", "Withdrawn"].includes(caseDetail?.processing_status) && (
                    <div className="timeline-start">
                      <p>End</p>
                    </div>
                  )}
                  <div className="timeline-launch">
                    <div className="timeline-box">
                      <div className="timeline-text">
                        <h3 className="font-size-18">Case Timeline</h3>
                        {/* <p className="text-muted mb-0">Pellentesque sapien ut est.</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Timeline;