/* TEMPLATES */
export const GET_TEMPLATES = "GET_TEMPLATES"
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS"
export const GET_TEMPLATES_FAIL = "GET_TEMPLATES_FAIL"

export const GET_TEMPLATE_DETAIL = "GET_TEMPLATE_DETAIL"
export const GET_TEMPLATE_DETAIL_SUCCESS = "GET_TEMPLATE_DETAIL_SUCCESS"
export const GET_TEMPLATE_DETAIL_FAIL = "GET_TEMPLATE_DETAIL_FAIL"

export const ADD_TEMPLATE_DETAIL = "ADD_TEMPLATE_DETAIL"
export const ADD_TEMPLATE_DETAIL_SUCCESS = "ADD_TEMPLATE_DETAIL_SUCCESS"
export const ADD_TEMPLATE_DETAIL_FAIL = "ADD_TEMPLATE_DETAIL_FAIL"

export const SEND_TEMPLATE = "SEND_TEMPLATE"
export const SEND_TEMPLATE_SUCCESS = "SEND_TEMPLATE_SUCCESS"
export const SEND_TEMPLATE_FAIL = "SEND_TEMPLATE_FAIL"

export const UPDATE_TEMPLATE_PDF = "UPDATE_TEMPLATE_PDF"
export const UPDATE_TEMPLATE_PDF_SUCCESS = "UPDATE_TEMPLATE_PDF_SUCCESS"
export const UPDATE_TEMPLATE_PDF_FAIL = "UPDATE_TEMPLATE_PDF_FAIL"

export const RECORD_PAYMENT = "RECORD_PAYMENT"
export const RECORD_PAYMENT_SUCCESS = "RECORD_PAYMENT_SUCCESS"
export const RECORD_PAYMENT_FAIL = "RECORD_PAYMENT_FAIL"

export const GET_TEMPLATE_SETTINGS = "GET_TEMPLATE_SETTINGS"
export const GET_TEMPLATE_SETTINGS_SUCCESS = "GET_TEMPLATE_SETTINGS_SUCCESS"
export const GET_TEMPLATE_SETTINGS_FAIL = "GET_TEMPLATE_SETTINGS_FAIL"

export const UPLOAD_TEMPLATE_ATTACHMENT = "UPLOAD_TEMPLATE_ATTACHMENT"
export const UPLOAD_TEMPLATE_ATTACHMENT_SUCCESS = "UPLOAD_TEMPLATE_ATTACHMENT_SUCCESS"
export const UPLOAD_TEMPLATE_ATTACHMENT_FAIL = "UPLOAD_TEMPLATE_ATTACHMENT_FAIL"

export const DELETE_TEMPLATE_DETAIL = "DELETE_TEMPLATE_DETAIL"
export const DELETE_TEMPLATE_DETAIL_SUCCESS = "DELETE_TEMPLATE_DETAIL_SUCCESS"
export const DELETE_TEMPLATE_DETAIL_FAIL = "DELETE_TEMPLATE_DETAIL_FAIL"

export const UPDATE_TEMPLATE_DETAIL = "UPDATE_TEMPLATE_DETAIL"
export const UPDATE_TEMPLATE_DETAIL_SUCCESS = "UPDATE_TEMPLATE_DETAIL_SUCCESS"
export const UPDATE_TEMPLATE_DETAIL_FAIL = "UPDATE_TEMPLATE_DETAIL_FAIL"
