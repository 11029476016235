import React from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";

const UpdateCase = () => {
    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <div className="mb-3">
                        <Label htmlFor="example-date-input" className="form-Label">Case Number : EXAS162Q001C0003</Label>
                    </div></Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div>
                        <div className="mb-3">
                            <Label className="form-Label">CASE STATUS</Label>
                            <select className="form-select">
                                <option>Active</option>
                                <option>Pending</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                        <div className="mb-6">
                            <Label htmlFor="example-text-input" className="form-Label">RECEIPT NUMBER</Label>
                            <Input className="form-control" type="text" value="XX32WE3434" defaultValue="IMMIXEWW" id="example-text-input" />
                        </div>
                        <div className="mb-3">
                            <Label className="form-Label">SHIPMENT TRACKING CARRIER</Label>
                            <select className="form-select">
                                <option>FEDEX</option>
                                <option>UPS</option>
                                <option>USPS</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <Label htmlFor="example-text-input" className="form-Label">Shipment Tracking Number</Label>
                            <Input className="form-control" type="text" defaultValue="IMMIXEWW" id="example-text-input" />
                        </div>

                        <div className="mb-3">
                            <Label className="form-Label">Processing Status </Label>
                            <select className="form-select">
                                <option>Qnnr Completed</option>
                                <option>In Review</option>
                                <option>Filed</option>
                                <option>Receipt Received</option>
                                <option>RFE Issued</option>
                                <option>RFE Responded</option>
                                <option>Approved</option>
                                <option>Rejected</option>
                                <option>Not Required</option>
                                <option>Duplicate</option>
                                <option>Withdrawn</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="example-date-input" className="form-Label">RFE Due Date</Label>
                            <ReactDatePicker
                                showYearDropdown={true}
                                showIcon
                                name="example-date-input"
                                id="example-date-input"
                                placeholderText="MM/dd/yyyy"
                                className={`form-control ${validation.touched.edition && validation.errors.edition ? 'is-invalid' : ''}`}
                                dateFormat="MM/dd/yyyy"
                                onChange={(date) => {
                                    // setFormData({ ...formData, edition: date });
                                }
                                }
                                selected={new Date('2024-03-25')}
                            />

                        </div>
                        <div className="mb-3">
                            <Label htmlFor="example-date-input" className="form-Label">Approved Until</Label>
                            <ReactDatePicker
                                showYearDropdown={true}
                                showIcon
                                name="example-date-input"
                                id="example-date-input"
                                placeholderText="MM/dd/yyyy"
                                className="form-control"
                                dateFormat="MM/dd/yyyy"
                                onChange={(date) => {
                                    // setFormData({ ...formData, edition: date });
                                }
                                }
                                selected={new Date('2024-03-25')}
                            />
                        </div>

                    </div>
                </Col>

            </Row>
            <Row>
                <Col lg="12">
                    <div className="mt-4">
                        <button type="submit" className="btn btn-primary">
                            Save
                        </button>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default UpdateCase;