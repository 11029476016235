import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import {
	Button,
	Col,
	Container,
	Form,
	Input,
	Label,
	Row
} from "reactstrap";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";


// Form Editor
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { getReminder, saveReminder } from "../../../../store/actions";

//redux
import { useDispatch, useSelector } from "react-redux";


const Reminder = () => {
	//meta title
	document.title = "Case Reminder | Immidock ";

	const queryParams = new URLSearchParams(location.search);

	const caseId = queryParams.get('caseId'); // Read the 'id' query parameter from the URL

	var userType = window.localStorage.getItem("user_type")

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const [editorDescription, seteditorDescription] = useState(EditorState.createEmpty());

	const onEditorDescriptionStateChange = (editorDescription) => {
		validation.values.description = draftToHtml(convertToRaw(editorDescription.getCurrentContent()));
		seteditorDescription(editorDescription);
	};
	const { casereminder, savereminder } = useSelector((state) => ({
		casereminder: state.AttorneyCases.casereminder || {},
		savereminder: state.AttorneyCases.savereminder || {},
	}));

	useEffect(() => {
		if (caseId) {
			dispatch(getReminder({
				case_id: caseId
			}));
		}
	}, [caseId, savereminder]);

	// Form validation 
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			case_id: caseId,
			description: "",
			remind_date_time: "2024-05-23 12:56:45",
		},
		validationSchema: Yup.object({
		}),
		onSubmit: (values) => {
			values.description = draftToHtml(convertToRaw(editorDescription.getCurrentContent()));
			dispatch(saveReminder(values));
			seteditorDescription(EditorState.createEmpty())
			navigate(`/${userType}/view-case?caseId=${caseId}`);
		}
	});

	return (
		<React.Fragment>
			<Container fluid={true}>
				<Form className="needs-validation"
					onSubmit={(e) => {
						e.preventDefault();
						validation.handleSubmit();
						return false;
					}}
				>
					<Col>
						<Row>
							<Col lg="12">
								<Editor
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									editorState={editorDescription}
									onEditorStateChange={onEditorDescriptionStateChange}
								/>
							</Col>
						</Row>
						<div className="mt-4">
							<Row>
								<Col lg="3">
									<div className="mt-4">
										<Label htmlFor="example-datetime-local-input" className="form-Label">Select Reminder Time</Label>
										<Input className="form-control" type="datetime-local" defaultValue="2023-10-02T13:45:00" id="example-datetime-local-input" />
									</div>
								</Col>
							</Row>
						</div>

						<Row className="mb-4">
							<Label
								htmlFor="horizontal-firstname-input"
								className="col-sm-2 col-form-label"
							>
							</Label>
							<Col md={12}>
								<div className="mt-3" style={{ float: "right" }}>
									<Button type="reset" color="secondary" onClick={() => navigate(-1)}>
										Cancel
									</Button>
									{" "}
									<Button type="submit" color="primary" className="ms-1">
										Save
									</Button>
								</div>

							</Col>
						</Row>

					</Col>
				</Form>
				<Row>
					<Col lg="12 mt-4">
						{casereminder.length > 0 && casereminder.map((reminder) => (
							<>
								<div className="card">
									<div className="card-body">
										<p className="text-muted mb-2">{reminder?.user_name}, {reminder?.created_at}</p>

										<span className="margin-bottom-0" dangerouslySetInnerHTML={{ __html: reminder?.description }}></span>

									</div>
								</div>
							</>
						))}
					</Col>
				</Row>
			</Container>

		</React.Fragment>
	);
};

export default Reminder;
