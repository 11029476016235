import {
  GET_ATTRIBUTEGROUPS,
  GET_ATTRIBUTEGROUPS_FAIL,
  GET_ATTRIBUTEGROUPS_SUCCESS,
  GET_ATTRIBUTEGROUP_DETAIL,
  GET_ATTRIBUTEGROUP_DETAIL_FAIL,
  GET_ATTRIBUTEGROUP_DETAIL_SUCCESS,
  UPDATE_ATTRIBUTEGROUP_DETAIL_FAIL,
  UPDATE_ATTRIBUTEGROUP_DETAIL_SUCCESS,
  UPDATE_ATTRIBUTEGROUP_DETAIL,
  DELETE_ATTRIBUTEGROUP_DETAIL_FAIL,
  DELETE_ATTRIBUTEGROUP_DETAIL_SUCCESS,
  DELETE_ATTRIBUTEGROUP_DETAIL,
  ADD_ATTRIBUTEGROUP_DETAIL_SUCCESS,
  ADD_ATTRIBUTEGROUP_DETAIL,
  ADD_ATTRIBUTEGROUP_DETAIL_FAIL

} from "./actionTypes"

export const getAttributegroups = () => ({
  type: GET_ATTRIBUTEGROUPS,
})

export const getAttributegroupsSuccess = Attributegroups => ({
  type: GET_ATTRIBUTEGROUPS_SUCCESS,
  payload: Attributegroups,
})

export const getAttributegroupsFail = error => ({
  type: GET_ATTRIBUTEGROUPS_FAIL,
  payload: error,
})

export const getAttributegroupDetail = AttributegroupId => ({
  type: GET_ATTRIBUTEGROUP_DETAIL,
  AttributegroupId,
})

export const getAttributegroupDetailSuccess = Attributegroups => ({
  type: GET_ATTRIBUTEGROUP_DETAIL_SUCCESS,
  payload: Attributegroups,
})

export const getAttributegroupDetailFail = error => ({
  type: GET_ATTRIBUTEGROUP_DETAIL_FAIL,
  payload: error,
})

export const  addAttributegroupDetail = (data) => ({
  type: ADD_ATTRIBUTEGROUP_DETAIL,
  payload: data
})

export const addAttributegroupdetailSuccess = (data) => ({
  type: ADD_ATTRIBUTEGROUP_DETAIL_SUCCESS,
  payload: data
})

export const addAttributegroupdetailFail = (error) => ({
  type: ADD_ATTRIBUTEGROUP_DETAIL_FAIL,
  payload: error
})

export const AttributegroupDelete = (data) => ({
  type: DELETE_ATTRIBUTEGROUP_DETAIL,
  payload: data
})

export const AttributegroupDeleteSuccess = (data) => ({
  type: DELETE_ATTRIBUTEGROUP_DETAIL_SUCCESS,
  payload: data
})

export const AttributegroupDeleteFail = (error) => ({
  type: DELETE_ATTRIBUTEGROUP_DETAIL_FAIL,
  payload: error
})

export const AttributegroupUpdate = (data) => ({
  type: UPDATE_ATTRIBUTEGROUP_DETAIL,
  payload: data
})

export const AttributegroupUpdateSuccess = (data) => ({
  type: UPDATE_ATTRIBUTEGROUP_DETAIL_SUCCESS,
  payload: data
})

export const AttributegroupUpdateFail = (error) => ({
  type: UPDATE_ATTRIBUTEGROUP_DETAIL_FAIL,
  payload: error
})
