import {
  GET_ATTRIBUTESETS,
  GET_ATTRIBUTESETS_FAIL,
  GET_ATTRIBUTESETS_SUCCESS,
  GET_ATTRIBUTESET_DETAIL,
  GET_ATTRIBUTESET_DETAIL_FAIL,
  GET_ATTRIBUTESET_DETAIL_SUCCESS,
  UPDATE_ATTRIBUTESET_DETAIL_FAIL,
  UPDATE_ATTRIBUTESET_DETAIL_SUCCESS,
  UPDATE_ATTRIBUTESET_DETAIL,
  DELETE_ATTRIBUTESET_DETAIL_FAIL,
  DELETE_ATTRIBUTESET_DETAIL_SUCCESS,
  DELETE_ATTRIBUTESET_DETAIL,
  ADD_ATTRIBUTESET_DETAIL_SUCCESS,
  ADD_ATTRIBUTESET_DETAIL,
  ADD_ATTRIBUTESET_DETAIL_FAIL

} from "./actionTypes"

export const getAttributesets = () => ({
  type: GET_ATTRIBUTESETS,
})

export const getAttributesetsSuccess = Attributesets => ({
  type: GET_ATTRIBUTESETS_SUCCESS,
  payload: Attributesets,
})

export const getAttributesetsFail = error => ({
  type: GET_ATTRIBUTESETS_FAIL,
  payload: error,
})

export const getAttributesetDetail = AttributesetId => ({
  type: GET_ATTRIBUTESET_DETAIL,
  AttributesetId,
})

export const getAttributesetDetailSuccess = Attributesets => ({
  type: GET_ATTRIBUTESET_DETAIL_SUCCESS,
  payload: Attributesets,
})

export const getAttributesetDetailFail = error => ({
  type: GET_ATTRIBUTESET_DETAIL_FAIL,
  payload: error,
})

export const  addAttributesetDetail = (data) => ({
  type: ADD_ATTRIBUTESET_DETAIL,
  payload: data
})

export const addAttributesetdetailSuccess = (data) => ({
  type: ADD_ATTRIBUTESET_DETAIL_SUCCESS,
  payload: data
})

export const addAttributesetdetailFail = (error) => ({
  type: ADD_ATTRIBUTESET_DETAIL_FAIL,
  payload: error
})

export const AttributesetDelete = (data) => ({
  type: DELETE_ATTRIBUTESET_DETAIL,
  payload: data
})

export const AttributesetDeleteSuccess = (data) => ({
  type: DELETE_ATTRIBUTESET_DETAIL_SUCCESS,
  payload: data
})

export const AttributesetDeleteFail = (error) => ({
  type: DELETE_ATTRIBUTESET_DETAIL_FAIL,
  payload: error
})

export const AttributesetUpdate = (data) => ({
  type: UPDATE_ATTRIBUTESET_DETAIL,
  payload: data
})

export const AttributesetUpdateSuccess = (data) => ({
  type: UPDATE_ATTRIBUTESET_DETAIL_SUCCESS,
  payload: data
})

export const AttributesetUpdateFail = (error) => ({
  type: UPDATE_ATTRIBUTESET_DETAIL_FAIL,
  payload: error
})
