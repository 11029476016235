import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ATTRIBUTEGROUPS, GET_ATTRIBUTEGROUP_DETAIL, ADD_ATTRIBUTEGROUP_DETAIL, DELETE_ATTRIBUTEGROUP_DETAIL, UPDATE_ATTRIBUTEGROUP_DETAIL } from "./actionTypes"
import {
  getAttributegroupsSuccess,
  getAttributegroupsFail,
  getAttributegroupDetailSuccess,
  getAttributegroupDetailFail,
  addAttributegroupdetailSuccess,
  addAttributegroupdetailFail,
  AttributegroupDeleteSuccess,
  AttributegroupDeleteFail,
  AttributegroupUpdateSuccess,
  AttributegroupUpdateFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getAttributegroups, getAttributegroupDetail, addAttributegroup, deleteAttributegroup, updateAttributegroup } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"
import { changePreloader } from "../../../store/actions"

function* fetchAttributegroups() {
  try {
    const response =yield call(getAttributegroups)
    yield put(getAttributegroupsSuccess(response.data))
  } catch (error) {
    yield put(getAttributegroupsFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchAttributegroupDetail({ AttributegroupId }) {
  try {
    if(AttributegroupId)
    {
      const response = yield call(getAttributegroupDetail, AttributegroupId)
      yield put(getAttributegroupDetailSuccess(response.data))
    }
    else
    {
      yield put(getAttributegroupDetailSuccess({}))
    }    
  } catch (error) {
    yield put(getAttributegroupDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* addAttributegroupDetail({ payload: user }) {
  try {
    const response = yield call(addAttributegroup, user)
    yield put(addAttributegroupdetailSuccess(response.data))
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(addAttributegroupdetailFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteAttributegroup({ payload: data }) {
  try {
    const response = yield call(deleteAttributegroup, data);
    yield put(AttributegroupDeleteSuccess(data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(AttributegroupDeleteFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateAttributegroup({ payload: data }) {
  try {
    const response = yield call(updateAttributegroup, data);
    yield put(AttributegroupUpdateSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(AttributegroupUpdateFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  } finally {
    yield put(changePreloader(false));
  }
}

function* Attributegroupsaga() {
  yield takeEvery(GET_ATTRIBUTEGROUPS, fetchAttributegroups)
  yield takeEvery(GET_ATTRIBUTEGROUP_DETAIL, fetchAttributegroupDetail)
  yield takeEvery(ADD_ATTRIBUTEGROUP_DETAIL, addAttributegroupDetail)
  yield takeEvery(DELETE_ATTRIBUTEGROUP_DETAIL, onDeleteAttributegroup)
  yield takeEvery(UPDATE_ATTRIBUTEGROUP_DETAIL, onUpdateAttributegroup)
}

export default Attributegroupsaga
