import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CASETYPES, GET_CASETYPE_DETAIL, ADD_CASETYPE_DETAIL, DELETE_CASETYPE_DETAIL, UPDATE_CASETYPE_DETAIL } from "./actionTypes"
import {
  getCasetypesSuccess,
  getCasetypesFail,
  getCasetypeDetailSuccess,
  getCasetypeDetailFail,
  addCasetypedetailSuccess,
  addCasetypedetailFail,
  CasetypeDeleteSuccess,
  CasetypeDeleteFail,
  CasetypeUpdateSuccess,
  CasetypeUpdateFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getCasetypes, getCasetypeDetail, addCasetype, deleteCasetype, updateCasetype } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"
import { changePreloader } from "../../../store/actions"

function* fetchCasetypes() {
  try {
    const response = yield call(getCasetypes)
    yield put(getCasetypesSuccess(response.data))
  } catch (error) {
    yield put(getCasetypesFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchCasetypeDetail({ CasetypeId }) {
  try {
    const response = yield call(getCasetypeDetail, CasetypeId)
    yield put(getCasetypeDetailSuccess(response.data))
  } catch (error) {
    yield put(getCasetypeDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* addCasetypeDetail({ payload: user }) {
  try {
    const response = yield call(addCasetype, user)
    yield put(addCasetypedetailSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addCasetypedetailFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteCasetype({ payload: data }) {
  try {
    const response = yield call(deleteCasetype, data);
    yield put(CasetypeDeleteSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(CasetypeDeleteFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateCasetype({ payload: data }) {
  try {
    const response = yield call(updateCasetype, data);
    yield put(CasetypeUpdateSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(CasetypeUpdateFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* Casetypesaga() {
  yield takeEvery(GET_CASETYPES, fetchCasetypes)
  yield takeEvery(GET_CASETYPE_DETAIL, fetchCasetypeDetail)
  yield takeEvery(ADD_CASETYPE_DETAIL, addCasetypeDetail)
  yield takeEvery(DELETE_CASETYPE_DETAIL, onDeleteCasetype)
  yield takeEvery(UPDATE_CASETYPE_DETAIL, onUpdateCasetype)
}

export default Casetypesaga
