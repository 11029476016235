import React, { useEffect, useMemo, useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import DatatableContainer from "../../../components/Common/DatatableContainer";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { changePreloader, customerDelete, getEmployerPaf } from "../../../store/actions";
import {
  CaseTypeName,
  CommonCell, Date,
  ViewdPdf
} from "./listCol";

//redux
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";



const PafList = () => {

  //meta title
  document.title = "Customers List | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openTab, setopenTab] = useState(1);
  const [customerdata, setCustomerdata] = useState(null);

  // for delete customer
  const [deleteModal, setDeleteModal] = useState(false);


  const [activeTab, setactiveTab] = useState(1);
  const [apiSuccess, setApiSuccess] = useState(false);

  const { employerpaf } = useSelector((state) => ({
    employerpaf: state.EmployerCases.employerpaf || [],
  }));

  useEffect(() => {
    dispatch(getEmployerPaf({ "status": 1 }));
    dispatch(changePreloader(true));
    setApiSuccess(true)
  }, []);

  const onReloadData = (event) => {
    var tabInfo = event.target.value;
    setactiveTab(tabInfo);
    dispatch(getEmployerPaf({ status: tabInfo }));
  }

  useEffect(() => {
    if (apiSuccess) {
      dispatch(changePreloader(false));
      setApiSuccess(false)
    }
  }, [employerpaf]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setCustomerdata(null);
    } else {
      setModal(true);
    }
  };
  var userType = window.localStorage.getItem("user_type")

  const handlePafClicks = () => {
    navigate(`/${userType}/create-customer`);
  };

  const onViewPaf = (data) => {
    navigate(`/${userType}/paf-forms?caseId=${data.case_id}`);
  };

  const onEditData = (data) => {
    dispatch(getEmployerPaf({ case_id: data.case_id }));
    navigate(`/${userType}/paf-forms?caseId=${data.case_id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setCustomerdata(data);
  };

  const handleDeleteCustomer = () => {
    if (customerdata.id) {
      dispatch(customerDelete({ customer_id: customerdata.id }));
      setDeleteModal(false);
      dispatch(getEmployerPaf({ 'status': 1 }));
    }
  };


  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" />;
      //   },
      // },
      {
        Header: "Case",
        accessor: "case_number",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Beneficiary",
        accessor: "emp_name",
        // accessor: "case_id",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "Case Type",
        accessor: "qnnr_type",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "LCA Status",
        accessor: "lca_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Case Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "PAF Status",
        accessor: "paf_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Generated PAF",
        accessor: "paf_bundle_path",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ViewdPdf {...cellProps} />;
        },
      },

      {
        Header: "Created",
        accessor: "created_at",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onViewPaf(cellProps.row.original)}>View</DropdownItem>
                <DropdownItem onClick={() => onViewPaf(cellProps.row.original)}>Move to inactive</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );


  let title = 'Public Access Files';
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/employer/dashboard' },
    { name: title, url: '#' }
  ]

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={employerpaf}
                    isGlobalFilter={true}
                    isPafFilter={true}
                    defaultFilter={1}
                    isPafFilterChange={onReloadData}
                    isAddPafList={false}
                    customPageSize={10}
                    handlePafClick={handlePafClicks}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PafList;
