import {
  GET_ATTRIBUTES,
  GET_ATTRIBUTES_FAIL,
  GET_ATTRIBUTES_SUCCESS,
  GET_ATTRIBUTE_DETAIL,
  GET_ATTRIBUTE_DETAIL_FAIL,
  GET_ATTRIBUTE_DETAIL_SUCCESS,
  UPDATE_ATTRIBUTE_DETAIL_FAIL,
  UPDATE_ATTRIBUTE_DETAIL_SUCCESS,
  UPDATE_ATTRIBUTE_DETAIL,
  DELETE_ATTRIBUTE_DETAIL_FAIL,
  DELETE_ATTRIBUTE_DETAIL_SUCCESS,
  DELETE_ATTRIBUTE_DETAIL,
  ADD_ATTRIBUTE_DETAIL_SUCCESS,
  ADD_ATTRIBUTE_DETAIL,
  ADD_ATTRIBUTE_DETAIL_FAIL

} from "./actionTypes"

export const getAttributes = () => ({
  type: GET_ATTRIBUTES,
})

export const getAttributesSuccess = Attributes => ({
  type: GET_ATTRIBUTES_SUCCESS,
  payload: Attributes,
})

export const getAttributesFail = error => ({
  type: GET_ATTRIBUTES_FAIL,
  payload: error,
})

export const getAttributeDetail = AttributeId => ({
  type: GET_ATTRIBUTE_DETAIL,
  AttributeId,
})

export const getAttributeDetailSuccess = Attributes => ({
  type: GET_ATTRIBUTE_DETAIL_SUCCESS,
  payload: Attributes,
})

export const getAttributeDetailFail = error => ({
  type: GET_ATTRIBUTE_DETAIL_FAIL,
  payload: error,
})

export const  addAttributeDetail = (data) => ({
  type: ADD_ATTRIBUTE_DETAIL,
  payload: data
})

export const addAttributedetailSuccess = (data) => ({
  type: ADD_ATTRIBUTE_DETAIL_SUCCESS,
  payload: data
})

export const addAttributedetailFail = (error) => ({
  type: ADD_ATTRIBUTE_DETAIL_FAIL,
  payload: error
})

export const AttributeDelete = (data) => ({
  type: DELETE_ATTRIBUTE_DETAIL,
  payload: data
})

export const AttributeDeleteSuccess = (data) => ({
  type: DELETE_ATTRIBUTE_DETAIL_SUCCESS,
  payload: data
})

export const AttributeDeleteFail = (error) => ({
  type: DELETE_ATTRIBUTE_DETAIL_FAIL,
  payload: error
})

export const AttributeUpdate = (data) => ({
  type: UPDATE_ATTRIBUTE_DETAIL,
  payload: data
})

export const AttributeUpdateSuccess = (data) => ({
  type: UPDATE_ATTRIBUTE_DETAIL_SUCCESS,
  payload: data
})

export const AttributeUpdateFail = (error) => ({
  type: UPDATE_ATTRIBUTE_DETAIL_FAIL,
  payload: error
})
