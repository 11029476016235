import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";


import {
    AttorneyCaseUpdate, getAttorneyCaseDetail
} from "../../../../store/actions";
//redux
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getDateFormatMDYYMD } from "../../../../common/data/dateFormat";
import { setAlertMessage } from "../../../../store/common/actions";

const UpdateCaseDetails = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const caseId = queryParams.get('id'); // Read the 'id' query parameter from the URL

    useEffect(() => {
        dispatch(getAttorneyCaseDetail({
            case_id: caseId
        }));
    }, [caseId]);

    const { caseDetail } = useSelector((state) => ({
        caseDetail: state.AttorneyCases.caseDetail || [],
    }));

    const { user_type } = useSelector((state) => ({
		user_type: state.login.user_type || localStorage.getItem('user_type'),
	}));

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            case_id: caseId,
            case_status: caseDetail.status,
            receipt_no: caseDetail.receipt_no,
            shipment_tacking_type: caseDetail.shipment_tacking_type,
            processing_status: caseDetail.processing_status,
            rfe_due_date: caseDetail.rfe_due_date,
            approved_until_date: caseDetail.approved_until_date,
            shipment_tracking: caseDetail.shipment_tracking
        },
        validationSchema: Yup.object({
            //receipt_no: Yup.string().required("Please enter Receipt number").nullable(),
            //shipment_tacking_type: Yup.string().required("Please select Shipment tracking type").nullable(),
            processing_status: Yup.string().required("Please select processing status").nullable(),
            // shipment_tracking: Yup.string().required("Please enter Shipment tracking number"),
        }),
        onSubmit: (values) => {
            dispatch(AttorneyCaseUpdate({ ...values, rfe_due_date: getDateFormatMDYYMD(values.rfe_due_date), approved_until_date: getDateFormatMDYYMD(values.approved_until_date) }));
            setTimeout(() => {
                navigate("/"+user_type+"/view-case?caseId="+caseId);
            },200)
        }
    });
    const routeTolIsting = () => {
        navigate("/attorney/cases");
    }
    let title = 'Update Case details';

    const caseStatusList = [
        { id: "Active", name: "Active" },
        { id: "Pending", name: "Pending" },
        { id: "Inactive", name: "Inactive" }
    ];
    const caseProcessingStatusList = [
        { id: "", name: "Select" },
        { id: "Qnnr Completed", name: "Qnnr Completed" },
        { id: "In Review", name: "In Review" },
        { id: "Filed", name: "Filed" },
        { id: "Receipt Received", name: "Receipt Received" },
        { id: "RFE Issued", name: "RFE Issued" },
        { id: "RFE Responded", name: "RFE Responded" },
        { id: "Approved", name: "Approved" },
        { id: "Rejected", name: "Rejected" },
        { id: "Not Required", name: "Not Required" },
        { id: "Duplicate", name: "Duplicate" },
        { id: "Withdrawn", name: "Withdrawn" },
    ]
    const BreadcrumbData = [
        { name: 'Cases', url: '/attorney/Cases' },
        { name: 'View Case', url: '/attorney/view-case?caseId=' + caseId },
        { name: title, url: '#' }
    ]
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Case information</h4>

                                </CardHeader>

                                <CardBody className="p-4">
                                    <Form className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            if (_.size(validation.errors) > 0) {
                                                const errorObj = { "response": { "status": "400", "data": { "message": "Error - Please fill required form fields" } } }
                                                dispatch(setAlertMessage({ message: errorObj, type: "danger" }));
                                              }
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        {/* import TextualInputs */}
                                        <div className="mt-4 mt-lg-0">

                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="example-date-input" className="form-Label">Case Number : {caseDetail.case_number}</Label>
                                                    </div></Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label className="form-Label">Case Status</Label>
                                                        <select className="form-select" name="case_status"
                                                            onChange={validation.handleChange}
                                                            value={validation.values.case_status}>
                                                            {caseStatusList.map((data, index) => (
                                                                <option key={data.id} value={data.id} selected={caseDetail.status === data.id}>{data.name}</option>
                                                            ))}</select>
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div className="mb-6">
                                                        <Label htmlFor="example-text-input" className="form-Label">Receipt Number</Label>
                                                        <Input className="form-control" name="receipt_no"
                                                            type="text" id="example-text-input"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.receipt_no}
                                                            invalid={
                                                                validation.touched.receipt_no && validation.errors.receipt_no ? true : false
                                                            }
                                                        />
                                                        {validation.touched.receipt_no && validation.errors.receipt_no ? (
                                                            <FormFeedback type="invalid">{validation.errors.receipt_no}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>
                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label className="form-Label">Shipment Tracking Carrier</Label>
                                                        <Input
                                                            name="shipment_tacking_type"
                                                            type="select"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.shipment_tacking_type}
                                                            invalid={
                                                                validation.touched.shipment_tacking_type && validation.errors.shipment_tacking_type ? true : false
                                                            }
                                                        >
                                                            <option value="">Select</option>
                                                            <option selected={validation.values.shipment_tacking_type === "FEDEX"} value="FEDEX">FEDEX</option>
                                                            <option selected={validation.values.shipment_tacking_type === "UPS"} value="UPS">UPS</option>
                                                            <option selected={validation.values.shipment_tacking_type === "USPS"} value="USPS">USPS</option>
                                                        </Input>


                                                        {validation.touched.shipment_tacking_type && validation.errors.shipment_tacking_type ? (
                                                            <FormFeedback type="invalid">{validation.errors.shipment_tacking_type}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>
                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label htmlFor="example-text-input" className="form-Label">Shipment Tracking Number</Label>
                                                        <Input className="form-control" name="shipment_tracking"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.shipment_tracking}
                                                            invalid={
                                                                validation.touched.shipment_tracking && validation.errors.shipment_tracking ? true : false
                                                            }
                                                        />
                                                        {validation.touched.shipment_tracking && validation.errors.shipment_tracking ? (
                                                            <FormFeedback type="invalid">{validation.errors.shipment_tracking}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>
                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label className="form-Label">Processing Status *</Label>
                                                        <Input
                                                            name="processing_status"
                                                            type="select"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.processing_status}
                                                            invalid={
                                                                validation.touched.processing_status && validation.errors.processing_status ? true : false
                                                            }
                                                        >
                                                            <option value="">Select status</option>
                                                            <option selected={validation.values.processing_status === "Pending"} value="Pending">Pending</option>
                                                            <option selected={validation.values.processing_status === "In Review"} value="In Review">In Review</option>
                                                            <option selected={validation.values.processing_status === "Filed"} value="Filed">Filed</option>
                                                            <option selected={validation.values.processing_status === "Receipt Received"} value="Receipt Received">Receipt Received</option>
                                                            <option selected={validation.values.processing_status === "RFE Issued"} value="RFE Issued">RFE Issued</option>
                                                            <option selected={validation.values.processing_status === "RFE Responded"} value="RFE Responded">RFE Responded</option>
                                                            <option selected={validation.values.processing_status === "Approved"} value="Approved">Approved</option>
                                                            <option selected={validation.values.processing_status === "Rejected"} value="Rejected">Rejected</option>
                                                            <option selected={validation.values.processing_status === "Not Required"} value="Not Required">Not Required</option>
                                                            <option selected={validation.values.processing_status === "Duplicate"} value="Duplicate">Duplicate</option>
                                                            <option selected={validation.values.processing_status === "Withdrawn"} value="Withdrawn">Withdrawn</option>
                                                        </Input>


                                                        {validation.touched.processing_status && validation.errors.processing_status ? (
                                                            <FormFeedback type="invalid">{validation.errors.processing_status}</FormFeedback>
                                                        ) : null}

                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label htmlFor="example-date-input" className="form-Label">RFE Due Date</Label>
                                                        <ReactDatePicker
                                                            showYearDropdown={true}
                                                            showIcon
                                                            name="rfe_due_date"
                                                            id="example-date-input"
                                                            placeholderText="MM/dd/yyyy"
                                                            className={`form-control ${validation.touched.rfe_due_date && validation.errors.rfe_due_date ? 'is-invalid' : ''}`}
                                                            dateFormat="MM/dd/yyyy"
                                                            onChange={(date) => {
                                                                validation.setFieldValue('rfe_due_date', date)
                                                            }
                                                            }
                                                            selected={validation?.values?.rfe_due_date ? new Date(validation.values.rfe_due_date) : ''}
                                                        />
                                                    </div>

                                                </Col>
                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label htmlFor="example-date-input" className="form-Label">Approved Until</Label>
                                                        <ReactDatePicker
                                                            showYearDropdown={true}
                                                            showIcon
                                                            name="approved_until_date"
                                                            id="example-date-input"
                                                            placeholderText="MM/dd/yyyy"
                                                            className={`form-control ${validation.touched.approved_until_date && validation.errors.approved_until_date ? 'is-invalid' : ''}`}
                                                            dateFormat="MM/dd/yyyy"
                                                            onChange={(date) => {
                                                                validation.setFieldValue('approved_until_date', date)
                                                            }
                                                            }
                                                            selected={validation?.values?.approved_until_date ? new Date(validation.values.approved_until_date) : ''}
                                                        />
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row style={{ float: "right" }}>
                                                <Col >
                                                    <span className="mt-4">
                                                        <button type="reset" className="btn btn-secondary" color="secondary" onClick={() => navigate(-1)}>
                                                            Cancel
                                                        </button>
                                                    </span>
                                                    &nbsp;&nbsp;
                                                    <span className="mt-4">
                                                        <button type="submit" className="btn btn-primary">
                                                            Save
                                                        </button>
                                                    </span>
                                                </Col>
                                            </Row>

                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment >
    );
};

export default UpdateCaseDetails;