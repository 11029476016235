import React, { useEffect, useState } from "react";
import {
    Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input,
    FormGroup, Button, FormFeedback, Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap"
import { useAlert } from "../../../components/Common/Alert";

import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ListPafForms from "./ListPafForms";

// Formik validation
import * as Yup from "yup";
import { useFormik, Field, FieldArray, FormikProvider, ErrorMessage } from "formik";
import classnames from "classnames";
// Form Editor
import { convertToRaw, convertFromHTML, ContentState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getEmployerPafInfo, getEmployerPafForm, updateEmployerPafForm, changePreloader } from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";

const ViewPafForms = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const caseId = queryParams.get('caseId'); // Read the 'caseId' query parameter from the URL
    const pafFormId = queryParams.get('pafFormId'); // Read the 'caseId' query parameter from the URL


    const [activeTab1, setactiveTab1] = useState("application");
    const toggle1 = (tab) => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };

    const [editorHtmlContent, seteditorHtmlContent] = useState({});
    const [initialValues, setInitialValues] = useState(false);
    const [apiSuccess, setApiSuccess] = useState(false);
    const handleActionSubmit = (e) => {
        validation.handleSubmit();
    };

    const onEditorHtmlContentStateChange = (editorHtmlContent) => {
        seteditorHtmlContent(editorHtmlContent)
        validation.values.html_content = draftToHtml(convertToRaw(editorHtmlContent.getCurrentContent()));
    };

    const { employerpafform, updateemployerpafform, updateemployerpafformerror } = useSelector((state) => ({
        employerpafform: state.EmployerCases.employerpafform || {},
        updateemployerpafform: state.EmployerCases.updateemployerpafform || {},
        updateemployerpafformerror: state.EmployerCases.updateemployerpafformerror || {},
    }));

    useEffect(() => {
        dispatch(getEmployerPafForm({ case_id: caseId, paf_form_id: pafFormId }));
    }, [dispatch]);

    useEffect(() => {
        if (Object.keys(updateemployerpafform).length > 0) {
            validation.values.html_content = (updateemployerpafform?.html_content);
            validation.setFieldValue("html_content", updateemployerpafform?.html_content)
        }
    }, [updateemployerpafform]);

    useEffect(() => {
        if (_.isEmpty(updateemployerpafform))
            dispatch(changePreloader(true));
        else
            dispatch(changePreloader(false));

        if (Object.keys(updateemployerpafform).length > 0 && apiSuccess) {
            dispatch(getEmployerPafInfo({ case_id: caseId }));
            setApiSuccess(false);
            var userType = window.localStorage.getItem("user_type")
            navigate(`/${userType}/paf-forms?caseId=${caseId}`, { replace: true });
        }
    }, [updateemployerpafform]);

    useEffect(() => {
        if (!updateemployerpafform && _.isEmpty(updateemployerpafformerror))
            dispatch(changePreloader(true));
        else
            dispatch(changePreloader(false));
        if (apiSuccess)
            if (!_.isEmpty(updateemployerpafformerror) || !_.isEmpty(updateemployerpafform)) {
                dispatch(changePreloader(false));
                if (!_.isEmpty(updateemployerpafformerror)) {
                    // handleAlertOpen(updateemployerpafformerror.response.data.message, 'warning');
                } else if (!_.isEmpty(updateemployerpafform)) {
                    // handleAlertOpen("PAF details updated successfully!", 'success');
                }
                setApiSuccess(false);
                dispatch(getEmployerPafInfo({ case_id: caseId }));
            }
    }, [updateemployerpafform, updateemployerpafformerror]);

    // const blocksFromHTML = convertFromHTML(convertToRaw(employerpafform?.html_content));
    // console.log("--blocksFromHTML--",blocksFromHTML)
    // const content = ContentState.createFromBlockArray(
    //   blocksFromHTML.contentBlocks,
    //   blocksFromHTML.entityMap,
    // );
    // const raw = convertToRaw(content);

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: (caseId && (Object.keys(employerpafform).length > 0)) ? employerpafform : employerpafform,
        validationSchema: Yup.object({
            html_content: Yup.string().required("Please Select LCA to date").nullable(),
        }),
        onSubmit: (values) => {
            if (Number(caseId) > 0) {
                values.case_id = caseId;
                values.paf_form_id = pafFormId;
                dispatch(updateEmployerPafForm(values));
                setApiSuccess(true);
                // handleAlertOpen("PAF details updated successfully!", 'success');
            }
        }
    });

    let title = 'PAF Form';
    //meta title
    document.title = title + " | Immidock ";
    const BreadcrumbData = [
        { name: 'Public Access Files', url: '/employer/paf' },
        { name: 'PAF Forms', url: '/employer/paf-forms?case_id=' + caseId },
        { name: title, url: '#' }
    ]
    return (
        <React.Fragment>
            <>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                        <Col xs={12}>
                            <Card>

                                <CardHeader>
                                    <h4 className="card-title">{validation?.values?.form_name}</h4>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <FormikProvider value={validation}>
                                        <Form className="needs-validation"
                                            onSubmit={(values) => {
                                                values.preventDefault();
                                                // console.log(validate(values));
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >

                                            <div className="mt-4 mt-lg-0">
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            {/* <Label htmlFor="example-text-input" className="form-Label">Html Content Description</Label> */}
                                                            {validation.values.html_content && (
                                                                <Editor
                                                                    editorStyle={{ height: '30rem' }}
                                                                    toolbarClassName="toolbarClassName"
                                                                    wrapperClassName="wrapperClassName"
                                                                    editorClassName="editorClassName"
                                                                    onEditorStateChange={onEditorHtmlContentStateChange}
                                                                    defaultContentState={convertToRaw(ContentState.createFromBlockArray(htmlToDraft(validation.values.html_content)))}
                                                                />
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row style={{ float: "right" }}>
                                                    <Col>
                                                        <span className="mt-4">
                                                            <button type="submit" className="btn btn-primary">
                                                                Save
                                                            </button>
                                                        </span>
                                                    </Col>
                                                </Row>

                                            </div>

                                        </Form>
                                    </FormikProvider>
                                </CardBody>

                            </Card>
                        </Col>
                    </Container>
                </div>
            </>
        </React.Fragment >

    );
};

export default ViewPafForms;