import React from "react";

import EmployerPayrollView from "../../../Attorney/Payroll/PayrollView";
import EmployerBasicSuite from "../../Basic";

const MainPayrollViewComponent = () => {

const getLoggedInPlanType = () => {
    return localStorage.getItem("plan_type");
  }

  if(getLoggedInPlanType()==1) {
    return <EmployerPayrollView/>
  } else {
    return <EmployerBasicSuite componentName="View Payroll"/>
  }
};

export default MainPayrollViewComponent;
