/* QNNRTYPES */
export const GET_EMPLOYER_DASHBOARD = "GET_EMPLOYER_DASHBOARD"
export const GET_EMPLOYER_DASHBOARD_SUCCESS = "GET_EMPLOYER_DASHBOARD_SUCCESS"
export const GET_EMPLOYER_DASHBOARD_FAIL = "GET_EMPLOYER_DASHBOARD_FAIL"

export const GET_EMPLOYER_DASHBOARD_DETAIL = "GET_EMPLOYER_DASHBOARD_DETAIL"
export const GET_EMPLOYER_DASHBOARD_DETAIL_SUCCESS = "GET_EMPLOYER_DASHBOARD_DETAIL_SUCCESS"
export const GET_EMPLOYER_DASHBOARD_DETAIL_FAIL = "GET_EMPLOYER_DASHBOARD_DETAIL_FAIL"

export const ADD_EMPLOYER_DASHBOARD_DETAIL = "ADD_EMPLOYER_DASHBOARD_DETAIL"
export const ADD_EMPLOYER_DASHBOARD_DETAIL_SUCCESS = "ADD_EMPLOYER_DASHBOARD_DETAIL_SUCCESS"
export const ADD_EMPLOYER_DASHBOARD_DETAIL_FAIL = "ADD_EMPLOYER_DASHBOARD_DETAIL_FAIL"

export const DELETE_EMPLOYER_DASHBOARD_DETAIL = "DELETE_EMPLOYER_DASHBOARD_DETAIL"
export const DELETE_EMPLOYER_DASHBOARD_DETAIL_SUCCESS = "DELETE_EMPLOYER_DASHBOARD_DETAIL_SUCCESS"
export const DELETE_EMPLOYER_DASHBOARD_DETAIL_FAIL = "DELETE_EMPLOYER_DASHBOARD_DETAIL_FAIL"

export const UPDATE_EMPLOYER_DASHBOARD_DETAIL = "UPDATE_EMPLOYER_DASHBOARD_DETAIL"
export const UPDATE_EMPLOYER_DASHBOARD_DETAIL_SUCCESS = "UPDATE_EMPLOYER_DASHBOARD_DETAIL_SUCCESS"
export const UPDATE_EMPLOYER_DASHBOARD_DETAIL_FAIL = "UPDATE_EMPLOYER_DASHBOARD_DETAIL_FAIL"
