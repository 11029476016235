import {
  GET_ATTRIBUTETEMPLATES_FAIL,
  GET_ATTRIBUTETEMPLATES_SUCCESS,
  GET_ATTRIBUTETEMPLATE_DETAIL_SUCCESS,
  GET_ATTRIBUTETEMPLATE_DETAIL_FAIL,
  UPDATE_ATTRIBUTETEMPLATE_DETAIL_FAIL,
  UPDATE_ATTRIBUTETEMPLATE_DETAIL_SUCCESS,
  UPDATE_ATTRIBUTETEMPLATE_DETAIL,
  DELETE_ATTRIBUTETEMPLATE_DETAIL_FAIL,
  DELETE_ATTRIBUTETEMPLATE_DETAIL_SUCCESS,
  DELETE_ATTRIBUTETEMPLATE_DETAIL,
  ADD_ATTRIBUTETEMPLATE_DETAIL_SUCCESS,
  ADD_ATTRIBUTETEMPLATE_DETAIL,
  ADD_ATTRIBUTETEMPLATE_DETAIL_FAIL
} from "./actionTypes"

const INIT_STATE = {
  attributetemplates: [],
  attributetemplateDetail: {},
  error: {},
}

const Attributetemplates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ATTRIBUTETEMPLATES_SUCCESS:
      return {
        ...state,
        attributetemplates: action.payload,
      }

    case GET_ATTRIBUTETEMPLATES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ATTRIBUTETEMPLATE_DETAIL_SUCCESS:
      return {
        ...state,
        attributetemplateDetail: action.payload,
      }

    case GET_ATTRIBUTETEMPLATE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ATTRIBUTETEMPLATE_DETAIL_SUCCESS:
      return {
        ...state,
        attributetemplates: action.payload,
      }

    case ADD_ATTRIBUTETEMPLATE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ATTRIBUTETEMPLATE_DETAIL_SUCCESS:
      return {
        ...state,
        attributetemplates: state.attributetemplates.filter((item) => item.id !== action.payload),
      };

    case DELETE_ATTRIBUTETEMPLATE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_ATTRIBUTETEMPLATE_DETAIL_SUCCESS:
      return {
        ...state,
        attributetemplates: action.payload
      };

    case UPDATE_ATTRIBUTETEMPLATE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state
  }
}

export default Attributetemplates
