import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Card, CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";

// Formik validation
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";

import {
  addEmployerContactDetail,
  getEmployerContactDetail,
  getEmployerEmployeeCountries, getEmployerEmployeeStates,
  updateEmployeePersonalDetails,
  validateDuplicateEmailId
} from "../../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";

import { getDateFormatYMD } from "../../../../common/data/dateFormat";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setAlertMessage } from "../../../../store/common/actions";


export const PersonalInformation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL
  const [sendInvoice, setSendInvoice] = useState(true)
  const [activeTab1, setactiveTab1] = useState("Personal information");
  const [validationCount, setValidationCount] = useState(0)
  useEffect(() => {
    if (editId > 0)
      dispatch(getEmployerContactDetail({ employee_id: editId }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEmployerEmployeeCountries());
  }, []);

  useEffect(() => {
    //if(validation.values.country_citizen > 0)
    dispatch(getEmployerEmployeeStates({ country_id: 233 }));
  }, [dispatch]);

  const { employercontactDetail } = useSelector((state) => ({
    employercontactDetail: state.EmployerContacts.employercontactDetail,
  }));

  const { countries } = useSelector((state) => ({
    countries: state.EmployerContacts.countries,
  }));

  const { states } = useSelector((state) => ({
    states: state.EmployerContacts.states,
  }));

  const { validateDuplicateEmployeeEmail } = useSelector((state) => ({
    validateDuplicateEmployeeEmail: state.EmployerContacts.validateDuplicateEmployeeEmail,
  }));


  const moreactions = [{ 'label': 'Save', 'action': 'saveandsend' }]
  const handleActionSubmit = (e) => {
    // setSendInvoice(true)
    validation.handleSubmit();
  };

  var empVisaDetails = [], empEducationDetails = [], empEmergencyDetails = [];
  if (_.isEmpty(employercontactDetail.personVisaDetails)) {
    empVisaDetails.push({
      visa_id: '', visa: '', valid_from: '', expiration_date: '',
      doc_number: '', issue_date: ''
    })
  } else {
    employercontactDetail.personVisaDetails.map((item, index) => {
      empVisaDetails.push({ // item.id item.visa item.doc_number item.expiration_date
        visa_id: item.id, visa: item.visa, valid_from: item.valid_from, expiration_date: item.expiration_date,
        doc_number: item.doc_number, issue_date: item.issue_date
      })
    })
  }
  if (_.isEmpty(employercontactDetail.personEducationDetails)) {
    empEducationDetails.push({
      edu_id: '', name_college: '', degree: '', specialization: '',
      gpa: '', edu_start_date: '', edu_end_date: ''
    })
  } else {
    employercontactDetail.personEducationDetails.map((item, index) => {
      empEducationDetails.push({
        edu_id: item.id, name_college: item.name_college, degree: item.degree, specialization: item.specialization,
        gpa: item.gpa, edu_start_date: item.edu_start_date, edu_end_date: item.edu_end_date
      })
    })
  }

  if (_.isEmpty(employercontactDetail.personEmergencyDetails)) {
    empEmergencyDetails.push({
      emergency_contact_id: '',
      em_contact_name: '', relationship: '', em_phone_number: ''
    })
  } else {
    employercontactDetail.personEmergencyDetails.map((item, index) => {
      empEmergencyDetails.push({
        emergency_contact_id: item.id,
        em_contact_name: item.em_contact_name, relationship: item.relationship, em_phone_number: item.em_phone_number
      })
    })
  }

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: editId ? {
      //empVisa_items: [{visaType:'', validFrom:'', expirationDate:'', documentNumber:'', issueDate:'' }],
      employee_id: editId ? editId : '',
      first_name: employercontactDetail.first_name,
      last_name: employercontactDetail.last_name,
      middle_name: employercontactDetail?.middle_name ? employercontactDetail.middle_name : '',
      other_first_name: employercontactDetail?.other_first_name ? employercontactDetail.other_first_name : '',
      other_last_name: employercontactDetail?.other_last_name ? employercontactDetail.other_last_name : '',
      other_middle_name: employercontactDetail?.other_middle_name ? employercontactDetail.other_middle_name : '',
      dob: employercontactDetail.dob,
      email: employercontactDetail.email ? employercontactDetail.email : '',
      phone: employercontactDetail.phone ? employercontactDetail.phone : '',
      marital_status: employercontactDetail?.marital_status ? employercontactDetail.marital_status : '',
      gender: employercontactDetail.gender ? employercontactDetail.gender : '',
      street_number: employercontactDetail.street_number ? employercontactDetail.street_number : '',
      state: employercontactDetail.state,
      country_citizen: employercontactDetail.country_citizen,
      zipcode: employercontactDetail.zipcode,
      current_number: employercontactDetail.current_number ? employercontactDetail.current_number : '',
      citytown: employercontactDetail.citytown,
      country: employercontactDetail.country,
      current_apt_ste: employercontactDetail.current_apt_ste ? employercontactDetail.current_apt_ste : false,
      personVisaDetails: empVisaDetails,
      personEducationDetails: empEducationDetails,
      personEmergencyDetails: empEmergencyDetails,
    } : {
      id: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      emp_type: '',
      job_title: '',
      visa_type: '',
      expiration_date: '',
      hire_date: '',
      validationEmail: 0,
    },
    validationSchema: editId ?
      Yup.object({
        first_name: Yup.string().required("Please Enter First Name"),
        last_name: Yup.string().required("Please Enter Last Name"),
        dob: Yup.string().required("Please Select Date of birth").nullable(),
        email: Yup.string().required("Please Enter Email"),
        gender: Yup.string().required("Please Select Gender").nullable(),
        phone: Yup.string().required("Please Enter Phone Number"),

        country_citizen: Yup.string().required("Please Select Country of citizenship").nullable(),
        street_number: Yup.string().required("Please Enter Street number").nullable(),
        citytown: Yup.string().required("Please Enter City").nullable(),
        state: Yup.string().required("Please Select State").nullable(),
        country: Yup.string().required("Please Select Country").nullable(),
        zipcode: Yup.string().required("Please Enter Zipcode").nullable(),
        current_number: Yup.string().required("Please Enter Current Number").nullable(),
        current_apt_ste: Yup.string().required("Please Select Apt/Ste/Flr").oneOf(["Apt", "Ste", "Flr"], "Please Select Apt/Ste/Flr"),

      })
      : Yup.object({
        first_name: Yup.string().required("Please Enter First Name"),
        last_name: Yup.string().required("Please Enter Last Name"),
        email: Yup.string().required("Please Enter Email"),
        phone: Yup.string().required("Please Enter Phone Number"),
        emp_type: Yup.string().required("Please Select Employee Type"),
        //visa_type: Yup.string().required("Please Select Visa type"),
      }),
    onSubmit: (values) => {
      if (Number(editId) > 0) {
        dispatch(updateEmployeePersonalDetails({ ...values, hire_date: getDateFormatYMD(values.hire_date), expiration_date: getDateFormatYMD(values.expiration_date) }));
      } else {
        if (validation.values.validationEmail == 0 && validation.values.email != '') {
          dispatch(addEmployerContactDetail({ ...validation.values, hire_date: getDateFormatYMD(validation.initialValues.hire_date), expiration_date: getDateFormatYMD(validation.initialValues.expiration_date) }));
          setTimeout(() => {
            navigate("/employer/employeelist");
          }, 500);
        }
      }
    }
  });

  const [apiSuccess, setApiSuccess] = useState(false);

  useEffect(() => {
    if (validateDuplicateEmployeeEmail?.count >= 0 && apiSuccess) {
      setValidationCount(validateDuplicateEmployeeEmail?.count);
      setApiSuccess(false)
      validation.setFieldValue('validationEmail', validateDuplicateEmployeeEmail?.count);
    }
  }, [validateDuplicateEmployeeEmail]);

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };
  let title = editId ? 'Update Beneficiary' : 'Add Beneficiary';
  return (
    <React.Fragment>
      <div className="">
        <Row>

          <Col xs={12}>
            <Card>

              <CardBody className="p-4">
                <div className="row">
                  <Col md="9"></Col>
                  <Col md="3">
                    <FormGroup className="mb-3">
                      {/* <Input
                        name="employerEmployee_action"
                        placeholder="More Actions"
                        type="select"
                        className="form-control"
                        id="employerEmployee_action"
                        onChange={(e) => {
                          validation.handleChange(e);
                          if (e.target.value == "saveandsend") {
                            setSendInvoice(true);
                          }
                          handleActionSubmit(e);
                        }}
                        value={validation.values.employerEmployee_action || ""}
                      >
                        <option value="">More Actions</option>
                        {moreactions.map((moreaction, index) => (
                          <option key={moreaction.action + index} value={moreaction.action}>
                            {moreaction.label}
                          </option>
                        ))}
                      </Input> */}
                      {/* {validation.touched.customer_id && validation.errors.customer_id ? (
                                                        <FormFeedback type="invalid">{validation.errors.customer_id}</FormFeedback>
                                                    ) : null} */}
                    </FormGroup>
                  </Col>

                </div>
                <FormikProvider value={validation}>
                  <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (_.size(validation.errors) > 0) {
                        const errorObj = { "response": { "status": "400", "data": { "message": "Error - Please fill required form fields" } } }
                        dispatch(setAlertMessage({ message: errorObj, type: "danger" }));
                      }
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {/* import TextualInputs */}
                    {editId > 0 ? (
                      <React.Fragment>
                        <div className="mt-4 mt-lg-0">
                          <h4>Identification Details</h4><br />
                          <Row>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">First Name *</Label>
                                <Input
                                  name="first_name"
                                  placeholder="First name"
                                  type="text"
                                  className="form-control"
                                  id="validationCustom01"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.first_name}
                                  // value={validation.values.first_name || ""}
                                  invalid={
                                    validation.touched.first_name && validation.errors.first_name ? true : false
                                  }
                                />
                                {validation.touched.first_name && validation.errors.first_name ? (
                                  <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">Last Name *</Label>
                                <Input
                                  name="last_name"
                                  placeholder="Last name"
                                  type="text"
                                  className="form-control"
                                  id="validationCustom02"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.last_name}
                                  invalid={
                                    validation.touched.last_name && validation.errors.last_name ? true : false
                                  }
                                />
                                {validation.touched.last_name && validation.errors.last_name ? (
                                  <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">Middle Name</Label>
                                <Input
                                  name="middle_name"
                                  placeholder="Middle Name"
                                  type="text"
                                  className="form-control"
                                  id="validationCustom02"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.middle_name || ""}
                                  invalid={
                                    validation.touched.middle_name && validation.errors.middle_name ? true : false
                                  }
                                />
                                {validation.touched.middle_name && validation.errors.middle_name ? (
                                  <FormFeedback type="invalid">{validation.errors.middle_name}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <br /><h6>Provide other names if used</h6><br />
                          <Row>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">First Name</Label>
                                <Input
                                  name="other_first_name"
                                  placeholder="First Name"
                                  type="text"
                                  className="form-control"
                                  id="validationCustom02"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.other_first_name || ""}
                                  invalid={
                                    validation.touched.other_first_name && validation.errors.other_first_name ? true : false
                                  }
                                />
                                {validation.touched.other_first_name && validation.errors.other_first_name ? (
                                  <FormFeedback type="invalid">{validation.errors.other_first_name}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">Last Name</Label>
                                <Input
                                  name="other_last_name"
                                  placeholder="Last Name"
                                  type="text"
                                  className="form-control"
                                  id="validationCustom02"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.other_last_name || ""}
                                  invalid={
                                    validation.touched.other_last_name && validation.errors.other_last_name ? true : false
                                  }
                                />
                                {validation.touched.other_last_name && validation.errors.other_last_name ? (
                                  <FormFeedback type="invalid">{validation.errors.other_last_name}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">Middle Name</Label>
                                <Input
                                  name="other_middle_name"
                                  placeholder="Middle Name"
                                  type="text"
                                  className="form-control"
                                  id="validationCustom02"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.other_middle_name || ""}
                                  invalid={
                                    validation.touched.other_middle_name && validation.errors.other_middle_name ? true : false
                                  }
                                />
                                {validation.touched.other_middle_name && validation.errors.other_middle_name ? (
                                  <FormFeedback type="invalid">{validation.errors.other_middle_name}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor='dob'>DOB Date *</Label>

                                {/* <ReactDatePicker
                                              showYearDropdown={true}
                                    showIcon
                                    placeholderText="MM/dd/yyyy"
                                      className="form-control"
                                    id="dob"
                                    name="dob"
                                    dateFormat="MM/dd/yyyy"
                                    onChange={(date) =>{
                                    validation.setFieldValue('dob', date)
                                    }
                                    }
                                    selected={validation?.values?.dob ? new Date(validation.values.dob):''}                       
                                  /> */}

                                <ReactDatePicker
                                  showYearDropdown={true}
                                  showIcon
                                  placeholderText="DOB"
                                  name="dob"
                                  placeholder="Display Date"
                                  className={`form-control ${validation.touched.dob && validation.errors.dob ? 'is-invalid' : ''}`}
                                  id="dob"
                                  dateFormat="MM/dd/yyyy"
                                  onChange={(date) => {
                                    validation.setFieldValue('dob', date)
                                  }
                                  }
                                  selected={validation?.values?.dob ? new Date(validation.values.dob) : ''}
                                />
                                {validation.touched.dob && validation.errors.dob ? (
                                  <FormFeedback type="invalid">{validation.errors.dob}</FormFeedback>
                                ) : null}
                              </FormGroup>

                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">Marital Status</Label>
                                <Input
                                  name="marital_status"
                                  placeholder="Select Status"
                                  type="select"
                                  className="form-control"
                                  id="validationCustom02"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.marital_status || ""}
                                  invalid={
                                    validation.touched.marital_status && validation.errors.marital_status ? true : false
                                  }
                                >  <option value="">Select Status</option>
                                  <option value="B1">Single</option>
                                  <option value="B2">Married</option>
                                  <option value="H1B">Widowed</option>
                                  <option value="H2B">Divorced</option>
                                  <option value="L1A">Separated</option>
                                </Input>
                                {validation.touched.marital_status && validation.errors.marital_status ? (
                                  <FormFeedback type="invalid">{validation.errors.marital_status}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">Gender *</Label>
                                <Input
                                  name="gender"
                                  placeholder="Select Gender"
                                  type="select"
                                  className="form-control"
                                  id="validationCustom02"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.gender || ""}
                                  invalid={
                                    validation.touched.gender && validation.errors.gender ? true : false
                                  }
                                >  <option value="">Select Status</option>
                                  <option value="B1">Male</option>
                                  <option value="B2">Female</option>
                                </Input>
                                {validation.touched.gender && validation.errors.gender ? (
                                  <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">Phone Number *</Label>
                                <InputMask
                                  name="phone"
                                  mask="(999)-999-9999"
                                  className={`${validation.touched.phone && validation.errors.phone ? 'form-control is-invalid' : 'form-control'}`}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.phone || ""}
                                  invalid={
                                    validation.touched.phone && validation.errors.phone ? true : false
                                  }
                                >
                                  {(inputProps) => (
                                    // <Input  type="tel" className="" />
                                    <InputMask
                                      {...inputProps}
                                      placeholder="Enter Phone"
                                      mask="(999)-999-9999"
                                      type="text"
                                      id="validationCustom02"

                                    />
                                  )}
                                </InputMask>
                                {validation.touched.phone && validation.errors.phone ? (
                                  <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">Email Address *</Label>
                                <Input
                                  readOnly={true}
                                  name="email"
                                  placeholder="Email"
                                  type="email"
                                  className="form-control"
                                  id="validationCustom02"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email && validation.errors.email ? true : false
                                  }
                                />
                                {validation.touched.email && validation.errors.email ? (
                                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">Country of Citizenship *</Label>
                                <Input
                                  name="country_citizen"
                                  placeholder="Select Country"
                                  type="select"
                                  className="form-control"
                                  id="validationCustom02"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.country_citizen || ""}
                                  invalid={
                                    validation.touched.country_citizen && validation.errors.country_citizen ? true : false
                                  }
                                >
                                  <option value="">Select Country</option>
                                  {countries && countries.map((country, index) => (
                                    <option key={index} value={country.id}>
                                      {country.name}
                                    </option>
                                  ))}
                                </Input>

                                {validation.touched.country_citizen && validation.errors.country_citizen ? (
                                  <FormFeedback type="invalid">{validation.errors.country_citizen}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>

                          </Row>


                        </div>

                        <div className="mt-4 mt-lg-0">
                          <br /><h4>Current Residential U.S Address</h4><br />
                          <Row>


                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">Street Number and Name *</Label>
                                <Input
                                  name="street_number"
                                  placeholder="Street Number and Name"
                                  type="text"
                                  className="form-control"
                                  id="validationCustom01"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.street_number || ""}
                                  invalid={
                                    validation.touched.street_number && validation.errors.street_number ? true : false
                                  }
                                />
                                {validation.touched.street_number && validation.errors.street_number ? (
                                  <FormFeedback type="invalid">{validation.errors.street_number}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">Apt/Ste/Flr *</Label>
                                <div className="hstack gap-3">
                                  <Input
                                    name="current_apt_ste"
                                    type="radio"
                                    className="form-control"
                                    id="validationCustom02"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value="Apt"
                                    checked={validation.values.current_apt_ste == "Apt" ? true : false}
                                  />
                                  Apt
                                  <Input
                                    name="current_apt_ste"
                                    type="radio"
                                    className="form-control"
                                    id="validationCustom02"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value="Ste"
                                    checked={validation.values.current_apt_ste == "Ste" ? true : false}

                                  />
                                  Ste

                                  <Input
                                    name="current_apt_ste"
                                    type="radio"
                                    className="form-control"
                                    id="validationCustom02"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value="Flr"
                                    checked={validation.values.current_apt_ste == "Flr" ? true : false}
                                  />Flr
                                </div>
                                {validation.touched.current_apt_ste && validation.errors.current_apt_ste ? (
                                  <FormFeedback type="invalid">{validation.errors.current_apt_ste}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">Number *</Label>
                                <Input
                                  name="current_number"
                                  type="text"
                                  placeholder="Enter Number"
                                  value={validation.values.current_number || ""}
                                  className="form-control input-color"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.current_number && validation.errors.current_number ? true : false
                                  }

                                />
                                {validation.touched.current_number && validation.errors.current_number ? (
                                  <FormFeedback type="invalid">{validation.errors.current_number}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">City or Town *</Label>
                                <Input
                                  name="citytown"
                                  placeholder="City or Town"
                                  type="text"
                                  className="form-control"
                                  id="validationCustom01"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.citytown || ""}
                                  invalid={
                                    validation.touched.citytown && validation.errors.citytown ? true : false
                                  }
                                />
                                {validation.touched.citytown && validation.errors.citytown ? (
                                  <FormFeedback type="invalid">{validation.errors.citytown}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">State *</Label>
                                <Input
                                  name="state"
                                  placeholder="State"
                                  type="select"
                                  className="form-control"
                                  id="validationCustom02"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.state || ""}
                                  invalid={
                                    validation.touched.state && validation.errors.state ? true : false
                                  }
                                >
                                  <option value="">Select State</option>
                                  {states && states.map((state, index) => (
                                    <option key={index} value={state.id}>
                                      {state.name}
                                    </option>
                                  ))}
                                </Input>


                                {validation.touched.state && validation.errors.state ? (
                                  <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">Country *</Label>
                                <Input
                                  name="country"
                                  placeholder="Country"
                                  type="select"
                                  className="form-control"
                                  id="validationCustom02"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.country || ""}
                                  invalid={
                                    validation.touched.country && validation.errors.country ? true : false
                                  }
                                >
                                  <option value="">Select Country</option>
                                  <option value="223">USA</option>
                                </Input>

                                {validation.touched.country && validation.errors.country ? (
                                  <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">Zipcode *</Label>
                                <Input
                                  name="zipcode"
                                  placeholder="Zipcode"
                                  type="number"
                                  className="form-control"
                                  id="validationCustom02"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.zipcode || ""}
                                  invalid={
                                    validation.touched.zipcode && validation.errors.zipcode ? true : false
                                  }
                                />
                                {validation.touched.zipcode && validation.errors.zipcode ? (
                                  <FormFeedback type="invalid">{validation.errors.zipcode}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>

                          </Row>

                        </div>
                        <div className="mt-4 mt-lg-0">
                          <br /><h4>Visa Status</h4><br />
                          <FieldArray
                            name="personVisaDetails"
                            render={arrayHelpers => (
                              <div>
                                {validation.values.personVisaDetails.map((item, index) => (
                                  <div key={index + "item"} className="more-item-box">
                                    <div className="remove-item">
                                      <Col sm={1}>
                                        {validation.values.personVisaDetails.length > 1 ? (
                                          <>
                                            <i className="bx bx-x bx-sm" onClick={() => arrayHelpers.remove(index)} ></i>
                                          </>
                                        ) : ''}
                                      </Col>
                                    </div>
                                    <div className="row more-items">
                                      <Col md="4">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor={`personVisaDetails[${index}].visa_name`}>Visa</Label>
                                          <Input
                                            name={`personVisaDetails[${index}].visa`}
                                            placeholder="visa_name"
                                            type="select"
                                            className="form-control"
                                            id="validationCustom02"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                          // invalid={
                                          //   validation.touched.visa_name && validation.errors.visa_name ? true : false
                                          // }
                                          >  <option value="">Select visa</option>
                                            <option selected={validation.values.personVisaDetails[index].visa == "B1"} value="B1">B1</option>
                                            <option selected={validation.values.personVisaDetails[index].visa == "B2"} value="B2">B2</option>
                                            <option selected={validation.values.personVisaDetails[index].visa == "H1B"} value="H1B">H1B</option>
                                            <option selected={validation.values.personVisaDetails[index].visa == "H2B"} value="H2B">H2B</option>
                                            <option selected={validation.values.personVisaDetails[index].visa == "L1A"} value="L1A">L1A</option>
                                            <option selected={validation.values.personVisaDetails[index].visa == "L1B"} value="L1B">L1B</option>
                                            <option selected={validation.values.personVisaDetails[index].visa == "OPT"} value="OPT">OPT</option>
                                            <option selected={validation.values.personVisaDetails[index].visa == "STEM OPT"} value="STEM OPT">STEM OPT</option>
                                            <option selected={validation.values.personVisaDetails[index].visa == "CPT"} value="CPT">CPT</option>
                                            <option selected={validation.values.personVisaDetails[index].visa == "H4-EAD"} value="H4-EAD">H4-EAD</option>
                                            <option selected={validation.values.personVisaDetails[index].visa == "GC-EAD"} value="GC-EAD">GC-EAD</option>
                                            <option selected={validation.values.personVisaDetails[index].visa == "Permanent Resident"} value="Permanent Resident">Permanent Resident</option>
                                            <option selected={validation.values.personVisaDetails[index].visa == "H1B Receipt"} value="H1B Receipt">H1B Receipt</option>
                                            <option selected={validation.values.personVisaDetails[index].visa == "Stem Receipt"} value="Stem Receipt">Stem Receipt</option>
                                          </Input>


                                          {validation.touched.visa_name && validation.errors.visa_name ? (
                                            <FormFeedback type="invalid">{validation.errors.visa_name}</FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                      <Col md="4">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor={`personVisaDetails[${index}].valid_from`}>Valid Date</Label>

                                          <ReactDatePicker
                                            showYearDropdown={true}
                                            showIcon
                                            placeholderText="MM/dd/yyyy"
                                            className="form-control"
                                            name={`personVisaDetails[${index}].valid_from`}
                                            id={`personVisaDetails[${index}].valid_from`}
                                            dateFormat="MM/dd/yyyy"
                                            onChange={(date) => {
                                              validation.setFieldValue(`personVisaDetails[${index}].valid_from`, date)
                                            }
                                            }
                                            selected={validation?.values?.personVisaDetails[index].valid_from ? new Date(validation.values.personVisaDetails[index].valid_from) : ''}
                                          />

                                          <ErrorMessage
                                            name={`personVisaDetails[${index}].valid_from`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </FormGroup>

                                      </Col>
                                      <Col md="4">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor={`personVisaDetails[${index}].issue_date`}>Expiration Date</Label>

                                          <ReactDatePicker
                                            showYearDropdown={true}
                                            showIcon
                                            placeholderText="MM/dd/yyyy"
                                            className="form-control"
                                            name={`personVisaDetails[${index}].expiration_date`}
                                            id={`personVisaDetails[${index}].expiration_date`}
                                            dateFormat="MM/dd/yyyy"
                                            onChange={(date) => {
                                              [0].expiration_date
                                              validation.setFieldValue(`personVisaDetails[${index}].expiration_date`, date)
                                            }
                                            }
                                            selected={validation?.values?.personVisaDetails[index].expiration_date ? new Date(validation.values.personVisaDetails[index].expiration_date) : ''}
                                          />
                                          <ErrorMessage
                                            name={`personVisaDetails[${index}].expiration_date`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </FormGroup>

                                      </Col>

                                      <Col md="4">
                                        <FormGroup className="mb-3">

                                          <Label htmlFor={`personVisaDetails[${index}].doc_number`}>Document Number</Label>
                                          <Field type="text"
                                            className="form-control"
                                            placeholder="Document Number"
                                            onChange={(e) => {
                                              validation.handleChange(e);
                                            }}
                                            onBlur={validation.handleBlur}
                                            name={`personVisaDetails[${index}].doc_number`} />
                                          <ErrorMessage
                                            name={`personVisaDetails[${index}].doc_number`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="4">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor={`personVisaDetails[${index}].issue_date`}>Issue Date</Label>

                                          <ReactDatePicker
                                            showYearDropdown={true}
                                            showIcon
                                            placeholderText="MM/dd/yyyy"
                                            className="form-control"
                                            name={`personVisaDetails[${index}].issue_date`}
                                            id={`personVisaDetails[${index}].issue_date`}
                                            dateFormat="MM/dd/yyyy"
                                            onChange={(date) => {
                                              validation.setFieldValue(`personVisaDetails[${index}].issue_date`, date)
                                            }
                                            }
                                            selected={validation?.values?.personVisaDetails[index].issue_date ? new Date(validation.values.personVisaDetails[index].issue_date) : ''}
                                          />
                                          <ErrorMessage
                                            name={`personVisaDetails[${index}].issue_date`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </FormGroup>

                                      </Col>
                                      <Row className="justify-content-middle">
                                        <Col sm={10}></Col>
                                        <Col sm={2} style={{ padding: "0 0 16px 0" }}>
                                          {validation.values.personVisaDetails.length === index + 1 ? (
                                            <Button
                                              color="primary add-more-button-right" className="ms-1"
                                              type="button"
                                              onClick={() => arrayHelpers.push({ name: '', age: '' })}
                                            >
                                              Add more
                                            </Button>
                                          ) : ''}
                                        </Col>

                                      </Row>
                                    </div>

                                  </div>
                                ))}

                              </div>
                            )}
                          />
                        </div>
                        <div className="mt-4 mt-lg-0">
                          <h4>Education</h4><br />
                          <FieldArray
                            name="personEducationDetails"
                            render={arrayHelpers2 => (
                              <div>
                                {validation.values.personEducationDetails.map((item, index) => (
                                  <div key={index + "item"} className="more-item-box">
                                    <div className="remove-item">

                                      <Col sm={1}>
                                        {validation.values.personEducationDetails.length > 1 ? (
                                          <>
                                            <i className="bx bx-x bx-sm" onClick={() => arrayHelpers2.remove(index)} ></i>
                                          </>
                                        ) : ''}
                                      </Col>
                                    </div>
                                    <div className="row more-items">
                                      <Col md="4">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor={`personEducationDetails[${index}].name_college`}>Name</Label>

                                          <Field type="text"
                                            className="form-control"
                                            placeholder="Name"
                                            name={`personEducationDetails[${index}].name_college`} />

                                          <ErrorMessage
                                            name={`personEducationDetails[${index}].name_college`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="4">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor={`personEducationDetails[${index}].degree`}>Degree</Label>

                                          <Field type="text"
                                            className="form-control"
                                            placeholder="Degree"
                                            name={`personEducationDetails[${index}].degree`} />

                                          <ErrorMessage
                                            name={`personEducationDetails[${index}].degree`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="4">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor={`personEducationDetails[${index}].specialization`}>Specilization</Label>

                                          <Field type="text"
                                            className="form-control"
                                            placeholder="specialization"
                                            name={`personEducationDetails[${index}].specialization`} />

                                          <ErrorMessage
                                            name={`personEducationDetails[${index}].specialization`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="4">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor={`personEducationDetails[${index}].gpa`}>GPA</Label>

                                          <Field type="text"
                                            className="form-control"
                                            placeholder="GPA"
                                            name={`personEducationDetails[${index}].gpa`} />

                                          <ErrorMessage
                                            name={`personEducationDetails[${index}].gpa`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="4">

                                        <FormGroup className="mb-3">
                                          <Label htmlFor={`personEducationDetails[${index}].edu_start_date`}>Start Date</Label>

                                          <ReactDatePicker
                                            showYearDropdown={true}
                                            showIcon
                                            placeholderText="MM/dd/yyyy"
                                            className="form-control"
                                            name={`personEducationDetails[${index}].edu_start_date`}
                                            id={`personEducationDetails[${index}].edu_start_date`}
                                            dateFormat="MM/dd/yyyy"
                                            onChange={(date) => {
                                              validation.setFieldValue(`personEducationDetails[${index}].edu_start_date`, date)
                                            }
                                            }
                                            selected={validation?.values?.personEducationDetails[index].edu_start_date ? new Date(validation.values.personEducationDetails[index].edu_start_date) : ''}
                                          />
                                          <ErrorMessage
                                            name={`personEducationDetails[${index}].edu_start_date`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="4">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor={`personEducationDetails[${index}].edu_end_date`}>End Date</Label>

                                          <ReactDatePicker
                                            showYearDropdown={true}
                                            showIcon
                                            placeholderText="MM/dd/yyyy"
                                            className="form-control"
                                            name={`personEducationDetails[${index}].edu_end_date`}
                                            id={`personEducationDetails[${index}].edu_end_date`}
                                            dateFormat="MM/dd/yyyy"
                                            onChange={(date) => {
                                              validation.setFieldValue(`personEducationDetails[${index}].edu_end_date`, date)
                                            }
                                            }
                                            selected={validation?.values?.personEducationDetails[index].edu_end_date ? new Date(validation.values.personEducationDetails[index].edu_end_date) : ''}
                                          />
                                          <ErrorMessage
                                            name={`personEducationDetails[${index}].edu_end_date`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Row className="justify-content-middle">
                                        <Col sm={10}></Col>

                                        <Col sm={2} style={{ padding: "0 0 16px 0" }}>
                                          {validation.values.personEducationDetails.length === index + 1 ? (
                                            <Button
                                              color="primary add-more-button-right" className="ms-1"
                                              type="button"
                                              onClick={() => arrayHelpers2.push({ name: '', age: '' })}
                                            >
                                              Add more
                                            </Button>
                                          ) : ''}
                                        </Col>

                                      </Row>
                                    </div>

                                  </div>
                                ))}

                              </div>
                            )}
                          />
                        </div>
                        <div className="mt-4 mt-lg-0">
                          <h4>Emergency Contact</h4><br />
                          <FieldArray
                            name="personEmergencyDetails"
                            render={arrayHelpers3 => (
                              <div>
                                {validation.values.personEmergencyDetails.map((item, index) => (
                                  <div key={index + "item"} className="more-item-box">
                                    <div className="remove-item">
                                      <Col sm={1}>
                                        {validation.values.personEmergencyDetails.length > 1 ? (
                                          <>
                                            <i className="bx bx-x bx-sm" onClick={() => arrayHelpers3.remove(index)} ></i>
                                          </>
                                        ) : ''}
                                      </Col>
                                    </div>
                                    <div className="row more-items">
                                      <Col md="4">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor={`personEmergencyDetails[${index}].em_contact_name`}>Name</Label>

                                          <Field type="text"
                                            className="form-control"
                                            placeholder="Contact Name"
                                            name={`personEmergencyDetails[${index}].em_contact_name`} />

                                          <ErrorMessage
                                            name={`personEmergencyDetails[${index}].em_contact_name`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="4">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor={`personEmergencyDetails[${index}].relationship`}>Relationship</Label>

                                          <Field type="text"
                                            className="form-control"
                                            placeholder="Relationship"
                                            name={`personEmergencyDetails[${index}].relationship`} />

                                          <ErrorMessage
                                            name={`personEmergencyDetails[${index}].relationship`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </FormGroup>

                                      </Col>
                                      <Col md="4">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor={`personEmergencyDetails[${index}].em_phone_number`}>Phone Number</Label>

                                          <Field type="text"
                                            className="form-control"
                                            placeholder="Phone Number"
                                            name={`personEmergencyDetails[${index}].em_phone_number`} />

                                          <ErrorMessage
                                            name={`personEmergencyDetails[${index}].em_phone_number`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Row className="justify-content-middle">
                                        <Col sm={10}></Col>
                                        <Col sm={2} style={{ padding: "0 0 16px 0" }}>
                                          {validation.values.personEmergencyDetails.length === index + 1 ? (
                                            <Button
                                              color="primary add-more-button-right" className="ms-1"
                                              type="button"
                                              onClick={() => arrayHelpers3.push({ name: '', age: '' })}
                                            >
                                              Add more
                                            </Button>
                                          ) : ''}
                                        </Col>

                                      </Row>

                                    </div>

                                  </div>
                                ))}
                                {/* <Row className="justify-content-middle">
                                        <Col sm={12}></Col>
                                        <Col sm={2}>
                                            <Button
                                                color="primary" className="ms-1"
                                                type="button"
                                                onClick={() => arrayHelpers3.push({ name: '', age: '' })}
                                            >
                                                Add More
                                            </Button>
                                        </Col>
                                    </Row> */}
                              </div>
                            )}
                          />
                        </div>
                      </React.Fragment>
                    ) :
                      <div className="mt-4 mt-lg-0">
                        <h4>Identification Details</h4><br />
                        <Row>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">First Name *</Label>
                              <Input
                                name="first_name"
                                placeholder="First name"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.first_name || ""}
                                invalid={
                                  validation.touched.first_name && validation.errors.first_name ? true : false
                                }
                              />
                              {validation.touched.first_name && validation.errors.first_name ? (
                                <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Last Name *</Label>
                              <Input
                                name="last_name"
                                placeholder="Last name"
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.last_name || ""}
                                invalid={
                                  validation.touched.last_name && validation.errors.last_name ? true : false
                                }
                              />
                              {validation.touched.last_name && validation.errors.last_name ? (
                                <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Phone Number *</Label>
                              <InputMask
                                name="phone"
                                mask="(999)-999-9999"
                                className={`${validation.touched.phone && validation.errors.phone ? 'form-control is-invalid' : 'form-control'}`}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={
                                  validation.touched.phone && validation.errors.phone ? true : false
                                }
                              >
                                {(inputProps) => (
                                  // <Input  type="tel" className="" />
                                  <Input
                                    {...inputProps}
                                    placeholder="Enter Phone"
                                    type="text"
                                    className="form-control"
                                    id="validationCustom02"

                                  />
                                )}
                              </InputMask>
                              {validation.touched.phone && validation.errors.phone ? (
                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Email Address *</Label>
                              <Input
                                name="email"
                                placeholder="Email"
                                type="email"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={(event) => {
                                  validation.setFieldValue("email", event.target.value);
                                  if (Number(editId) > 0) {
                                  } else {
                                    setApiSuccess(true)
                                    dispatch(validateDuplicateEmailId({ email: event.target.value }))
                                  }
                                }}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email && validation.errors.email || validation.values.validationEmail > 0 ? true : false
                                }
                              />
                              {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                              ) : validation.values.validationEmail > 0 ? (
                                <FormFeedback type="invalid">Email address already exist.</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Employment Type * </Label>
                              <Input
                                name="emp_type"
                                placeholder="Employment Type"
                                type="select"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.emp_type || ""}
                                invalid={
                                  validation.touched.emp_type && validation.errors.emp_type ? true : false
                                }
                              >
                                <option value="">Select employment type</option>
                                <option selected={validation.values.emp_type == "Full Time"} value="Full Time">Full Time</option>
                                <option selected={validation.values.emp_type == "Part Time"} value="Part Time">Part Time</option>
                                <option selected={validation.values.emp_type == "Internship"} value="Internship">Internship</option>
                                <option selected={validation.values.emp_type == "1099"} value="1099">1099</option>
                                <option selected={validation.values.emp_type == "C2C"} value="C2C">C2C</option>

                              </Input>


                              {validation.touched.emp_type && validation.errors.emp_type ? (
                                <FormFeedback type="invalid">{validation.errors.emp_type}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Job Title </Label>
                              <Input
                                name="job_title"
                                placeholder="Job Tile"
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.job_title || ""}
                                invalid={
                                  validation.touched.job_title && validation.errors.job_title ? true : false
                                }
                              />
                              {validation.touched.job_title && validation.errors.job_title ? (
                                <FormFeedback type="invalid">{validation.errors.job_title}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>


                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Visa  </Label>
                              <Input
                                name="visa_type"
                                placeholder="Visa type"
                                type="select"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.visa_type || ""}
                                invalid={
                                  validation.touched.visa_type && validation.errors.visa_type ? true : false
                                }
                              >  <option value="">Select visa</option>
                                <option selected={validation.values.visa_type == "B1"} value="B1">B1</option>
                                <option selected={validation.values.visa_type == "B2"} value="B2">B2</option>
                                <option selected={validation.values.visa_type == "H1B"} value="H1B">H1B</option>
                                <option selected={validation.values.visa_type == "H2B"} value="H2B">H2B</option>
                                <option selected={validation.values.visa_type == "L1A"} value="L1A">L1A</option>
                                <option selected={validation.values.visa_type == "L1B"} value="L1B">L1B</option>
                                <option selected={validation.values.visa_type == "OPT"} value="OPT">OPT</option>
                                <option selected={validation.values.visa_type == "STEM OPT"} value="STEM OPT">STEM OPT</option>
                                <option selected={validation.values.visa_type == "CPT"} value="CPT">CPT</option>
                                <option selected={validation.values.visa_type == "H4-EAD"} value="H4-EAD">H4-EAD</option>
                                <option selected={validation.values.visa_type == "GC-EAD"} value="GC-EAD">GC-EAD</option>
                                <option selected={validation.values.visa_type == "Permanent Resident"} value="Permanent Resident">Permanent Resident</option>
                                <option selected={validation.values.visa_type == "H1B Receipt"} value="H1B Receipt">H1B Receipt</option>
                                <option selected={validation.values.visa_type == "Stem Receipt"} value="Stem Receipt">Stem Receipt</option>
                              </Input>


                              {validation.touched.visa_type && validation.errors.visa_type ? (
                                <FormFeedback type="invalid">{validation.errors.visa_type}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>


                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Expire Date </Label>

                              <ReactDatePicker
                                showYearDropdown={true}
                                showIcon
                                placeholderText="MM/dd/yyyy"
                                className={`form-control ${validation.touched.expiration_date && validation.errors.expiration_date ? 'is-invalid' : ''}`}
                                name={`expiration_date`}
                                id={`expiration_date`}
                                dateFormat="MM/dd/yyyy"
                                onChange={(date) => {
                                  validation.setFieldValue(`expiration_date`, date)
                                }
                                }
                                selected={validation?.values?.expiration_date ? new Date(validation.values.expiration_date) : ''}
                              />  {validation.touched.expiration_date && validation.errors.expiration_date ? (
                                <FormFeedback type="invalid">{validation.errors.expiration_date}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Hire Date </Label>
                              <ReactDatePicker
                                showYearDropdown={true}
                                showIcon
                                placeholderText="MM/dd/yyyy"
                                className={`form-control ${validation.touched.hire_date && validation.errors.hire_date ? 'is-invalid' : ''}`}
                                name={`hire_date`}
                                id={`hire_date`}
                                dateFormat="MM/dd/yyyy"
                                onChange={(date) => {
                                  validation.setFieldValue(`hire_date`, date)
                                }
                                }
                                selected={validation?.values?.hire_date ? new Date(validation.values.hire_date) : ''}
                              />
                              {validation.touched.hire_date && validation.errors.hire_date ? (
                                <FormFeedback type="invalid">{validation.errors.hire_date}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                        </Row>


                      </div>
                    }
                    <Row style={{ float: "right", marginTop: "18px" }}>
                      <Col >
                        <span className="mt-4">
                          <button type="reset" className="btn btn-secondary" color="secondary" onClick={() => navigate(-1)}>
                            Cancel
                          </button>
                        </span>
                        &nbsp;&nbsp;
                        <span className="mt-4">
                          <button type="submit" className="btn btn-primary">
                            Save
                          </button>
                        </span>
                      </Col>
                    </Row>
                  </Form>
                </FormikProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>



      </div>
    </React.Fragment>
  )
};
