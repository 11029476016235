import React from 'react';
import Label from "./Label";
import {
    FormGroup
} from "reactstrap";
import { checkValidityClass } from "../Shared/utility";
const ReadOnly = ({ name, label, type, placeholder, className, required, invalidMessage, value, content, onChanged }) => {
    var newValue = "";
    if (content?.upload_type == "file"){
        if (content?.value && (typeof content?.value == 'object' || Array.isArray(content?.value))) {
            Array.from(content?.value).map(val => {
                newValue += '<i class="bx bx-upload"></i>  <a target="_blank" rel="noreferrer" href=' + val?.url + ' className="align-middle font-size-15">' + val?.name + '</a>'
                newValue += "<br/>"
            });
        } else {
            newValue = (value ?? "");
        }
    }
    else {
        newValue = (value ?? "");
    }
    const labelname = content?.label ? content?.label?.replaceAll('_', ' ') : "";
    
    const selectFieldTypeClass = (content?.select_field_type == "label") ? "" : "";
    return (
        <FormGroup className={selectFieldTypeClass}>
            <Label
                label={labelname}
                name={name}
                isRequired={content.required}
            />

            {(newValue) ? (
                <>
                    <div className="flex-grow-1">
                        <div dangerouslySetInnerHTML={{ __html: newValue }} />
                    </div>
                </>
            ) : ""}

        </FormGroup>
    );
};


export default ReadOnly