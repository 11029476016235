/* USERS */
export const GET_COMMON_USERS = "GET_COMMON_USERS"
export const GET_COMMON_USERS_SUCCESS = "GET_COMMON_USERS_SUCCESS"
export const GET_COMMON_USERS_FAIL = "GET_COMMON_USERS_FAIL"

export const GET_COMMON_USER_DETAIL = "GET_COMMON_USER_DETAIL"
export const GET_COMMON_USER_DETAIL_SUCCESS = "GET_COMMON_USER_DETAIL_SUCCESS"
export const GET_COMMON_USER_DETAIL_FAIL = "GET_COMMON_USER_DETAIL_FAIL"

export const ADD_COMMON_USER_DETAIL = "ADD_COMMON_USER_DETAIL"
export const ADD_COMMON_USER_DETAIL_SUCCESS = "ADD_COMMON_USER_DETAIL_SUCCESS"
export const ADD_COMMON_USER_DETAIL_FAIL = "ADD_COMMON_USER_DETAIL_FAIL"

export const DELETE_COMMON_USER_DETAIL = "DELETE_COMMON_USER_DETAIL"
export const DELETE_COMMON_USER_DETAIL_SUCCESS = "DELETE_COMMON_USER_DETAIL_SUCCESS"
export const DELETE_COMMON_USER_DETAIL_FAIL = "DELETE_COMMON_USER_DETAIL_FAIL"

export const UPDATE_COMMON_USER_DETAIL = "UPDATE_COMMON_USER_DETAIL"
export const UPDATE_COMMON_USER_DETAIL_SUCCESS = "UPDATE_COMMON_USER_DETAIL_SUCCESS"
export const UPDATE_COMMON_USER_DETAIL_FAIL = "UPDATE_COMMON_USER_DETAIL_FAIL"
