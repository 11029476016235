import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";


import { getCaseDependentsAttorney, getCaseFormsList, saveCaseforms } from "../../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";

const AddForm = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const caseId = queryParams.get('caseId'); // Read the 'id' query parameter from the URL

    useEffect(() => {
        dispatch(getCaseFormsList());
    }, [dispatch]);

    const { CaseFormsList, savecaseforms, casedependents } = useSelector((state) => ({
        CaseFormsList: state.CaseFormsList.CaseFormsList,
        savecaseforms: state.AttorneyCases.savecaseforms || {},
        casedependents: state.AttorneyCases.casedependents || [],

    }));


    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            case_id: caseId,
            form_id: '',
            group: '',
            dpnd_id: ''
        },
        validationSchema: Yup.object({
            form_id: Yup.string().required("Please Select Status").nullable(),
            group: Yup.string().when('form_id', {
                is: (val) => val !== null && val?.includes("G-28"),
                then: Yup.string()
                    .required("Please Select the Group").nullable(),
                otherwise: () => Yup.string().nullable(),
            }),
            dpnd_id: Yup.string().when('form_id', {
                is: (val) => val !== null && val?.includes("I-539A"),
                then: Yup.string()
                    .required("Please Select the dependent").nullable(),
                otherwise: () => Yup.string().nullable(),
            }),
        }),
        onSubmit: (values) => {

            if (caseId > 0) {
                dispatch(saveCaseforms(values));
            }
            setTimeout(() => {
                navigate('/attorney/view-case?activetab=forms&caseId=' + caseId);
            }, 100);
        }
    });
    let title = caseId ? 'Add Form' : 'Add Form';
    const BreadcrumbData = [
        { name: 'Cases', url: '/attorney/Cases' },
        { name: 'View Case', url: '/attorney/view-case?caseId=' + caseId },
        { name: title, url: '/attorney/add-form' }
    ]
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Add Form</h4>

                                </CardHeader>

                                <CardBody className="p-4">
                                    <Form className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        {/* import TextualInputs */}
                                        <div className="mt-4 mt-lg-0">

                                            <Row>

                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="form_id">Select the Form *</Label>
                                                        <Input
                                                            name="form_id"
                                                            placeholder=""
                                                            type="select"
                                                            className="form-control"
                                                            id="form_id"
                                                            onChange={(e) => {
                                                                validation.handleChange(e);
                                                                if (e.target?.value == "I-539A") {
                                                                    dispatch(getCaseDependentsAttorney({ case_id: caseId }));
                                                                }
                                                            }}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.form_id || ""}
                                                            invalid={
                                                                validation.touched.form_id && validation.errors.form_id ? true : false
                                                            }
                                                        >
                                                            <option value="">--Select Form--</option>
                                                            {CaseFormsList.map(row => (
                                                                <option key={row.form_id} value={row.form_id == "1" || row.form_id == "7" ? row.form_name : row.form_id}>
                                                                    {row.form_name || row.name}
                                                                </option>
                                                            ))}
                                                            <option value="Blank Page">Blank Page</option>
                                                        </Input>


                                                        {validation.touched.form_id && validation.errors.form_id ? (
                                                            <FormFeedback type="invalid">{validation.errors.form_id}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                {validation.values.form_id && validation.values.form_id == "G-28" ? (
                                                    <Row>
                                                        <Col md="4">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="group">Select relevant form for G28</Label>
                                                                <Input
                                                                    name="group"
                                                                    type="select"
                                                                    className="form-control"
                                                                    id="group"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.group || ""}
                                                                    invalid={
                                                                        validation.values.form_id && validation.values.form_id == "G-28" && validation.touched.group && validation.errors.group ? true : false
                                                                    }
                                                                >
                                                                    <option value="">Please select group</option>
                                                                    <option value="i129">I-129</option>
                                                                    <option value="i539">I-539</option>
                                                                    <option value="i140">I-140</option>
                                                                    <option value="i130">I-130</option>
                                                                    <option value="i765">I-765</option>
                                                                </Input>
                                                                {validation.touched.group && validation.errors.group ? (
                                                                    <FormFeedback type="invalid">{validation.errors.group}</FormFeedback>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                ) : null}
                                                {validation.values.form_id && validation.values.form_id == "I-539A" ? (
                                                    <Row>
                                                        <Col md="4">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="dpnd_id">Select dependent</Label>
                                                                <Input
                                                                    name="dpnd_id"
                                                                    type="select"
                                                                    className="form-control"
                                                                    id="dpnd_id"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.dpnd_id || ""}
                                                                    invalid={
                                                                        validation.values.form_id && validation.values.form_id == "I-539A" && validation.touched.dpnd_id && validation.errors.dpnd_id ? true : false
                                                                    }
                                                                >
                                                                    <option value="">Please select dependent</option>
                                                                    {casedependents.map(dependent => (
                                                                        <option key={dependent.case_type_id} value={dependent.dependent_id}>
                                                                            {dependent.first_name + " " + dependent.last_name}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                                {validation.touched.dpnd_id && validation.errors.dpnd_id ? (
                                                                    <FormFeedback type="invalid">{validation.errors.dpnd_id}</FormFeedback>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                ) : null}

                                            </Row>

                                            <Row className="mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-input"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                </Label>
                                                <Col sm={2}>
                                                    <div style={{ float: "right" }}>
                                                        <Button type="reset" color="secondary" onClick={() => navigate(-1)}>
                                                            Cancel
                                                        </Button>
                                                        {" "}
                                                        <Button type="submit" color="primary" className="ms-1">
                                                            Save
                                                        </Button>
                                                    </div>

                                                </Col>
                                            </Row>



                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment >
    );
};

export default AddForm;