/* QNNRTYPES */
export const GET_QNNRTYPES = "GET_QNNRTYPES"
export const GET_QNNRTYPES_SUCCESS = "GET_QNNRTYPES_SUCCESS"
export const GET_QNNRTYPES_FAIL = "GET_QNNRTYPES_FAIL"

export const GET_QNNRTYPE_DETAIL = "GET_QNNRTYPE_DETAIL"
export const GET_QNNRTYPE_DETAIL_SUCCESS = "GET_QNNRTYPE_DETAIL_SUCCESS"
export const GET_QNNRTYPE_DETAIL_FAIL = "GET_QNNRTYPE_DETAIL_FAIL"

export const ADD_QNNRTYPE_DETAIL = "ADD_QNNRTYPE_DETAIL"
export const ADD_QNNRTYPE_DETAIL_SUCCESS = "ADD_QNNRTYPE_DETAIL_SUCCESS"
export const ADD_QNNRTYPE_DETAIL_FAIL = "ADD_QNNRTYPE_DETAIL_FAIL"

export const DELETE_QNNRTYPE_DETAIL = "DELETE_QNNRTYPE_DETAIL"
export const DELETE_QNNRTYPE_DETAIL_SUCCESS = "DELETE_QNNRTYPE_DETAIL_SUCCESS"
export const DELETE_QNNRTYPE_DETAIL_FAIL = "DELETE_QNNRTYPE_DETAIL_FAIL"

export const UPDATE_QNNRTYPE_DETAIL = "UPDATE_QNNRTYPE_DETAIL"
export const UPDATE_QNNRTYPE_DETAIL_SUCCESS = "UPDATE_QNNRTYPE_DETAIL_SUCCESS"
export const UPDATE_QNNRTYPE_DETAIL_FAIL = "UPDATE_QNNRTYPE_DETAIL_FAIL"
