import {
  GET_EMPLOYER_COMPANY,
  GET_EMPLOYER_COMPANY_FAIL,
  GET_EMPLOYER_COMPANY_SUCCESS,
  GET_EMPLOYER_COMPANY_DETAIL,
  GET_EMPLOYER_COMPANY_DETAIL_FAIL,
  GET_EMPLOYER_COMPANY_DETAIL_SUCCESS,
  UPDATE_EMPLOYER_COMPANY_DETAIL_FAIL,
  UPDATE_EMPLOYER_COMPANY_DETAIL_SUCCESS,
  UPDATE_EMPLOYER_COMPANY_DETAIL,
  DELETE_EMPLOYER_COMPANY_DETAIL_FAIL,
  DELETE_EMPLOYER_COMPANY_DETAIL_SUCCESS,
  DELETE_EMPLOYER_COMPANY_DETAIL,
  ADD_EMPLOYER_COMPANY_DETAIL_SUCCESS,
  ADD_EMPLOYER_COMPANY_DETAIL,
  ADD_EMPLOYER_COMPANY_DETAIL_FAIL

} from "./actionTypes"

export const getEmployerCompany = () => ({
  type: GET_EMPLOYER_COMPANY,
})

export const getEmployerCompanySuccess = Company => ({
  type: GET_EMPLOYER_COMPANY_SUCCESS,
  payload: Company,
})

export const getEmployerCompanyFail = error => ({
  type: GET_EMPLOYER_COMPANY_FAIL,
  payload: error,
})

export const getEmployerCompanyDetail = CompanyId => ({
  type: GET_EMPLOYER_COMPANY_DETAIL,
  CompanyId,
})

export const getEmployerCompanyDetailSuccess = Company => ({
  type: GET_EMPLOYER_COMPANY_DETAIL_SUCCESS,
  payload: Company,
})

export const getEmployerCompanyDetailFail = error => ({
  type: GET_EMPLOYER_COMPANY_DETAIL_FAIL,
  payload: error,
})

export const  addEmployerCompanyDetail = (data) => ({
  type: ADD_EMPLOYER_COMPANY_DETAIL,
  payload: data
})

export const addEmployerCompanydetailSuccess = (data) => ({
  type: ADD_EMPLOYER_COMPANY_DETAIL_SUCCESS,
  payload: data
})

export const addEmployerCompanydetailFail = (error) => ({
  type: ADD_EMPLOYER_COMPANY_DETAIL_FAIL,
  payload: error
})

export const EmployerCompanyDelete = (data) => ({
  type: DELETE_EMPLOYER_COMPANY_DETAIL,
  payload: data
})

export const EmployerCompanyDeleteSuccess = (data) => ({
  type: DELETE_EMPLOYER_COMPANY_DETAIL_SUCCESS,
  payload: data
})

export const EmployerCompanyDeleteFail = (error) => ({
  type: DELETE_EMPLOYER_COMPANY_DETAIL_FAIL,
  payload: error
})

export const EmployerCompanyUpdate = (data) => ({
  type: UPDATE_EMPLOYER_COMPANY_DETAIL,
  payload: data
})

export const EmployerCompanyUpdateSuccess = (data) => ({
  type: UPDATE_EMPLOYER_COMPANY_DETAIL_SUCCESS,
  payload: data
})

export const EmployerCompanyUpdateFail = (error) => ({
  type: UPDATE_EMPLOYER_COMPANY_DETAIL_FAIL,
  payload: error
})
