import React, { createContext, useCallback, useContext, useEffect, useState } from "react"
import { Alert } from "reactstrap"
import './Alert.css';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAlertMessage } from "../../store/common/actions";

const AlertContext = createContext()

export function AlertComponent(props) {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState()
  const [alertType, setAlertType] = useState('info')
  const [alertIcon, setAlertIcon] = useState('label-icon mdi mdi-block-helper')

  const { alert } = useSelector((state) => ({
    alert: state.CommonAlertMessage.alert || '',
  }));
  const dispatch = useDispatch();
  var milliseconds = 10000;

  useEffect(() => {
    if (alert?.message) {
      if (alert.type === 'danger') {
        if (alert?.message?.response?.status == 422) {
          if (alert?.message?.response?.data?.errors) {
            for (var rerror in alert?.message?.response?.data?.errors) {
              if (alert?.message?.response?.data?.errors.hasOwnProperty(rerror)) {
                milliseconds = 10000;
                setMessage(alert?.message?.response?.data?.errors[rerror]?.[0]);
              }
            }
          }
        } if (alert?.message?.response?.status == 400) {
          setMessage(alert?.message?.response?.data?.message);
        } else {
          setMessage('Something went wrong please try again!')
        }
      }
      else {
        setMessage(alert.message.message)
      }

      if (alert.type === 'danger') {
        setAlertIcon('label-icon mdi mdi-block-helper')
      }
      else{
        setAlertIcon('label-icon mdi mdi-check-all')
      }
      setAlertType(alert.type)
      setOpen(true)
      window.setTimeout(() => {
        setOpen(false)
        dispatch(setAlertMessage({}))
      }, milliseconds);
    }
  }, [alert])

  const handleAlertClose = useCallback(() => {
    setOpen(false)
    dispatch(setAlertMessage({}))
  }, [setOpen])

  return (
    <>
      {props.childern}
      {message &&
        <Alert color={alertType} isOpen={open} toggle={handleAlertClose} className="alert-label-icon label-arrow alert-message">
          <i className={alertIcon}></i>{message}
        </Alert>
      }
    </>
  )
}


export function useAlert() {
  const context = useContext(AlertContext);
  if (!context)
    throw new Error('`useAlert()` must be called inside an `AlertProvider` child.')

  return context
}