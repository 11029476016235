import {
  GENERATE_FORM_FAIL,
  GENERATE_FORM_SUCCESS,
  GET_EMPLOYERCASES_FAIL,
  GET_EMPLOYERCASES_SUCCESS,
  GET_EMPLOYER_PAF_FAIL,
  GET_EMPLOYER_PAF_SUCCESS,
  SEND_PAF_EMPLOYEE_NOTIFICATION_FAIL,
  SEND_PAF_EMPLOYEE_NOTIFICATION_SUCCESS,

  CREATE_PAF_BUNDLE_FAIL,
  CREATE_PAF_BUNDLE_SUCCESS,

  GET_EMPLOYER_PAF_FORM_FAIL,
  GET_EMPLOYER_PAF_FORM_SUCCESS,
  GET_EMPLOYER_PAF_FORMS_FAIL,
  GET_EMPLOYER_PAF_FORMS_SUCCESS,
  UPDATE_EMPLOYER_PAF_FORM_FAIL,
  UPDATE_EMPLOYER_PAF_FORM_SUCCESS,
  GET_EMPLOYER_PAFINFO_FAIL,
  GET_EMPLOYER_PAFINFO_SUCCESS,
  UPDATE_EMPLOYER_PAFINFO_FAIL,
  UPDATE_EMPLOYER_PAFINFO_SUCCESS,
  GET_EMPLOYERCASE_DETAIL_SUCCESS,
  GET_EMPLOYERCASE_DETAIL_FAIL,
  UPDATE_EMPLOYERCASE_DETAIL_FAIL,
  UPDATE_EMPLOYERCASE_DETAIL_SUCCESS,
  DELETE_EMPLOYERCASE_DETAIL_FAIL,
  DELETE_EMPLOYERCASE_DETAIL_SUCCESS,
  ADD_EMPLOYERCASE_DETAIL_SUCCESS,
  ADD_EMPLOYERCASE_DETAIL_FAIL,
  GET_EMPLOYERQNNRTYPES_SUCCESS,
  GET_EMPLOYERQNNRTYPES_FAIL,
  GET_EMPLOYERCASETYPES_SUCCESS,
  GET_EMPLOYERCASETYPES_FAIL,
  GET_PAF_TEMPLATE_VARIABLES_SUCCESS,
  GET_PAF_TEMPLATE_VARIABLES_FAIL
} from "./actionTypes"

const INIT_STATE = {
  generateformemployer: [],
  employercase: [],
  employerpaf: [],
  employerpafinfo: {},
  employerpafform: {},
  employerpafforms: [],
  updateemployerpafinfo: {},
  updateemployerpafinfoerror: {},
  updateemployerpafform: {},
  updateemployerpafformerror: {},
  employercaseDetail: {},
  employerCaseTypes: [],
  employerQnnrTypes: [],
  sendpafemployeenotification: {},
  createpafbundle: {},
  paftemplatevariables: {},
  error: {},
}

const EmployerCases = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case GENERATE_FORM_SUCCESS:
    //   return {
    //     ...state,
    //     generateform: action.payload,
    //   }

    // case GENERATE_FORM_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    case GET_EMPLOYERCASES_SUCCESS:
      return {
        ...state,
        employercase: action.payload,
      }

    case GET_EMPLOYERCASES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYER_PAF_SUCCESS:
      return {
        ...state,
        employerpaf: action.payload,
      }

    case GET_EMPLOYER_PAF_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SEND_PAF_EMPLOYEE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        sendpafemployeenotification: action.payload,
      }

    case SEND_PAF_EMPLOYEE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case CREATE_PAF_BUNDLE_SUCCESS:
      return {
        ...state,
        createpafbundle: action.payload,
      }

    case CREATE_PAF_BUNDLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYER_PAF_FORM_SUCCESS:
      return {
        ...state,
        employerpafform: action.payload,
      }

    case GET_EMPLOYER_PAF_FORM_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYER_PAF_FORMS_SUCCESS:
      return {
        ...state,
        employerpafforms: action.payload,
      }

    case GET_EMPLOYER_PAF_FORMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_EMPLOYER_PAF_FORM_SUCCESS:
      return {
        ...state,
        updateemployerpafform: action.payload,
      }

    case UPDATE_EMPLOYER_PAF_FORM_FAIL:
      return {
        ...state,
        updateemployerpafformerror: action.payload,
      }

    case GET_EMPLOYER_PAFINFO_SUCCESS:
      return {
        ...state,
        employerpafinfo: action.payload,
      }

    case GET_EMPLOYER_PAFINFO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_EMPLOYER_PAFINFO_SUCCESS:
      return {
        ...state,
        updateemployerpafinfo: action.payload,
      }

    case UPDATE_EMPLOYER_PAFINFO_FAIL:
      return {
        ...state,
        updateemployerpafinfoerror: action.payload,
      }

    case GET_EMPLOYERCASE_DETAIL_SUCCESS:
      return {
        ...state,
        empoyercaseDetail: action.payload,
      }

    case GET_EMPLOYERCASE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_EMPLOYERCASE_DETAIL_SUCCESS:
      return {
        ...state,
        employercase: [...state.employercase, action.payload],
      }

    case ADD_EMPLOYERCASE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_EMPLOYERCASE_DETAIL_SUCCESS:
      return {
        ...state,
        employercase: state.employercase.filter((item) => item.id !== action.payload),
      };

    case DELETE_EMPLOYERCASE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_EMPLOYERCASE_DETAIL_SUCCESS:
      return {
        ...state,
        employercase: state.casetypes.map((row) =>
          row.id.toString() === action.payload.id.toString()
            ? { row, ...action.payload }
            : row
        ),
      };

    case UPDATE_EMPLOYERCASE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_EMPLOYERCASETYPES_SUCCESS:
      return {
        ...state,
        employerCaseTypes: action.payload,
      }

    case GET_EMPLOYERCASETYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case GET_EMPLOYERQNNRTYPES_SUCCESS:
      return {
        ...state,
        employerQnnrTypes: action.payload,
      }

    case GET_EMPLOYERQNNRTYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PAF_TEMPLATE_VARIABLES_SUCCESS:
      return {
        ...state,
        paftemplatevariables: action.payload,
      }

    case GET_PAF_TEMPLATE_VARIABLES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default EmployerCases
