import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_TEMPLATE_DETAIL, DELETE_TEMPLATE_DETAIL, GET_TEMPLATES, GET_TEMPLATE_DETAIL, GET_TEMPLATE_SETTINGS, SEND_TEMPLATE, UPDATE_TEMPLATE_DETAIL, UPDATE_TEMPLATE_PDF, UPLOAD_TEMPLATE_ATTACHMENT } from "./actionTypes"
import {
  addTemplatedetailFail,
  addTemplatedetailSuccess,
  getTemplateDetailFail,
  getTemplateDetailSuccess,
  getTemplateSettingsFail,
  getTemplateSettingsSuccess,
  getTemplatesFail,
  getTemplatesSuccess,
  templateDeleteFail,
  templateDeleteSuccess,
  templateUpdateFail,
  templateUpdateSuccess,
  updateTemplatePdfFail,
  updateTemplatePdfSuccess,
  uploadTemplateAttachmentFail,
  uploadTemplateAttachmentSuccess
} from "./actions"
import { changePreloader } from "../../store/actions"

//Include Both Helper File with needed methods
import { addTemplate, deleteTemplate, getTemplateDetail, getTemplateSettingsApiHelper, getTemplates, updateTemplate, updateTemplatePdf, uploadTemplateAttachmentApiHelper } from "../../helpers/apibackend_helper"
import { setAlertMessage } from "../common/actions"
const templateList = [
  {
    id: 1,
    templateId: "#MN0215",
    founder: "Marion Burton",
    founderAt: "Skote",
    templateID: "14251",
    company: "Skote Dashboard UI",
    templatePrice: "1455",
    Amount: "$26.30",
    status: "Paid",
    date: "10 Oct, 2020",
    color: "success",
    orderId: "12345",
    billingAddress: "John Smith, 1234 Main, Apt. 4B, Springfield ST 54321",
    shippingAddress: "Kenny Rigdon, 1234 Main, Apt. 4B, Springfield ST 54321",
    card: "Visa ending **** 4242",
    email: "jsmith@email.com",
    orderDate: "October 16, 2019",
    orderSummary: {}
  },
]

function* fetchTemplates({ payload: data }) {
  try {
    const response = yield call(getTemplates, data)
    yield put(getTemplatesSuccess(response.data))
  } catch (error) {
    yield put(getTemplatesFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchTemplateDetail({ templateId }) {
  try {
    const response = yield call(getTemplateDetail, templateId)
    yield put(getTemplateDetailSuccess(response.data))
  } catch (error) {
    yield put(getTemplateDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* addTemplateDetail({ payload: templatedata }) {
  try {
    const response = yield call(addTemplate, templatedata)
    yield put(addTemplatedetailSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addTemplatedetailFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}


function* fetchUpdateTemplatePdf({ payload: templatedata }) {
  try {
    const response = yield call(updateTemplatePdf, templatedata)
    yield put(updateTemplatePdfSuccess(response.data))
  } catch (error) {
    yield put(updateTemplatePdfFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}


function* fetchTemplateSettings({ payload: templatedata }) {
  try {
    const response = yield call(getTemplateSettingsApiHelper, templatedata)
    yield put(getTemplateSettingsSuccess(response.data))
  } catch (error) {
    yield put(getTemplateSettingsFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* uploadTemplateAttachments({ payload: templatedata }) {
  try {
    const response = yield call(uploadTemplateAttachmentApiHelper, templatedata)
    yield put(uploadTemplateAttachmentSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(uploadTemplateAttachmentFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteTemplate({ payload: data }) {
  try {
    const response = yield call(deleteTemplate, data);
    yield put(templateDeleteSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(templateDeleteFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdatetemplate({ payload: data }) {
  try {
    const response = yield call(updateTemplate, data);
    yield put(templateUpdateSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(templateUpdateFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* templateSaga() {
  yield takeEvery(GET_TEMPLATES, fetchTemplates)
  yield takeEvery(GET_TEMPLATE_DETAIL, fetchTemplateDetail)
  yield takeEvery(ADD_TEMPLATE_DETAIL, addTemplateDetail)
  yield takeEvery(UPDATE_TEMPLATE_PDF, fetchUpdateTemplatePdf)
  yield takeEvery(GET_TEMPLATE_SETTINGS, fetchTemplateSettings)
  yield takeEvery(UPLOAD_TEMPLATE_ATTACHMENT, uploadTemplateAttachments)
  yield takeEvery(DELETE_TEMPLATE_DETAIL, onDeleteTemplate)
  yield takeEvery(UPDATE_TEMPLATE_DETAIL, onUpdatetemplate)
}

export default templateSaga
