import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_EMPLOYEECASES, GET_EMPLOYEECASE_DETAIL, ADD_EMPLOYEECASE_DETAIL, DELETE_EMPLOYEECASE_DETAIL,
  UPDATE_EMPLOYEECASE_DETAIL, GET_EMPLOYEECASETYPES, GET_EMPLOYERQNNRTYPES, GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO_SUCCESS,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO_FAIL,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_SUCCESS,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_FAIL,
  ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_SUCCESS,
  ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_FAIL,
  ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO,
  GET_EMPLOYEE_COUNTRYS,
  GET_EMPLOYEE_STATES,
  GET_EMPLOYEE_NOTIFICATIONS,
  GET_EMPLOYEE_PAF_ESIGN_FORM,
  UPDATE_EMPLOYEE_PAF_ESIGN_FORM,
  UPLOAD_QNNR_DOCUMENT,
  READ_NOTIFICATION,
  DELETE_QNNR_DOCUMENT
} from "./actionTypes"
import {
  getEmployeeCasesSuccess,
  getEmployeeCasesFail,
  getEmployeeCaseDetailSuccess,
  getEmployeeCaseDetailFail,
  addEmployeeCasedetailSuccess,
  addEmployeeCasedetailFail,
  EmployeeCaseDeleteSuccess,
  EmployeeCaseDeleteFail,
  EmployeeCaseUpdateSuccess,
  EmployeeCaseUpdateFail,
  getEmployeeCaseTypesSuccess,
  getEmployeeCaseTypesFail,
  getEmployerQnnrtypesSuccess,
  getEmployerQnnrtypesFail,
  getEmployeeCaseQnnrTemplateInfoSucess,
  getEmployeeCaseQnnrTemplateInfoFail,
  getEmployeeCaseQnnrTemplateGroupInfoSucess,
  getEmployeeCaseQnnrTemplateGroupInfoFail,
  addEmployeeCaseQnnrTemplateGroupInfoSucess,
  addEmployeeCaseQnnrTemplateGroupInfoFail,
  getEmployeeCountrysSuccess,
  getEmployeeStatesSuccess,
  getEmployeeNotificationsSuccess,
  getEmployeeNotificationsFail,
  getEmployeePAFEsignFormSuccess,
  getEmployeePAFEsignFormFail,
  updateEmployeePafEsignFormSuccess,
  updateEmployeePafEsignFormFail,
  uploadQnnrDocumentSucess,
  uploadQnnrDocumentFail,
  readNotificationSuccess,
  readNotificationFail,
  deleteQnnrDocumentSucess,
  deleteQnnrDocumentFail

} from "./actions"

//Include Both Helper File with needed methods
import {
  getEmployeeCases, getEmployeeCaseDetail, addEmployeeCase, deleteEmployeeCase,
  updateEmployeeCase, getEmployeeCaseQnnrTemplateInfo, addEmployeeCaseQnnrTemplateInfo, getEmployeeStatesList, getEmployeeCountrysList, getEmployeeDashboardNotifications, getEmployeePafEsignDetails,
  updateEmployeePafEsignForm,
  uploadQnnrDocument,
  readNotificationApi,
  deleteQnnrDocument
} from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"
import { changePreloader } from "../../../store/actions"

function* fetchEmployeeCases({ payload: user }) {
  try {
    const response = yield call(getEmployeeCases)
    yield put(getEmployeeCasesSuccess(response.data))
  } catch (error) {
    yield put(getEmployeeCasesFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchEmployeeCaseDetail({ EmployeeCaseId }) {
  try {
    const response = yield call(getEmployeeCaseDetail, EmployeeCaseId)
    yield put(getEmployeeCaseDetailSuccess(response.data))
  } catch (error) {
    yield put(getEmployeeCaseDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* addEmployeeCaseDetail({ payload: user }) {
  try {
    const response = yield call(addEmployeeCase, user)
    yield put(addEmployeeCasedetailSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addEmployeeCasedetailFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteEmployeeCase({ payload: data }) {
  try {
    const response = yield call(deleteEmployeeCase, data);
    yield put(EmployeeCaseDeleteSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(EmployeeCaseDeleteFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateEmployeeCase({ payload: data }) {
  try {
    const response = yield call(updateEmployeeCase, data);
    yield put(EmployeeCaseUpdateSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(EmployeeCaseUpdateFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}
function processGroupResponseData(response) {
  // Helper function to fix a JSON string by removing extra escapes
  const fixJsonString = (str) => {
    str = str.replace(/\\"/g, '"');
    str = str.replace(/^"(.*)"$/, '$1');
    const regex = /\,(?!\s*?[\{\[\"\'\w])/g;
    const fixedStr = str.replace(regex, '');
    let result = JSON.parse(fixedStr);
    return Array.isArray(result) ? result[0] : result;
  }

  // Helper function to remove whitespace and make a string lowercase
  const removeWhiteSpace = (str) => {
    return str != undefined ? str.replace(/(?<=\S)\s+(?=\S)/g, "").toLowerCase() : Math.random(0, 1000);
  }

  // Helper function to check if a string is empty, null, or undefined
  const isValidCheck = (str) => {
    return str === undefined || str === null || str === '{}' || str === '';
  }
  let data = response.data;
  return data;

}

function* fetchEmployeeCaseQnnrTemplateInfo({ payload: user }) {
  try {
    const response = yield call(getEmployeeCaseQnnrTemplateInfo, user)
    const data = response.data;
    const keyName = (data?.groupProgress) ? data.groupProgress : null;
    var activeTabProgress = 1;
    const groupsArray = Object.entries(data.groups).map(([key, value], i) => {
      if (keyName == value) {
        activeTabProgress = i + 1;
      }
      return {
        id: i + 1,
        key,
        value,
      }
    });
    data.groups = groupsArray;
    data.activeTabProgress = activeTabProgress;
    // data.qnnrProgress = data?.qunnrProgress;
    data.qnnr_template_id = data?.qnnr_template_id;
    data.case_id = data?.case_id;

    var selected_group_progress = keyName ?? groupsArray[0]?.value;
    // console.log("selected_group_progress",selected_group_progress)
    const groupInfoResponse = yield call(getEmployeeCaseQnnrTemplateInfo, { ...user, "group_id": selected_group_progress });

    const finalResponse = { templateInfo: data, groupInfo: processGroupResponseData(groupInfoResponse) };
    yield put(getEmployeeCaseQnnrTemplateInfoSucess(finalResponse))
    // yield put(getEmployeeCaseQnnrTemplateGroupInfoSucess())


  } catch (error) {
    yield put(getEmployeeCaseQnnrTemplateInfoFail(error))
  }
}

function* fetchEmployeeCaseQnnrTemplateGroupInfo({ payload: user }) {
  try {
    // Fetch data from an API using a generator function
    const response = yield call(getEmployeeCaseQnnrTemplateInfo, user);
    yield put(getEmployeeCaseQnnrTemplateGroupInfoSucess(processGroupResponseData(response)))
  } catch (error) {
    yield put(getEmployeeCaseQnnrTemplateGroupInfoFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* addEmployeeCaseQnnrTemplateGroupInfo({ payload: user }) {
  try {
    const response = yield call(addEmployeeCaseQnnrTemplateInfo, user)

    yield put(addEmployeeCaseQnnrTemplateGroupInfoSucess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addEmployeeCaseQnnrTemplateGroupInfoFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* fetchUploadQnnrDocument({ payload: user }) {
  try {
    const response = yield call(uploadQnnrDocument, user)
    yield put(uploadQnnrDocumentSucess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(uploadQnnrDocumentFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* deleteUploadedQnnrDocument({ payload: user }) {
  try {
    const response = yield call(deleteQnnrDocument, user)
    yield put(deleteQnnrDocumentSucess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(deleteQnnrDocumentFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchEmployeeCountrys({ EmployeeCaseId }) {
  try {
    const response = yield call(getEmployeeCountrysList)
    yield put(getEmployeeCountrysSuccess(response.data))
  } catch (error) {
    yield put(getEmployeeCaseDetailFail(error))
  }
}

function* fetchEmployeeStates(selectedCountry) {
  try {
    const response = yield call(getEmployeeStatesList, { "country_id": selectedCountry.payload })
    yield put(getEmployeeStatesSuccess(response.data))
  } catch (error) {
    yield put(getEmployeeCaseDetailFail(error))
  }
}

function* fetchEmployeeNotifications() {
  try {
    const response = yield call(getEmployeeDashboardNotifications)
    yield put(getEmployeeNotificationsSuccess(response.data))
  } catch (error) {
    yield put(getEmployeeNotificationsFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchreadNotification({ payload: readNotificationdata }) {
  try {
    const response = yield call(readNotificationApi, readNotificationdata)
    yield put(readNotificationSuccess(response.data))
  } catch (error) {
    yield put(readNotificationFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchEmployeePAFEsignform({ payload }) {
  try {
    const response = yield call(getEmployeePafEsignDetails, payload)
    yield put(getEmployeePAFEsignFormSuccess(response.data))
  } catch (error) {
    yield put(getEmployeePAFEsignFormFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchUpdateEmployeePafEsignForm({ payload }) {
  try {
    const response = yield call(updateEmployeePafEsignForm, payload)
    yield put(updateEmployeePafEsignFormSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(updateEmployeePafEsignFormFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* EmployeeCasesaga() {
  yield takeEvery(GET_EMPLOYEECASES, fetchEmployeeCases)
  yield takeEvery(GET_EMPLOYEECASE_DETAIL, fetchEmployeeCaseDetail)
  yield takeEvery(ADD_EMPLOYEECASE_DETAIL, addEmployeeCaseDetail)
  yield takeEvery(DELETE_EMPLOYEECASE_DETAIL, onDeleteEmployeeCase)
  yield takeEvery(UPDATE_EMPLOYEECASE_DETAIL, onUpdateEmployeeCase)
  yield takeEvery(GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO, fetchEmployeeCaseQnnrTemplateInfo)
  yield takeEvery(GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO, fetchEmployeeCaseQnnrTemplateGroupInfo)
  yield takeEvery(ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO, addEmployeeCaseQnnrTemplateGroupInfo)
  yield takeEvery(UPLOAD_QNNR_DOCUMENT, fetchUploadQnnrDocument)
  yield takeEvery(DELETE_QNNR_DOCUMENT, deleteUploadedQnnrDocument)

  //yield takeEvery(GET_EMPLOYERQNNRTYPES, fetchEmployerQnnrTypes)
  yield takeEvery(GET_EMPLOYEE_COUNTRYS, fetchEmployeeCountrys)
  yield takeEvery(GET_EMPLOYEE_STATES, fetchEmployeeStates)
  yield takeEvery(GET_EMPLOYEE_NOTIFICATIONS, fetchEmployeeNotifications)
  yield takeEvery(READ_NOTIFICATION, fetchreadNotification)
  yield takeEvery(GET_EMPLOYEE_PAF_ESIGN_FORM, fetchEmployeePAFEsignform)
  yield takeEvery(UPDATE_EMPLOYEE_PAF_ESIGN_FORM, fetchUpdateEmployeePafEsignForm)
}

export default EmployeeCasesaga
