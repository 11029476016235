import React from 'react';
import InputMask from "react-input-mask";
import {
  FormGroup, Input
} from "reactstrap";
import { checkValidityClass } from "../Shared/utility";
import Label from "./Label";

const SSNInputType = ({ name, label, type, placeholder, className, required, invalidMessage, value, content, onChanged }) => {
  return (
    <FormGroup className="mb-3">
      <Label
        label={content.label}
        name={name}
        isRequired={content.required}
      />
      <InputMask
        name={content.name}
        mask="999-99-9999"
        placeholder={content?.placeholder}
        className={`${checkValidityClass(invalidMessage)} ${invalidMessage ? 'form-control is-invalid' : 'form-control'}`}
        id={name}
        value={value}
        required={content.required}
        aria-invalid="true"
        aria-describedby={`invalid_${name}`}
        onChange={onChanged}
        invalid={
          invalidMessage ? true : false
        }
      >
        {(inputProps) => (
          <Input
            {...inputProps}
            type="text"
            className="form-control"
          />
        )}
      </InputMask>
      <div type="invalid" className="invalid-feedback">{invalidMessage}</div>
    </FormGroup>
  );
};


export default SSNInputType