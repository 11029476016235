import React from "react";

import EmployerPayrollCreate from "../../../Attorney/Payroll/CreatePayroll";
import EmployerBasicSuite from "../../Basic";

const MainPayrollCreateComponent = () => {

const getLoggedInPlanType = () => {
    return localStorage.getItem("plan_type");
  }

  if(getLoggedInPlanType()==1) {
    return <EmployerPayrollCreate/>
  } else {
    return <EmployerBasicSuite componentName="Create Payroll"/>
  }
};

export default MainPayrollCreateComponent;
