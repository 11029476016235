import {
  GET_ATTRIBUTESETS_FAIL,
  GET_ATTRIBUTESETS_SUCCESS,
  GET_ATTRIBUTESET_DETAIL_SUCCESS,
  GET_ATTRIBUTESET_DETAIL_FAIL,
  UPDATE_ATTRIBUTESET_DETAIL_FAIL,
  UPDATE_ATTRIBUTESET_DETAIL_SUCCESS,
  UPDATE_ATTRIBUTESET_DETAIL,
  DELETE_ATTRIBUTESET_DETAIL_FAIL,
  DELETE_ATTRIBUTESET_DETAIL_SUCCESS,
  DELETE_ATTRIBUTESET_DETAIL,
  ADD_ATTRIBUTESET_DETAIL_SUCCESS,
  ADD_ATTRIBUTESET_DETAIL,
  ADD_ATTRIBUTESET_DETAIL_FAIL
} from "./actionTypes"

const INIT_STATE = {
  attributesets: [],
  attributeSetsFilter:[],
  attributesetDetail: {},
  error: {},
}

const Attributesets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ATTRIBUTESETS_SUCCESS:
      return {
        ...state,
        attributesets: action.payload,
        attributeSetsFilter: state.attributesets.filter((item) => item.id !== action.payload),
      }

    case GET_ATTRIBUTESETS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ATTRIBUTESET_DETAIL_SUCCESS:
      return {
        ...state,
        attributesetDetail: action.payload,
      }

    case GET_ATTRIBUTESET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ATTRIBUTESET_DETAIL_SUCCESS:
      return {
        ...state,
        attributesets: action.payload,
      }

    case ADD_ATTRIBUTESET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ATTRIBUTESET_DETAIL_SUCCESS:
      return {
        ...state,
        attributesets: state.attributesets.filter((item) => item.id !== action.payload),
      };

    case DELETE_ATTRIBUTESET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_ATTRIBUTESET_DETAIL_SUCCESS:
      return {
        ...state,
        attributesets: action.payload
      };

    case UPDATE_ATTRIBUTESET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state
  }
}

export default Attributesets
