import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input, FormGroup, Button, FormFeedback } from "reactstrap"
import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";


import { getCasetypeDetail as ongetCasetypeDetail, addCasetypeDetail, CasetypeUpdate } from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "../../../components/Common/Alert";



const FormLayoutsBasic = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL

  useEffect(() => {
    if (editId)
      dispatch(ongetCasetypeDetail(editId));
  }, [dispatch, editId]);

  const { casetypeDetail } = useSelector((state) => ({
    casetypeDetail: state.casetypes.casetypeDetail,
  }));


  const backToListing = () => {
    navigate(`/admin/cases`);
  }

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: editId ? casetypeDetail : {
      id: editId,
      case_type: '',
      status: ''
    },
    validationSchema: Yup.object({
      case_type: Yup.string().required("Please Enter Your Case Type Name"),
      status: Yup.string().required("Please select Status"),

    }),
    onSubmit: (values) => {
      if (Number(editId) > 0) {
        dispatch(CasetypeUpdate(values));
      } else {
        dispatch(addCasetypeDetail(values));
      }
      navigate("/admin/case-types");
    }
  });
  const BreadcrumbData = [
    { name: 'Case Types', url: '/admin/case-types' },
    { name: 'Add Case Type', url: '/admin/add-case-types' }
  ]
  let title = editId ? 'Update Case Type' : 'Add Case Type';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />


          <Row>
            <Col xs={12}>
              <Card>

                <CardBody className="p-4">
                  <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {/* import TextualInputs */}
                    <div className="mt-4 mt-lg-0">



                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          Case Type Name:
                        </Label>
                        <Col sm={5}>
                          <Input
                            name="case_type"
                            placeholder="Case Type"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.case_type || ""}
                            invalid={
                              validation.touched.case_type && validation.errors.case_type ? true : false
                            }
                          />
                          {validation.touched.case_type && validation.errors.case_type ? (
                            <FormFeedback type="invalid">{validation.errors.case_type}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>



                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          Status:
                        </Label>
                        <Col sm={5}>
                          <Input
                            id="validationCustom02"
                            name="status"
                            type="select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status || ""}
                            invalid={
                              validation.touched.status && validation.errors.status ? true : false
                            }
                          >
                            <option >Open this select status</option>
                            <option value="Active">Active</option>
                            <option value="InActive">InActive</option>
                          </Input>

                          {validation.touched.status && validation.errors.status ? (
                            <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                          ) : null}

                        </Col>
                      </Row>
                      <Row>
                        <Col sm={7}>
                          <FormGroup className="mb-0">
                            <div>
                              <Button type="reset" color="secondary" onClick={() => navigate(-1)}>
                                Cancel
                              </Button>{" "}
                              <Button type="submit" className="ms-1" color="primary">
                                Save
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>


                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormLayoutsBasic;