import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CASEFORMATTRIBUTESETS, GET_CASEFORMATTRSETS_DETAIL,
  ADD_CASEFORMATTRSET,
  UPDATE_CASEFORM_ATTRIBUTESET_DETAIL,
  DELETE_CASEFORM_ATTRIBUTESET_DETAIL
} from "./actionTypes"
import {
  getcaseformAttributesetsSuccess, getcaseformAttributesetsFail,
  getCaseFormAttributeSetDetailSuccess, getCaseFormAttributeSetDetailFail,
  addcaseformAttributesetsSuccess, addcaseformAttributesetsFail, getCaseFormAttributeSetDetail, updateCaseformAttributesetsSuccess, updateCaseformAttributesetsFail, caseFormAttributeSetDeleteSuccess, caseFormAttributeSetDeleteFail
} from "./actions"

//Include Both Helper File with needed methods
import { getCaseFormAttributesets, addCaseformAttributeset, getCaseFormAttributeSetDetailInfo, updateCaseformAttributeset, deleteCaseformAttributeset } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions";
import { changePreloader } from "../../../store/actions"

function* fetchCaseFormAttributesets(data) {
  //console.log('-------saga exu============');
  try {
    const response = yield call(getCaseFormAttributesets, data)
    yield put(getcaseformAttributesetsSuccess(response.data))
  } catch (error) {
    yield put(getcaseformAttributesetsFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchCaseFormAttributesetsDetail({ Caseformid }) {
  try {
    if (Caseformid) {
      const response = yield call(getCaseFormAttributeSetDetailInfo, Caseformid)
      yield put(getCaseFormAttributeSetDetailSuccess(response.data))
    }
    else {
      yield put(getCaseFormAttributeSetDetailSuccess({}))
    }

  } catch (error) {
    yield put(getCaseFormAttributeSetDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* addCaseFormAttributeset({ payload: caseformattributeset }) {
  try {
    const response = yield call(addCaseformAttributeset, caseformattributeset)
    yield put(addcaseformAttributesetsSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addcaseformAttributesetsFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

// function* onDeleteAttributeset({ payload: data }) {
//   try {
//     const response = yield call(deleteAttributeset, data);
//     yield put(AttributesetDeleteSuccess(response));
//   } catch (error) {
//     yield put(AttributesetDeleteFail(error));
//   }
// }

function* onUpdateCaseAttributeset({ payload: data }) {
  try {
    const response = yield call(updateCaseformAttributeset, data);
    yield put(updateCaseformAttributesetsSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(updateCaseformAttributesetsFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteCaseAttributeset({ payload: data }) {
  try {
    const response = yield call(deleteCaseformAttributeset, data);
    yield put(caseFormAttributeSetDeleteSuccess(data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(caseFormAttributeSetDeleteFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* Caseformattributesetsaga() {
  yield takeEvery(GET_CASEFORMATTRIBUTESETS, fetchCaseFormAttributesets)
  yield takeEvery(GET_CASEFORMATTRSETS_DETAIL, fetchCaseFormAttributesetsDetail)
  yield takeEvery(ADD_CASEFORMATTRSET, addCaseFormAttributeset)
  yield takeEvery(DELETE_CASEFORM_ATTRIBUTESET_DETAIL, onDeleteCaseAttributeset)
  yield takeEvery(UPDATE_CASEFORM_ATTRIBUTESET_DETAIL, onUpdateCaseAttributeset)
}

export default Caseformattributesetsaga
