/* QNNRTYPES */
export const GET_ATTORNEY_DASHBOARD = "GET_ATTORNEY_DASHBOARD"
export const GET_ATTORNEY_DASHBOARD_SUCCESS = "GET_ATTORNEY_DASHBOARD_SUCCESS"
export const GET_ATTORNEY_DASHBOARD_FAIL = "GET_ATTORNEY_DASHBOARD_FAIL"

export const GET_ATTORNEY_DASHBOARD_DETAIL = "GET_ATTORNEY_DASHBOARD_DETAIL"
export const GET_ATTORNEY_DASHBOARD_DETAIL_SUCCESS = "GET_ATTORNEY_DASHBOARD_DETAIL_SUCCESS"
export const GET_ATTORNEY_DASHBOARD_DETAIL_FAIL = "GET_ATTORNEY_DASHBOARD_DETAIL_FAIL"

export const ADD_ATTORNEY_DASHBOARD_DETAIL = "ADD_ATTORNEY_DASHBOARD_DETAIL"
export const ADD_ATTORNEY_DASHBOARD_DETAIL_SUCCESS = "ADD_ATTORNEY_DASHBOARD_DETAIL_SUCCESS"
export const ADD_ATTORNEY_DASHBOARD_DETAIL_FAIL = "ADD_ATTORNEY_DASHBOARD_DETAIL_FAIL"

export const DELETE_ATTORNEY_DASHBOARD_DETAIL = "DELETE_ATTORNEY_DASHBOARD_DETAIL"
export const DELETE_ATTORNEY_DASHBOARD_DETAIL_SUCCESS = "DELETE_ATTORNEY_DASHBOARD_DETAIL_SUCCESS"
export const DELETE_ATTORNEY_DASHBOARD_DETAIL_FAIL = "DELETE_ATTORNEY_DASHBOARD_DETAIL_FAIL"

export const UPDATE_ATTORNEY_DASHBOARD_DETAIL = "UPDATE_ATTORNEY_DASHBOARD_DETAIL"
export const UPDATE_ATTORNEY_DASHBOARD_DETAIL_SUCCESS = "UPDATE_ATTORNEY_DASHBOARD_DETAIL_SUCCESS"
export const UPDATE_ATTORNEY_DASHBOARD_DETAIL_FAIL = "UPDATE_ATTORNEY_DASHBOARD_DETAIL_FAIL"
