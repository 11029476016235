
/* EMPLOYER PAYROLL TYPES */
export const GET_EMPLOYER_PAYROLLS= "GET_EMPLOYER_PAYROLLS"
export const GET_EMPLOYER_PAYROLLS_SUCCESS = "GET_EMPLOYER_PAYROLLS_SUCCESS"
export const GET_EMPLOYER_PAYROLLS_FAIL = "GET_EMPLOYER_PAYROLLS_FAIL"

export const GET_EMPLOYER_PAYROLL= "GET_EMPLOYER_PAYROLL"
export const GET_EMPLOYER_PAYROLL_SUCCESS = "GET_EMPLOYER_PAYROLL_SUCCESS"
export const GET_EMPLOYER_PAYROLL_FAIL = "GET_EMPLOYER_PAYROLL_FAIL"

export const ADD_EMPLOYER_PAYROLL = "ADD_EMPLOYER_PAYROLL"
export const ADD_EMPLOYER_PAYROLL_SUCCESS = "ADD_EMPLOYER_PAYROLL_SUCCESS"
export const ADD_EMPLOYER_PAYROLL_FAIL = "ADD_EMPLOYER_PAYROLL_FAIL"

export const UPDATE_EMPLOYER_PAYROLL = "UPDATE_EMPLOYER_PAYROLL"
export const UPDATE_EMPLOYER_PAYROLL_SUCCESS = "UPDATE_EMPLOYER_PAYROLL_SUCCESS"
export const UPDATE_EMPLOYER_PAYROLL_FAIL = "UPDATE_EMPLOYER_PAYROLL_FAIL"


export const DELETE_EMPLOYER_PAYROLL = "DELETE_EMPLOYER_PAYROLL"
export const DELETE_EMPLOYER_PAYROLL_SUCCESS = "DELETE_EMPLOYER_PAYROLL_SUCCESS"
export const DELETE_EMPLOYER_PAYROLL_FAIL = "DELETE_EMPLOYER_PAYROLL_FAIL"

export const REFRESH_EMPLOYER_PAYROLL = "REFRESH_EMPLOYER_PAYROLL"
export const REFRESH_EMPLOYER_PAYROLL_SUCCESS = "REFRESH_EMPLOYER_PAYROLL_SUCCESS"
export const REFRESH_EMPLOYER_PAYROLL_FAIL = "REFRESH_EMPLOYER_PAYROLL_FAIL"

export const REFRESH_EMPLOYEE_PAYROLL = "REFRESH_EMPLOYEE_PAYROLL"
export const REFRESH_EMPLOYEE_PAYROLL_SUCCESS = "REFRESH_EMPLOYEE_PAYROLL_SUCCESS"
export const REFRESH_EMPLOYEE_PAYROLL_FAIL = "REFRESH_EMPLOYEE_PAYROLL_FAIL"
