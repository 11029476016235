import { GET_CASEFORMATTRIBUTES, GET_CASEFORMATTRIBUTES_SUCCESS,
     GET_CASEFORMATTRIBUTES_FAIL, ADD_CASEFORMATTRIBUTE, ADD_CASEFORMATTRIBUTE_SUCCESS,
    ADD_CASEFORMATTRIBUTE_FAIL,
    GET_CASEFORMATTRIBUTE_DETAIL,
    GET_CASEFORMATTRIBUTE_DETAIL_SUCCESS,
    GET_CASEFORMATTRIBUTE_DETAIL_FAIL,
    UPDATE_CASEFORMATTRIBUTE_DETAIL,
    UPDATE_CASEFORMATTRIBUTE_SUCCESS,
    UPDATE_CASEFORMATTRIBUTE_FAIL,
    DELETE_CASEFORMATTRIBUTE_DETAIL,
    DELETE_CASEFORMATTRIBUTE_FAIL,
    DELETE_CASEFORMATTRIBUTE_SUCCESS} from "./actionTypes"

export const getCaseFormAttributes = (data) => ({
  type: GET_CASEFORMATTRIBUTES,
  form_id:data?.form_id || ''
})

export const getCaseFormAttributesFail = error => ({
  type: GET_CASEFORMATTRIBUTES_FAIL,
  payload: error
})

export const getCaseFormAttributesSuccess = CaseFormAttributeTemplates => ({
  type: GET_CASEFORMATTRIBUTES_SUCCESS,
  payload: CaseFormAttributeTemplates,
})

export const addCaseFormAttribute = (data) => ({
  type: ADD_CASEFORMATTRIBUTE,
  payload: data
})

export const addCaseFormAttributeSuccess = (data) => ({
  type: ADD_CASEFORMATTRIBUTE_SUCCESS,
  payload: data
})

export const addCaseFormAttributeFail = (error) => ({
  type: ADD_CASEFORMATTRIBUTE_FAIL,
  payload: error
})


export const getCaseFormAttributeDetail = Caseformid => ({
  type: GET_CASEFORMATTRIBUTE_DETAIL,
  Caseformid,
})

export const getCaseFormAttributeDetailSuccess = Caseformattribute => ({
  type: GET_CASEFORMATTRIBUTE_DETAIL_SUCCESS,
  payload: Caseformattribute,
})

export const getCaseFormAttributeDetailFail = error => ({
  type: GET_CASEFORMATTRIBUTE_DETAIL_FAIL,
  payload: error,
})



export const caseAttributeUpdate = (data) => ({
  type: UPDATE_CASEFORMATTRIBUTE_DETAIL,
  payload: data
})

export const caseAttributeUpdateSuccess = (data) => ({
  type: UPDATE_CASEFORMATTRIBUTE_SUCCESS,
  payload: data
})

export const caseAttributeUpdateFail = (error) => ({
  type: UPDATE_CASEFORMATTRIBUTE_FAIL,
  payload: error
})


export const caseFormAttributeDelete = (data) => ({
  type: DELETE_CASEFORMATTRIBUTE_DETAIL,
  payload: data
})

export const caseFormAttributeDeleteSuccess = (data) => ({
  type: DELETE_CASEFORMATTRIBUTE_SUCCESS,
  payload: data
})

export const caseFormAttributeDeleteFail = (error) => ({
  type: DELETE_CASEFORMATTRIBUTE_FAIL,
  payload: error
})
