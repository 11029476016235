/* QNNRTYPES */
export const GET_CASEFORMATTRIBUTES = "GET_CASEFORMATTRIBUTES"
export const GET_CASEFORMATTRIBUTES_SUCCESS = "GET_CASEFORMATTRIBUTES_SUCCESS"
export const GET_CASEFORMATTRIBUTES_FAIL = "GET_CASEFORMATTRIBUTES_FAIL"

export const ADD_CASEFORMATTRIBUTE = "ADD_CASEFORMATTRIBUTE"
export const ADD_CASEFORMATTRIBUTE_SUCCESS = "ADD_CASEFORMATTRIBUTE_SUCCESS"
export const ADD_CASEFORMATTRIBUTE_FAIL = "ADD_CASEFORMATTRIBUTE_FAIL"


export const GET_CASEFORMATTRIBUTE_DETAIL = "GET_CASEFORMATTRIBUTE_DETAIL"
export const GET_CASEFORMATTRIBUTE_DETAIL_SUCCESS = "GET_CASEFORMATTRIBUTE_DETAIL_SUCCESS"
export const GET_CASEFORMATTRIBUTE_DETAIL_FAIL = "GET_CASEFORMATTRIBUTE_DETAIL_FAIL"

export const UPDATE_CASEFORMATTRIBUTE_DETAIL = "UPDATE_CASEFORMATTRIBUTE_DETAIL"
export const UPDATE_CASEFORMATTRIBUTE_SUCCESS = "UPDATE_CASEFORMATTRIBUTE_SUCCESS"
export const UPDATE_CASEFORMATTRIBUTE_FAIL = "UPDATE_CASEFORMATTRIBUTE_FAIL"


export const DELETE_CASEFORMATTRIBUTE_DETAIL = "DELETE_CASEFORMATTRIBUTE_DETAIL"
export const DELETE_CASEFORMATTRIBUTE_SUCCESS = "DELETE_CASEFORMATTRIBUTE_SUCCESS"
export const DELETE_CASEFORMATTRIBUTE_FAIL = "DELETE_CASEFORMATTRIBUTE_FAIL"
