import React from "react";
import { Link } from "react-router-dom"
import moment from "moment";
import { useSelector } from "react-redux";


const Id = (cell) => {
  return cell.value ? cell.value : '';
};

const LinkCell = (cell) => {
  return (
    <Link className="text-dark fw-medium" to="#">{cell.value}</Link>
  );
};

const Date = (cell) => {
  return cell.value ? moment(cell.value).format("MMM DD, YYYY") : '';
};

const CaseTypeName = (cell) => {

  const { casetypes } = useSelector((state) => ({
    casetypes: state.casetypes.casetypes,
  }));
  const type = casetypes?.find((item) => cell.value === item.id)

  return type?.case_type || '';
};

const QnnrTypeName = (cell) => {
  const { qnnrtypes } = useSelector((state) => ({
    qnnrtypes: state.qnnrtypes.qnnrtypes,
  }));
  const type = qnnrtypes?.find((item) => cell.value === item.id)
  return type?.qnnr_type || '';
};

const CommonCell = (cell) => {
  return cell.value ? cell.value : '';
};

export {
  CommonCell,
  Date,
  CaseTypeName,
  QnnrTypeName
  
};