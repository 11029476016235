import {
  GET_ATTORNEY_DASHBOARD,
  GET_ATTORNEY_DASHBOARD_FAIL,
  GET_ATTORNEY_DASHBOARD_SUCCESS,
  GET_ATTORNEY_DASHBOARD_DETAIL,
  GET_ATTORNEY_DASHBOARD_DETAIL_FAIL,
  GET_ATTORNEY_DASHBOARD_DETAIL_SUCCESS,
  UPDATE_ATTORNEY_DASHBOARD_DETAIL_FAIL,
  UPDATE_ATTORNEY_DASHBOARD_DETAIL_SUCCESS,
  UPDATE_ATTORNEY_DASHBOARD_DETAIL,
  DELETE_ATTORNEY_DASHBOARD_DETAIL_FAIL,
  DELETE_ATTORNEY_DASHBOARD_DETAIL_SUCCESS,
  DELETE_ATTORNEY_DASHBOARD_DETAIL,
  ADD_ATTORNEY_DASHBOARD_DETAIL_SUCCESS,
  ADD_ATTORNEY_DASHBOARD_DETAIL,
  ADD_ATTORNEY_DASHBOARD_DETAIL_FAIL

} from "./actionTypes"

export const getAttorneyDashboard = () => ({
  type: GET_ATTORNEY_DASHBOARD,
})

export const getAttorneyDashboardSuccess = Dashboard => ({
  type: GET_ATTORNEY_DASHBOARD_SUCCESS,
  payload: Dashboard,
})

export const getAttorneyDashboardFail = error => ({
  type: GET_ATTORNEY_DASHBOARD_FAIL,
  payload: error,
})

export const getAttorneyDashboardDetail = DashboardId => ({
  type: GET_ATTORNEY_DASHBOARD_DETAIL,
  DashboardId,
})

export const getAttorneyDashboardDetailSuccess = Dashboard => ({
  type: GET_ATTORNEY_DASHBOARD_DETAIL_SUCCESS,
  payload: Dashboard,
})

export const getAttorneyDashboardDetailFail = error => ({
  type: GET_ATTORNEY_DASHBOARD_DETAIL_FAIL,
  payload: error,
})

export const  addDashboardDetail = (data) => ({
  type: ADD_ATTORNEY_DASHBOARD_DETAIL,
  payload: data
})

export const addDashboarddetailSuccess = (data) => ({
  type: ADD_ATTORNEY_DASHBOARD_DETAIL_SUCCESS,
  payload: data
})

export const addDashboarddetailFail = (error) => ({
  type: ADD_ATTORNEY_DASHBOARD_DETAIL_FAIL,
  payload: error
})

export const DashboardDelete = (data) => ({
  type: DELETE_ATTORNEY_DASHBOARD_DETAIL,
  payload: data
})

export const DashboardDeleteSuccess = (data) => ({
  type: DELETE_ATTORNEY_DASHBOARD_DETAIL_SUCCESS,
  payload: data
})

export const DashboardDeleteFail = (error) => ({
  type: DELETE_ATTORNEY_DASHBOARD_DETAIL_FAIL,
  payload: error
})

export const DashboardUpdate = (data) => ({
  type: UPDATE_ATTORNEY_DASHBOARD_DETAIL,
  payload: data
})

export const DashboardUpdateSuccess = (data) => ({
  type: UPDATE_ATTORNEY_DASHBOARD_DETAIL_SUCCESS,
  payload: data
})

export const DashboardUpdateFail = (error) => ({
  type: UPDATE_ATTORNEY_DASHBOARD_DETAIL_FAIL,
  payload: error
})
