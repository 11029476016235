import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";

import { AttorneyCompanyUpdate, getAttorneyCompanyDetail, getProfile } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDateFormatYMD } from "../../../common/data/dateFormat";



const FormLayoutsBasic = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL

  const [username, setusername] = useState("Attorney");
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setusername(obj.data.name);

    }

  }, []);

  const { qnnrtypeDetail, attorneycompany } = useSelector((state) => ({
    qnnrtypeDetail: state.AttorneyCompanyInfo.attorneycompanyDetail,
    attorneycompany: state.AttorneyContacts.attorneycompany,
  }));

  useEffect(() => {
    dispatch(getAttorneyCompanyDetail());
    dispatch(getProfile('attorney'));
  }, [editId, attorneycompany]);


  //console.log('qnnrtypeDetail',qnnrtypeDetail);
  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: qnnrtypeDetail,
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Representative First Name").nullable(),
      last_name: Yup.string().required("Please Enter Representative Last Name").nullable(),
      email: Yup.string().required("Please Enter Company Email").nullable(),
      daytime_telephone: Yup.string().required("Please Enter Daytime Phone").nullable(),
      mobile_telephone: Yup.string().required("Please Enter Cell phone").nullable(),
      city: Yup.string().required("Please Select City").nullable(),
      state_id: Yup.string().required("Please Select State").nullable(),
      country_id: Yup.string().required("Please Select Country").nullable(),
      zipcode: Yup.number()
        .typeError(`Zipcode is required`)
        .integer(`Please enter a number without decimal values`)
        .positive(`Please enter a valid zipcode`)
        .test(
          "onlyNumbers",
          `Please enter a valid zipcode`,
          (value) => value && (/^[0-9]*$/.test(value.toString()) && value.toString().length >= 5))
        .required("Please Enter Zipcode").nullable(),
      // pincode: Yup.number()
      //   .typeError(`Postal Code is required`)
      //   .integer(`Please enter a valid number without decimal values`)
      //   .positive(`Please enter a valid postal code`)
      //   .test(
      //     "onlyNumbers",
      //     `Please enter a valid postal code`,
      //     (value) => value && (/^[0-9]*$/.test(value.toString()) && value.toString().length >= 5))
      //   .nullable(),

      street_number_name: Yup.string().required("Please Enter Street Number And Name").nullable(),
      eligibility_status: Yup.string().required("Please Select Status").nullable(),
      apt_ste_flr: Yup.string().required("Please select Apt/Ste/Flr").nullable(),
      number: Yup.string().required("Please Enter Number").nullable(),
      license_authority: Yup.string().when('eligibility_status', {
        is: (val) => val !== null && val.includes("1"),
        then: Yup.string()
          .required("Please Enter License Authority").nullable(),
        otherwise: () => Yup.string().nullable(),
      }),
      bar_number: Yup.string().when('eligibility_status', {
        is: (val) => val !== null && val.includes("1"),
        then: Yup.string()
          .required("Please Enter Bar Number").nullable(),
        otherwise: () => Yup.string().nullable(),
      }),
      law_firm_name: Yup.string().when('eligibility_status', {
        is: (val) => val !== null && val.includes("1"),
        then: Yup.string()
          .required("Please Enter Law Firm Name").nullable(),
        otherwise: () => Yup.string().nullable(),
      }),
      Violation_notes: Yup.string().when('eligibility_status', {
        is: (val) => val !== null && val.includes("1"),
        then: Yup.string()
          .required("Please Select Option").nullable(),
        otherwise: () => Yup.string().nullable(),
      }),
      name_of_recog_org: Yup.string().when('eligibility_status', {
        is: (val) => val !== null && val.includes("2"),
        then: Yup.string()
          .required("Please Enter Name Of Recognized Organization").nullable(),
        otherwise: () => Yup.string().nullable(),
      }),
      date_of_accredation: Yup.string().when('eligibility_status', {
        is: (val) => val !== null && val.includes("2"),
        then: Yup.string()
          .required("Please Select Date Accreditation Expires").nullable(),
        otherwise: () => Yup.string().nullable(),
      }),

      associated_with: Yup.string().when('eligibility_status', {
        is: (val) => val !== null && val.includes("3"),
        then: Yup.string()
          .required("Please Select Option").nullable(),
        otherwise: () => Yup.string().nullable(),
      }),

      atrn_name: Yup.string().when('eligibility_status', {
        is: (val) => val !== null && val.includes("3"),
        then: Yup.string()
          .required("Please Enter The Attorney's Name").nullable(),
        otherwise: () => Yup.string().nullable(),
      }),

      atrn_license_authority: Yup.string().when('eligibility_status', {
        is: (val) => val !== null && val.includes("3"),
        then: Yup.string()
          .required("Please Enter The Licensing Authority").nullable(),
        otherwise: () => Yup.string().nullable(),
      }),

      atrn_bar_number: Yup.string().when('eligibility_status', {
        is: (val) => val !== null && val.includes("3"),
        then: Yup.string()
          .required("Please Enter The Attorney's Bar Number").nullable(),
        otherwise: () => Yup.string().nullable(),

      }),

      law_student_name: Yup.string().when('eligibility_status', {
        is: (val) => val !== null && val.includes("4"),
        then: Yup.string()
          .required("Please Enter The Law Student Or Graduate's Name").nullable(),
        otherwise: () => Yup.string().nullable(),
      }),
    }),
    onSubmit: (values) => {
      // delete values.associated_with;
      // delete values.atrn_name
      // delete values.atrn_license_authority;
      // delete values.atrn_bar_number;


      if (Number(editId) > 0) {
        dispatch(AttorneyCompanyUpdate({ ...values, date_of_accredation: getDateFormatYMD(values.date_of_accredation) }));
      } else {
        dispatch(AttorneyCompanyUpdate({ ...values, date_of_accredation: getDateFormatYMD(values.date_of_accredation) }));
      }
      setTimeout(() => {
        dispatch(getProfile('attorney'));
        navigate("/attorney/dashboard");
      }, 1000);
    }
  });
  let title = editId ? 'Registration' : 'Registration';
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/attorney/dashboard' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <CardHeader>
            <h4 className="card-title">Welcome, {username}</h4>
            <p>Welcome to Immidock, your complete Immigration Case Management software. Please complete below one time registartion process. This information will be used to populate G-28 forms for your future immigration cases. If you require any changes later, you can always update the information from your dashboard.</p>

          </CardHeader>


          <Row>
            <Col xs={12}>
              <Card>

                <CardBody className="p-4">

                  <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {/* import TextualInputs */}
                    <div className="mt-4 mt-lg-0">


                      <Row>
                        <p>
                          Name of attorney or accredited representative
                        </p>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="first_name">First Name *</Label>
                            <Input
                              name="first_name"
                              placeholder="First name"
                              type="text"
                              className="form-control"
                              id="first_name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.first_name || ""}
                              invalid={
                                validation.touched.first_name && validation.errors.first_name ? true : false
                              }
                            />
                            {validation.touched.first_name && validation.errors.first_name ? (
                              <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="last_name">Last Name *</Label>
                            <Input
                              name="last_name"
                              placeholder="Last name"
                              type="text"
                              className="form-control"
                              id="last_name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.last_name || ""}
                              invalid={
                                validation.touched.last_name && validation.errors.last_name ? true : false
                              }
                            />
                            {validation.touched.last_name && validation.errors.last_name ? (
                              <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="middle_name">Middle Name</Label>
                            <Input
                              name="middle_name"
                              placeholder="Middle name"
                              type="text"
                              className="form-control"
                              id="middle_name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.middle_name || ""}
                              invalid={
                                validation.touched.middle_name && validation.errors.middle_name ? true : false
                              }
                            />
                            {validation.touched.middle_name && validation.errors.middle_name ? (
                              <FormFeedback type="invalid">{validation.errors.middle_name}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">Company Logo</Label>
                            <Input
                              type="file"
                              className="form-control"
                              name="company_logo"
                              onChange={(e) => validation.setFieldValue('company_logo', e.target.files[0])}
                              accept=".png, .jpg, .jpeg"
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.company_logo &&
                                  validation.errors.company_logo
                                  ? true
                                  : false}
                            />
                            {validation.touched.company_logo &&
                              validation.errors.company_logo ? (
                              <FormFeedback type="invalid">
                                {validation.errors.company_logo}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                          {validation?.values?.company_logo_url && (
                            <span className="logo-sm">
                              <img src={validation?.values?.company_logo_url} alt="" width="80" height="80" />
                            </span>
                          )}
                        </Col>

                      </Row>


                      <Row className="mt-3">
                        <p>
                          Address of attorney or accredited representative
                        </p>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="street_number_name">Street Number And Name *</Label>
                            <Input
                              name="street_number_name"
                              placeholder=""
                              type="text"
                              className="form-control"
                              id="street_number_name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.street_number_name || ""}
                              invalid={
                                validation.touched.street_number_name && validation.errors.street_number_name ? true : false
                              }
                            />
                            {validation.touched.street_number_name && validation.errors.street_number_name ? (
                              <FormFeedback type="invalid">{validation.errors.street_number_name}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">Apt/Ste/Flr *</Label>

                            <div className="hstack gap-3">
                              <Input
                                name="apt_ste_flr"
                                type="radio"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value="Apt"
                                checked={validation.values.apt_ste_flr == "Apt" ? true : false}
                              />
                              Apt
                              <Input
                                name="apt_ste_flr"
                                type="radio"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value="Ste"
                                checked={validation.values.apt_ste_flr == "Ste" ? true : false}

                              />
                              Ste

                              <Input
                                name="apt_ste_flr"
                                type="radio"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value="Flr"
                                checked={validation.values.apt_ste_flr == "Flr" ? true : false}
                              />Flr

                            </div>
                            {validation.touched.apt_ste_flr && validation.errors.apt_ste_flr ? (
                              <FormFeedback type="invalid">{validation.errors.apt_ste_flr}</FormFeedback>
                            ) : null}
                          </FormGroup>

                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="number">Number *</Label>
                            <Input
                              name="number"
                              placeholder="Number"
                              type="text"
                              className="form-control"
                              id="number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.number || ""}
                              invalid={
                                validation.touched.number && validation.errors.number ? true : false
                              }
                            />
                            {validation.touched.number && validation.errors.number ? (
                              <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="city">City or Town *</Label>
                            <Input
                              name="city"
                              placeholder="city"
                              type="text"
                              className="form-control"
                              id="city"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.city || ""}
                              invalid={
                                validation.touched.city && validation.errors.city ? true : false
                              }
                            />
                            {validation.touched.city && validation.errors.city ? (
                              <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="state_id">State *</Label>
                            <Input
                              name="state_id"
                              placeholder=""
                              type="select"
                              className="form-control"
                              id="state_id"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.state_id || ""}
                              invalid={
                                validation.touched.state_id && validation.errors.state_id ? true : false
                              }
                            >
                              <option value="">Select State</option>

                              <option value="AL">Alabama</option>

                              <option value="AK">Alaska</option>

                              <option value="AS">American Samoa</option>

                              <option value="AZ">Arizona</option>

                              <option value="AR">Arkansas</option>

                              <option value="UM-81">Baker Island</option>

                              <option value="CA">California</option>

                              <option value="CO">Colorado</option>

                              <option value="CT">Connecticut</option>

                              <option value="DE">Delaware</option>

                              <option value="DC">District of Columbia</option>

                              <option value="FL">Florida</option>

                              <option value="GA">Georgia</option>

                              <option value="GU">Guam</option>

                              <option value="HI">Hawaii</option>

                              <option value="UM-84">Howland Island</option>

                              <option value="ID">Idaho</option>

                              <option value="IL">Illinois</option>

                              <option value="IN">Indiana</option>

                              <option value="IA">Iowa</option>

                              <option value="UM-86">Jarvis Island</option>

                              <option value="UM-67">Johnston Atoll</option>

                              <option value="KS">Kansas</option>

                              <option value="KY">Kentucky</option>

                              <option value="UM-89">Kingman Reef</option>

                              <option value="LA">Louisiana</option>

                              <option value="ME">Maine</option>

                              <option value="MD">Maryland</option>

                              <option value="MA">Massachusetts</option>

                              <option value="MI">Michigan</option>

                              <option value="UM-71">Midway Atoll</option>

                              <option value="MN">Minnesota</option>

                              <option value="MS">Mississippi</option>

                              <option value="MO">Missouri</option>

                              <option value="MT">Montana</option>

                              <option value="UM-76">Navassa Island</option>

                              <option value="NE">Nebraska</option>

                              <option value="NV">Nevada</option>

                              <option value="NH">New Hampshire</option>

                              <option value="NJ" selected="">New Jersey</option>

                              <option value="NM">New Mexico</option>

                              <option value="NY">New York</option>

                              <option value="NC">North Carolina</option>

                              <option value="ND">North Dakota</option>

                              <option value="MP">Northern Mariana Islands</option>

                              <option value="OH">Ohio</option>

                              <option value="OK">Oklahoma</option>

                              <option value="OR">Oregon</option>

                              <option value="UM-95">Palmyra Atoll</option>

                              <option value="PA">Pennsylvania</option>

                              <option value="PR">Puerto Rico</option>

                              <option value="RI">Rhode Island</option>

                              <option value="SC">South Carolina</option>

                              <option value="SD">South Dakota</option>

                              <option value="TN">Tennessee</option>

                              <option value="TX">Texas</option>

                              <option value="UM">United States Minor Outlying Islands</option>

                              <option value="VI">United States Virgin Islands</option>

                              <option value="UT">Utah</option>

                              <option value="VT">Vermont</option>

                              <option value="VA">Virginia</option>

                              <option value="UM-79">Wake Island</option>

                              <option value="WA">Washington</option>

                              <option value="WV">West Virginia</option>

                              <option value="WI">Wisconsin</option>

                              <option value="WY">Wyoming</option>



                            </Input>
                            {validation.touched.state_id && validation.errors.state_id ? (
                              <FormFeedback type="invalid">{validation.errors.state_id}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>


                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">Zipcode *</Label>
                            <Input
                              maxLength={5}
                              size={5}
                              name="zipcode"
                              placeholder="zipcode"
                              type="text"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.zipcode || ""}
                              invalid={
                                validation.touched.zipcode && validation.errors.zipcode ? true : false
                              }
                            />
                            {validation.touched.zipcode && validation.errors.zipcode ? (
                              <FormFeedback type="invalid">{validation.errors.zipcode}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>


                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="province">Province</Label>
                            <Input
                              name="province"
                              placeholder="Province"
                              type="text"
                              className="form-control"
                              id="province"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.province || ""}
                              invalid={
                                validation.touched.province && validation.errors.province ? true : false
                              }
                            />
                            {validation.touched.province && validation.errors.province ? (
                              <FormFeedback type="invalid">{validation.errors.province}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>


                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="pincode">Postal Code</Label>
                            <Input
                              maxLength={5}
                              size={5}
                              name="pincode"
                              placeholder="pincode"
                              type="text"
                              className="form-control"
                              id="pincode"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.pincode || ""}
                              invalid={
                                validation.touched.pincode && validation.errors.pincode ? true : false
                              }
                            />
                            {validation.touched.pincode && validation.errors.pincode ? (
                              <FormFeedback type="invalid">{validation.errors.pincode}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="country_id">Country *</Label>
                            <Input
                              name="country_id"
                              placeholder="country_id"
                              type="select"
                              className="form-control"
                              id="country_id"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.country_id || ""}
                              invalid={
                                validation.touched.country_id && validation.errors.country_id ? true : false
                              }
                            >

                              <option value="">Choose Country</option>
                              <option value="USA" selected="">USA</option>
                            </Input>

                            {validation.touched.country_id && validation.errors.country_id ? (
                              <FormFeedback type="invalid">{validation.errors.country_id}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>

                      </Row>


                      <Row>
                        <p>
                          Contact information of attorney or accredited representative
                        </p>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="daytime_telephone">Daytime Phone *</Label>
                            <InputMask
                              mask="(999)-999-9999"
                              name="daytime_telephone"
                              placeholder="Daytime Phone"
                              type="text"
                              className={`form-control ${validation.touched.daytime_telephone && validation.errors.daytime_telephone ? 'is-invalid' : ''}`}
                              id="daytime_telephone"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.daytime_telephone || ""}
                              invalid={
                                validation.touched.daytime_telephone && validation.errors.daytime_telephone ? true : false
                              }
                            />
                            {validation.touched.daytime_telephone && validation.errors.daytime_telephone ? (
                              <FormFeedback type="invalid">{validation.errors.daytime_telephone}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="mobile_telephone">Cell Phone *</Label>
                            <InputMask
                              mask="(999)-999-9999"
                              name="mobile_telephone"
                              placeholder="Mobile Phone"
                              type="text"
                              className={`form-control ${validation.touched.mobile_telephone && validation.errors.mobile_telephone ? 'is-invalid' : ''}`}
                              id="mobile_telephone"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.mobile_telephone || ""}
                              invalid={
                                validation.touched.mobile_telephone && validation.errors.mobile_telephone ? true : false
                              }
                            />
                            {validation.touched.mobile_telephone && validation.errors.mobile_telephone ? (
                              <FormFeedback type="invalid">{validation.errors.mobile_telephone}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="email">Email Address *</Label>
                            <Input
                              name="email"
                              placeholder="Email Address"
                              type="text"
                              className="form-control"
                              id="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="fax">Fax Number</Label>
                            <Input
                              name="fax"
                              placeholder="fax"
                              type="text"
                              className="form-control"
                              id="fax"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.fax || ""}
                              invalid={
                                validation.touched.fax && validation.errors.fax ? true : false
                              }
                            />
                            {validation.touched.fax && validation.errors.fax ? (
                              <FormFeedback type="invalid">{validation.errors.fax}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <p>
                          Eligibility information of attorney or accredited representative
                        </p>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="eligibility_status">Select Status *</Label>
                            <Input
                              name="eligibility_status"
                              placeholder=""
                              type="select"
                              className="form-control"
                              id="eligibility_status"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.eligibility_status || ""}
                              invalid={
                                validation.touched.eligibility_status && validation.errors.eligibility_status ? true : false
                              }
                            >
                              <option value="0">Please select the appropraite status</option>
                              <option value="1">I am an attorney</option>
                              <option value="2">Accredited representative</option>
                              <option value="3">Associated with an attorney or accredited representative</option>
                              <option value="4">Law student or graduate under the supervision of an attorney</option>
                            </Input>


                            {validation.touched.eligibility_status && validation.errors.eligibility_status ? (
                              <FormFeedback type="invalid">{validation.errors.eligibility_status}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        {validation.values.eligibility_status && validation.values.eligibility_status == 1 ? (
                          <Row>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="license_authority">Licensing Authority *</Label>
                                <Input
                                  name="license_authority"
                                  placeholder="Licensing Authority"
                                  type="text"
                                  className="form-control"
                                  id="license_authority"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.license_authority || ""}
                                  invalid={
                                    validation.values.eligibility_status && validation.values.eligibility_status == 1 && validation.touched.license_authority && validation.errors.license_authority ? true : false
                                  }
                                />
                                {validation.touched.license_authority && validation.errors.license_authority ? (
                                  <FormFeedback type="invalid">{validation.errors.license_authority}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="bar_number">Bar number *</Label>
                                <Input
                                  name="bar_number"
                                  placeholder="Bar number"
                                  type="text"
                                  className="form-control"
                                  id="bar_number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.bar_number || ""}
                                  invalid={
                                    validation.values.eligibility_status && validation.values.eligibility_status == 1 && validation.touched.bar_number && validation.errors.bar_number ? true : false
                                  }
                                />
                                {validation.touched.bar_number && validation.errors.bar_number ? (
                                  <FormFeedback type="invalid">{validation.errors.bar_number}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="law_firm_name">Name of law firm *</Label>
                                <Input
                                  name="law_firm_name"
                                  placeholder="Name of law firm"
                                  type="text"
                                  className="form-control"
                                  id="law_firm_name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.law_firm_name || ""}
                                  invalid={
                                    validation.values.eligibility_status && validation.values.eligibility_status == 1 && validation.touched.law_firm_name && validation.errors.law_firm_name ? true : false
                                  }
                                />
                                {validation.touched.law_firm_name && validation.errors.law_firm_name ? (
                                  <FormFeedback type="invalid">{validation.errors.law_firm_name}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col md="8">
                              <FormGroup className="mb-3">
                                <Label htmlFor="Violation_notes">Are you subject to any order restricting them from the practice of law? *</Label>
                                <Input
                                  name="Violation_notes"
                                  type="select"
                                  className="form-control"
                                  id="Violation_notes"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Violation_notes || ""}
                                  invalid={
                                    validation.values.eligibility_status && validation.values.eligibility_status == 1 && validation.touched.Violation_notes && validation.errors.Violation_notes ? true : false
                                  }
                                > <option value="">Open this select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </Input>
                                {validation.touched.Violation_notes && validation.errors.Violation_notes ? (
                                  <FormFeedback type="invalid">{validation.errors.Violation_notes}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : null}

                        {validation.values.eligibility_status && validation.values.eligibility_status == 2 ? (
                          <Row>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="eligibility_status">Name of recognized organization *</Label>
                                <Input
                                  name="name_of_recog_org"
                                  placeholder="Name of recognized organization"
                                  type="text"
                                  className="form-control"
                                  id="eligibility_status"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name_of_recog_org || ""}
                                  invalid={
                                    validation.values.eligibility_status && validation.values.eligibility_status == 2 && validation.touched.name_of_recog_org && validation.errors.name_of_recog_org ? true : false
                                  }
                                />
                                {validation.touched.name_of_recog_org && validation.errors.name_of_recog_org ? (
                                  <FormFeedback type="invalid">{validation.errors.name_of_recog_org}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="date_of_accredation">Date accreditation expires *</Label>
                                <ReactDatePicker
                                  showYearDropdown={true}
                                  showIcon
                                  placeholderText="MM/dd/yyyy"
                                  name="date_of_accredation"
                                  placeholder="Date accreditation expires"
                                  className={`form-control ${validation.touched.date_of_accredation && validation.errors.date_of_accredation ? 'is-invalid' : ''}`}
                                  id="date_of_accredation"
                                  dateFormat="MM/dd/yyyy"
                                  onChange={(date) => {
                                    validation.setFieldValue('date_of_accredation', date)
                                  }
                                  }
                                  selected={validation?.values?.date_of_accredation ? new Date(validation.values.date_of_accredation) : ''}
                                />
                                {validation.touched.date_of_accredation && validation.errors.date_of_accredation ? (
                                  <FormFeedback type="invalid">{validation.errors.date_of_accredation}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : null}

                        {validation.values.eligibility_status && validation.values.eligibility_status == 3 ? (
                          <Row>

                            <Col md="8">
                              <FormGroup className="mb-3">
                                <Label htmlFor="associated_with">Are You Associated With An Attorney Or Accredited Representative * </Label>
                                <Input
                                  name="associated_with"
                                  placeholder="Last name"
                                  type="select"
                                  className="form-control"
                                  id="associated_with"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.associated_with || ""}
                                  invalid={
                                    validation.values.eligibility_status && validation.values.eligibility_status == 3 && validation.touched.associated_with && validation.errors.associated_with ? true : false
                                  }
                                >
                                  <option value="">Please select</option>
                                  <option value="Attorney">Attorney</option>
                                  <option value="Accredited reprenstative">Accredited reprenstative *</option>
                                </Input>
                                {validation.touched.associated_with && validation.errors.associated_with ? (
                                  <FormFeedback type="invalid">{validation.errors.associated_with}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="atrn_name">Please Provide The Attorney's Name *</Label>
                                <Input
                                  name="atrn_name"
                                  placeholder="Please provide the attorney's name"
                                  type="text"
                                  className="form-control"
                                  id="atrn_name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.atrn_name || ""}
                                  invalid={
                                    validation.values.eligibility_status && validation.values.eligibility_status == 3 && validation.touched.atrn_name && validation.errors.atrn_name ? true : false
                                  }
                                />
                                {validation.touched.atrn_name && validation.errors.atrn_name ? (
                                  <FormFeedback type="invalid">{validation.errors.atrn_name}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="atrn_license_authority">Licensing Authority *</Label>
                                <Input
                                  name="atrn_license_authority"
                                  placeholder="Licensing Authority"
                                  type="text"
                                  className="form-control"
                                  id="atrn_license_authority"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.atrn_license_authority || ""}
                                  invalid={
                                    validation.values.eligibility_status && validation.values.eligibility_status == 3 && validation.touched.atrn_license_authority && validation.errors.atrn_license_authority ? true : false
                                  }
                                />
                                {validation.touched.atrn_license_authority && validation.errors.atrn_license_authority ? (
                                  <FormFeedback type="invalid">{validation.errors.atrn_license_authority}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="atrn_bar_number">Please Provide The Attorney's Bar Number *</Label>
                                <Input
                                  name="atrn_bar_number"
                                  placeholder="Please provide the attorney's bar number"
                                  type="text"
                                  className="form-control"
                                  id="atrn_bar_number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.atrn_bar_number || ""}
                                  invalid={
                                    validation.values.eligibility_status && validation.values.eligibility_status == 3 && validation.touched.atrn_bar_number && validation.errors.atrn_bar_number ? true : false
                                  }
                                />
                                {validation.touched.atrn_bar_number && validation.errors.atrn_bar_number ? (
                                  <FormFeedback type="invalid">{validation.errors.atrn_bar_number}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>


                          </Row>
                        ) : null}

                        {validation.values.eligibility_status && validation.values.eligibility_status == 4 ? (
                          <Row>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="law_student_name">Please provide the law student or graduate's name *</Label>
                                <Input
                                  name="law_student_name"
                                  placeholder="Please provide the law student or graduate's name"
                                  type="text"
                                  className="form-control"
                                  id="law_student_name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.law_student_name || ""}
                                  invalid={
                                    validation.values.eligibility_status && validation.values.eligibility_status == 4 && validation.touched.law_student_name && validation.errors.law_student_name ? true : false
                                  }
                                />
                                {validation.touched.law_student_name && validation.errors.law_student_name ? (
                                  <FormFeedback type="invalid">{validation.errors.law_student_name}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : null}

                      </Row>
                      <Row className="justify-content-middle">
                        <Col sm={2}>&nbsp;
                        </Col>
                        <Col sm={4}>
                          <FormGroup className="mb-0">
                            <div>
                              <Button type="reset" color="secondary" onClick={() => navigate(-1)}>
                                Cancel
                              </Button>{" "}
                              <Button type="submit" className="ms-1" color="primary">
                                Save
                              </Button>
                            </div>
                          </FormGroup>

                        </Col>
                      </Row>

                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};
export default FormLayoutsBasic
