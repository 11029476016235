import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input, FormGroup, Button, FormFeedback } from "reactstrap"
import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";


//import { getCasetypeDetail as ongetCasetypeDetail, getEmployerDetails, addCasetypeDetail,CasetypeUpdate } from "../../../store/actions";
//redux
import { getEmployerDetails, updateEmployer } from "../../../store/actions"
import { useSelector, useDispatch } from "react-redux";



const FormLayoutsBasic = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL

  useEffect(() => {
    dispatch(getEmployerDetails(editId));
  }, [dispatch,editId]);
  
  const { employerDetails } = useSelector((state) => ({
    employerDetails: state.Employers.employerDetails,
  }));

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      employer_id: editId,
      company_name: employerDetails?.company_name ? employerDetails.company_name : '',
      first_name: employerDetails?.first_name ? employerDetails.first_name : '',
      last_name: employerDetails?.last_name ? employerDetails.last_name : '',
      domain: employerDetails?.domain ?  employerDetails.domain : '',
      plan_type: employerDetails?.plan_type ? employerDetails.plan_type : '',
    },
    validationSchema: Yup.object({
      company_name: Yup.string().required("Please enter Company Name"),
      first_name: Yup.string().required("Please enter First Name"),
      last_name: Yup.string().required("Please enter Last Name"),
      domain: Yup.string().required("Please enter Domain"),
      plan_type: Yup.string().required("Please select Plan Type"),
    }),
    onSubmit: (values) => { 
      if(Number(editId)>0){
        dispatch(updateEmployer(values));
        setTimeout(() => {
            navigate("/admin/Employers");
        },3000)
      }
      
    }
  });
  let title = editId ? 'Update Employer' : '';
  const BreadcrumbData = [
    { name: 'Employers', url: '/admin/Employers' },
    { name: title, url: '/admin/editEmployer' }
  ] 
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />


          <Row>
            <Col xs={12}>
              <Card>

                <CardBody className="p-4">
                <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {/* import TextualInputs */}
                    <div className="mt-4 mt-lg-0">



                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          Company Name:
                        </Label>
                        <Col sm={5}>
                          <Input
                            name="company_name"
                            placeholder="Enter Company Name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.company_name || ""}
                            invalid={
                              validation.touched.company_name && validation.errors.company_name ? true : false
                            }
                          />
                          {validation.touched.company_name && validation.errors.company_name ? (
                            <FormFeedback type="invalid">{validation.errors.company_name}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          First Name:
                        </Label>
                        <Col sm={5}>
                          <Input
                            name="first_name"
                            placeholder="Enter First Name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.first_name || ""}
                            invalid={
                              validation.touched.first_name && validation.errors.first_name ? true : false
                            }
                          />
                          {validation.touched.first_name && validation.errors.first_name ? (
                            <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          Last Name:
                        </Label>
                        <Col sm={5}>
                          <Input
                            name="last_name"
                            placeholder="Enter Last Name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.last_name || ""}
                            invalid={
                              validation.touched.last_name && validation.errors.last_name ? true : false
                            }
                          />
                          {validation.touched.last_name && validation.errors.last_name ? (
                            <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          Domain:
                        </Label>
                        <Col sm={5}>
                          <Input
                            name="domain"
                            placeholder="Enter Domain"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.domain || ""}
                            invalid={
                              validation.touched.domain && validation.errors.domain ? true : false
                            }
                          />
                          {validation.touched.domain && validation.errors.domain ? (
                            <FormFeedback type="invalid">{validation.errors.domain}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          Plan Type:
                        </Label>
                        <Col sm={5}>
                        <Input
                            name="plan_type"
                            placeholder="Select Plan Type"
                            type="select"
                            className="form-control"
                            id="plan_type"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.plan_type || ""}
                            invalid={
                                validation.touched.plan_type && validation.errors.plan_type ? true : false
                            }
                        >
                            <option value="">Select Plan Type</option>
                            <option selected={validation.values.plan_type==1} value="1">Full Suite</option>
                            <option selected={validation.values.plan_type==2} value="2">Basic Suite</option>
                          </Input>   
                        {validation.touched.plan_type && validation.errors.plan_type ? (
                            <FormFeedback type="invalid">{validation.errors.plan_type}</FormFeedback>
                        ) : null}
                        </Col>
                      </Row>


                      <Row className="justify-content-middle">
                        <Col sm={2}>&nbsp;
                        </Col>
                        <Col sm={4}>
                          <FormGroup className="mb-0">
                            <div>
                              <Button type="reset" color="secondary" onClick={() => navigate(-1)}>
                                Cancel
                              </Button>{" "}
                              <Button type="submit" className="ms-1" color="primary">
                                Save
                              </Button>
                              
                            </div>
                          </FormGroup>

                        </Col>
                      </Row>

                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormLayoutsBasic;