export const getDateFormatYMD = (date) => {
    if (date) {
        const newDate = new Date(date);
        return newDate.getFullYear() + "-" + ((newDate.getMonth() + 1) <= 9 ? `0${(newDate.getMonth() + 1)}` : (newDate.getMonth() + 1)) + '-' + ((newDate.getDate() <= 9) ? `0${newDate.getDate()}` : newDate.getDate());
    }
    else {
        //const newDate = new Date();
        //return newDate.getFullYear() + '-' + ((newDate.getMonth() + 1) <= 9 ? `0${(newDate.getMonth() + 1)}` : (newDate.getMonth() + 1)) + "-" + ((newDate.getDate() <= 9) ? `0${newDate.getDate()}` : newDate.getDate())
        return;
    }
}

export const getDateFormatDMonY = (date) => {
    if (date) {
        const newDate = new Date(date);
        return ((newDate.getDate() <= 9) ? `0${newDate.getDate()}` : newDate.getDate()) + ' ' + (newDate.toLocaleString('default', { month: 'short' })) + ', ' + newDate.getFullYear();
    } else {
        return;
    }
}

export const getDateFormatYMDMDY = (date) => {
    if (date) {
        const newDate = new Date(date);
        return ((newDate.getMonth() + 1) <= 9 ? `0${(newDate.getMonth() + 1)}` : (newDate.getMonth() + 1)) + "/" + ((newDate.getDate() <= 9) ? `0${newDate.getDate()}` : newDate.getDate()) + '/' + newDate.getFullYear()
    }
    else {
        return;
    }

}

export const getDateFormatMDYYMD = (date) => {
    if (date) {
        const newDate = new Date(date);
        return newDate.getFullYear() + "-" + ((newDate.getMonth() + 1) <= 9 ? `0${(newDate.getMonth() + 1)}` : (newDate.getMonth() + 1)) + '-' + ((newDate.getDate() <= 9) ? `0${newDate.getDate()}` : newDate.getDate());
    }

}