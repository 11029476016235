import {
  ADD_EMPLOYEECASE_DETAIL_FAIL,
  ADD_EMPLOYEECASE_DETAIL_SUCCESS,
  ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_FAIL,
  ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_SUCCESS,
  DELETE_EMPLOYEECASE_DETAIL_FAIL,
  DELETE_EMPLOYEECASE_DETAIL_SUCCESS,
  DELETE_QNNR_DOCUMENT_FAIL,
  DELETE_QNNR_DOCUMENT_SUCCESS,
  GET_EMPLOYEECASES_FAIL,
  GET_EMPLOYEECASES_SUCCESS,
  GET_EMPLOYEECASETYPES_FAIL,
  GET_EMPLOYEECASETYPES_SUCCESS,
  GET_EMPLOYEECASE_DETAIL_FAIL,
  GET_EMPLOYEECASE_DETAIL_SUCCESS,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_FAIL,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_SUCCESS,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO_FAIL,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO_SUCCESS,
  GET_EMPLOYEE_COUNTRYS_FAIL,
  GET_EMPLOYEE_COUNTRYS_SUCCESS,
  GET_EMPLOYEE_NOTIFICATIONS_FAIL,
  GET_EMPLOYEE_NOTIFICATIONS_SUCCESS,
  GET_EMPLOYEE_PAF_ESIGN_FORM_FAIL,
  GET_EMPLOYEE_PAF_ESIGN_FORM_SUCCESS,
  GET_EMPLOYEE_STATES_FAIL,
  GET_EMPLOYEE_STATES_SUCCESS,
  GET_EMPLOYERQNNRTYPES_FAIL,
  GET_EMPLOYERQNNRTYPES_SUCCESS,
  UPDATE_EMPLOYEECASE_DETAIL_FAIL,
  UPDATE_EMPLOYEECASE_DETAIL_SUCCESS,
  UPDATE_EMPLOYEE_PAF_ESIGN_FORM_FAIL,
  UPDATE_EMPLOYEE_PAF_ESIGN_FORM_SUCCESS,
  UPLOAD_QNNR_DOCUMENT_FAIL,
  UPLOAD_QNNR_DOCUMENT_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  employeecase: [],
  employeecaseDetail: {},
  employeecaseTypes: [],
  employerQnnrTypes: [],
  employeeCaseQnnrTemplateInfo: [],
  employeeCaseQnnrTemplateGroupInfo: [],
  employeeCaseQnnrTemplateGroupError: {},
  error: {},
  countrys: [],
  states: [],
  selectedStates: [],
  employeeNotifications: [],
  employeePAFEsign: {},
  addQnnrTemplateGroupInfo: {},
  updateEmployeePafEsignForm: {},
  uploadqnnrdocuments: [],
  deleteqnnrdocuments: {},
};

const EmployeeCases = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DELETE_QNNR_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteqnnrdocuments: action.payload,
      };

    case DELETE_QNNR_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
      
    case UPLOAD_QNNR_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadqnnrdocuments: action.payload,
      };

    case UPLOAD_QNNR_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_EMPLOYEECASES_SUCCESS:
      return {
        ...state,
        employeecase: action.payload,
      };

    case GET_EMPLOYEECASES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_EMPLOYEECASE_DETAIL_SUCCESS:
      return {
        ...state,
        empoyercaseDetail: action.payload,
      };

    case GET_EMPLOYEECASE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_EMPLOYEECASE_DETAIL_SUCCESS:
      return {
        ...state,
        employeecase: [...state.employercasse, action.payload],
      };

    case ADD_EMPLOYEECASE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_EMPLOYEECASE_DETAIL_SUCCESS:
      return {
        ...state,
        employeecase: state.employeecase.filter(
          (item) => item.id !== action.payload
        ),
      };

    case DELETE_EMPLOYEECASE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_EMPLOYEECASE_DETAIL_SUCCESS:
      return {
        ...state,
        employeecase: state.casetypes.map((row) =>
          row.id.toString() === action.payload.id.toString()
            ? { row, ...action.payload }
            : row
        ),
      };

    case UPDATE_EMPLOYEECASE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_EMPLOYEECASETYPES_SUCCESS:
      return {
        ...state,
        employeecaseTypes: action.payload,
      };

    case GET_EMPLOYEECASETYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_EMPLOYERQNNRTYPES_SUCCESS:
      return {
        ...state,
        employerQnnrTypes: action.payload,
      };

    case GET_EMPLOYERQNNRTYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO_SUCCESS:
      return {
        ...state,
        employeeCaseQnnrTemplateInfo: action.payload?.templateInfo,
        employeeCaseQnnrTemplateGroupInfo: action.payload?.groupInfo,
      };

    case GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_SUCCESS:
      return {
        ...state,
        employeeCaseQnnrTemplateGroupInfo: action.payload,
      };

    case GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_FAIL:
      return {
        ...state,
        employeeCaseQnnrTemplateGroupError: action.payload,
      };
    case ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_SUCCESS:
      return {
        ...state,
        addQnnrTemplateGroupInfo: action.payload,
      };
    case ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_EMPLOYEE_COUNTRYS_SUCCESS:
      return {
        ...state,
        countrys: action.payload,
        selectedCountries: action.payload.map(country => ({
          value: `${country.country_code}`,
          label: `${country.name}`
        }))
      };

    case GET_EMPLOYEE_COUNTRYS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_EMPLOYEE_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        selectedStates: action.payload.map(state => ({
          value: `${state.state_code}`,
          label: `${state.name}`
        }))
      };

    case GET_EMPLOYEE_STATES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_EMPLOYEE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        employeeNotifications: action.payload,
      };

    case GET_EMPLOYEE_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_EMPLOYEE_PAF_ESIGN_FORM_SUCCESS:
      return {
        ...state,
        employeePAFEsign: action.payload,
      };
    case GET_EMPLOYEE_PAF_ESIGN_FORM_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_EMPLOYEE_PAF_ESIGN_FORM_SUCCESS:
      return {
        ...state,
        updateEmployeePafEsignForm: action.payload,
      };

    case UPDATE_EMPLOYEE_PAF_ESIGN_FORM_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default EmployeeCases;
