import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

import "react-datepicker/dist/react-datepicker.css";
import { addCaseform, getCaseForm, getCasetypes, updateCaseForm } from "../../../store/actions";
//redux
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getDateFormatYMD } from "../../../common/data/dateFormat";


const FormLayoutsBasic = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL

  const { casetypeDetail, casetypes, caseformdetail, updatecaseform } = useSelector((state) => ({
    casetypeDetail: state.casetypes.casetypeDetail,
    casetypes: state.casetypes.casetypes,
    caseformdetail: state.CaseFormsList.caseformdetail,
    updatecaseform: state.CaseFormsList.updatecaseform,

  }));
  useEffect(() => {
    dispatch(getCasetypes());
  }, [dispatch]);

  useEffect(() => {
    if (Number(editId) > 0) {
      dispatch(getCaseForm(editId));
    }
  }, [editId]);

  // const initialInvoiceSettings = Object.keys(createinvoicesettings).length > 0 ? createinvoicesettings : {};
  const iniValues = {
    "name": "",
    "description": "",
    "edition": "",
    "form": "",
    "visa_type": "",
    "group": "",
    "headtitle": "",
    "title": "",
    "expires": "",
    "omb_number": "",
    "sub_title": "",
    "display_name": "",
  };

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    innerRef: { formikRef },
    initialValues: (editId && (Object.keys(caseformdetail).length > 0)) ? caseformdetail : iniValues,
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name").nullable(),
      description: Yup.string().required("Please Enter Description").nullable(),
      // edition: Yup.string().required("Please Select Edition Date").nullable(),
      form: Yup.string().required("Please Enter Case Form").nullable(),
      visa_type: Yup.string().required("Please Select Case Type").nullable(),
      group: Yup.string().required("Please Enter Group").nullable(),
      // head_title: Yup.string().required("Please Enter Head Title").nullable(),
      // title: Yup.string().required("Please Enter Title").nullable(),
      // omb_number: Yup.string().required("Please Enter OMB Number").nullable(),
      // expires: Yup.string().required("Please Select Expires Date").nullable(),
      display_name: Yup.string().required("Please Enter Form Display Name").nullable(),
    }),
    validate: values => {
      const errors = {};
      // Validation logic here
      return errors;
    },
    onSubmit: (values) => {
      let data = { ...values, expires: getDateFormatYMD(values.expires), edition: getDateFormatYMD(values.edition) };
      if (Number(editId) > 0) {
        dispatch(updateCaseForm(data));
      } else {
        dispatch(addCaseform(data));
      }
      navigate("/admin/case-forms");
    }
  });
  const BreadcrumbData = [
    { name: 'Case Forms', url: '/admin/case-forms' },
    { name: 'Add Case Form', url: '/admin/add-case-forms' }
  ]
  let title = editId ? 'Update Case Form' : 'Add Case Form';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

          <Row>
            <Col xs={12}>
              <Card>

                <CardBody className="p-4">
                  <FormikProvider value={validation}>
                    <Form className="needs-validation"
                      onSubmit={(values) => {
                        values.preventDefault();
                        // console.log(validate(values));
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* import TextualInputs */}
                      <div className="mt-4 mt-lg-0">
                        <Row className="mb-4">
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-2 col-form-label"
                          >
                            Case Form:
                          </Label>
                          <Col sm={5}>
                            <FormGroup className="mb-3">
                              <Input
                                name="form"
                                placeholder="Case Form"
                                type="text"
                                className="form-control"
                                id="form"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.form || ""}
                                invalid={
                                  validation.touched.form && validation.errors.form ? true : false
                                }
                              />
                              {validation.touched.form && validation.errors.form ? (
                                <FormFeedback type="invalid">{validation.errors.form}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-2 col-form-label"
                          >
                            Name:
                          </Label>
                          <Col sm={5}>
                            <FormGroup className="mb-3">
                              <Input
                                name="name"
                                placeholder="Name"
                                type="text"
                                className="form-control"
                                id="name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name && validation.errors.name ? true : false
                                }
                              />
                              {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                            htmlFor="display_name"
                            className="col-sm-2 col-form-label"
                          >
                            Form Display Name:
                          </Label>
                          <Col sm={5}>
                            <FormGroup className="mb-3">
                              <Input
                                name="display_name"
                                placeholder="Form Display Name"
                                type="text"
                                className="form-control"
                                id="display_name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.display_name || ""}
                                invalid={
                                  validation.touched.display_name && validation.errors.display_name ? true : false
                                }
                              />
                              {validation.touched.display_name && validation.errors.display_name ? (
                                <FormFeedback type="invalid">{validation.errors.display_name}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>


                        <Row className="mb-4">
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-2 col-form-label"
                          >
                            Group:
                          </Label>
                          <Col sm={5}>
                            <FormGroup className="mb-3">
                              <Input
                                name="group"
                                placeholder="Group"
                                type="text"
                                className="form-control"
                                id="group"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.group || ""}
                                invalid={
                                  validation.touched.group && validation.errors.group ? true : false
                                }
                              />
                              {validation.touched.group && validation.errors.group ? (
                                <FormFeedback type="invalid">{validation.errors.group}</FormFeedback>
                              ) : null}
                            </FormGroup>


                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-2 col-form-label"
                          >
                            Case Type:
                          </Label>
                          <Col sm={5}>

                            <FormGroup className="mb-3">
                              <Input
                                name="visa_type"
                                placeholder="Visa Type"
                                type="select"
                                className="form-control"
                                id="visa_type"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.visa_type || ""}
                                invalid={
                                  validation.touched.visa_type && validation.errors.visa_type ? true : false
                                }
                              >  <option value="" data-new="">Select Case Type</option>
                                {casetypes && casetypes.map((casetype, index) => (
                                  <option key={casetype.id + casetype.case_type + index} value={casetype.id}>
                                    {casetype.case_type}
                                  </option>
                                ))}
                              </Input>
                              {validation.touched.visa_type && validation.errors.visa_type ? (
                                <FormFeedback type="invalid">{validation.errors.visa_type}</FormFeedback>
                              ) : null}
                            </FormGroup>




                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-2 col-form-label"
                          >
                            Edition:
                          </Label>
                          <Col sm={5}>
                            <FormGroup className="mb-3">
                              <ReactDatePicker
                                showYearDropdown={true}
                                showIcon
                                placeholderText="MM/dd/yyyy"
                                className={`form-control ${validation.touched.edition && validation.errors.edition ? 'is-invalid' : ''}`}
                                id="edition"
                                name="edition"
                                dateFormat="MM/dd/yyyy"
                                onChange={(date) => {
                                  validation.setFieldValue('edition', date)
                                }
                                }
                                selected={validation?.values?.edition ? new Date(validation.values.edition) : ''}
                              />

                              {validation.touched.edition && validation.errors.edition ? (
                                <FormFeedback type="invalid">{validation.errors.edition}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-2 col-form-label"
                          >
                            Head Title:
                          </Label>
                          <Col sm={5}>
                            <FormGroup className="mb-3">
                              <Input
                                name="head_title"
                                placeholder="Head Title"
                                type="text"
                                className="form-control"
                                id="head_title"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.head_title || ""}
                                invalid={
                                  validation.touched.head_title && validation.errors.head_title ? true : false
                                }
                              />
                              {validation.touched.head_title && validation.errors.head_title ? (
                                <FormFeedback type="invalid">{validation.errors.head_title}</FormFeedback>
                              ) : null}
                            </FormGroup>


                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-2 col-form-label"
                          >
                            Title:
                          </Label>
                          <Col sm={5}>
                            <FormGroup className="mb-3">
                              <Input
                                name="title"
                                placeholder="Title"
                                type="text"
                                className="form-control"
                                id="title"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title && validation.errors.title ? true : false
                                }
                              />
                              {validation.touched.title && validation.errors.title ? (
                                <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                              ) : null}
                            </FormGroup>


                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-2 col-form-label"
                          >
                            Sub Title:
                          </Label>
                          <Col sm={5}>
                            <FormGroup className="mb-3">
                              <Input
                                name="sub_title"
                                placeholder="Sub Title"
                                type="text"
                                className="form-control"
                                id="sub_title"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sub_title || ""}
                                invalid={
                                  validation.touched.sub_title && validation.errors.sub_title ? true : false
                                }
                              />
                              {validation.touched.sub_title && validation.errors.sub_title ? (
                                <FormFeedback type="invalid">{validation.errors.sub_title}</FormFeedback>
                              ) : null}
                            </FormGroup>


                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-2 col-form-label"
                          >
                            OMB Number:
                          </Label>
                          <Col sm={5}>
                            <FormGroup className="mb-3">
                              <Input
                                name="omb_number"
                                placeholder="OMB Number"
                                type="text"
                                className="form-control"
                                id="omb_number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.omb_number || ""}
                                invalid={
                                  validation.touched.omb_number && validation.errors.omb_number ? true : false
                                }
                              />
                              {validation.touched.omb_number && validation.errors.omb_number ? (
                                <FormFeedback type="invalid">{validation.errors.omb_number}</FormFeedback>
                              ) : null}
                            </FormGroup>


                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-2 col-form-label"
                          >
                            Expires:
                          </Label>
                          <Col sm={5}>
                            <FormGroup className="mb-3">
                              <ReactDatePicker
                                showYearDropdown={true}
                                showIcon
                                placeholderText="MM/dd/yyyy"
                                className={`form-control ${validation.touched.expires && validation.errors.expires ? 'is-invalid' : ''}`}
                                id="expires"
                                name="expires"
                                dateFormat="MM/dd/yyyy"
                                onChange={(date) => {
                                  validation.setFieldValue('expires', date)
                                }
                                }
                                selected={validation?.values?.expires ? new Date(validation.values.expires) : ''}
                              />

                              {validation.touched.expires && validation.errors.expires ? (
                                <FormFeedback type="invalid">{validation.errors.expires}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-2 col-form-label"
                          >
                            Description:
                          </Label>
                          <Col sm={5}>
                            <FormGroup className="mb-3">
                              <Input
                                name="description"
                                placeholder="Description"
                                type="text"
                                className="form-control"
                                id="description"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description && validation.errors.description ? true : false
                                }
                              />
                              {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                              ) : null}
                            </FormGroup>


                          </Col>
                        </Row>
                        <Row style={{ float: "right" }}>
                          <Col sm={7}>
                            <span className="mt-4">
                              <button type="reset" className="btn btn-secondary" color="secondary" onClick={() => navigate(-1)}>
                                Cancel
                              </button>
                            </span>
                            &nbsp;&nbsp;
                            <span className="mt-4">
                              <button type="submit" className="btn btn-primary">
                                Save
                              </button>
                            </span>
                          </Col>
                        </Row>

                      </div>
                    </Form>
                  </FormikProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormLayoutsBasic;