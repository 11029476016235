import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_ATTORNEY_DETAIL, DELETE_ATTORNEY_DETAIL, GET_ATTORNEYS, GET_ATTORNEY_DETAIL, UPDATE_ATTORNEY_DETAIL } from "./actionTypes"
import {
  addAttorneydetailFail,
  addAttorneydetailSuccess,
  attorneyDeleteFail,
  attorneyDeleteSuccess,
  attorneyUpdateFail,
  attorneyUpdateSuccess,
  getAttorneyDetailFail,
  getAttorneyDetailSuccess,
  getAttorneyFail,
  getAttorneySuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { addAttorney, deleteAttorney, getAttorneyDetailApi, getAttorneys, updateAttorney } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"
import { changePreloader } from "../../../store/actions"

function* fetchAttorneys({payload:data}) {
  try {
    const response =yield call(getAttorneys,data)
    yield put(getAttorneySuccess(response.data))
  } catch (error) {
    yield put(getAttorneyFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchAttorneyDetail({payload:AttorneyId}) {
  try {
    const response = yield call(getAttorneyDetailApi, AttorneyId)
    yield put(getAttorneyDetailSuccess(response.data))
  } catch (error) {
    yield put(getAttorneyDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* addAttorneyDetail({ payload: user }) {
  try {
    const response = yield call(addAttorney, user)
    yield put(addAttorneydetailSuccess(response.data))
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(addAttorneydetailFail(error))
    yield put(setAlertMessage({message:error,type:"danger"}))    
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteAttorney({ payload: data }) {
  try {
    const response = yield call(deleteAttorney, data);
    yield put(attorneyDeleteSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(attorneyDeleteFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateAttorney({ payload: data }) {
  try {
    const response = yield call(updateAttorney, data);
    yield put(attorneyUpdateSuccess(response.data));
    yield put(setAlertMessage({message:response,type:"success"}));
  } catch (error) {
    yield put(attorneyUpdateFail(error));
    yield put(setAlertMessage({message:error,type:"danger"}))
  } finally {
    yield put(changePreloader(false));
  }
}

function* Attorneysaga() {
  yield takeEvery(GET_ATTORNEYS, fetchAttorneys)
  yield takeEvery(GET_ATTORNEY_DETAIL, fetchAttorneyDetail)
  yield takeEvery(ADD_ATTORNEY_DETAIL, addAttorneyDetail)
  yield takeEvery(DELETE_ATTORNEY_DETAIL, onDeleteAttorney)
  yield takeEvery(UPDATE_ATTORNEY_DETAIL, onUpdateAttorney)
}

export default Attorneysaga
