import {
  ADD_CUSTOMER_DETAIL_FAIL,
  ADD_CUSTOMER_DETAIL_SUCCESS,
  DELETE_CUSTOMER_DETAIL_FAIL,
  DELETE_CUSTOMER_DETAIL_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_DETAIL_FAIL,
  GET_CUSTOMER_DETAIL_SUCCESS,
  GET_CUSTOMER_SETTINGS_FAIL,
  GET_CUSTOMER_SETTINGS_SUCCESS,
  SEND_CUSTOMER_FAIL,
  SEND_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_DETAIL_FAIL,
  UPDATE_CUSTOMER_DETAIL_SUCCESS,
  UPDATE_CUSTOMER_PDF_FAIL,
  UPDATE_CUSTOMER_PDF_SUCCESS,
  UPLOAD_CUSTOMER_ATTACHMENT_FAIL,
  UPLOAD_CUSTOMER_ATTACHMENT_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  customers: [],
  updatedcustomer: {},
  deletedcustomer: {},
  customerDetail: {},
  createCustomerDetail: {},
  customersettings: {},
  uploadedcustomerattachments: {},
  sendCustomerDetail: {},
  updatecustomerpdf: {},
  error: {},
}

const Customers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER_SETTINGS_SUCCESS:
      return {
        ...state,
        customersettings: action.payload,
      }

    case GET_CUSTOMER_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      }

    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOMER_DETAIL_SUCCESS:
      return {
        ...state,
        customerDetail: action.payload,
      }

    case GET_CUSTOMER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CUSTOMER_DETAIL_SUCCESS:
      return {
        ...state,
        createCustomerDetail: action.payload,
      }

    case ADD_CUSTOMER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case SEND_CUSTOMER_SUCCESS:
      return {
        ...state,
        sendCustomerDetail: action.payload,
      }

    case SEND_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CUSTOMER_PDF_SUCCESS:
      return {
        ...state,
        updatecustomerpdf: action.payload,
      }

    case UPDATE_CUSTOMER_PDF_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPLOAD_CUSTOMER_ATTACHMENT_SUCCESS:
      return {
        ...state,
        updatecustomerpdf: action.payload,
      }

    case UPLOAD_CUSTOMER_ATTACHMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CUSTOMER_DETAIL_SUCCESS:
      return {
        ...state,
        customers: state.customers.filter((item) => item.id !== action.payload),
      };

    case DELETE_CUSTOMER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_CUSTOMER_DETAIL_SUCCESS:
      return {
        ...state,
        updatedcustomer: action.payload,
      };

    case UPDATE_CUSTOMER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state
  }
}

export default Customers
