import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";


const Id = (cell) => {
  return cell.value ? cell.value : '';
};

const InvoiceId = (cell) => {
  return (
    <Link className="text-dark fw-medium" to="#">{cell.value}</Link>
  );
};


const Date = (cell) => {
  return cell.value ? moment(cell.value).format("MMM DD, YYYY") : '';
};

const Assigned = (cell) => {
  return (cell.value === 'Person' || cell.value === 'Employee') ? 'Beneficiary' : (cell.value === 'Petitioner' || cell.value === 'Employer') ? 'Employer' : cell.value;
};

const BillingName = (cell) => {
  return cell.value ? cell.value : '';
};
const SharedInfo = ({ cell, clickSharedInfo }) => {
  return cell.value && cell.value > 0 ? <a href="javascript:void(0)" onClick={clickSharedInfo}>{(cell.value + ' Contact(s)')}</a> : '';
};

const Amount = (cell) => {
  return cell.value ? cell.value : '';
};


const PdfView = ({ viewPdf,cell }) => {
  return (
    <>
      <div onClick={() => viewPdf(cell.row.original.pdf_url)}  className="" style={{ "padding-top": "5px", "margin-left": "12px", "float": "left" }}
        >
          <i className={"mdi mdi-eye font-size-15"}></i>
      </div>
    </>
  );
};

const DownloadPdfIcon = (cell) => {
  return (
    <>
      <div>
        <Link to={cell.row.original.pdf_url}
          target="_blank" className="" style={{ "padding-top": "5px", "margin-left": "12px", "float": "left" }}
        >
          <i className="bx bx-download label-icon"></i>
        </Link>
      </div>
    </>
  );
};

const DownloadPdf = (cell) => {
  return (
    <>
      <div>
        <Link to={cell.row.original.pdf_url}
          target="_blank" className="btn btn-soft-light btn-sm w-xs waves-effect btn-label waves-light"
          download>
          Download
        </Link>
      </div>
    </>
  );
};
const ViewdPdf = (cell) => {
  return (
    <>
      <div>
        <Link to={cell.row.original.pdf_url}
          target="_blank" style={{ "text-decoration": "none" }} className=" btn-soft-light btn-sm"
        >
          View Pdf
        </Link>
      </div>
    </>
  );
};
const DownloadDoc = (cell) => {
  return (
    <>
      <div>
        <Link to={cell.row.original.pdf_url}
          target="_blank" style={{ "text-decoration": "none" }} className=" btn-soft-light btn-sm" download
        >
          Download
        </Link>
      </div>
    </>
  );
};


const IsPrinted = (cell) => {
  return (
    <>
      <div
        className="form-check form-switch form-switch-lg mb-3"
        dir="ltr"
      >
        <input
          type="checkbox"
          className="form-check-input"
          id="customSwitchsizelg"
          defaultChecked
        />
      </div>
    </>
  );
};

export {
  Amount, Assigned, BillingName, Date, DownloadDoc, DownloadPdf,
  DownloadPdfIcon, Id,
  InvoiceId, IsPrinted, SharedInfo, ViewdPdf,PdfView
};
