import React, { useEffect, useMemo, useState } from "react";
import DatatableContainer from "../../../components/Common/DatatableContainer";
import {
  Link, useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

import { getCommonUsers, getCommonUserDetail, userCommonDelete, addCommonUser, setAlertFlagInfo } from "../../../store/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  CommonCell, Date, CaseTypeName, Amount
} from "./listCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";

import { useAlert } from "../../../components/Common/Alert";


const UsersList = () => {

  //meta title
  document.title = "Users List | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openTab, setopenTab] = useState("Active");
  const [userdata, setUserdata] = useState(null);

  // for delete user
  const [deleteModal, setDeleteModal] = useState(false);


  const { userDetail, users } = useSelector((state) => ({
    users: state.Users.users,
    userDetail: state.Users.userDetail,

  }));

  const [contactList, setContactList] = useState({ 'status': 'Active' });


  const [activeTab, setactiveTab] = useState("Active");

  useEffect(() => {
    dispatch(getCommonUsers({ status: activeTab }));
  }, [dispatch]);

  const onReloadData = (event) => {
    var tabInfo = event.target.value;
    setactiveTab(tabInfo);
    dispatch(getCommonUsers({ status: tabInfo }));
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setUserdata(null);
    } else {
      setModal(true);
    }
  };
  var userType = window.localStorage.getItem("user_type")

  const handleUserClicks = () => {
    navigate(`/${userType}/create-user`);
  };

  const onViewuser = (data) => {
    navigate(`/${userType}/view-user?userId=${data.id}`);
  };

  const onEditData = (data) => {
    dispatch(getCommonUserDetail({ user_id: data.id }));
    navigate(`/${userType}/edit-user?userId=${data.id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setUserdata(data);
  };

  const handleDeleteUser = () => {
    if (userdata.id) {
      dispatch(userCommonDelete({ user_id: userdata.id }));
      setDeleteModal(false);
      dispatch(getCommonUsers({ 'status': 'Active' }));
    }
  };


  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" />;
      //   },
      // },
      {
        Header: "First Name",
        accessor: "first_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      }, {
        Header: "Designation",
        accessor: "designation",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      }, {
        Header: "Role",
        accessor: "role_id",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      }, {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onEditData(cellProps.row.original)}>Edit</DropdownItem>
                {/* <DropdownItem onClick={() => onEditData(cellProps.row.original)}>Resend password</DropdownItem> */}
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  
  const title = "Users"  
  var userType = window.localStorage.getItem("user_type")
    const BreadcrumbData = [
        {name:'Dashboard', url:'/'+userType+'/dashboard'},
        { name: title, url: '#' }
    ]
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isUserFilter={true}
                    defaultAttorneyFilter={'Active'}
                    isUserFilterChange={onReloadData}
                    isAddUserList={true}
                    customPageSize={10}
                    handleUserClick={handleUserClicks}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsersList;
