/* QNNRTYPES */
export const GET_CASETYPES = "GET_CASETYPES"
export const GET_CASETYPES_SUCCESS = "GET_CASETYPES_SUCCESS"
export const GET_CASETYPES_FAIL = "GET_CASETYPES_FAIL"

export const GET_CASETYPE_DETAIL = "GET_CASETYPE_DETAIL"
export const GET_CASETYPE_DETAIL_SUCCESS = "GET_CASETYPE_DETAIL_SUCCESS"
export const GET_CASETYPE_DETAIL_FAIL = "GET_CASETYPE_DETAIL_FAIL"

export const ADD_CASETYPE_DETAIL = "ADD_CASETYPE_DETAIL"
export const ADD_CASETYPE_DETAIL_SUCCESS = "ADD_CASETYPE_DETAIL_SUCCESS"
export const ADD_CASETYPE_DETAIL_FAIL = "ADD_CASETYPE_DETAIL_FAIL"

export const DELETE_CASETYPE_DETAIL = "DELETE_CASETYPE_DETAIL"
export const DELETE_CASETYPE_DETAIL_SUCCESS = "DELETE_CASETYPE_DETAIL_SUCCESS"
export const DELETE_CASETYPE_DETAIL_FAIL = "DELETE_CASETYPE_DETAIL_FAIL"

export const UPDATE_CASETYPE_DETAIL = "UPDATE_CASETYPE_DETAIL"
export const UPDATE_CASETYPE_DETAIL_SUCCESS = "UPDATE_CASETYPE_DETAIL_SUCCESS"
export const UPDATE_CASETYPE_DETAIL_FAIL = "UPDATE_CASETYPE_DETAIL_FAIL"
