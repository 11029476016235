/* QNNRTYPES */
export const GET_CASEFORMATTRIBUTESETS = "GET_CASEFORMATTRIBUTESETS"
export const GET_CASEFORMATTRIBUTESETS_SUCCESS = "GET_CASEFORMATTRIBUTESETS_SUCCESS"
export const GET_CASEFORMATTRIBUTESETS_FAIL = "GET_CASEFORMATTRIBUTESETS_FAIL"

export const ADD_CASEFORMATTRSET = "ADD_CASEFORMATTRSET"
export const ADD_CASEFORMATTRSET_SUCCESS = "ADD_CASEFORMATTRSET_SUCCESS"
export const ADD_CASEFORMATTRSET_FAIL = "ADD_CASEFORMATTRSET_FAIL"

export const GET_CASEFORMATTRSETS_DETAIL = "GET_ATTRIBUTETEMPLATE_DETAIL"
export const GET_CASEFORMATTRSETS_DETAIL_SUCCESS = "GET_ATTRIBUTETEMPLATE_DETAIL_SUCCESS"
export const GET_CASEFORMATTRSETS_DETAIL_FAIL = "GET_ATTRIBUTETEMPLATE_DETAIL_FAIL"

// export const ADD_ATTRIBUTESET_DETAIL = "ADD_ATTRIBUTESET_DETAIL"
// export const ADD_ATTRIBUTESET_DETAIL_SUCCESS = "ADD_ATTRIBUTESET_DETAIL_SUCCESS"
// export const ADD_ATTRIBUTESET_DETAIL_FAIL = "ADD_ATTRIBUTESET_DETAIL_FAIL"

export const DELETE_CASEFORM_ATTRIBUTESET_DETAIL = "DELETE_CASEFORM_ATTRIBUTESET_DETAIL"
export const DELETE_CASEFORM_ATTRIBUTESET_DETAIL_SUCCESS = "DELETE_CASEFORM_ATTRIBUTESET_DETAIL_SUCCESS"
export const DELETE_CASEFORM_ATTRIBUTESET_DETAIL_FAIL = "DELETE_CASEFORM_ATTRIBUTESET_DETAIL_FAIL"

export const UPDATE_CASEFORM_ATTRIBUTESET_DETAIL = "UPDATE_CASEFORM_ATTRIBUTESET_DETAIL"
export const UPDATE_CASEFORM_ATTRIBUTESET_DETAIL_SUCCESS = "UPDATE_CASEFORM_ATTRIBUTESET_DETAIL_SUCCESS"
export const UPDATE_CASEFORM_ATTRIBUTESET_DETAIL_FAIL = "UPDATE_CASEFORM_ATTRIBUTESET_DETAIL_FAIL"
