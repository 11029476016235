import React from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
//import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
//let token = accessToken
//apply base url for axios
const REACT_APP_API_LOCAL_ENDPOINT = "http://127.0.0.1:8000/api/"
const REACT_APP_API_DEVELOPMENT_ENDPOINT = "https://dev-api.immidock.com/api/"
const REACT_APP_API_PRODUCTION_ENDPOINT = "https://api.immidock.com/api/"
export const GOOGLE_API_SITE_KEY = "6LfyvS4qAAAAAAGRyXqPoRqYRL8Rhfg3hfca3qE0"
//Prod below
//export const GOOGLE_API_SITE_KEY = "6LcrL4EcAAAAAMFf_hR_INNGI3teUSPnwnvQbSNy"
export const GOOGLE_API_SECRETE_KEY = "-------------------------------------"
//const API_URL = (process.env.NODE_ENV == 'production') ? REACT_APP_API_PRODUCTION_ENDPOINT : ((process.env.NODE_ENV == 'development') ? REACT_APP_API_DEVELOPMENT_ENDPOINT : REACT_APP_API_LOCAL_ENDPOINT);

const API_URL = REACT_APP_API_DEVELOPMENT_ENDPOINT;
const axiosApi = axios.create({
  baseURL: API_URL,
})

const obj = JSON.parse(localStorage.getItem("authUser"));
const accessToken = obj && obj?.data?.token ? `Bearer ${obj.data.token}` : '';

axiosApi.defaults.headers.Authorization = accessToken;
axiosApi.defaults.headers.post['Content-Type'] = 'multipart/form-data';


// axiosApi.defaults.headers.common["Authorization"] = accessToken

axiosApi.interceptors.request.use(
  (config) => {
    const authUserObj = JSON.parse(localStorage.getItem("authUser"));
    const token = authUserObj && authUserObj?.data?.token ? `Bearer ${authUserObj.data.token}` : '';
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
);

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status == 401) {
      return logoutUser();
    } else if (error.response.status === 403) {
      const user_type = localStorage.getItem("user_type")
      window.location = "/"+user_type+"/dashboard"
    }
    //document.getElementById("preloader").style.display = "none";
    return Promise.reject(error)
  }
)

let logoutUser = () => {
  localStorage.removeItem("authUser");
  localStorage.removeItem('user_type');
  localStorage.removeItem("status");
  localStorage.removeItem('i18nextLng');
  localStorage.removeItem('I18N_LANGUAGE');
  window.location.reload();
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
