import {
  ADD_ATTORNEY_DETAIL,
  ADD_ATTORNEY_DETAIL_FAIL,
  ADD_ATTORNEY_DETAIL_SUCCESS,
  DELETE_ATTORNEY_DETAIL,
  DELETE_ATTORNEY_DETAIL_FAIL,
  DELETE_ATTORNEY_DETAIL_SUCCESS,
  GET_ATTORNEYS,
  GET_ATTORNEYS_FAIL,
  GET_ATTORNEYS_SUCCESS,
  GET_ATTORNEY_DETAIL,
  GET_ATTORNEY_DETAIL_FAIL,
  GET_ATTORNEY_DETAIL_SUCCESS,
  UPDATE_ATTORNEY_DETAIL,
  UPDATE_ATTORNEY_DETAIL_FAIL,
  UPDATE_ATTORNEY_DETAIL_SUCCESS
} from "./actionTypes"

export const getAttorneys = (data) => ({
  type: GET_ATTORNEYS,
  payload: data
})

export const getAttorneySuccess = Attorney => ({
  type: GET_ATTORNEYS_SUCCESS,
  payload: Attorney,
})

export const getAttorneyFail = error => ({
  type: GET_ATTORNEYS_FAIL,
  payload: error,
})

export const getAttorneyDetail = (AttroneyId) => (
  {
    type: GET_ATTORNEY_DETAIL,
    payload: AttroneyId,
  })

export const getAttorneyDetailSuccess = Attorney => ({
  type: GET_ATTORNEY_DETAIL_SUCCESS,
  payload: Attorney,
})

export const getAttorneyDetailFail = error => ({
  type: GET_ATTORNEY_DETAIL_FAIL,
  payload: error,
})

export const addAttorneyDetail = (data) => ({
  type: ADD_ATTORNEY_DETAIL,
  payload: data
})

export const addAttorneydetailSuccess = (data) => ({
  type: ADD_ATTORNEY_DETAIL_SUCCESS,
  payload: data
})

export const addAttorneydetailFail = (error) => ({
  type: ADD_ATTORNEY_DETAIL_FAIL,
  payload: error
})

export const attorneysDelete = (data) => ({
  type: DELETE_ATTORNEY_DETAIL,
  payload: data
})

export const attorneyDeleteSuccess = (data) => ({
  type: DELETE_ATTORNEY_DETAIL_SUCCESS,
  payload: data
})

export const attorneyDeleteFail = (error) => ({
  type: DELETE_ATTORNEY_DETAIL_FAIL,
  payload: error
})

export const attorneyUpdate = (data) => ({
  type: UPDATE_ATTORNEY_DETAIL,
  payload: data
})

export const attorneyUpdateSuccess = (data) => ({
  type: UPDATE_ATTORNEY_DETAIL_SUCCESS,
  payload: data
})

export const attorneyUpdateFail = (error) => ({
  type: UPDATE_ATTORNEY_DETAIL_FAIL,
  payload: error
})
