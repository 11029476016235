import React from "react";

import EmployerInvoiceDownload from "../../Invoices/DownloadInvoice";
import EmployerBasicSuite from "../../Basic";

const MainInvoiceDownloadComponent = () => {

const getLoggedInPlanType = () => {
    return localStorage.getItem("plan_type");
  }

  if(getLoggedInPlanType()==1) {
    return <EmployerInvoiceDownload/>
  } else {
    return <EmployerBasicSuite componentName="Download Invoice"/>
  }
};

export default MainInvoiceDownloadComponent;
