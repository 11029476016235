import {
  GET_EMPLOYER_DASHBOARD,
  GET_EMPLOYER_DASHBOARD_FAIL,
  GET_EMPLOYER_DASHBOARD_SUCCESS,
  GET_EMPLOYER_DASHBOARD_DETAIL,
  GET_EMPLOYER_DASHBOARD_DETAIL_FAIL,
  GET_EMPLOYER_DASHBOARD_DETAIL_SUCCESS,
  UPDATE_EMPLOYER_DASHBOARD_DETAIL_FAIL,
  UPDATE_EMPLOYER_DASHBOARD_DETAIL_SUCCESS,
  UPDATE_EMPLOYER_DASHBOARD_DETAIL,
  DELETE_EMPLOYER_DASHBOARD_DETAIL_FAIL,
  DELETE_EMPLOYER_DASHBOARD_DETAIL_SUCCESS,
  DELETE_EMPLOYER_DASHBOARD_DETAIL,
  ADD_EMPLOYER_DASHBOARD_DETAIL_SUCCESS,
  ADD_EMPLOYER_DASHBOARD_DETAIL,
  ADD_EMPLOYER_DASHBOARD_DETAIL_FAIL

} from "./actionTypes"

export const getEmployerDashboard = () => ({
  type: GET_EMPLOYER_DASHBOARD,
})

export const getEmployerDashboardSuccess = Dashboard => ({
  type: GET_EMPLOYER_DASHBOARD_SUCCESS,
  payload: Dashboard,
})

export const getEmployerDashboardFail = error => ({
  type: GET_EMPLOYER_DASHBOARD_FAIL,
  payload: error,
})

export const getEmployerDashboardDetail = DashboardId => ({
  type: GET_EMPLOYER_DASHBOARD_DETAIL,
  DashboardId,
})

export const getEmployerDashboardDetailSuccess = Dashboard => ({
  type: GET_EMPLOYER_DASHBOARD_DETAIL_SUCCESS,
  payload: Dashboard,
})

export const getEmployerDashboardDetailFail = error => ({
  type: GET_EMPLOYER_DASHBOARD_DETAIL_FAIL,
  payload: error,
})

export const  addEmployerDashboardDetail = (data) => ({
  type: ADD_EMPLOYER_DASHBOARD_DETAIL,
  payload: data
})

export const addEmployerDashboarddetailSuccess = (data) => ({
  type: ADD_EMPLOYER_DASHBOARD_DETAIL_SUCCESS,
  payload: data
})

export const addEmployerDashboarddetailFail = (error) => ({
  type: ADD_EMPLOYER_DASHBOARD_DETAIL_FAIL,
  payload: error
})

export const DashboardEmployerDelete = (data) => ({
  type: DELETE_EMPLOYER_DASHBOARD_DETAIL,
  payload: data
})

export const DashboardEmployerDeleteSuccess = (data) => ({
  type: DELETE_EMPLOYER_DASHBOARD_DETAIL_SUCCESS,
  payload: data
})

export const DashboardEmployerDeleteFail = (error) => ({
  type: DELETE_EMPLOYER_DASHBOARD_DETAIL_FAIL,
  payload: error
})

export const DashboardEmployerUpdate = (data) => ({
  type: UPDATE_EMPLOYER_DASHBOARD_DETAIL,
  payload: data
})

export const DashboardEmployerUpdateSuccess = (data) => ({
  type: UPDATE_EMPLOYER_DASHBOARD_DETAIL_SUCCESS,
  payload: data
})

export const DashboardEmployerUpdateFail = (error) => ({
  type: UPDATE_EMPLOYER_DASHBOARD_DETAIL_FAIL,
  payload: error
})
