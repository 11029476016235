/* QNNRTYPES */
export const GET_EMPLOYERCONTACTS = "GET_EMPLOYERCONTACTS"
export const GET_EMPLOYERCONTACTS_SUCCESS = "GET_EMPLOYERCONTACTS_SUCCESS"
export const GET_EMPLOYERCONTACTS_FAIL = "GET_EMPLOYERCONTACTS_FAIL"

export const GET_EMPLOYERCONTACT_DETAIL = "GET_EMPLOYERCONTACT_DETAIL"
export const GET_EMPLOYERCONTACT_DETAIL_SUCCESS = "GET_EMPLOYERCONTACT_DETAIL_SUCCESS"
export const GET_EMPLOYERCONTACT_DETAIL_FAIL = "GET_EMPLOYERCONTACT_DETAIL_FAIL"

export const ADD_EMPLOYERCONTACT_DETAIL = "ADD_EMPLOYERCONTACT_DETAIL"
export const ADD_EMPLOYERCONTACT_DETAIL_SUCCESS = "ADD_EMPLOYERCONTACT_DETAIL_SUCCESS"
export const ADD_EMPLOYERCONTACT_DETAIL_FAIL = "ADD_EMPLOYERCONTACT_DETAIL_FAIL"

export const DELETE_EMPLOYERCONTACT_DETAIL = "DELETE_EMPLOYERCONTACT_DETAIL"
export const DELETE_EMPLOYERCONTACT_DETAIL_SUCCESS = "DELETE_EMPLOYERCONTACT_DETAIL_SUCCESS"
export const DELETE_EMPLOYERCONTACT_DETAIL_FAIL = "DELETE_EMPLOYERCONTACT_DETAIL_FAIL"

export const UPDATE_EMPLOYERCONTACT_DETAIL = "UPDATE_EMPLOYERCONTACT_DETAIL"
export const UPDATE_EMPLOYERCONTACT_DETAIL_SUCCESS = "UPDATE_EMPLOYERCONTACT_DETAIL_SUCCESS"
export const UPDATE_EMPLOYERCONTACT_DETAIL_FAIL = "UPDATE_EMPLOYERCONTACT_DETAIL_FAIL"

export const UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL = "UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL"
export const UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL_SUCCESS = "UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL_SUCCESS"
export const UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL_FAIL = "UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL_FAIL"

export const UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL = "UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL"
export const UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL_SUCCESS = "UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL_SUCCESS"
export const UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL_FAIL = "UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL_FAIL"

export const UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL = "UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL"
export const UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL_SUCCESS = "UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL_SUCCESS"
export const UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL_FAIL = "UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL_FAIL"

export const GET_EMPLOYEREMPLOYEE_COUNTRIES = "GET_EMPLOYEREMPLOYEE_COUNTRIES"
export const GET_EMPLOYEREMPLOYEE_COUNTRIES_SUCCESS = "GET_EMPLOYEREMPLOYEE_COUNTRIES_SUCCESS"
export const GET_EMPLOYEREMPLOYEE_COUNTRIES_FAIL = "GET_EMPLOYEREMPLOYEE_COUNTRIES_FAIL"

export const GET_EMPLOYEREMPLOYEE_STATES = "GET_EMPLOYEREMPLOYEE_STATES"
export const GET_EMPLOYEREMPLOYEE_STATES_SUCCESS = "GET_EMPLOYEREMPLOYEE_STATES_SUCCESS"
export const GET_EMPLOYEREMPLOYEE_STATES_FAIL = "GET_EMPLOYEREMPLOYEE_STATES_FAIL"

export const CREATE_CUSTOMER = "CREATE_CUSTOMER"
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS"
export const CREATE_CUSTOMER_FAIL = "CREATE_CUSTOMER_FAIL"

export const ADD_EMPLOYEREMPLOYEE_DOC = "ADD_EMPLOYEREMPLOYEE_DOC"
export const ADD_EMPLOYEREMPLOYEE_DOC_SUCCESS = "ADD_EMPLOYEREMPLOYEE_DOC_SUCCESS"
export const ADD_EMPLOYEREMPLOYEE_DOC_FAIL = "ADD_EMPLOYEREMPLOYEE_DOC_FAIL"

export const SHARE_EMPLOYEREMPLOYEE_DOC = "SHARE_EMPLOYEREMPLOYEE_DOC"
export const SHARE_EMPLOYEREMPLOYEE_DOC_SUCCESS = "SHARE_EMPLOYEREMPLOYEE_DOC_SUCCESS"
export const SHARE_EMPLOYEREMPLOYEE_DOC_FAIL = "SHARE_EMPLOYEREMPLOYEE_DOC_FAIL"

export const ASSIGN_DOC_TO_CASE = "ASSIGN_DOC_TO_CASE"
export const ASSIGN_DOC_TO_CASE_SUCCESS = "ASSIGN_DOC_TO_CASE_SUCCESS"
export const ASSIGN_DOC_TO_CASE_FAIL = "ASSIGN_DOC_TO_CASE_FAIL"

export const GET_DOCUMENTS_BYCASE = "GET_DOCUMENTS_BYCASE"
export const GET_DOCUMENTS_BYCASE_SUCCESS = "GET_DOCUMENTS_BYCASE_SUCCESS"
export const GET_DOCUMENTS_BYCASE_FAIL = "GET_DOCUMENTS_BYCASE_FAIL"

export const VALIDATE_EMPLOYEE_EMAILID = "VALIDATE_EMPLOYEE_EMAILID"
export const VALIDATE_EMPLOYEE_EMAILID_SUCCESS = "VALIDATE_EMPLOYEE_EMAILID_SUCCESS"
export const VALIDATE_EMPLOYEE_EMAILID_FAIL = "VALIDATE_EMPLOYEE_EMAILID_FAIL"

