import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Card,
    CardHeader,
    Col,
    Row
} from "reactstrap";


//redux
import { useDispatch, useSelector } from "react-redux";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Notifications from "../../Common/Notifications";

const BasicTable = () => {
    //meta title
    document.title = "Beneficiary | Immidock ";

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL

    const [activeTab, setactiveTab] = useState("1");
    const [activeTab1, setactiveTab1] = useState("5");
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [username, setusername] = useState("Attorney");

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            setusername(obj.data.name);
        }
    }, []);

    let { attorneycontacts } = useSelector((state) => ({
        attorneycontacts: state.AttorneyContacts.attorneycontacts || [],
    }));
    const toggle1 = (tab) => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };
    let title = 'Dashboard';
    const BreadcrumbData = [
        { name: 'Dashboard', url: '/employee/dashboard' },
        { name: title, url: '#' }
    ]
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                    <Row>
                        <Col xl={6}>


                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Welcome, {username}</h4>
                                </CardHeader>

                            </Card>
                        </Col>

                        <Notifications></Notifications>

                    </Row>




                </div>
            </div>
        </React.Fragment>
    );
};

export default BasicTable;
