import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, resetServerContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Container, Input, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { addAttributegroupDetail, AttributegroupUpdate, getAttributegroupDetail, getAttributesets as onGetAttributesets } from "../../../store/actions";
import {
  getFirstListStyle,
  getItemStyle,
  getListStyle,
  move,
  reorder
} from "./helper";
resetServerContext()

const FormLayoutsBasic = () => {
  //SAVE DATA
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL


  const { attributesets } = useSelector((state) => ({
    attributesets: state.attributesets.attributeSetsFilter,
  }));

  const { attributeGroupDetail } = useSelector((state) => ({
    attributeGroupDetail: state.attributegroups.attributeGroupDetail,
  }));

  const [formData, setFormData] = useState({
    "title": "",
    "name": "",
    "shortDescription": "",
    "description": "",
    "attributesSetsIds": "",
    "status": "Active"
  });
  useEffect(() => {
    dispatch(onGetAttributesets());
  }, [])

  useEffect(() => {
    if (editId) {
      dispatch(getAttributegroupDetail(editId));
    }
  }, [editId]);

  useEffect(() => {
    //setFormData(attributeGroupDetail);
    if (attributeGroupDetail && Object.entries(attributeGroupDetail).length > 0) {
      let { attributesSetsIds } = attributeGroupDetail;
      let attributesSetsArr = attributesSetsIds.split(',');
      let filteredList = attributesets.filter((item) => {
        if (attributesSetsArr.includes(item.id.toString())) {
          return item
        }
      })
      setFormData(attributeGroupDetail);

      setLists({
        items: attributesets,
        // selected: filteredList
        selected: attributeGroupDetail.selectedAttributeSetsList
      });
    }
  }, [attributeGroupDetail, attributesets]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let attributesSetsIds = lists.selected.map((item) => {
      return item.id;
    })

    attributesSetsIds = attributesSetsIds.join(",");
    let postBody = { ...formData, attributesSetsIds };
    if (postBody.name) {
      let response = {};
      if (Number(formData.id) > 0) {
        dispatch(AttributegroupUpdate(postBody));
      } else {
        dispatch(addAttributegroupDetail(postBody));
      }
      setFormData({
        "id": "",
        "title": "",
        "name": "",
        "shortDescription": "",
        "description": "",
        "attributesSetsIds": "",
        "status": "Active"
      });
      navigate("/admin/questionnaire-attributes-groups");


    }
  }

  const handleClickCancle = () => {
    navigate("/admin/questionnaire-attributes-groups");
  }

  const [lists, setLists] = useState({ 'items': attributesets, 'selected': [] });
  const id2List = {
    droppable: 'items',
    droppable2: 'selected'
  };

  const getList = id => lists[id2List[id]];

  const onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === 'droppable2') {
        state = { selected: items };
      }
      let newstate = {
        ...lists,
        ...state
      }
      setLists(newstate);

    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      setLists({
        items: result.droppable,
        selected: result.droppable2
      });
    }
  };

  let title = editId ? 'Update Questionnaire Attribute Groups' : 'Add Questionnaire Attribute Groups';
  const BreadcrumbData = [
    { name: 'Questionnaire Attribute Groups', url: '/admin/questionnaire-attributes-groups' },
    { name: title, url: '/admin/add-questionnaire-attributes-groups' }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />


          <Row>
            <Col xs={12}>
              <form onSubmit={handleSubmit}>

                <div className="container">
                  <div className="row">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <div className="col-lg-6 col-md-12">
                        <div className="card dashboard-secondbox dashboard-border-radius">
                          <h5>Questionnaire attribute Sets List</h5>
                          <p>Please drag and drop and Make sets.</p>

                          <div className=''>
                            <div className="" style={{ 'height': '500px', 'overflowY': 'auto', 'overflowX': 'hidden' }}>
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                  <ul className="list-group border"

                                    ref={provided.innerRef}
                                    style={getFirstListStyle(snapshot.isDraggingOver)}
                                  >
                                    {lists['items'].map((item, index) => (
                                      <Draggable
                                        key={item.id.toString()}
                                        draggableId={item.id.toString()}
                                        index={index}>
                                        {(provided, snapshot) => (
                                          <li className="list-group-item border"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            {item.title}
                                          </li>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}

                                  </ul>
                                )}
                              </Droppable>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">


                        <div className="card dashboard-secondbox dashboard-border-radius">
                          <h5>Creat Attribute Groups</h5>


                          <div className="">
                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Title:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                placeholder="Enter Label"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Name:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                placeholder="Enter Label"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Questionnaire Attribute Sets List:
                              </label>
                              <p>Please drag and drop from left to right.</p>

                            </div>
                            <Droppable droppableId="droppable2">
                              {(provided, snapshot) => (
                                <ul className="list-group"
                                  ref={provided.innerRef}
                                  style={getListStyle(snapshot.isDraggingOver)}>
                                  {lists['selected'].map((item, index) => (
                                    <Draggable
                                      key={item.id.toString()}
                                      draggableId={item.id.toString()}
                                      index={index}>
                                      {(provided, snapshot) => (
                                        <li className="list-group-item"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}>
                                          {item.title}
                                        </li>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </ul>
                              )}
                            </Droppable>



                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Short Description
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                name="shortDescription"
                                placeholder="Enter Short Description"
                                value={formData.shortDescription}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput" className="form-label">
                                Description
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="formGroupExampleInput"
                                placeholder="Enter Description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="mb-3">
                              <label htmlFor="formGroupExampleInput2" className="form-label">
                                Select Status:
                              </label>


                              <Input
                                id="validationCustom02"
                                name="status"
                                type="select"
                                onChange={handleChange}
                                value={formData.status || ""}

                              >
                                <option >Open this select status</option>
                                <option value="Active">Active</option>
                                <option value="InActive">InActive</option>
                              </Input>




                            </div>
                            <Button type="button" id="trash" color="secondary" onClick={handleClickCancle}>
                              Cancel
                            </Button> {' '}
                            <button type="submit" className="btn btn-primary">
                              save
                            </button>

                          </div>

                        </div>

                      </div>

                    </DragDropContext>
                  </div>
                </div>

              </form>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};
export default FormLayoutsBasic
