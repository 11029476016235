import {
  GET_CASETYPES_FAIL,
  GET_CASETYPES_SUCCESS,
  GET_CASETYPE_DETAIL_SUCCESS,
  GET_CASETYPE_DETAIL_FAIL,
  UPDATE_CASETYPE_DETAIL_FAIL,
  UPDATE_CASETYPE_DETAIL_SUCCESS,
  DELETE_CASETYPE_DETAIL_FAIL,
  DELETE_CASETYPE_DETAIL_SUCCESS,
  ADD_CASETYPE_DETAIL_SUCCESS,
  ADD_CASETYPE_DETAIL_FAIL
} from "./actionTypes"
const INIT_STATE = {
  casetypes: [],
  casetypeDetail: {},
  updatecasetypedata: {},
  error: {},
}

const Casetypes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CASETYPES_SUCCESS:
      return {
        ...state,
        casetypes: action.payload,
      }

    case GET_CASETYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CASETYPE_DETAIL_SUCCESS:
      return {
        ...state,
        casetypeDetail: action.payload,
      }

    case GET_CASETYPE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CASETYPE_DETAIL_SUCCESS:
      return {
        ...state,
        casetypes: [...state.casetypes, action.payload],
      }

    case ADD_CASETYPE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CASETYPE_DETAIL_SUCCESS:
      return {
        ...state,
        casetypes: action.payload,
      };

    case DELETE_CASETYPE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_CASETYPE_DETAIL_SUCCESS:
      return {
        ...state,
        updatecasetypedata: action.payload,
      };

    case UPDATE_CASETYPE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state
  }
}

export default Casetypes
