
export const GET_MARKET_OVERVIEW = "GET_MARKET_OVERVIEW"
export const GET_MARKET_OVERVIEW_SUCCESS = "GET_MARKET_OVERVIEW_SUCCESS"
export const GET_MARKET_OVERVIEW_FAIL = "GET_MARKET_OVERVIEW_FAIL"

export const GET_WALLENT_BALANCE = "GET_WALLENT_BALANCE"
export const GET_WALLENT_BALANCE_SUCCESS = "GET_WALLENT_BALANCE_SUCCESS"
export const GET_WALLENT_BALANCE_FAIL = "GET_WALLENT_BALANCE_FAIL"

export const GET_Invested_Overview = "GET_Invested_Overview"
export const GET_Invested_Overview_SUCCESS = "GET_Invested_Overview_SUCCESS"
export const GET_Invested_Overview_FAIL = "GET_Invested_Overview__FAIL"



