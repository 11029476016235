import React from 'react';
import {
    FormGroup, Input
} from "reactstrap";
import { checkValidityClass } from "../Shared/utility";
import Label from "./Label";
const FileType = ({ name, label, type, placeholder, className, required, invalidMessage, value, content, onChanged, isDocumentTab, onFileUploadHandler, isQnnrUnlock, onDeleteFileHandler }) => {

    return (
        <>
            <FormGroup className="mb-3 mt-1">
                <Label
                    label={content.label}
                    name={name}
                    isRequired={content.required}
                />
                <Input
                    disabled={(isQnnrUnlock == 0 ? true : false)}
                    name={content.name}
                    type={content.type}
                    multiple
                    className={`${checkValidityClass(invalidMessage)} ${className ? 'form-control ' + className : 'form-control'}`}
                    id={content.id}
                    placeholder={content.placeholder}
                    required={content.required}
                    aria-invalid="true"
                    aria-describedby={`invalid_${name}`}
                    onChange={onChanged}
                    accept="application/pdf"
                    //accept="application/pdf,.png, .jpg, .jpeg"
                />
                <div type="invalid" className="invalid-feedback">{invalidMessage}</div>
                {isDocumentTab && (

                    <button
                        className={(isQnnrUnlock == 0 ? "disabled " : "") + "mt-3 ms-1 btn btn-primary"}
                        type="button"
                        onClick={(event) => {
                            if (isQnnrUnlock == 1)
                                onFileUploadHandler(name)
                        }}
                    >
                        Upload
                    </button>
                )}

                <div className="flex-grow-1 mb-6 mt-2">
                    {
                        (value && (typeof value == 'object' || Array.isArray(value))) &&
                        Array.from(value).map(val => {
                            return (
                                (val) ? (
                                    <>
                                        <a target="_blank" rel="noreferrer" href={val?.url} className="align-middle font-size-15" style={{ "padding": "0px 3px" }}><i className="bx bx-check btn btn-success btn-rounded" style={{ "padding": "0px 2px","font-size": "10px"}}></i>&nbsp;{val?.name}</a>
                                        {isDocumentTab && isQnnrUnlock == 1 && (
                                            <i
                                                onClick={(event) => {
                                                    onDeleteFileHandler(val)
                                                }}
                                                className="bx bx-trash text-danger"
                                                style={{ "cursor": "pointer", "padding-top": "0px !important", "font-size": "20px" }}></i>
                                        )}
                                        <br/>
                                    </>
                                ) : ""
                            )
                        })

                    }
                </div>
            </FormGroup>

        </>
    );
};


export default FileType