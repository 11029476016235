import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input, FormGroup, Button, FormFeedback,
          Nav,
          NavItem,
          NavLink, TabContent,
          TabPane, } from "reactstrap"
import { useLocation, useNavigate } from 'react-router-dom';
import "../../../assets/scss/pages/_employer-tabs.scss"
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import classnames from "classnames";

import { getEmployerContactDetail, addEmployerContactDetail, EmployerContactUpdate } from "../../../store/actions"; // getEmployerEmployeeCountries
//redux
import { useSelector, useDispatch } from "react-redux";

import { useAlert } from "../../../components/Common/Alert";

// Form Mask
import InputMask from "react-input-mask";
import {PersonalInformation} from "./EmployeeDetails/PersonalInformation";
import {Timesheet} from "./EmployeeDetails/Timesheet";
import {Case} from "./EmployeeDetails/Case";
import {Pay} from "./EmployeeDetails/Pay";
import {Job} from "./EmployeeDetails/Job";


//import {StatusReports} from "./EmployeeDetails/StatusReports";
//import {Meeting} from "./EmployeeDetails/Meeting";
import {Documents} from "./EmployeeDetails/Documents";


const FormLayoutsBasic = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL
  const [activeTab1, setactiveTab1] = useState("Personal information");
   useEffect(() => {
     dispatch(getEmployerContactDetail({'employee_id': editId}));
  }, [dispatch]);

   const { employercontactDetail } = useSelector((state) => ({
     employercontactDetail: state.EmployerContacts.employercontactDetail,
   }));
  

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    //initialValues: editId ? employercontactDetail : {
    initialValues: {
      id: editId,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      emp_type: '',
      job_title:'',
      visa:'',
      expiration_date:'',
      hire_date:'',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter  First Name"),
      last_name: Yup.string().required("Please Enter  Last Name"),
      email: Yup.string().required("Please Enter Company Email"),
      phone: Yup.string().required("Please Enter Phone Number"),
      emp_type: Yup.string().required("Please Select Beneficiary Type"),

    }),
    onSubmit: (values) => {
      if (Number(editId) > 0) {
        dispatch(EmployerContactUpdate(values));
      } else {
        dispatch(addEmployerContactDetail(values));
      }
      navigate("/employer/employeelist");
    }
  });
  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  const getLoggedInPlanType = () => {
    return localStorage.getItem("plan_type");
  }

  let title = editId ? 'Beneficiary Name: '+employercontactDetail.first_name+" "+employercontactDetail.last_name : 'Add Beneficiary';
  const BreadcrumbData = [
    {name:'Employees', url:'/employer/employeelist'},
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "Personal information",
                        })}
                        onClick={() => {
                          toggle1("Personal information");
                        }}
                      >
                        Personal information
                      </NavLink>
                    </NavItem>
                    { editId > 0 ? (
                      <React.Fragment>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "Job",
                          })}
                          onClick={() => {
                            toggle1("Job");
                          }}
                        >
                          Job
                        </NavLink> 
                      </NavItem>
                      { getLoggedInPlanType()==1 ? ( 
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "Pay",
                          })}
                          onClick={() => {
                            toggle1("Pay");
                          }}
                        >
                          Pay
                        </NavLink>
                      </NavItem>
                      ) : ''}
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "Case",
                          })}
                          onClick={() => {
                            toggle1("Case");
                          }}
                        >
                          Cases
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "Timesheets",
                          })}
                          onClick={() => {
                            toggle1("Timesheets");
                          }}
                        >
                          Timesheets
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "Status Reports",
                          })}
                          onClick={() => {
                            toggle1("Status Reports");
                          }}
                        >
                          Status Reports
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "Meeting",
                          })}
                          onClick={() => {
                            toggle1("Meeting");
                          }}
                        >
                          1:1 Meeting
                        </NavLink>
                      </NavItem>
                        */}
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "Documents",
                          })}
                          onClick={() => {
                            toggle1("Documents");
                          }}
                        >
                          Documents
                        </NavLink>
                      </NavItem>
                      </React.Fragment>
                    ) : ''}
                  </Nav>

                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="Personal information">
                      <PersonalInformation></PersonalInformation>
                    </TabPane>
                    <TabPane tabId="Job">
                      <Job></Job>
                    </TabPane>
                    <TabPane tabId="Pay">
                      <Pay></Pay>
                    </TabPane>
                    <TabPane tabId="Case">
                      <Case></Case>
                    </TabPane>
                    <TabPane tabId="Timesheets">
                      <Timesheet></Timesheet>
                    </TabPane> 
                    {/* <TabPane tabId="Status Reports">
                      <StatusReports></StatusReports>
                    </TabPane>
                    <TabPane tabId="Meeting">
                      <Meeting></Meeting>
                    </TabPane>
                     */}
                    <TabPane tabId="Documents">
                      <Documents></Documents>
                    </TabPane>
                  </TabContent>
                  
                </CardHeader>
                
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
      
    </React.Fragment>
  );
};

export default FormLayoutsBasic;