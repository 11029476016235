import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Card, CardBody,
  Container,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";

// Formik validation
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  addEmployerContactDetail,
  getEmployerEmployeeCountries,
  getEmployerEmployeeStates,
  validateDuplicateEmailId,
  getAttorneyContacts
} from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";

import { getDateFormatYMD } from "../../../common/data/dateFormat";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setAlertMessage } from "../../../store/common/actions";

const CreateBeneficiary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL
  const [sendInvoice, setSendInvoice] = useState(true)
  const [activeTab1, setactiveTab1] = useState("Personal information");
  const [validationCount, setValidationCount] = useState(0)
  useEffect(() => {
    if (editId > 0) {
      dispatch(getAttorneyContacts({ "status": "Active" }));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEmployerEmployeeCountries());
  }, []);

  useEffect(() => {
    //if(validation.values.country_citizen > 0)
    dispatch(getEmployerEmployeeStates({ country_id: 233 }));
  }, [dispatch]);

  const { attorneycontacts } = useSelector((state) => ({
    attorneycontacts: state.AttorneyContacts.attorneycontacts || [],
  }));

  const { countries } = useSelector((state) => ({
    countries: state.EmployerContacts.countries,
  }));

  const { states } = useSelector((state) => ({
    states: state.EmployerContacts.states,
  }));

  const { validateDuplicateEmployeeEmail } = useSelector((state) => ({
    validateDuplicateEmployeeEmail: state.EmployerContacts.validateDuplicateEmployeeEmail,
  }));


  const moreactions = [{ 'label': 'Save', 'action': 'saveandsend' }]
  const handleActionSubmit = (e) => {
    // setSendInvoice(true)
    validation.handleSubmit();
  };

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      employer_id: editId,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      emp_type: '',
      job_title: '',
      visa_type: '',
      expiration_date: '',
      hire_date: '',
      validationEmail: 0,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter First Name"),
      last_name: Yup.string().required("Please Enter Last Name"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone Number"),
      emp_type: Yup.string().required("Please Select Employee Type"),
      // validationEmail: Yup.mixed()
      //   .test("is-valid-email", "Email address already exist.",
      //     value => value == 0),
    }),
    onSubmit: (values) => {
      if (validation.values.validationEmail == 0 && validation.values.email != '') {
        dispatch(addEmployerContactDetail({ ...validation.values, hire_date: getDateFormatYMD(validation.initialValues.hire_date), expiration_date: getDateFormatYMD(validation.initialValues.expiration_date) }));
        setTimeout(() => {
          navigate("/attorney/add-contact?id=" + editId);
        }, 500);
      }
    }
  });

  const [apiSuccess, setApiSuccess] = useState(false);

  useEffect(() => {
    if (validateDuplicateEmployeeEmail?.count >= 0 && apiSuccess) {
      setValidationCount(validateDuplicateEmployeeEmail?.count);
      setApiSuccess(false)
      validation.setFieldValue('validationEmail', validateDuplicateEmployeeEmail?.count);
    }
  }, [validateDuplicateEmployeeEmail]);

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };
  let title = 'Create Beneficiary';

  const BreadcrumbData = [
    { name: 'Contacts', url: '/attorney/contacts' },
    { name: 'Add Contact', url: '/attorney/add-contact?id=' + editId }
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />


          <Row>

            <Col xs={12}>
              <Card>

                <CardBody className="p-4">
                  <div className="row">
                    <Col md="9"></Col>
                    <Col md="3">
                      <FormGroup className="mb-3">
                      </FormGroup>
                    </Col>

                  </div>
                  <FormikProvider value={validation}>
                    <Form className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (_.size(validation.errors) > 0) {
                          const errorObj = { "response": { "status": "400", "data": { "message": "Error - Please fill required form fields" } } }
                          dispatch(setAlertMessage({ message: errorObj, type: "danger" }));
                        }
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* import TextualInputs */}
                      <div className="mt-4 mt-lg-0">

                        <Row>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02"> Select Petitioner *</Label>
                              <Input
                                disabled={true}
                                name="employer_id"
                                placeholder="Select Petitione"
                                type="select"
                                className="form-control"
                                id="validationCustom02"
                                value={validation.values.employer_id || ""}
                                invalid={
                                  validation.touched.employer_id && validation.errors.employer_id ? true : false
                                }
                              >
                                {attorneycontacts && attorneycontacts.map((row, index) => (
                                  <option key={row?.company_name + index} value={row?.id}>{row.company_name}</option>
                                ))}
                              </Input>


                              {validation.touched.employer_id && validation.errors.employer_id ? (
                                <FormFeedback type="invalid">{validation.errors.employer_id}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">First Name *</Label>
                              <Input
                                name="first_name"
                                placeholder="First name"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.first_name || ""}
                                invalid={
                                  validation.touched.first_name && validation.errors.first_name ? true : false
                                }
                              />
                              {validation.touched.first_name && validation.errors.first_name ? (
                                <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Last Name *</Label>
                              <Input
                                name="last_name"
                                placeholder="Last name"
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.last_name || ""}
                                invalid={
                                  validation.touched.last_name && validation.errors.last_name ? true : false
                                }
                              />
                              {validation.touched.last_name && validation.errors.last_name ? (
                                <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Phone Number *</Label>
                              <InputMask
                                name="phone"
                                mask="(999)-999-9999"
                                className={`${validation.touched.phone && validation.errors.phone ? 'form-control is-invalid' : 'form-control'}`}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={
                                  validation.touched.phone && validation.errors.phone ? true : false
                                }
                              >
                                {(inputProps) => (
                                  // <Input  type="tel" className="" />
                                  <Input
                                    {...inputProps}
                                    placeholder="Enter Phone"
                                    type="text"
                                    className="form-control"
                                    id="validationCustom02"

                                  />
                                )}
                              </InputMask>
                              {validation.touched.phone && validation.errors.phone ? (
                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Email Address *</Label>
                              <Input
                                name="email"
                                placeholder="Email"
                                type="email"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={(event) => {
                                  validation.setFieldValue("email", event.target.value);
                                  setApiSuccess(true)
                                  dispatch(validateDuplicateEmailId({
                                    employer_id: editId,
                                    email: event.target.value
                                  }
                                  ))
                                }}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email && validation.errors.email || validation.values.validationEmail > 0 ? true : false
                                }
                              />
                              {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                              ) : validation.values.validationEmail > 0 ? (
                                <FormFeedback type="invalid">Email address already exist.</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Employment Type * </Label>
                              <Input
                                name="emp_type"
                                placeholder="Employment Type"
                                type="select"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.emp_type || ""}
                                invalid={
                                  validation.touched.emp_type && validation.errors.emp_type ? true : false
                                }
                              >
                                <option value="">Select employment type</option>
                                <option selected={validation.values.emp_type == "Full Time"} value="Full Time">Full Time</option>
                                <option selected={validation.values.emp_type == "Part Time"} value="Part Time">Part Time</option>
                                <option selected={validation.values.emp_type == "Internship"} value="Internship">Internship</option>
                                <option selected={validation.values.emp_type == "1099"} value="1099">1099</option>
                                <option selected={validation.values.emp_type == "C2C"} value="C2C">C2C</option>

                              </Input>


                              {validation.touched.emp_type && validation.errors.emp_type ? (
                                <FormFeedback type="invalid">{validation.errors.emp_type}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Job Title </Label>
                              <Input
                                name="job_title"
                                placeholder="Job Tile"
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.job_title || ""}
                                invalid={
                                  validation.touched.job_title && validation.errors.job_title ? true : false
                                }
                              />
                              {validation.touched.job_title && validation.errors.job_title ? (
                                <FormFeedback type="invalid">{validation.errors.job_title}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>


                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Visa  </Label>
                              <Input
                                name="visa_type"
                                placeholder="Visa type"
                                type="select"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.visa_type || ""}
                                invalid={
                                  validation.touched.visa_type && validation.errors.visa_type ? true : false
                                }
                              >  <option value="">Select visa</option>
                                <option selected={validation.values.visa_type == "B1"} value="B1">B1</option>
                                <option selected={validation.values.visa_type == "B2"} value="B2">B2</option>
                                <option selected={validation.values.visa_type == "H1B"} value="H1B">H1B</option>
                                <option selected={validation.values.visa_type == "H2B"} value="H2B">H2B</option>
                                <option selected={validation.values.visa_type == "L1A"} value="L1A">L1A</option>
                                <option selected={validation.values.visa_type == "L1B"} value="L1B">L1B</option>
                                <option selected={validation.values.visa_type == "OPT"} value="OPT">OPT</option>
                                <option selected={validation.values.visa_type == "STEM OPT"} value="STEM OPT">STEM OPT</option>
                                <option selected={validation.values.visa_type == "CPT"} value="CPT">CPT</option>
                                <option selected={validation.values.visa_type == "H4-EAD"} value="H4-EAD">H4-EAD</option>
                                <option selected={validation.values.visa_type == "GC-EAD"} value="GC-EAD">GC-EAD</option>
                                <option selected={validation.values.visa_type == "Permanent Resident"} value="Permanent Resident">Permanent Resident</option>
                                <option selected={validation.values.visa_type == "H1B Receipt"} value="H1B Receipt">H1B Receipt</option>
                                <option selected={validation.values.visa_type == "Stem Receipt"} value="Stem Receipt">Stem Receipt</option>
                              </Input>


                              {validation.touched.visa_type && validation.errors.visa_type ? (
                                <FormFeedback type="invalid">{validation.errors.visa_type}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>


                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Expire Date </Label>

                              <ReactDatePicker
                                showYearDropdown={true}
                                showIcon
                                placeholderText="MM/dd/yyyy"
                                className={`form-control ${validation.touched.expiration_date && validation.errors.expiration_date ? 'is-invalid' : ''}`}
                                name={`expiration_date`}
                                id={`expiration_date`}
                                dateFormat="MM/dd/yyyy"
                                onChange={(date) => {
                                  validation.setFieldValue(`expiration_date`, date)
                                }
                                }
                                selected={validation?.values?.expiration_date ? new Date(validation.values.expiration_date) : ''}
                              />  {validation.touched.expiration_date && validation.errors.expiration_date ? (
                                <FormFeedback type="invalid">{validation.errors.expiration_date}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Hire Date </Label>
                              <ReactDatePicker
                                showYearDropdown={true}
                                showIcon
                                placeholderText="MM/dd/yyyy"
                                className={`form-control ${validation.touched.hire_date && validation.errors.hire_date ? 'is-invalid' : ''}`}
                                name={`hire_date`}
                                id={`hire_date`}
                                dateFormat="MM/dd/yyyy"
                                onChange={(date) => {
                                  validation.setFieldValue(`hire_date`, date)
                                }
                                }
                                selected={validation?.values?.hire_date ? new Date(validation.values.hire_date) : ''}
                              />
                              {validation.touched.hire_date && validation.errors.hire_date ? (
                                <FormFeedback type="invalid">{validation.errors.hire_date}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                        </Row>


                      </div>
                      <Row style={{ float: "right", marginTop: "18px" }}>
                        <Col >
                          <span className="mt-4">
                            <button type="reset" className="btn btn-secondary" color="secondary" onClick={() => navigate(-1)}>
                              Cancel
                            </button>
                          </span>
                          &nbsp;&nbsp;
                          <span className="mt-4">
                            <button type="submit" className="btn btn-primary">
                              Save
                            </button>
                          </span>
                        </Col>
                      </Row>
                    </Form>
                  </FormikProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  )
};

export default CreateBeneficiary;