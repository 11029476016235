import React, { useEffect, useMemo, useState } from "react";
import {
    Link, useNavigate
} from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
} from "reactstrap";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import featureOne from "../../../assets/images/features-one.png";
import featureTwo from "../../../assets/images/features-two.png";
import featureThree from "../../../assets/images/features-three.png";
import featureFour from "../../../assets/images/features-four.png";
import featureFive from "../../../assets/images/features-five.png";


const EmployerBasicSuite = props => {

    //meta title
    document.title = `${props.componentName} | Immidock `;
    let title = props.componentName;
    const BreadcrumbData = [
        {name:'Dashboard', url:'/employer/dashboard'},
        { name: title, url: '#' }
    ]
    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
                    <Row>
                        <div className="wizard-container">
                            <div className="card card-wizard active" data-color="rose" id="wizardProfile">

                                {/* <div className="card-header text-center">
                                    <div className="card-header card-header-primary card-header-icon"> </div>
                                </div> */}
                                <div className="card-body invoice-card-cnt no-pad">





                                    <div className="features-container">


                                        <div className="features-block">

                                            <div className="features-text-primary" style={{ background: "#f4f7fb", padding: "30px 30px 120px 30px" }}>


                                                <h4><i className="fa fa-caret-up"></i> {props.componentName}</h4>

                                                <h2>This feature is available in HR Suite</h2>

                                                <a href="" className="green-arrow-link">Explore HR Suite for more features<span></span></a>
                                                <div className="spacer10"></div>

                                                <a href="#0" className="cd-btn js-cd-panel-trigger button-fb timesheet-btn add-invoice-btn cd-btn js-cd-panel-trigger text-capitalize btn-camel contactus" data-panel="main56">Contact us </a>

                                            </div>

                                            <div className="features-image">


                                                {/* <!--<img src="https://demo.immidock.com/assets/img/features/featured-image.jpg"/>--> */}

                                            </div>


                                        </div>



                                        <div className="features-block" style={{ display: "inline-flex" }}>


                                            <img src={featureOne} alt="" />
                                            {/* <img src="https://demo.immidock.com/assets/img/features/features-one.png"> */}

                                            <div className="features-text" style={{ margin: "50px" }}>




                                                <h2>Employee Engagement</h2>

                                                <p>Maintain Employer-Employee relationship through Reviews, Status Reports and 1:1's. Manage employees working at remote location through customizable questions for status updates. Conduct Reviews timely basis on a individual performance and provide formal feedback. Schedule 1:1 meetings with employees to discuss on the tasks and structure the conversation, collect next steps.</p>

                                                <a href="" className="green-arrow-link">Explore Features<span></span></a>


                                            </div>




                                        </div>
                                        <div className="features-block" style={{ display: "inline-flex" }}>

                                            <img src={featureTwo} alt="" />
                                            <div className="features-text" style={{ margin: "50px" }}>




                                                <h2>Performance Management</h2>

                                                <p>Identify your employees' strengths and weaknesses. Set goals, gather 360-degree feedback, and review performance. Bridge the gap between where employees are and where they need to be. Collaborate on an agenda to structure the conversation and collect next steps. Make sure you have time for check-ins and career development.</p>

                                                <a href="" className="green-arrow-link">Explore Features<span></span></a>


                                            </div>

                                        </div>

                                        <div className="features-block" style={{ display: "inline-flex" }}>

                                            <img src={featureThree} alt="" />

                                            <div className="features-text" style={{ margin: "50px" }}>




                                                <h2>Invoices</h2>

                                                <p>Generate invoices to your clients from your employee submitted timesheets and keep track of the status of invoices. Our software is integrated with Quickbooks and </p>

                                                <a href="" className="green-arrow-link">Explore Features<span></span></a>


                                            </div>


                                        </div>



                                        <div className="features-block" style={{ display: "inline-flex" }}>

                                            <img src={featureFour} alt="" />

                                            <div className="features-text" style={{ margin: "50px" }}>




                                                <h2>I-9 &amp; W-4</h2>

                                                <p>With the administration's high priority on immigration enforcement, it's crucial for employers to verify the identity and employment eligibility of new hires, promptly and accurately.</p>

                                                <a href="" className="green-arrow-link">Explore Features<span></span></a>


                                            </div>




                                        </div>

                                        <div className="features-block" style={{ display: "inline-flex" }}>

                                            <img src={featureFive} alt="" />
                                            <div className="features-text" style={{ margin: "50px" }}>




                                                <h2>Timesheets</h2>

                                                <p>Time tracking solution which puts your company in auto pilot mode. Track your employees work hours and approve them instantly. Add attachments with timesheets and auto generated alerts for over due timesheets submissions. </p>

                                                <a href="" className="green-arrow-link">Explore Features<span></span></a>


                                            </div>



                                        </div>


                                    </div>

                                    {/* 								  
<div id="contactus" className="cd-panel contactus cd-panel--from-right js-cd-panel-main56">
<header className="cd-panel__header">
 
  <h1 style={{fontSize: "18px", margin:"15px 0px 0px 0px", color: "#30bbbb", fontWeight: "500"}}>Contact Us</h1>
  <a href="#1" className="cd-panel__close js-cd-close">Close</a> </header>
<div className="cd-panel__container">
<form method="POST" action="">
	<input type="hidden" name="_token" value="VI9D73OYDgtL9Hnn8gtEJP6P8zm1ol3wu7IRUm8E"/>  <div className="cd-panel__content">
  
    <div className="row">
       <div className="col-md-11" style={{margin:"0px auto"}}>
        <div className="form-group bmd-form-group">
            <label htmlFor="exampleEmail" className="bmd-label ">First Name</label>
            <input type="text" className="form-control forminputfield" name="first_name" placeholder="Enter Input" value="" id="first_name" autoComplete="off"/>
          </div>
      </div>
    </div>
     <div className="row">
      <div className="col-md-11" style={{margin:"0px auto"}}>
         <div className="form-group bmd-form-group">
            <label htmlFor="exampleEmail" className="bmd-label ">Last Name</label>
            <input type="text" className="form-control forminputfield" name="last_name" placeholder="Enter Input" value="" id="last_name" autoComplete="off"/>
          </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-11" style={{margin:"0px auto"}}>
        <div className="form-group bmd-form-group">
            <label htmlFor="exampleEmail" className="bmd-label "> Email</label>
            <input type="text" className="form-control forminputfield" name="email" placeholder="Enter Input" value="" id="email" autoComplete="off"/>
          </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-11" style={{margin:"0px auto"}}>
          <div className="form-group bmd-form-group">
            <label htmlFor="exampleEmail" className="bmd-label ">Phone Number</label>
            <input type="text" className="form-control forminputfield" name="phone_no" placeholder="Enter Input" value="" id="phone_no" autoComplete="off"/>
          </div>
      </div>
    </div>
	
	<div className="row">
      <div className="col-md-11" style={{margin:"0px auto"}}>
          <div className="form-group bmd-form-group">
            <label htmlFor="exampleEmail" className="bmd-label ">Comments</label>
             <textarea className="form-control forminputfield" rows="05" name="comments" value="" placeholder="Enter input"></textarea>
          </div>
      </div>
    </div>
	
	
    <div className="clearfix"></div>
   <input type="submit" id="sendcontact" className="btn btn-round btn-rose timesheet-btn add-invoice-btn add-invoice-btn38" value="Send"/>
	</div>
	 </form>
</div>
</div> */}
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EmployerBasicSuite;
