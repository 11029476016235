import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardText,
  CardTitle,
  Col, Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Case from "./CaseDetails/Case";
import Documents from "./CaseDetails/Documents";
import Forms from "./CaseDetails/Forms";
import Invoice from "./CaseDetails/Invoice";
import Messages from "./CaseDetails/Messages";
import Notes from "./CaseDetails/Notes";
import Questionnaire from "./CaseDetails/Questionnaire";
import Reminder from "./CaseDetails/Reminder";
import Timeline from "./CaseDetails/Timeline";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";

import classnames from "classnames";

import { CasetypeUpdate, addCasetypeDetail, getCaseDocuments, getCaseForms, getCaseQnnrDetails, getCaseTimelines, getMessage, getNote, getReminder } from "../../../store/actions";

//redux
import { useDispatch, useSelector } from "react-redux";

import { changePreloader } from "../../../store/actions";



const FormLayoutsBasic = () => {

  var userType = window.localStorage.getItem("user_type");

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL
  const caseId = queryParams.get('caseId'); // Read the 'id' query parameter from the URL
  const activetab = queryParams.get('activetab');


  const { casetypeDetail } = useSelector((state) => ({
    casetypeDetail: state.casetypes.casetypeDetail,
  }));


  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: editId ? casetypeDetail : {
      id: editId,
      case_type: '',
      status: ''
    },
    validationSchema: Yup.object({
      case_type: Yup.string().required("Please Enter Your Case Type Name"),
      status: Yup.string().required("Please select Status"),

    }),
    onSubmit: (values) => {
      if (Number(editId) > 0) {
        dispatch(CasetypeUpdate(values));
      } else {
        dispatch(addCasetypeDetail(values));
      }
      navigate("/admin/case-types");
    }
  });
  let title = 'Case View';


  const [activeTab, setactiveTab] = useState("1");
  const [activeTab1, setactiveTab1] = useState("case");
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [headerTab, setHeaderTab] = useState("1");
  const [headerPillsTab, setHeaderPillsTab] = useState("1");
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);

  const [col5, setcol5] = useState(true);
  const [col6, setcol6] = useState(true);
  const [col7, setcol7] = useState(true);

  const [col9, setcol9] = useState(true);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);

  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };
  const toggleHeaderPills = (tab) => {
    if (headerPillsTab !== tab) setHeaderPillsTab(tab);
  };

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
  };

  const t_col5 = () => {
    setcol5(!col5);
  };

  const t_col6 = () => {
    setcol6(!col6);
  };

  const t_col7 = () => {
    setcol7(!col7);
  };

  const t_col8 = () => {
    setcol6(!col6);
    setcol7(!col7);
  };

  const t_col9 = () => {
    setcol9(!col9);
    setcol10(false);
    setcol11(false);
  };

  const t_col10 = () => {
    setcol10(!col10);
    setcol9(false);
    setcol11(false);
  };

  const t_col11 = () => {
    setcol11(!col11);
    setcol10(false);
    setcol9(false);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };
  useEffect(() => {
    if (activetab) {
      toggle1(activetab);
      dispatch(changePreloader(true))
      dispatch(getCaseForms({
        case_id: caseId
      }));
    }
  }, [activetab]);


  const BreadcrumbData = [
    { name: 'Cases', url: '/attorney/cases' },
    { name: title, url: '/attorney/view-case' }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

          <Row>
            <Col xs={12}>


              {/* start view */}
              <Card>
                {/*<CardHeader>
                  <CardTitle className="h4">Lakshman Garikapati</CardTitle>
  </CardHeader>*/}

                {/* <CardBody className="p-4"> */}
                <Nav tabs className="nav-tabs-custom nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab1 === "case",
                      })}
                      onClick={() => {
                        toggle1("case");
                      }}
                    >
                      Case
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab1 === "questionnaire",
                      })}
                      onClick={() => {
                        toggle1("questionnaire");
                        dispatch(changePreloader(true))
                        dispatch(getCaseQnnrDetails({
                          case_id: caseId
                        }));
                      }}
                    >
                      Questionnaire
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab1 === "forms",
                      })}
                      onClick={() => {
                        toggle1("forms");
                        dispatch(changePreloader(true))
                        dispatch(getCaseForms({
                          case_id: caseId
                        }));
                      }}
                    >
                      Forms
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab1 === "documents",
                      })}
                      onClick={() => {
                        toggle1("documents");
                        dispatch(changePreloader(true))
                        dispatch(getCaseDocuments({
                          case_id: caseId
                        }));
                      }}
                    >
                      Documents
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab1 === "timeline",
                      })}
                      onClick={() => {
                        toggle1("timeline");
                        dispatch(changePreloader(true))
                        dispatch(getCaseTimelines({
                          case_id: caseId,
                          category: "" // ??
                        }));
                      }}
                    >
                      Timeline
                    </NavLink>
                  </NavItem>



                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab1 === "notes",
                      })}
                      onClick={() => {
                        toggle1("notes");
                        dispatch(changePreloader(true))
                        dispatch(getNote({
                          case_id: caseId
                        }));
                      }}
                    >
                      Notes
                    </NavLink>
                  </NavItem>


                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab1 === "messages",
                      })}
                      onClick={() => {
                        toggle1("messages");
                        dispatch(changePreloader(true))
                        dispatch(getMessage({
                          case_id: caseId
                        }));
                      }}
                    >
                      Messages
                    </NavLink>
                  </NavItem>


                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab1 === "reminder",
                      })}
                      onClick={() => {
                        toggle1("reminder");
                        dispatch(changePreloader(true))
                        dispatch(getReminder({
                          case_id: caseId
                        }));
                      }}
                    >
                      Reminder
                    </NavLink>
                  </NavItem>

                  {/* {userType === "employer" && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "invoice",
                        })}
                        onClick={() => {
                          toggle1("invoice");
                        }}
                      >
                        Invoice
                      </NavLink>
                    </NavItem>
                  )} */}

                </Nav>

                <TabContent activeTab={activeTab1} className="p-3 text-muted">
                  <TabPane tabId="case">
                    <Case></Case>
                  </TabPane>
                  <TabPane tabId="questionnaire">
                    <Questionnaire></Questionnaire>
                  </TabPane>
                  <TabPane tabId="forms">
                    <Forms></Forms>
                  </TabPane>
                  <TabPane tabId="documents">
                    <Documents></Documents>
                  </TabPane>
                  <TabPane tabId="timeline">
                    <Timeline></Timeline>
                  </TabPane>
                  <TabPane tabId="notes">
                    <Notes></Notes>
                  </TabPane>
                  <TabPane tabId="messages">
                    <Messages></Messages>
                  </TabPane>
                  <TabPane tabId="reminder">
                    <Reminder></Reminder>
                  </TabPane>
                  {/* {userType === "employer" && (
                    <TabPane tabId="invoice">
                      <Invoice></Invoice>
                    </TabPane>
                  )} */}
                  <TabPane tabId="7">
                    <Row>
                      <Col sm="12">
                        <CardText className="mb-0">
                          Etsy mixtape wayfarers, ethical wes anderson tofu
                          before they sold out mcsweeney's organic lomo retro
                          fanny pack lo-fi farm-to-table readymade. Messenger
                          bag gentrify pitchfork tattooed craft beer, iphone
                          skateboard locavore carles etsy salvia banksy hoodie
                          helvetica. DIY synth PBR banksy irony. Leggings
                          gentrify squid 8-bit cred pitchfork. Williamsburg
                          banh mi whatever gluten-free, carles pitchfork
                          biodiesel fixie etsy retro mlkshk vice blog.
                          Scenester cred you probably haven't heard of them,
                          vinyl craft beer blog stumptown. Pitchfork
                          sustainable tofu synth chambray yr.
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="8">
                    <Row>
                      <Col sm="12">
                        <CardText className="mb-0">
                          Trust fund seitan letterpress, keytar raw denim
                          keffiyeh etsy art party before they sold out master
                          cleanse gluten-free squid scenester freegan cosby
                          sweater. Fanny pack portland seitan DIY, art party
                          locavore wolf cliche high life echo park Austin.
                          Cred vinyl keffiyeh DIY salvia PBR, banh mi before
                          they sold out farm-to-table VHS viral locavore cosby
                          sweater. Lomo wolf viral, mustache readymade
                          thundercats keffiyeh craft beer marfa ethical. Wolf
                          salvia freegan, sartorial keffiyeh echo park vegan.
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
                {/* </CardBody> */}
              </Card>
              {/* end view */}


            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormLayoutsBasic;