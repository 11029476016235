import {
  GET_COMMON_USERS,
  GET_COMMON_USERS_FAIL,
  GET_COMMON_USERS_SUCCESS,

  GET_COMMON_USER_DETAIL,
  GET_COMMON_USER_DETAIL_FAIL,
  GET_COMMON_USER_DETAIL_SUCCESS,

  UPDATE_COMMON_USER_DETAIL_FAIL,
  UPDATE_COMMON_USER_DETAIL_SUCCESS,
  UPDATE_COMMON_USER_DETAIL,

  DELETE_COMMON_USER_DETAIL_FAIL,
  DELETE_COMMON_USER_DETAIL_SUCCESS,
  DELETE_COMMON_USER_DETAIL,

  ADD_COMMON_USER_DETAIL_SUCCESS,
  ADD_COMMON_USER_DETAIL,
  ADD_COMMON_USER_DETAIL_FAIL

} from "./actionTypes"

export const getCommonUsers = (data) => ({
  type: GET_COMMON_USERS,
  payload: data
})

export const getCommonUsersSuccess = users => ({
  type: GET_COMMON_USERS_SUCCESS,
  payload: users,
})

export const getCommonUsersFail = error => ({
  type: GET_COMMON_USERS_FAIL,
  payload: error,
})

export const getCommonUserDetail = userId => ({
  type: GET_COMMON_USER_DETAIL,
  userId,
})

export const getCommonUserDetailSuccess = users => ({
  type: GET_COMMON_USER_DETAIL_SUCCESS,
  payload: users,
})

export const getCommonUserDetailFail = error => ({
  type: GET_COMMON_USER_DETAIL_FAIL,
  payload: error,
})

export const addCommonUserDetail = (data) => ({
  type: ADD_COMMON_USER_DETAIL,
  payload: data
})

export const addCommonUserdetailSuccess = (data) => ({
  type: ADD_COMMON_USER_DETAIL_SUCCESS,
  payload: data
})

export const addCommonUserdetailFail = (error) => ({
  type: ADD_COMMON_USER_DETAIL_FAIL,
  payload: error
})

export const userCommonDelete = (data) => ({
  type: DELETE_COMMON_USER_DETAIL,
  payload: data
})

export const userCommonDeleteSuccess = (data) => ({
  type: DELETE_COMMON_USER_DETAIL_SUCCESS,
  payload: data
})

export const userCommonDeleteFail = (error) => ({
  type: DELETE_COMMON_USER_DETAIL_FAIL,
  payload: error
})

export const userCommonUpdate = (data) => ({
  type: UPDATE_COMMON_USER_DETAIL,
  payload: data
})

export const userCommonUpdateSuccess = (data) => ({
  type: UPDATE_COMMON_USER_DETAIL_SUCCESS,
  payload: data
})

export const userCommonUpdateFail = (error) => ({
  type: UPDATE_COMMON_USER_DETAIL_FAIL,
  payload: error
})
