import React, { useEffect, useMemo, useState } from "react";
import DatatableContainer from "../../../components/Common/DatatableContainer";
import {
  Link, useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

import { getCaseFormAttributeGroups, getcaseformAttributesets, changePreloader, getAttributegroupDetail } from "../../../store/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  CommonCell, Date, CaseTypeName
} from "./caseTypeListCol";
import { useAlert } from "../../../components/Common/Alert";

//redux
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";
import { getCaseformattributegroups } from "../../../helpers/apibackend_helper";
import { caseFormAttributeGroupDelete, getCaseFormAttributeGroupDetail } from "../../../store/admin/caseformattributesgroups/actions";

const CaseFormAttributeGroups = () => {

  //meta title
  document.title = "Case Form Attribute Groups | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [order, setOrder] = useState(null);

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);

  const { caseformattributegroups } = useSelector((state) => ({
    caseformattributegroups: state.caseformattributesgroups.caseformattributegroups || [],
  }));

  useEffect(() => {
    dispatch(getCaseFormAttributeGroups());
  }, []);
  
  useEffect(() => {
    if (_.isEmpty(caseformattributegroups))
      dispatch(changePreloader(true));
    else
      dispatch(changePreloader(false));
  }, [caseformattributegroups]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {
    dispatch(getCaseFormAttributeGroupDetail());
    navigate(`/admin/add-case-form-attribute-groups`);
  };

  const onUpdateData = (data) => {
    if (data.id) {
      dispatch(getCaseFormAttributeGroupDetail(data.id));
    }
    navigate(`/admin/add-case-form-attribute-groups?id=${data.id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };

  const handleDeleteInvoice = () => {
    if (order.id) {
      dispatch(caseFormAttributeGroupDelete(order.id));
      setDeleteModal(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Case Form",
        accessor: "form_name",
        filterable: true,
        disableFilters: true,
        // Cell: (cellProps) => {
        //   return <CaseTypeName {...cellProps} />;
        // },
      },

      {
        Header: "Attribute Sets",
        accessor: "attributesSetsIds",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Description",
        accessor: "shortDescription",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },


      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onUpdateData(cellProps.row.original)}>Edit</DropdownItem>
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/admin/dashboard' },
    { name: 'Case Form Attribute Groups', url: '/admin/case-form-attribute-groups' }
  ]
  let title = 'Case Form Attribute Groups';

  return (
    <React.Fragment>
      {<DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteInvoice}
        onCloseClick={() => setDeleteModal(false)}
      />}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={caseformattributegroups}
                    isGlobalFilter={true}
                    isFilterDisable={true}
                    isAddCaseFormAttributeGroup={true}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CaseFormAttributeGroups;
