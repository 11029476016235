import {
  GET_ATTRIBUTETEMPLATES,
  GET_ATTRIBUTETEMPLATES_FAIL,
  GET_ATTRIBUTETEMPLATES_SUCCESS,
  GET_ATTRIBUTETEMPLATE_DETAIL,
  GET_ATTRIBUTETEMPLATE_DETAIL_FAIL,
  GET_ATTRIBUTETEMPLATE_DETAIL_SUCCESS,
  UPDATE_ATTRIBUTETEMPLATE_DETAIL_FAIL,
  UPDATE_ATTRIBUTETEMPLATE_DETAIL_SUCCESS,
  UPDATE_ATTRIBUTETEMPLATE_DETAIL,
  DELETE_ATTRIBUTETEMPLATE_DETAIL_FAIL,
  DELETE_ATTRIBUTETEMPLATE_DETAIL_SUCCESS,
  DELETE_ATTRIBUTETEMPLATE_DETAIL,
  ADD_ATTRIBUTETEMPLATE_DETAIL_SUCCESS,
  ADD_ATTRIBUTETEMPLATE_DETAIL,
  ADD_ATTRIBUTETEMPLATE_DETAIL_FAIL

} from "./actionTypes"

export const getAttributetemplates = () => ({
  type: GET_ATTRIBUTETEMPLATES,
})

export const getAttributetemplatesSuccess = Attributetemplates => ({
  type: GET_ATTRIBUTETEMPLATES_SUCCESS,
  payload: Attributetemplates,
})

export const getAttributetemplatesFail = error => ({
  type: GET_ATTRIBUTETEMPLATES_FAIL,
  payload: error,
})

export const getAttributetemplateDetail = AttributetemplateId => ({
  type: GET_ATTRIBUTETEMPLATE_DETAIL,
  AttributetemplateId,
})

export const getAttributetemplateDetailSuccess = Attributetemplates => ({
  type: GET_ATTRIBUTETEMPLATE_DETAIL_SUCCESS,
  payload: Attributetemplates,
})

export const getAttributetemplateDetailFail = error => ({
  type: GET_ATTRIBUTETEMPLATE_DETAIL_FAIL,
  payload: error,
})

export const  addAttributetemplateDetail = (data) => ({
  type: ADD_ATTRIBUTETEMPLATE_DETAIL,
  payload: data
})

export const addAttributetemplatedetailSuccess = (data) => ({
  type: ADD_ATTRIBUTETEMPLATE_DETAIL_SUCCESS,
  payload: data
})

export const addAttributetemplatedetailFail = (error) => ({
  type: ADD_ATTRIBUTETEMPLATE_DETAIL_FAIL,
  payload: error
})

export const AttributetemplateDelete = (data) => ({
  type: DELETE_ATTRIBUTETEMPLATE_DETAIL,
  payload: data
})

export const AttributetemplateDeleteSuccess = (data) => ({
  type: DELETE_ATTRIBUTETEMPLATE_DETAIL_SUCCESS,
  payload: data
})

export const AttributetemplateDeleteFail = (error) => ({
  type: DELETE_ATTRIBUTETEMPLATE_DETAIL_FAIL,
  payload: error
})

export const AttributetemplateUpdate = (data) => ({
  type: UPDATE_ATTRIBUTETEMPLATE_DETAIL,
  payload: data
})

export const AttributetemplateUpdateSuccess = (data) => ({
  type: UPDATE_ATTRIBUTETEMPLATE_DETAIL_SUCCESS,
  payload: data
})

export const AttributetemplateUpdateFail = (error) => ({
  type: UPDATE_ATTRIBUTETEMPLATE_DETAIL_FAIL,
  payload: error
})
