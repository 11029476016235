import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

import { useSelector } from "react-redux";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");
  const [isParentUser, setIsParentUser] = useState(false);
  const { user_type } = useSelector((state) => ({
    user_type: state.login.user_type || localStorage.getItem('user_type'),
  }));
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setIsParentUser(obj?.data?.isParentUser)
      setusername(obj?.data?.name);
    }
  }, [props.success]);
  var userType = window.localStorage.getItem("user_type")
  var userStatus = window.localStorage.getItem("status")

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile"> */}
          {/* <Link to={"/contacts-profile"} className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
            {props.t("Profile")}{" "}
          </Link>{" "} */}
          {userStatus == "Active" && (<>
            {
              ["attorney", "employer"].includes(userType) ? (
                <>
                  <Link to={`/${userType}/users`} className="dropdown-item">
                    <i className="bx bx-group font-size-16 align-middle me-1" />{" "}
                    {props.t("Users")}{" "}
                  </Link>
                  {isParentUser && (
                    <Link to={`/${userType}/companyinfo`} className="dropdown-item">
                      <i className="bx bx-buildings font-size-16 align-middle me-1" />{" "}
                      {props.t("Company Info")}{" "}
                    </Link>
                  )}
                </>
              ) : null
            }
            {(user_type != 'admin') && (
              <Link to={`/${userType}/change-password`} className="dropdown-item">
                <i className="bx bxs-lock font-size-16 align-middle me-1" />{" "}
                {props.t("Change Password")}{" "}
              </Link>
            )}

            {(user_type === 'employer' || user_type === "employee") && (
              <>
                <Link to={`/${user_type}/esignature`} className="dropdown-item">
                  <i className="bx bx-wrench font-size-16 align-middle me-1" />
                  {'Esign'}
                </Link>
              </>)
            }
            {(user_type === 'employer') && (
              <>
                <Link to={"/employer/settings"} className="dropdown-item">
                  <i className="bx bx-wrench font-size-16 align-middle me-1" />
                  {'Settings'}
                </Link>
              </>)
            }
          </>
          )}

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)
  );