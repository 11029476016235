import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";


import { addAttorneyCaseDetail, AttorneyCaseUpdate, getAttorneyCaseDetail, getAttorneyCases, getAttorneyCaseTypes, getAttorneyContacts, getEmployerEmployees, getEmployerQnnrtypes } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "../../../store/common/actions";

// import { useAlert } from "../../../components/Common/Alert";


const FormLayoutsBasic = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL

  const [OptionGroup, setOptionGroup] = useState([]);
  const [EmployerEmployeeOptionGroup, setEmployerEmployeeOptionGroup] = useState([]);

  const [selectedGroup, setselectedGroup] = useState(null)

  const [selectedEmployerGroup, setselectedEmployerGroup] = useState(null)

  const { attorneyCaseTypes } = useSelector((state) => ({
    attorneyCaseTypes: state.AttorneyCases.attorneyCaseTypes,
  }));

  const { employercontacts, attorneycontacts, employeremployees } = useSelector((state) => ({
    employercontacts: state.EmployerContacts.employercontacts || [],
    attorneycontacts: state.AttorneyContacts.attorneycontacts || [],
    employeremployees: state.AttorneyCases.employeremployees || [],
  }));

  const { employerQnnrTypes } = useSelector((state) => ({
    employerQnnrTypes: state.EmployerCases.employerQnnrTypes,
  }));


  //GET EMPLOYEES
  useEffect(() => {
    dispatch(getAttorneyCaseTypes());
    if (editId)
      dispatch(getAttorneyCaseDetail(editId));

  }, [dispatch, editId]);

  useEffect(() => {
    dispatch(getAttorneyContacts({ "status": "Active" }));
  }, [dispatch]);

  useEffect(() => {
    if (attorneycontacts && attorneycontacts.length > 0) {
      let arrEmployees = [];
      attorneycontacts.forEach(row => {
        if (row?.company_name)
          arrEmployees.push({ label: row.company_name, value: (row.id) })
      })
      setOptionGroup(arrEmployees);
    }
  }, [attorneycontacts]);


  useEffect(() => {
    if (employeremployees && employeremployees.length > 0) {
      let arrEmployees = [];
      employeremployees.forEach(row => {
        if (row.first_name)
          arrEmployees.push({ label: row.first_name + " " + row.last_name, value: (row.id || row?.employee_id) })
      })
      setEmployerEmployeeOptionGroup(arrEmployees);
    }
  }, [employeremployees]);

  const handleSelectEmployerGroup = (selectedEmployerGroup) => {
    setselectedEmployerGroup(selectedEmployerGroup);
    validation.setFieldValue('employer_id', selectedEmployerGroup.value)
    dispatch(getEmployerEmployees({ "employer_id": selectedEmployerGroup.value }));
  };
  const handleSelectGroup = (selectedData) => {
    setselectedGroup(selectedData)
  };

  useEffect(() => {
    if (selectedGroup) {
      validation.setFieldValue('emp_name', selectedGroup?.label)
      validation.setFieldValue('employee_id', selectedGroup?.value)
    }
  }, [selectedGroup]);

  const reloadQnnrTypes = (e) => {
    validation.setFieldValue(e.target.name, e.target.value)
    if (Number(e.target.value))
      dispatch(getEmployerQnnrtypes(Number(e.target.value)));
  }



  // Form validation 
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: editId,
      case_type: "",
      qnnr_type: "",
      employer_id: "",
      employee_id: "",
      emp_name: "",
      qnnr_type: "",
      accept_terms: ""
    },
    validationSchema: Yup.object({
      employer_id: Yup.string().required("Please Select Employer").nullable(),
      employee_id: Yup.string().required("Please Select Employee").nullable(),
      case_type: Yup.string().required("Please Select Case Type").nullable(),
      qnnr_type: Yup.string().required("Please select Questionnaire").nullable(),
      accept_terms: Yup.boolean().required('Please accept checkbox for no changes in the company information')
        .oneOf([true], 'Please accept checkbox for no changes in the company information').nullable(),
    }),
    onSubmit: (values) => {
      if (values.employee_id) {
        if (Number(editId) > 0) {
          dispatch(AttorneyCaseUpdate(values));
        } else {
          dispatch(addAttorneyCaseDetail(values));
        }
        setTimeout(function () {
          navigate("/attorney/cases");
        }, 1000)
      }
    }
  });

  let title = editId ? 'Update Case' : 'Create Case';
  const BreadcrumbData = [
    { name: 'Cases', url: '/attorney/cases' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody className="p-4">
                  <FormikProvider value={validation}>
                    <Form className="needs-validation"
                      onSubmit={(values) => {
                        values.preventDefault();
                        if (_.size(validation.errors) > 0) {
                          const errorObj = { "response": { "status": "400", "data": { "message": "Error - Please fill required form fields" } } }
                          dispatch(setAlertMessage({ message: errorObj, type: "danger" }));
                        }
                        // console.log(validate(values));
                        validation.handleSubmit();
                        return false;
                      }}
                    >

                      <div className="mt-4 mt-lg-0">


                        <Row className="mb-4">

                          <Col sm={6}>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="horizontal-firstname-input"
                              >
                                Select employer name for this case *
                              </Label>
                              <Select

                                name="employer_id"
                                value={selectedEmployerGroup}
                                onChange={event => handleSelectEmployerGroup(event)}
                                options={OptionGroup}
                                classNamePrefix={`${validation.touched.employer_id && validation.errors.employer_id ? 'is-invalid-select2 select2-selection' : 'select2-selection'}`}
                                invalid={
                                  validation.touched.employer_id && validation.errors.employer_id ? true : false
                                }
                              >
                                <option value="">Select</option>
                              </Select>
                              {validation.touched.employer_id && validation.errors.employer_id ? (
                                <FormFeedback type="invalid">{validation.errors.employer_id}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col sm={6}>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="horizontal-firstname-input"
                              >
                                Select beneficiary Name for this case *
                              </Label>
                              <Select

                                name="employee_id"
                                value={selectedGroup}
                                onChange={event => handleSelectGroup(event)}
                                options={EmployerEmployeeOptionGroup}
                                classNamePrefix={`${validation.touched.employee_id && validation.errors.employee_id ? 'is-invalid-select2 select2-selection' : 'select2-selection'}`}
                                invalid={
                                  validation.touched.employee_id && validation.errors.employee_id ? true : false
                                }
                              >
                                <option value="">Select</option>
                              </Select>
                              {validation.touched.employee_id && validation.errors.employee_id ? (
                                <FormFeedback type="invalid">{validation.errors.employee_id}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col sm={6}>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="horizontal-firstname-input"
                              >
                                Select Visa category *
                              </Label>
                              <Input
                                name="case_type"
                                type="select"
                                className="form-control"
                                id="validationCustom02"
                                onBlur={validation.handleBlur}
                                onChange={reloadQnnrTypes}
                                value={validation.values.case_type || ""}
                                invalid={
                                  validation.touched.case_type && validation.errors.case_type ? true : false
                                }
                              >
                                <option value="">Select visa category</option>
                                {attorneyCaseTypes.map(row => (
                                  <option key={row.id} value={row.id}>
                                    {row.case_type}
                                  </option>
                                ))}
                              </Input>
                              {validation.touched.case_type && validation.errors.case_type ? (
                                <FormFeedback type="invalid">{validation.errors.case_type}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col sm={6}>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="horizontal-firstname-input"
                              >
                                Select questionnaire to be used to file the petition*
                              </Label>
                              <Input
                                name="qnnr_type"
                                placeholder=""
                                type="select"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.qnnr_type || ""}
                                invalid={
                                  validation.touched.qnnr_type && validation.errors.qnnr_type ? true : false
                                }

                              >
                                <option value="">Click to select</option>
                                {employerQnnrTypes.map(row => (
                                  <option key={row.qnnr_type + row.id} value={row.id}>
                                    {row.qnnr_type}
                                  </option>
                                ))}
                              </Input>

                              {validation.touched.qnnr_type && validation.errors.qnnr_type ? (
                                <FormFeedback type="invalid">{validation.errors.qnnr_type}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col sm={10}>
                            <FormGroup className="mb-3">

                              <Label
                              >
                                <Input
                                  name="accept_terms"
                                  type="checkbox"
                                  className="form-control"
                                  id="validationCustom01"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.accept_terms}
                                  style={{ "float": "left", "margin-right": "5px" }}
                                />
                                There are no changes to company information *
                              </Label>
                              {validation.touched.accept_terms && validation.errors.accept_terms ? (
                                <FormFeedback type="invalid">{validation.errors.accept_terms}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col sm={2}>
                            <FormGroup className="mb-0">
                              <div style={{ "float": "right" }}>
                                <Button type="reset" color="secondary" onClick={() => navigate(-1)}>
                                  Cancel
                                </Button>{" "}
                                <Button type="submit" className="ms-1" color="primary">
                                  Save
                                </Button>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>

                      </div>
                    </Form>
                  </FormikProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormLayoutsBasic;