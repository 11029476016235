import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";


import { addAttorneyDetail, attorneyUpdate, getAttorneyDetail } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";

const FormLayoutsBasic = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL

  useEffect(() => {
    if (editId)
      dispatch(getAttorneyDetail(editId));
  }, [dispatch, editId]);


  const { attorneyDetail } = useSelector((state) => ({
    attorneyDetail: state.attorneys.attorneyDetail,
  }));

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: editId ? attorneyDetail : {
      first_name: '',
      last_name: '',
      email: '',
      domain: '',
      cmpn_name: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter representative First Name"),
      last_name: Yup.string().required("Please Enter representative Last Name"),
      email: Yup.string().required("Please Enter Company Email"),
      domain: Yup.string().required("Please Enter Login URL"),
      cmpn_name: Yup.string().required("Please Enter Attorney Company Name"),

    }),
    onSubmit: (values) => {
      if (Number(editId) > 0) {
        dispatch(attorneyUpdate(values));
      } else {
        dispatch(addAttorneyDetail(values));
      }
      navigate("/admin/attorneys");
    }
  });
  const BreadcrumbData = [
    { name: 'Attorneys', url: '/admin/attorneys' },
    { name: 'Create Attorney', url: '/admin/add-attorney' }
  ]
  let title = editId ? 'Update Attorney' : 'Add Attorney';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

          <Row>
            <Col xs={12}>
              <Card>

                <CardBody className="p-4">
                  <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {/* import TextualInputs */}
                    <div className="mt-4 mt-lg-0">
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          Company Name:
                        </Label>
                        <Col sm={5}>
                          <Input
                            name="cmpn_name"
                            placeholder="Enter Attorney Company Name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.cmpn_name || ""}
                            invalid={
                              validation.touched.cmpn_name && validation.errors.cmpn_name ? true : false
                            }
                          />
                          {validation.touched.cmpn_name && validation.errors.cmpn_name ? (
                            <FormFeedback type="invalid">{validation.errors.cmpn_name}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          First Name:
                        </Label>
                        <Col sm={5}>
                          <Input
                            name="first_name"
                            placeholder="Enter Representative First Name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.first_name || ""}
                            invalid={
                              validation.touched.first_name && validation.errors.first_name ? true : false
                            }
                          />
                          {validation.touched.first_name && validation.errors.first_name ? (
                            <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          Last Name:
                        </Label>
                        <Col sm={5}>
                          <Input
                            name="last_name"
                            placeholder="Enter Representative Last Name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.last_name || ""}
                            invalid={
                              validation.touched.last_name && validation.errors.last_name ? true : false
                            }
                          />
                          {validation.touched.last_name && validation.errors.last_name ? (
                            <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>


                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          Email:
                        </Label>
                        <Col sm={5}>
                          <Input
                            name="email"
                            placeholder="Enter Company Email"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                          Domain:
                        </Label>
                        <Col sm={5}>
                          <Input
                            name="domain"
                            placeholder="Enter Login URL"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.domain || ""}
                            invalid={
                              validation.touched.domain && validation.errors.domain ? true : false
                            }
                          />
                          {validation.touched.domain && validation.errors.domain ? (
                            <FormFeedback type="invalid">{validation.errors.domain}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>


                      <Row className="justify-content-middle">
                        <Col sm={2}>&nbsp;
                        </Col>
                        <Col sm={4}>
                          <FormGroup className="mb-0">
                            <div>
                              <Button type="reset" color="secondary" onClick={() => navigate(-1)}>
                                Cancel
                              </Button>{" "}
                              <Button type="submit" className="ms-1" color="primary">
                                Save
                              </Button>

                            </div>
                          </FormGroup>

                        </Col>
                      </Row>

                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormLayoutsBasic;