import React from "react";
import { Link } from "react-router-dom"
import moment from "moment";


const Id = (cell) => {
  return cell.value ? cell.value : '';
};

const LinkCell = (cell) => {
  return (
    <Link target="_blank" style={{ color: "lightblue" }} className="text-dark fw-medium" to={cell.value}>{cell.column.displayName}</Link>
  );
};
const LinkCases = (cell) => {
  const casesViewList = cell.value ? cell.value.split(',') : [];
  return (
    <>
      {

        casesViewList.map((case_id, index) => (
          <>
            <Link key={index} target="_blank" className="text-primary fw-medium" to={`/employer/view-case?caseId=${case_id}`}>{case_id}</Link>
            {casesViewList.length - 1 == index ? "" : ", "}
          </>
        ))}
    </>);
};

const Date = (cell) => {
  return cell.value ? moment(cell.value).format("MMM DD, YYYY") : '';
};

const CaseTypeName = (cell) => {
  return cell.value;
};
const CommonCell = (cell) => {
  return cell.value ? cell.value + ' ' + (cell.column.concatVal ? cell.column.concatVal : '') : '';
};

export {
  CommonCell,
  Date,
  CaseTypeName,
  LinkCell,
  LinkCases
};