import React from "react";

const NotificationName = ({ cellProps, onViewNotification }) => {

  return (
    <div onClick={() => onViewNotification(cellProps.row.original)} style={!cellProps.row.original.is_read ? { backgroundColor: "#e9e9ef", cursor: "pointer" } : { cursor: "pointer" }} >
      <span style={!cellProps.row.original.is_read ? { fontWeight: "bold", width: "100%" } : {}} dangerouslySetInnerHTML={{ __html: cellProps.row.original.notification }} />
      <div className="notificationdate">
        <i className="fa fa-calendar" aria-hidden="true" /> {cellProps.row.original.created_at}
        &nbsp;&nbsp;&nbsp;<i className={cellProps.row.original.is_read ? "mdi mdi-eye font-size-15" : "mdi mdi-eye-off font-size-15"}></i>
      </div>
    </div>
  );
};

export {
  NotificationName
};

