import React, { useState, useEffect } from 'react';

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Card, CardBody, CardHeader, Col, Container, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap"

import UpdateCase from "./UpdateCase";
import { changePreloader, getAttorneyCaseDetail } from "../../../../store/actions";
import { Link, useNavigate } from 'react-router-dom';


const Case = () => {
    const [isScrollBackDrop, setIsScrollBackDrop] = useState(false);
    const toggleScrollBackDrop = () => {
        setIsScrollBackDrop(!isScrollBackDrop);
    };
    //meta title
    document.title = "Case Details | Immidock ";

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const caseId = queryParams.get('caseId'); // Read the 'id' query parameter from the URL

    const { caseDetail } = useSelector((state) => ({
        caseDetail: state.AttorneyCases.caseDetail || {},
    }));

    const [apiSuccess, setApiSuccess] = useState(false);

    useEffect(() => {
        dispatch(getAttorneyCaseDetail({
            case_id: caseId
        }));
        dispatch(changePreloader(true));
        setApiSuccess(true)
    }, [dispatch]);

    useEffect(() => {
        if (apiSuccess) {
            dispatch(changePreloader(false));
            setApiSuccess(false)
        }
    }, [caseDetail]);

    useEffect(() => {
        document.querySelectorAll("a[class^='updateCase']").forEach(node => {
            node.addEventListener('click', e => {
                e.preventDefault(); // Prevent the default behavior (redirect to another page)
                //console.log(e.target.href, '--- print href value'); // Log the clicked anchor tag's href
                navigate(`/${userType}/update-case-details?id=${caseId}`);
            });
        }),
            document.querySelectorAll("a[class^='updateLCA']").forEach(node => {
                node.addEventListener('click', e => {
                    e.preventDefault(); // Prevent the default behavior (redirect to another page)
                    //console.log(e.target.href, '--- print href value'); // Log the clicked anchor tag's href
                    navigate(`/${userType}/update-lca-details?id=${caseId}`);
                });
            })
    }, []);

    
    var userType = window.localStorage.getItem("user_type")

    return (
        <React.Fragment>
            <div className="">

                <row>
                    {/* Render Breadcrumbs */}

                    <Row>
                        <Col lg={12}>

                            <Offcanvas
                                isOpen={isScrollBackDrop}
                                scrollable
                                direction="end"
                                toggle={toggleScrollBackDrop}>
                                <OffcanvasHeader toggle={toggleScrollBackDrop}>
                                    Update Case Details
                                </OffcanvasHeader>
                                <OffcanvasBody>
                                    <UpdateCase></UpdateCase>
                                </OffcanvasBody>
                            </Offcanvas>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>

                            <Card>
                                <CardHeader>
                                    <div className="invoice-title">
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <div className="mb-4">
                                                    <img src="/favicon.ico" alt="" height="24" /><span className="logo-txt">Case Details</span>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <div className="mb-4">
                                                    <h4 className="float-end font-size-16">
                                                        <a className="updateCase btn btn-primary" href="#">
                                                            <i className="bx bx-edit align-middle"></i>
                                                        </a>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <hr className="my-4" /> */}

                                </CardHeader>

                                <CardBody>

                                    <Row>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Case ID
                                                    </h5>
                                                    <p>{caseDetail.case_number}</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Case Type
                                                    </h5>
                                                    <p>{caseDetail.qnnr_type}</p>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Case Status
                                                    </h5>
                                                    <p>{caseDetail.status}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Date Created <br />

                                                    </h5>
                                                    <p>{caseDetail.created_at}</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Beneficiary Name
                                                    </h5>
                                                    <p>{caseDetail.employee_name}</p>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Petitioner </h5>
                                                    <p>{caseDetail.petitioner_company}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Receipt Number

                                                    </h5>
                                                    <p>{caseDetail.receipt_no ? caseDetail.receipt_no : 'N/A'}</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        RFE Due Date
                                                    </h5>
                                                    <p>{caseDetail.rfe_due_date ? caseDetail.rfe_due_date : 'N/A'}</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Shipment Tracking
                                                    </h5>
                                                    <p>{caseDetail.shipment_tracking ? caseDetail.shipment_tracking : 'N/A'}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row >

                                    <Row>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Beneficiary Email
                                                    </h5>
                                                    <p>{caseDetail.employee_email}</p>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Beneficiary Phone Number
                                                    </h5>
                                                    <p>{caseDetail.phone_number}</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Approved Until
                                                    </h5>
                                                    <p>{caseDetail.approved_until_date}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row >
                                </CardBody >
                            </Card >
                        </Col >
                    </Row >


                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="font-size-13">USCIS Status</h5>
                                </CardHeader>

                                <CardBody>

                                    <Row>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Current Status
                                                    </h5>
                                                    <p>{caseDetail.uscis_case_status ? caseDetail.uscis_case_status : 'N/A'}</p>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Last Updated
                                                    </h5>
                                                    <p>{caseDetail.uscis_status_last_updated ? caseDetail.uscis_status_last_updated : 'N/A'}</p>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4">

                                            <div className="mt-4">
                                                <Link to={caseDetail.uscis_case_status_landing_url ? caseDetail.uscis_case_status_landing_url : 'javascript:void()'} target="_blank" className="btn btn-primary">
                                                    Details Status
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col >
                    </Row >

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="font-size-13">Processing Status</h5>
                                </CardHeader>

                                <CardBody>

                                    <div className="grid-structure">
                                        <Row>
                                            <div className="col-lg-4">
                                                <div className="">
                                                    <button className="btn btn-primary" type="button"><i
                                                        className="bx bxs-check-circle align-middle"></i></button> <br />{caseDetail.processing_status}
                                                </div>
                                            </div>
                                        </Row>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <div className="invoice-title">
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <div className="mb-4">
                                                    <img src="/favicon.ico" alt="" height="24" /><span className="logo-txt">LCA Details</span>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <div className="mb-4">
                                                    <h4 className="float-end font-size-16">
                                                        <a className="updateLCA btn btn-primary" href="#">
                                                            <i className="bx bx-edit align-middle"></i>
                                                        </a>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </CardHeader>

                                <CardBody>

                                    <Row>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        LCA Number
                                                    </h5>
                                                    <p>{caseDetail.lca_number ? caseDetail.lca_number : 'N/A'}</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        SOC Occupation Title
                                                    </h5>
                                                    <p>{caseDetail.soc_occupation_title ? caseDetail.soc_occupation_title : 'N/A'}</p>


                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        SOC Code
                                                    </h5>
                                                    <p>{caseDetail.soc_code ? caseDetail.soc_code : 'N/A'}</p>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">

                                                        Wage Level
                                                    </h5>
                                                    <p>{caseDetail.wage_level ? caseDetail.wage_level : 'N/A'}</p>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">

                                                        Prevailing Wage
                                                    </h5>
                                                    <p>{caseDetail.wages ? caseDetail.wages : 'N/A'}</p>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Wage Cycle
                                                    </h5>
                                                    <p>{caseDetail.wages_cycle ? caseDetail.wages_cycle : 'N/A'}</p>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        Job Title
                                                    </h5>
                                                    <p>{caseDetail.lca_job_title ? caseDetail.lca_job_title : 'N/A'}</p>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        From Date
                                                    </h5>
                                                    <p>{caseDetail.lca_from_date ? caseDetail.lca_from_date : ''}</p>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        To Date
                                                    </h5>
                                                    <p>{caseDetail.lca_to_date ? caseDetail.lca_to_date : ''}</p>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row >
                                    <Row>
                                        <Col sm="4">
                                            <div>
                                                <div>
                                                    <h5 className="font-size-15">
                                                        LCA File
                                                    </h5>
                                                    {caseDetail.lca_file_path && (
                                                        <p>
                                                            <i className="text-success bx bx-check-circle" style={{"font-size":"16px"}}></i><Link  style={{"font-size":"14px"}} target='_blank' to={caseDetail.lca_file_path ? caseDetail.lca_file_path : 'javascript:void()'}>LCA File</Link>
                                                        </p>
                                                    )}

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </CardBody >
                            </Card >
                        </Col >
                    </Row >


                </row >
            </div >
        </React.Fragment >
    );
}

export default Case;