import React from "react";
import { Link } from "react-router-dom"
import moment from "moment";


const Id = (cell) => {
  return cell.value ? cell.value : '';
};

const LinkCell = (cell) => {
  return (
    <Link className="text-dark fw-medium" to="#">{cell.value}</Link>
  );
};

const Date = (cell) => {
  return cell.value ? moment(cell.value).format("MMM DD, YYYY") : '';
};

const CaseTypeName = (cell) => {
  return cell.value;
};
const CommonCell = (cell) => {
  return cell.value ? cell.value : '';
};

const ViewdPdf = (cell) => {
  return (
    <>
      <div>
        {
          cell.row.original.paf_bundle_path && (
            <>
              <Link to={cell.row.original.paf_bundle_path}
                target="_blank" style={{ "text-decoration": "none" }} className=""
              >
                <i className="bx bx-file label-icon"></i> Pdf
              </Link>
            </>
          )
        }

      </div>
    </>
  );
};

export {
  CommonCell,
  Date,
  CaseTypeName,
  ViewdPdf
};