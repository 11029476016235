import {
  GET_CASEFORMATTRIBUTESETS,
  GET_CASEFORMATTRIBUTESETS_FAIL,
  GET_CASEFORMATTRIBUTESETS_SUCCESS,
  GET_CASEFORMATTRSETS_DETAIL,
  GET_CASEFORMATTRSETS_DETAIL_SUCCESS,
  GET_CASEFORMATTRSETS_DETAIL_FAIL,
  ADD_CASEFORMATTRSET,
  ADD_CASEFORMATTRSET_SUCCESS,
  ADD_CASEFORMATTRSET_FAIL,
  UPDATE_CASEFORM_ATTRIBUTESET_DETAIL_SUCCESS,
  UPDATE_CASEFORM_ATTRIBUTESET_DETAIL_FAIL,
  UPDATE_CASEFORM_ATTRIBUTESET_DETAIL,
  DELETE_CASEFORM_ATTRIBUTESET_DETAIL,
  DELETE_CASEFORM_ATTRIBUTESET_DETAIL_SUCCESS,
  DELETE_CASEFORM_ATTRIBUTESET_DETAIL_FAIL
} from "./actionTypes"
export const getcaseformAttributesets = (data) => ({
  type: GET_CASEFORMATTRIBUTESETS,
  form_id:data?.form_id || ''
})

export const getcaseformAttributesetsSuccess = CaseFormAttributesets => ({
  type: GET_CASEFORMATTRIBUTESETS_SUCCESS,
  payload: CaseFormAttributesets,
})

export const getcaseformAttributesetsFail = error => ({
  type: GET_CASEFORMATTRIBUTESETS_FAIL,
  payload: error,
})

export const addcaseformAttributesets = (data) => ({
  type: ADD_CASEFORMATTRSET,
  payload: data
})

export const addcaseformAttributesetsSuccess = data => ({
  type: ADD_CASEFORMATTRSET_SUCCESS,
  payload: data,
})

export const addcaseformAttributesetsFail = error => ({
  type: ADD_CASEFORMATTRSET_FAIL,
  payload: error,
})


export const updateCaseformAttributesets = (data) => ({
  type: UPDATE_CASEFORM_ATTRIBUTESET_DETAIL,
  payload: data
})

export const updateCaseformAttributesetsSuccess = data => ({
  type: UPDATE_CASEFORM_ATTRIBUTESET_DETAIL_SUCCESS,
  payload: data,
})

export const updateCaseformAttributesetsFail = error => ({
  type: UPDATE_CASEFORM_ATTRIBUTESET_DETAIL_FAIL,
  payload: error,
})

export const getCaseFormAttributeSetDetail = Caseformid => ({
  type: GET_CASEFORMATTRSETS_DETAIL,
  Caseformid,
})

export const getCaseFormAttributeSetDetailSuccess = Caseformattributesets => ({
  type: GET_CASEFORMATTRSETS_DETAIL_SUCCESS,
  payload: Caseformattributesets,
})

export const getCaseFormAttributeSetDetailFail = error => ({
  type: GET_CASEFORMATTRSETS_DETAIL_FAIL,
  payload: error,
})

export const caseFormAttributeSetDelete = (data) => ({
  type: DELETE_CASEFORM_ATTRIBUTESET_DETAIL,
  payload: data
})

export const caseFormAttributeSetDeleteSuccess = (data) => ({
  type: DELETE_CASEFORM_ATTRIBUTESET_DETAIL_SUCCESS,
  payload: data
})

export const caseFormAttributeSetDeleteFail = (error) => ({
  type: DELETE_CASEFORM_ATTRIBUTESET_DETAIL_FAIL,
  payload: error
})
