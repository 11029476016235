import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatatableContainer from "../../../../components/Common/DatatableContainer";
import { changePreloader, addEmployerEmployeeDocument, assignDocToCase, getDcoumentsByCase, getEmployerCases, getEmployerContactDetail, shareEmployeeEmployerDoc } from "../../../../store/actions";
//redux
import { useFormik } from "formik";
import * as Yup from 'yup';

import {
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown
} from "reactstrap";
import {
  CommonCell, Date, LinkCases
} from "./listCol";


export const Documents = () => {
  document.title = "Documents | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openTab, setopenTab] = useState("Open");
  const [showAddDocModel, setShowAddDocModel] = useState(false);
  const [moreActions, setMoreActions] = useState('');

  const queryparams = new URLSearchParams(location.search);
  const employeeID = queryparams.get('id')

  const { employercontactDetail } = useSelector((state) => ({
    employercontactDetail: state.EmployerContacts.employercontactDetail.personDocuments || [],
  }));

  const { employercases } = useSelector((state) => ({
    employercases: state.EmployerCases.employercase || [],
  }));

  const { documentsByCase } = useSelector((state) => ({
    documentsByCase: state.EmployerContacts.documentsByCase || [],
  }));

  useEffect(() => {
    dispatch(getEmployerCases({ "status": "All", "employee_id": employeeID }));
  }, []);

  const [showReviewModel, setReviewShowModel] = useState(false);
  const [docId, setdocId] = useState();
  const shareFormforReview = (data) => {
    console.log(data, '---share data on link---------')
    setReviewShowModel(!showReviewModel);
    setdocId(data.id);
    //setReviewShowModelData(data);
  }

  const [assignDocShowModel, setAssignDocShowModel] = useState(false);
  //const [docId, setdocId] = useState();
  const assignDocumentforReview = (data) => {
    setAssignDocShowModel(!showReviewModel);
    setdocId(data.id);
    //setReviewShowModelData(data);
  }



  const [shareUserError, setShareUserError] = useState('');
  const handleSubmitReview = (event) => {
    event.preventDefault();

    // let {case_form_id,case_id} = reviewModelData;
    let { share_user } = formData;
    if (share_user === '') {
      setShareUserError("Please select user type")
    }
    else {
      let postBody = {
        employee_id: employeeID, share_user, document_id: docId,
      };

      dispatch(shareEmployeeEmployerDoc(postBody));
      setReviewShowModel(false);
    }
  }

  //   const handleSubmitAssignDoc = (event) => {
  //     event.preventDefault();

  //     // let {case_form_id,case_id} = reviewModelData;
  //     let {case_id, case_document_id} = assDocFormData;
  //     if(case_id === '')
  //     {
  //       setShareUserError("Please select Case")
  //     }
  //     else if(case_document_id === '') {
  //       setShareUserError("Please select Case Document")
  //     } else
  //     {
  //       let postBody =  {
  //         employee_id: employeeID,case_id,document_id : docId, case_document_id,
  //       };
  // console.log(postBody,'--- check post data-------------');
  //       dispatch(assignDocToCase(postBody));
  //       setAssignDocShowModel(false);
  //       handleAlertOpen("Document assigned to Case successfully!",'success');
  //       setAssDocFormData({"case_id": "", "case_document_id": ""})
  //     }   
  //   }

  //   const [formData, setFormData] = useState({
  //     "share_user": "",
  //   });

  //   const handleChange = (e) => {

  //       setFormData({ ...formData, [e.target.name]: e.target.value });
  //   }

  //   const [assDocFormData, setAssDocFormData] = useState({
  //     "case_id": "",
  //     "case_document_id": ""
  //   });

  //   const handleAssgnDocChange = (e) => { alert(e.target.name+'----'+ e.target.value)
  //     setAssDocFormData({ ...assDocFormData, [e.target.name]: e.target.value });
  //   }

  const onReloadData = (event) => {
    var tabInfo = event.target.value;
    //dispatch(getEmployerTimesheets({ status: tabInfo }));
  }
  const MAX_FILE_SIZE = 2097152;
  const MAX_ALLOWED_MESSAGE = "Max allowed size is 2MB";
  // Form validation 
  const addDocvalidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      employee_id: employeeID,
      document: '',
      share_user: '',
      document_name: '',
      document_category: '',
    },
    validationSchema: Yup.object({
      document: Yup.mixed().required('Please select document')
        .test("is-valid-size", MAX_ALLOWED_MESSAGE,
          value => value && value.size <= MAX_FILE_SIZE),
      // share_user: Yup.string().required("Please select contact"),
      document_name: Yup.string().required("Please Enter document description"),
      document_category: Yup.string().required("Please select document category"),
    }),
    onSubmit: (values) => {
      dispatch(addEmployerEmployeeDocument(values));
      setMoreActions('');
      setTimeout(() => {
        addDocvalidation.resetForm();
        setShowAddDocModel(false);
        dispatch(getEmployerContactDetail({ employee_id: employeeID }))
      }, 2000);
    }
  });

  const moreactions = [
    { label: "Add Document", action: "adddocument" }
  ];

  const handleActionSubmit = (e) => {
    // validation.handleSubmit();
    e.preventDefault();
    setMoreActions(e.target.value);
    if (e.target.value === 'adddocument') {
      setShowAddDocModel(!showAddDocModel);
    }
    if (e.target.value === 'requestdocument') {
      setShowRequestDocModel(!showRequestDocModel)
    }
    //console.log(e.target)
  };

  const viewDocument = (docLink) => {
    dispatch(changePreloader(true));
    window.open(docLink, '_blank', 'noreferrer');
    setTimeout(() => {
      dispatch(changePreloader(false));
    }, 1000);
  }
  const pdfRef = useRef(null);
  const printDocument = (docData, print) => {
    dispatch(changePreloader(true));
    //console.log(pdfRef, "pdfRef")
    //if (pdfRef) {
    //if (Object.keys(invoiceDetail).length > 0) {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    //const content = pdfRef.current;
    const content = docData.attachment;
    const doc = new jsPDF(orientation, unit, size);
    doc.margin = { horiz: 20, vert: 10 };
    doc.setFontSize(1);
    doc.setPage(1);
    doc.html(content, {
      callback: function (doc) {
        // if (print == "saveandsend") {
        //     if (invoiceDetail.invoice_pdf_file == null || invoiceDetail.invoice_pdf_file.length === 0) {
        //         var binaryStr = doc.output('blob');
        //         dispatch(updateInvoicePdf({
        //             invoice_id: invoiceId,
        //             invoice_pdf_file: binaryStr
        //         }));
        //     }
        //     setSendInvoice(true);
        // }
        if (print == "pdf") {
          doc.save(docData.file_name);
        }
        if (print == "printpdf") {
          window.open(doc.output('bloburl'));
        }
      },
      html2canvas: { scale: 0.5 }
    });
    //}
    //}
    setTimeout(() => {
      dispatch(changePreloader(false));
    }, 1000);
  };

  const downloadPDF = (docData) => {
    fetch(docData.attachment).then((response) => {
      response.blob().then((blob) => {

        // Creating new object of PDF file
        const fileURL =
          window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = docData.file_name;
        alink.click();
      });

    });
  }

  const handleDownload = (docData) => {
    const link = document.createElement('Link');
    link.download = docData.file_name;
    link.target = "_blank";
    link.rel = "noreferrer";
    // 👇️ set to relative path
    link.href = docData.attachment;

    link.click();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Attachment",
        accessor: "file_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "Category",
        accessor: "category_type",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Uploaded",
        accessor: "created_at",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      // {
      //   Header: "Shared To",
      //   accessor: "is_doc_shared",
      //   concatVal: "Contact(s)",
      //   filterable: true,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     return <CommonCell {...cellProps} />;
      //   },
      // },
      {
        Header: "Assigned Cases",
        accessor: "doc_assigned_cases",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <LinkCases {...cellProps} />;
        },
      },
      {
        Header: "Uploaded By",
        accessor: "uploaded_by",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => viewDocument(cellProps.row.original.attachment)}>View</DropdownItem>
                {/* <DropdownItem onClick={() => onEditData(cellProps.row.original)}>Print</DropdownItem> */}
                {/* <DropdownItem onClick={() => printDocument(cellProps.row.original, 'printpdf')}>Print</DropdownItem> */}
                {/* <DropdownItem onClick={() => downloadPDF(cellProps.row.original)}>Download</DropdownItem> */}
                {/* <DropdownItem onClick={() => printDocument(cellProps.row.original, 'pdf')}>Download file</DropdownItem> */}
                {/* <DropdownItem onClick={() => shareFormforReview(cellProps.row.original)}>Share Document</DropdownItem> */}
                <DropdownItem onClick={() => assignDocumentforReview(cellProps.row.original)}>Assign Document to Case</DropdownItem>
                {/* <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },

    ],
    []
  );

  // const modalPopupColumns = useMemo(
  //   () => [
  //     {
  //       Header: "#",
  //       Cell: () => {
  //         return <input type="checkbox" />;
  //       },
  //     },
  //     {
  //       Header: "Case",
  //       accessor: "case_number",
  //       filterable: true,
  //       disableFilters: true,
  //       Cell: (cellProps) => {
  //         return <CaseTypeName {...cellProps} />;
  //       },
  //     },
  //     {
  //       Header: "Case Type",
  //       accessor: "qnnr_type",
  //       filterable: true,
  //       disableFilters: true,
  //       Cell: (cellProps) => {
  //         return <CommonCell {...cellProps} />;
  //       },
  //     },
  //     {
  //       Header: "Created",
  //       accessor: "created_at",
  //       filterable: true,
  //       disableFilters: true,
  //       Cell: (cellProps) => {
  //         return <Date {...cellProps} />;
  //       },
  //     },

  //   ],
  //   []
  // );

  // Form validation 
  const addAssignDocToCasevalidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      employee_id: employeeID,
      case_id: '',
      document_id: docId,
      case_document_id: '',
    },
    validationSchema: Yup.object({
      case_id: Yup.number().required('Please select Case'),
      case_document_id: Yup.string().required("Please select Case Document"),
    }),
    onSubmit: (values) => {
      dispatch(assignDocToCase(values));
      setTimeout(() => {
        addAssignDocToCasevalidation.resetForm();
        setAssignDocShowModel(false);
        dispatch(getEmployerContactDetail({ employee_id: employeeID }))
      }, 2000);
    }
  });

  const shareDocvalidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      employee_id: employeeID,
      share_user: '',
      document_id: docId
    },
    validationSchema: Yup.object({
      //share_user:Yup.number().required('Please select Case'),
      share_user: Yup.string().required("Please select User"),
    }),
    onSubmit: (values) => {
      dispatch(shareEmployeeEmployerDoc(values));
      shareDocvalidation.resetForm();
      setTimeout(() => {
        setReviewShowModel(false);
      }, 2000)

    }
  });

  useEffect(() => {
    if (addAssignDocToCasevalidation.values.case_id !== '') {
      dispatch(getDcoumentsByCase({ "case_id": addAssignDocToCasevalidation.values.case_id }))
    }
  }, [addAssignDocToCasevalidation.values.case_id])

  var userType = window.localStorage.getItem("user_type")

  return (
    <React.Fragment>
      <div>
        {/* Render Breadcrumbs */}
        <Modal isOpen={assignDocShowModel}>
          <ModalHeader toggle={() => { addAssignDocToCasevalidation.resetForm(); setAssignDocShowModel(false) }} tag="h4">
            Assign Document to Case
          </ModalHeader>
          <ModalBody>
            <p className="text-muted font-size-16">You can assign document to below cases. Select one for more cases from below and click on submit.</p>

            <Form
              //onSubmit={handleSubmitAssignDoc}
              onSubmit={(e) => {
                e.preventDefault();
                addAssignDocToCasevalidation.handleSubmit();
                return false;
              }}
            >
              <Row form>
                {/* <DatatableContainer
                    columns={modalPopupColumns}
                    data={employercases}
                    
                  />     */}
                <Col className="col-12 mb-3">
                  <Label className="form-label">
                    Case
                  </Label>
                  <Input name="case_id" type="select"
                    onChange={addAssignDocToCasevalidation.handleChange}
                    onBlur={addAssignDocToCasevalidation.handleBlur}
                    value={addAssignDocToCasevalidation.values.case_id || ""}
                    invalid={
                      addAssignDocToCasevalidation.touched.case_id &&
                        addAssignDocToCasevalidation.errors.case_id
                        ? true
                        : false
                    }>
                    <option value="">Select Case and Type</option>
                    {employercases.map((cases, index) => (
                      <option key={cases.case_id} value={cases.case_id}>
                        {cases.case_number} - {cases.case_type}
                      </option>
                    ))}

                  </Input>
                  {addAssignDocToCasevalidation.touched.case_id &&
                    addAssignDocToCasevalidation.errors.case_id ? (
                    <FormFeedback type="invalid">
                      {addAssignDocToCasevalidation.errors.case_id}
                    </FormFeedback>
                  ) : null}
                </Col>

                <Col className="col-12 mb-3">
                  <Label className="form-label">
                    Select Case Document
                  </Label>
                  <Input name="case_document_id" type="select"
                    onChange={addAssignDocToCasevalidation.handleChange}
                    onBlur={addAssignDocToCasevalidation.handleBlur}
                    value={addAssignDocToCasevalidation.values.case_document_id || ""}
                    invalid={
                      addAssignDocToCasevalidation.touched.case_document_id &&
                        addAssignDocToCasevalidation.errors.case_document_id
                        ? true
                        : false
                    }>
                    <option value="">Select Case Document</option>
                    {documentsByCase && documentsByCase.map((document, index) => (
                      <option key={index} value={document.case_docs_id}>{document.doc_name}</option>
                    ))}

                  </Input>
                  {addAssignDocToCasevalidation.touched.case_document_id &&
                    addAssignDocToCasevalidation.errors.case_document_id ? (
                    <FormFeedback type="invalid">
                      {addAssignDocToCasevalidation.errors.case_document_id}
                    </FormFeedback>
                  ) : null}
                </Col>

              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="ms-1 btn btn-primary save-event"
                    >
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <Modal isOpen={showReviewModel}>
          <ModalHeader toggle={() => { shareDocvalidation.resetForm(); setReviewShowModel(false) }} tag="h4">
            Share the Document
          </ModalHeader>
          <ModalBody>
            <p className="text-muted font-size-16">Share the document with Benificiery</p>

            <Form
              //onSubmit={handleSubmitReview}
              onSubmit={(e) => {
                e.preventDefault();
                shareDocvalidation.handleSubmit();
                return false;
              }}
            >
              <Row form>
                <Col className="col-12 mb-3">
                  <Label className="form-label">
                    Choose User Type
                  </Label>
                  <Input name="share_user" type="select"
                    onChange={shareDocvalidation.handleChange}
                    onBlur={shareDocvalidation.handleBlur}
                    value={shareDocvalidation.values.share_user || ""}
                    invalid={
                      shareDocvalidation.touched.share_user &&
                        shareDocvalidation.errors.share_user
                        ? true
                        : false
                    }>
                    <option value="">Select user</option>
                    <option value="Employer">Employer</option>
                    <option value="Employee">Beneficiary</option>
                    <option value="Both">Both</option>
                  </Input>
                  {shareDocvalidation.touched.share_user &&
                    shareDocvalidation.errors.share_user ? (
                    <FormFeedback type="invalid">
                      {shareDocvalidation.errors.share_user}
                    </FormFeedback>
                  ) : null}
                </Col>

              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="ms-1 btn btn-primary save-event"
                    >
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <Modal isOpen={showAddDocModel}>
          {/* <ModalHeader toggle={() => {addDocvalidation.resetForm();setShowAddDocModel(false)}} tag="h4">
              Add Document
            </ModalHeader> */}
          <h4 style={{ padding: "15px 0 0 15px" }}>Add Document
            <span className="remove-item" onClick={() => { addDocvalidation.resetForm(); setShowAddDocModel(false); setMoreActions('') }}>
              <span className="col-sm-1"><i className="bx bx-x bx-sm"></i></span>
            </span>
          </h4>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                addDocvalidation.handleSubmit();
                return false;
              }}
            >
              <Row form>
                <Col className="col-12 mb-3">    
                <Label className="form-label">Upload Document *</Label>
                  <div className="file-select">
                    <Input
                      type="file"
                      name="document"
                      id="chooseFile"
                      accept=".pdf"
                      onChange={(e) => addDocvalidation.setFieldValue('document', e.target.files[0])}
                      onBlur={addDocvalidation.handleBlur}
                      invalid={
                        addDocvalidation.touched.document &&
                          addDocvalidation.errors.document
                          ? true
                          : false}
                    />
                    {addDocvalidation.touched.document &&
                      addDocvalidation.errors.document ? (
                      <FormFeedback type="invalid">
                        {addDocvalidation.errors.document}
                      </FormFeedback>
                    ) : null}

                    <FormFeedback className="text-muted">
                      Note: {MAX_ALLOWED_MESSAGE}
                    </FormFeedback>
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <Label className="form-label">Document Description *</Label>
                  <Input
                    name="document_name"
                    type="text"
                    placeholder="Eg : Passport Copy"
                    onChange={addDocvalidation.handleChange}
                    onBlur={addDocvalidation.handleBlur}
                    value={addDocvalidation.values.document_name || ""}
                    invalid={
                      addDocvalidation.touched.document_name &&
                        addDocvalidation.errors.document_name
                        ? true
                        : false
                    }
                  />
                  {addDocvalidation.touched.document_name &&
                    addDocvalidation.errors.document_name ? (
                    <FormFeedback type="invalid">
                      {addDocvalidation.errors.document_name}
                    </FormFeedback>
                  ) : null}
                </Col>

                <Col className="col-12 mb-3">
                  <Label className="form-label">Category *</Label>
                  <Input
                    type="select"
                    name="document_category"
                    title="Select from list"
                    onChange={addDocvalidation.handleChange}
                    onBlur={addDocvalidation.handleBlur}
                    value={addDocvalidation.values.document_category || ""}
                    invalid={
                      addDocvalidation.touched.document_category &&
                        addDocvalidation.errors.document_category
                        ? true
                        : false
                    }
                  >
                    <option value="">Select category</option>
                    <option value="Immigration">Immigration</option>
                    <option value="Job">Job</option>
                    <option value="Education">Education</option>
                    <option value="Contracts">Contracts</option>
                    <option value="Dependents">Dependents</option>
                    <option value="Other">Other</option>
                  </Input>
                  {addDocvalidation.touched.document_category &&
                    addDocvalidation.errors.document_category ? (
                    <FormFeedback type="invalid">
                      {addDocvalidation.errors.document_category}
                    </FormFeedback>
                  ) : null}
                </Col>
                {/* <Col className="col-12 mb-3">
                  <Label className="form-label">
                    Select the contact to share file
                  </Label>
                  <Input name="share_user" type="select"
                    onChange={addDocvalidation.handleChange}
                    onBlur={addDocvalidation.handleBlur}
                    value={addDocvalidation.values.share_user || ""}
                    invalid={
                      addDocvalidation.touched.share_user &&
                        addDocvalidation.errors.share_user
                        ? true
                        : false}>
                    <option value="">Select user</option>
                    {userType === "employer" && (
                      <option value="Attorney">Attorney</option>
                    )}
                    {userType === "attorney" && (
                      <option value="Employer">Employer</option>
                    )}
                    <option value="Employee">Beneficiary</option>
                    <option value="Both">Both</option>
                  </Input>
                  {addDocvalidation.touched.share_user &&
                    addDocvalidation.errors.share_user ? (
                    <FormFeedback type="invalid">
                      {addDocvalidation.errors.share_user}
                    </FormFeedback>
                  ) : null}
                </Col> */}
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="ms-1 btn btn-primary save-event"
                    >
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <Row>

          <Col lg="12">

            <Card>
              <CardBody>
                {/* <div className="col-sm-6" style={{width:"18%",position:"relative",float:"right", marginBottom:"15px"}}>
                  <Input
                    id="timesheetViewPageAction"
                    type="select"
                    //onChange={(e) => onTimesheeViewActionChange(e)}
                    name="timesheetViewPageAction"
                  >
                    <option value="">More Actions</option>
                    <option value="Print">Add Document</option>
                  </Input>
                </div> */}
                <div className="row" >
                  <Col md="9"></Col>
                  <Col md="3">
                    <FormGroup className="mb-3" >
                      <Input
                        name="document_action"
                        placeholder="More Actions"
                        type="select"
                        className="form-control"
                        id="document_action"
                        value={moreActions || ''}
                        onChange={(e) => {
                          handleActionSubmit(e);
                        }}
                      >
                        <option value="">More Actions</option>
                        {moreactions.map((moreaction, index) => (
                          <option
                            key={moreaction.action + index}
                            value={moreaction.action}
                          >
                            {moreaction.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </div>
                <DatatableContainer
                  columns={columns}
                  data={employercontactDetail}
                  isGlobalFilter={true}
                  isEmployerEmployeeDocumentsFilter={true}
                  isEmployerEmployeeDocumentsFilterChange={onReloadData}
                  customPageSize={10}
                />

              </CardBody>

            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
