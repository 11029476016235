/* QNNRTYPES */
export const GET_CASEFORMATTRIBUTETEMPLATES = "GET_CASEFORMATTRIBUTETEMPLATES"
export const GET_CASEFORMATTRIBUTETEMPLATES_SUCCESS = "GET_CASEFORMATTRIBUTETEMPLATES_SUCCESS"
export const GET_CASEFORMATTRIBUTETEMPLATES_FAIL = "GET_CASEFORMATTRIBUTETEMPLATES_FAIL"
export const ADD_CASEFORMATTRIBUTETEMPLATE = "ADD_CASEFORMATTRIBUTETEMPLATE"
export const ADD_CASEFORMATTRIBUTETEMPLATE_SUCCESS = "ADD_CASEFORMATTRIBUTETEMPLATE_SUCCESS"
export const ADD_CASEFORMATTRIBUTETEMPLATE_FAIL = "ADD_CASEFORMATTRIBUTETEMPLATE_FAIL"
export const GET_CASEFORMATTR_TEMPLATE_DETAIL = "GET_CASEFORMATTR_TEMPLATE_DETAIL"
export const GET_CASEFORMATTR_TEMPLATE_DETAIL_SUCCESS = "GET_CASEFORMATTR_TEMPLATE_DETAIL_SUCCESS"
export const GET_CASEFORMATTR_TEMPLATE_DETAIL_FAIL = "GET_CASEFORMATTR_TEMPLATE_DETAIL_FAIL"


export const UPDATE_CASEFORMATTRIBUTE_TEMPLATES = "UPDATE_CASEFORMATTRIBUTE_TEMPLATES"
export const UPDATE_CASEFORMATTRIBUTE_TEMPLATES_SUCCESS = "UPDATE_CASEFORMATTRIBUTE_TEMPLATES_SUCCESS"
export const UPDATE_CASEFORMATTRIBUTE_TEMPLATES_FAIL = "UPDATE_CASEFORMATTRIBUTE_TEMPLATES_FAIL"

export const DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL = "DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL"
export const DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL_SUCCESS = "DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL_SUCCESS"
export const DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL_FAIL = "DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL_FAIL"
