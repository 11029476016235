import {
  GET_COMMON_USERS_FAIL,
  GET_COMMON_USERS_SUCCESS,
  GET_COMMON_USER_DETAIL_SUCCESS,
  GET_COMMON_USER_DETAIL_FAIL,
  UPDATE_COMMON_USER_DETAIL_FAIL,
  UPDATE_COMMON_USER_DETAIL_SUCCESS,
  DELETE_COMMON_USER_DETAIL_FAIL,
  DELETE_COMMON_USER_DETAIL_SUCCESS,
  ADD_COMMON_USER_DETAIL_SUCCESS,
  ADD_COMMON_USER_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  userDetail: {},
  createUserDetail: {},
  error: {},
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_COMMON_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    case GET_COMMON_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMMON_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
      }

    case GET_COMMON_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_COMMON_USER_DETAIL_SUCCESS:
      return {
        ...state,
        createUserDetail: action.payload,
      }

    case ADD_COMMON_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_COMMON_USER_DETAIL_SUCCESS:
      return {
        ...state,
        users: state.Users.filter((item) => item.id !== action.payload),
      };

    case DELETE_COMMON_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_COMMON_USER_DETAIL_SUCCESS:
      return {
        ...state,
        users: state.Users.map((order) =>
          order.id.toString() === action.payload.id.toString()
            ? { order, ...action.payload }
            : order
        ),
      };

    case UPDATE_COMMON_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state
  }
}

export default Users
