import {
  GET_CASETYPES,
  GET_CASETYPES_FAIL,
  GET_CASETYPES_SUCCESS,
  GET_CASETYPE_DETAIL,
  GET_CASETYPE_DETAIL_FAIL,
  GET_CASETYPE_DETAIL_SUCCESS,
  UPDATE_CASETYPE_DETAIL_FAIL,
  UPDATE_CASETYPE_DETAIL_SUCCESS,
  UPDATE_CASETYPE_DETAIL,
  DELETE_CASETYPE_DETAIL_FAIL,
  DELETE_CASETYPE_DETAIL_SUCCESS,
  DELETE_CASETYPE_DETAIL,
  ADD_CASETYPE_DETAIL_SUCCESS,
  ADD_CASETYPE_DETAIL,
  ADD_CASETYPE_DETAIL_FAIL

} from "./actionTypes"

export const getCasetypes = () => ({
  type: GET_CASETYPES,
})

export const getCasetypesSuccess = Casetypes => ({
  type: GET_CASETYPES_SUCCESS,
  payload: Casetypes,
})

export const getCasetypesFail = error => ({
  type: GET_CASETYPES_FAIL,
  payload: error,
})

export const getCasetypeDetail = CasetypeId => ({
  type: GET_CASETYPE_DETAIL,
  CasetypeId,
})

export const getCasetypeDetailSuccess = Casetypes => ({
  type: GET_CASETYPE_DETAIL_SUCCESS,
  payload: Casetypes,
})

export const getCasetypeDetailFail = error => ({
  type: GET_CASETYPE_DETAIL_FAIL,
  payload: error,
})

export const  addCasetypeDetail = (data) => ({
  type: ADD_CASETYPE_DETAIL,
  payload: data
})

export const addCasetypedetailSuccess = (data) => ({
  type: ADD_CASETYPE_DETAIL_SUCCESS,
  payload: data
})

export const addCasetypedetailFail = (error) => ({
  type: ADD_CASETYPE_DETAIL_FAIL,
  payload: error
})

export const CasetypeDelete = (data) => ({
  type: DELETE_CASETYPE_DETAIL,
  payload: data
})

export const CasetypeDeleteSuccess = (data) => ({
  type: DELETE_CASETYPE_DETAIL_SUCCESS,
  payload: data
})

export const CasetypeDeleteFail = (error) => ({
  type: DELETE_CASETYPE_DETAIL_FAIL,
  payload: error
})

export const CasetypeUpdate = (data) => ({
  type: UPDATE_CASETYPE_DETAIL,
  payload: data
})

export const CasetypeUpdateSuccess = (data) => ({
  type: UPDATE_CASETYPE_DETAIL_SUCCESS,
  payload: data
})

export const CasetypeUpdateFail = (error) => ({
  type: UPDATE_CASETYPE_DETAIL_FAIL,
  payload: error
})
