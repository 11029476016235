import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { ADD_CASEFORMATTRIBUTETEMPLATE, DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL, GET_CASEFORMATTRIBUTETEMPLATES, GET_CASEFORMATTR_TEMPLATE_DETAIL, UPDATE_CASEFORMATTRIBUTE_TEMPLATES } from "./actionTypes";
import {
  addcaseformAttributetemplateFail,
  addcaseformAttributetemplateSuccess,
  caseFormAttributeTemplateDeleteFail,
  caseFormAttributeTemplateDeleteSuccess,
  getCaseFormAttributeGroupTemplateFail,
  getCaseFormAttributeGroupTemplateSuccess,
  getCaseFormAttributeTemplatesFail,
  getCaseFormAttributeTemplatesSuccess,
  updateCaseformAttributetemplatesFail,
  updateCaseformAttributetemplatesSuccess
} from "./actions";

//Include Both Helper File with needed methods
import { addCaseformattributetemplate, deleteCaseformAttributeTemplates, getCaseFormAttributeTemplateDetailInfo, getCaseformattributetemp, updateCaseformAttributeTemplates } from "../../../helpers/apibackend_helper";
import { setAlertMessage } from "../../common/actions";
import { changePreloader } from "../../../store/actions"

function* fetchCaseformattributetemp() {
  try {
    const response = yield call(getCaseformattributetemp)
    yield put(getCaseFormAttributeTemplatesSuccess(response.data))
  } catch (error) {
    yield put(getCaseFormAttributeTemplatesFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* addCaseformattributetemp({ payload: caseformattributetemplate }) {
  try {
    const response = yield call(addCaseformattributetemplate, caseformattributetemplate)
    yield put(addcaseformAttributetemplateSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));

  } catch (error) {
    yield put(addcaseformAttributetemplateFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchCaseFormAttributeTemplateDetail({ Caseformid }) {
  try {
    if (Caseformid) {
      const response = yield call(getCaseFormAttributeTemplateDetailInfo, Caseformid)
      yield put(getCaseFormAttributeGroupTemplateSuccess(response.data))
    }
    else {
      yield put(getCaseFormAttributeGroupTemplateSuccess({}))
    }

  } catch (error) {
    yield put(getCaseFormAttributeTemplatesFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateCaseFormAttributeTemplate({ payload: caseformattributetemplate }) {
  try {
    const response = yield call(updateCaseformAttributeTemplates, caseformattributetemplate)
    yield put(updateCaseformAttributetemplatesSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(updateCaseformAttributetemplatesFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}
function* onDeleteCaseFormAttributeTemplate({ payload: data }) {
  try {
    const response = yield call(deleteCaseformAttributeTemplates, data);
    yield put(caseFormAttributeTemplateDeleteSuccess(data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(caseFormAttributeTemplateDeleteFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* Caseformattributetemplatessaga() {
  yield takeEvery(GET_CASEFORMATTRIBUTETEMPLATES, fetchCaseformattributetemp)
  yield takeEvery(ADD_CASEFORMATTRIBUTETEMPLATE, addCaseformattributetemp)
  yield takeEvery(GET_CASEFORMATTR_TEMPLATE_DETAIL, fetchCaseFormAttributeTemplateDetail)
  yield takeEvery(DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL, onDeleteCaseFormAttributeTemplate)
  yield takeEvery(UPDATE_CASEFORMATTRIBUTE_TEMPLATES, onUpdateCaseFormAttributeTemplate)
}

export default Caseformattributetemplatessaga
