import {
  ADD_CUSTOMER_DETAIL,
  ADD_CUSTOMER_DETAIL_FAIL,
  ADD_CUSTOMER_DETAIL_SUCCESS,
  DELETE_CUSTOMER_DETAIL,
  DELETE_CUSTOMER_DETAIL_FAIL,
  DELETE_CUSTOMER_DETAIL_SUCCESS,
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_DETAIL,
  GET_CUSTOMER_DETAIL_FAIL,
  GET_CUSTOMER_DETAIL_SUCCESS,
  GET_CUSTOMER_SETTINGS,
  GET_CUSTOMER_SETTINGS_FAIL,
  GET_CUSTOMER_SETTINGS_SUCCESS,
  SEND_CUSTOMER,
  SEND_CUSTOMER_FAIL,
  SEND_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_DETAIL,
  UPDATE_CUSTOMER_DETAIL_FAIL,
  UPDATE_CUSTOMER_DETAIL_SUCCESS,
  UPDATE_CUSTOMER_PDF,
  UPDATE_CUSTOMER_PDF_FAIL,
  UPDATE_CUSTOMER_PDF_SUCCESS,
  UPLOAD_CUSTOMER_ATTACHMENT,
  UPLOAD_CUSTOMER_ATTACHMENT_FAIL,
  UPLOAD_CUSTOMER_ATTACHMENT_SUCCESS
} from "./actionTypes"

export const getCustomers = (data) => ({
  type: GET_CUSTOMERS,
  payload: data
})

export const getCustomersSuccess = customers => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: customers,
})

export const getCustomersFail = error => ({
  type: GET_CUSTOMERS_FAIL,
  payload: error,
})

export const getCustomerDetail = customerId => ({
  type: GET_CUSTOMER_DETAIL,
  customerId,
})

export const getCustomerDetailSuccess = customers => ({
  type: GET_CUSTOMER_DETAIL_SUCCESS,
  payload: customers,
})

export const getCustomerDetailFail = error => ({
  type: GET_CUSTOMER_DETAIL_FAIL,
  payload: error,
})

export const sendCustomer = (data) => ({
  type: SEND_CUSTOMER,
  payload: data
})

export const sendCustomerSuccess = (data) => ({
  type: SEND_CUSTOMER_SUCCESS,
  payload: data
})

export const sendCustomerFail = (error) => ({
  type: SEND_CUSTOMER_FAIL,
  payload: error
})

export const updateCustomerPdf = (data) => ({
  type: UPDATE_CUSTOMER_PDF,
  payload: data
})

export const updateCustomerPdfSuccess = (data) => ({
  type: UPDATE_CUSTOMER_PDF_SUCCESS,
  payload: data
})

export const updateCustomerPdfFail = (error) => ({
  type: UPDATE_CUSTOMER_PDF_FAIL,
  payload: error
})


export const addCustomerDetail = (data) => ({
  type: ADD_CUSTOMER_DETAIL,
  payload: data
})

export const addCustomerdetailSuccess = (data) => ({
  type: ADD_CUSTOMER_DETAIL_SUCCESS,
  payload: data
})

export const addCustomerdetailFail = (error) => ({
  type: ADD_CUSTOMER_DETAIL_FAIL,
  payload: error
})

// Get Customer settings for create customer

export const getCustomerSettings = (data) => ({
  type: GET_CUSTOMER_SETTINGS,
  payload: data
})

export const getCustomerSettingsSuccess = (data) => ({
  type: GET_CUSTOMER_SETTINGS_SUCCESS,
  payload: data
})

export const getCustomerSettingsFail = (error) => ({
  type: GET_CUSTOMER_SETTINGS_FAIL,
  payload: error
})

export const uploadCustomerAttachment = (data) => ({
  type: UPLOAD_CUSTOMER_ATTACHMENT,
  payload: data
})

export const uploadCustomerAttachmentSuccess = (data) => ({
  type: UPLOAD_CUSTOMER_ATTACHMENT_SUCCESS,
  payload: data
})

export const uploadCustomerAttachmentFail = (error) => ({
  type: UPLOAD_CUSTOMER_ATTACHMENT_FAIL,
  payload: error
})

export const customerDelete = (data) => ({
  type: DELETE_CUSTOMER_DETAIL,
  payload: data
})

export const customerDeleteSuccess = (data) => ({
  type: DELETE_CUSTOMER_DETAIL_SUCCESS,
  payload: data
})

export const customerDeleteFail = (error) => ({
  type: DELETE_CUSTOMER_DETAIL_FAIL,
  payload: error
})

export const customerUpdate = (data) => ({
  type: UPDATE_CUSTOMER_DETAIL,
  payload: data
})

export const customerUpdateSuccess = (data) => ({
  type: UPDATE_CUSTOMER_DETAIL_SUCCESS,
  payload: data
})

export const customerUpdateFail = (error) => ({
  type: UPDATE_CUSTOMER_DETAIL_FAIL,
  payload: error
})
