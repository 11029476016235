import {
  ADD_TEMPLATE_DETAIL,
  ADD_TEMPLATE_DETAIL_FAIL,
  ADD_TEMPLATE_DETAIL_SUCCESS,
  DELETE_TEMPLATE_DETAIL,
  DELETE_TEMPLATE_DETAIL_FAIL,
  DELETE_TEMPLATE_DETAIL_SUCCESS,
  GET_TEMPLATES,
  GET_TEMPLATES_FAIL,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATE_DETAIL,
  GET_TEMPLATE_DETAIL_FAIL,
  GET_TEMPLATE_DETAIL_SUCCESS,
  GET_TEMPLATE_SETTINGS,
  GET_TEMPLATE_SETTINGS_FAIL,
  GET_TEMPLATE_SETTINGS_SUCCESS,
  SEND_TEMPLATE,
  SEND_TEMPLATE_FAIL,
  SEND_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_DETAIL,
  UPDATE_TEMPLATE_DETAIL_FAIL,
  UPDATE_TEMPLATE_DETAIL_SUCCESS,
  UPDATE_TEMPLATE_PDF,
  UPDATE_TEMPLATE_PDF_FAIL,
  UPDATE_TEMPLATE_PDF_SUCCESS,
  UPLOAD_TEMPLATE_ATTACHMENT,
  UPLOAD_TEMPLATE_ATTACHMENT_FAIL,
  UPLOAD_TEMPLATE_ATTACHMENT_SUCCESS
} from "./actionTypes"

export const getTemplates = (data) => ({
  type: GET_TEMPLATES,
  payload: data
})

export const getTemplatesSuccess = templates => ({
  type: GET_TEMPLATES_SUCCESS,
  payload: templates,
})

export const getTemplatesFail = error => ({
  type: GET_TEMPLATES_FAIL,
  payload: error,
})

export const getTemplateDetail = templateId => ({
  type: GET_TEMPLATE_DETAIL,
  templateId,
})

export const getTemplateDetailSuccess = templates => ({
  type: GET_TEMPLATE_DETAIL_SUCCESS,
  payload: templates,
})

export const getTemplateDetailFail = error => ({
  type: GET_TEMPLATE_DETAIL_FAIL,
  payload: error,
})

export const sendTemplate = (data) => ({
  type: SEND_TEMPLATE,
  payload: data
})

export const sendTemplateSuccess = (data) => ({
  type: SEND_TEMPLATE_SUCCESS,
  payload: data
})

export const sendTemplateFail = (error) => ({
  type: SEND_TEMPLATE_FAIL,
  payload: error
})

export const updateTemplatePdf = (data) => ({
  type: UPDATE_TEMPLATE_PDF,
  payload: data
})

export const updateTemplatePdfSuccess = (data) => ({
  type: UPDATE_TEMPLATE_PDF_SUCCESS,
  payload: data
})

export const updateTemplatePdfFail = (error) => ({
  type: UPDATE_TEMPLATE_PDF_FAIL,
  payload: error
})


export const addTemplateDetail = (data) => ({
  type: ADD_TEMPLATE_DETAIL,
  payload: data
})

export const addTemplatedetailSuccess = (data) => ({
  type: ADD_TEMPLATE_DETAIL_SUCCESS,
  payload: data
})

export const addTemplatedetailFail = (error) => ({
  type: ADD_TEMPLATE_DETAIL_FAIL,
  payload: error
})

// Get Template settings for create template

export const getTemplateSettings = (data) => ({
  type: GET_TEMPLATE_SETTINGS,
  payload: data
})

export const getTemplateSettingsSuccess = (data) => ({
  type: GET_TEMPLATE_SETTINGS_SUCCESS,
  payload: data
})

export const getTemplateSettingsFail = (error) => ({
  type: GET_TEMPLATE_SETTINGS_FAIL,
  payload: error
})

export const uploadTemplateAttachment = (data) => ({
  type: UPLOAD_TEMPLATE_ATTACHMENT,
  payload: data
})

export const uploadTemplateAttachmentSuccess = (data) => ({
  type: UPLOAD_TEMPLATE_ATTACHMENT_SUCCESS,
  payload: data
})

export const uploadTemplateAttachmentFail = (error) => ({
  type: UPLOAD_TEMPLATE_ATTACHMENT_FAIL,
  payload: error
})

export const templateDelete = (data) => ({
  type: DELETE_TEMPLATE_DETAIL,
  payload: data
})

export const templateDeleteSuccess = (data) => ({
  type: DELETE_TEMPLATE_DETAIL_SUCCESS,
  payload: data
})

export const templateDeleteFail = (error) => ({
  type: DELETE_TEMPLATE_DETAIL_FAIL,
  payload: error
})

export const templateUpdate = (data) => ({
  type: UPDATE_TEMPLATE_DETAIL,
  payload: data
})

export const templateUpdateSuccess = (data) => ({
  type: UPDATE_TEMPLATE_DETAIL_SUCCESS,
  payload: data
})

export const templateUpdateFail = (error) => ({
  type: UPDATE_TEMPLATE_DETAIL_FAIL,
  payload: error
})
