import React, { useEffect, useMemo, useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown
} from "reactstrap";
import SortDatatableContainer from "../../../../components/Common/SortDatatableContainer";

import { bundleCaseforms, changePreloader, deleteCaseForm, getAttorneyCaseDetail, getCaseForms, revokeAttorneyFormReview, updateAttorneyFormReview } from "../../../../store/actions";

import {
  Date,
  InvoiceId,
  PdfView,
  SharedInfo
} from "./formCol";

//redux
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import DeleteModal from "../../../Calendar/DeleteModal";

const Forms = () => {

  //meta title
  document.title = "Forms | Immidock ";

  const dispatch = useDispatch();

  const navigate = useNavigate();

  var userType = window.localStorage.getItem("user_type")

  const queryParams = new URLSearchParams(location.search);

  const caseId = queryParams.get('caseId'); // Read the 'id' query parameter from the URL

  const [apiSuccess, setApiSuccess] = useState(false);

  const [moreActions, setMoreActions] = useState('');

  const moreactions = [
    ... (userType === "attorney") ? [{ label: "Add Case Form", action: "addcaseform" }, { label: "Create Bundle", action: "createbundle" }] : [],
  ];


  const handleFormAction = (e) => {
    e.preventDefault();
    setMoreActions(e.target.value);
    if (e.target.value === 'addcaseform') {
      handleAddForm();
    } else if (e.target.value === 'createbundle') {
      if (caseFormIds.length > 0) {
        dispatch(bundleCaseforms({
          case_id: caseId,
          case_form_ids: caseFormIds.toString(),
        }));
        dispatch(changePreloader(true));
        setCheckall(false);
        setApiSuccess(true);
        setMoreActions("");
        setCaseFormIds([]);
      } else {
        alert('Please select generated forms');
      }
    }
  };

  useEffect(() => {
    if (caseId) {
      dispatch(getCaseForms({
        case_id: caseId
      }));
      dispatch(getAttorneyCaseDetail({
        case_id: caseId
      }));
    }
  }, [caseId]);

  const { caseForms } = useSelector((state) => ({
    caseForms: state.AttorneyCases.caseForms || [],
  }));

  const { caseDetail, bundlecaseforms } = useSelector((state) => ({
    caseDetail: state.AttorneyCases.caseDetail || [],
    bundlecaseforms: state.AttorneyCases.bundlecaseforms || "",
  }));

  useEffect(() => {
    if (bundlecaseforms.length > 0 && apiSuccess) {
      setMoreActions('');
      setApiSuccess(false);
      dispatch(changePreloader(false));
      window.open(bundlecaseforms, '_blank', 'noreferrer');
    }
  }, [bundlecaseforms]);

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [order, setOrder] = useState(null);

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);

  const { invoices } = useSelector((state) => ({
    invoices: state.Invoices.invoices,
  }));


  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };


  const handleAddForm = () => {
    navigate(`/${userType}/add-form?caseId=${caseId}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };

  const handleDeleteForm = () => {
    if (order?.case_id && order?.case_form_id) {
      dispatch(deleteCaseForm({
        case_id: order?.case_id,
        form_id: order?.case_form_id,
      }));
      setTimeout(function () {
        dispatch(getCaseForms({
          case_id: order?.case_id,
        }));
      }, 500)
      setDeleteModal(false);
    }
  };
  const viewPdf = (docLink) => {
    dispatch(changePreloader(true));
    window.open(docLink, '_blank', 'noreferrer');
    setTimeout(() => {
      dispatch(changePreloader(false));
    }, 1000);
  }

  const DownloadDocument = (doc) => {
    if (doc?.pdf_url) {
      dispatch(changePreloader(true));
      const link = document.createElement("a");
      link.href = doc?.pdf_url;
      link.download = doc?.form_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  // const DownloadDocument = (docData) => {
  //   if (doc?.pdf_url) {
  //     dispatch(changePreloader(true));

  //     fetch(docData.pdf_url).then((response) => {
  //       response.blob().then((blob) => {
  //         // Creating new object of PDF file
  //         const fileURL =
  //           window.URL.createObjectURL(blob);
  //         let alink = document.createElement("a");
  //         alink.href = fileURL;
  //         alink.download = docData.form_name;
  //         alink.click();
  //       });
  //     });
  //     setTimeout(() => {
  //       dispatch(changePreloader(false));
  //     }, 1000);
  //   }
  // }

  const viewForm = (data) => {
    if (caseDetail?.is_qnnr_completed != 1) {
      alert('Candidate has not completed the questionnaire. Any future changes to questionnaire will not reflect in the form.')
    }
    setTimeout(function () {
      navigate(`/${userType}/view-form?case_id=${data.case_id}&case_form_id=${data.case_form_id}&form_id=${data.form_id}`);
      // window.location.reload();
    }, 200)

  };
  const [showReviewModel, setReviewShowModel] = useState(false);
  const [reviewModelData, setReviewShowModelData] = useState('');
  const shareFormforReview = (data) => {
    setReviewShowModel(!showReviewModel);
    setReviewShowModelData(data);
  }
  const onUpdateData = (data) => {
    setOrder({
      id: data.id,
      Amount: data.Amount,
      color: data.color,
      date: moment(data.date).format("YYYY-MM-DD"),
      founder: data.founder,
      invoiceId: data.invoiceId,
      status: data.status,
    });
    setIsEdit(true);
    toggle();
  };
  const formstatus = ["Completed", "Benificiery In-review", "Petitioner In-review", "In-review With Both"];
  const [showSharedModel, setShowSharedModel] = useState(false);
  const [sharedContacts, setSharedContacts] = useState([]);
  const [docInfo, setDocumentInfo] = useState('');
  const [caseFormIds, setCaseFormIds] = useState([]);
  const [checkall, setCheckall] = useState(false);
  const handleCheckAll = () => {
    let case_form_ids = [];
    if (!checkall)
      caseForms.map((form, index) => {
        if (formstatus.includes(form.status))
          case_form_ids.push(form.case_form_id)
      })
    setCaseFormIds([...case_form_ids]);
  };
  const handleChecked = (e, index) => {
    let prev = caseFormIds;
    let itemIndex = prev.indexOf(index);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      prev.push(index);
    }
    setCaseFormIds([...prev]);
  };

  const toggleHandler = (item) => () => {
    setCaseFormIds((state) => ({
      ...state,
      item
    }));
  };
  const clickSharedInfo = (cellInfo, caseInfo) => {
    setShowSharedModel(!showSharedModel)
    let { share_to_benificiery, share_to_employer } = cellInfo.cell.row.original;
    let sharedDataShow = [];
    if (share_to_employer == 1) {
      sharedDataShow = [...sharedDataShow, { contact: caseDetail?.petitioner_company, type: 'Petitioner' }]
    }
    if (share_to_benificiery == 1) {
      sharedDataShow = [...sharedDataShow, { contact: caseDetail?.employee_name, type: 'Employee' }]
    }
    setSharedContacts(sharedDataShow);
    setDocumentInfo(cellInfo);
  };
  if (userType == 'employer') {
    var columns = useMemo(
      () => [
        {
          Header: "Name",
          accessor: "form_name",
          filterable: true,
          disableFilters: true,
          Cell: (cellProps) => {
            return <InvoiceId {...cellProps} />;
          },
        },
        {
          Header: "Group",
          accessor: "form_group",
          filterable: true,
          disableFilters: true,
          Cell: (cellProps) => {
            return <InvoiceId {...cellProps} />;
          },
        },
        {
          Header: "Edition",
          accessor: "edition_date",
          filterable: true,
          disableFilters: true,
          Cell: (cellProps) => {
            return <Date {...cellProps} />;
          },
        },
        {
          Header: "Status",
          accessor: "status",
          filterable: true,
          disableFilters: true,
          Cell: (invoice) => {
            return (
              invoice.row.original.status
            )
          },
        },
        {
          Header: "Created",
          accessor: "created_at",
          filterable: true,
          disableFilters: true,
          Cell: (cellProps) => {
            return <Date {...cellProps} />;
          },
        },
        {
          Header: "Action",
          Cell: (cellProps) => {
            return (
              <UncontrolledDropdown>
                <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                  <i className="bx bx-dots-horizontal-rounded"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  {cellProps.row.original.pdf_url && (
                    <>
                      <DropdownItem onClick={() => viewPdf(cellProps.row.original.pdf_url)}>View Pdf</DropdownItem>
                    </>
                  )}
                </DropdownMenu>
                {cellProps.row.original.pdf_url && (
                  <PdfView viewPdf={viewPdf} {...cellProps} />
                )}
              </UncontrolledDropdown>

            );
          },
        },
      ],
      []
    );
  } else {
    var columns = [
      {
        accessor: "id",
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        Header: () => {
          return <input
            checked={checkall}
            onChange={(e) => {
              setCheckall(!checkall)
              handleCheckAll()
            }}
            type="checkbox"
            name="case_form_ids_checkall"
            value={checkall}
          />;
        },
        Cell: (cell) => {
          return <input
            checked={caseFormIds.includes(cell.row.original.case_form_id)}
            onChange={(e) => handleChecked(e, cell.row.original.case_form_id)}
            //  onChange={toggleHandler(cell.row.original.case_form_id)} 
            type="checkbox"
            name="case_form_ids"
            value={cell.row.original.case_form_id}
            disabled={formstatus.includes(cell.row.original.status) ? false : true} />;
        },
      },
      {
        Header: "Name",
        accessor: "form_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <InvoiceId {...cellProps} />;
        },
      },
      {
        Header: "Group",
        accessor: "form_group",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <InvoiceId {...cellProps} />;
        },
      },
      {
        Header: "Edition",
        accessor: "edition_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },

      {
        Header: "Shared With",
        accessor: "shared_form_count",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <SharedInfo
            {...cellProps}
            clickSharedInfo={() => clickSharedInfo(cellProps, caseDetail)}
          />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (invoice) => {
          return (
            invoice.row.original.status
          )
        },
      },
      {
        Header: "Created",
        accessor: "created_at",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle style={{ "float": "left" }} className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {cellProps.row.original.form_id && (
                  <>
                    <DropdownItem onClick={() => viewForm(cellProps.row.original)}>View Form</DropdownItem>
                  </>
                )}

                {/* <DropdownItem onClick={() => onUpdateData(cellProps.row.original)}>Edit</DropdownItem> */}
                {cellProps.row.original.pdf_url && (
                  <>
                    <DropdownItem onClick={() => shareFormforReview(cellProps.row.original)}>Share the Form for Review</DropdownItem>
                    <DropdownItem onClick={() => viewPdf(cellProps.row.original.pdf_url)}>View Pdf</DropdownItem>
                    {/* <DropdownItem onClick={() => DownloadDocument(cellProps.row.original)}>Download</DropdownItem> */}
                  </>
                )}
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>


              </DropdownMenu>
              {cellProps.row.original.pdf_url && (
                <PdfView viewPdf={viewPdf} {...cellProps} />
              )}
            </UncontrolledDropdown>

          );
        },
      },
    ]
  }
  const [formData, setFormData] = useState({
    "share_user": "",
    "is_review_required": "",
  });

  const handleChange = (e) => {
    if (e.target.name === 'is_review_required') {
      setFormData({ ...formData, [e.target.name]: e.target.checked ? 1 : 0 });
    }
    else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  }
  const [shareUserError, setShareUserError] = useState('');
  const handleSubmitReview = (event) => {
    event.preventDefault();

    let { case_form_id, case_id } = reviewModelData;
    let { is_review_required, share_user } = formData;
    if (share_user === '') {
      setShareUserError("Please select user type")
    }
    else {
      let postBody = {
        case_form_id, case_id, share_user, is_review_required: is_review_required,
      };

      dispatch(updateAttorneyFormReview(postBody));
      setReviewShowModel(false);
    }
  }

  const handleRevokeButtonClicks = (type) => {
    let { case_form_id, case_id } = docInfo.cell.row.original;
    const reqBody = {
      case_form_id: case_form_id,
      case_id,
      user_type: type,
      formType: 'forms'
    }
    dispatch(revokeAttorneyFormReview(reqBody));
    setShowSharedModel(false)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteForm}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Row>
        <Col lg="12">
          <Modal isOpen={showReviewModel}>
            <ModalHeader toggle={() => setReviewShowModel(false)} tag="h4">
              Share the Form for Review
            </ModalHeader>
            <ModalBody>
              <p className="text-muted font-size-16">Share the form with Benificiery or Petitioner or Both for Review</p>

              <Form
                onSubmit={handleSubmitReview}
              >
                <Row form>
                  <Col className="col-12 mb-3">
                    <Label className="form-label">
                      Choose User Type
                    </Label>
                    <Input name="share_user" type="select" onChange={handleChange}>
                      <option value="">Select user</option>
                      <option value="Employer">Employer</option>
                      <option value="Employee">Beneficiary</option>
                      <option value="Both">Both</option>
                    </Input>
                    <label style={{ color: "red" }}>{shareUserError}</label>
                  </Col>
                  <Col className="col-12 mb-3">
                    <Input
                      type="checkbox"
                      name="is_review_required"
                      onChange={handleChange}
                      id="is_review_required"
                    />
                    <label className="form-check-label" htmlFor="is_review_required" > &nbsp; Is review required?</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary save-event"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
          <Modal isOpen={showSharedModel}>
            <ModalHeader toggle={() => setShowSharedModel(false)} tag="h4">
              Shared Information
            </ModalHeader>
            <ModalBody>
              <Table bordered hover>
                <thead className="table-light table-nowrap">
                  <tr role="row">
                    <th>Contact</th>
                    <th>User Type</th> <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {sharedContacts.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.contact}</td>
                        <td>{item?.type}</td>
                        <td>
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2 btn btn-danger "
                            onClick={() => handleRevokeButtonClicks(item?.type)}
                          >
                            Revoke
                          </Button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
          {moreactions && (
            <div className="row" >
              <Col md="9"></Col>
              <Col md="3">
                <FormGroup className="mb-3" >
                  <Input
                    name="form_action"
                    placeholder="More Actions"
                    type="select"
                    className="form-control"
                    id="form_action"
                    value={moreActions || ''}
                    onChange={(e) => {
                      handleFormAction(e);
                    }}
                  >
                    <option value="">More Actions</option>
                    {moreactions.map((moreaction, index) => (
                      <option
                        key={moreaction.action + index}
                        value={moreaction.action}
                      >
                        {moreaction.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </div>
          )}
          <SortDatatableContainer
            columns={columns}
            data={caseForms}
            isGlobalFilter={true}
            isAddInvoiceList={false}
            isFilterDisable={true}
            isAdd2ColumnSpace={true}
            customPageSize={10}
            handleInvoiceClick={handleAddForm}
            isAddCaseForm={false}
          />

        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Forms;
