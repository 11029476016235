import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row
} from "reactstrap";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";


// Form Editor
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getMessage, saveMessage } from "../../../store/actions";

//redux
import { useDispatch, useSelector } from "react-redux";


const Messages = () => {
  //meta title
  document.title = "Case Messages | Immidock ";

  const queryParams = new URLSearchParams(location.search);

  const caseId = queryParams.get('caseId'); // Read the 'id' query parameter from the URL

  var userType = window.localStorage.getItem("user_type")

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [editorMessage, seteditorMessage] = useState(EditorState.createEmpty());

  const onEditorMessageStateChange = (editorMessage) => {
    validation.values.message = draftToHtml(convertToRaw(editorMessage.getCurrentContent()));
    seteditorMessage(editorMessage);
  };
  const { casemessage, savemessage } = useSelector((state) => ({
    casemessage: state.AttorneyCases.casemessage || {},
    savemessage: state.AttorneyCases.savemessage || {},
  }));

  useEffect(() => {
    if (caseId) {
      dispatch(getMessage({
        case_id: caseId
      }));
    }
  }, [caseId, savemessage]);

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      case_id: caseId,
      message: "",
      is_sent_to_atnr: false,
      is_sent_to_ptnr: false,
      is_sent_to_benificary: false
    },
    validationSchema: Yup.object({
      is_sent_to_atnr: Yup.boolean(),
      is_sent_to_ptnr: Yup.boolean(),
      is_sent_to_benificary: Yup.boolean(),
    }),
    onSubmit: (values) => {
      values.message = draftToHtml(convertToRaw(editorMessage.getCurrentContent()));
      dispatch(saveMessage(values));
      seteditorMessage(EditorState.createEmpty())
      validation.resetForm();
      navigate(`/${userType}/messages?caseId=${caseId}`);
    }
  });
  var userType = window.localStorage.getItem("user_type")
  let title = 'Messages';
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/employee/dashboard' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Col>
                      <Row>
                        <Col lg="12">
                          <Editor
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            editorState={editorMessage}
                            onEditorStateChange={onEditorMessageStateChange}
                          />
                        </Col>
                      </Row>
                      <div className="mt-4">
                        <Row>
                          <Col lg="2">
                            <div className="form-group">
                              <div className="form-check">
                                <Input
                                  name="is_sent_to_atnr"
                                  type="checkbox"
                                  className="form-check-input"
                                  id="validationCustom01"
                                  onChange={(e) => (validation.handleChange(e))}
                                // checked={validation.values.is_sent_to_atnr}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="formrow-customCheck"
                                >
                                  Attroney
                                </Label>
                              </div>
                            </div>
                          </Col>
                          <Col lg="2">
                            <div className="form-group">
                              <div className="form-check">
                                <Input
                                  name="is_sent_to_ptnr"
                                  type="checkbox"
                                  className="form-check-input"
                                  id="validationCustom01"
                                  onChange={(e) => (validation.handleChange(e))}
                                // checked={validation.values.is_sent_to_ptnr}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="formrow-customCheck"
                                >
                                  Petitioner
                                </Label>
                              </div>
                            </div>
                          </Col>

                        </Row>
                      </div>

                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-2 col-form-label"
                        >
                        </Label>
                        <Col md={12}>
                          <div className="mt-3" style={{ float: "right" }}>
                            <Button type="reset" color="secondary" onClick={() => navigate(-1)}>
                              Cancel
                            </Button>
                            {" "}
                            <Button type="submit" color="primary" className="ms-1">
                              Save
                            </Button>
                          </div>

                        </Col>
                      </Row>

                    </Col>
                  </Form>
                  <Row>
                    <Col lg="12 mt-4">
                      {casemessage.length > 0 && casemessage.map((message) => (
                        <>
                          <div className="card">
                            <div className="card-body">
                              <p className="text-muted mb-2">{message?.sender_name}, {message?.created_at}</p>

                              <span className="margin-bottom-0" dangerouslySetInnerHTML={{ __html: message?.message }}></span>

                            </div>
                          </div>
                        </>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Messages;