import {
  GET_ATTORNEY_CONTACTS_FAIL,
  GET_ATTORNEY_CONTACTS_SUCCESS,
  GET_ATTORNEY_CONTACT_DETAIL_SUCCESS,
  GET_ATTORNEY_CONTACT_DETAIL_FAIL,
  UPDATE_ATTORNEY_CONTACT_DETAIL_FAIL,
  UPDATE_ATTORNEY_CONTACT_DETAIL_SUCCESS,
  DELETE_ATTORNEY_CONTACT_DETAIL_FAIL,
  DELETE_ATTORNEY_CONTACT_DETAIL_SUCCESS,
  ADD_ATTORNEY_CONTACT_DETAIL_SUCCESS,
  ADD_ATTORNEY_CONTACT_DETAIL_FAIL,
  VALIDATE_COMPANY_EMAILID_SUCCESS,
  VALIDATE_COMPANY_EMAILID_FAIL
} from "./actionTypes"

const INIT_STATE = {
  attorneycontacts: [],
  attorneycontactDetail: {},
  validateCompanyEmailID: {},
  error: {},
}

const AttorneyContacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ATTORNEY_CONTACTS_SUCCESS:
      return {
        ...state,
        attorneycontacts: action.payload,
      }

    case GET_ATTORNEY_CONTACTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ATTORNEY_CONTACT_DETAIL_SUCCESS:
      return {
        ...state,
        attorneycontactDetail: action.payload,
      }

    case GET_ATTORNEY_CONTACT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ATTORNEY_CONTACT_DETAIL_SUCCESS:
      return {
        ...state,
        attorneycontacts:  [...state.attorneycontacts, action.payload]
      }

    case ADD_ATTORNEY_CONTACT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ATTORNEY_CONTACT_DETAIL_SUCCESS:
      return {
        ...state,
        attorneycontacts: state.attorneycontacts.filter((item) => item.id !== action.payload),
      };

    case DELETE_ATTORNEY_CONTACT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_ATTORNEY_CONTACT_DETAIL_SUCCESS:
      return {
        ...state,
        // attorneycontacts: state.contacts.map((row) =>
        //   row.id.toString() === action.payload.id.toString()
        //     ? { row, ...action.payload }
        //     : row
        // ),
      };

    case UPDATE_ATTORNEY_CONTACT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case VALIDATE_COMPANY_EMAILID_SUCCESS:
      return {
        ...state,
        validateCompanyEmailID: action.payload,
      }

    case VALIDATE_COMPANY_EMAILID_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default AttorneyContacts
