/* QNNRTYPES */
export const GET_EMPLOYER_COMPANY = "GET_EMPLOYER_COMPANY"
export const GET_EMPLOYER_COMPANY_SUCCESS = "GET_EMPLOYER_COMPANY_SUCCESS"
export const GET_EMPLOYER_COMPANY_FAIL = "GET_EMPLOYER_COMPANY_FAIL"

export const GET_EMPLOYER_COMPANY_DETAIL = "GET_EMPLOYER_COMPANY_DETAIL"
export const GET_EMPLOYER_COMPANY_DETAIL_SUCCESS = "GET_EMPLOYER_COMPANY_DETAIL_SUCCESS"
export const GET_EMPLOYER_COMPANY_DETAIL_FAIL = "GET_EMPLOYER_COMPANY_DETAIL_FAIL"

export const ADD_EMPLOYER_COMPANY_DETAIL = "ADD_EMPLOYER_COMPANY_DETAIL"
export const ADD_EMPLOYER_COMPANY_DETAIL_SUCCESS = "ADD_EMPLOYER_COMPANY_DETAIL_SUCCESS"
export const ADD_EMPLOYER_COMPANY_DETAIL_FAIL = "ADD_EMPLOYER_COMPANY_DETAIL_FAIL"

export const DELETE_EMPLOYER_COMPANY_DETAIL = "DELETE_EMPLOYER_COMPANY_DETAIL"
export const DELETE_EMPLOYER_COMPANY_DETAIL_SUCCESS = "DELETE_EMPLOYER_COMPANY_DETAIL_SUCCESS"
export const DELETE_EMPLOYER_COMPANY_DETAIL_FAIL = "DELETE_EMPLOYER_COMPANY_DETAIL_FAIL"

export const UPDATE_EMPLOYER_COMPANY_DETAIL = "UPDATE_EMPLOYER_COMPANY_DETAIL"
export const UPDATE_EMPLOYER_COMPANY_DETAIL_SUCCESS = "UPDATE_EMPLOYER_COMPANY_DETAIL_SUCCESS"
export const UPDATE_EMPLOYER_COMPANY_DETAIL_FAIL = "UPDATE_EMPLOYER_COMPANY_DETAIL_FAIL"
