import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Button,
    Card, CardBody, CardHeader, Col, Container,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import classnames from "classnames";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
// Form Editor


import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import { changePreloader, connectQuickbooks, getInvoiceSettings, getQuickbookStatus, updateInvoiceSettings } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";

const InvoiceSettings = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [activeTab1, setactiveTab1] = useState("invoice-setting");
    const toggle1 = (tab) => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };

    const { updateinvoicesettings, invoicesettings, connectQuickbookURL, quickbookstatus } = useSelector((state) => ({
        connectQuickbookURL: state.Invoices.connectQuickbookURL,
        quickbookstatus: state.Invoices.quickbookstatus,
        invoicesettings: state.Invoices.invoicesettings || {},
        updateinvoicesettings: state.Invoices.updateinvoicesettings || {},
    }));


    useEffect(() => {
        dispatch(getQuickbookStatus());
        dispatch(getInvoiceSettings({}));
        dispatch(connectQuickbooks());
        // setInitialValues(invoicesettings?.invoice_settings)
    }, [dispatch]);

    useEffect(() => {

        if (_.isEmpty(invoicesettings))
            dispatch(changePreloader(true));
        else
            dispatch(changePreloader(false));

    }, [invoicesettings]);

    useEffect(() => {
        if (_.isEmpty(updateinvoicesettings))
            dispatch(changePreloader(true));
        else
            dispatch(changePreloader(false));

        if (Object.keys(updateinvoicesettings).length > 0) {
            dispatch(getInvoiceSettings({}));
            var userType = window.localStorage.getItem("user_type")
            navigate(`/${userType}/invoice-settings`, { replace: true });
        }
    }, [updateinvoicesettings]);

    // const customers = invoicesettings.customers;
    const iniValues = {
        service: (invoicesettings?.invoice_settings?.service),
        discount_percentage: (invoicesettings?.invoice_settings?.discount_percentage) !== 0 ? invoicesettings?.invoice_settings?.discount_percentage : 0,
        discount_amount: (invoicesettings?.invoice_settings?.discount_amount) !== 0 ? invoicesettings?.invoice_settings?.discount_amount : 0,
        is_company_logo_enable: (invoicesettings?.invoice_settings?.is_company_logo_enable) == 1 ? true : false,
        is_payment_terms_enable: (invoicesettings?.invoice_settings?.is_payment_terms_enable) == 1 ? true : false,
        is_payment_due_enable: (invoicesettings?.invoice_settings?.is_payment_due_enable) == 1 ? true : false,
        is_invoice_period_enable: (invoicesettings?.invoice_settings?.is_invoice_period_enable) == 1 ? true : false,
        is_candidate_name_enable: (invoicesettings?.invoice_settings?.is_candidate_name_enable) == 1 ? true : false,
        is_job_title_enable: (invoicesettings?.invoice_settings?.is_job_title_enable) == 1 ? true : false,
        is_service_enable: (invoicesettings?.invoice_settings?.is_service_enable) == 1 ? true : false,
        is_discount_percentage_enable: (invoicesettings?.invoice_settings?.is_discount_percentage_enable) == 1 ? true : false,
        is_discount_amount_enable: (invoicesettings?.invoice_settings?.is_discount_amount_enable) == 1 ? true : false,
        is_footer_enable: (invoicesettings?.invoice_settings?.is_footer_enable) == 1 ? true : false,
        // is_color_theme_enable: (invoicesettings?.invoice_settings?.is_color_theme_enable) == 1 ? true : false,
        footer_description: (invoicesettings?.invoice_settings?.footer_description),
        message: "<p></p>",
        from_email: (invoicesettings?.invoice_settings?.from_email),
        cc_email: (invoicesettings?.invoice_settings?.cc_email),
        // ...initialValues
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        // initialValues: iniValues,
        initialValues: ((Object.keys(invoicesettings).length > 0)) ? invoicesettings : iniValues,
        validationSchema: Yup.object({
            from_email: Yup.string().email().nullable(),
            cc_email: Yup.string().email().nullable(),
            is_company_logo_enable: Yup.boolean(),
            is_payment_terms_enable: Yup.boolean(),
            is_payment_due_enable: Yup.boolean(),
            is_invoice_period_enable: Yup.boolean(),
            is_candidate_name_enable: Yup.boolean(),
            is_job_title_enable: Yup.boolean(),
            is_service_enable: Yup.boolean(),
            is_discount_percentage_enable: Yup.boolean(),
            is_discount_amount_enable: Yup.boolean(),
            is_footer_enable: Yup.boolean(),
            is_color_theme_enable: Yup.boolean(),
            discount_percentage: Yup.number().when('is_discount_percentage_enable', {
                is: (val) => val === true, //&& val.includes("1"),
                then: Yup.number().min(0).default(1).positive("Must be a positive value").transform((value) => Number.isNaN(value) ? null : value).typeError('Percentage must be a number')
                    .required("Please Enter Discount percentage").nullable(),
                otherwise: () => Yup.number().default(0).nullable(),
            }),
            service: Yup.string().when('is_service_enable', {
                is: (val) => val === true, //&& val.includes("1"),
                then: Yup.string().required("Please Enter Service Name").nullable(),
                otherwise: () => Yup.string().nullable(),
            }),
            discount_amount: Yup.number().when('is_discount_amount_enable', {
                is: (val) => val === true, //&& val.includes("1"),
                then: Yup.number().min(0).default(1).positive("Must be a positive value").transform((value) => Number.isNaN(value) ? null : value).typeError('Amount must be a number')
                    .required("Please Enter Discount amount").nullable(),
                otherwise: () => Yup.number().default(0).nullable(),
            }),
        }),
        onSubmit: (values) => {
            dispatch(updateInvoiceSettings(values));
        }
    });


    let title = 'Invoice Settings';
    //meta title
    document.title = title + " | Immidock ";
    const BreadcrumbData = [
        { name: 'Dashboard', url: '/employer/dashboard' },
        { name: title, url: '#' }
    ]
    return (
        <React.Fragment>

            {Object.keys(invoicesettings).length > 0 && (
                <>

                    <div className="page-content">
                        <Container fluid>
                            {/* Render Breadcrumbs */}
                            <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                            <Col xs={12}>
                                <Card>

                                    <CardHeader>
                                        <h4 className="card-title">Invoice Settings</h4>
                                    </CardHeader>
                                    <Nav tabs className="nav-tabs-custom nav-justified">
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab1 === "invoice-setting",
                                                })}
                                                onClick={() => {
                                                    toggle1("invoice-setting");
                                                }}
                                            >
                                                General
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab1 === "integrations",
                                                })}
                                                onClick={() => {
                                                    toggle1("integrations");
                                                    dispatch(connectQuickbooks());
                                                }}
                                            >
                                                Integrations
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab1} className="p-3 text-muted">
                                        <TabPane tabId="invoice-setting">

                                            <CardBody className="p-4">
                                                <FormikProvider value={validation}>
                                                    <Form className="needs-validation"
                                                        onSubmit={(values) => {
                                                            values.preventDefault();
                                                            // console.log(validate(values));
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="example-date-input" className="form-Label">General</Label>
                                                                </div></Col>
                                                        </Row>

                                                        <div className="row">
                                                            <div className="col-lg-12">

                                                                <div className="col-lg-12">
                                                                    <Col md="6">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="from_email">From Email</Label>
                                                                            <Input
                                                                                name="from_email"
                                                                                placeholder="From Email"
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="from_email"
                                                                                onChange={validation.handleChange}
                                                                                onBlur={validation.handleBlur}
                                                                                value={validation.values.from_email || ""}
                                                                                invalid={
                                                                                    validation.touched.from_email && validation.errors.from_email ? true : false
                                                                                }
                                                                            />
                                                                            {validation.touched.from_email && validation.errors.from_email ? (
                                                                                <FormFeedback type="invalid">{validation.errors.from_email}</FormFeedback>
                                                                            ) : null}
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="cc_email">Cc Email</Label>
                                                                            <Input
                                                                                name="cc_email"
                                                                                placeholder="Cc Email"
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="cc_email"
                                                                                onChange={validation.handleChange}
                                                                                onBlur={validation.handleBlur}
                                                                                value={validation.values.cc_email || ""}
                                                                                invalid={
                                                                                    validation.touched.cc_email && validation.errors.cc_email ? true : false
                                                                                }
                                                                            />
                                                                            {validation.touched.cc_email && validation.errors.cc_email ? (
                                                                                <FormFeedback type="invalid">{validation.errors.cc_email}</FormFeedback>
                                                                            ) : null}
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="example-text-input" className="form-Label">Message</Label>

                                                                            <Input
                                                                                rows={5}
                                                                                type="textarea"
                                                                                id="message"
                                                                                name="message"
                                                                                value={validation.values.message || ""}
                                                                                onChange={(e) => {
                                                                                    validation.handleChange(e);
                                                                                }}
                                                                            ></Input>&nbsp;
                                                                        </div>
                                                                    </Col>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_company_logo_enable"
                                                                                onChange={(e) => {
                                                                                    validation.handleChange(e);
                                                                                }}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="is_company_logo_enable"
                                                                                checked={validation.values.is_company_logo_enable}
                                                                            />
                                                                            <span className="font-size-16">Display Company Logo</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_payment_terms_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation.values.is_payment_terms_enable}
                                                                            />
                                                                            <span className="font-size-16">Payment Terms</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_payment_due_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation.values.is_payment_due_enable}
                                                                            />
                                                                            <span className="font-size-16">Payment Due</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_invoice_period_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation.values.is_invoice_period_enable}
                                                                            />
                                                                            <span className="font-size-16">Invoice Period</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_candidate_name_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation.values.is_candidate_name_enable}
                                                                            />
                                                                            <span className="font-size-16">Candidate Name</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_job_title_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation.values.is_job_title_enable}
                                                                            />
                                                                            <span className="font-size-16">Job Title</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_service_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation.values.is_service_enable}
                                                                            />
                                                                            <span className="font-size-16">Service</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_discount_percentage_enable"
                                                                                onChange={(e) => {
                                                                                    if (!validation.values.is_discount_percentage_enable == true)
                                                                                        validation.values.is_discount_amount_enable = false;
                                                                                    validation.handleChange(e);
                                                                                }}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation.values.is_discount_percentage_enable}
                                                                            />
                                                                            <span className="font-size-16">Provide Discount %</span>
                                                                        </div>
                                                                    </div>
                                                                    {validation.values.is_discount_percentage_enable && (
                                                                        <Col md="6">
                                                                            <FormGroup className="mb-3">
                                                                                <Label htmlFor="validationCustom01">Discount Percentage</Label>
                                                                                <Input
                                                                                    name="discount_percentage"
                                                                                    placeholder="Discount Percentage"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="validationCustom01"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.discount_percentage || 0}
                                                                                    invalid={
                                                                                        validation.touched.discount_percentage && validation.errors.discount_percentage ? true : false
                                                                                    }
                                                                                />
                                                                                {validation.touched.discount_percentage && validation.errors.discount_percentage ? (
                                                                                    <FormFeedback type="invalid">{validation.errors.discount_percentage}</FormFeedback>
                                                                                ) : null}
                                                                            </FormGroup>
                                                                        </Col>

                                                                    )}
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_discount_amount_enable"
                                                                                onChange={(e) => {
                                                                                    if (!validation.values.is_discount_amount_enable == true)
                                                                                        validation.values.is_discount_percentage_enable = false;
                                                                                    validation.handleChange(e);
                                                                                }}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation.values.is_discount_amount_enable}
                                                                            />
                                                                            <span className="font-size-16">Provide Discount $</span>
                                                                        </div>
                                                                    </div>

                                                                    {validation.values.is_discount_amount_enable && (
                                                                        <Col md="6">
                                                                            <FormGroup className="mb-3">
                                                                                <Label htmlFor="discount_amount">Discount Amount</Label>
                                                                                <Input
                                                                                    name="discount_amount"
                                                                                    placeholder="Discount Amount"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="discount_amount"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.discount_amount || 0}
                                                                                    invalid={
                                                                                        validation.touched.discount_amount && validation.errors.discount_amount ? true : false
                                                                                    }
                                                                                />
                                                                                {validation.touched.discount_amount && validation.errors.discount_amount ? (
                                                                                    <FormFeedback type="invalid">{validation.errors.discount_amount}</FormFeedback>
                                                                                ) : null}
                                                                            </FormGroup>
                                                                        </Col>
                                                                    )}
                                                                    <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_footer_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation.values.is_footer_enable}
                                                                            />
                                                                            <span className="font-size-16">Footer</span>
                                                                        </div>
                                                                    </div>
                                                                    {validation.values.is_footer_enable && (
                                                                        <Col md="6">
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="example-text-input" className="form-Label">Footer Description</Label>
                                                                                <Input
                                                                                    rows={5}
                                                                                    type="textarea"
                                                                                    id="footer_description"
                                                                                    name="footer_description"
                                                                                    value={validation.values.footer_description || ""}
                                                                                    onChange={(e) => {
                                                                                        validation.handleChange(e);
                                                                                    }}
                                                                                ></Input>&nbsp;
                                                                            </div>
                                                                        </Col>
                                                                    )}

                                                                    <Col md="6">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="service">Service Name</Label>
                                                                            <Input
                                                                                name="service"
                                                                                placeholder="Service Name"
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="service"
                                                                                onChange={validation.handleChange}
                                                                                onBlur={validation.handleBlur}
                                                                                value={validation.values.service || ""}
                                                                                invalid={
                                                                                    validation.touched.service && validation.errors.service ? true : false
                                                                                }
                                                                            />
                                                                            {validation.touched.service && validation.errors.service ? (
                                                                                <FormFeedback type="invalid">{validation.errors.service}</FormFeedback>
                                                                            ) : null}
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {/* <div className="mb-3">
                                                                        <div
                                                                            className="form-check form-switch form-switch-md mb-3"
                                                                            dir="ltr"
                                                                        >
                                                                            <input
                                                                                name="is_color_theme_enable"
                                                                                onChange={validation.handleChange}
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                checked={validation.values.is_color_theme_enable}
                                                                            />
                                                                            <span className="font-size-16">Color Theme</span>
                                                                        </div>
                                                                    </div> */}


                                                                    <Row className="mb-4">
                                                                        <Label
                                                                            htmlFor="horizontal-firstname-input"
                                                                            className="col-sm-2 col-form-label"
                                                                        >
                                                                        </Label>
                                                                        <Col md={4}>
                                                                            <div style={{ float: "right" }}>
                                                                                <Button type="submit" color="primary" className="ms-1">
                                                                                    Save
                                                                                </Button>
                                                                            </div>

                                                                        </Col>
                                                                    </Row>



                                                                </div>

                                                            </div>
                                                        </div>


                                                    </Form>
                                                </FormikProvider>
                                            </CardBody>
                                        </TabPane>
                                        <TabPane tabId="integrations">

                                            <CardBody className="p-4">
                                                Quickbooks Integration
                                                <div className="row">
                                                    <div className="col-lg-11 col-md-11">
                                                        {quickbookstatus?.status != "1" && connectQuickbookURL && (
                                                            <div className="mt-3">
                                                                <a target="_blank" rel="noreferrer" href={connectQuickbookURL} className="simple-text logo-mini">
                                                                    {/* <img  style="height:35px;" alt="QB Connect"  height="24"/> */}
                                                                    <img style={{ height: "35px" }} height="24" src="https://demo.immidock.com/C2QB_white_btn_med_default.png" alt="QB Connect" />
                                                                </a>
                                                            </div>
                                                        )}
                                                        {quickbookstatus?.status == "1" && (
                                                            <div className="mt-3">
                                                                <Button color="primary" className="ms-1">
                                                                    Connected Quick Books
                                                                </Button>
                                                                {/* <a rel="noreferrer" href="javascript:void(0)" className="simple-text logo-mini">
                                                                <img style={{ height: "35px" }} height="24" src="https://demo.immidock.com/C2QB_white_btn_med_default.png" alt="QB Connect" />
                                                            </a> */}
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                            </CardBody>

                                        </TabPane>
                                    </TabContent>

                                </Card>
                            </Col>
                        </Container>
                    </div>
                </>
            )}
        </React.Fragment>


    );
};

export default InvoiceSettings;