import React, { useEffect, useMemo, useState } from "react";
import {
    Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input,
    FormGroup, Button, FormFeedback, Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledDropdown,
    Modal,
} from "reactstrap"
import { useAlert } from "../../../components/Common/Alert";

import moment from "moment";

import Dropzone from "react-dropzone";

import { Link } from "react-router-dom";

import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import * as Yup from "yup";
import { useFormik, Field, FieldArray, FormikProvider, ErrorMessage } from "formik";
import classnames from "classnames";
// Form Editor
import { convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import { getInvoiceSettings, changePreloader, quickbookPreloader, getQuickbookCallback, invoiceUpdate, getEmployerContacts as getEmployerContacts, getEmployerContactDetail, addEmployerContactDetail, EmployerContactUpdate, uploadInvoiceAttachment } from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";

const QuickbookCallback = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code'); // Read the 'code' query parameter from the URL
    const quickbook_state = queryParams.get('state'); // Read the '' query parameter from the URL
    const realmId = queryParams.get('realmId'); // Read the '' query parameter from the URL

    const [activeTab1, setactiveTab1] = useState("invoice-setting");
    const toggle1 = (tab) => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };

    const [editorFooterDescription, seteditorFooterDescription] = useState({});
    const [initialValues, setInitialValues] = useState(false);

    const { quickbookerror, quickbookcallbackstatus } = useSelector((state) => ({
        quickbookerror: state.Invoices.quickbookerror || {},
        quickbookcallbackstatus: state.Invoices.quickbookcallbackstatus || false,
    }));


    useEffect(() => {
        // if (code) {
        dispatch(getQuickbookCallback({
            code: code,
            realm_id: realmId,
            "state": quickbook_state,
        }));
        // }
    }, [code]);

    // useEffect(() => {
    //     if (!quickbookcallbackstatus && _.isEmpty(quickbookerror))
    //         dispatch(changePreloader(true));
    //     else
    //         dispatch(changePreloader(false));
    // }, [dispatch]);

    useEffect(() => {
        if (!quickbookcallbackstatus && _.isEmpty(quickbookerror))
            dispatch(quickbookPreloader(true));
        else
            dispatch(quickbookPreloader(false));

        if (!_.isEmpty(quickbookerror) || quickbookcallbackstatus) {
            dispatch(quickbookPreloader(false));
            if (!_.isEmpty(quickbookerror)) {
                // handleAlertOpen(quickbookerror.response.data.message, 'warning');
            } else if (quickbookcallbackstatus) {
                // handleAlertOpen("Quickbook connected successfully!", 'success');
            }
            dispatch(getInvoiceSettings({}));
            var userType = window.localStorage.getItem("user_type")
            navigate(`/${userType}/invoice-settings`, { replace: true });
        }
    }, [quickbookcallbackstatus, quickbookerror]);

    let title = 'Quickbook Integration';
    //meta title
    document.title = title + " | Immidock ";
    const BreadcrumbData = [
        {name:'Dashboard', url:'/employer/dashboard'},
        { name: title, url: '#' }
    ]
    return (

        <React.Fragment>
            <>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                        <Col xs={12}>
                            <Card>

                                <CardHeader>
                                    <h4 className="card-title">Quickbook Callback</h4>
                                </CardHeader>


                            </Card>
                        </Col>
                    </Container>
                </div>
            </>
        </React.Fragment >

    );
};

export default QuickbookCallback;