import {
  SHOW_ALERT_MESSAGE, SHOW_ALERT_MESSAGE_INFO
} from "./actionTypes";

const initialState = {
  alert:{error:"",message:'',loading:false}
};

const CommonAlertMessage = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT_MESSAGE_INFO:
      state = {
        ...state,
        alert:action.payload
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CommonAlertMessage;
