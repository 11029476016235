/* QNNRTYPES */
export const GET_ATTRIBUTESETS = "GET_ATTRIBUTESETS"
export const GET_ATTRIBUTESETS_SUCCESS = "GET_ATTRIBUTESETS_SUCCESS"
export const GET_ATTRIBUTESETS_FAIL = "GET_ATTRIBUTESETS_FAIL"

export const GET_ATTRIBUTESET_DETAIL = "GET_ATTRIBUTESET_DETAIL"
export const GET_ATTRIBUTESET_DETAIL_SUCCESS = "GET_ATTRIBUTESET_DETAIL_SUCCESS"
export const GET_ATTRIBUTESET_DETAIL_FAIL = "GET_ATTRIBUTESET_DETAIL_FAIL"

export const ADD_ATTRIBUTESET_DETAIL = "ADD_ATTRIBUTESET_DETAIL"
export const ADD_ATTRIBUTESET_DETAIL_SUCCESS = "ADD_ATTRIBUTESET_DETAIL_SUCCESS"
export const ADD_ATTRIBUTESET_DETAIL_FAIL = "ADD_ATTRIBUTESET_DETAIL_FAIL"

export const DELETE_ATTRIBUTESET_DETAIL = "DELETE_ATTRIBUTESET_DETAIL"
export const DELETE_ATTRIBUTESET_DETAIL_SUCCESS = "DELETE_ATTRIBUTESET_DETAIL_SUCCESS"
export const DELETE_ATTRIBUTESET_DETAIL_FAIL = "DELETE_ATTRIBUTESET_DETAIL_FAIL"

export const UPDATE_ATTRIBUTESET_DETAIL = "UPDATE_ATTRIBUTESET_DETAIL"
export const UPDATE_ATTRIBUTESET_DETAIL_SUCCESS = "UPDATE_ATTRIBUTESET_DETAIL_SUCCESS"
export const UPDATE_ATTRIBUTESET_DETAIL_FAIL = "UPDATE_ATTRIBUTESET_DETAIL_FAIL"
