import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_COMMON_USERS, GET_COMMON_USER_DETAIL, ADD_COMMON_USER_DETAIL, DELETE_COMMON_USER_DETAIL, UPDATE_COMMON_USER_DETAIL } from "./actionTypes"
import {
  getCommonUsersSuccess,
  getCommonUsersFail,
  getCommonUserDetailSuccess,
  getCommonUserDetailFail,
  addCommonUserdetailSuccess,
  addCommonUserdetailFail,
  userCommonDeleteSuccess,
  userCommonDeleteFail,
  userCommonUpdateSuccess,
  userCommonUpdateFail,
} from "./actions"
import { changePreloader } from "../../store/actions"

//Include Both Helper File with needed methods
import { getCommonUsers, getCommonUserDetail, addCommonUser, deleteUser, updateUser } from "../../helpers/apibackend_helper"
import { setAlertMessage } from "../common/actions"

function* fetchUsers({ payload: data }) {
  try {
    const response = yield call(getCommonUsers, data)
    yield put(getCommonUsersSuccess(response.data))
  } catch (error) {
    yield put(getCommonUsersFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchUserDetail({ userId }) {
  try {
    const response = yield call(getCommonUserDetail, userId)
    yield put(getCommonUserDetailSuccess(response.data))
  } catch (error) {
    yield put(getCommonUserDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* addCommonUserDetail({ payload: userdata }) {
  try {
    const response = yield call(addCommonUser, userdata)
    yield put(addCommonUserdetailSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addCommonUserdetailFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteUser({ payload: data }) {
  try {
    const response = yield call(deleteUser, data);
    yield put(userCommonDeleteSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(userCommonDeleteFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateuser({ payload: data }) {
  try {
    const response = yield call(updateUser, data);
    yield put(userCommonUpdateSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(userCommonUpdateFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* userSaga() {
  yield takeEvery(GET_COMMON_USERS, fetchUsers)
  yield takeEvery(GET_COMMON_USER_DETAIL, fetchUserDetail)
  yield takeEvery(ADD_COMMON_USER_DETAIL, addCommonUserDetail)
  yield takeEvery(DELETE_COMMON_USER_DETAIL, onDeleteUser)
  yield takeEvery(UPDATE_COMMON_USER_DETAIL, onUpdateuser)
}

export default userSaga
