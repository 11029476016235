import React, { useEffect, useMemo, useState } from "react";
import {
    Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input,
    FormGroup, Button, FormFeedback, Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledDropdown,
    Modal,
} from "reactstrap"
import { useAlert } from "../../../components/Common/Alert";

import ViewInvoice from "./ViewInvoice";


import moment from "moment";

import Dropzone from "react-dropzone";

import { Link } from "react-router-dom";

import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import * as Yup from "yup";
import { useFormik, Field, FieldArray, FormikProvider, ErrorMessage } from "formik";
import classnames from "classnames";
// Form Editor
import { convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import { getInvoices, getInvoiceDetail, addInvoiceDetail, getInvoiceSettings, invoiceUpdate, getEmployerContacts as getEmployerContacts, getEmployerContactDetail, addEmployerContactDetail, EmployerContactUpdate, uploadInvoiceAttachment } from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";


// import fs from 'fs';
import { usePDF, pdf, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});


const MyDoc = (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                {/* <ViewInvoice></ViewInvoice> */}
            </View>
        </Page>
    </Document>
);

const blob = pdf(MyDoc).toBlob();



const DownloadInvoice = () => {
   
    const [instance, updateInstance] = usePDF({ document: MyDoc });

    if (instance.loading) return <div>Loading ...</div>;

    if (instance.error) return <div>Something went wrong: {error}</div>;


    let title = invoiceNumber ? 'Update Invoice' : 'Create Invoice';
    //meta title
    document.title = title + " | Immidock ";

    return (
        <React.Fragment>
            <>
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">{title} Invoice</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Invoices</a></li>
                                            <li className="breadcrumb-item active">Invoice Detail</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <a href={instance.url} download={invoiceNumber}>
                            Download Srikanth
                        </a>

                    </div>
                </div>
            </>
        </React.Fragment >
    );
};

export default DownloadInvoice;