import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ATTRIBUTETEMPLATES, GET_ATTRIBUTETEMPLATE_DETAIL, ADD_ATTRIBUTETEMPLATE_DETAIL, DELETE_ATTRIBUTETEMPLATE_DETAIL, UPDATE_ATTRIBUTETEMPLATE_DETAIL } from "./actionTypes"
import {
  getAttributetemplatesSuccess,
  getAttributetemplatesFail,
  getAttributetemplateDetailSuccess,
  getAttributetemplateDetailFail,
  addAttributetemplatedetailSuccess,
  addAttributetemplatedetailFail,
  AttributetemplateDeleteSuccess,
  AttributetemplateDeleteFail,
  AttributetemplateUpdateSuccess,
  AttributetemplateUpdateFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getAttributetemplates, getAttributetemplateDetail, addAttributetemplate, deleteAttributetemplate, updateAttributetemplate } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"
import { changePreloader } from "../../../store/actions"

function* fetchAttributetemplates() {
  try {
    const response = yield call(getAttributetemplates)
    yield put(getAttributetemplatesSuccess(response.data))
  } catch (error) {
    yield put(getAttributetemplatesFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchAttributetemplateDetail({ AttributetemplateId }) {
  try {
    if (AttributetemplateId) {
      const response = yield call(getAttributetemplateDetail, AttributetemplateId)
      yield put(getAttributetemplateDetailSuccess(response.data))
    }
    else {
      yield put(getAttributetemplateDetailSuccess({}))
    }
  } catch (error) {
    yield put(getAttributetemplateDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* addAttributetemplateDetail({ payload: user }) {
  try {
    const response = yield call(addAttributetemplate, user)
    yield put(addAttributetemplatedetailSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addAttributetemplatedetailFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteAttributetemplate({ payload: data }) {
  try {
    const response = yield call(deleteAttributetemplate, data);
    yield put(AttributetemplateDeleteSuccess(data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(AttributetemplateDeleteFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateAttributetemplate({ payload: data }) {
  try {
    const response = yield call(updateAttributetemplate, data);
    yield put(AttributetemplateUpdateSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(AttributetemplateUpdateFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* Attributetemplatesaga() {
  yield takeEvery(GET_ATTRIBUTETEMPLATES, fetchAttributetemplates)
  yield takeEvery(GET_ATTRIBUTETEMPLATE_DETAIL, fetchAttributetemplateDetail)
  yield takeEvery(ADD_ATTRIBUTETEMPLATE_DETAIL, addAttributetemplateDetail)
  yield takeEvery(DELETE_ATTRIBUTETEMPLATE_DETAIL, onDeleteAttributetemplate)
  yield takeEvery(UPDATE_ATTRIBUTETEMPLATE_DETAIL, onUpdateAttributetemplate)
}

export default Attributetemplatesaga
