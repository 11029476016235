import { GET_CASEFORMATTRIBUTETEMPLATES, 
        GET_CASEFORMATTRIBUTETEMPLATES_SUCCESS,
       GET_CASEFORMATTRIBUTETEMPLATES_FAIL,
        ADD_CASEFORMATTRIBUTETEMPLATE,
        ADD_CASEFORMATTRIBUTETEMPLATE_SUCCESS,
        ADD_CASEFORMATTRIBUTETEMPLATE_FAIL, 
        GET_CASEFORMATTR_TEMPLATE_DETAIL,
        GET_CASEFORMATTR_TEMPLATE_DETAIL_SUCCESS,
        GET_CASEFORMATTR_TEMPLATE_DETAIL_FAIL,
        UPDATE_CASEFORMATTRIBUTE_TEMPLATES,
        UPDATE_CASEFORMATTRIBUTE_TEMPLATES_SUCCESS,
        UPDATE_CASEFORMATTRIBUTE_TEMPLATES_FAIL,
        DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL_FAIL,
        DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL_SUCCESS,
        DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL} from "./actionTypes"

export const getCaseFormAttributeTemplates = () => ({
  type: GET_CASEFORMATTRIBUTETEMPLATES
})

export const getCaseFormAttributeTemplatesFail = error => ({
  type: GET_CASEFORMATTRIBUTETEMPLATES_FAIL,
  payload: error
})

export const getCaseFormAttributeTemplatesSuccess = CaseFormAttributeTemplates => ({
  type: GET_CASEFORMATTRIBUTETEMPLATES_SUCCESS,
  payload: CaseFormAttributeTemplates,
})

export const addcaseformAttributetemplate = CaseFormAttributeTemplates => ({
  type: ADD_CASEFORMATTRIBUTETEMPLATE,
  payload: CaseFormAttributeTemplates,
})

export const addcaseformAttributetemplateFail = error => ({
  type: ADD_CASEFORMATTRIBUTETEMPLATE_FAIL,
  payload: error
})

export const addcaseformAttributetemplateSuccess = CaseFormAttributeTemplates => ({
  type: ADD_CASEFORMATTRIBUTETEMPLATE_SUCCESS,
  payload: CaseFormAttributeTemplates,
})


export const getCaseFormAttributeTemplateDetail = Caseformid => ({
  type: GET_CASEFORMATTR_TEMPLATE_DETAIL,
  Caseformid,
})

export const getCaseFormAttributeGroupTemplateSuccess = caseformattributetemplates => ({
  type: GET_CASEFORMATTR_TEMPLATE_DETAIL_SUCCESS,
  payload: caseformattributetemplates,
})

export const getCaseFormAttributeGroupTemplateFail = error => ({
  type: GET_CASEFORMATTR_TEMPLATE_DETAIL_FAIL,
  payload: error,
})


export const updateCaseformAttributetemplates = caseformattributetemplates => ({
  type: UPDATE_CASEFORMATTRIBUTE_TEMPLATES,
  payload: caseformattributetemplates,
})
export const updateCaseformAttributetemplatesSuccess = caseformattributetemplates => ({
  type: UPDATE_CASEFORMATTRIBUTE_TEMPLATES_SUCCESS,
  payload: caseformattributetemplates,
})
export const updateCaseformAttributetemplatesFail = error => ({
  type: UPDATE_CASEFORMATTRIBUTE_TEMPLATES_FAIL,
  payload: error
})


export const caseFormAttributeTemplateDelete = (data) => ({
  type: DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL,
  payload: data
})

export const caseFormAttributeTemplateDeleteSuccess = (data) => ({
  type: DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL_SUCCESS,
  payload: data
})

export const caseFormAttributeTemplateDeleteFail = (error) => ({
  type: DELETE_CASEFORM_ATTRIBUTETEMPLATE_DETAIL_FAIL,
  payload: error
})

