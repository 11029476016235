import React, { useEffect, useRef, useState } from "react";
import { FormGroup, Input, Label, Row, Col, Form, FormFeedback } from "reactstrap";
import {
  useNavigate,
} from "react-router-dom";

import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useDispatch } from "react-redux";
import { uploadEmployerEsign } from "../../../store/employer/esign/actions";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import { getEmployeePAFEsignForm, updateEmployeePafEsignForm } from "../../../store/actions";

const PafEsignForm = () => {
  //meta title
  document.title = "Beneficiary | Immidock ";
  const [text, setText] = useState("");
  const [fontSize, setFontSize] = useState(24);
  const [fontColor, setFontColor] = useState("#000000");
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(40);
  const previewRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fontOptions = [
    { label: "IBM Plex Sans", value: "IBM Plex Sans" },
    { label: "Material Design Icons", value: "Material Design Icons" },
    // { label: "boxicons", value: "boxicons" },
    // { label: "Font Awesome 5 Free", value: "Font Awesome 5 Free" },
    { label: "cursive", value: "cursive" },
    // { label: "emoji", value: "emoji" },
    // { label: "fantasy", value: "fantasy" },
    { label: "monospace", value: "monospace" },
    { label: "sans-serif", value: "sans-serif" },
    { label: "system-ui", value: "system-ui" },
  ];
  const queryParams = new URLSearchParams(location.search);
  const case_id = queryParams.get('case_id');
  const paf_form_id = queryParams.get('paf_form_id');

  const [checkBoxrequired, setCheckBoxrequired] = useState(false);
  const [selectedFont, setSelectedFont] = useState(fontOptions[6]);
  const { employeePAFEsign } = useSelector((state) => ({
    employeePAFEsign: state.EmployeeCases.employeePAFEsign || "",
  }));

  useEffect(() => {
    dispatch(
      getEmployeePAFEsignForm({
        case_id: case_id,
        paf_form_id: paf_form_id,
      })
    );
  }, [dispatch]);
  function createBlob(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(',');
      var contentType = parts[0].split(':')[1];
      var raw = decodeURIComponent(parts[1]);
      return new Blob([raw], { type: contentType });
    }
    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }
  const generateImage = () => {
    html2canvas(previewRef.current).then((canvas) => {
      var dataURL = canvas.toDataURL();
      //   const imgData = canvas.toDataURL("image/png");
      //   const link = document.createElement("a");
      //   link.href = imgData;
      //   link.download = `${text}.png`;
      //   link.click();
      const reqBody = {
        sign_text: `${text}`,
        font_type: `${selectedFont}`,
        esignature_file: createBlob(dataURL),
      };
      dispatch(uploadEmployerEsign(reqBody));
      setTimeout(() => {
        dispatch(
          getEmployeePAFEsignForm({
            case_id: case_id,
            paf_form_id: paf_form_id,
          })
        );
      }, 5000);
    });
  };

  // Form validation 
  const pafEsignFormValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      e_sign_terms: false,
    },
    validationSchema: Yup.object({
      e_sign_terms: Yup.boolean().oneOf([true], 'Please accept the terms'),
    }),
    onSubmit: (values) => {
      dispatch(updateEmployeePafEsignForm({
        ...values,
        case_id: case_id,
        paf_form_id: paf_form_id,
        html_content: employeePAFEsign?.html_content,
      }));
      setTimeout(() => {
        getEmployeePAFEsignForm({
          case_id: case_id,
          paf_form_id: paf_form_id,
        })
        navigate(`/employee/`);
      }, 2000);
      pafEsignFormValidation.resetForm();
    }
  });



  let title = "PAF Esign";
  const BreadcrumbData = [
    { name: "Dashboard", url: "/employee/dashboard" },
    { name: title, url: "#" },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          {employeePAFEsign?.e_sign_path == null ? (
            <Row
              className="d-flex align-items-center w-50"
              style={{ margin: "0px auto" }}
            >
              <FormGroup className="mb-3">
                <div className="mb-3">
                  <Label className="form-label">{"Signature Text *"}</Label>
                  <Input
                    maxLength={50}
                    name={"text"}
                    className={"form-control"}
                    placeholder={"Enter input"}
                    required={true}
                    aria-invalid="true"
                    onChange={(e) => setText(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">{"Select Font family*"}</Label>
                  <Select
                    defaultValue={[fontOptions[0]]}
                    options={fontOptions}
                    classNamePrefix="select2-selection"
                    onChange={(option) => {
                      setSelectedFont(option.value);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <div
                    ref={previewRef}
                    style={{
                      display: "inline-block",
                      background: "rgb(228, 227, 227)",
                      fontFamily: `${selectedFont}`,
                      width:
                        text.length > 5 ? text.length * 10 + 50 : 100 + "px",
                      height: height + "px",
                      fontSize,
                      color: fontColor,
                      textAlign: "left",
                      lineHeight: height + "px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      padding: "0px 10px",
                      cursor: "none",
                      pointerEvents: "none",
                      userSelect: "none",
                    }}
                  >
                    {text}
                  </div>
                </div>
                <div className="mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={generateImage}
                  >
                    Submit
                  </button>
                </div>
              </FormGroup>
            </Row>
          ) : (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  pafEsignFormValidation.handleSubmit();
                  return false;
                }}
              >
                <Row
                  className="d-flex align-items-center w-50"
                  style={{ margin: "0px auto" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: employeePAFEsign?.html_content }} />
                </Row>
                <Row className="d-flex align-items-center w-50">
                  <div className="col-md-12 cf-form-check">
                    <div className="mb-3 mb-3">
                      <div className="form-check mb-3">
                        <input
                          name="e_sign_terms"
                          onChange={(e) => {
                            pafEsignFormValidation.handleChange(e);
                          }}
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitchsizelg"
                          checked={pafEsignFormValidation.values.e_sign_terms}
                        />
                        <label className="form-check-label">Please accept the terms and condition for the e sign form</label>
                        {pafEsignFormValidation.touched.e_sign_terms &&
                          pafEsignFormValidation.errors.e_sign_terms ? (
                          <FormFeedback type="invalid">
                            {pafEsignFormValidation.errors.e_sign_terms}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Row>
                <Row>
                  <Col>
                    <span className="mt-4">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </span>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PafEsignForm;
