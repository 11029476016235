import { GET_CASEFORMS, GET_CASEFORMS_SUCCESS,
  GET_CASEFORMS_FAIL, ADD_CASEFORMS, ADD_CASEFORMS_SUCCESS, 
  ADD_CASEFORMS_FAIL,
  UPDATE_CASE_FORM_FAIL,
  UPDATE_CASE_FORM_SUCCESS,
  UPDATE_CASE_FORM,
  GET_CASE_FORM_SUCCESS,
  GET_CASE_FORM_FAIL,
  GET_CASE_FORM} from "./actionTypes"

export const getCaseFormsList = () => ({
  type: GET_CASEFORMS
})

export const getCaseFormsFail = error => ({
  type: GET_CASEFORMS_FAIL,
  payload: error
})

export const getCaseFormsSuccess = CaseFormsList => ({
  type: GET_CASEFORMS_SUCCESS,
  payload: CaseFormsList,
})

export const getCaseForm = id => ({
  type: GET_CASE_FORM,
  id,
})

export const getCaseFormFail = error => ({
  type: GET_CASE_FORM_FAIL,
  payload: error
})

export const getCaseFormSuccess = CaseFormData => ({
  type: GET_CASE_FORM_SUCCESS,
  payload: CaseFormData,
})

export const  addCaseform = (data) => ({
  type: ADD_CASEFORMS,
  payload: data
})

export const addCaseformFail = error => ({
  type: ADD_CASEFORMS_FAIL,
  payload: error
})

export const addCaseformSuccess = CaseFormsList => ({
  type: ADD_CASEFORMS_SUCCESS,
  payload: CaseFormsList,
})

export const  updateCaseForm = (data) => ({
  type: UPDATE_CASE_FORM,
  payload: data
})

export const updateCaseFormFail = error => ({
  type: UPDATE_CASE_FORM_FAIL,
  payload: error
})

export const updteCaseFormSuccess = CaseFormData => ({
  type: UPDATE_CASE_FORM_SUCCESS,
  payload: CaseFormData,
})


