import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//invoices
import Invoices from "./invoices/reducer"

//customers
import Customers from "./customers/reducer"

//templates
import Templates from "./templates/reducer"

//users
import Users from "./users/reducer"

//profile
import CommonProfile from "./profile/reducer"


//contacts
import contacts from "./contacts/reducer"

//dashboard
import dashboard from "./Dashboard/reducer"
//New roues
import qnnrtypes from "./admin/qnnrtypes/reducer"
import casetypes from "./admin/casetypes/reducer"
import Employers from "./admin/employers/reducer"
// Case form attributes
import caseformattributesets from "./admin/caseformattributesets/reducer"
//import caseformattributes from "./admin/caseformattributes/reducer"
import CaseFormAttributes from "./admin/caseformattributes/reducer"

//New roues
import attributes from "./admin/attributes/reducer"
import attorneys from "./admin/attorneys/reducer"
import attributesets from "./admin/attributesets/reducer"
//New roues
import attributegroups from "./admin/attributegroups/reducer"
import attributetemplates from "./admin/attributetemplates/reducer"

//Attorney
import AttorneyContacts from "./attorney/contacts/reducer"
import AttorneyDashboard from "./attorney/dashboard/reducer"
import AttorneyCompanyInfo from "./attorney/companyinfo/reducer"
import AttorneyCases from "./attorney/cases/reducer"
import AttorneyMasterDocs from "./attorney/masterdocs/reducer"

//Employeer
import EmployerContacts from "./employer/contacts/reducer"
import EmployerDashboard from "./employer/dashboard/reducer"
import EmployerCompanyInfo from "./employer/companyinfo/reducer"
import EmployerCases from "./employer/cases/reducer"
import employerPayrolls from "./employer/payroll/reducer"


//Employee 
import EmployeeCases from "./employee/cases/reducer"

//AlertInfo
import AlertInfo from "./alertinfo/reducer"

import caseformattributetemplates from "./admin/caseformattributestemplates/reducer"
import CaseFormsList from "./admin/caseforms/reducer"
import caseformattributesgroups from "./admin/caseformattributesgroups/reducer"

//Timesheets
import EmployeeTimesheets from "./employee/timesheets/reducer"
import EmployerTimesheets  from "./employer/timesheets/reducer"
import employerEsign  from "./employer/esign/reducer"
import CommonAlertMessage  from "./common/reducer"

const rootReducer = combineReducers({
  //Attorney
  AttorneyContacts,
  AttorneyDashboard,
  AttorneyCompanyInfo,
  AttorneyCases,
  EmployerCases,
  EmployerContacts,
  EmployerDashboard,
  EmployerCompanyInfo,
//Employee
  EmployeeCases,
  // public
  Layout,
  login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  chat,
  Invoices,
  Customers,
  Templates,
  Users,
  CommonProfile,
  qnnrtypes,
  casetypes,
  Employers,
  attributes,
  attributesets,
  attorneys,
  attributegroups,
  attributetemplates,
  contacts,
  dashboard,
  AlertInfo,
  caseformattributesets,
  CaseFormAttributes,
  caseformattributetemplates,
  CaseFormsList,
  caseformattributesgroups,
  EmployeeTimesheets,
  EmployerTimesheets,
  employerPayrolls,
  AttorneyMasterDocs,
  CommonAlertMessage,
  employerEsign
})

export default rootReducer
