import React from "react";

import EmployerInvoiceView from "../../Invoices/ViewInvoice";
import EmployerBasicSuite from "../../Basic";

const MainInvoiceViewComponent = () => {

const getLoggedInPlanType = () => {
    return localStorage.getItem("plan_type");
  }

  if(getLoggedInPlanType()==1) {
    return <EmployerInvoiceView/>
  } else {
    return <EmployerBasicSuite componentName="View Invoice"/>
  }
};

export default MainInvoiceViewComponent;
