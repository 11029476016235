/* USERS */
export const UPDATE_NOTIFICATION_EMAILS = "UPDATE_NOTIFICATION_EMAILS"
export const UPDATE_NOTIFICATION_EMAILS_SUCCESS = "UPDATE_NOTIFICATION_EMAILS_SUCCESS"
export const UPDATE_NOTIFICATION_EMAILS_FAIL = "UPDATE_NOTIFICATION_EMAILS_FAIL"

export const UPDATE_LCA_EMPLOYEE_ACCESS = "UPDATE_LCA_EMPLOYEE_ACCESS"
export const UPDATE_LCA_EMPLOYEE_ACCESS_SUCCESS = "UPDATE_LCA_EMPLOYEE_ACCESS_SUCCESS"
export const UPDATE_LCA_EMPLOYEE_ACCESS_FAIL = "UPDATE_LCA_EMPLOYEE_ACCESS_FAIL"

export const UPDATE_QUESTIONAIRE_REVIEW = "UPDATE_QUESTIONAIRE_REVIEW"
export const UPDATE_QUESTIONAIRE_REVIEW_SUCCESS = "UPDATE_QUESTIONAIRE_REVIEW_SUCCESS"
export const UPDATE_QUESTIONAIRE_REVIEW_FAIL = "UPDATE_QUESTIONAIRE_REVIEW_FAIL"

export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL"

export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL"

export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL"

export const EMPLOYER_RESEND_PASSWORD = "EMPLOYER_RESEND_PASSWORD"
export const EMPLOYER_RESEND_PASSWORD_SUCCESS = "EMPLOYER_RESEND_PASSWORD_SUCCESS"
export const EMPLOYER_RESEND_PASSWORD_FAIL = "EMPLOYER_RESEND_PASSWORD_FAIL"

export const EMPLOYEE_RESEND_PASSWORD = "EMPLOYEE_RESEND_PASSWORD"
export const EMPLOYEE_RESEND_PASSWORD_SUCCESS = "EMPLOYEE_RESEND_PASSWORD_SUCCESS"
export const EMPLOYEE_RESEND_PASSWORD_FAIL = "EMPLOYEE_RESEND_PASSWORD_FAIL"
