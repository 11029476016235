import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Input, Label } from "reactstrap";
import { getCaseDependentsAttorney, getEmployeeCountrys, getEmployeeStates } from "../../../../store/actions";
import CheckboxGroupType from "../Elements/CheckboxGroupType";
import FileType from "../Elements/File";
import InputType from "../Elements/Input";
import InputTextarea from '../Elements/InputTextarea';
import RadioGroupType from '../Elements/RadioGroupType';
import ReadOnly from "../Elements/ReadOnly";
import Select from "../Elements/Select";
import { checkValidation } from "../Shared/utility";

const CasePartGeneration = ({ controls, setControls, checkAttributeSetDependency, checkBoxrequired, setCheckBoxrequired, activeTabProgress }) => {
  const onInputChangedHandler = (event, controlName) => {
    const updatedControls = { ...controls };
    for (const updatedControldata in updatedControls) {
      if (updatedControls[updatedControldata] && updatedControls[updatedControldata].sets) { //if (parts.sets.hasOwnProperty(key)) {
        for (const set of updatedControls[updatedControldata].sets) {
          for (const attribute of set.attributes) {
            if (attribute?.content?.select_field === "country") {
              setSelectedCountry(event.target?.value);
            }
            else if (attribute?.content?.select_field === "countryus") {
              setSelectedCountryField("countryus");
              setSelectedCountry("USA");
            }
            if (attribute.name === controlName) {
              var attribute_value = event?.target?.value || "";
              if (attribute.type === "date") {
                attribute.selected = event?.target?.value ? "" : event;
                attribute.value = attribute_value ? "" : event;
                attribute.isValid = attribute_value || attribute?.value ? true : false;
              } else {
                if (attribute.type === "checkbox-group") {
                  var previous_value = (attribute?.value).split(",");
                  var values = event?.target?.getAttribute("values").split(",");

                  const index = previous_value.findIndex(v => v === attribute_value);
                  if (index > -1) {
                    previous_value.splice(index, 1);
                  } else {
                    previous_value.push(attribute_value);
                  }

                  attribute_value = previous_value.filter(value => {
                    return value != '' && values.includes(value);
                  });

                  if (Array.isArray(attribute_value)) {
                    attribute_value = attribute_value.join(",");
                  }
                }

                attribute.value = attribute_value;

                if (updatedControls?.groupId == "application") {
                  const validationObj = checkValidation(attribute_value, attribute?.content, attribute?.content?.label);
                  attribute.isValid = validationObj.isValid;
                  attribute.invalidMessage = validationObj.invalidMessage;
                }
              }
              break; // Attribute found and updated, exit the loop
            }
          }
        }
      }
    }
    setControls(updatedControls);
  }

  const queryParams = new URLSearchParams(location.search);
  const caseId = queryParams.get('case_id');

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryField, setSelectedCountryField] = useState("");

  const { states, selectedStates, countrys, selectedCountries, casedependentsFormList } = useSelector((state) => ({
    states: state.EmployeeCases.states || [],
    selectedStates: state.EmployeeCases.selectedStates || [],
    countrys: state.EmployeeCases.countrys || [],
    selectedCountries: state.EmployeeCases.selectedCountries || [],
    casedependentsFormList: state.AttorneyCases.casedependentsFormList || [],
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEmployeeCountrys());
    dispatch(getCaseDependentsAttorney({ case_id: caseId }))
    dispatch(getEmployeeStates(233));
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const cuntryCode = countrys.find(
        (item) => item.label === selectedCountry
      );
      dispatch(getEmployeeStates(cuntryCode?.code || 233));
    }
  }, [selectedCountry]);


  return (
    <div key="case-generation-form">
      {controls.length > 0 && controls.map((control, keyindex) => (
        <div key={"title" + control?.title + control?.group_id + keyindex}>
          <>
            {(control.title) ? (
              <table width="100%" border="0" cellSpacing="1" style={{ backgroundColor: '#000' }}>
                <tbody><tr>
                  <td style={{ backgroundColor: '#e2e2e2', 'padding': '3px', marginTop: '0.5rem' }} className="header">
                    <h5>{control.title} {control.shortDesc}</h5></td>
                </tr>
                </tbody>
              </table>
            ) : ""}
          </>
          {
            control.sets.map((item, keyindex) => {
              {
                var isDependent_block_check = checkAttributeSetDependency(item);
              }
              return (
                <div key={"cfsets" + item?.id + keyindex}>
                  {
                    isDependent_block_check == true
                      ? (
                        < div key={'generatecaseform' + item.id} className="mt-2 case_form_view_sets" >
                          <div className="text-left">
                            {item.name?.length > 0 && (
                              <>
                                <p className="card-title">
                                  <h6>
                                    <span dangerouslySetInnerHTML={{ __html: item.name }} /><span dangerouslySetInnerHTML={{ __html: item.shortDescription }} />
                                  </h6>
                                </p>
                                <p>{item.description}</p>
                              </>
                            )}
                            {/* Fill all information below */}
                          </div>
                          <div className="row">
                            {item?.attributes?.map((row, keyindex) => (
                              <div className={row?.content?.class_name ? row?.content?.class_name : 'col-lg-4'} key={"cfattributes" + row?.id + keyindex}>
                                {
                                  row?.content?.select_field === "country" &&
                                    (row.type === "text" || row.type === "select") ? (
                                    <>
                                      <Control
                                        {...row}
                                        name={row.name}
                                        type="select"
                                        content={{ ...row.content, name: row.name, values: selectedCountries }}
                                        onChanged={(event) =>
                                          onInputChangedHandler(event, row.name, row)
                                        }
                                      />
                                    </>
                                  ) : row?.content?.select_field === "countryus" &&
                                    (row.type === "text" || row.type === "select") ? (
                                    <>
                                      <Control
                                        {...row}
                                        name={row.name}
                                        type="select"
                                        content={{
                                          ...row.content,
                                          name: row.name,
                                          values: [
                                            {
                                              code: 233,
                                              label: "USA",
                                              value: "USA",
                                              iso: "US",
                                              phonecode: "1",
                                              selected: false,
                                            },
                                          ],
                                        }}
                                        onChanged={(event) =>
                                          onInputChangedHandler(event, row.name, row)
                                        }
                                      />
                                    </>
                                  ) : row?.content?.select_field === "state" &&
                                    (row.type === "select") ? ( // || row.type === "text"
                                    <>
                                      {selectedStates ? (
                                        <Control
                                          {...row}
                                          name={row.name}
                                          type="select"
                                          content={{ ...row.content, name: row.name, values: selectedStates }}
                                          onChanged={(event) =>
                                            onInputChangedHandler(event, row.name, row)
                                          }
                                        />
                                      ) : (
                                        <Control
                                          {...row}
                                          name={row.name}
                                          content={{ ...row.content, name: row.name, values: [] }}
                                          onChanged={(event) =>
                                            onInputChangedHandler(event, row.name, row)
                                          }
                                          onChange={(event) =>
                                            onInputChangedHandler(event, row.name, row)
                                          }
                                        />
                                      )}
                                    </>
                                  ) :
                                    row?.content?.select_field === "dependent" &&
                                      (row.content?.type === "select") ? (
                                      <>
                                        <Control
                                          {...row}
                                          name={row.name}
                                          type="select"
                                          content={{ ...row.content, name: row.name, values: casedependentsFormList }}
                                          onChanged={(event) =>
                                            onInputChangedHandler(event, row.name, row)
                                          }
                                        />
                                      </>
                                    ) : (
                                      <Control {...row} onChanged={(event) => onInputChangedHandler(event, row.name)} onChange={(event) =>
                                        onInputChangedHandler(event, row.name, row)
                                      } />
                                    )
                                }


                              </div>
                            ))}
                          </div>
                        </div >
                      )
                      : ""
                  }
                </div>
              )
            }
            )
          }

        </div>
      ))
      }
      {controls?.groupId == "main" && (
        <>
          <div className="col-md-12 cf-form-check">
            <div className="mb-3 mb-3 mt-4">
              <div className="form-check mb-3">
                <Input
                  onChanged={(event) => onInputChangedHandler(event, "mandate", "")}
                  onChange={(event) => {
                    setCheckBoxrequired(!checkBoxrequired)
                    onInputChangedHandler(event, "mandate", "")
                  }
                  }
                  name="dc_sec3_3"
                  aria-describedby="invalid_dc_sec3_3"
                  type="checkbox"
                  className="form-control form-check-input"
                  value="A"
                  required={true}
                  checked={checkBoxrequired}
                />
                <label className={checkBoxrequired ? 'form-check-label font-size-15' : 'form-check-label font-size-15 invalid-feedback'}>Please accept the form changes</label>
              </div>
            </div>
          </div>
        </>
      )}
    </div >
  );
};

export default CasePartGeneration;

const Control = (props) => {
  switch (props.type) {
    case 'select':
      return <Select {...props} />;
    case "checkbox-group":
      return <CheckboxGroupType {...props} />;
    case 'radio-group':
      return <RadioGroupType {...props} />;
    case 'file':
      return <FileType {...props} />;
    case "label":
      return <ReadOnly {...props} />;
    case "date":
      var clearable = (props?.value) ? true : false;
      return (
        <div className="">
          <Label className="form-label mb-0" style={{ fontWeight: "bold" }}> {props?.content?.label}</Label>
          <ReactDatePicker
            isClearable={clearable}
            showYearDropdown={true}
            startDate={props?.value}
            placeholderText="MM/dd/yyyy"
            {...props}
            dateFormat="MM/dd/yyyy"
            showIcon
            placeholder="Date picker"
            className={props?.invalidMessage ? "is-invalid form-control" : "form-control"}

          />
        </div>
      );
    case "textarea":
      return <InputTextarea {...props} />;
    case "text":
      if (props?.content?.select_field_type == "label")
        return <ReadOnly {...props} />;
      else
        return <InputType {...props} />;
    default:
      return <InputType {...props} />;
  }
};
