import React, { useEffect, useMemo, useState } from "react";
import DatatableContainer from "../../../components/Common/DatatableContainer";
import {
  Link, useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

import { getAttorneyCases as getAttorneyCases, deleteAttorneyCase, setAlertFlagInfo } from "../../../store/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  CommonCell, Date, CaseTypeName
} from "./listCol";


//redux
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";


const InvoicesList = () => {

  //meta title
  document.title = "Invoices List | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openTab, setopenTab] = useState("Open");

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);



  // const { invoices } = useSelector((state) => ({
  //   invoices: state.AttorneyCases.attorneycase || [],
  // }));

  const invoices = [
    {
      "case_id": 42,
      "invoice": "P7UXDCX1HLQ7",
      "customer": "Customer",
      "employee": "Joel Benett",
      "date": "01\/05\/2023",
      "due_date": "02\/07\/2023",
      "total": "$26.30",
      "status": "Active"
    }];

  const [contactList, setContactList] = useState({ "status": "Open" });

  useEffect(() => {


    dispatch(getAttorneyCases(contactList));
    // 

  }, [dispatch]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };
  var userType = window.localStorage.getItem("user_type")

  const handleUserClicks = () => {
    navigate(`/${userType}/create-invoice`);
  };

  const onUpdateData = (data) => {
    navigate(`/${userType}/view-invoice?invoiceId=${data.case_id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };

  const handleDeleteInvoice = () => {
    if (order.id) {
      dispatch(qnnrtypeDelete(order.id));
      setDeleteModal(false);
    }
  };

  const onReloadData = (tabInfo) => {
    setopenTab(tabInfo);
    dispatch(getAttorneyCases({ status: tabInfo }));

  }
  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" />;
      //   },
      // },
      {
        Header: "Invoice",
        accessor: "invoice",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Customer",
        accessor: "customer",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Beneficiary",
        accessor: "employee",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Date",
        accessor: "date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },

      {
        Header: "Due Date",
        accessor: "due_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Total",
        accessor: "total",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },


      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onUpdateData(cellProps.row.original)}>View</DropdownItem>
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  let title = 'Invoices';
  const BreadcrumbData = [
    {name:'Dashboard', url:'/attorney/dashboard'},
    { name: title, url: '#' }
  ] 
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteInvoice}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-pills nav-pills-warning" role="tablist">
                    <li className="nav-item">
                      <a
                        className={openTab == 'Open' ? 'nav-link  active  show' : 'nav-link'}
                        onClick={() => onReloadData('Open')}
                      >
                        Open
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={openTab == 'Draft' ? 'nav-link  active  show' : 'nav-link'} onClick={() => onReloadData('Draft')}>
                        Draft
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={openTab == 'Paid' ? 'nav-link  active  show' : 'nav-link'} onClick={() => onReloadData('Paid')}>
                        Paid
                      </a>
                    </li>
                  </ul>


                  <DatatableContainer
                    columns={columns}
                    data={invoices}
                    isGlobalFilter={true}
                    isAddAttorneyCase={true}
                    isAddInvoiceList={true}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InvoicesList;
