export const TEXT = "text";
export const EMAIL = "email";
export const DATE = "date";
export const DATE_TIME = "datetime";
export const PASSWORD = "password";
export const SELECT = "SELECT";

const ValidationRules = {
    REQUIRED: "required",
    IS_EMAIL: 'email',
    IS_NUMBER: 'number',
    LENGTH: 'maxlength',
    MIN_LENGTH: 'minLength',
    VALID_EXTENSIONS: 'validExtensions',
};

export const checkValidityClass = (errorMessage) => {
    if (typeof errorMessage != "undefined" && errorMessage.length > 0)
        return 'is-invalid';
    return '';
}

export const getCurrentProgressValue = (value, qnnrProgress) => {
    let currentProgress = Math.floor(value / 5) * 5;
    return (qnnrProgress > currentProgress) ? qnnrProgress : currentProgress;
}

export const checkValidation = (value, attribute, label) => {
    if (typeof label == 'undefined' || label == "undefined") {
        label = '';
    }
    const rules = attribute;
    let validStatus = {
        isValid: true,
        invalidMessage: ''
    };

    if (!rules) {
        validStatus.isValid = true;
        return validStatus;
    }

    if (rules[ValidationRules.REQUIRED]) {
        if (((value?.length === 0 || value === 'null' || value === '' || value === null) && validStatus.isValid)) {
            validStatus.isValid = false;
            validStatus.invalidMessage = label + " field is required!";
            return validStatus
        }
    }

    if (rules?.subtype == ValidationRules.IS_EMAIL && (value !== null)) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!(value.match(regex) && validStatus.isValid)) {
            validStatus.isValid = false;
            validStatus.invalidMessage = label + " field is must be an email!";
            return validStatus
        }
    }

    if (rules?.type == ValidationRules.IS_NUMBER) {
        if (!(!isNaN(value) && validStatus.isValid)) {
            validStatus.isValid = false;
            validStatus.invalidMessage = label + " is must number value!"
            return validStatus
        }
    }
    // if (rules[ValidationRules.LENGTH]) {
    //     if (!(value.length <= rules.maxlength && validStatus.isValid)) {
    //         validStatus.isValid = false;
    //         validStatus.invalidMessage = label + " field is must on " + rules.maxlength + " character!";
    //         return validStatus
    //     }
    // }
    if (rules[ValidationRules.MIN_LENGTH]) {
        if (!(value.length < rules.minLength && validStatus.isValid)) {
            validStatus.isValid = false;
            validStatus.invalidMessage = label + " must on " + rules.minLength + " characters!"
            return validStatus
        }
    }


    if (rules[ValidationRules.VALID_EXTENSIONS]) {

        const ext = value.name.substring(value.name.lastIndexOf('.') + 1).toLowerCase();

        if (!rules[ValidationRules.VALID_EXTENSIONS].includes(ext)) {
            validStatus.isValid = false;
            validStatus.invalidMessage = label + " is must " + rules[ValidationRules.VALID_EXTENSIONS].join(', ') + " extension !"
            return validStatus
        }
    }
    return validStatus;
}

