import PropTypes from "prop-types"
import React, { useEffect, useRef, useCallback } from "react"
import { useSelector } from "react-redux";


//Import Icons
import FeatherIcon from "feather-icons-react";

//Import images
import giftBox from "../../assets/images/giftbox.png"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { Link, useLocation } from "react-router-dom"
import withRouter from "../Common/withRouter"
//redux

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

 

  const ADMINSIDEBAR = [
    {
      title: 'Dashboard',
      icon: 'home',
      path: '/'
    },
    {
      title: 'Attorneys',
      icon: 'list',
      path: '/admin/attorneys'
    },
    {
      title: 'CaseTypes',
      icon: 'list',
      path: '/admin/case-types'
    },
    {
      title: 'Questionnaire Types',
      icon: 'gift',
      path: '/admin/questionnaire-types'
    },
    {
      title: 'Attributes',
      icon: 'briefcase',
      path: '/admin/questionnaire-attributes'
    },
    {
      title: 'Attributes Sets',
      icon: 'grid',
      path: '/admin/questionnaire-attributes-sets'
    },
    {
      title: 'Attributes Groups',
      icon: 'users',
      path: '/admin/questionnaire-attributes-groups'
    },
    {
      title: 'Qnnr Templates',
      icon: 'map',
      path: '/admin/questionnaire-templates'
    }
  ]

  const ATTORNEYSIDEBAR = [
    {
      title: 'Dashboard',
      icon: 'home',
      path: '/attorney/'
    },
    {
      title: 'CompanyInfo',
      icon: 'list',
      path: '/attorney/companyinfo'
    },
    {
      title: 'Contacts',
      icon: 'list',
      path: '/attorney/contacts'
    },
    {
      title: 'Cases',
      icon: 'gift',
      path: '/attorney/cases'
    },
    // {
    //   title: 'Invoices',
    //   icon: 'map',
    //   path: '/attorney/invoices'
    // },
    // {
    //   title: 'Payroll',
    //   icon: 'map',
    //   path: '/attorney/payroll'
    // },
  /*  {
      title: 'Reminders',
      icon: 'briefcase',
      path: '/attorney/reminders'
    },
    {
      title: 'Team',
      icon: 'grid',
      path: '/attorney/team'
    },
    {
      title: 'Events',
      icon: 'users',
      path: '/attorney/events'
    },
    {
      title: 'Learning Center',
      icon: 'map',
      path: '/attorney/learningcenter'
    },
    {
      title: 'Support',
      icon: 'map',
      path: '/attorney/support'
    }*/
  ]
  const ATTORNEYPENDINGSIDEBAR = [
    {
      title: 'Registration',
      icon: 'list',
      path: '/attorney/add-company-info'
    },

  ]

  const EMPLOYERSIDEBAR = [
    {
      title: 'Dashboard',
      icon: 'home',
      path: '/employer/'
    },
    {
      title: 'CompanyInfo',
      icon: 'list',
      path: '/employer/companyinfo'
    },
    {
      title: 'Cases',
      icon: 'gift',
      path: '/employer/cases'
    },
    {
      title: 'Employees',
      icon: 'list',
      path: '/employer/employeelist'
    },

    {
      title: 'Timesheets',
      icon: 'briefcase',
      path: '/employer/timesheets'
    },
    {
      title: 'Invoices',
      icon: 'map',
      path: '/employer/invoices'
    },
    {
      title: 'Payroll',
      icon: 'map',
      path: '/employer/payroll'
    },
    {
      title: 'Learning Center',
      icon: 'map',
      path: '/employer/learningcenter'
    },
    {
      title: 'Support',
      icon: 'map',
      path: '/employer/support'
    }
  ]

  const EMPLOYERPENDINGSIDEBAR = [

    {
      title: 'Registration',
      icon: 'list',
      path: '/employer/companyinfo'
    },

  ]

  const   EMPLOYEESIDEBAR=[
    {
      title: 'Dashboard',
      icon: 'home',
      path: '/employee/'
    },
    {
      title: 'Cases',
      icon: 'gift',
      path: '/employee/cases'
    },
    {
      title: 'Documents',
      icon: 'gift',
      path: '/employee/documents'
    },
    {
      title: 'Forms',
      icon: 'map',
      path: '/employee/forms'
    },
    {
      title: 'Support',
      icon: 'map',
      path: '/employee/support'
    }
  ]


  const { profile } = useSelector((state) => ({
    profile: state.Profile.profile,
  }));
  let navigation = ADMINSIDEBAR;
  const getSessionUserType = JSON.parse(localStorage.getItem("authUser"));
  const userRole = profile ? profile?.user_type : getSessionUserType?.data?.user_type;
  const userStatus = profile ? profile?.status : getSessionUserType?.data?.status;
  

  if (userRole == 'Attorney') {
    navigation = userStatus == 'Pending' ? ATTORNEYPENDINGSIDEBAR : ATTORNEYSIDEBAR
  } else if (userRole == 'Petitioner') {
    navigation = userStatus == 'Pending' ? EMPLOYERPENDINGSIDEBAR : EMPLOYERSIDEBAR
  } else if (userRole == 'Employee') {
    navigation = EMPLOYEESIDEBAR
  } else if (userRole == 'superadmin' || userRole == 'admin') {
    navigation = ADMINSIDEBAR
  }else{
    navigation = EMPLOYEESIDEBAR
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {navigation.map(item => (
              <li key={item.title}>
                <Link
                  to={`${item.path}`}
                  className=""
                >  <FeatherIcon
                    icon={item.icon}
                  />
                  <span>{props.t(item.title)}</span>
                </Link>
              </li>
            ))}
            
          </ul>

        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
