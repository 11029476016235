import {
  ADD_EMPLOYER_PAYROLL_FAIL,
  ADD_EMPLOYER_PAYROLL_SUCCESS,
  DELETE_EMPLOYER_PAYROLL,
  DELETE_EMPLOYER_PAYROLL_FAIL,
  DELETE_EMPLOYER_PAYROLL_SUCCESS,
  GET_EMPLOYER_PAYROLLS_FAIL,
  GET_EMPLOYER_PAYROLLS_SUCCESS,
  GET_EMPLOYER_PAYROLL_FAIL,
  GET_EMPLOYER_PAYROLL_SUCCESS,
  REFRESH_EMPLOYEE_PAYROLL_FAIL,
  REFRESH_EMPLOYEE_PAYROLL_SUCCESS,
  REFRESH_EMPLOYER_PAYROLL_FAIL,
  REFRESH_EMPLOYER_PAYROLL_SUCCESS,
  UPDATE_EMPLOYER_PAYROLL_FAIL,
  UPDATE_EMPLOYER_PAYROLL_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  employerPayrolls: [],
  employerPayroll: {},
  updateemployerPayrolls:{},
  error: {},
}

const employerPayrolls = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYER_PAYROLLS_SUCCESS:
      return {
        ...state,
        employerPayrolls: action.payload,
      }

    case GET_EMPLOYER_PAYROLLS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYER_PAYROLL_SUCCESS:
      return {
        ...state,
        employerPayroll: action.payload,
      }

    case GET_EMPLOYER_PAYROLL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_EMPLOYER_PAYROLL_SUCCESS:
      return {
        ...state,
        employerPayrolls: [...state.employerPayrolls, action.payload],
      }

    case ADD_EMPLOYER_PAYROLL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_EMPLOYER_PAYROLL_SUCCESS:
      return {
        ...state,
        updateemployerPayrolls: [...state.employerPayrolls, action.payload],
      };

    case UPDATE_EMPLOYER_PAYROLL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_EMPLOYER_PAYROLL_SUCCESS:
      return {
        ...state,
        employerPayrolls: action.payload,
      };

    case DELETE_EMPLOYER_PAYROLL_FAIL:
      return {
        ...state,
        error: action.payload,
      };  
    case REFRESH_EMPLOYER_PAYROLL_SUCCESS:
      return {
        ...state,
        employerPayrolls:action.payload,
      };

    case REFRESH_EMPLOYER_PAYROLL_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case REFRESH_EMPLOYEE_PAYROLL_SUCCESS:
      return {
        ...state,
        employerPayroll: action.payload,
      };

    case REFRESH_EMPLOYEE_PAYROLL_FAIL:
      return {
        ...state,
        error: action.payload,
      };      
    default:
      return state
  }
}

export default employerPayrolls
