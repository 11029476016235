import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ATTRIBUTES, GET_ATTRIBUTE_DETAIL, ADD_ATTRIBUTE_DETAIL, DELETE_ATTRIBUTE_DETAIL, UPDATE_ATTRIBUTE_DETAIL } from "./actionTypes"
import {
  getAttributesSuccess,
  getAttributesFail,
  getAttributeDetailSuccess,
  getAttributeDetailFail,
  addAttributedetailSuccess,
  addAttributedetailFail,
  AttributeDeleteSuccess,
  AttributeDeleteFail,
  AttributeUpdateSuccess,
  AttributeUpdateFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getAttributes, getAttributeDetail, addAttribute, deleteAttribute, updateAttribute } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"
import { changePreloader } from "../../../store/actions"

function* fetchAttributes() {
  try {
    const response = yield call(getAttributes)
    yield put(getAttributesSuccess(response.data))
  } catch (error) {
    yield put(getAttributesFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchAttributeDetail({ AttributeId }) {
  try {
    const response = yield call(getAttributeDetail, AttributeId)
    yield put(getAttributeDetailSuccess(response.data))
  } catch (error) {
    yield put(getAttributeDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* addAttributeDetail({ payload: user }) {
  try {
    const response = yield call(addAttribute, user)
    yield put(addAttributedetailSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addAttributedetailFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteAttribute({ payload: data }) {
  try {
    const response = yield call(deleteAttribute, data);
    yield put(AttributeDeleteSuccess(data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(AttributeDeleteFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateAttribute({ payload: data }) {
  try {
    const response = yield call(updateAttribute, data);
    yield put(AttributeUpdateSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(AttributeUpdateFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* Attributesaga() {
  yield takeEvery(GET_ATTRIBUTES, fetchAttributes)
  yield takeEvery(GET_ATTRIBUTE_DETAIL, fetchAttributeDetail)
  yield takeEvery(ADD_ATTRIBUTE_DETAIL, addAttributeDetail)
  yield takeEvery(DELETE_ATTRIBUTE_DETAIL, onDeleteAttribute)
  yield takeEvery(UPDATE_ATTRIBUTE_DETAIL, onUpdateAttribute)
}

export default Attributesaga
