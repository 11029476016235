import { GET_CASEFORMATTRIBUTES_SUCCESS,
         GET_CASEFORMATTRIBUTES_FAIL,
         ADD_CASEFORMATTRIBUTE_SUCCESS,
         ADD_CASEFORMATTRIBUTE_FAIL,
         GET_CASEFORMATTRIBUTE_DETAIL_FAIL,
         GET_CASEFORMATTRIBUTE_DETAIL_SUCCESS,
         UPDATE_CASEFORMATTRIBUTE_SUCCESS,
         UPDATE_CASEFORMATTRIBUTE_FAIL,
         DELETE_CASEFORMATTRIBUTE_FAIL,
         DELETE_CASEFORMATTRIBUTE_SUCCESS} from "./actionTypes"

const INIT_STATE = {
  caseformattributes: [],
  caseformattributeDetail:{},
  error: {},
}

const Caseformattributetemplates = (state = INIT_STATE, action) => {
  switch (action.type) { 
    case GET_CASEFORMATTRIBUTES_SUCCESS:
      return {
        ...state,
        caseformattributes: action.payload,
      }

    case GET_CASEFORMATTRIBUTES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CASEFORMATTRIBUTE_SUCCESS:
        return {
          ...state,
          caseformattributes: action.payload
        }
  
    case ADD_CASEFORMATTRIBUTE_FAIL:
        return {
          ...state,
          error: action.payload,
        }
      case GET_CASEFORMATTRIBUTE_DETAIL_SUCCESS:
        return {
          ...state,
          caseformattributeDetail: action.payload,
        }
  
      case GET_CASEFORMATTRIBUTE_DETAIL_FAIL:
        return {
          ...state,
          error: action.payload,
        }
        case UPDATE_CASEFORMATTRIBUTE_SUCCESS:
          return {
            ...state,
            attributes: action.payload
          };
    
        case UPDATE_CASEFORMATTRIBUTE_FAIL:
          return {
            ...state,
            error: action.payload,
          };

      case DELETE_CASEFORMATTRIBUTE_SUCCESS:
        return {
          ...state,
          caseformattributes: state.caseformattributes.filter((item) => item.id !== action.payload)
        };
  
      case DELETE_CASEFORMATTRIBUTE_FAIL:
        return {
          ...state,
          error: action.payload,
        };

    default:
      return state
  }
}

export default Caseformattributetemplates
