import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";

import Select, { components } from "react-select";

//redux
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { getDateFormatYMD } from "../../../common/data/dateFormat";
import { addEmployerPayroll, getEmployerPayrolls } from "../../../store/employer/payroll/actions";
import { setAlertMessage } from "../../../store/common/actions";


const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex",
        cursor: "pointer"
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <input type="checkbox" checked={isSelected} />&nbsp;
            {children}
        </components.Option>
    );
};

const allOptions = [
    { value: "Full Time", label: "Full Time" },
    { value: "Part Time", label: "Part Time" },
    { value: "Internship", label: "Internship" },
    { value: "1099", label: "1099" },
    { value: "C2C", label: "C2C" },
];

const CreatePayroll = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedOptions, setSelectedOptions] = useState([]);

    const queryParams = new URLSearchParams(location.search);
    const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL


    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            pay_from: '',
            pay_to: '',
            employement_type: '',
            payroll_description: '',
        },
        validationSchema: Yup.object({
            pay_from: Yup.string().required("Please Enter pay form"),
            pay_to: Yup.string().required("Please Enter pay to"),
            employement_type: Yup.string().required("Please Enter employee type"),
            payroll_description: Yup.string().required("Please Enter payroll description"),
        }),
        onSubmit: (values) => {
            dispatch(addEmployerPayroll({ ...values, pay_from: getDateFormatYMD(values?.pay_from), pay_to: getDateFormatYMD(values?.pay_to) }))
            setTimeout(() => {
                dispatch(getEmployerPayrolls({
                    "status": "All"
                }));
                navigate("/employer/payroll");
            }, 500);
        }
    });

    const selectedOptionsUpdate = (options) => {
        validation.setFieldValue('employement_type', options.map((opt) => opt.value).toString())
        setSelectedOptions(options.map((opt) => opt.value));
    }

    const routeTolIsting = () => {
        navigate("/employer/payroll");
    }

    let title = editId ? 'Add Payroll' : 'Add Payroll';
    const BreadcrumbData = [
        { name: 'Dashboard', url: '/attorney/dashboard' },
        { name: title, url: '#' }
    ]
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Payroll</h4>

                                </CardHeader>

                                <CardBody className="p-4">
                                    <Form className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            if (_.size(validation.errors) > 0) {
                                                const errorObj = { "response": { "status": "400", "data": { "message": "Error - Please fill required form fields" } } }
                                                dispatch(setAlertMessage({ message: errorObj, type: "danger" }));
                                              }
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        {/* import TextualInputs */}
                                        <div className="mt-4 mt-lg-0">

                                            <Row>


                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label htmlFor="pay_from" className="form-Label">Payroll Period From *</Label>
                                                        <ReactDatePicker
                                                            showYearDropdown={true}
                                                            showIcon
                                                            name="pay_from"
                                                            placeholderText="MM/dd/yyyy"
                                                            id="pay_from"
                                                            className={`form-control ${validation.touched.pay_from && validation.errors.pay_from ? 'is-invalid' : ''}`}
                                                            dateFormat="MM/dd/yyyy"
                                                            onChange={(date) => {
                                                                validation.setFieldValue('pay_from', date)
                                                            }
                                                            }
                                                            selected={validation?.values?.pay_from ? new Date(validation.values.pay_from) : ''}
                                                        />



                                                        {validation.touched.pay_from && validation.errors.pay_from ? (
                                                            <FormFeedback type="invalid">{validation.errors.pay_from}</FormFeedback>
                                                        ) : null}

                                                    </div>

                                                </Col>


                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label htmlFor="pay_to" className="form-Label">Payroll Period To *</Label>
                                                        <ReactDatePicker
                                                            showYearDropdown={true}
                                                            showIcon
                                                            name="pay_to"
                                                            id="pay_to"
                                                            placeholderText="MM/dd/yyyy"
                                                            className={`form-control ${validation.touched.pay_to && validation.errors.pay_to ? 'is-invalid' : ''}`}
                                                            dateFormat="MM/dd/yyyy"
                                                            onChange={(date) => {
                                                                validation.setFieldValue('pay_to', date)
                                                            }
                                                            }
                                                            selected={validation?.values?.pay_to ? new Date(validation.values.pay_to) : ''}
                                                        />


                                                        {validation.touched.pay_to && validation.errors.pay_to ? (
                                                            <FormFeedback type="invalid">{validation.errors.pay_to}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>

                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label className="form-Label">Employment Type *</Label>
                                                        <Select
                                                            defaultValue={[]}
                                                            isMulti
                                                            // className={selectedOptions.length === 0 ? 'is-invalid':''}
                                                            name="employement_type"
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            onChange={(options) => {
                                                                if (Array.isArray(options)) {
                                                                    selectedOptionsUpdate(options)
                                                                }
                                                            }}
                                                            options={allOptions}
                                                            components={{
                                                                Option: InputOption
                                                            }}
                                                            invalid={
                                                                validation.touched.employement_type && validation.errors.employement_type ? true : false
                                                              }
                                                            classNamePrefix={`${validation.touched.employement_type && validation.errors.employement_type ? 'is-invalid-select2 select2-selection' : 'select2-selection'}`}
                                                        />
                                                        {validation.touched.employement_type && validation.errors.employement_type ? (
                                                            <FormFeedback type="invalid">{validation.errors.employement_type}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>
                                                <Col md="12">
                                                    <div className="mb-6">
                                                        <Label htmlFor="example-text-input" className="form-Label">Description *</Label>
                                                        <Input
                                                            rows={5}
                                                            type="textarea"
                                                            id="payroll_description"
                                                            name="payroll_description"
                                                            className={`form-control ${validation.touched.payroll_description && validation.errors.payroll_description ? 'is-invalid' : ''}`}
                                                            value={validation.values.payroll_description || ""}
                                                            onChange={(e) => {
                                                                validation.handleChange(e);
                                                            }}
                                                        ></Input>
                                                        {validation.touched.payroll_description && validation.errors.payroll_description ? (
                                                            <FormFeedback type="invalid">{validation.errors.payroll_description}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-input"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                </Label>
                                                <Col md={12}>
                                                    <div className="mt-3" style={{ float: "right" }}>
                                                        <Button type="reset" color="secondary" onClick={() => navigate(-1)}>
                                                            Cancel
                                                        </Button>
                                                        {" "}
                                                        <Button type="submit" color="primary" className="ms-1">
                                                            Save
                                                        </Button>
                                                    </div>

                                                </Col>
                                            </Row>


                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment >
    );
};

export default CreatePayroll;