
export const GENERATE_FORM = "GENERATE_FORM"
export const GENERATE_FORM_SUCCESS = "GENERATE_FORM_SUCCESS"
export const GENERATE_FORM_FAIL = "GENERATE_FORM_FAIL"

/* EMPLOYERQNNRTYPES */
export const GET_EMPLOYERCASES = "GET_EMPLOYERCASES"
export const GET_EMPLOYERCASES_SUCCESS = "GET_EMPLOYERCASES_SUCCESS"
export const GET_EMPLOYERCASES_FAIL = "GET_EMPLOYERCASES_FAIL"

export const GET_EMPLOYER_PAF = "GET_EMPLOYER_PAF"
export const GET_EMPLOYER_PAF_SUCCESS = "GET_EMPLOYER_PAF_SUCCESS"
export const GET_EMPLOYER_PAF_FAIL = "GET_EMPLOYER_PAF_FAIL"

export const GET_EMPLOYER_PAF_FORM = "GET_EMPLOYER_PAF_FORM"
export const GET_EMPLOYER_PAF_FORM_SUCCESS = "GET_EMPLOYER_PAF_FORM_SUCCESS"
export const GET_EMPLOYER_PAF_FORM_FAIL = "GET_EMPLOYER_PAF_FORM_FAIL"

export const GET_EMPLOYER_PAF_FORMS = "GET_EMPLOYER_PAF_FORMS"
export const GET_EMPLOYER_PAF_FORMS_SUCCESS = "GET_EMPLOYER_PAF_FORMS_SUCCESS"
export const GET_EMPLOYER_PAF_FORMS_FAIL = "GET_EMPLOYER_PAF_FORMS_FAIL"

export const UPLOAD_EMPLOYER_LCA = "UPLOAD_EMPLOYER_LCA"
export const UPLOAD_EMPLOYER_LCA_SUCCESS = "UPLOAD_EMPLOYER_LCA_SUCCESS"
export const UPLOAD_EMPLOYER_LCA_FAIL = "UPLOAD_EMPLOYER_LCA_FAIL"

export const GET_PAF_TEMPLATE_VARIABLES = "GET_PAF_TEMPLATE_VARIABLES"
export const GET_PAF_TEMPLATE_VARIABLES_SUCCESS = "GET_PAF_TEMPLATE_VARIABLES_SUCCESS"
export const GET_PAF_TEMPLATE_VARIABLES_FAIL = "GET_PAF_TEMPLATE_VARIABLES_FAIL"

export const UPDATE_PAF_TEMPLATE = "UPDATE_PAF_TEMPLATE"
export const UPDATE_PAF_TEMPLATE_SUCCESS = "UPDATE_PAF_TEMPLATE_SUCCESS"
export const UPDATE_PAF_TEMPLATE_FAIL = "UPDATE_PAF_TEMPLATE_FAIL"

export const UPDATE_EMPLOYER_PAF_FORM = "UPDATE_EMPLOYER_PAF_FORM"
export const UPDATE_EMPLOYER_PAF_FORM_SUCCESS = "UPDATE_EMPLOYER_PAF_FORM_SUCCESS"
export const UPDATE_EMPLOYER_PAF_FORM_FAIL = "UPDATE_EMPLOYER_PAF_FORM_FAIL"

export const GET_EMPLOYER_PAFINFO = "GET_EMPLOYER_PAFINFO"
export const GET_EMPLOYER_PAFINFO_SUCCESS = "GET_EMPLOYER_PAFINFO_SUCCESS"
export const GET_EMPLOYER_PAFINFO_FAIL = "GET_EMPLOYER_PAFINFO_FAIL"

export const SEND_PAF_EMPLOYEE_NOTIFICATION = "SEND_PAF_EMPLOYEE_NOTIFICATION"
export const SEND_PAF_EMPLOYEE_NOTIFICATION_SUCCESS = "SEND_PAF_EMPLOYEE_NOTIFICATION_SUCCESS"
export const SEND_PAF_EMPLOYEE_NOTIFICATION_FAIL = "SEND_PAF_EMPLOYEE_NOTIFICATION_FAIL"

export const CREATE_PAF_BUNDLE = "CREATE_PAF_BUNDLE"
export const CREATE_PAF_BUNDLE_SUCCESS = "CREATE_PAF_BUNDLE_SUCCESS"
export const CREATE_PAF_BUNDLE_FAIL = "CREATE_PAF_BUNDLE_FAIL"

export const UPDATE_EMPLOYER_PAFINFO = "UPDATE_EMPLOYER_PAFINFO"
export const UPDATE_EMPLOYER_PAFINFO_SUCCESS = "UPDATE_EMPLOYER_PAFINFO_SUCCESS"
export const UPDATE_EMPLOYER_PAFINFO_FAIL = "UPDATE_EMPLOYER_PAFINFO_FAIL"

export const GET_EMPLOYERCASE_DETAIL = "GET_EMPLOYERCASE_DETAIL"
export const GET_EMPLOYERCASE_DETAIL_SUCCESS = "GET_EMPLOYERCASE_DETAIL_SUCCESS"
export const GET_EMPLOYERCASE_DETAIL_FAIL = "GET_EMPLOYERCASE_DETAIL_FAIL"

export const ADD_EMPLOYERCASE_DETAIL = "ADD_EMPLOYERCASE_DETAIL"
export const ADD_EMPLOYERCASE_DETAIL_SUCCESS = "ADD_EMPLOYERCASE_DETAIL_SUCCESS"
export const ADD_EMPLOYERCASE_DETAIL_FAIL = "ADD_EMPLOYERCASE_DETAIL_FAIL"

export const DELETE_EMPLOYERCASE_DETAIL = "DELETE_EMPLOYERCASE_DETAIL"
export const DELETE_EMPLOYERCASE_DETAIL_SUCCESS = "DELETE_EMPLOYERCASE_DETAIL_SUCCESS"
export const DELETE_EMPLOYERCASE_DETAIL_FAIL = "DELETE_EMPLOYERCASE_DETAIL_FAIL"

export const UPDATE_EMPLOYERCASE_DETAIL = "UPDATE_EMPLOYERCASE_DETAIL"
export const UPDATE_EMPLOYERCASE_DETAIL_SUCCESS = "UPDATE_EMPLOYERCASE_DETAIL_SUCCESS"
export const UPDATE_EMPLOYERCASE_DETAIL_FAIL = "UPDATE_EMPLOYERCASE_DETAIL_FAIL"


export const GET_EMPLOYERCASETYPES = "GET_EMPLOYERCASETYPES"
export const GET_EMPLOYERCASETYPES_SUCCESS = "GET_EMPLOYERCASETYPES_SUCCESS"
export const GET_EMPLOYERCASETYPES_FAIL = "GET_EMPLOYERCASETYPES_FAIL"

export const GET_EMPLOYERQNNRTYPES = "GET_EMPLOYERQNNRTYPES"
export const GET_EMPLOYERQNNRTYPES_SUCCESS = "GET_EMPLOYERQNNRTYPES_SUCCESS"
export const GET_EMPLOYERQNNRTYPES_FAIL = "GET_EMPLOYERQNNRTYPES_FAIL"


