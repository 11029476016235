import {
  SHOW_ALERT_FLAG,
  DISABLE_ALERT_FLAG
} from "./actionTypes";

const initialState = {
  alertInfo:{error:"",showSuccess:false,showMessage:'',loading:false}
};

const AlertMessage = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT_FLAG:
      state = {
        ...state,
        alertInfo:action.payload
      };
      break;
    case DISABLE_ALERT_FLAG:
      state = {
        ...state,
        alertInfo:{error:"",showSuccess:false,showMessage:'',loading:false}
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AlertMessage;
