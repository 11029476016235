import React from "react";

import EmployeeTimesheetView from "../Timesheets/TimesheetView";
import EmployeeBasicSuite from "../Basic";

const MainTimesheetViewComponent = () => {

  const getLoggedInPlanType = () => {
    return localStorage.getItem("plan_type");
  }

  if (getLoggedInPlanType() == 1) {
    return <EmployeeTimesheetView />
  } else {
    return <EmployeeBasicSuite componentName="Timesheet View" />
  }
};

export default MainTimesheetViewComponent;
