import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Card,
    CardBody,
    CardText,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Table,
} from "reactstrap";

import { getEmployeeNotifications, readNotification } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
//Import Breadcrumb

import classnames from "classnames";
import DatatableContainer from "../../../components/Common/DatatableContainer";
import {
    NotificationName
} from "./colListCol";


const Notifications = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL

    const [activeTab, setactiveTab] = useState("1");
    const [activeTab1, setactiveTab1] = useState("5");
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [username, setusername] = useState("Attorney");
    const { employeeNotifications } = useSelector((state) => ({
        employeeNotifications: state.EmployeeCases.employeeNotifications?.notifications || [],
    }));

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            setusername(obj.data.name);
        }
    }, []);

    useEffect(() => {
        dispatch(getEmployeeNotifications());
    }, [dispatch]);


    const toggle1 = (tab) => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };
    let title = 'Dashboard';
    const BreadcrumbData = [
        { name: 'Dashboard', url: '/employee/dashboard' },
        { name: title, url: '#' }
    ];


    const { user_type } = useSelector((state) => ({
        user_type: state.login.user_type || localStorage.getItem('user_type'),
    }));

    const onViewNotification = (data) => {
        if (!data.is_read) {
            dispatch(readNotification({
                "id": data.id,
                "case_id": data.case_id,
                "type": data.notification_type,
            }));
        }
        setTimeout(() => {
            dispatch(getEmployeeNotifications());
            if (!data.link_status) {
                if (user_type == 'employee') {
                    navigate(`/${user_type}/questionnaire?caseId=${data.case_id}`);
                } else {
                    navigate(`/${user_type}/view-case?caseId=${data.case_id}`);
                }
            }
        }, 100)
    }

    const columns = useMemo(
        () => [
            {
                Header: "Notification",
                disableFilters: true,
                Cell: (cellProps) => {
                    return <NotificationName
                        cellProps={cellProps}
                        onViewNotification={onViewNotification}
                    />;
                },
            },

        ],
        []
    );

    return (
        <React.Fragment>
            <Col xl={6}>
                <Card>

                    <CardBody>
                        <Nav pills className="navtab-bg nav-justified">
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: activeTab1 === "5",
                                    })}
                                    onClick={() => {
                                        toggle1("5");
                                    }}
                                >
                                    Notifications
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                        active: activeTab1 === "6",
                    })}
                    onClick={() => {
                        toggle1("6");
                    }}
                >
                    LCA
                </NavLink>
            </NavItem> */}

                        </Nav>
                        <TabContent activeTab={activeTab1} className="p-3 text-muted">
                            <TabPane tabId="5">
                                <Row>
                                    <Col sm="12">
                                        <CardText className="mb-0">
                                            <DatatableContainer
                                                isSelectRow={false}
                                                columns={columns}
                                                data={employeeNotifications}
                                                isGlobalFilter={false}
                                                isAddAttorneys={false}
                                                customPageSize={10}
                                                isAttorneyFilter={false}
                                            />
                                        </CardText>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="6">
                                <Row>
                                    <Col sm="12">
                                        <CardText className="mb-0">
                                            <div className="table-responsive">
                                                <Table className="table table-striped mb-0">

                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">1</th>
                                                            <td><span style={{ fontWeight: "bold", width: "100%" }}>
                                                                Exasoft Inc : Exasoft Inc has completed LCA Questionnaire
                                                            </span>
                                                                <div className="notificationdate">
                                                                    <i className="fa fa-calendar" aria-hidden="true" /> Wed 08 Mar, 2023
                                                                </div></td>
                                                            <td>DONE</td>
                                                            <td>Delete</td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row">2</th>
                                                            <td><span style={{ fontWeight: "bold", width: "100%" }}>
                                                                Exasoft Inc : Exasoft Inc has completed LCA Questionnaire
                                                            </span>
                                                                <div className="notificationdate">
                                                                    <i className="fa fa-calendar" aria-hidden="true" /> Wed 08 Mar, 2023
                                                                </div></td>
                                                            <td>DONE</td>
                                                            <td>Delete</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">3</th>
                                                            <td><span style={{ fontWeight: "bold", width: "100%" }}>
                                                                Exasoft Inc : Exasoft Inc has completed LCA Questionnaire
                                                            </span>
                                                                <div className="notificationdate">
                                                                    <i className="fa fa-calendar" aria-hidden="true" /> Wed 08 Mar, 2023
                                                                </div></td>
                                                            <td>DONE</td>
                                                            <td>Delete</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardText>
                                    </Col>
                                </Row>
                            </TabPane>

                        </TabContent>
                    </CardBody>
                </Card>



            </Col>
        </React.Fragment>
    );
};

export default Notifications;
