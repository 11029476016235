import {
  GET_ATTORNEY_COMPANY,
  GET_ATTORNEY_COMPANY_FAIL,
  GET_ATTORNEY_COMPANY_SUCCESS,
  GET_ATTORNEY_COMPANY_DETAIL,
  GET_ATTORNEY_COMPANY_DETAIL_FAIL,
  GET_ATTORNEY_COMPANY_DETAIL_SUCCESS,
  UPDATE_ATTORNEY_COMPANY_DETAIL_FAIL,
  UPDATE_ATTORNEY_COMPANY_DETAIL_SUCCESS,
  UPDATE_ATTORNEY_COMPANY_DETAIL,
  DELETE_ATTORNEY_COMPANY_DETAIL_FAIL,
  DELETE_ATTORNEY_COMPANY_DETAIL_SUCCESS,
  DELETE_ATTORNEY_COMPANY_DETAIL,
  ADD_ATTORNEY_COMPANY_DETAIL_SUCCESS,
  ADD_ATTORNEY_COMPANY_DETAIL,
  ADD_ATTORNEY_COMPANY_DETAIL_FAIL,
  GET_ATTORNEY_ELIGIBILITY_STATUS,
  GET_ATTORNEY_ELIGIBILITY_STATUS_SUCCESS,
  GET_ATTORNEY_ELIGIBILITY_STATUS_FAIL

} from "./actionTypes"

export const getAttorneyCompany = () => ({
  type: GET_ATTORNEY_COMPANY,
})

export const getAttorneyCompanySuccess = Company => ({
  type: GET_ATTORNEY_COMPANY_SUCCESS,
  payload: Company,
})

export const getAttorneyCompanyFail = error => ({
  type: GET_ATTORNEY_COMPANY_FAIL,
  payload: error,
})

export const getAttorneyCompanyDetail = CompanyId => ({
  type: GET_ATTORNEY_COMPANY_DETAIL,
  CompanyId,
})

export const getAttorneyCompanyDetailSuccess = Company => ({
  type: GET_ATTORNEY_COMPANY_DETAIL_SUCCESS,
  payload: Company,
})

export const getAttorneyCompanyDetailFail = error => ({
  type: GET_ATTORNEY_COMPANY_DETAIL_FAIL,
  payload: error,
})

export const  addAttorneyCompanyDetail = (data) => ({
  type: ADD_ATTORNEY_COMPANY_DETAIL,
  payload: data
})

export const addAttorneyCompanydetailSuccess = (data) => ({
  type: ADD_ATTORNEY_COMPANY_DETAIL_SUCCESS,
  payload: data
})

export const addAttorneyCompanydetailFail = (error) => ({
  type: ADD_ATTORNEY_COMPANY_DETAIL_FAIL,
  payload: error
})

export const AttorneyCompanyDelete = (data) => ({
  type: DELETE_ATTORNEY_COMPANY_DETAIL,
  payload: data
})

export const AttorneyCompanyDeleteSuccess = (data) => ({
  type: DELETE_ATTORNEY_COMPANY_DETAIL_SUCCESS,
  payload: data
})

export const AttorneyCompanyDeleteFail = (error) => ({
  type: DELETE_ATTORNEY_COMPANY_DETAIL_FAIL,
  payload: error
})

export const AttorneyCompanyUpdate = (data) => ({
  type: UPDATE_ATTORNEY_COMPANY_DETAIL,
  payload: data
})

export const AttorneyCompanyUpdateSuccess = (data) => ({
  type: UPDATE_ATTORNEY_COMPANY_DETAIL_SUCCESS,
  payload: data
})

export const AttorneyCompanyUpdateFail = (error) => ({
  type: UPDATE_ATTORNEY_COMPANY_DETAIL_FAIL,
  payload: error
})

export const getAttorneyEligibileStatus = () => ({
  type: GET_ATTORNEY_ELIGIBILITY_STATUS,
})

export const getAttorneyEligibileStatusSuccess = Company => ({
  type: GET_ATTORNEY_ELIGIBILITY_STATUS_SUCCESS,
  payload: Company,
})

export const getAttorneyEligibileStatusFail = error => ({
  type: GET_ATTORNEY_ELIGIBILITY_STATUS_FAIL,
  payload: error,
})
