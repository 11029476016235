import {
  GET_ATTORNEY_CONTACTS,
  GET_ATTORNEY_CONTACTS_FAIL,
  GET_ATTORNEY_CONTACTS_SUCCESS,
  GET_ATTORNEY_CONTACT_DETAIL,
  GET_ATTORNEY_CONTACT_DETAIL_FAIL,
  GET_ATTORNEY_CONTACT_DETAIL_SUCCESS,
  UPDATE_ATTORNEY_CONTACT_DETAIL_FAIL,
  UPDATE_ATTORNEY_CONTACT_DETAIL_SUCCESS,
  UPDATE_ATTORNEY_CONTACT_DETAIL,
  DELETE_ATTORNEY_CONTACT_DETAIL_FAIL,
  DELETE_ATTORNEY_CONTACT_DETAIL_SUCCESS,
  DELETE_ATTORNEY_CONTACT_DETAIL,
  ADD_ATTORNEY_CONTACT_DETAIL_SUCCESS,
  ADD_ATTORNEY_CONTACT_DETAIL,
  ADD_ATTORNEY_CONTACT_DETAIL_FAIL,
  VALIDATE_COMPANY_EMAILID,
  VALIDATE_COMPANY_EMAILID_SUCCESS,
  VALIDATE_COMPANY_EMAILID_FAIL

} from "./actionTypes"

export const getAttorneyContacts = (data) => ({
  type: GET_ATTORNEY_CONTACTS,
  payload: data
})

export const getAttorneyContactsSuccess = Contacts => ({
  type: GET_ATTORNEY_CONTACTS_SUCCESS,
  payload: Contacts,
})

export const getAttorneyContactsFail = error => ({
  type: GET_ATTORNEY_CONTACTS_FAIL,
  payload: error,
})

export const getAttorneyContactDetail = ContactId => ({
  type: GET_ATTORNEY_CONTACT_DETAIL,
  ContactId,
})

export const getAttorneyContactDetailSuccess = Contacts => ({
  type: GET_ATTORNEY_CONTACT_DETAIL_SUCCESS,
  payload: Contacts,
})

export const getAttorneyContactDetailFail = error => ({
  type: GET_ATTORNEY_CONTACT_DETAIL_FAIL,
  payload: error,
})

export const  addAttorneyContactDetail = (data) => ({
  type: ADD_ATTORNEY_CONTACT_DETAIL,
  payload: data
})

export const addAttorneyContactDetailSuccess = (data) => ({
  type: ADD_ATTORNEY_CONTACT_DETAIL_SUCCESS,
  payload: data
})

export const addAttorneyContactDetailFail = (error) => ({
  type: ADD_ATTORNEY_CONTACT_DETAIL_FAIL,
  payload: error
})

export const AttorneyContactDelete = (data) => ({
  type: DELETE_ATTORNEY_CONTACT_DETAIL,
  payload: data
})

export const AttorneyContactDeleteSuccess = (data) => ({
  type: DELETE_ATTORNEY_CONTACT_DETAIL_SUCCESS,
  payload: data
})

export const AttorneyContactDeleteFail = (error) => ({
  type: DELETE_ATTORNEY_CONTACT_DETAIL_FAIL,
  payload: error
})

export const AttorneyContactUpdate = (data) => ({
  type: UPDATE_ATTORNEY_CONTACT_DETAIL,
  payload: data
})

export const AttorneyContactUpdateSuccess = (data) => ({
  type: UPDATE_ATTORNEY_CONTACT_DETAIL_SUCCESS,
  payload: data
})

export const AttorneyContactUpdateFail = (error) => ({
  type: UPDATE_ATTORNEY_CONTACT_DETAIL_FAIL,
  payload: error
})

export const validateDuplicateCompEmailId = (data) => ({
  type: VALIDATE_COMPANY_EMAILID,
  payload: data
})

export const validateDuplicateCompEmailIdSuccess = (data) => ({
  type: VALIDATE_COMPANY_EMAILID_SUCCESS,
  payload: data
})

export const validateDuplicateCompEmailIdFail = (error) => ({
  type: VALIDATE_COMPANY_EMAILID_FAIL,
  payload: error
})


