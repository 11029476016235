import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_ATTORNEY_MASTER_DOCS,
  GET_ATTORNEY_MASTER_DOCS_FAIL,
  GET_ATTORNEY_MASTER_DOCS_SUCCESS,
  UPDATE_ATTORNEY_MASTER_DOCS_FAIL,
  UPDATE_ATTORNEY_MASTER_DOCS_SUCCESS,
  UPDATE_ATTORNEY_MASTER_DOCS,
  DELETE_ATTORNEY_MASTER_DOCS,
  DELETE_ATTORNEY_MASTER_DOCS_SUCCESS,
  DELETE_ATTORNEY_MASTER_DOCS_FAIL,
} from "./actionTypes";

//Include Both Helper File with needed methods
import { getAttorneyDocuments, updateAttorneyDocuments, deleteAttorneyDocuments } from "../../../helpers/apibackend_helper"
import { deleteAttorneyMasterDocsFail, deleteAttorneyMasterDocsSuccess, getAttorneyMasterDocsFail, getAttorneyMasterDocsSuccess, updateAttorneyMasterDocs, updateAttorneyMasterDocsFail, updateAttorneyMasterDocsSuccess } from "./actions";
import { setAlertMessage } from "../../common/actions";
import { changePreloader } from "../../../store/actions"


function* fetchAttorneyMasterDocs({ payload: docs }) {
  try {
    const response = yield call(getAttorneyDocuments, docs)
    yield put(getAttorneyMasterDocsSuccess(response.data))
  } catch (error) {
    yield put(getAttorneyMasterDocsFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}


function* onUpdateAttorneyMasterDocs({ payload: data }) {
  try {
    const response = yield call(updateAttorneyDocuments, data);
    yield put(updateAttorneyMasterDocsSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(updateAttorneyMasterDocsFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteAttorneyMasterDocs({ payload: data }) {
  try {
    const response = yield call(deleteAttorneyDocuments, data);
    yield put(deleteAttorneyMasterDocsSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(deleteAttorneyMasterDocsFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* AttorneyMasterDocsSaga() {
  yield takeEvery(GET_ATTORNEY_MASTER_DOCS, fetchAttorneyMasterDocs)
  yield takeEvery(UPDATE_ATTORNEY_MASTER_DOCS, onUpdateAttorneyMasterDocs)
  yield takeEvery(DELETE_ATTORNEY_MASTER_DOCS, onDeleteAttorneyMasterDocs)
}

export default AttorneyMasterDocsSaga
