import React from "react";
import { Link } from "react-router-dom"
import moment from "moment";


const Id = (cell) => {
  return cell.value ? cell.value : '';
};

const LinkCell = (cell) => {
  return (
    <Link className="text-dark fw-medium" to="#">{cell.value}</Link>
  );
};

const Date = (cell) => {
  return cell.value ? moment(cell.value).format("MMM DD, YYYY") : '';
};

const CaseTypeName = (cell) => {
  return cell.value==1 ? 'H1B' : 'Green Card';
};

const CommonCell = (cell) => {
  return cell.value ? cell.value : '';
};

export {
  CommonCell,
  Date,
  CaseTypeName
  
};