import {
  UPDATE_NOTIFICATION_EMAILS,
  UPDATE_NOTIFICATION_EMAILS_FAIL,
  UPDATE_NOTIFICATION_EMAILS_SUCCESS,

  UPDATE_LCA_EMPLOYEE_ACCESS,
  UPDATE_LCA_EMPLOYEE_ACCESS_FAIL,
  UPDATE_LCA_EMPLOYEE_ACCESS_SUCCESS,

  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD,

  EMPLOYEE_RESEND_PASSWORD_FAIL,
  EMPLOYEE_RESEND_PASSWORD_SUCCESS,
  EMPLOYEE_RESEND_PASSWORD,

  EMPLOYER_RESEND_PASSWORD_FAIL,
  EMPLOYER_RESEND_PASSWORD_SUCCESS,
  EMPLOYER_RESEND_PASSWORD,

  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD,

  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,

  UPDATE_QUESTIONAIRE_REVIEW_SUCCESS,
  UPDATE_QUESTIONAIRE_REVIEW,
  UPDATE_QUESTIONAIRE_REVIEW_FAIL

} from "./actionTypes"

export const updateNotificationEmails = (data) => ({
  type: UPDATE_NOTIFICATION_EMAILS,
  payload: data
})

export const updateNotificationEmailsSuccess = users => ({
  type: UPDATE_NOTIFICATION_EMAILS_SUCCESS,
  payload: users,
})

export const updateNotificationEmailsFail = error => ({
  type: UPDATE_NOTIFICATION_EMAILS_FAIL,
  payload: error,
})

export const updateLcaEmployeeAccess = userId => ({
  type: UPDATE_LCA_EMPLOYEE_ACCESS,
  userId,
})

export const updateLcaEmployeeAccessSuccess = users => ({
  type: UPDATE_LCA_EMPLOYEE_ACCESS_SUCCESS,
  payload: users,
})

export const updateLcaEmployeeAccessFail = error => ({
  type: UPDATE_LCA_EMPLOYEE_ACCESS_FAIL,
  payload: error,
})

export const updateQuestionaireReview = (data) => ({
  type: UPDATE_QUESTIONAIRE_REVIEW,
  payload: data
})

export const updateQuestionaireReviewSuccess = (data) => ({
  type: UPDATE_QUESTIONAIRE_REVIEW_SUCCESS,
  payload: data
})

export const updateQuestionaireReviewFail = (error) => ({
  type: UPDATE_QUESTIONAIRE_REVIEW_FAIL,
  payload: error
})

export const changePassword = (data) => ({
  type: CHANGE_PASSWORD,
  payload: data
})

export const changePasswordSuccess = (data) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: data
})

export const changePasswordFail = (error) => ({
  type: CHANGE_PASSWORD_FAIL,
  payload: error
})

export const resetPassword = (data) => ({
  type: RESET_PASSWORD,
  payload: data
})

export const resetPasswordSuccess = (data) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: data
})

export const resetPasswordFail = (error) => ({
  type: RESET_PASSWORD_FAIL,
  payload: error
})

export const forgotPassword = (data) => ({
  type: FORGOT_PASSWORD,
  payload: data
})

export const forgotPasswordSuccess = (data) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: data
})

export const forgotPasswordFail = (error) => ({
  type: FORGOT_PASSWORD_FAIL,
  payload: error
})

export const employerResendPassword = (data) => ({
  type: EMPLOYER_RESEND_PASSWORD,
  payload: data
})

export const employerresendPasswordSuccess = (data) => ({
  type: EMPLOYER_RESEND_PASSWORD_SUCCESS,
  payload: data
})

export const employerresendPasswordFail = (error) => ({
  type: EMPLOYER_RESEND_PASSWORD_FAIL,
  payload: error
})

export const employeeResendPassword = (data) => ({
  type: EMPLOYEE_RESEND_PASSWORD,
  payload: data
})

export const employeeResendPasswordSuccess = (data) => ({
  type: EMPLOYEE_RESEND_PASSWORD_SUCCESS,
  payload: data
})

export const employeeResendPasswordFail = (error) => ({
  type: EMPLOYEE_RESEND_PASSWORD_FAIL,
  payload: error
})
