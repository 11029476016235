import React, { useEffect, useState } from "react";
import {
    Card, CardBody, CardHeader, Col, Form, Label, Input,
    FormGroup, FormFeedback, Row,
    Alert, Container
} from "reactstrap";
import { Link } from "react-router-dom";

// import images
import logo from "../../assets/images/logo-sm.png";
import CarouselPage from "../AuthenticationInner/CarouselPage";

import { useLocation, useNavigate } from 'react-router-dom';

// Formik validation
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { getProfile, resetPassword } from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";

const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const emailId = queryParams.get('email');
    const token = queryParams.get('token');

    const [initialValues, setInitialValues] = useState(false);

    const [currentPasswordShow, setCurrentPasswordShow] = useState(false);
    const [newPasswordShow, setNewPasswordShow] = useState(false);
    const [confirmNewPasswordShow, setConfirmNewPasswordShow] = useState(false);

    const handleActionSubmit = (e) => {
        validation.handleSubmit();
    };

    const iniValues = {
        email: emailId,
        token: token,
        password: "",
        password_confirmation: "",
    };
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: iniValues,
        validationSchema: Yup.object({
            email: Yup.string().required("Please enter email address").nullable(),
            password: Yup.string().required("Please enter new password")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Password must contain minimum 8 characters, one uppercase, one lowercase, one number and one special character"
              )
          .nullable(),
            password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required("Please enter confirmation password").nullable(),
        }),
        onSubmit: (values) => {
            dispatch(resetPassword(values));
            setApiSuccess(true);
        }
    });
    const [errorMessage,setErrorMessage] = useState('');
    const [apiSuccess, setApiSuccess] = useState(false);


    const { resetPasswordErrorResponse, resetpasswordResponse } = useSelector(state => ({
        resetPasswordErrorResponse: state.CommonProfile.resetPasswordErrorResponse,
        resetpasswordResponse: state.CommonProfile.resetpasswordResponse,
    }))
    useEffect(() => {
        if (!_.isEmpty(resetPasswordErrorResponse) && apiSuccess) {
            setErrorMessage(resetPasswordErrorResponse?.response?.data?.message)
            setApiSuccess(false);
         }
      }, [resetPasswordErrorResponse]);


    useEffect(() => {
        if (!_.isEmpty(resetpasswordResponse) && apiSuccess) {
            setApiSuccess(false);
            setTimeout(() => {
                navigate("/login");
            }, 2000);
         }
      }, [resetpasswordResponse]);
      

    let title = 'Reset Password';
    //meta title
    document.title = title + " | Immidock ";
    var userType = window.localStorage.getItem("user_type")
    const BreadcrumbData = [
        { name: 'Dashboard', url: '/' + userType + '/dashboard' },
        { name: title, url: '#' }
    ]
    return (
        <React.Fragment>
            <>
                <React.Fragment>
                    <div className="auth-page">
                        <Row className="g-0">
                            <Col lg={4} md={5} className="col-xxl-4">
                                <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                    <div className="w-100">
                                        <div className="d-flex flex-column h-100">
                                            <div className="mb-4 mb-md-5 text-center">
                                                <Link to="/dashboard" className="d-block auth-logo">
                                                    <img src={logo} alt="" height="28" /> <span className="logo-txt">Immidock</span>
                                                </Link>
                                            </div>
                                            <div className="auth-content my-auto">
                                                <div className="text-center">
                                                    <h5 className="mb-0">Reset Password</h5>
                                                    {/* <p className="text-muted mt-2">Reset Password with Immidock.</p> */}
                                                </div>

                                                {errorMessage && errorMessage ? (
                                                    <Alert color="danger" style={{ marginTop: "13px" }}>
                                                        {errorMessage}
                                                    </Alert>
                                                ) : null}
                                                {/* {resetpasswordResponse ? (
                                                    <Alert color="success" style={{ marginTop: "13px" }}>
                                                        {resetpasswordResponse}
                                                    </Alert>
                                                ) : null} */}


                                                <FormikProvider value={validation}>
                                                    <Form
                                                        className="needs-validation custom-form mt-4"
                                                        onSubmit={(values) => {
                                                            values.preventDefault();
                                                            // console.log(validate(values));
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                    >

                                                        <div className="mb-3">

                                                            <Label htmlFor="email">Email *</Label>
                                                            <div className="input-group auth-pass-inputgroup">
                                                                <Input
                                                                    name="email"
                                                                    placeholder="Email"
                                                                    type={"text"}
                                                                    readOnly={true}
                                                                    className="form-control"
                                                                    id="email"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.email || ""}
                                                                    invalid={
                                                                        validation.touched.email && validation.errors.email ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.email && validation.errors.email ? (
                                                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">

                                                            <Label htmlFor="password">New Password *</Label>
                                                            <div className="input-group auth-pass-inputgroup">
                                                                <Input
                                                                    name="password"
                                                                    placeholder="New Password"
                                                                    type={newPasswordShow ? "text" : "password"}
                                                                    className="form-control"
                                                                    id="password"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.password || ""}
                                                                    invalid={
                                                                        validation.touched.password && validation.errors.password ? true : false
                                                                    }
                                                                />
                                                                <button onClick={() => setNewPasswordShow(!newPasswordShow)} className="btn btn-light shadow-none ms-0" type="button" id="new-password-addon"><i className="mdi mdi-eye-outline"></i></button>

                                                                {validation.touched.password && validation.errors.password ? (
                                                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">

                                                            <Label htmlFor="password_confirmation">Confirm New Password *</Label>

                                                            <div className="input-group auth-pass-inputgroup">
                                                                <Input
                                                                    name="password_confirmation"
                                                                    placeholder="Confirm New Password"
                                                                    type={confirmNewPasswordShow ? "text" : "password"}
                                                                    className="form-control"
                                                                    id="password_confirmation"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.password_confirmation || ""}
                                                                    invalid={
                                                                        validation.touched.password_confirmation && validation.errors.password_confirmation ? true : false
                                                                    }
                                                                />
                                                                <button onClick={() => setConfirmNewPasswordShow(!confirmNewPasswordShow)} className="btn btn-light shadow-none ms-0" type="button" id="confirm-password-addon"><i className="mdi mdi-eye-outline"></i></button>

                                                                {validation.touched.password_confirmation && validation.errors.password_confirmation ? (
                                                                    <FormFeedback type="invalid">{validation.errors.password_confirmation}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <Row className="mb-3">
                                                            <Col className="text-end">
                                                                <button
                                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                                    type="submit"
                                                                >
                                                                    Reset
                                                                </button>
                                                            </Col>
                                                        </Row>

                                                    </Form>
                                                </FormikProvider>
                                                <div className="mt-5 text-center">
                                                    <p className="text-muted mb-0">Remember It ?  <a href="/login"
                                                        className="text-primary fw-semibold"> Sign In </a> </p>
                                                </div>
                                            </div>
                                            <div className="mt-4 mt-md-5 text-center">
                                                <p className="mb-0">© {new Date().getFullYear()} Immidock.
                                                    {/* Crafted with <i className="mdi mdi-heart text-danger"></i> by Immidock */}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <CarouselPage />
                        </Row>
                    </div>
                </React.Fragment>

            </>
        </React.Fragment >
    );
};

export default ResetPassword;