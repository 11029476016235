import {
  GET_ATTORNEYS_FAIL,
  GET_ATTORNEYS_SUCCESS,
  GET_ATTORNEY_DETAIL_SUCCESS,
  GET_ATTORNEY_DETAIL_FAIL,
  UPDATE_ATTORNEY_DETAIL_FAIL,
  UPDATE_ATTORNEY_DETAIL_SUCCESS,
  DELETE_ATTORNEY_DETAIL_FAIL,
  DELETE_ATTORNEY_DETAIL_SUCCESS,
  ADD_ATTORNEY_DETAIL_SUCCESS,
  ADD_ATTORNEY_DETAIL_FAIL
} from "./actionTypes"

const INIT_STATE = {
  attorney: [],
  attorneyDetail: {},
  error: {},
}

const Attorneys = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ATTORNEYS_SUCCESS:
      return {
        ...state,
        attorney: action.payload,
      }

    case GET_ATTORNEYS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ATTORNEY_DETAIL_SUCCESS:
      return {
        ...state,
        attorneyDetail: action.payload,
      }

    case GET_ATTORNEY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ATTORNEY_DETAIL_SUCCESS:
      return {
        ...state,
        attorney: [...state.attorney, action.payload],
      }

    case ADD_ATTORNEY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ATTORNEY_DETAIL_SUCCESS:
      return {
        ...state,
        attorney: state.attorney.filter((item) => item.id !== action.payload),
      };

    case DELETE_ATTORNEY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_ATTORNEY_DETAIL_SUCCESS:
      return {
        ...state,
        attorney: action.payload
      };

    case UPDATE_ATTORNEY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state
  }
}

export default Attorneys
