import { useFormik } from "formik";
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, Row } from "reactstrap";
import * as Yup from "yup";
import { getDateFormatYMDMDY } from "../../../common/data/dateFormat";
import { validateTimesheetByPeriod } from '../../../store/employee/timesheets/actions';
import { useNavigate } from 'react-router-dom';

const addTimesheetModal = ({ show, onAddSubmitClick, onCloseClick }) => {
  const [apiRefresh, setApiRefresh] = useState(true);
  const [formData, setFormData] = useState({
    'client_id': '',
    'time_period': "7",
    'startDate': "",
    'endDate': '',
    'actualStartDate': ''
  })
  const [validationCount, setValidationCount] = useState(0)

  const { validate_timesheet_by_period_check, customers } = useSelector((state) => ({
    validate_timesheet_by_period_check: state.EmployeeTimesheets.validate_timesheet_by_period_check,
    customers: state.Customers.customers,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (validate_timesheet_by_period_check === 0) {
      onAddSubmitClick(formData)
    }
  }, [apiRefresh, validate_timesheet_by_period_check]);



  const navigate = useNavigate();

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      client_id: formData.client_id,
      startDate: formData.startDate
    },
    validationSchema: Yup.object({
      client_id: Yup.string().required("Please Select Vendor"),
      startDate: Yup.date().required("Please Select Start Date"),
    }),
    onSubmit: (values) => {
      var data = {
        ...formData, startDate: getDateFormatYMDMDY(values.startDate)
      };
      setFormData(data)
      setApiRefresh(true);
      dispatch(validateTimesheetByPeriod({
        "customer_id": data.client_id,
        "from_date": data.startDate,
        "to_date": data.endDate
      }))
    }
  });

  const onclickStartCalender = (e, action = "onStartDateSelect", timePeriodVal) => {

    var startDateVal = '', tmpStartDate, tmpActualStartDateVal;
    if (action === "onStartDateSelect") {
      tmpStartDate = new Date(moment(e).format("MM/DD/yyyy"));
    } else {
      tmpStartDate = new Date(formData.startDate);
    }

    var startDategetMonth = (tmpStartDate.getMonth()).toString();
    var selectedMonth = parseInt(tmpStartDate.getMonth()) + 1;
    var setStartDateVal = (selectedMonth < 2 ? 0 + (selectedMonth.toString()) : selectedMonth).toString()
      + '/' + tmpStartDate.getDate().toString() + '/' + tmpStartDate.getFullYear().toString();


    if (action === "onStartDateSelect") {
      startDateVal = new Date(moment(e).format("MM/DD/yyyy"));
    } else {
      startDateVal = new Date(moment(formData.startDate).format("MM/DD/yyyy"));//new Date(formData.startDate);
    }

    //var numOfWeeks = parseInt(e.length > 2 ? formData.time_period : e);
    timePeriodVal = timePeriodVal ? timePeriodVal : formData.time_period;
    var numOfWeeks = parseInt(timePeriodVal);
    const endDate = startDateVal.setDate(startDateVal.getDate() + numOfWeeks - 1);
    var endDateYear = startDateVal.getFullYear().toString();
    var endDateMonth;
    var endDateDate;
    if (numOfWeeks < 15) {
      endDateMonth = (startDateVal.getMonth() + 1).toString();
      endDateDate = startDateVal.getDate().toString();
    } else {
      if (numOfWeeks === 15) {
        if (startDateVal.getMonth() !== tmpStartDate.getMonth()) {
          endDateMonth = (startDateVal.getMonth()).toString();
          endDateDate = new Date(endDateYear, endDateMonth, 0);
          endDateDate = endDateDate.getDate().toString();
        } else {
          endDateDate = startDateVal.getDate().toString();
          endDateMonth = (startDateVal.getMonth() + 1).toString();
        }
      }
      if (numOfWeeks > 15) {
        endDateMonth = moment(tmpStartDate).format('M');
        endDateDate = new Date(endDateYear, endDateMonth, 0);
        endDateDate = endDateDate.getDate().toString();
      }
    }
    var endDateSetVal = '', checkStartDateVal = '';
    if (tmpStartDate != 'Invalid Date') {
      endDateMonth = endDateMonth.length < 2 ? 0 + endDateMonth : endDateMonth;
      endDateSetVal = endDateMonth + '/' + endDateDate + '/' + endDateYear;
      checkStartDateVal = new Date(moment(setStartDateVal).format("MM/DD/yyyy"));
    }

    setFormData({
      ...formData,
      endDate: endDateSetVal,
      startDate: checkStartDateVal,
      actualStartDate: setStartDateVal,
      time_period: numOfWeeks.toString(),
      client_id: formData.client_id
    });

  }
  const onValChange = (e) => {
    //setFormData({ ...formData, [e.target.name]: e.target.value });
    onclickStartCalender(e, 'setEndDateOnCheckboxSelection', e.target.value);
  }

  const onVendorChange = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;
    setFormData({ ...formData, client_id: e.target.value, client_name: label })
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <h4>{"Select Time Period!"}</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Label
              htmlFor="horizontal-firstname-input"
              className="col-sm-2 col-form-label"
            >
              Client/Vendor:
            </Label>
            <Input
              id="client_id"
              name="client_id"
              type="select"
              //value={formData.client_id}
              onChange={(validation.handleChange, onVendorChange)}
              onBlur={validation.handleBlur}
              // value={validation.values.client_id }
              invalid={
                validation.touched.client_id &&
                  validation.errors.client_id
                  ? true
                  : false
              }
            >
              <option value="">Select</option>
              {customers && customers.map((customer, index) => (
                <option data-vendor-label={customer.cmp_name} key={index} value={customer.id}>{customer.cmp_name}</option>
              ))}
            </Input>
            {validation.touched.client_id &&
              validation.errors.client_id ? (
              <FormFeedback type="invalid">
                {validation.errors.client_id}
              </FormFeedback>
            ) : null}
          </Row><br />
          <Row>

            <Input className="form-control" id="time_period1" name="time_period" type="checkbox" value="7"
              checked={formData.time_period === "7"} onChange={onValChange}
            //onChange={onAddSubmitClick} 
            ></Input>
            <Label htmlFor="horizontal-firstname-input" className="col-sm-2 col-form-label" style={{ padding: "0 5px" }}>
              Weekly</Label>
            <Input className="form-control" id="time_period2" name="time_period" type="checkbox" value="14"
              checked={formData.time_period === "14"} onChange={onValChange}
            //onChange={onAddSubmitClick} 
            ></Input>
            <Label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label" style={{ padding: "0 5px" }}>
              Bi Weekly
            </Label>

            <Input className="form-control" id="time_period3" name="time_period" type="checkbox" value="15"
              checked={formData.time_period === "15"} onChange={onValChange}
            //onChange={onAddSubmitClick} 
            ></Input>
            <Label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label" style={{ padding: "0 5px" }}>
              Semi Monthly
            </Label>

            <Input className="form-control" id="time_period4" name="time_period" type="checkbox" value="30"
              checked={formData.time_period === "30"} onChange={onValChange}
            //onChange={onAddSubmitClick} 
            ></Input>
            <Label htmlFor="horizontal-firstname-input" className="col-sm-2 col-form-label" style={{ padding: "0 5px" }}>
              Monthly
            </Label>
          </Row>
          <Row>
            <Label
              htmlFor="horizontal-firstname-input"
              className="col-form-label"
            >
              Start Date:
            </Label>
            <ReactDatePicker
              showYearDropdown={true}
              showIcon
              autoComplete='off'
              name="startDate"
              placeholderText="MM/dd/yyyy"
              className={`form-control ${validation.touched.startDate && validation.errors.startDate ? 'is-invalid' : ''}`}
              id="startDate"
              dateFormat="MM/dd/yyyy"
              // onChangeRaw={(date)=>{onclickStartCalender()}
              // }
              onChange={(date) => {
                validation.setFieldValue('startDate', date)
                onclickStartCalender(date)
              }}
              selected={validation?.values?.startDate ? new Date(validation.values.startDate) : ''}
            />

            {validation.touched.startDate &&
              validation.errors.startDate ? (
              <FormFeedback type="invalid">
                {validation.errors.startDate}
              </FormFeedback>
            ) : null}
          </Row>
          <Row>
            <Label
              htmlFor="horizontal-firstname-input"
              className="col-form-label"
            >
              End Date:
            </Label>
            <ReactDatePicker
              showYearDropdown={true}
              showIcon
              autoComplete='off'
              name="endDate"
              placeholderText="MM/dd/yyyy"
              id="endDate"
              dateFormat="MM/dd/yyyy"
              readOnly
              className="form-control"
              onChange={(date) => {
              }}
              selected={formData.endDate ? new Date(formData.endDate) : ''}
            />
           
          </Row>
          <Row style={{ float: "right", marginTop: "18px" }}>
            <Col >
              <span className="mt-4">
                <button type="reset" className="btn btn-secondary" color="secondary" onClick={() => navigate(-1)}>
                  Cancel
                </button>
              </span>
              &nbsp;&nbsp;
              <span className="mt-4">
                <button type="submit" className="btn btn-primary">
                Submit
                </button>
              </span>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

addTimesheetModal.propTypes = {
  onCloseClick: PropTypes.func,
  onAddSubmitClick: PropTypes.func,
  show: PropTypes.any
}

export default addTimesheetModal

