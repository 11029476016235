import React, { useEffect, useMemo, useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown
} from "reactstrap";

//redux
import { useFormik } from "formik";
import * as Yup from "yup";

import DatatableContainer from "../../../components/Common/DatatableContainer";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { AttorneyCaseDelete, changePreloader, getEmployerCases } from "../../../store/actions";
import {
  CaseTypeName,
  CommonCell, Date
} from "./listCol";

//redux
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";


const InvoiceList = () => {

  //meta title
  document.title = "Employer Cases | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [activeTab, setactiveTab] = useState("All");

  // for delete 
  const [order, setOrder] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [moveToInactiveModal, setMoveToInactiveModal] = useState(false);
  const [moveToActiveModal, setMoveToActiveModal] = useState(false);


  const { employercases } = useSelector((state) => ({
    employercases: state.EmployerCases.employercase || [],
  }));


  const [contactList, setContactList] = useState({ "status": "All" });


  useEffect(() => {
    dispatch(changePreloader(true));
    dispatch(getEmployerCases({ "status": "All" }));
  }, []);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {

    navigate(`/employer/add-employer-case`);

  };

  const onViewData = (data) => {
    navigate(`/employer/view-case?caseId=${data.case_id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };
  const onMoveToInactive = (data) => {
    setMoveToInactiveModal(true);
    setOrder(data);
  };
  const onMoveToActive = (data) => {
    setMoveToActiveModal(true);
    setOrder(data);
  };

  const handleChangeCaseStatus = (status, processing_status = '') => {
    if (order.case_id) {
      var data = {
        "case_id": order.case_id,
        "status": status
      };
      if (processing_status != '') {
        data.processing_status = processing_status;
      }
      dispatch(AttorneyCaseDelete(data));
      setDeleteModal(false);
      setMoveToInactiveModal(false);
      setMoveToActiveModal(false);
      setTimeout(() => {
        dispatch(getEmployerCases(contactList));
      }, 200)
    }
  };

  const onReloadData = (event) => {
    var tabInfo = event.target.value;
    setactiveTab(tabInfo);
    dispatch(getEmployerCases({ status: tabInfo }));
  }

  const columns = useMemo(
    () => [
      {
        Header: "Case",
        accessor: "case_number",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Beneficiary",
        accessor: "employee_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Case Type",
        accessor: "case_type",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Questionnaire Type",
        accessor: "qnnr_type",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Processing Status",
        accessor: "processing_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Case Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Created",
        accessor: "created_at",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">

                {(cellProps.row.original.status != "Inactive") && (
                  <>
                    <DropdownItem onClick={() => onViewData(cellProps.row.original)}>View</DropdownItem>
                    <DropdownItem onClick={() => onMoveToInactive(cellProps.row.original)}>Move to Inactive</DropdownItem>
                  </>
                )}
                {(cellProps.row.original.status == "Inactive") && (
                  <DropdownItem onClick={() => onMoveToActive(cellProps.row.original)}>Move to Active</DropdownItem>
                )}

                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      processing_status: '',
    },
    validationSchema: Yup.object({
      processing_status: Yup.string().required("Please select processing status"),
    }),
    onSubmit: (values) => {
      if (values.processing_status != '') {
        handleChangeCaseStatus("Inactive", values.processing_status)
        validation.resetForm();
      }
    }
  });

  let title = 'Cases';
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/employer/dashboard' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <Modal isOpen={moveToInactiveModal}>
        <ModalHeader toggle={() => {
          validation.resetForm();
          setMoveToInactiveModal(false)
        }} tag="h4">
          Move to Inactive
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row form>

              <Col className="col-12 mb-3">
                <Label className="form-label mb-1">Processing Status</Label>
                <Input
                  type="select"
                  name="processing_status"
                  title="Select from list"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.processing_status || ""}
                  invalid={
                    validation.touched.processing_status &&
                      validation.errors.processing_status
                      ? true
                      : false
                  }
                >
                  <option value="">Select status</option>
                  <option selected={validation.values.processing_status === "Approved"} value="Approved">Approved</option>
                  <option selected={validation.values.processing_status === "Rejected"} value="Rejected">Rejected</option>
                  <option selected={validation.values.processing_status === "Not Required"} value="Not Required">Not Required</option>
                  <option selected={validation.values.processing_status === "Duplicate"} value="Duplicate">Duplicate</option>
                  <option selected={validation.values.processing_status === "Withdrawn"} value="Withdrawn">Withdrawn</option>
                </Input>
                {validation.touched.processing_status &&
                  validation.errors.processing_status ? (
                  <FormFeedback type="invalid">
                    {validation.errors.processing_status}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-primary save-event"
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleChangeCaseStatus("Deleted")}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={moveToActiveModal}
        onDeleteClick={() => handleChangeCaseStatus("Active")}
        onCloseClick={() => setMoveToActiveModal(false)}
        message={"Move case to Active status"}
        status={"Active"}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={employercases}
                    isGlobalFilter={true}
                    isAddEmployerCase={true}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                    isAttorneyFilter={true}
                    defaultAttorneyFilter={'Active'}
                    isAttorneyFilterChange={onReloadData}
                    isExport={true}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InvoiceList;
