import { call, put, takeEvery } from "redux-saga/effects"


// Login Redux States
import { EDIT_PROFILE,GET_PROFILE } from "./actionTypes"
import { profileSuccess, profileError,getProfileSuccess,getProfileFail } from "./actions"

import {
  postProfile,
  getProfile,
} from "../../../helpers/apibackend_helper"


function* editProfile({ payload: { user } }) {
  try {
    
      const response = yield call(postProfile, {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    
  } catch (error) {
    yield put(profileError(error))
  }
}


function* fetchProfile({payload:data}) {
  try {
   
    const response = yield call(getProfile,data)
    yield put(getProfileSuccess(response.data))
  } catch (error) {
    yield put(getProfileFail(error))
  }
}


function* ProfileSaga() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(GET_PROFILE, fetchProfile)
}

export default ProfileSaga
