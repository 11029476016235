/* QNNRTYPES */
export const GET_ATTRIBUTETEMPLATES = "GET_ATTRIBUTETEMPLATES"
export const GET_ATTRIBUTETEMPLATES_SUCCESS = "GET_ATTRIBUTETEMPLATES_SUCCESS"
export const GET_ATTRIBUTETEMPLATES_FAIL = "GET_ATTRIBUTETEMPLATES_FAIL"

export const GET_ATTRIBUTETEMPLATE_DETAIL = "GET_ATTRIBUTETEMPLATE_DETAIL"
export const GET_ATTRIBUTETEMPLATE_DETAIL_SUCCESS = "GET_ATTRIBUTETEMPLATE_DETAIL_SUCCESS"
export const GET_ATTRIBUTETEMPLATE_DETAIL_FAIL = "GET_ATTRIBUTETEMPLATE_DETAIL_FAIL"

export const ADD_ATTRIBUTETEMPLATE_DETAIL = "ADD_ATTRIBUTETEMPLATE_DETAIL"
export const ADD_ATTRIBUTETEMPLATE_DETAIL_SUCCESS = "ADD_ATTRIBUTETEMPLATE_DETAIL_SUCCESS"
export const ADD_ATTRIBUTETEMPLATE_DETAIL_FAIL = "ADD_ATTRIBUTETEMPLATE_DETAIL_FAIL"

export const DELETE_ATTRIBUTETEMPLATE_DETAIL = "DELETE_ATTRIBUTETEMPLATE_DETAIL"
export const DELETE_ATTRIBUTETEMPLATE_DETAIL_SUCCESS = "DELETE_ATTRIBUTETEMPLATE_DETAIL_SUCCESS"
export const DELETE_ATTRIBUTETEMPLATE_DETAIL_FAIL = "DELETE_ATTRIBUTETEMPLATE_DETAIL_FAIL"

export const UPDATE_ATTRIBUTETEMPLATE_DETAIL = "UPDATE_ATTRIBUTETEMPLATE_DETAIL"
export const UPDATE_ATTRIBUTETEMPLATE_DETAIL_SUCCESS = "UPDATE_ATTRIBUTETEMPLATE_DETAIL_SUCCESS"
export const UPDATE_ATTRIBUTETEMPLATE_DETAIL_FAIL = "UPDATE_ATTRIBUTETEMPLATE_DETAIL_FAIL"
