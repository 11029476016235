import {
  GET_QNNRTYPES_FAIL,
  GET_QNNRTYPES_SUCCESS,
  GET_QNNRTYPE_DETAIL_SUCCESS,
  GET_QNNRTYPE_DETAIL_FAIL,
  UPDATE_QNNRTYPE_DETAIL_FAIL,
  UPDATE_QNNRTYPE_DETAIL_SUCCESS,
  DELETE_QNNRTYPE_DETAIL_FAIL,
  DELETE_QNNRTYPE_DETAIL_SUCCESS,
  ADD_QNNRTYPE_DETAIL_SUCCESS,
  ADD_QNNRTYPE_DETAIL_FAIL
} from "./actionTypes"

const INIT_STATE = {
  qnnrtypes: [],
  qnnrtypeDetail: {},
  error: {},
}

const Qnnrtypes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QNNRTYPES_SUCCESS:
      return {
        ...state,
        qnnrtypes: action.payload,
      }

    case GET_QNNRTYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_QNNRTYPE_DETAIL_SUCCESS:
      return {
        ...state,
        qnnrtypeDetail: action.payload,
      }

    case GET_QNNRTYPE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_QNNRTYPE_DETAIL_SUCCESS:
      return {
        ...state,
        qnnrtypes: [...state.qnnrtypes, action.payload],
      }

    case ADD_QNNRTYPE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_QNNRTYPE_DETAIL_SUCCESS:
      return {
        ...state,
        qnnrtypes: action.payload,
      };

    case DELETE_QNNRTYPE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_QNNRTYPE_DETAIL_SUCCESS:
      return {
        ...state,
        qnnrtypes: state.qnnrtypes.map((row) =>
          row.id.toString() === action.payload.id.toString()
            ? { row, ...action.payload }
            : row
        ),
      };

    case UPDATE_QNNRTYPE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state
  }
}

export default Qnnrtypes
