import React, { useEffect, useMemo, useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import DatatableContainer from "../../../components/Common/DatatableContainer";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { AttorneyContactDelete, changePreloader, employerResendPassword, getAttorneyContacts } from "../../../store/actions";
import {
  CaseTypeName,
  CommonCell, Date
} from "./listCol";

//redux
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";


const InvoiceList = () => {

  //meta title
  document.title = "Contacts | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [activeTab, setactiveTab] = useState("Active");
  const [order, setOrder] = useState(null);

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);

  const { attorneycontacts } = useSelector((state) => ({
    attorneycontacts: state.AttorneyContacts.attorneycontacts || [],
  }));
  const [contactList, setContactList] = useState({ "status": "All" });
  const [apiSuccess, setApiSuccess] = useState(false);

  useEffect(() => {
    dispatch(getAttorneyContacts(contactList));
    dispatch(changePreloader(true));
    // setApiSuccess(true)
  }, [dispatch]);

  // useEffect(() => {
  //   if (apiSuccess) {
  //     dispatch(changePreloader(false));
  //     setApiSuccess(false)
  //   }
  // }, [attorneycontacts]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {
    navigate(`/attorney/add-contact`);
  };

  const onUpdateData = (data) => {
    navigate(`/attorney/add-contact?id=${data.id}`);
  };

  const onResendLogin = (data) => {
    dispatch(employerResendPassword(data.id));
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };

  const handleDeleteContact = () => {
    if (order.id) {
      dispatch(AttorneyContactDelete(order.id));
      setDeleteModal(false);
      setTimeout(() => {
        dispatch(getAttorneyContacts(contactList));
      }, 200)
    }
  };

  const onReloadData = (event) => {
    var tabInfo = event.target.value;
    setactiveTab(tabInfo);
    dispatch(getAttorneyContacts({ status: tabInfo }));
  }
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "company_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Phone",
        accessor: "phone",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Active Cases",
        accessor: "active_cases_count",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Total Cases",
        accessor: "total_cases_count",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "Portal Access",
        accessor: "portal_visibility",
        filterable: true,
        disableFilters: true,
        Cell: (qnnrtype) => {
          return (
            <div className={""}>
              {qnnrtype.value}
            </div>
          )
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Created",
        accessor: "created_at",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },


      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onUpdateData(cellProps.row.original)}>View</DropdownItem>
                <DropdownItem onClick={() => onResendLogin(cellProps.row.original)}>Resend Password</DropdownItem>
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  const title = "Contacts"
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/attorney/dashboard' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteContact}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                  <DatatableContainer
                    columns={columns}
                    data={attorneycontacts}
                    isGlobalFilter={true}
                    isAddCompanyType={true}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                    isAttorneyFilter={true}
                    defaultAttorneyFilter={'Active'}
                    isAttorneyFilterChange={onReloadData}
                    isExport={true}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InvoiceList;
