import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};

const userTypes = {
  'Petitioner': 'employer',
  'Attorney': 'attorney',
  'superadmin': 'superadmin',
  'employer': 'employer',
  'attorney': 'attorney',
  'employee': "employee",
  'admin': 'admin'
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      localStorage.setItem("user_type", userTypes[action.payload.data.user_type])
      localStorage.setItem("status", action.payload.data.status)
      state = {
        ...state,
        user_type: userTypes[action.payload.data.user_type],
        loading: false,
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;
    case LOGOUT_USER_SUCCESS:
      state = null;
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
