import {
  GET_QNNRTYPES,
  GET_QNNRTYPES_FAIL,
  GET_QNNRTYPES_SUCCESS,
  GET_QNNRTYPE_DETAIL,
  GET_QNNRTYPE_DETAIL_FAIL,
  GET_QNNRTYPE_DETAIL_SUCCESS,
  UPDATE_QNNRTYPE_DETAIL_FAIL,
  UPDATE_QNNRTYPE_DETAIL_SUCCESS,
  UPDATE_QNNRTYPE_DETAIL,
  DELETE_QNNRTYPE_DETAIL_FAIL,
  DELETE_QNNRTYPE_DETAIL_SUCCESS,
  DELETE_QNNRTYPE_DETAIL,
  ADD_QNNRTYPE_DETAIL_SUCCESS,
  ADD_QNNRTYPE_DETAIL,
  ADD_QNNRTYPE_DETAIL_FAIL

} from "./actionTypes"

export const getQnnrtypes = (id) => ({
  type: GET_QNNRTYPES,
  id
})

export const getQnnrtypesSuccess = Qnnrtypes => ({
  type: GET_QNNRTYPES_SUCCESS,
  payload: Qnnrtypes,
})

export const getQnnrtypesFail = error => ({
  type: GET_QNNRTYPES_FAIL,
  payload: error,
})

export const getQnnrtypeDetail = QnnrtypeId => ({
  type: GET_QNNRTYPE_DETAIL,
  QnnrtypeId,
})

export const getQnnrtypeDetailSuccess = Qnnrtypes => ({
  type: GET_QNNRTYPE_DETAIL_SUCCESS,
  payload: Qnnrtypes,
})

export const getQnnrtypeDetailFail = error => ({
  type: GET_QNNRTYPE_DETAIL_FAIL,
  payload: error,
})

export const  addQnnrtypeDetail = (data) => ({
  type: ADD_QNNRTYPE_DETAIL,
  payload: data
})

export const addQnnrtypedetailSuccess = (data) => ({
  type: ADD_QNNRTYPE_DETAIL_SUCCESS,
  payload: data
})

export const addQnnrtypedetailFail = (error) => ({
  type: ADD_QNNRTYPE_DETAIL_FAIL,
  payload: error
})

export const qnnrtypeDelete = (data) => ({
  type: DELETE_QNNRTYPE_DETAIL,
  payload: data
})

export const qnnrtypeDeleteSuccess = (data) => ({
  type: DELETE_QNNRTYPE_DETAIL_SUCCESS,
  payload: data
})

export const qnnrtypeDeleteFail = (error) => ({
  type: DELETE_QNNRTYPE_DETAIL_FAIL,
  payload: error
})

export const qnnrtypeUpdate = (data) => ({
  type: UPDATE_QNNRTYPE_DETAIL,
  payload: data
})

export const qnnrtypeUpdateSuccess = (data) => ({
  type: UPDATE_QNNRTYPE_DETAIL_SUCCESS,
  payload: data
})

export const qnnrtypeUpdateFail = (error) => ({
  type: UPDATE_QNNRTYPE_DETAIL_FAIL,
  payload: error
})
