import React from "react";

import EmployerInvoiceCreate from "../../Invoices/CreateInvoice";
import EmployerBasicSuite from "../../Basic";

const MainInvoiceCreateComponent = () => {

  const getLoggedInPlanType = () => {
    return localStorage.getItem("plan_type");
  }

  if (getLoggedInPlanType() == 1) {
    return <EmployerInvoiceCreate />
  } else {
    return <EmployerBasicSuite componentName="Create Invoice" />
  }
};

export default MainInvoiceCreateComponent;
