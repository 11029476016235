import { GET_EMPLOYERCASE_DETAIL_SUCCESS } from "../../employer/cases/actionTypes"
import {
  GET_EMPLOYERS_SUCCESS,
  GET_EMPLOYERS_FAIL,
  GET_EMPLOYER_DETAILS_SUCCESS,
  GET_EMPLOYER_DETAILS_FAIL,
  UPDATE_EMPLOYER_DETAILS_SUCCESS,
  UPDATE_EMPLOYER_DETAILS_FAIL
} from "./actionTypes"
const INIT_STATE = { 
  employers: [],
  employerDetails: {},
  error: {},
}

const Employers = (state = INIT_STATE, action) => {
  
  switch (action.type) { 
    case GET_EMPLOYERS_SUCCESS:
      return { 
        ...state,
        employers: action.payload,
      }

    case GET_EMPLOYERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYER_DETAILS_SUCCESS:
      return { 
        ...state,
        employerDetails: action.payload,
      }

    case GET_EMPLOYER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_EMPLOYER_DETAILS_SUCCESS:
      return { 
        ...state,
        employerDetails: action.payload,
      }

    case UPDATE_EMPLOYER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    
    default:
      return state
  }
}

export default Employers
