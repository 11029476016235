import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  ADD_ATTORNEY_DOCUMENT_UPLOAD,
  ADD_ATTORNEYCASE_DETAIL,
  BUNDLE_CASE_DOCUMENTS,
  BUNDLE_CASEFORMS,
  DELETE_ATTORNEYCASE_DETAIL,
  DELETE_CASE_FORM,
  GENERATE_FORM,
  GENERATE_FORM_DETAIL,
  GET_ATTORNEYCASE_DETAIL,
  GET_ATTORNEYCASE_DOCUMENTS,
  GET_ATTORNEYCASE_FORMS,
  GET_ATTORNEYCASE_QNNR_DETAILS,
  GET_ATTORNEYCASES,
  GET_ATTORNEYCASES_OVERVIEW,
  GET_ATTORNEYCASETYPES,
  GET_ATTORNEYQNNRTYPES,
  GET_CASE_DEPENDENTS_ATTORNEY,
  GET_CASE_TIMELINES,
  GET_EMPLOYER_EMPLOYEES,
  GET_MESSAGE,
  GET_NOTE, GET_REMINDER,
  REQUEST_ATTORNEY_DOCUMENT,
  REVOKE_ATTORNEY_FORM_SHARE_REVIEW,
  SAVE_CASE_FORM_TEMPLATE_DATA,
  SAVE_CASEFORMS,
  SAVE_MESSAGE,
  SAVE_NOTE, SAVE_REMINDER,
  SEND_REMINDER_DOCUMENT_ATTORNEY,
  SHARE_UPLOADED_DOCUMENT_ATTORNEY,
  SORT_CASEFORMS,
  UDATE_ATTORNEY_FORM_SHARE,
  UDATE_ATTORNEY_LCADETAILS,
  UDATE_ATTORNEY_UNLOCK_QUNNR,
  UPDATE_ATTORNEYCASE_DETAIL
} from "./actionTypes"
import {
  addAttorneyCasedetailFail,
  addAttorneyCasedetailSuccess,
  addDocumentUploadFail,
  addDocumentUploadSuccess,
  AttorneyCaseDeleteFail,
  AttorneyCaseDeleteSuccess,
  AttorneyCaseUpdateFail,
  AttorneyCaseUpdateSuccess,
  bundleCasedocumentFail,
  bundleCasedocumentSuccess,
  bundleCaseformFail,
  bundleCaseformSuccess,
  deleteCaseFormFail,
  deleteCaseFormSuccess,
  generateFormDetailFail,
  generateFormDetailSuccess,
  generateFormFail,
  generateFormSuccess,
  getAttorneyCaseDetailFail,
  getAttorneyCaseDetailSuccess,
  getAttorneyCasesFail,
  getAttorneyCasesOverviewFail,
  getAttorneyCasesOverviewSuccess,
  getAttorneyCasesSuccess,
  getAttorneyCaseTypesFail,
  getAttorneyCaseTypesSuccess,
  getAttorneyQnnrtypesFail,
  getAttorneyQnnrtypesSuccess,
  getCaseDependentsAttorneyFail,
  getCaseDependentsAttorneySuccess,
  getCaseDocumentsFail,
  getCaseDocumentsSuccess,
  getCaseFormsFail,
  getCaseFormsSuccess,
  getCaseQnnrDetailsFail,
  getCaseQnnrDetailsSuccess,
  getCaseTimelinesFail,
  getCaseTimelinesSuccess,
  getEmployerEmployeesFail,
  getEmployerEmployeesSuccess,
  getMessageFail,
  getMessageSuccess,
  getNoteFail,
  getNoteSuccess,
  getReminderFail,
  getReminderSuccess,
  requestDocumentFail,
  requestDocumentSuccess,
  revokeAttorneyFormReviewFail,
  revokeAttorneyFormReviewSuccess,
  saveCaseformFail,
  saveCaseformSuccess,
  saveCaseFormTemplateDataFail,
  saveCaseFormTemplateDataSucess,
  saveMessageFail,
  saveMessageSuccess,
  saveNoteFail,
  saveNoteSuccess,
  saveReminderFail,
  saveReminderSuccess,
  sendReminderDocumentFail,
  sendReminderDocumentSuccess,
  shareUploadedDocFail,
  shareUploadedDocSuccess,
  sortCaseFormsFail,
  sortCaseFormsSuccess,
  updateAttorneyFormReviewFail,
  updateAttorneyFormReviewSuccess,
  updateAttorneyLCADetailsFail,
  updateAttorneyLCADetailsSuccess,
  updateAttorneyUnlockQunnrFail,
  updateAttorneyUnlockQunnrSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { addAttorneyCase, addAttorneyDocumentUploadForm, bundleCasedocs, bundleCaseforms, deleteAttorneyCase, deleteCaseFormApi, generateForm, generateFormDetail, getAttorneyCaseDetail, getAttorneyCases, getAttorneyCaseTypes, getAttorneyQnnrtypes, getCaseDependents, getCaseDocuments, getCaseForms, getCaseQnnrDetails, getCaseTimelines, getEmployerEmployeesApi, getMessage, getNote, getReminder, requestAttorneyDocumentForm, revokeAttorneyCaseFormsReview, saveCaseforms, saveCaseFormTemplateData, saveMessage, saveNote, saveReminder, sendReminderDocument, shareUploadedDocument, sortAttorneyCaseForms, updateAttorneyCase, updateAttorneyCaseForms, updateAttorneyCaseLCA, updateAttorneyUnlockQunnr, getAttorneyCasesOverview } from "../../../helpers/apibackend_helper"
import { changePreloader } from "../../../store/actions"
import { setAlertMessage } from "../../common/actions"

function processGroupResponseData(response) {
  // Helper function to fix a JSON string by removing extra escapes
  const fixJsonString = (str) => {
    str = str.replace(/\\"/g, '"');
    str = str.replace(/^"(.*)"$/, '$1');
    const regex = /\,(?!\s*?[\{\[\"\'\w])/g;
    const fixedStr = str.replace(regex, '');
    let result = JSON.parse(fixedStr);
    return Array.isArray(result) ? result[0] : result;
  }

  // Helper function to remove whitespace and make a string lowercase
  const removeWhiteSpace = (str) => {
    return str != undefined ? str.replace(/(?<=\S)\s+(?=\S)/g, "") : Math.random(0, 1000);
  }

  // Helper function to check if a string is empty, null, or undefined
  const isValidCheck = (str) => {
    return str === undefined || str === null || str === '{}' || str === '';
  }

  // Process the response data
  // let apiresponse = response.data;
  let data = response.data.tabInfo;
  // console.log("data", data)
  let formInfo = response.data.formInfo;
  let keyName = Object.keys(data)?.[0];
  const groupsArray = [];
  const modifiedGroupArray = [];

  // Iterate through sets in the data
  for (const partkey in data[keyName]) {
    let parts = data[keyName][partkey];
    let groupId = parts.group_id;
    let dataprepare = [];
    let i = 1;
    for (const key in parts.sets) {
      if (parts.sets.hasOwnProperty(key)) {
        dataprepare.push({ id: parts.sets[key].attribute_set_id, key: groupId + '' + parts.sets[key].attribute_set_id, value: parts.sets[key] });
      }
      i++;
    }
    let dataprepareModified = [];
    dataprepare.forEach((item) => {
      // Assign values and transform attributes
      item.attribute_set_id = item.value.attribute_set_id;
      item.name = item.value.name;
      item.shortDescription = item.value.shortDescription;
      item.description = item.value.description;
      var objArray = [];
      if (item?.value?.attributes) {
        objArray = item.value.attributes.map((obj, index) => {
          let content = obj.content;
          obj.name = removeWhiteSpace(obj.name);
          obj.invalidMessage = '';
          obj.isValid = false;
          obj.value = isValidCheck(obj.value) ? '' : obj.value;
          obj.validation = isValidCheck(content.required) ? false : content.required;
          content.name = removeWhiteSpace(content.name || content.key);
          return { ...obj, content };
        });
      }
      item.attributes = objArray;
      dataprepareModified.push(item);
    });

    let partArray = { formInfo: formInfo, sets: dataprepareModified, part: partkey, 'title': parts.title, 'shortDesc': parts.short_desc };
    // groupsArray.push(partArray);
    modifiedGroupArray.push(partArray);

  }

  // console.log("modifiedGroupArray", modifiedGroupArray)

  // Modify the data structure
  data = modifiedGroupArray;
  data.formInfo = formInfo;
  data.groupName = keyName;
  data.groupId = keyName;
  data.qnnrProgress = null;//data[keyName].qnnr_progress;
  // delete data[keyName].sets;
  // delete data[keyName].tab;
  // delete data[keyName];
  return data;

}

function* fetchSaveCaseFormTemplateData({ payload: user }) {
  try {
    const response = yield call(saveCaseFormTemplateData, user)

    yield put(saveCaseFormTemplateDataSucess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(saveCaseFormTemplateDataFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  }
}

function* fetchGenerateForm({ payload: user }) {
  try {
    const response = yield call(generateForm, user)
    //const tabData = yield call(fetchEmployeeCaseQnnrTemplateGroupInfo());
    const data = response.data;
    const keyName = Object.keys(data)[0];
    const groupsArray = Object.entries(data.tab).map(([key, value], i) => ({
      id: i + 1,
      key,
      value,
    }));
    data.tab = groupsArray;
    data.qnnr_template_id = data?.qnnr_template_id;
    data.case_id = data?.case_id;
    data.case_form_id = data?.case_form_id;
    // console.log('groupsArray', groupsArray);
    const groupInfoResponse = yield call(generateForm, { ...user, "tab": groupsArray[0]?.value });
    const finalResponse = { templateInfo: data, groupInfo: processGroupResponseData(groupInfoResponse) };
    // console.log("finalResponse", finalResponse)
    yield put(generateFormSuccess(finalResponse))
    // yield put(getEmployeeCaseQnnrTemplateGroupInfoSucess())

    yield put(setAlertMessage({ message: finalResponse, type: "success" }));

  } catch (error) {
    yield put(generateFormFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))

  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchGenerateFormDetail({ payload: form }) {
  try {
    // Fetch data from an API using a generator function
    const response = yield call(generateFormDetail, form);
    yield put(generateFormDetailSuccess(processGroupResponseData(response)))
  } catch (error) {
    yield put(generateFormDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}
function* fetchAttorneyCases({ payload: user }) {
  try {
    const response = yield call(getAttorneyCases, user)
    yield put(getAttorneyCasesSuccess(response.data))
  } catch (error) {
    yield put(getAttorneyCasesFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}
function* fetchAttorneyCasesOverview({ payload: user }) {
  try {
    const response = yield call(getAttorneyCasesOverview, user)
    yield put(getAttorneyCasesOverviewSuccess(response.data))
  } catch (error) {
    yield put(getAttorneyCasesOverviewFail(error))
  }
}

function* fetchSortedCaseForms({ payload: CaseForms }) {
  try {
    // console.log(CaseForms, '-------check caae forms in saga----------')
    const response = yield call(sortAttorneyCaseForms, CaseForms)
    yield put(sortCaseFormsSuccess(response.data))
  } catch (error) {
    yield put(sortCaseFormsFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchAttorneyCaseDetail({ payload: cases }) {
  try {
    const response = yield call(getAttorneyCaseDetail, cases)
    yield put(getAttorneyCaseDetailSuccess(response.data))
  } catch (error) {
    yield put(getAttorneyCaseDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}


function* fetchAttorneyCaseQnnrDetail({ payload: cases }) {
  try {
    const response = yield call(getCaseQnnrDetails, cases)
    yield put(getCaseQnnrDetailsSuccess(response.data))
  } catch (error) {
    yield put(getCaseQnnrDetailsFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}


function* fetchAttorneyCaseForms({ payload: cases }) {
  try {
    const response = yield call(getCaseForms, cases)
    yield put(getCaseFormsSuccess(response.data))
  } catch (error) {
    yield put(getCaseFormsFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}


function* fetchAttorneyCaseDocuments({ payload: cases }) {
  try {
    const response = yield call(getCaseDocuments, cases)
    yield put(getCaseDocumentsSuccess(response.data))
  } catch (error) {
    yield put(getCaseDocumentsFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchCaseTimeLines({ payload: cases }) {
  try {
    const response = yield call(getCaseTimelines, cases)
    yield put(getCaseTimelinesSuccess(response.data))
  } catch (error) {
    yield put(getCaseTimelinesFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}


function* addAttorneyCaseDetail({ payload: user }) {
  try {
    const response = yield call(addAttorneyCase, user)
    yield put(addAttorneyCasedetailSuccess(response))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addAttorneyCasedetailFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteAttorneyCase({ payload: data }) {
  try {
    const response = yield call(deleteAttorneyCase, data);
    yield put(AttorneyCaseDeleteSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(AttorneyCaseDeleteFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}


function* onDeleteAttorneyCaseForm({ payload: data }) {
  try {
    const response = yield call(deleteCaseFormApi, data);
    yield put(deleteCaseFormSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(deleteCaseFormFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchGetEmployerEmployees({ payload: data }) {
  try {
    const response = yield call(getEmployerEmployeesApi, data);
    yield put(getEmployerEmployeesSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(getEmployerEmployeesFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}


function* onUpdateAttorneyCase({ payload: data }) {
  try {
    const response = yield call(updateAttorneyCase, data);
    yield put(AttorneyCaseUpdateSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(AttorneyCaseUpdateFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchAttorneyCaseTypes({ payload: user }) {
  try {
    const response = yield call(getAttorneyCaseTypes, user)
    yield put(getAttorneyCaseTypesSuccess(response.data))
  } catch (error) {
    yield put(getAttorneyCaseTypesFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchAttorneyQnnrTypes({ payload: AttorneyCaseId }) {
  try {
    const response = yield call(getAttorneyQnnrtypes, AttorneyCaseId)
    yield put(getAttorneyQnnrtypesSuccess(response.data))
  } catch (error) {
    yield put(getAttorneyQnnrtypesFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* updateAttorneyCaseForm({ payload: data }) {
  try {
    const response = yield call(updateAttorneyCaseForms, data)
    yield put(updateAttorneyFormReviewSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(updateAttorneyFormReviewFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}


function* revokeAttorneyCaseForm({ payload: data }) {
  try {
    const response = yield call(revokeAttorneyCaseFormsReview, data)
    yield put(revokeAttorneyFormReviewSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(revokeAttorneyFormReviewFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}

function* addDocumentUploadForm({ payload: data }) {
  try {
    const response = yield call(addAttorneyDocumentUploadForm, data)
    yield put(addDocumentUploadSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addDocumentUploadFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}

function* requestDocumentForm({ payload: data }) {
  try {
    const response = yield call(requestAttorneyDocumentForm, data)
    yield put(requestDocumentSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(requestDocumentFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}
function* updateAttorneyUnlock({ payload: data }) {
  try {
    const response = yield call(updateAttorneyUnlockQunnr, data)
    yield put(updateAttorneyUnlockQunnrSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(updateAttorneyUnlockQunnrFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateAttorneyCaseLCA({ payload: data }) {
  try {
    const response = yield call(updateAttorneyCaseLCA, data);
    yield put(updateAttorneyLCADetailsSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(updateAttorneyLCADetailsFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchBundleCaseforms({ payload: data }) {
  try {
    const response = yield call(bundleCaseforms, data)
    yield put(bundleCaseformSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(bundleCaseformFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}


function* fetchBundleCaseDocs({ payload: data }) {
  try {
    const response = yield call(bundleCasedocs, data)
    yield put(bundleCasedocumentSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(bundleCasedocumentFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchSaveCaseforms({ payload: data }) {
  try {
    const response = yield call(saveCaseforms, data)
    yield put(saveCaseformSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(saveCaseformFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}


function* fetchSaveNote({ payload: data }) {
  try {
    const response = yield call(saveNote, data)
    yield put(saveNoteSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(saveNoteFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}


function* fetchSaveReminder({ payload: data }) {
  try {
    const response = yield call(saveReminder, data)
    yield put(saveReminderSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(saveReminderFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}


function* fetchSaveMessage({ payload: data }) {
  try {
    const response = yield call(saveMessage, data)
    yield put(saveMessageSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(saveMessageFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}




function* fetchGetNote({ payload: data }) {
  try {
    const response = yield call(getNote, data)
    yield put(getNoteSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(getNoteFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}


function* fetchGetReminder({ payload: data }) {
  try {
    const response = yield call(getReminder, data)
    yield put(getReminderSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(getReminderFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}


function* fetchGetMessage({ payload: data }) {
  try {
    const response = yield call(getMessage, data)
    yield put(getMessageSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(getMessageFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchShareUploadDocument({ payload: data }) {
  try {
    const response = yield call(shareUploadedDocument, data)
    yield put(shareUploadedDocSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(shareUploadedDocFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}


function* fetchsendReminderDocument({ payload: data }) {
  try {
    const response = yield call(sendReminderDocument, data)
    yield put(sendReminderDocumentSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(sendReminderDocumentFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchGetCaseDependents({ payload: data }) {
  try {
    const response = yield call(getCaseDependents, data)
    yield put(getCaseDependentsAttorneySuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(getCaseDependentsAttorneyFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }));
  } 
}

function* AttorneyCasesaga() {
  yield takeEvery(SAVE_CASE_FORM_TEMPLATE_DATA, fetchSaveCaseFormTemplateData)
  yield takeEvery(GENERATE_FORM, fetchGenerateForm)
  yield takeEvery(GENERATE_FORM_DETAIL, fetchGenerateFormDetail)
  yield takeEvery(GET_ATTORNEYCASES, fetchAttorneyCases)
  yield takeEvery(GET_ATTORNEYCASES_OVERVIEW, fetchAttorneyCasesOverview)
  yield takeEvery(SORT_CASEFORMS, fetchSortedCaseForms)
  yield takeEvery(GET_ATTORNEYCASE_DETAIL, fetchAttorneyCaseDetail)
  yield takeEvery(GET_ATTORNEYCASE_QNNR_DETAILS, fetchAttorneyCaseQnnrDetail)
  yield takeEvery(GET_ATTORNEYCASE_FORMS, fetchAttorneyCaseForms)
  yield takeEvery(GET_ATTORNEYCASE_DOCUMENTS, fetchAttorneyCaseDocuments)
  yield takeEvery(GET_CASE_TIMELINES, fetchCaseTimeLines)
  yield takeEvery(ADD_ATTORNEYCASE_DETAIL, addAttorneyCaseDetail)
  yield takeEvery(DELETE_ATTORNEYCASE_DETAIL, onDeleteAttorneyCase)
  yield takeEvery(UPDATE_ATTORNEYCASE_DETAIL, onUpdateAttorneyCase)
  yield takeEvery(GET_ATTORNEYCASETYPES, fetchAttorneyCaseTypes)
  yield takeEvery(GET_ATTORNEYQNNRTYPES, fetchAttorneyQnnrTypes)
  yield takeEvery(UDATE_ATTORNEY_FORM_SHARE, updateAttorneyCaseForm)
  yield takeEvery(REVOKE_ATTORNEY_FORM_SHARE_REVIEW, revokeAttorneyCaseForm)
  yield takeEvery(ADD_ATTORNEY_DOCUMENT_UPLOAD, addDocumentUploadForm)
  yield takeEvery(REQUEST_ATTORNEY_DOCUMENT, requestDocumentForm)
  yield takeEvery(UDATE_ATTORNEY_UNLOCK_QUNNR, updateAttorneyUnlock)
  yield takeEvery(UDATE_ATTORNEY_LCADETAILS, onUpdateAttorneyCaseLCA)
  yield takeEvery(BUNDLE_CASEFORMS, fetchBundleCaseforms)
  yield takeEvery(BUNDLE_CASE_DOCUMENTS, fetchBundleCaseDocs)
  yield takeEvery(SAVE_CASEFORMS, fetchSaveCaseforms)
  yield takeEvery(SAVE_NOTE, fetchSaveNote)
  yield takeEvery(SAVE_REMINDER, fetchSaveReminder)
  yield takeEvery(SAVE_MESSAGE, fetchSaveMessage)
  yield takeEvery(GET_NOTE, fetchGetNote)
  yield takeEvery(GET_REMINDER, fetchGetReminder)
  yield takeEvery(GET_MESSAGE, fetchGetMessage)
  yield takeEvery(SHARE_UPLOADED_DOCUMENT_ATTORNEY, fetchShareUploadDocument)
  yield takeEvery(SEND_REMINDER_DOCUMENT_ATTORNEY, fetchsendReminderDocument)
  yield takeEvery(GET_CASE_DEPENDENTS_ATTORNEY, fetchGetCaseDependents)
  yield takeEvery(DELETE_CASE_FORM, onDeleteAttorneyCaseForm)
  yield takeEvery(GET_EMPLOYER_EMPLOYEES, fetchGetEmployerEmployees)
}

export default AttorneyCasesaga
