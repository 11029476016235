import PropTypes from "prop-types"
import React, { useState } from "react"

import { Row, Col, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux"

import { Link, Navigate, useNavigate } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"

import { GOOGLE_API_SITE_KEY } from "../../helpers/api_helper"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-sm.png"

//Import config
import { facebook, google } from "../../config"
import CarouselPage from "../AuthenticationInner/CarouselPage"
import { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Login = props => {


  const { profile } = useSelector((state) => ({
    profile: state.Profile.profile,
  }));

  const [passwordShow, setPasswordShow] = useState(false);

  const [showLoginError, setShowLogin] = useState(false);

  const dispatch = useDispatch()

  const { error } = useSelector(state => ({
    error: state.login.error,
  }))

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
      recaptcha: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
      recaptcha: Yup.string().required("Captcha field required").nullable(),
    }),
    onSubmit: (values) => {
      localStorage.removeItem("authUser");
      if (recaptchaValue) {
        dispatch(loginUser(values, props.router.navigate));
      }
    }
  });


  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  const recaptchaRef = React.createRef();

  const [recaptchaValue, setReCaptchaValue] = useState(false);
  function onChange(value) {
    if (recaptchaRef.current.getValue()) {
      validation.setFieldValue('recaptcha', true)
      setReCaptchaValue(recaptchaRef.current.getValue())
    }
  }

  useEffect(() => {
    if (error?.message) {
      setShowLogin(true)
      window.setTimeout(() => { setShowLogin(false) }, 8000);
    }
  }, [error]);


  document.title = "Login | Immidock ";
  let navigate = useNavigate();
  if (localStorage.getItem("authUser")) {
    let response = JSON.parse(localStorage.getItem("authUser"));
    let user_status = localStorage.getItem("status");
    let userRole = response.data.user_type;
    let userStatus = user_status && user_status != 'undefined' ? user_status : (response.data.status ? response.data.status : '');

    return (
      <>
        {
          (userRole == 'Attorney') ?
            <Navigate to={{ pathname: userStatus == 'Pending' ? `/${localStorage.getItem("user_type")}/add-company-info` : `/${localStorage.getItem("user_type")}/dashboard` }} />
            :
            (userRole == 'Petitioner') ?
              <Navigate to={{ pathname: userStatus == 'Pending' ? `/${localStorage.getItem("user_type")}/companyinfo` : `/${localStorage.getItem("user_type")}/dashboard` }} />
              :
              <Navigate to={{ pathname: `/${localStorage.getItem("user_type")}/dashboard` }} />
        }
      </>

    );
  }
  return (
    <React.Fragment>
      <div className="auth-page">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-4">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="28" /> <span className="logo-txt">Immidock</span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Back !</h5>
                        <p className="text-muted mt-2">Sign in to continue to Immidock.</p>
                      </div>
                      <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error?.message && showLoginError ? <Alert color="danger" show={showLoginError} >{error?.message}</Alert> : null}
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <Label className="form-label">Password</Label>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="">
                                <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                              </div>
                            </div>
                          </div>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light shadow-none ms-0" type="button" id="password-addon"><i className="mdi mdi-eye-outline"></i></button>
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={GOOGLE_API_SITE_KEY}
                              onChange={onChange}
                            />
                            <FormFeedback type="invalid">{validation.errors.recaptcha}</FormFeedback>

                            {/* <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="remember-check"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="remember-check"
                              >
                                Remember me
                              </label>
                            </div> */}

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>



                      {/* <div className="mt-5 text-center">
                        <p className="text-muted mb-0">Don't have an account ? <Link to="/register"
                          className="text-primary fw-semibold"> Signup now </Link> </p>
                            </div> */}
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">© {new Date().getFullYear()} Immidock.
                         {/* Crafted with <i className="mdi mdi-heart text-danger"></i> by Immidock */}
                         </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}