import {
  SHOW_ALERT_MESSAGE,
  SHOW_ALERT_MESSAGE_INFO,
 } from "./actionTypes"


export const setAlertMessage = (data) => ({
  type: SHOW_ALERT_MESSAGE,
  payload: data,
})

export const setAlertMessageInfo = (data) => ({
  type: SHOW_ALERT_MESSAGE_INFO,
  payload: data,
})

