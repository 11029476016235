import React from 'react';
import Label from "./Label";
import {
  FormGroup, Input
} from "reactstrap";
import { checkValidityClass } from "../Shared/utility";
import InputMask from "react-input-mask";
const MaskPhoneInputType = ({ name, label, type, placeholder, className, required, invalidMessage, value, content, onChanged }) => {
  return (
    <FormGroup className="mb-3">
      <Label
        label={content.label}
        name={name}
        isRequired={content.required}
      />

      <InputMask
        name={content.name}
        mask="(999)-999-9999"
        className={`${checkValidityClass(invalidMessage)} ${className ? 'form-control is-invalid' : 'form-control'}`}
        id={name}
        // onBlur={validation.handleBlur}
        value={value}
        required={content.required}
        aria-invalid="true"
        aria-describedby={`invalid_${name}`}
        onChange={onChanged}
        invalid={
          invalidMessage ? true : false
        }

      >
        {(inputProps) => (
          <Input
            {...inputProps}
            type="text"
            className="form-control"
          />
        )}
      </InputMask>
      <div type="invalid" className="invalid-feedback">{invalidMessage}</div>
    </FormGroup>
  );
};


export default MaskPhoneInputType