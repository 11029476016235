import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classname from "classnames";
import { Collapse } from "reactstrap";
import withRouter from "../Common/withRouter";

//Import Icons
import FeatherIcon from "feather-icons-react";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

// MetisMenu
import { Link } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";
import { getProfile } from "../../store/actions";
const Navbar = props => {

  const [ui, setui] = useState(false);
  const [app, setapp] = useState(false);
  const [dropdownlist, setDropdownlist] = useState(false);
  const [email, setemail] = useState(false);
  const [contact, setcontact] = useState(false);
  const [component, setcomponent] = useState(false);
  const [form, setform] = useState(false);
  const [table, settable] = useState(false);
  const [chart, setchart] = useState(false);
  const [icon, seticon] = useState(false);
  const [map, setmap] = useState(false);
  const [extra, setextra] = useState(false);
  const [invoice, setinvoice] = useState(false);
  const [auth, setauth] = useState(false);
  const [utility, setutility] = useState(false);

  // useEffect(() => {
  //   var matchingMenuItem = null;
  //   var ul = document.getElementById("navigation");
  //   var items = ul.getElementsByTagName("a");
  //   for (var i = 0; i < items.length; ++i) {
  //     if (window.location.pathname === items[i].pathname) {
  //       matchingMenuItem = items[i];
  //       break;
  //     }
  //   }
  //   if (matchingMenuItem) {
  //     activateParentDropdown(matchingMenuItem);
  //   }
  // });
  // function activateParentDropdown(item) {
  //   item.classList.add("active");
  //   const parent = item.parentElement;
  //   if (parent) {
  //     parent.classList.add("active"); // li
  //     const parent2 = parent.parentElement;
  //     parent2.classList.add("active"); // li
  //     const parent3 = parent2.parentElement;
  //     if (parent3) {
  //       parent3.classList.add("active"); // li
  //       const parent4 = parent3.parentElement;
  //       if (parent4) {
  //         parent4.classList.add("active"); // li
  //         const parent5 = parent4.parentElement;
  //         if (parent5) {
  //           parent5.classList.add("active"); // li
  //           const parent6 = parent5.parentElement;
  //           if (parent6) {
  //             parent6.classList.add("active"); // li
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return false;
  // }

  /** Horizental menu items */

  // const ref = useRef();
  // const activateParentDropdown = useCallback((item) => {
  //   item.classList.add("active");
  //   const parent = item.parentElement;
  //   const parent2El = parent.childNodes[1];

  //   if (parent2El && parent2El.id !== "side-menu") {
  //     parent2El.classList.add("mm-show");
  //   }

  //   if (parent) {
  //     parent.classList.add("mm-active");
  //     const parent2 = parent.parentElement;

  //     if (parent2) {
  //       parent2.classList.add("mm-show"); // ul tag

  //       const parent3 = parent2.parentElement; // li tag

  //       if (parent3) {
  //         parent3.classList.add("mm-active"); // li
  //         parent3.childNodes[0].classList.add("mm-active"); //a
  //         const parent4 = parent3.parentElement; // ul
  //         if (parent4) {
  //           parent4.classList.add("mm-show"); // ul
  //           const parent5 = parent4.parentElement;
  //           if (parent5) {
  //             parent5.classList.add("mm-show"); // li
  //             parent5.childNodes[0].classList.add("mm-active"); // a tag
  //           }
  //         }
  //       }
  //     }
  //     scrollElement(item);
  //     return false;
  //   }
  //   scrollElement(item);
  //   return false;
  // }, []);

  // const removeActivation = (items) => {
  //   for (var i = 0; i < items.length; ++i) {
  //     var item = items[i];
  //     const parent = items[i].parentElement;

  //     if (item && item.classList.contains("active")) {
  //       item.classList.remove("active");
  //     }
  //     if (parent) {
  //       const parent2El =
  //         parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
  //           ? parent.childNodes[1]
  //           : null;
  //       if (parent2El && parent2El.id !== "side-menu") {
  //         parent2El.classList.remove("mm-show");
  //       }

  //       parent.classList.remove("mm-active");
  //       const parent2 = parent.parentElement;

  //       if (parent2) {
  //         parent2.classList.remove("mm-show");

  //         const parent3 = parent2.parentElement;
  //         if (parent3) {
  //           parent3.classList.remove("mm-active"); // li
  //           parent3.childNodes[0].classList.remove("mm-active");

  //           const parent4 = parent3.parentElement; // ul
  //           if (parent4) {
  //             parent4.classList.remove("mm-show"); // ul
  //             const parent5 = parent4.parentElement;
  //             if (parent5) {
  //               parent5.classList.remove("mm-show"); // li
  //               parent5.childNodes[0].classList.remove("mm-active"); // a tag
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // };

  // const path = useLocation();
  // const activeMenu = useCallback(() => {
  //   const pathName = path.pathname;
  //   let matchingMenuItem = null;
  //   const ul = document.getElementById("side-menu");
  //   const items = ul.getElementsByTagName("a");
  //   removeActivation(items);

  //   for (let i = 0; i < items.length; ++i) {
  //     if (pathName === items[i].pathname) {
  //       matchingMenuItem = items[i];
  //       break;
  //     }
  //   }
  //   if (matchingMenuItem) {
  //     activateParentDropdown(matchingMenuItem);
  //   }
  // }, [path.pathname, activateParentDropdown]);

  // useEffect(() => {
  //   ref.current.recalculate();
  // }, []);

  // useEffect(() => {
  //   new MetisMenu("#side-menu");
  //   activeMenu();
  // }, []);

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  //   activeMenu();
  // }, [activeMenu]);

  // function scrollElement(item) {
  //   if (item) {
  //     const currentPosition = item.offsetTop;
  //     if (currentPosition > window.innerHeight) {
  //       ref.current.getScrollElement().scrollTop = currentPosition - 300;
  //     }
  //   }
  // }

  const ADMINSIDEBAR = [
    {
      title: 'Dashboard',
      icon: 'home',
      path: '/'
    },
    {
      title: 'Attorneys',
      icon: 'list',
      path: '/admin/attorneys'
    },
    {
      title: 'Employers',
      icon: 'list',
      path: '/admin/Employers'
    },
    {
      title: 'Case Types',
      icon: 'list',
      path: '/admin/case-types'
    },
    {
      title: 'Case Forms',
      icon: 'list',
      path: '/admin/case-forms'
    },

    {
      title: 'Qnnr Types',
      icon: 'gift',
      path: '/admin/questionnaire-types'
    }
  ]

  const ATTORNEYSIDEBAR = [
    {
      title: 'Dashboard',
      icon: 'home',
      path: '/attorney/'
    },
    // {
    //   title: 'CompanyInfo',
    //   icon: 'list',
    //   path: '/attorney/companyinfo'
    // },
    {
      title: 'Contacts',
      icon: 'list',
      path: '/attorney/contacts'
    },
    {
      title: 'Cases',
      icon: 'gift',
      path: '/attorney/cases'
    },
    {
      title: 'Master Docs',
      icon: 'gift',
      path: '/attorney/master-docs'
    },
    // {
    //   title: 'Invoices',
    //   icon: 'map',
    //   path: '/attorney/invoices'
    // },
    // {
    //   title: 'Payroll',
    //   icon: 'map',
    //   path: '/attorney/payroll'
    // },
    /*  {
        title: 'Reminders',
        icon: 'briefcase',
        path: '/attorney/reminders'
      },
      {
        title: 'Team',
        icon: 'grid',
        path: '/attorney/team'
      },
      {
        title: 'Events',
        icon: 'users',
        path: '/attorney/events'
      },
      {
        title: 'Learning Center',
        icon: 'map',
        path: '/attorney/learningcenter'
      },
      {
        title: 'Support',
        icon: 'map',
        path: '/attorney/support'
      }*/
  ]
  const ATTORNEYPENDINGSIDEBAR = [
    {
      title: 'Registration',
      icon: 'list',
      path: '/attorney/add-company-info'
    },

  ]

  const EMPLOYERSIDEBAR = [
    {
      title: 'Dashboard',
      icon: 'home',
      path: '/employer/'
    },
    // {
    //   title: 'CompanyInfo',
    //   icon: 'list',
    //   path: '/employer/companyinfo'
    // },
    {
      title: 'Cases',
      icon: 'gift',
      path: '/employer/cases',
      list: [
        {
          title: 'Cases',
          icon: 'gift',
          path: '/employer/cases',
        },
        {
          title: 'PAF',
          icon: 'briefcase',
          path: '/employer/paf'
        },
        {
          title: 'Templates',
          icon: 'briefcase',
          path: '/employer/templates'
        }
      ]
    },
    {
      title: 'Employees',
      icon: 'list',
      path: '/employer/employeelist'
    },

    {
      title: 'Timesheets',
      icon: 'briefcase',
      path: '/employer/timesheets'
    },
    {
      title: 'Invoices',
      icon: 'credit-card',
      path: '/employer/invoices'
    },
    {
      title: 'Customers',
      icon: 'users',
      path: '/employer/customers'
    },
    {
      title: 'Invoice Settings',
      icon: 'settings',
      path: '/employer/invoice-settings'
    },
    {
      title: 'Payroll',
      icon: 'map',
      path: '/employer/payroll'
    },
    // {
    //   title: 'Learning Center',
    //   icon: 'map',
    //   path: '/employer/learningcenter'
    // },
    // {
    //   title: 'Support',
    //   icon: 'map',
    //   path: '/employer/support'
    // }
  ]

  const EMPLOYERPENDINGSIDEBAR = [

    {
      title: 'Registration',
      icon: 'list',
      path: '/employer/companyinfo'
    },

  ]

  var EMPLOYEESIDEBAR = [
    {
      title: 'Dashboard',
      icon: 'home',
      path: '/employee/'
    },
    {
      title: 'Cases',
      icon: 'gift',
      path: '/employee/cases'
    },
    // {
    //   title: 'Documents',
    //   icon: 'gift',
    //   path: '/employee/documents'
    // }
    // {
    //   title: 'Forms',
    //   icon: 'map',
    //   path: '/employee/forms'
    // },
    // {
    //   title: 'Support',
    //   icon: 'map',
    //   path: '/employee/support'
    // }
  ];


  const { profile } = useSelector((state) => ({
    profile: state.Profile.profile,
  }));
  let navigation = ADMINSIDEBAR;
  const getSessionUserType = JSON.parse(localStorage.getItem("authUser"));
  const userRole = getSessionUserType?.data?.user_type;
  const is_timesheet_access = getSessionUserType?.data?.is_timesheet_access;
  const userStatus = profile ? profile?.status : getSessionUserType?.data?.status;

  if (is_timesheet_access) {
    EMPLOYEESIDEBAR = [...EMPLOYEESIDEBAR,
    {
      title: 'Timesheets',
      icon: 'gift',
      path: '/employee/timesheets'
    }];
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (userRole != 'superadmin' && userRole != 'admin' && userRole != 'employee')
      dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    if (profile?.status) {
      if (profile.status != "undefined") {
        localStorage.setItem("status", profile?.status);
        localStorage.setItem("plan_type", profile?.plan_type);
      }
    }
  }, [profile]);

  if (userRole == 'Attorney') {
    navigation = userStatus == 'Pending' ? ATTORNEYPENDINGSIDEBAR : ATTORNEYSIDEBAR
  } else if (userRole == 'Petitioner') {
    navigation = userStatus == 'Pending' ? EMPLOYERPENDINGSIDEBAR : EMPLOYERSIDEBAR
  } else if (userRole == 'Employee') {
    navigation = EMPLOYEESIDEBAR
  } else if (userRole == 'superadmin' || userRole == 'admin') {
    navigation = ADMINSIDEBAR
  } else {
    navigation = EMPLOYEESIDEBAR
  }
  /** Horizental menu items */


  const attributes_navigation = [
    {
      title: 'Attributes',
      icon: 'briefcase',
      path: '/admin/questionnaire-attributes'
    },
    {
      title: 'Attributes Sets',
      icon: 'grid',
      path: '/admin/questionnaire-attributes-sets'
    },
    {
      title: 'Attributes Groups',
      icon: 'users',
      path: '/admin/questionnaire-attributes-groups'
    },
    {
      title: 'Qnnr Templates',
      icon: 'map',
      path: '/admin/questionnaire-templates'
    }
  ];
  const caseformattributes_navigation = [
    {
      title: 'Case Form Attributes',
      icon: 'list',
      path: '/admin/case-form-attributes'
    },
    {
      title: 'Case Form Attribute Sets',
      icon: 'list',
      path: '/admin/case-form-attribute-sets'
    },
    {
      title: 'Case Form Attribute Groups',
      icon: 'list',
      path: '/admin/case-form-attribute-groups'
    },
    {
      title: 'Case Form Template',
      icon: 'list',
      path: '/admin/case-form-attribute-templates'
    },

  ];
  return (
    <React.Fragment>
      {/* <div className="topnav"  style={{ maxWidth: "100%" }} ref={ref}> */}
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">

                {navigation.map(item => (
                  <li key={item.title} className="nav-item dropdown">
                    <Link
                      to={`${item.path}`}
                      onClick={e => {
                        if ('list' in item) {
                          e.preventDefault();
                          setDropdownlist(!dropdownlist);
                        }
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <FeatherIcon
                        icon={item.icon}
                      />
                      <span>{props.t(item.title)}</span>
                      {('list' in item) && (
                        <div className="arrow-down"></div>
                      )}
                    </Link>
                    {('list' in item) && Object.keys(item.list).length >= 1 && (
                      < div className={classname("dropdown-menu",
                        // { show: dropdownlist }
                      )}>
                        {item.list.map(item => (
                          <Link to={`${item.path}`} key={item.title}
                            className="dropdown-item"
                          >
                            <span>{props.t(item.title)}</span>
                          </Link>
                        ))}
                      </div>
                    )}
                  </li>
                ))}

                {(userRole == 'superadmin' || userRole == 'admin') && (
                  <>
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          setapp(!app);
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <FeatherIcon
                          icon="grid"
                        />
                        {props.t("Qnnr Templates")} <div className="arrow-down"></div>
                      </Link>
                      <div className={classname("dropdown-menu"/*, { show: app }*/)}>

                        {attributes_navigation.map(item => (
                          <Link to={`${item.path}`} key={item.title}
                            className="dropdown-item"
                          >
                            <span>{props.t(item.title)}</span>
                          </Link>
                        ))}
                      </div>

                    </li>

                    <li className="nav-item dropdown">

                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          setapp(!app);
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <FeatherIcon
                          icon="grid"
                        />
                        {props.t("CF Templates")} <div className="arrow-down"></div>
                      </Link>

                      <div className={classname("dropdown-menu"/*, { show: app }*/)}>

                        {caseformattributes_navigation.map(item => (
                          <Link to={`${item.path}`} key={item.title}
                            className="dropdown-item"
                          >
                            <span>{props.t(item.title)}</span>
                          </Link>
                        ))}
                      </div>
                    </li>
                  </>
                )}

              </ul>

            </Collapse>

          </nav>
        </div>
      </div>
    </React.Fragment >
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default
  withRouter(connect(mapStatetoProps, {})(withTranslation()(Navbar))
  );
