import {
  GET_EMPLOYERCONTACTS_FAIL,
  GET_EMPLOYERCONTACTS_SUCCESS,
  GET_EMPLOYERCONTACT_DETAIL_SUCCESS,
  GET_EMPLOYERCONTACT_DETAIL_FAIL,
  UPDATE_EMPLOYERCONTACT_DETAIL_FAIL,
  UPDATE_EMPLOYERCONTACT_DETAIL_SUCCESS,
  DELETE_EMPLOYERCONTACT_DETAIL_FAIL,
  DELETE_EMPLOYERCONTACT_DETAIL_SUCCESS,
  ADD_EMPLOYERCONTACT_DETAIL_SUCCESS,
  ADD_EMPLOYERCONTACT_DETAIL_FAIL,
  UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL_SUCCESS,
  UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL_FAIL,
  UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL_SUCCESS,
  UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL_FAIL,
  UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL,
  UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL_SUCCESS,
  UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL_FAIL,
  GET_EMPLOYEREMPLOYEE_COUNTRIES_SUCCESS,
  GET_EMPLOYEREMPLOYEE_COUNTRIES_FAIL,
  GET_EMPLOYEREMPLOYEE_STATES_SUCCESS,
  GET_EMPLOYEREMPLOYEE_STATES_FAIL,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAIL,
  ADD_EMPLOYEREMPLOYEE_DOC_SUCCESS,
  ADD_EMPLOYEREMPLOYEE_DOC_FAIL,
  SHARE_EMPLOYEREMPLOYEE_DOC_SUCCESS,
  SHARE_EMPLOYEREMPLOYEE_DOC_FAIL,
  ASSIGN_DOC_TO_CASE_SUCCESS,
  ASSIGN_DOC_TO_CASE_FAIL,
  GET_DOCUMENTS_BYCASE_SUCCESS,
  GET_DOCUMENTS_BYCASE_FAIL,
  VALIDATE_EMPLOYEE_EMAILID_SUCCESS,
  VALIDATE_EMPLOYEE_EMAILID_FAIL

} from "./actionTypes"

const INIT_STATE = {
  employercontacts: [],
  employercontactDetail: [],
  employerEmpJobDetail:[],
  employerEmpPayDetail:[],
  countries:[],
  states: [],
  shareDocument: [],
  assignDocToCase: [],
  documentsByCase: [],
  validateDuplicateEmployeeEmail:[],
  error: {},
}

const EmployerContacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYERCONTACTS_SUCCESS:
      return {
        ...state,
        employercontacts: action.payload,
      }

    case GET_EMPLOYERCONTACTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYERCONTACT_DETAIL_SUCCESS:
      return {
        ...state,
        employercontactDetail: action.payload,
      }

    case GET_EMPLOYERCONTACT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_EMPLOYERCONTACT_DETAIL_SUCCESS:
      return {
        ...state,
        // employercontacts: [...state.employercontacts, action.payload],
      }

    case ADD_EMPLOYERCONTACT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_EMPLOYERCONTACT_DETAIL_SUCCESS:
      return {
        ...state,
        // employercontacts: state.employercontacts.filter((item) => item.id !== action.payload),
      };

    case DELETE_EMPLOYERCONTACT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_EMPLOYERCONTACT_DETAIL_SUCCESS:
      return {
        ...state,
        // employercontacts: state.employercontacts.map((row) =>
        //   row.id.toString() === action.payload.id.toString()
        //     ? { row, ...action.payload }
        //     : row
        // ),
      };

    case UPDATE_EMPLOYERCONTACT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL_SUCCESS:
      return {
        ...state,
        employerEmpJobDetail: action.payload,
      }

    case UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL_SUCCESS:
      return {
        ...state,
        employerEmpPayDetail: action.payload,
      }

    case UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL_SUCCESS:
      return {
        ...state,
        employerEmpPayDetail: action.payload,
      }

    case UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYEREMPLOYEE_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
      };

    case GET_EMPLOYEREMPLOYEE_COUNTRIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_EMPLOYEREMPLOYEE_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
      };

    case GET_EMPLOYEREMPLOYEE_STATES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CREATE_CUSTOMER_SUCCESS:
      return {
        ...state
        //states: action.payload,
      };

    case CREATE_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_EMPLOYEREMPLOYEE_DOC_SUCCESS:
      return {
        ...state
        //states: action.payload,
      };

    case ADD_EMPLOYEREMPLOYEE_DOC_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case SHARE_EMPLOYEREMPLOYEE_DOC_SUCCESS:
      return {
        ...state,
        shareDocument: action.payload,
      };

    case SHARE_EMPLOYEREMPLOYEE_DOC_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    
    case ASSIGN_DOC_TO_CASE_SUCCESS:
      return {
        ...state,
        assignDocToCase: action.payload,
      };

    case ASSIGN_DOC_TO_CASE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_DOCUMENTS_BYCASE_SUCCESS:
      return {
        ...state,
        documentsByCase: action.payload,
      }

    case GET_DOCUMENTS_BYCASE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case VALIDATE_EMPLOYEE_EMAILID_SUCCESS:
      return {
        ...state,
        validateDuplicateEmployeeEmail: action.payload,
      }

    case VALIDATE_EMPLOYEE_EMAILID_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state
  }
}

export default EmployerContacts
