import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_EMPLOYERS, GET_EMPLOYER_DETAILS, UPDATE_EMPLOYER_DETAILS } from "./actionTypes"
import {
  getEmployersSuccess,
  getEmployersFail,
  getEmployerDetailsSuccess,
  getEmployerDetailsFail,
  updateEmployerSuccess,
  updateEmployerFail
} from "./actions"

import { changePreloader } from "../../../store/actions"

//Include Both Helper File with needed methods
import { getEmployersAPIHelper, getEmployerDetailsAPIHelper, updateEmployerDetailsAPIHelper } from "../../../helpers/apibackend_helper"

function* fetchEmployers({ payload: form }) {
  try {
    const response = yield call(getEmployersAPIHelper, form)
    yield put(getEmployersSuccess(response.data))
  } catch (error) {
    yield put(getEmployersFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchEmployerDetails({ EmployerId }) {
  try {
    const response = yield call(getEmployerDetailsAPIHelper, EmployerId)
    yield put(getEmployerDetailsSuccess(response.data))
  } catch (error) {
    yield put(getEmployerDetailsFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* updateEmployerDetails({ EmployerDetails }) {
  try {
    const response = yield call(updateEmployerDetailsAPIHelper, EmployerDetails)
    yield put(updateEmployerSuccess(response.data))
  } catch (error) {
    yield put(updateEmployerFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* Employertypesaga() {
  yield takeEvery(GET_EMPLOYERS, fetchEmployers)
  yield takeEvery(GET_EMPLOYER_DETAILS, fetchEmployerDetails)
  yield takeEvery(UPDATE_EMPLOYER_DETAILS, updateEmployerDetails)
}

export default Employertypesaga
