import React, { useEffect, useMemo, useState } from "react";
import DatatableContainer from "../../../components/Common/DatatableContainer";
import {
  Link, useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

import { fetchEmployeeTimesheets } from "../../../store/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  CommonCell, Date, AttributeName
} from "./timesheetsListCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";
import moment from "moment";
import { deleteTimesheet } from "../../../store/employer/timesheets/actions";


const AttributesList = () => {

  //meta title
  document.title = "Timesheets | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [order, setOrder] = useState(null);

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);

  //const employeeTimesheets = [];
  const { employeeTimehseets } = useSelector((state) => ({
    employeeTimehseets: state.EmployeeTimesheets.employeeTimehseets ? state.EmployeeTimesheets.employeeTimehseets : [],
  }));

  useEffect(() => {
    dispatch(fetchEmployeeTimesheets({ "status": "All" }));
  }, [dispatch]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {
    navigate(`/employee/timesheets/add`);
  };


  const handleDeleteTimesheet = () => {
    if (order.id) {
      dispatch(deleteTimesheet(order.id));
      setDeleteModal(false);
      setTimeout(() => {
        dispatch(fetchEmployeeTimesheets({ "status": "All" }));
      }, 500);
    }
  };

  const onUpdateData = (data) => {
    navigate(`/employee/view-timesheet?timesheet-id=${data.id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "Client/Vendor",
      //   accessor: "name",
      //   filterable: true,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     return <CommonCell {...cellProps} />;
      //   },
      // },

      {
        Header: "Time Period",
        accessor: "period",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "Date Submitted",
        accessor: "submitted_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Total Hours",
        accessor: "total_hours",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Comment",
        accessor: "comment",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onUpdateData(cellProps.row.original)}>View</DropdownItem>
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  let title = 'Timesheets';
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/employee/dashboard' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTimesheet}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={employeeTimehseets}
                    isGlobalFilter={true}
                    isAddTimesheets={true}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AttributesList;
