import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
// import calendarSaga from "./calendar/saga"
// import chatSaga from "./chat/saga"
import invoiceSaga from "./invoices/saga"
import customerSaga from "./customers/saga"
import templateSaga from "./templates/saga"
import userSaga from "./users/saga"
import profileSaga from "./profile/saga"
import contactsSaga from "./contacts/saga";
//import dashboard
import dashBoardSaga from "./Dashboard/saga"

//New routes
import qnnrtypeSaga from "./admin/qnnrtypes/saga"
import casetypeSaga from "./admin/casetypes/saga"
import attributesSaga from "./admin/attributes/saga"
import attributesetsSaga from "./admin/attributesets/saga"
import attributegroupsSaga from "./admin/attributegroups/saga"
import attributetemplatesSaga from "./admin/attributetemplates/saga"
import AttorneysSaga from "./admin/attorneys/saga"
import EmployersSaga from "./admin/employers/saga"
//New routes
import AttorneyCompanySaga from "./attorney/companyinfo/saga"
import AttorneyContactsSaga from "./attorney/contacts/saga"
import AttorneyDashoadSaga from "./attorney/dashboard/saga"
import AttorneyCasesSaga from "./attorney/cases/saga"

//New routes
import EmployerCompanySaga from "./employer/companyinfo/saga"
import EmployerContactsSaga from "./employer/contacts/saga"
import EmployerCasesSaga from "./employer/cases/saga"
import EmployerDashoadSaga from "./employer/dashboard/saga"
//Employee 
import EmployeeCasesSaga from "./employee/cases/saga"
//AlertInfo
import AlertInfoSaga from "./alertinfo/saga"

import CaseFormAttributesets from "./admin/caseformattributesets/saga"
import CaseFormAttributes from "./admin/caseformattributes/saga"

import CaseFormAttributeTemplates from "./admin/caseformattributestemplates/saga"
import CaseFormsList from "./admin/caseforms/saga"
import CaseformattributesGroups from "./admin/caseformattributesgroups/saga"
import timesheetSaga from "./employee/timesheets/saga"
import employerTimesheetSaga from "./employer/timesheets/saga"
import EmployerPayrollsaga from "./employer/payroll/saga"
import AttorneyMasterDocsSaga from "./attorney/masterdocs/saga"
import CommonAlertSaga from "./common/saga"
import EmployerEsignsaga from "./employer/esign/saga"


export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    // fork(calendarSaga),
    // fork(chatSaga),
    fork(invoiceSaga),
    fork(customerSaga),
    fork(templateSaga),
    fork(userSaga), 
    fork(profileSaga),
    fork(qnnrtypeSaga),
    fork(casetypeSaga),
    fork(attributesSaga),
    fork(attributesetsSaga),
    fork(attributegroupsSaga),
    fork(attributetemplatesSaga),
    fork(contactsSaga),
    fork(dashBoardSaga),
    fork(AttorneyCompanySaga),
    fork(AttorneyContactsSaga),
    fork(AttorneyDashoadSaga),
    fork(AttorneyCasesSaga),
    fork(AttorneysSaga),
    fork(EmployersSaga),
    fork(EmployerCompanySaga),
    fork(EmployerContactsSaga),
    fork(EmployerDashoadSaga),
    fork(EmployerCasesSaga),
    fork(EmployeeCasesSaga),
    fork(AlertInfoSaga),
    fork(CaseFormAttributesets),
    fork(CaseFormAttributes),
    fork(CaseFormAttributeTemplates),
    fork(CaseFormsList),
    fork(CaseformattributesGroups),
    fork(timesheetSaga),
    fork(employerTimesheetSaga),
    fork(EmployerPayrollsaga),
    fork(AttorneyMasterDocsSaga),
    fork(CommonAlertSaga),
    fork(EmployerEsignsaga),

  ])
}
