import React from 'react';
import Label from "./Label";
import {
	FormGroup,Input
} from "reactstrap";
import { checkValidityClass } from "../Shared/utility";
const Select = ({ invalidMessage, className, value, onChanged, defaultOption, content }) => {
    let options = content?.values;
    return (
        <FormGroup className="mb-3" key={content?.name}>
              <Label
                label={content?.label}
                name={content?.name}
                isRequired={content?.required}
            />
            <Input
                className={`${checkValidityClass(invalidMessage)} ${className ? className : `form-control form-control-sm`}`}
                id={content?.name}
                name={content?.name}
                required={content?.required}
                multiple={content?.multiple}
                type='select'
                value={value}
                onChange={onChanged}
                aria-describedby={`invalid_${content?.name}`}>
                <option value="">{defaultOption ? defaultOption : '--Select--'}</option>
                {options && options.map((row, index) => (
                    <option key={row?.label + index} value={row?.value}>{row.label}</option>
                ))}
            </Input>
            <div type="invalid" className="invalid-feedback">{invalidMessage}</div>
        </FormGroup>
    );
};

export default Select;