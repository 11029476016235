/* QNNRTYPES */
export const GET_ATTRIBUTEGROUPS = "GET_ATTRIBUTEGROUPS"
export const GET_ATTRIBUTEGROUPS_SUCCESS = "GET_ATTRIBUTEGROUPS_SUCCESS"
export const GET_ATTRIBUTEGROUPS_FAIL = "GET_ATTRIBUTEGROUPS_FAIL"

export const GET_ATTRIBUTEGROUP_DETAIL = "GET_ATTRIBUTEGROUP_DETAIL"
export const GET_ATTRIBUTEGROUP_DETAIL_SUCCESS = "GET_ATTRIBUTEGROUP_DETAIL_SUCCESS"
export const GET_ATTRIBUTEGROUP_DETAIL_FAIL = "GET_ATTRIBUTEGROUP_DETAIL_FAIL"

export const ADD_ATTRIBUTEGROUP_DETAIL = "ADD_ATTRIBUTEGROUP_DETAIL"
export const ADD_ATTRIBUTEGROUP_DETAIL_SUCCESS = "ADD_ATTRIBUTEGROUP_DETAIL_SUCCESS"
export const ADD_ATTRIBUTEGROUP_DETAIL_FAIL = "ADD_ATTRIBUTEGROUP_DETAIL_FAIL"

export const DELETE_ATTRIBUTEGROUP_DETAIL = "DELETE_ATTRIBUTEGROUP_DETAIL"
export const DELETE_ATTRIBUTEGROUP_DETAIL_SUCCESS = "DELETE_ATTRIBUTEGROUP_DETAIL_SUCCESS"
export const DELETE_ATTRIBUTEGROUP_DETAIL_FAIL = "DELETE_ATTRIBUTEGROUP_DETAIL_FAIL"

export const UPDATE_ATTRIBUTEGROUP_DETAIL = "UPDATE_ATTRIBUTEGROUP_DETAIL"
export const UPDATE_ATTRIBUTEGROUP_DETAIL_SUCCESS = "UPDATE_ATTRIBUTEGROUP_DETAIL_SUCCESS"
export const UPDATE_ATTRIBUTEGROUP_DETAIL_FAIL = "UPDATE_ATTRIBUTEGROUP_DETAIL_FAIL"
