import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_QNNRTYPES, GET_QNNRTYPE_DETAIL, ADD_QNNRTYPE_DETAIL, DELETE_QNNRTYPE_DETAIL, UPDATE_QNNRTYPE_DETAIL } from "./actionTypes"
import {
  getQnnrtypesSuccess,
  getQnnrtypesFail,
  getQnnrtypeDetailSuccess,
  getQnnrtypeDetailFail,
  addQnnrtypedetailSuccess,
  addQnnrtypedetailFail,
  qnnrtypeDeleteSuccess,
  qnnrtypeDeleteFail,
  qnnrtypeUpdateSuccess,
  qnnrtypeUpdateFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getQnnrtypes, getQnnrtypeDetail, addQnnrtype, deleteQnnrtype, updateQnnrtype } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"
import { changePreloader } from "../../../store/actions"

function* fetchQnnrtypes({ id }) {
  try {
    const response = yield call(getQnnrtypes, id)
    yield put(getQnnrtypesSuccess(response.data))
  } catch (error) {
    yield put(getQnnrtypesFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchQnnrtypeDetail({ QnnrtypeId }) {
  try {
    const response = yield call(getQnnrtypeDetail, QnnrtypeId)
    yield put(getQnnrtypeDetailSuccess(response.data))
  } catch (error) {
    yield put(getQnnrtypeDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* addQnnrtypeDetail({ payload: user }) {
  try {
    const response = yield call(addQnnrtype, user)
    yield put(addQnnrtypedetailSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addQnnrtypedetailFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteQnnrtype({ payload: data }) {
  try {
    const response = yield call(deleteQnnrtype, data);
    yield put(qnnrtypeDeleteSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(qnnrtypeDeleteFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateQnnrtype({ payload: data }) {
  try {
    const response = yield call(updateQnnrtype, data);
    yield put(qnnrtypeUpdateSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(qnnrtypeUpdateFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* Qnnrtypesaga() {
  yield takeEvery(GET_QNNRTYPES, fetchQnnrtypes)
  yield takeEvery(GET_QNNRTYPE_DETAIL, fetchQnnrtypeDetail)
  yield takeEvery(ADD_QNNRTYPE_DETAIL, addQnnrtypeDetail)
  yield takeEvery(DELETE_QNNRTYPE_DETAIL, onDeleteQnnrtype)
  yield takeEvery(UPDATE_QNNRTYPE_DETAIL, onUpdateQnnrtype)
}

export default Qnnrtypesaga
