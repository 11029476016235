import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ATTRIBUTESETS, GET_ATTRIBUTESET_DETAIL, ADD_ATTRIBUTESET_DETAIL, DELETE_ATTRIBUTESET_DETAIL, UPDATE_ATTRIBUTESET_DETAIL } from "./actionTypes"
import {
  getAttributesetsSuccess,
  getAttributesetsFail,
  getAttributesetDetailSuccess,
  getAttributesetDetailFail,
  addAttributesetdetailSuccess,
  addAttributesetdetailFail,
  AttributesetDeleteSuccess,
  AttributesetDeleteFail,
  AttributesetUpdateSuccess,
  AttributesetUpdateFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getAttributesets, getAttributesetDetail, addAttributeset, deleteAttributeset, updateAttributeset } from "../../../helpers/apibackend_helper"
import { useAlert } from "../../../components/Common/Alert";
import { setAlertMessage } from "../../common/actions";
import { changePreloader } from "../../../store/actions"

function* fetchAttributesets() {
  try {
    const response = yield call(getAttributesets)
    yield put(getAttributesetsSuccess(response.data))
  } catch (error) {
    yield put(getAttributesetsFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchAttributesetDetail({ AttributesetId }) {
  try {
    if (AttributesetId) {
      const response = yield call(getAttributesetDetail, AttributesetId)
      yield put(getAttributesetDetailSuccess(response.data))
    }
    else {
      yield put(getAttributesetDetailSuccess({}))
    }
  } catch (error) {
    yield put(getAttributesetDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* addAttributesetDetail({ payload: user }) {
  try {
    const response = yield call(addAttributeset, user)
    yield put(addAttributesetdetailSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addAttributesetdetailFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteAttributeset({ payload: data }) {
  try {
    const response = yield call(deleteAttributeset, data);
    yield put(AttributesetDeleteSuccess(data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(AttributesetDeleteFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateAttributeset({ payload: data }) {
  try {
    const response = yield call(updateAttributeset, data);
    yield put(AttributesetUpdateSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(AttributesetUpdateFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* Attributesetsaga() {
  yield takeEvery(GET_ATTRIBUTESETS, fetchAttributesets)
  yield takeEvery(GET_ATTRIBUTESET_DETAIL, fetchAttributesetDetail)
  yield takeEvery(ADD_ATTRIBUTESET_DETAIL, addAttributesetDetail)
  yield takeEvery(DELETE_ATTRIBUTESET_DETAIL, onDeleteAttributeset)
  yield takeEvery(UPDATE_ATTRIBUTESET_DETAIL, onUpdateAttributeset)
}

export default Attributesetsaga
