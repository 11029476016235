import {
  GET_EMPLOYER_ESIGN_FAIL,
  GET_EMPLOYER_ESIGN_SUCCESS,
  UPLOAD_EMPLOYER_ESIGN_FAIL,
  UPLOAD_EMPLOYER_ESIGN_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  employerEsignDetails: [],
  employerProfile: {},
  error: {},
}

const employerEsign = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYER_ESIGN_SUCCESS:
      return {
        ...state,
        employerProfile: action.payload,
      }

    case GET_EMPLOYER_ESIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPLOAD_EMPLOYER_ESIGN_SUCCESS:
      return {
        ...state,
        employerEsignDetails: action.payload,
      }

    case UPLOAD_EMPLOYER_ESIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      
    default:
      return state
  }
}

export default employerEsign
