/* CUSTOMERS */
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"

export const GET_CUSTOMER_DETAIL = "GET_CUSTOMER_DETAIL"
export const GET_CUSTOMER_DETAIL_SUCCESS = "GET_CUSTOMER_DETAIL_SUCCESS"
export const GET_CUSTOMER_DETAIL_FAIL = "GET_CUSTOMER_DETAIL_FAIL"

export const ADD_CUSTOMER_DETAIL = "ADD_CUSTOMER_DETAIL"
export const ADD_CUSTOMER_DETAIL_SUCCESS = "ADD_CUSTOMER_DETAIL_SUCCESS"
export const ADD_CUSTOMER_DETAIL_FAIL = "ADD_CUSTOMER_DETAIL_FAIL"

export const SEND_CUSTOMER = "SEND_CUSTOMER"
export const SEND_CUSTOMER_SUCCESS = "SEND_CUSTOMER_SUCCESS"
export const SEND_CUSTOMER_FAIL = "SEND_CUSTOMER_FAIL"

export const UPDATE_CUSTOMER_PDF = "UPDATE_CUSTOMER_PDF"
export const UPDATE_CUSTOMER_PDF_SUCCESS = "UPDATE_CUSTOMER_PDF_SUCCESS"
export const UPDATE_CUSTOMER_PDF_FAIL = "UPDATE_CUSTOMER_PDF_FAIL"

export const RECORD_PAYMENT = "RECORD_PAYMENT"
export const RECORD_PAYMENT_SUCCESS = "RECORD_PAYMENT_SUCCESS"
export const RECORD_PAYMENT_FAIL = "RECORD_PAYMENT_FAIL"

export const GET_CUSTOMER_SETTINGS = "GET_CUSTOMER_SETTINGS"
export const GET_CUSTOMER_SETTINGS_SUCCESS = "GET_CUSTOMER_SETTINGS_SUCCESS"
export const GET_CUSTOMER_SETTINGS_FAIL = "GET_CUSTOMER_SETTINGS_FAIL"

export const UPLOAD_CUSTOMER_ATTACHMENT = "UPLOAD_CUSTOMER_ATTACHMENT"
export const UPLOAD_CUSTOMER_ATTACHMENT_SUCCESS = "UPLOAD_CUSTOMER_ATTACHMENT_SUCCESS"
export const UPLOAD_CUSTOMER_ATTACHMENT_FAIL = "UPLOAD_CUSTOMER_ATTACHMENT_FAIL"

export const DELETE_CUSTOMER_DETAIL = "DELETE_CUSTOMER_DETAIL"
export const DELETE_CUSTOMER_DETAIL_SUCCESS = "DELETE_CUSTOMER_DETAIL_SUCCESS"
export const DELETE_CUSTOMER_DETAIL_FAIL = "DELETE_CUSTOMER_DETAIL_FAIL"

export const UPDATE_CUSTOMER_DETAIL = "UPDATE_CUSTOMER_DETAIL"
export const UPDATE_CUSTOMER_DETAIL_SUCCESS = "UPDATE_CUSTOMER_DETAIL_SUCCESS"
export const UPDATE_CUSTOMER_DETAIL_FAIL = "UPDATE_CUSTOMER_DETAIL_FAIL"
