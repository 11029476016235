import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input, FormGroup, Button, FormFeedback } from "reactstrap"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";


import { getAttorneyCaseDetail, AttorneyCaseUpdate, updateAttorneyLCADetails } from "../../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import { setAlertMessage } from "../../../../store/common/actions";
import { getDateFormatYMD } from "../../../../common/data/dateFormat";



const UpdateLCADetails = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const caseId = queryParams.get('id'); // Read the 'id' query parameter from the URL

    useEffect(() => {
        dispatch(getAttorneyCaseDetail({
            case_id: caseId
        }));
    }, [caseId]);

    const { caseDetail } = useSelector((state) => ({
        caseDetail: state.AttorneyCases.caseDetail || [],
    }));

    const { user_type } = useSelector((state) => ({
		user_type: state.login.user_type || localStorage.getItem('user_type'),
	}));

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            case_id: caseId,
            lca_file_path: "",
            //lca_file_path:validation.values.lca_file_path,
            lca_number: caseDetail.lca_number,
            soc_occupation_title: caseDetail.soc_occupation_title,
            soc_code: caseDetail.soc_code,
            lca_date_applied: caseDetail.lca_date_applied,
            wages: caseDetail.wages,
            wage_level: caseDetail.wage_level,
            wages_cycle: caseDetail.wages_cycle,
            lca_status: caseDetail.lca_status,
            lca_from_date: caseDetail.lca_from_date,
            lca_to_date: caseDetail.lca_to_date,
            lca_job_title: caseDetail.lca_job_title
        },
        validationSchema: Yup.object({
            //lca_file_path: Yup.mixed().required('Please select document'),
            lca_number: Yup.string().required("Please enter LCA number").nullable(),
            wage_level: Yup.string().required("Please select wage level").nullable(),
            lca_date_applied: Yup.string().required("Please select LCA applied date").nullable(),
            lca_from_date: Yup.string().required("Please select LCA from date").nullable(),
            lca_to_date: Yup.string().required("Please select LCA to date").nullable(),
            soc_occupation_title: Yup.string().required("Please enter SOC occupation title").nullable(),
            soc_code: Yup.string().required("Please enter SOC code").nullable(),
            wages: Yup.string().required("Please enter Wage").nullable(),
            lca_job_title: Yup.string().required("Please enter Job title").nullable(),
            wages_cycle: Yup.string().required("Please select Wage cycle").nullable(),
            lca_status: Yup.string().required("Please select LCA status").nullable(),
            // emp_type: Yup.string().required("Please select Employee Type"),

        }),
        onSubmit: (values) => {
            let data = { ...values, lca_date_applied: getDateFormatYMD(values.lca_date_applied), lca_from_date: getDateFormatYMD(values.lca_from_date), lca_to_date: getDateFormatYMD(values.lca_to_date) };
            dispatch(updateAttorneyLCADetails(data));

            setTimeout(() => {
                navigate("/"+user_type+"/view-case?caseId="+caseId);
            },200)

        }
    });

    const routeTolIsting = () => {
        navigate("/attorney/cases");
    }

    const lcaDocument = (e) => {
        //validation.setFieldValue('lca_file_path', e.target.files[0].name)
    }

    let title = 'Update LCA details';
    const BreadcrumbData = [
        { name: 'Cases', url: '/attorney/Cases' },
        { name: 'View Case', url: '/attorney/view-case?caseId=' + caseId },
        { name: title, url: '#' }
    ]
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">LCA information</h4>

                                </CardHeader>

                                <CardBody className="p-4">
                                    <Form className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            if (_.size(validation.errors) > 0) {
                                                const errorObj = { "response": { "status": "400", "data": { "message": "Error - Please fill required form fields" } } }
                                                dispatch(setAlertMessage({ message: errorObj, type: "danger" }));
                                              }
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        {/* import TextualInputs */}
                                        <div className="mt-4 mt-lg-0">

                                            <Row className="mb-4">
                                                <Col sm={5}>
                                                    {/* <Col sm={5}>
                                                <Input
                                                type="file"
                                                className="form-control"
                                                name="lca_file_path"
                                                //onChange={lcaDocument}
                                                onChange={(e) => validation.setFieldValue('lca_file_path', e.target.files[0])}
                                                value={validation.values.lca_file_path}
                                                accept=".pdf"
                                                onBlur={validation.handleBlur}
                                                invalid={
                                                validation.touched.lca_file_path &&
                                                validation.errors.lca_file_path
                                                    ? true
                                                    : false}
                                                />
                                                {validation.touched.lca_file_path &&
                                                            validation.errors.lca_file_path ? (
                                                            <FormFeedback type="invalid">
                                                            {validation.errors.lca_file_path}
                                                            </FormFeedback>
                                                        ) : null} */}
                                                    <Label htmlFor="example-text-input" className="form-Label">Upload LCA File</Label>    
                                                    <Input
                                                        type="file"
                                                        className="form-control"
                                                        name="lca_file_path"
                                                        multiple
                                                        //onChange={()=> {addTimesheetAttachments, validation.handleChange}}
                                                        onChange={(e) => { validation.setFieldValue('lca_file_path', e.target.files[0]) }}
                                                        accept=".pdf"
                                                        //onChange={() => addTimesheetAttachments}
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.lca_file_path &&
                                                                validation.errors.lca_file_path
                                                                ? true
                                                                : false}
                                                    />
                                                    {validation.touched.lca_file_path &&
                                                        validation.errors.lca_file_path ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.lca_file_path}
                                                        </FormFeedback>
                                                    ) : null}
                                                    <Label
                                                        htmlFor="lca_file_path"
                                                        className="col-sm-12 col-form-label"
                                                    >
                                                        Note: You can upload only pdf files.
                                                    </Label>
                                                    {caseDetail.lca_file_path && (
                                                        <p>
                                                            <i className="text-success bx bx-check-circle" style={{"font-size":"16px"}}></i><Link  style={{"font-size":"14px"}} target='_blank' to={caseDetail.lca_file_path ? caseDetail.lca_file_path : 'javascript:void()'}>LCA File</Link>
                                                        </p>
                                                    )}

                                                </Col>

                                            </Row>

                                            <Row>

                                                <Col md="4">
                                                    <div className="mb-6">
                                                        <Label htmlFor="example-text-input" className="form-Label">LCA Number *</Label>
                                                        <Input className="form-control" type="text"
                                                            name="lca_number" id="example-text-input"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleChange}
                                                            value={validation.values.lca_number}
                                                            invalid={
                                                                validation.touched.lca_number && validation.errors.lca_number ? true : false
                                                            } />
                                                        {validation.touched.lca_number && validation.errors.lca_number ? (
                                                            <FormFeedback type="invalid">{validation.errors.lca_number}</FormFeedback>
                                                        ) : null}


                                                    </div>

                                                </Col>


                                                <Col md="4">
                                                    <div className="mb-6">
                                                        <Label htmlFor="example-text-input" className="form-Label">SOC Occupation Title *</Label>
                                                        <Input className="form-control" type="text" placeholder="SOC Occupation Title"
                                                            name="soc_occupation_title" id="example-text-input"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleChange}
                                                            value={validation.values.soc_occupation_title}
                                                            invalid={
                                                                validation.touched.soc_occupation_title && validation.errors.soc_occupation_title ? true : false
                                                            } />

                                                        {validation.touched.soc_occupation_title && validation.errors.soc_occupation_title ? (
                                                            <FormFeedback type="invalid">{validation.errors.soc_occupation_title}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>


                                                <Col md="4">
                                                    <div className="mb-6">
                                                        <Label htmlFor="example-text-input" className="form-Label">SOC Code *</Label>
                                                        <Input className="form-control" type="text" placeholder="SOC Code"
                                                            name="soc_code" id="example-text-input"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleChange}
                                                            value={validation.values.soc_code}
                                                            invalid={
                                                                validation.touched.soc_code && validation.errors.soc_code ? true : false
                                                            } />
                                                        {validation.touched.soc_code && validation.errors.soc_code ? (
                                                            <FormFeedback type="invalid">{validation.errors.soc_code}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label htmlFor="lca_date_applied" className="form-Label">LCA Applied Date *</Label>
                                                        <ReactDatePicker
                                                            showYearDropdown={true}
                                                            showIcon
                                                            name="lca_date_applied"
                                                            id="lca_date_applied"
                                                            placeholderText="MM/dd/yyyy"
                                                            className={`form-control ${validation.touched.lca_date_applied && validation.errors.lca_date_applied ? 'is-invalid' : ''}`}
                                                            dateFormat="MM/dd/yyyy"
                                                            onChange={(date) => {
                                                                validation.setFieldValue('lca_date_applied', date)
                                                            }
                                                            }
                                                            selected={validation?.values?.lca_date_applied ? new Date(validation.values.lca_date_applied) : ''}
                                                        />

                                                        {validation.touched.lca_date_applied && validation.errors.lca_date_applied ? (
                                                            <FormFeedback type="invalid">{validation.errors.lca_date_applied}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>
                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label className="form-Label">Wage Level *</Label>
                                                        <Input
                                                            name="wage_level"
                                                            type="select"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.wage_level}
                                                            invalid={
                                                                validation.touched.wage_level && validation.errors.wage_level ? true : false
                                                            }
                                                        >
                                                            <option value=''>Select</option>
                                                            <option selected={caseDetail.wage_level === "Level 1"} value="Level 1">Level 1</option>
                                                            <option selected={caseDetail.wage_level === "Level 2"} value="Level 2">Level 2</option>
                                                            <option selected={caseDetail.wage_level === "Level 3"} value="Level 3">Level 3</option>
                                                            <option selected={caseDetail.wage_level === "Level 4"} value="Level 3">Level 4</option>
                                                        </Input>
                                                        {validation.touched.wage_level && validation.errors.wage_level ? (
                                                            <FormFeedback type="invalid">{validation.errors.wage_level}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div className="mb-6">
                                                        <Label htmlFor="example-text-input" className="form-Label">Wage *</Label>
                                                        <Input className="form-control" type="text" placeholder="Wage"
                                                            name="wages" id="example-text-input"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.wages}
                                                            invalid={
                                                                validation.touched.wages && validation.errors.wages ? true : false
                                                            } />
                                                        {validation.touched.wages && validation.errors.wages ? (
                                                            <FormFeedback type="invalid">{validation.errors.wages}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label className="form-Label">Wage Cycle *</Label>
                                                        <Input
                                                            name="wages_cycle"
                                                            type="select"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.wages_cycle}
                                                            invalid={
                                                                validation.touched.wages_cycle && validation.errors.wages_cycle ? true : false
                                                            }
                                                        >
                                                            <option value=''>Select</option>
                                                            <option selected={caseDetail.wages_cycle === "Hour"} value="Hour">Hour</option>
                                                            <option selected={caseDetail.wages_cycle === "Week"} value="Week">Week</option>
                                                            <option selected={caseDetail.wages_cycle === "Month"} value="Month">Month</option>
                                                            <option selected={caseDetail.wages_cycle === "Year"} value="Year">Year</option>
                                                        </Input>


                                                        {validation.touched.wages_cycle && validation.errors.wages_cycle ? (
                                                            <FormFeedback type="invalid">{validation.errors.wages_cycle}</FormFeedback>
                                                        ) : null}

                                                        {/* <Label className="form-Label">Wage Cycle</Label>
                                                        <select className="form-select" name="wages_cycle"
                                                            onChange={validation.handleChange}
                                                            value={validation.values.wages_cycle}>
                                                                <option value=''>Select</option>
                                                                <option selected={caseDetail.wages_cycle==="Hour"} value="Hour">Hour</option>
                                                                <option selected={caseDetail.wages_cycle==="Week"} value="Week">Week</option>
                                                                <option selected={caseDetail.wages_cycle==="Month"} value="Month">Month</option>
                                                        </select>
                                                         {validation.touched.wages_cycle &&
                                                            validation.errors.wages_cycle ? (
                                                            <FormFeedback type="invalid">
                                                            {validation.errors.wages_cycle}
                                                            </FormFeedback>
                                                        ) : null} */}
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label className="form-Label">Status *</Label>
                                                        <Input
                                                            name="lca_status"
                                                            type="select"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.lca_status}
                                                            invalid={
                                                                validation.touched.lca_status && validation.errors.lca_status ? true : false
                                                            }
                                                        >
                                                            <option value=''>Select</option>
                                                            <option selected={caseDetail.lca_status === "Initiated"} value="Initiated">Initiated</option>
                                                            <option selected={caseDetail.lca_status === "Certified"} value="Certified">Certified</option>
                                                            <option selected={caseDetail.lca_status === "Withdrawn"} value="Withdrawn">Withdrawn</option>
                                                        </Input>


                                                        {validation.touched.lca_status && validation.errors.lca_status ? (
                                                            <FormFeedback type="invalid">{validation.errors.lca_status}</FormFeedback>
                                                        ) : null}


                                                        {/* <select className="form-select" name="lca_status"
                                                            onChange={validation.handleChange}
                                                            value={validation.values.lca_status}>
                                                                <option value=''>Select</option>
                                                                <option selected={caseDetail.wages_cycle==="Initiated"} value="Initiated">Initiated</option>
                                                                <option selected={caseDetail.wages_cycle==="Certified"} value="Certified">Certified</option>
                                                                <option selected={caseDetail.wages_cycle==="Withdrawn"} value="Withdrawn">Withdrawn</option>
                                                        </select>
                                                         {validation.touched.lca_status &&
                                                            validation.errors.lca_status ? (
                                                            <FormFeedback type="invalid">
                                                            {validation.errors.lca_status}
                                                            </FormFeedback>
                                                        ) : null} */}
                                                    </div>

                                                </Col>

                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label htmlFor="lca_from_date" className="form-Label">From Date *</Label>
                                                        <ReactDatePicker
                                                            showYearDropdown={true}
                                                            showIcon
                                                            name="lca_from_date"
                                                            id="lca_from_date"
                                                            placeholderText="MM/dd/yyyy"
                                                            className={`form-control ${validation.touched.lca_from_date && validation.errors.lca_from_date ? 'is-invalid' : ''}`}
                                                            dateFormat="MM/dd/yyyy"
                                                            onChange={(date) => {
                                                                validation.setFieldValue('lca_from_date', date)
                                                            }
                                                            }
                                                            selected={validation?.values?.lca_from_date ? new Date(validation.values.lca_from_date) : ''}
                                                        />

                                                        {validation.touched.lca_from_date && validation.errors.lca_from_date ? (
                                                            <FormFeedback type="invalid">{validation.errors.lca_from_date}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>


                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <Label htmlFor="lca_to_date" className="form-Label">To Date *</Label>
                                                        <ReactDatePicker
                                                            showYearDropdown={true}
                                                            showIcon
                                                            name="lca_to_date"
                                                            id="lca_to_date"
                                                            placeholderText="MM/dd/yyyy"
                                                            className={`form-control ${validation.touched.lca_to_date && validation.errors.lca_to_date ? 'is-invalid' : ''}`}
                                                            dateFormat="MM/dd/yyyy"
                                                            onChange={(date) => {
                                                                validation.setFieldValue('lca_to_date', date)
                                                            }
                                                            }
                                                            selected={validation?.values?.lca_to_date ? new Date(validation.values.lca_to_date) : ''}
                                                        />

                                                        {validation.touched.lca_to_date && validation.errors.lca_to_date ? (
                                                            <FormFeedback type="invalid">{validation.errors.lca_to_date}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>

                                                <Col md="4">
                                                    <div className="mb-6">
                                                        <Label htmlFor="example-text-input" className="form-Label">Job Title *</Label>
                                                        <Input className="form-control" type="text" placeholder="Job Title"
                                                            id="example-text-input"
                                                            name="lca_job_title"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.lca_job_title}
                                                            invalid={
                                                                validation.touched.lca_job_title && validation.errors.lca_job_title ? true : false
                                                            } />
                                                        {validation.touched.lca_job_title && validation.errors.lca_job_title ? (
                                                            <FormFeedback type="invalid">{validation.errors.lca_job_title}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{ float: "right" }}>
                                                <Col >
                                                    <span className="mt-4">
                                                        <button type="reset" className="btn btn-secondary" color="secondary" onClick={() => navigate(-1)}>
                                                            Cancel
                                                        </button>
                                                    </span>
                                                    &nbsp;&nbsp;
                                                    <span className="mt-4">
                                                        <button type="submit" className="btn btn-primary">
                                                            Save
                                                        </button>
                                                    </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment >
    );
};

export default UpdateLCADetails;