
import { takeLatest, put, delay,takeEvery } from 'redux-saga/effects';
// Login Redux States
import { SHOW_ALERT_FLAG,DISABLE_ALERT_FLAG } from "./actionTypes";
import { setAlertFlagInfo,disableAlertFlagInfo } from "./actions";


function* displaySuccessMessage({payload:alertInfo}) {

  //console.log('alertInfo========',alertInfo);
  yield put(setAlertFlagInfo(alertInfo));
  //yield delay(3000); // Wait for 3 seconds
  //yield put(disableAlertFlagInfo({error:"",showSuccess:false,showMessage:'',loading:false}));
}

function* successSaga() {
  yield takeEvery(SHOW_ALERT_FLAG, displaySuccessMessage);
}

export default successSaga;
