import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CASEFORMATTRIBUTES, ADD_CASEFORMATTRIBUTE, GET_CASEFORMATTRIBUTE_DETAIL, UPDATE_CASEFORMATTRIBUTE_DETAIL, DELETE_CASEFORMATTRIBUTE_DETAIL } from "./actionTypes"
import {
  getCaseFormAttributesSuccess, getCaseFormAttributesFail,
  addCaseFormAttributeSuccess, addCaseFormAttributeFail, getCaseFormAttributeDetailSuccess, getCaseFormAttributeDetailFail, caseAttributeUpdateSuccess, caseAttributeUpdateFail, caseFormAttributeDeleteFail, caseFormAttributeDeleteSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getcaseformAttributes, addCaseFormAttributes, getCaseFormAttributeDetailInfo, updateCaseFormAttributes, deleteCaseformAttribute } from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"
import { changePreloader } from "../../../store/actions"

function* fetchCaseformattributetemp(data) {
  try {
    const response = yield call(getcaseformAttributes, data)
    yield put(getCaseFormAttributesSuccess(response.data))
  } catch (error) {
    yield put(getCaseFormAttributesFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* addCaseFormAttribute({ payload: user }) {
  try {
    const response = yield call(addCaseFormAttributes, user)
    yield put(addCaseFormAttributeSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(addCaseFormAttributeFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* fetchCaseformattributeDetail({ Caseformid }) {
  try {
    if (Caseformid) {
      const response = yield call(getCaseFormAttributeDetailInfo, Caseformid)
      yield put(getCaseFormAttributeDetailSuccess(response.data))
    }
    else {
      yield put(getCaseFormAttributeDetailSuccess({}))
    }
  } catch (error) {
    yield put(getCaseFormAttributeDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}
function* onUpdateCaseFormAttribute({ payload: data }) {
  try {
    const response = yield call(updateCaseFormAttributes, data);
    yield put(caseAttributeUpdateSuccess(response.data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(caseAttributeUpdateFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteCaseAttribute({ payload: data }) {
  try {
    const response = yield call(deleteCaseformAttribute, data);
    yield put(caseFormAttributeDeleteSuccess(data));
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(caseFormAttributeDeleteFail(error));
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* Caseformattributessaga() {
  yield takeEvery(GET_CASEFORMATTRIBUTES, fetchCaseformattributetemp)
  yield takeEvery(GET_CASEFORMATTRIBUTE_DETAIL, fetchCaseformattributeDetail)
  yield takeEvery(ADD_CASEFORMATTRIBUTE, addCaseFormAttribute)
  yield takeEvery(DELETE_CASEFORMATTRIBUTE_DETAIL, onDeleteCaseAttribute)
  yield takeEvery(UPDATE_CASEFORMATTRIBUTE_DETAIL, onUpdateCaseFormAttribute)
}

export default Caseformattributessaga
