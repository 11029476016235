/* QNNRTYPES */
export const GET_CASEFORMATTRIBUTEGROUPS = "GET_CASEFORMATTRIBUTEGROUPS"
export const GET_CASEFORMATTRIBUTEGROUPS_SUCCESS = "GET_CASEFORMATTRIBUTEGROUPS_SUCCESS"
export const GET_CASEFORMATTRIBUTEGROUPS_FAIL = "GET_CASEFORMATTRIBUTEGROUPS_FAIL"
export const ADD_CASEFORMATTRIBUTEGROUP = "ADD_CASEFORMATTRIBUTEGROUP"
export const ADD_CASEFORMATTRIBUTEGROUP_SUCCESS = "ADD_CASEFORMATTRIBUTEGROUP_SUCCESS"
export const ADD_CASEFORMATTRIBUTEGROUP_FAIL = "ADD_CASEFORMATTRIBUTEGROUP_FAIL"

export const GET_CASEFORMATTRGROUP_DETAIL = "GET_CASEFORMATTRGROUP_DETAIL"
export const GET_CASEFORMATTRGROUP_DETAIL_SUCCESS = "GET_CASEFORMATTRGROUP_DETAIL_SUCCESS"
export const GET_CASEFORMATTRGROUP_DETAIL_FAIL = "GET_CASEFORMATTRGROUP_DETAIL_FAIL"

export const UPDATE_CASEFORMATTRIBUTEGROUP = "UPDATE_CASEFORMATTRIBUTEGROUP"
export const UPDATE_CASEFORMATTRIBUTEGROUP_SUCCESS = "UPDATE_CASEFORMATTRIBUTEGROUP_SUCCESS"
export const UPDATE_CASEFORMATTRIBUTEGROUP_FAIL = "UPDATE_CASEFORMATTRIBUTEGROUP_FAIL"

export const DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL = "DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL"
export const DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL_SUCCESS = "DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL_SUCCESS"
export const DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL_FAIL = "DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL_FAIL"
