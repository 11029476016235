import {
  GET_ATTORNEY_MASTER_DOCS,
  GET_ATTORNEY_MASTER_DOCS_FAIL,
  GET_ATTORNEY_MASTER_DOCS_SUCCESS,
  UPDATE_ATTORNEY_MASTER_DOCS_FAIL,
  UPDATE_ATTORNEY_MASTER_DOCS_SUCCESS,
  UPDATE_ATTORNEY_MASTER_DOCS,
  DELETE_ATTORNEY_MASTER_DOCS,
  DELETE_ATTORNEY_MASTER_DOCS_SUCCESS,
  DELETE_ATTORNEY_MASTER_DOCS_FAIL,
} from "./actionTypes";

export const getAttorneyMasterDocs = (data) => ({
  type: GET_ATTORNEY_MASTER_DOCS,
  payload: data,
});

export const getAttorneyMasterDocsSuccess = (attorneydocs) => ({
  type: GET_ATTORNEY_MASTER_DOCS_SUCCESS,
  payload: attorneydocs,
});

export const getAttorneyMasterDocsFail = (error) => ({
  type: GET_ATTORNEY_MASTER_DOCS_FAIL,
  payload: error,
});

export const updateAttorneyMasterDocs = (data) => ({
  type: UPDATE_ATTORNEY_MASTER_DOCS,
  payload: data,
});

export const updateAttorneyMasterDocsSuccess = (attorneydocs) => ({
  type: UPDATE_ATTORNEY_MASTER_DOCS_SUCCESS,
  payload: attorneydocs,
});

export const updateAttorneyMasterDocsFail = (error) => ({
  type: UPDATE_ATTORNEY_MASTER_DOCS_FAIL,
  payload: error,
});

export const deleteAttorneyMasterDocs = (data) => ({
  type: DELETE_ATTORNEY_MASTER_DOCS,
  payload: data,
});

export const deleteAttorneyMasterDocsSuccess = (attorneydocs) => ({
  type: DELETE_ATTORNEY_MASTER_DOCS_SUCCESS,
  payload: attorneydocs,
});

export const deleteAttorneyMasterDocsFail = (error) => ({
  type: DELETE_ATTORNEY_MASTER_DOCS_FAIL,
  payload: error,
});
