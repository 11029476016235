import React, { useEffect, useMemo, useState } from "react";
import {
    Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input,
    FormGroup, Button, FormFeedback, Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledDropdown
} from "reactstrap"
import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import classnames from "classnames";
// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import { getEmployerContactDetail, addEmployerContactDetail, EmployerContactUpdate } from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";



const CreatePayroll = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL

    const [activeTab1, setactiveTab1] = useState("invoice");
    const toggle1 = (tab) => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };

    useEffect(() => {
        dispatch(getEmployerContactDetail(editId));
    }, [dispatch, editId]);

    const { employercontactDetail } = useSelector((state) => ({
        employercontactDetail: state.EmployerContacts.employercontactDetail,
    }));

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: editId ? employercontactDetail : {
            id: editId,
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            emp_type: '',
            job_title: '',
            visa: '',
            expiration_date: '',
            hire_date: '',
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required("Please Enter  First Name"),
            last_name: Yup.string().required("Please Enter  Last Name"),
            email: Yup.string().required("Please Enter Company Email"),
            phone: Yup.string().required("Please Enter Phone Number"),
            emp_type: Yup.string().required("Please select Beneficiary Type"),

        }),
        onSubmit: (values) => {
            if (Number(editId) > 0) {
                dispatch(EmployerContactUpdate(values));
            } else {
                dispatch(addEmployerContactDetail(values));
            }
            navigate("/employer/employeelist");
        }
    });
    let title = editId ? 'Add Payroll' : 'Add Payroll';
    return (
        <React.Fragment>

            <>
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Create Invoice</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Invoices</a></li>
                                            <li className="breadcrumb-item active">Invoice Detail</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">

                                <div className="card">
                                    <div className="card-body">

                                        <Nav pills className="navtab-bg nav-justified">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab1 === "invoice",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("invoice");
                                                    }}
                                                >
                                                    Invoice
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab1 === "settings",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("settings");
                                                    }}
                                                >
                                                    Settings
                                                </NavLink>
                                            </NavItem>

                                        </Nav>

                                        <TabContent activeTab={activeTab1} className="p-3 text-muted">
                                            <TabPane tabId="invoice">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-3">
                                                            <Label className="form-Label">Bill To</Label>
                                                            <select className="form-select">
                                                                <option>Narendra</option>
                                                                <option>Lakshman</option>
                                                                <option>Srikanth</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label className="form-Label">Beneficiary</Label>
                                                            <select className="form-select">
                                                                <option>Lakshman</option>
                                                                <option>Narendra</option>
                                                                <option>Srikanth</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label htmlFor="example-date-input" className="form-Label">Invoice Date</Label>
                                                            <Input className="form-control" type="date" defaultValue="10/02/2023" id="example-date-input" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label htmlFor="example-date-input" className="form-Label">Invoice Period</Label>
                                                            <Input className="form-control" type="date" defaultValue="10/02/2023" id="example-date-input" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label htmlFor="example-date-input" className="form-Label">Die Date</Label>
                                                            <Input className="form-control" type="date" defaultValue="10/02/2023" id="example-date-input" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label htmlFor="example-text-input" className="form-Label">Service</Label>
                                                            <Input className="form-control" type="text" placeholder="Service" defaultValue="" id="example-text-input" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label htmlFor="example-text-input" className="form-Label">Description</Label>
                                                            <Input className="form-control" type="text" placeholder="Description" defaultValue="" id="example-text-input" />
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="mb-3">
                                                                    <Label htmlFor="example-text-input" className="form-Label">Hours</Label>
                                                                    <Input className="form-control" type="text" placeholder="Hours" defaultValue="" id="example-text-input" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="mb-3">
                                                                    <Label htmlFor="example-text-input" className="form-Label">Rate</Label>
                                                                    <Input className="form-control" type="text" placeholder="Description" defaultValue="" id="example-text-input" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="settings">
                                                <div className="row">
                                                    <div className="col-lg-12">

                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <div
                                                                    className="form-check form-switch form-switch-md mb-3"
                                                                    dir="ltr"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"
                                                                        defaultChecked
                                                                    />
                                                                    <span className="font-size-16">Display Company Logo</span>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <div
                                                                    className="form-check form-switch form-switch-md mb-3"
                                                                    dir="ltr"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"
                                                                        defaultChecked
                                                                    />
                                                                    <span className="font-size-16">Payment Terms</span>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <div
                                                                    className="form-check form-switch form-switch-md mb-3"
                                                                    dir="ltr"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"
                                                                        defaultChecked
                                                                    />
                                                                    <span className="font-size-16">Payment Due</span>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <div
                                                                    className="form-check form-switch form-switch-md mb-3"
                                                                    dir="ltr"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"
                                                                        defaultChecked
                                                                    />
                                                                    <span className="font-size-16">Invoice Period</span>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <div
                                                                    className="form-check form-switch form-switch-md mb-3"
                                                                    dir="ltr"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"
                                                                        defaultChecked
                                                                    />
                                                                    <span className="font-size-16">Candidate Name</span>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <div
                                                                    className="form-check form-switch form-switch-md mb-3"
                                                                    dir="ltr"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"
                                                                        defaultChecked
                                                                    />
                                                                    <span className="font-size-16">Job Title</span>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <div
                                                                    className="form-check form-switch form-switch-md mb-3"
                                                                    dir="ltr"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"
                                                                        defaultChecked
                                                                    />
                                                                    <span className="font-size-16">Service</span>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <div
                                                                    className="form-check form-switch form-switch-md mb-3"
                                                                    dir="ltr"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"
                                                                        defaultChecked
                                                                    />
                                                                    <span className="font-size-16">Provide Discount %</span>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <div
                                                                    className="form-check form-switch form-switch-md mb-3"
                                                                    dir="ltr"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"

                                                                    />
                                                                    <span className="font-size-16">Provide Discount $</span>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <div
                                                                    className="form-check form-switch form-switch-md mb-3"
                                                                    dir="ltr"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"
                                                                        defaultChecked
                                                                    />
                                                                    <span className="font-size-16">Footer</span>
                                                                </div>
                                                            </div>

                                                            <div className="mb-3">
                                                                <Label htmlFor="example-text-input" className="form-Label">Description</Label>
                                                                <Editor
                                                                    toolbarClassName="toolbarClassName"
                                                                    wrapperClassName="wrapperClassName"
                                                                    editorClassName="editorClassName"
                                                                />
                                                            </div>
                                                            {/* <div className="mb-3">
                                                                <div
                                                                    className="form-check form-switch form-switch-md mb-3"
                                                                    dir="ltr"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"
                                                                        defaultChecked
                                                                    />
                                                                    <span className="font-size-16">Color Theme</span>
                                                                </div>
                                                            </div> */}


                                                        </div>

                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>

                                    </div>
                                </div>
                            </div>
                            <div className="col-8">

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="invoice-title">
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <div className="mb-4">
                                                                {/* <img src="assets/images/logo-sm.svg" alt="" height="24"><span className="logo-txt">Minia</span></img> */}
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="mb-4">
                                                                <h4 className="font-size-16">Invoice # 12345</h4>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <p className="mb-1">1874 County Line Road City, FL 33566</p>
                                                    <p className="mb-1"><i className="mdi mdi-email align-middle me-1"></i> abc@123.com</p>
                                                    <p><i className="mdi mdi-phone align-middle me-1"></i> 012-345-6789</p>
                                                </div>
                                                <hr className="my-4"></hr>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div>
                                                            <h5 className="font-size-15 mb-3">Billed To:</h5>
                                                            <h5 className="font-size-14 mb-2">Richard Saul</h5>
                                                            <p className="mb-1">1208 Sherwood Circle
                                                                Lafayette, LA 70506</p>
                                                            <p className="mb-1">RichardSaul@rhyta.com</p>
                                                            <p>337-256-9134</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div>
                                                            <div>
                                                                <h5 className="font-size-15">Invoice Date  : </h5>
                                                                <p>February 16, 2020</p>
                                                            </div>

                                                            <div className="mt-4">
                                                                <h5 className="font-size-15">Payment Due:</h5>
                                                                <p>February 16, 2020</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="py-2 mt-3">
                                                    <h5 className="font-size-15">Order summary</h5>
                                                </div>
                                                <div className="p-4 border rounded">
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap align-middle mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "70px" }}>S.No.</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Description</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Hours</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Rate</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">01</th>
                                                                    <td>
                                                                        <h5 className="font-size-15 mb-1">Minia</h5>
                                                                        <p className="font-size-13 text-muted mb-0">Bootstrap 5 Admin Dashboard </p>
                                                                    </td>
                                                                    <td className="text-end">3</td>
                                                                    <td className="text-end">$100.00</td>
                                                                    <td className="text-end">$300.00</td>
                                                                </tr>

                                                                <tr>
                                                                    <th scope="row">02</th>
                                                                    <td>
                                                                        <h5 className="font-size-15 mb-1">Skote</h5>
                                                                        <p className="font-size-13 text-muted mb-0">Bootstrap 5 Admin Dashboard </p>
                                                                    </td>
                                                                    <td className="text-end">3</td>
                                                                    <td className="text-end">$100.00</td>
                                                                    <td className="text-end">$300.00</td>
                                                                </tr>

                                                                <tr>
                                                                    <th scope="row" colSpan="4" className="text-end">Sub Total</th>
                                                                    <td className="text-end">$600.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row" colSpan="4" className="border-0 text-end">
                                                                        Tax</th>
                                                                    <td className="border-0 text-end">$12.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row" colSpan="4" className="border-0 text-end">Total</th>
                                                                    <td className="border-0 text-end"><h4 className="m-0">$612.00</h4></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </React.Fragment >
    );
};

export default CreatePayroll;