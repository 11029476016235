import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Card,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  FormGroup,
  Table,
  Button,
} from "reactstrap";
import DatatableContainer from "../../../components/Common/DatatableContainer";
import { useFormik } from "formik";

import * as Yup from "yup";

import { changePreloader, createPafBundle, customerDelete, getEmployerPaf, getEmployerPafForms, sendPafEmployeeNotification, uploadEmployerLca } from "../../../store/actions";
import {
  CaseTypeName,
  CommonCell
} from "./listCol";

//redux
import { useDispatch, useSelector } from "react-redux";

const ListPafForms = ({ employerpafinfo }) => {

  //meta title
  document.title = "Customers List | Immidock ";
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const caseId = queryParams.get('caseId'); // Read the 'caseId' query parameter from the URL

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openTab, setopenTab] = useState(1);
  const [customerdata, setCustomerdata] = useState(null);

  // for delete customer
  const [deleteModal, setDeleteModal] = useState(false);


  const [activeTab, setactiveTab] = useState(1);

  const { employerpafforms, createpafbundle } = useSelector((state) => ({
    employerpafforms: state.EmployerCases.employerpafforms || [],
    createpafbundle: state.EmployerCases.createpafbundle || [],

  }));

  useEffect(() => {
    if (createpafbundle.length > 0) {
      window.open(createpafbundle, '_blank', 'noreferrer')
      dispatch(changePreloader(false))
    }
  }, [createpafbundle]);

  useEffect(() => {
    dispatch(getEmployerPafForms({ case_id: caseId }));
  }, []);

  const onReloadData = (event) => {
    var tabInfo = event.target.value;
    setactiveTab(tabInfo);
    dispatch(getEmployerPaf({ status: tabInfo }));
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setCustomerdata(null);
    } else {
      setModal(true);
    }
  };

  var userType = window.localStorage.getItem("user_type")

  const handleGeneratePafBundle = () => {
    if (employerpafinfo?.paf_bundle_status) {
      dispatch(changePreloader(true));
      dispatch(createPafBundle({
        case_id: caseId
      }));
      //dispatch(changePreloader(false));
    } else {
      alert("Some of the PAF forms are presently unavailable but all PAF forms status should Available then only you can generate bundle.")
    }
  };
  const [uploadLcaModel, setUploadLcaModel] = useState(false);
  const [pafFormId, setPafFormId] = useState();
  const onUploadLca = (data) => {
    setUploadLcaModel(!uploadLcaModel);
    setPafFormId(data.paf_form_id);
  }

  // Form validation 
  const uploadLcaValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      lca_file: '',
    },
    validationSchema: Yup.object({
      lca_file: Yup.mixed().required('Please select file'),
    }),
    onSubmit: (values) => {
      dispatch(uploadEmployerLca({ ...values, case_id: caseId, paf_form_id: pafFormId }));
      setUploadLcaModel(false);
      setTimeout(() => {
        dispatch(getEmployerPafForms({ case_id: caseId }));
      }, 2000);
      uploadLcaValidation.resetForm();
    }
  });

  const onViewPafForm = (data) => {
    navigate(`/${userType}/paf-content-view?caseId=${data.case_id}&pafFormId=${data.paf_form_id}`);
  };
  const onViewPafFormPDF = (data) => {
    navigate(`/${userType}/paf-content-view?caseId=${data.case_id}&pafFormId=${data.paf_form_id}`);
  };
  const sendPafEmployeeReview = (data) => {
    dispatch(sendPafEmployeeNotification({
      case_id: data.case_id,
      paf_form_id: data.paf_form_id
    }));
    setTimeout(() => {
      dispatch(getEmployerPafForms({ case_id: data.case_id }));
    }, 3000);
  };

  const onEditData = (data) => {
    dispatch(getEmployerPaf({ case_id: data.case_id }));
    navigate(`/${userType}/paf-forms?caseId=${data.case_id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setCustomerdata(data);
  };

  const handleDeleteCustomer = () => {
    if (customerdata.id) {
      dispatch(customerDelete({ customer_id: customerdata.id }));
      setDeleteModal(false);
      dispatch(getEmployerPaf({ 'status': 1 }));
    }
  };


  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "form_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Modified By",
        accessor: "modified_by",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "Modified",
        accessor: "modified_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {(cellProps.row.original.paf_id == 2 || cellProps.row.original.paf_id == 10) && (cellProps.row.original.status == "Available") ? (
                  <>
                    <DropdownItem onClick={() => onViewPafFormPDF(cellProps.row.original)}>View</DropdownItem>
                  </>
                ) : (cellProps.row.original.paf_id == 2 || cellProps.row.original.paf_id == 10) && (cellProps.row.original.status == "Not Available") && (
                  <>
                    <DropdownItem onClick={() => onUploadLca(cellProps.row.original)}>Upload</DropdownItem>
                  </>
                )}

                {(cellProps.row.original.paf_id == 9) && (
                  <>
                    {(cellProps.row.original.status == "In Progress") && (

                      <DropdownItem>Beneficiary Review Pending</DropdownItem>
                    )}
                    {(cellProps.row.original.status != "In Progress") && (

                      <DropdownItem onClick={() => sendPafEmployeeReview(cellProps.row.original)}>Send Beneficiary Review</DropdownItem>
                    )}
                  </>
                )}

                {(cellProps.row.original.paf_id != 2 && cellProps.row.original.paf_id != 10 && cellProps.row.original.paf_id != 9) && (
                  <>
                    <DropdownItem onClick={() => onViewPafForm(cellProps.row.original)}>View</DropdownItem>
                  </>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  return (
    <CardBody>
      <Modal isOpen={uploadLcaModel}>
        <ModalHeader toggle={() => { setMoreActions(''); uploadLcaValidation.resetForm(); setUploadLcaModel(false) }} tag="h4">
          Certified Labor Condition Application Upload File
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              uploadLcaValidation.handleSubmit();
              return false;
            }}
          >
            <Row form>
              <Col className="col-12 mb-3">
                <div className="file-select">
                  <Input
                    type="file"
                    name="lca_file"
                    id="chooseFile"
                    accept=".pdf"
                    onChange={(e) => uploadLcaValidation.setFieldValue('lca_file', e.target.files[0])}
                    onBlur={uploadLcaValidation.handleBlur}
                    invalid={
                      uploadLcaValidation.touched.lca_file &&
                        uploadLcaValidation.errors.lca_file
                        ? true
                        : false}
                  />
                  {uploadLcaValidation.touched.lca_file &&
                    uploadLcaValidation.errors.lca_file ? (
                    <FormFeedback type="invalid">
                      {uploadLcaValidation.errors.lca_file}
                    </FormFeedback>
                  ) : null}

                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-primary save-event"
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <DatatableContainer
        columns={columns}
        data={employerpafforms}
        isGlobalFilter={true}
        isPafFilter={false}
        defaultFilter={1}
        isPafFilterChange={onReloadData}
        isGeneratePafBundle={true}
        viewGeneratedPafBundle={employerpafinfo?.paf_bundle_path}
        customPageSize={10}
        handleGeneratePafBundle={handleGeneratePafBundle}
      />
    </CardBody>
  );
};

export default ListPafForms;