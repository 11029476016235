import {
  GET_EMPLOYER_ESIGN,
  GET_EMPLOYER_ESIGN_SUCCESS,
  GET_EMPLOYER_ESIGN_FAIL,
  UPLOAD_EMPLOYER_ESIGN,
  UPLOAD_EMPLOYER_ESIGN_SUCCESS,
  UPLOAD_EMPLOYER_ESIGN_FAIL
} from "./actionTypes"

export const getEmployerEsign = (data) => ({
  type: GET_EMPLOYER_ESIGN,
  payload:data
})

export const getEmployerEsignSuccess = data => ({
  type: GET_EMPLOYER_ESIGN_SUCCESS,
  payload: data,
})

export const getEmployerEsignFail = error => ({
  type: GET_EMPLOYER_ESIGN_FAIL,
  payload: error,
})


export const uploadEmployerEsign = (data) => ({
  type: UPLOAD_EMPLOYER_ESIGN,
  payload:data
})

export const uploadEmployerEsignSuccess = data => ({
  type: UPLOAD_EMPLOYER_ESIGN_SUCCESS,
  payload: data,
})

export const uploadEmployerEsignFail = error => ({
  type: UPLOAD_EMPLOYER_ESIGN_FAIL,
  payload: error,
})
