import React, { useEffect, useRef, useState } from "react";
import {
    Col, Container, Row, Form, Label, Input,
    FormGroup,
    Modal,
    FormFeedback,
} from "reactstrap"
import { useAlert } from "../../../components/Common/Alert";

// import fs from 'fs';
import { usePDF, PDFViewer, pdf, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

import Dropzone from "react-dropzone";

import { Link } from "react-router-dom";

import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import * as Yup from "yup";
import { useFormik, Field, FieldArray, FormikProvider, ErrorMessage } from "formik";
import classnames from "classnames";
// Form Editor
import { convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import { getCustomers, getCustomerDetail, addCustomerDetail, updateCustomerPdf, recordPayment as recordPaymentApi, sendCustomer as sendCustomerApi, getCustomerSettings, customerUpdate, getEmployerContacts as getEmployerContacts, getEmployerContactDetail, addEmployerContactDetail, EmployerContactUpdate, uploadCustomerAttachment } from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";


const ViewCustomer = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const customerId = queryParams.get('customerId'); // Read the 'customerId' query parameter from the URL
    const sendCustomerParam = queryParams.get('sendcustomer') ? true : false; // Read the 'sendcustomer' query parameter from the URL


    const [sendCustomer, setSendCustomer] = useState(sendCustomerParam)
    const [recordPaymentModal, setRecordPaymentModal] = useState(false)

    const [editorState, seteditorState] = useState({});

    const [activeTab1, setactiveTab1] = useState("customer");
    const toggle1 = (tab) => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };

    const moreactions = [{ 'label': 'Record Payment', 'action': 'recordpayment' }, { 'label': 'Export as PDF', 'action': 'pdf' }, { 'label': 'Print PDF', 'action': 'printpdf' }, { 'label': 'Resend Customer', 'action': 'saveandsend' }]
    const handleActionSubmit = (e) => {
        validation.handleSubmit();
    };
    const handleRecordPaymentActionSubmit = (e) => {
        record_payment_validation.handleSubmit();
    };
    const onEditorStateChange = (editorState) => {
        seteditorState(editorState)
        validation.values.message = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };
    const onEditorFooterStateChange = (editorFooterDescription) => {
        seteditorFooterDescription(editorFooterDescription)
        validation.values.footer_description = draftToHtml(convertToRaw(editorFooterDescription.getCurrentContent()));
    };
    const {
        recordPaymentDetail, customerDetail, employercontacts, customersettings, uploadedcustomerattachments } = useSelector((state) => ({
            customerDetail: state.Customers.customerDetail,
            recordPaymentDetail: state.Customers.recordPaymentDetail,
            employercontacts: state.EmployerContacts.employercontacts || [],
            customersettings: state.Customers.customersettings || {},
            uploadedcustomerattachments: state.Customers.uploadedcustomerattachments || "",
        }));

    const pdfRef = useRef(null);

    const printDocument = (print) => {
        if (pdfRef) {
            if (Object.keys(customerDetail).length > 0) {
                const unit = "pt";
                const size = "A4"; // Use A1, A2, A3 or A4
                const orientation = "portrait"; // portrait or landscape
                const content = pdfRef.current;
                const doc = new jsPDF(orientation, unit, size);
                doc.margin = { horiz: 20, vert: 10 };
                doc.setFontSize(1);
                doc.setPage(1);
                doc.html(content, {
                    callback: function (doc) {
                        if (print == "saveandsend") {
                            if (customerDetail.customer_pdf_file == null || customerDetail.customer_pdf_file.length === 0) {
                                var binaryStr = doc.output('blob');
                                dispatch(updateCustomerPdf({
                                    customer_id: customerId,
                                    customer_pdf_file: binaryStr
                                }));
                            }
                            setSendCustomer(true);
                        }
                        if (print == "pdf") {
                            doc.save(customerDetail.customer_number + '.pdf');
                        }
                        if (print == "printpdf") {
                            window.open(doc.output('bloburl'));
                        }
                    },
                    html2canvas: { scale: 0.5 }
                });
            }
        }
    };

    useEffect(() => {
        dispatch(getCustomerDetail({ customer_id: customerId }));
        dispatch(getCustomers({ 'status': 'Active' }));
        dispatch(getCustomerSettings({}));
        // dispatch(getCustomerSettings(values));
        // setInitialValues(customersettings?.customer_settings)
        // dispatch(getEmployerContactDetail(customerId));
    }, [dispatch]);

    useEffect(() => {
        if (sendCustomer) {
            if (customerDetail.customer_pdf_file == null || customerDetail.customer_pdf_file.length === 0) {
                printDocument("saveandsend")
            }
        }
    }, [sendCustomer, customerDetail]);

    // const customer_details = customersettings.customer_details;
    const customer_details = [
        {
            "cus_id": 10,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "1 Point System LLC",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "864 Watling St",
            "cmp_state": "SC",
            "cmp_apt_ste_flr": "",
            "cmp_number": null,
            "cmp_country": null,
            "cmp_city": "Fort Mill",
            "cmp_zipcode": "29715",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net 15",
            "quickbook_sync_status": 1,
            "quickbook_sync_id": "63",
            "qb_last_sync_date": "2020-08-19 12:09:06",
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-08-19T17:39:06.000000Z"
        },
        {
            "cus_id": 11,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "7C Info Com, Inc.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "939 Eaglewood Ave",
            "cmp_state": "ND",
            "cmp_apt_ste_flr": "",
            "cmp_number": "",
            "cmp_country": null,
            "cmp_city": "West Fargo",
            "cmp_zipcode": "58078",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net45",
            "quickbook_sync_status": 1,
            "quickbook_sync_id": "58",
            "qb_last_sync_date": "2020-06-30 09:43:32",
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-06-30T15:13:32.000000Z"
        },
        {
            "cus_id": 12,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Analysts International Corporation",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "7700 France Ave",
            "cmp_state": "MN",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "200",
            "cmp_country": null,
            "cmp_city": "Minneapolis",
            "cmp_zipcode": "55435",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net15",
            "quickbook_sync_status": 1,
            "quickbook_sync_id": "64",
            "qb_last_sync_date": "2020-08-16 14:57:08",
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-08-16T20:27:08.000000Z"
        },
        {
            "cus_id": 13,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Artech Information Systems LLC",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "360 Mt. Kemble Avenue",
            "cmp_state": "NJ",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "2000",
            "cmp_country": null,
            "cmp_city": "Morristown",
            "cmp_zipcode": "7960",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": null,
            "quickbook_sync_status": 1,
            "quickbook_sync_id": "74",
            "qb_last_sync_date": "2020-06-25 10:44:18",
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-06-25T16:14:18.000000Z"
        },
        {
            "cus_id": 9,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Arthur Lawrence Management LLC",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "",
            "cmp_state": "",
            "cmp_apt_ste_flr": "",
            "cmp_number": "",
            "cmp_country": null,
            "cmp_city": "",
            "cmp_zipcode": "",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "",
            "quickbook_sync_status": 1,
            "quickbook_sync_id": "76",
            "qb_last_sync_date": "2020-08-27 11:37:31",
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T20:57:49.000000Z",
            "updated_at": "2020-08-27T17:07:31.000000Z"
        },
        {
            "cus_id": 14,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Arthur Lawrence Management LLC",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "11233 Shadow Creek Pkwy",
            "cmp_state": "TX",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "313",
            "cmp_country": null,
            "cmp_city": "Pearland",
            "cmp_zipcode": "77584",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net45",
            "quickbook_sync_status": 1,
            "quickbook_sync_id": "76",
            "qb_last_sync_date": "2020-06-22 09:44:21",
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-06-22T15:14:21.000000Z"
        },
        {
            "cus_id": 15,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Attain, LLC",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "1600 Tysons Blvd",
            "cmp_state": "VA",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "1400",
            "cmp_country": null,
            "cmp_city": "McLean",
            "cmp_zipcode": "22102",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net30",
            "quickbook_sync_status": 1,
            "quickbook_sync_id": "77",
            "qb_last_sync_date": "2020-06-22 09:27:01",
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-06-22T14:57:01.000000Z"
        },
        {
            "cus_id": 16,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Collabera Inc",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "110 Allen Road",
            "cmp_state": "NJ",
            "cmp_apt_ste_flr": "",
            "cmp_number": null,
            "cmp_country": null,
            "cmp_city": "Basking Ridge",
            "cmp_zipcode": "7920",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": null,
            "quickbook_sync_status": 1,
            "quickbook_sync_id": "75",
            "qb_last_sync_date": "2020-06-21 06:52:55",
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-07-01T17:33:51.000000Z"
        },
        {
            "cus_id": 17,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Compass DX Consulting Group, LLC",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "1150 Ripley Street",
            "cmp_state": "MD",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "1419",
            "cmp_country": null,
            "cmp_city": "Silver Spring",
            "cmp_zipcode": "20910",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net45",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 18,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Digital Intelligence Systems, LLC",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "8270 Greensboro Drive",
            "cmp_state": "VA",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "1000",
            "cmp_country": null,
            "cmp_city": "McLean",
            "cmp_zipcode": "22102",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net45",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 19,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Diverse Lynx, LLC",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "300 Alexander Park",
            "cmp_state": "NJ",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "200",
            "cmp_country": null,
            "cmp_city": "Princeton",
            "cmp_zipcode": "8540",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net45",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 20,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "DynPro Inc.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "7412 Chapel Hill Road",
            "cmp_state": "NC",
            "cmp_apt_ste_flr": "",
            "cmp_number": "",
            "cmp_country": null,
            "cmp_city": "Raleigh",
            "cmp_zipcode": "27607",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net45",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 21,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Enterprise Solutions, Inc.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "500 East Diehl Rd",
            "cmp_state": "IL",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "130",
            "cmp_country": null,
            "cmp_city": "Naperville",
            "cmp_zipcode": "60563",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net45",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 22,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "IDC Technologies Inc. ",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "920 Hillview Court",
            "cmp_state": "CA",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "250",
            "cmp_country": null,
            "cmp_city": "Milpitas ",
            "cmp_zipcode": "95035",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net45",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 23,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Infinite Computer Solutions Inc.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "15201 Diamondback Drive",
            "cmp_state": "MD",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "125",
            "cmp_country": null,
            "cmp_city": "Rockville",
            "cmp_zipcode": "20850",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 24,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "ITC Infotech (USA) Inc.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "12 North State Route 17",
            "cmp_state": "NJ",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "303",
            "cmp_country": null,
            "cmp_city": "Paramus",
            "cmp_zipcode": "7652",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net15",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 25,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Judge Group",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "151 S. Warner Road",
            "cmp_state": "PA",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "100",
            "cmp_country": null,
            "cmp_city": "Wayne",
            "cmp_zipcode": "19087",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net7",
            "quickbook_sync_status": 1,
            "quickbook_sync_id": "60",
            "qb_last_sync_date": "2020-06-21 08:27:06",
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-06-21T13:57:06.000000Z"
        },
        {
            "cus_id": 6,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Judge Tech",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "270 University Plz",
            "cmp_state": "DE",
            "cmp_apt_ste_flr": "Apt",
            "cmp_number": "205",
            "cmp_country": null,
            "cmp_city": "Newark",
            "cmp_zipcode": "19702",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net7",
            "quickbook_sync_status": 1,
            "quickbook_sync_id": "62",
            "qb_last_sync_date": "2020-06-21 09:51:18",
            "created_by": null,
            "updated_by": null,
            "status": "Active",
            "created_at": "2020-05-10T16:05:46.000000Z",
            "updated_at": "2020-06-21T15:21:18.000000Z"
        },
        {
            "cus_id": 26,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Kelly Mitchell Group, Inc.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "8229 Maryland Avenue",
            "cmp_state": "MO",
            "cmp_apt_ste_flr": "",
            "cmp_number": "",
            "cmp_country": null,
            "cmp_city": "Clayton",
            "cmp_zipcode": "63105",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net45",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 27,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Kforce Inc.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "2810 Crossroads Dr",
            "cmp_state": "WI",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "1000",
            "cmp_country": null,
            "cmp_city": "Madison",
            "cmp_zipcode": "53718",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net30",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 28,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Lib N Leo, LLC.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "1013 Brookside Road",
            "cmp_state": "PA",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "200",
            "cmp_country": null,
            "cmp_city": "Allentown",
            "cmp_zipcode": "18106",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net45",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 29,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "MINT Technologies",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "771 Stallion Drive\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0",
            "cmp_state": "TX",
            "cmp_apt_ste_flr": "",
            "cmp_number": "",
            "cmp_country": null,
            "cmp_city": "Fairview",
            "cmp_zipcode": "75069",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net50",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 30,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Novalink Solutions, LLC",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "2180 Satellite Blvd",
            "cmp_state": "GA",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "400",
            "cmp_country": null,
            "cmp_city": "Duluth",
            "cmp_zipcode": "30097",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net45",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 31,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Object Computing Inc.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "12140 Woodcrest Executive Dr",
            "cmp_state": "MO",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "300",
            "cmp_country": null,
            "cmp_city": "St.Louis",
            "cmp_zipcode": "63141",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net30",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 32,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Object Technology Solutions, Inc.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "6363 College Blvd",
            "cmp_state": "KS",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "230",
            "cmp_country": null,
            "cmp_city": "Overland Park",
            "cmp_zipcode": "66211",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net30",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 33,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Syontek, Inc.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "30 N Gould St",
            "cmp_state": "WY",
            "cmp_apt_ste_flr": "",
            "cmp_number": "",
            "cmp_country": null,
            "cmp_city": "Ste E Sheridan",
            "cmp_zipcode": "82801",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net60",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 34,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "SYSMIND LLC",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "38 Washington Road",
            "cmp_state": "NJ",
            "cmp_apt_ste_flr": "",
            "cmp_number": "",
            "cmp_country": null,
            "cmp_city": "Princeton Junction",
            "cmp_zipcode": "8550",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net30",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 35,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "TEKsystems, Inc",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "7437 Race Road ",
            "cmp_state": "MD",
            "cmp_apt_ste_flr": "",
            "cmp_number": "",
            "cmp_country": null,
            "cmp_city": "Hanover",
            "cmp_zipcode": "21076",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net30",
            "quickbook_sync_status": 1,
            "quickbook_sync_id": "59",
            "qb_last_sync_date": "2021-01-30 11:38:40",
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2021-01-30T17:08:40.000000Z"
        },
        {
            "cus_id": 1,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "test comapny",
            "cmp_phone": "(231) 231-2313",
            "cmp_fax": "(123) 123-3333",
            "cmp_street": "212 wer 21312",
            "cmp_state": "HI",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "22",
            "cmp_country": "USA",
            "cmp_city": "erwwrwer",
            "cmp_zipcode": "324324",
            "inv_first_name": "adasd",
            "inv_last_name": "sdfsdf",
            "inv_email": "dummy@yopmail.com",
            "inv_phone": "(123) 434-3434",
            "payment_terms": "Net30",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": null,
            "status": "Active",
            "created_at": "2020-04-30T18:19:05.000000Z",
            "updated_at": "2020-04-30T18:51:09.000000Z"
        },
        {
            "cus_id": 46,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "test comapny",
            "cmp_phone": "(231) 231-2313",
            "cmp_fax": "(123) 123-3333",
            "cmp_street": "212 wer 21312",
            "cmp_state": "HI",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "22",
            "cmp_country": "USA",
            "cmp_city": "erwwrwer",
            "cmp_zipcode": "324324",
            "inv_first_name": "adasd",
            "inv_last_name": "sdfsdf",
            "inv_email": "dummy@yopmail.com",
            "inv_phone": "(123) 434-3434",
            "payment_terms": "Net30",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": null,
            "status": "Active",
            "created_at": "2020-04-30T18:19:05.000000Z",
            "updated_at": "2020-04-30T18:51:09.000000Z"
        },
        {
            "cus_id": 2,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "testcompany1",
            "cmp_phone": "(111) 111-1111",
            "cmp_fax": "(111) 111-1111",
            "cmp_street": null,
            "cmp_state": null,
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": null,
            "cmp_country": null,
            "cmp_city": null,
            "cmp_zipcode": null,
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net45",
            "quickbook_sync_status": 1,
            "quickbook_sync_id": "61",
            "qb_last_sync_date": "2020-06-21 08:55:05",
            "created_by": "2",
            "updated_by": null,
            "status": "Active",
            "created_at": "2020-04-30T18:22:55.000000Z",
            "updated_at": "2020-06-21T14:25:05.000000Z"
        },
        {
            "cus_id": 36,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "The Allere Group",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "110 E. State Street",
            "cmp_state": "PA",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "D",
            "cmp_country": null,
            "cmp_city": "Kennett Square",
            "cmp_zipcode": "19348",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 37,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "The CSI Companies, Inc.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "9995 Gate Parkway North",
            "cmp_state": "FL",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "100",
            "cmp_country": null,
            "cmp_city": "Jacksonville",
            "cmp_zipcode": "32246",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net90",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 38,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "THEMESOFT, INC.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "5151 Headquarters Dr",
            "cmp_state": "TX",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "210",
            "cmp_country": null,
            "cmp_city": "Plano",
            "cmp_zipcode": "75024",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net45",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 7,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "trtt",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": null,
            "cmp_state": null,
            "cmp_apt_ste_flr": null,
            "cmp_number": null,
            "cmp_country": null,
            "cmp_city": null,
            "cmp_zipcode": null,
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": null,
            "quickbook_sync_status": 1,
            "quickbook_sync_id": "65",
            "qb_last_sync_date": "2020-09-09 14:16:08",
            "created_by": null,
            "updated_by": null,
            "status": null,
            "created_at": "2020-05-11T08:15:45.000000Z",
            "updated_at": "2020-09-09T19:46:08.000000Z"
        },
        {
            "cus_id": 8,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "trtt",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": null,
            "cmp_state": null,
            "cmp_apt_ste_flr": null,
            "cmp_number": null,
            "cmp_country": null,
            "cmp_city": null,
            "cmp_zipcode": null,
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": null,
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": null,
            "updated_by": null,
            "status": null,
            "created_at": "2020-05-11T08:15:45.000000Z",
            "updated_at": "2020-05-11T08:15:45.000000Z"
        },
        {
            "cus_id": 39,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "US Data Management, LLC",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "535 Chapala Street",
            "cmp_state": "CA",
            "cmp_apt_ste_flr": "",
            "cmp_number": "",
            "cmp_country": null,
            "cmp_city": "Santa Barbara",
            "cmp_zipcode": "93101",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net30",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 40,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "ValueMomentum Inc.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "220 Old New Brunswick Road",
            "cmp_state": "NJ",
            "cmp_apt_ste_flr": "",
            "cmp_number": "",
            "cmp_country": null,
            "cmp_city": "Piscataway",
            "cmp_zipcode": "8854",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net45",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 41,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Ventech Solutions, Inc.",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "8425 Pulsar Place",
            "cmp_state": "OH",
            "cmp_apt_ste_flr": "Ste",
            "cmp_number": "300",
            "cmp_country": null,
            "cmp_city": "Columbus",
            "cmp_zipcode": "43240",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net30",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 42,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Vy Systems Inc",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "4701 Patrick Henry Dr, Building 16",
            "cmp_state": "CA",
            "cmp_apt_ste_flr": "",
            "cmp_number": "",
            "cmp_country": null,
            "cmp_city": "Santa Clara",
            "cmp_zipcode": "95054",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net30",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        },
        {
            "cus_id": 43,
            "atrn_user_id": 2,
            "qb_customer_id": null,
            "cmp_name": "Xoriant Corporation",
            "cmp_phone": null,
            "cmp_fax": null,
            "cmp_street": "1248 Reamwood Ave",
            "cmp_state": "CA",
            "cmp_apt_ste_flr": "",
            "cmp_number": "",
            "cmp_country": null,
            "cmp_city": "Sunnyvale",
            "cmp_zipcode": "94089",
            "inv_first_name": null,
            "inv_last_name": null,
            "inv_email": "dummy@yopmail.com",
            "inv_phone": null,
            "payment_terms": "Net30",
            "quickbook_sync_status": 0,
            "quickbook_sync_id": null,
            "qb_last_sync_date": null,
            "created_by": "2",
            "updated_by": "2",
            "status": "Active",
            "created_at": "2020-05-18T21:43:58.000000Z",
            "updated_at": "2020-05-18T21:43:58.000000Z"
        }
    ];

    // Form Send Customer validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            customer_id: customerId,
            to_email: '',
            from_email: '',
            cc_email: '',
            message: '',
            subject: '',
            // customer_attachments: [],
            // customer_action: '',
        },
        validationSchema: Yup.object({
            customer_action: Yup.string().nullable(),
            customer_attachments: Yup.array().nullable(),
            subject: Yup.string().required("Subject required").nullable(),
            from_email: Yup.string().required("From email address required").nullable(),
            to_email: Yup.string().required("To email address required").nullable(),
            cc_email: Yup.string().nullable(),
            message: Yup.string().nullable(),
        }),
        onSubmit: (values) => {
            dispatch(sendCustomerApi(values));
            dispatch(getCustomers({ 'status': 'Active' }));
            setTimeout(() => {
                navigate("/employer/customers", { replace: true });
            }, 1000);
            // setSendCustomer(false);
        }
    });

    // Form RecordPayment validation 
    const record_payment_validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            customer_id: customerId,
            payment_items: [{
                payment_record_id: '', payment_amount: 0, receive_date: '',
                payment_type: '', reference: '', is_discount_amount_enable: false,
                discount_amount: '', qb_deposit_to_account_ref: ''
            }],
        },
        validationSchema: Yup.object({
            payment_items: Yup.array()
                .of(
                    Yup.object().shape({
                        // payment_record_id: Yup.string().required("Please Enter Payment Record Id").nullable(),
                        // payment_amount: Yup.number().min(0).default(1).positive("Must be a positive value").transform((value) => Number.isNaN(value) ? null : value).required("Please Enter Payment").typeError('Payment must be a number'),
                        // receive_date: Yup.string().required("Please Enter Receive Date").nullable(),
                        // payment_type: Yup.string().required("Please Enter Payment Type").nullable(),
                        // reference: Yup.string().required("Please Enter Reference").nullable(),
                        // is_discount_amount_enable: Yup.boolean(),
                        // discount_amount: Yup.number().when('is_discount_amount_enable', {
                        //     is: (val) => val === true, //&& val.includes("1"),
                        //     then: Yup.number().min(0).default(1).positive("Must be a positive value").transform((value) => Number.isNaN(value) ? null : value).typeError('Amount must be a number')
                        //         .required("Please Enter Discount amount").nullable(),
                        //     otherwise: () => Yup.number().default(0).nullable(),
                        // }),
                        // qb_deposit_to_account_ref: Yup.string().nullable(),

                    })
                ) // .required('Must filled the payment details'),
            ,
        }),
        onSubmit: (values) => {
            dispatch(recordPaymentApi(values));
            dispatch(getCustomers({ 'status': 'Active' }));
            setRecordPaymentModal(false);
            setTimeout(() => {
                navigate("/employer/customers", { replace: true });
            }, 1000);
        }
    });

    let title = customerId ? 'View Customer' : 'Customer';
    //meta title
    document.title = title + " | Immidock ";
    return (
        <React.Fragment>
            {(Object.keys(customerDetail).length > 0) && (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0 font-size-18">{title} Customer</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a href="javascript: void(0);">Customers</a></li>
                                                <li className="breadcrumb-item active">Customer Detail</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div className="col-12">
                                <div className="row">
                                    <Col md="2">
                                        <p className="">Status: {customerDetail.status}</p>
                                    </Col>
                                    <Col md="4">
                                        <p className="">Customer Due Date: {(customerDetail.customer_due_date != '') && (
                                            <>
                                                {(new Date(customerDetail.customer_due_date)).getDay()} {(new Date(customerDetail.customer_due_date)).toLocaleString('default', { month: 'short' })}, {(new Date(customerDetail.customer_due_date)).getFullYear()}
                                            </>)}</p>
                                    </Col>
                                    <Col md="3">
                                        <p className="">Balance Due: ${customerDetail?.balance_amount}</p>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Input
                                                name="customer_action"
                                                placeholder="More Actions"
                                                type="select"
                                                className="form-control"
                                                id="customer_action"
                                                onChange={(e) => {
                                                    if (e.target.value == "recordpayment") {
                                                        setRecordPaymentModal(true);
                                                    } else {
                                                        printDocument(e.target.value);
                                                    }
                                                }}
                                            >
                                                <option value="">More Actions</option>
                                                {moreactions.map((moreaction, index) => (
                                                    <option key={moreaction.action + index} value={moreaction.action}>
                                                        {moreaction.label}
                                                    </option>
                                                ))}
                                            </Input>
                                            {/* {validation.touched.customer_id && validation.errors.customer_id ? (
                                                <FormFeedback type="invalid">{validation.errors.customer_id}</FormFeedback>
                                            ) : null} */}
                                        </FormGroup>
                                    </Col>

                                </div>
                                <div className="row" ref={pdfRef}>
                                    <div className="col-lg-12">

                                        <div className="card">
                                            <div className="card-body">
                                                <div className="customer-title">
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <div className="mb-4">
                                                                {/* <img src="assets/images/logo-sm.svg" alt="" height="24"><span className="logo-txt">Minia</span></img> */}
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="mb-4">
                                                                <h4 className="font-size-16">Customer # {customerDetail.customer_number}</h4>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <p className="mb-1">1874 County Line Road City, FL 33566</p>
                                                    <p className="mb-1"><i className="mdi mdi-email align-middle me-1"></i> abc@123.com</p>
                                                    <p><i className="mdi mdi-phone align-middle me-1"></i> 012-345-6789</p>
                                                </div>
                                                <hr className="my-4"></hr>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div>
                                                            <h5 className="font-size-15 mb-3">Billed To:</h5>
                                                            {/* {(Object.keys(billedTo).length > 0) && (
                                                                <>
                                                                    <h5 className="font-size-14 mb-2">{billedTo.cmp_name || ""}</h5>
                                                                    <p className="mb-1">{billedTo.inv_email || ""}</p>
                                                                    <p className="mb-1">{billedTo.cmp_street || ""} {billedTo.state || ""} {billedTo.cmp_city || ""} {billedTo.cmp_country || ""} {billedTo.zipcode || ""}</p>
                                                                    <p>{billedTo.cmp_phone || ""}</p>
                                                                </>
                                                            )} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div>
                                                            <div>
                                                                <h5 className="font-size-15">Customer Date  : </h5>
                                                                {(customerDetail.customer_date != '') && (
                                                                    <p>{(new Date(customerDetail.customer_date)).getDay()} {(new Date(customerDetail.customer_date)).toLocaleString('default', { month: 'short' })}, {(new Date(customerDetail.customer_date)).getFullYear()}</p>
                                                                )}
                                                            </div>

                                                            {customerDetail.is_payment_due_enable && (
                                                                <div className="mt-4">
                                                                    <h5 className="font-size-15">Payment Due:</h5>
                                                                    {(customerDetail.customer_due_date != '') && (
                                                                        <p>{(new Date(customerDetail.customer_due_date)).getDay()} {(new Date(customerDetail.customer_due_date)).toLocaleString('default', { month: 'short' })}, {(new Date(customerDetail.customer_due_date)).getFullYear()}</p>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {customerDetail.is_customer_period_enable && (
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div>
                                                                <div>
                                                                    <h5 className="font-size-15">Customer Period : </h5>
                                                                    {(customerDetail.customer_from_date != '' && customerDetail.customer_to_date != '') && (
                                                                        <p>{(new Date(customerDetail.customer_from_date)).getDay()} {(new Date(customerDetail.customer_from_date)).toLocaleString('default', { month: 'short' })}, {(new Date(customerDetail.customer_from_date)).getFullYear()} - {(new Date(customerDetail.customer_to_date)).getDay()} {(new Date(customerDetail.customer_to_date)).toLocaleString('default', { month: 'short' })}, {(new Date(customerDetail.customer_to_date)).getFullYear()}</p>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {customerDetail.is_candidate_name_enable && (
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div>
                                                                <div>
                                                                    <h5 className="font-size-15">Candidate Name: </h5>
                                                                    <p>{customerDetail.emp_name}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                )}
                                                {customerDetail.is_job_title_enable && (
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div>
                                                                <div>
                                                                    <h5 className="font-size-15">Job Title: </h5>
                                                                    <p>{customerDetail.service_name}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                )}
                                                {customerDetail.is_service_enable && (
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div>
                                                                <div>
                                                                    <h5 className="font-size-15">Service : </h5>
                                                                    <p>{customerDetail.service_name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}



                                                <div className="py-2 mt-3">
                                                    <h5 className="font-size-15">Order summary</h5>
                                                </div>
                                                <div className="p-4 border rounded">
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap align-middle mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "70px" }}>S.No.</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Description</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Hours</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Rate</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(Object.keys(customerDetail.customer_items).length > 0) && customerDetail.customer_items.map((item, index) => (
                                                                    <tr key={index + "itemdisplay"}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>
                                                                            <h5 className="font-size-15 mb-1">{item.description}</h5>
                                                                            {/* <p className="font-size-13 text-muted mb-0">item desc </p> */}
                                                                        </td>
                                                                        <td className="text-end">{item.hours}</td>
                                                                        <td className="text-end">${item.rate}</td>
                                                                        <td className="text-end">{(item.hours && item.rate) ? "$" + parseFloat(item.hours * item.rate).toFixed(2) : ""}</td>
                                                                    </tr>
                                                                ))}


                                                                <tr>
                                                                    <th scope="row" colSpan="4" className="text-end">Sub Total</th>
                                                                    <td className="text-end">
                                                                        ${customerDetail.customer_amount}
                                                                    </td>
                                                                </tr>
                                                                {(customerDetail.is_discount_percentage_enable || customerDetail.is_discount_amount_enable) && (
                                                                    <tr>
                                                                        <td scope="row" colSpan="4" className="text-end">Discount {customerDetail.is_discount_percentage_enable ? "(" + customerDetail.discount_percentage + "%)" : ""}</td>
                                                                        < td className="text-end">
                                                                            ${customerDetail.total_discount_amount}
                                                                        </td>
                                                                    </tr>
                                                                )}

                                                                <tr>
                                                                    <th scope="row" colSpan="4" className="border-0 text-end">Total</th>
                                                                    <td className="border-0 text-end"><h4 className="m-0">${customerDetail.total_amount}</h4></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                {customerDetail.is_footer_enable && (
                                                    <div className="py-2 mt-3">
                                                        <h5 className="font-size-15">Notes:</h5>
                                                        <p dangerouslySetInnerHTML={{ __html: customerDetail.footer_description }}></p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormikProvider value={validation}>
                        <Form className="needs-validation"
                            onSubmit={(values, { validate }) => {
                                values.preventDefault();
                                // console.log(validate(values));
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Modal
                                isOpen={sendCustomer}
                                toggle={() => {
                                    // tog_varyingmodal('@mdo')
                                }}
                                scrollable={true}
                                id="staticBackdrop"
                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">Send Customer</h5>
                                    <button type="button" className="btn-close"
                                        onClick={() => {
                                            setSendCustomer(false)
                                        }} aria-label="Send Customer"></button>
                                </div>
                                <div className="modal-body">
                                    <FormGroup className="mb-3">

                                        <Label htmlFor="to_email">To Email</Label>
                                        <Field
                                            name="to_email"
                                            placeholder="To Email"
                                            type="text"
                                            className="form-control"
                                            id="to_email"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.to_email || ""}
                                        />
                                        <ErrorMessage
                                            name="to_email"
                                            component="div"
                                            className="text-danger"
                                        />

                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="from_email">From Email</Label>
                                        <Field
                                            name="from_email"
                                            placeholder="From Email"
                                            type="text"
                                            className="form-control"
                                            id="from_email"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.from_email || ""}

                                        />
                                        <ErrorMessage
                                            name="from_email"
                                            component="div"
                                            className="text-danger"
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="cc_email">Cc Email</Label>
                                        <Field
                                            name="cc_email"
                                            placeholder="Cc Email"
                                            type="text"
                                            className="form-control"
                                            id="cc_email"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.cc_email || ""}

                                        />
                                        <ErrorMessage
                                            name="cc_email"
                                            component="div"
                                            className="text-danger"
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="subject">Subject</Label>
                                        <Field
                                            name="subject"
                                            placeholder="Subject"
                                            type="text"
                                            className="form-control"
                                            id="subject"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.subject || ""}

                                        />
                                        <ErrorMessage
                                            name="subject"
                                            component="div"
                                            className="text-danger"
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="message">Message</Label>
                                        <Editor
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange}

                                        />
                                    </FormGroup>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={(e) => {
                                        handleActionSubmit(e);
                                    }}>Send</button>
                                    {/* 
                                                <Row className="justify-content-middle">
                                                    <Col sm={12}>
                                                        <FormGroup className="mb-0">
                                                            <div>
                                                                <Button type="submit" color="primary" className="ms-1">
                                                                    Save
                                                                </Button>{" "}
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row> */}
                                </div>
                            </Modal>
                        </Form>
                    </FormikProvider>



                    <FormikProvider value={record_payment_validation}>
                        <Form className="needs-validation"
                            onSubmit={(values, { validate }) => {
                                values.preventDefault();
                                // console.log(validate(values));
                                record_payment_validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Modal
                                isOpen={recordPaymentModal}
                                toggle={() => {
                                    // tog_varyingmodal('@mdo')
                                }}
                                scrollable={true}
                                id="staticBackdrop"
                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">Record Payment</h5>
                                    <button type="button" className="btn-close"
                                        onClick={() => {
                                            setRecordPaymentModal(false)

                                        }} aria-label="Send Customer"></button>
                                </div>
                                <div className="modal-body">


                                    <FieldArray
                                        name="payment_items"
                                        render={arrayHelpers => (
                                            <div>
                                                {record_payment_validation.values.payment_items.map((item, index) => (
                                                    <div key={index + "item"}>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor={`payment_items.${index}.payment_amount`}>Payment Amount</Label>

                                                                <Field type="text"
                                                                    className="form-control"
                                                                    placeholder="Payment Amount"
                                                                    name={`payment_items.${index}.payment_amount`} />

                                                                <ErrorMessage
                                                                    name={`payment_items.${index}.payment_amount`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor={`payment_items.${index}.receive_date`}>Receive Date</Label>

                                                                <ReactDatePicker
                                                                    showYearDropdown={true}
                                                                    showIcon
                                                                    placeholderText="MM/dd/yyyy"
                                                                    className="form-control"
                                                                    id={`payment_items.${index}.receive_date`}
                                                                    name={`payment_items.${index}.receive_date`}
                                                                    dateFormat="MM/dd/yyyy"
                                                                    onChange={(date) => {
                                                                        record_payment_validation.setFieldValue(`payment_items.${index}.receive_date`, date)
                                                                    }
                                                                    }
                                                                    selected={record_payment_validation?.values?.[`payment_items.${index}.receive_date`] ? new Date(record_payment_validation.values[`payment_items.${index}.receive_date`]) : ''}
                                                                />
                                                                <ErrorMessage
                                                                    name={`payment_items.${index}.receive_date`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor={`payment_items.${index}.reference`}>Reference #</Label>
                                                                <Field type="text"
                                                                    className="form-control"
                                                                    placeholder="Reference #"
                                                                    name={`payment_items.${index}.reference`} />

                                                                <ErrorMessage
                                                                    name={`payment_items.${index}.reference`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor={`payment_items.${index}.payment_type`}>Payment Type</Label>
                                                                <Field as="select"
                                                                    className="form-control"
                                                                    placeholder="Payment Type"
                                                                    name={`payment_items.${index}.payment_type`} >
                                                                    <option value="">Select Payment Type</option>
                                                                    <option value="Check">Check</option>
                                                                    <option value="ACH">ACH</option>
                                                                    <option value="DirectDeposit">DirectDeposit</option>
                                                                    <option value="Cash">Cash</option>
                                                                </Field>

                                                                <ErrorMessage
                                                                    name={`payment_items.${index}.payment_type`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />



                                                                {/* <Input
                                                                    name="customer_action"
                                                                    placeholder="More Actions"
                                                                    type="select"
                                                                    className="form-control"
                                                                    id="customer_action"
                                                                    onChange={(e) => {
                                                                        if (e.target.value == "recordpayment") {
                                                                            setRecordPaymentModal(true);
                                                                        } else {
                                                                            printDocument(e.target.value);
                                                                        }
                                                                    }}
                                                                >

                                                                </Input> */}
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="12">
                                                            <div className="mb-3">
                                                                <div
                                                                    className="form-check form-switch form-switch-md mb-3"
                                                                    dir="ltr"
                                                                >
                                                                    <Label htmlFor={`payment_items.${index}.is_discount_amount_enable`}>Discount</Label>
                                                                    <Field type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"
                                                                        name={`payment_items.${index}.is_discount_amount_enable`} />

                                                                    <ErrorMessage
                                                                        name={`payment_items.${index}.is_discount_amount_enable`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />

                                                                </div>
                                                            </div>

                                                            {record_payment_validation.values.payment_items[index].is_discount_amount_enable && (
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor={`payment_items.${index}.discount_amount`}>Discount Amount</Label>
                                                                    <Field type="text"
                                                                        className="form-control"
                                                                        placeholder="Discount Amount"
                                                                        name={`payment_items.${index}.discount_amount`} />

                                                                    <ErrorMessage
                                                                        name={`payment_items.${index}.discount_amount`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />
                                                                </FormGroup>
                                                            )}
                                                        </Col>

                                                        {record_payment_validation.values.payment_items.length > 1 ? (
                                                            <>
                                                                <Col md="12 text-end">
                                                                    <button className="mb-3" type="button" onClick={() => arrayHelpers.remove(index)}>
                                                                        - Remove Item
                                                                    </button>
                                                                </Col>
                                                            </>
                                                        ) : ''}
                                                    </div>
                                                ))}

                                                <Row className="justify-content-middle">
                                                    <Col md="8"></Col>
                                                    <Col md="4">
                                                        <button
                                                            className="mb-3"
                                                            type="button"
                                                            onClick={() => arrayHelpers.push({ name: '', age: '' })}
                                                        >
                                                            + Add Payment
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    />


                                    {validation.values.is_discount_amount_enable && (
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="discount_amount">Discount Amount</Label>
                                            <Input
                                                name="discount_amount"
                                                placeholder="Discount Amount"
                                                type="text"
                                                className="form-control"
                                                id="discount_amount"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.discount_amount || 0}
                                                invalid={
                                                    validation.touched.discount_amount && validation.errors.discount_amount ? true : false
                                                }
                                            />
                                            {validation.touched.discount_amount && validation.errors.discount_amount ? (
                                                <FormFeedback type="invalid">{validation.errors.discount_amount}</FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    )}

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={(e) => {
                                        handleRecordPaymentActionSubmit(e);
                                    }}>Record Payment</button>

                                </div>
                            </Modal>
                        </Form>
                    </FormikProvider>

                </>
            )}
        </React.Fragment >
    );
};

export default ViewCustomer;