import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown
} from "reactstrap";

import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";


import { addAttorneyContactDetail, AttorneyContactUpdate, employeeResendPassword, getAttorneyContactDetail, validateDuplicateCompEmailId } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import DatatableContainer from "../../../components/Common/DatatableContainer";
import { CaseTypeName, CommonCell } from "./listCol";
import { setAlertMessage } from "../../../store/common/actions";

const FormLayoutsBasic = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL

  useEffect(() => {
    if (editId) {
      dispatch(getAttorneyContactDetail(editId));
    }
  }, [editId]);

  const [apiSuccess, setApiSuccess] = useState(false);

  const { validateCompanyEmailID } = useSelector((state) => ({
    validateCompanyEmailID: state.AttorneyContacts.validateCompanyEmailID,
  }));


  const createBeneficiaryClick = () => {
    if (editId) {
      if (attorneyconatctDetail.status == 'Active') {
        navigate(`/attorney/create-beneficiary?id=${editId}`);
      } else {
        alert("You can create beneficiary account for active petitioners only")
      }
    }
  };


  const { attorneyconatctDetail } = useSelector((state) => ({
    attorneyconatctDetail: state.AttorneyContacts.attorneycontactDetail,
  }));



  const BreadcrumbData = [
    { name: 'Contacts', url: '/attorney/contacts' },
    { name: 'Add Contact', url: '/attorney/add-contact' }
  ]

  const backToListing = () => {
    navigate(`/attorney/contacts`);
  }

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: editId ? attorneyconatctDetail : {
      id: editId,
      first_name: '',
      last_name: '',
      email: '',
      portal_visibility: '',
      company_name: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter representative First Name"),
      last_name: Yup.string().required("Please Enter representative Last Name"),
      email: Yup.string().required("Please Enter Company Email"),
      company_name: Yup.string().required("Please Enter Attorney Company Name"),
      portal_visibility: Yup.string().required("Please select portal access"),
    }),
    onSubmit: (values) => {
      if (Number(editId) > 0) {
        dispatch(AttorneyContactUpdate(values));
        setTimeout(() => {
          navigate("/attorney/contacts");
        }, 3000)
      } else {
        setApiSuccess(true);
        dispatch(validateDuplicateCompEmailId({ email: values.email }))
      }

    }
  });

  useEffect(() => {
    if (apiSuccess) {
      if (validateCompanyEmailID.count < 1 && validation?.values?.email !== "") {
        dispatch(addAttorneyContactDetail(validation.values));
        setTimeout(() => {
          setApiSuccess(false);
          navigate("/attorney/contacts");
        }, 3000)
      }
      else {
        const errorObj = { "response": { "status": "400", "data": { "message": "Error - Please fill required form fields" } } }
        dispatch(setAlertMessage({ message: errorObj, type: "danger" }));
      }
    }

  }, [validateCompanyEmailID.count])

  const [activeTab, setactiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  const onResendLogin = (data) => {
    if (data?.id) {
      dispatch(employeeResendPassword(data?.id));
    }
  };

  const onViewCase = (data) => {
    navigate(`/attorney/view-case?caseId=${data.case_id}`);
  };

  const caseColumns = useMemo(
    () => [
      {
        Header: "Case",
        accessor: "case_number",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Beneficiary",
        accessor: "employee_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Case Type",
        accessor: "case_type",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Questionnaire Type",
        accessor: "qnnr_type",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Processing Status",
        accessor: "processing_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Case Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Created",
        accessor: "created_at",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onViewCase(cellProps.row.original)}>View</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );


  const beneficiariesColumns = useMemo(
    () => [
      {
        Header: "Beneficiary",
        accessor: "beneficiary_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      }, {
        Header: "Cases count",
        accessor: "person_cases_count",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      }, {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onResendLogin(cellProps.row.original)}>Resend Password</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  function capitalize(string) {
    return (string) ? string[0].toUpperCase() + string.slice(1) : string;
  }

  let title = editId ? capitalize(attorneyconatctDetail?.company_name) + '  Information' : 'Add Company';
  return (
    <React.Fragment>
      {(editId ? (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

            <Row>
              <Col xs={12}>
                <Card>

                  <CardBody className="p-4">
                    {/* import TextualInputs */}
                    <div className="mt-4 mt-lg-0">



                      <Nav tabs className="nav-tabs-custom nav-justified nav nav-tabs">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggle("1");
                            }}
                          >
                            Profile
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggle("2");
                            }}
                          >
                            Beneficiaries
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              toggle("3");
                            }}
                          >
                            Cases
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent activeTab={activeTab} className="p-3 text-muted">
                        <TabPane tabId="1">

                          <Row style={{ float: "right" }}>

                            <Col sm="12">
                              <Card>
                                <Link to={`/attorney/update-employer-contacts?id=` + editId} className="btn btn-primary">
                                  <i className="bx bx-edit align-middle"></i>
                                </Link>
                              </Card>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm="12">
                              <Card>

                                <CardHeader>
                                  <h4 className="card-title">Basic information</h4>
                                </CardHeader>
                                <CardBody className="p-4">


                                  {/* import TextualInputs */}
                                  <Row>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Company Name or Organization Name</h5> </Label>
                                        <p>{attorneyconatctDetail?.company_name}</p>
                                      </FormGroup>
                                    </Col>
                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Phone Number</h5> </Label>
                                        <p>{attorneyconatctDetail?.phone}</p>
                                      </FormGroup>
                                    </Col>
                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Fax Number</h5></Label>
                                        <p>{attorneyconatctDetail?.fax}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Email Address</h5></Label>
                                        <p>{attorneyconatctDetail?.email}</p>
                                      </FormGroup>
                                    </Col>
                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Website</h5></Label>
                                        <p>{attorneyconatctDetail?.website}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Portal Access</h5></Label>
                                        <p>{attorneyconatctDetail?.portal_visibility ? "Yes" : "No"}</p>
                                      </FormGroup>
                                    </Col>
                                    <Col md="4">
                                      {attorneyconatctDetail?.company_logo_url && (
                                        <span className="logo-sm">
                                          <img src={attorneyconatctDetail?.company_logo_url} alt="" width="80" height="80" />
                                        </span>
                                      )}
                                    </Col>

                                  </Row>
                                </CardBody>
                              </Card>
                              <Card>
                                <CardHeader>
                                  <h4 className="card-title">Authorized representative</h4>
                                </CardHeader>
                                <CardBody className="p-4">
                                  <Row>
                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">First name </h5></Label>
                                        <p>{attorneyconatctDetail?.first_name}</p>
                                      </FormGroup>
                                    </Col>
                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Last name </h5></Label>
                                        <p>{attorneyconatctDetail?.last_name}</p>
                                      </FormGroup>
                                    </Col>
                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Title</h5></Label>
                                        <p>{attorneyconatctDetail?.title}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Daytime Phone </h5></Label>
                                        <p>{attorneyconatctDetail?.daytime_phone}</p>

                                      </FormGroup>
                                    </Col>
                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Cell Phone </h5></Label>
                                        <p>{attorneyconatctDetail?.cell}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Email Address </h5></Label>
                                        <p>{attorneyconatctDetail?.auth_email}</p>
                                      </FormGroup>
                                    </Col>


                                  </Row>
                                </CardBody>
                              </Card>
                              <Card>
                                <CardHeader>
                                  <h4 className="card-title">Current Address</h4>
                                </CardHeader>
                                <CardBody className="p-4">

                                  <Row>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Street Number And Name </h5></Label>
                                        <p>{attorneyconatctDetail?.current_address}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Apt/Ste/Flr </h5></Label>
                                        <Label>
                                          {attorneyconatctDetail?.current_apt_ste_flr}
                                        </Label>
                                      </FormGroup>

                                    </Col>
                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Number</h5></Label>
                                        <p>{attorneyconatctDetail?.current_number}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">City or Town</h5></Label>
                                        <p>{attorneyconatctDetail?.current_city}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">State</h5></Label>
                                        <p>{attorneyconatctDetail?.current_state}</p>
                                      </FormGroup>
                                    </Col>


                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Zipcode </h5></Label>
                                        <p>{attorneyconatctDetail?.current_zipcode}</p>
                                      </FormGroup>
                                    </Col>


                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Province</h5></Label>
                                        <p>{attorneyconatctDetail?.current_province}</p>
                                      </FormGroup>
                                    </Col>


                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Postal Code</h5></Label>
                                        <p>{attorneyconatctDetail?.current_pcode}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Country </h5></Label>
                                        <p>{attorneyconatctDetail?.current_country}</p>
                                      </FormGroup>
                                    </Col>

                                  </Row>
                                </CardBody>
                              </Card>
                              <Card>
                                <CardHeader>
                                  <h4 className="card-title">Mailing Address</h4>
                                </CardHeader>
                                <CardBody className="p-4">
                                  <Row>
                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Street Number And Name </h5></Label>
                                        <p>{attorneyconatctDetail?.mailing_address}</p>
                                      </FormGroup>
                                    </Col>
                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Apt/Ste/Flr </h5></Label>

                                        <p>{attorneyconatctDetail?.apt_ste_flr}</p>



                                      </FormGroup>

                                    </Col>
                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Number</h5></Label>
                                        <p>{attorneyconatctDetail?.mailing_number}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">City or Town</h5></Label>
                                        <p>{attorneyconatctDetail?.mailing_city}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">State</h5></Label>
                                        <p>{attorneyconatctDetail?.mailing_state}</p>
                                      </FormGroup>
                                    </Col>




                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Zipcode </h5></Label>
                                        <p>{attorneyconatctDetail?.mailing_zipcode}</p>
                                      </FormGroup>
                                    </Col>


                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Province</h5></Label>
                                        <p>{attorneyconatctDetail?.mailing_province}</p>
                                      </FormGroup>
                                    </Col>


                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Postal Code</h5></Label>
                                        <p>{attorneyconatctDetail?.mailing_pcode}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Country </h5></Label>
                                        <p>{attorneyconatctDetail?.mailing_country}</p>
                                      </FormGroup>
                                    </Col>

                                  </Row>
                                </CardBody>
                              </Card>
                              <Card>
                                <CardHeader>
                                  <h4 className="card-title">Other information</h4>
                                </CardHeader>
                                <CardBody className="p-4">

                                  <Row>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Type Of Business </h5></Label>
                                        <p>{attorneyconatctDetail?.business_type}</p>
                                      </FormGroup>
                                    </Col>


                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Individual Irs Tax Number (IRS) </h5></Label>
                                        <p>{attorneyconatctDetail?.irs_tax_num}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">Gross Annual Income (USD)</h5></Label>
                                        <p>{attorneyconatctDetail?.gross_income}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01"><h5 className="text-muted font-size-14">NAISC Code </h5></Label>
                                        <p>{attorneyconatctDetail?.naisc_code}</p>
                                      </FormGroup>
                                    </Col>



                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Year Incorporated In US </h5></Label>
                                        <p>{attorneyconatctDetail?.yiiu}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Net Annual Income (USD) </h5></Label>
                                        <p>{attorneyconatctDetail?.net_income}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Place Incorporated In US </h5></Label>
                                        <p>{attorneyconatctDetail?.place_incorporated}</p>
                                      </FormGroup>
                                    </Col>

                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Federal Employer Identification Number (FEIN) </h5></Label>
                                        <p>{attorneyconatctDetail?.fein}</p>
                                      </FormGroup>
                                    </Col>


                                    <Col md="4">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Current Number Of Employees In The United States </h5></Label>
                                        <p>{attorneyconatctDetail?.cnoeitus}</p>
                                      </FormGroup>
                                    </Col>



                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02"><h5 className="text-muted font-size-14">Are more than 50 percent of these employees in H-1B,L-1A or L-1B nonimmigrant Status ?</h5></Label>
                                        <p>{attorneyconatctDetail?.employees_nonimmigrant_status}</p>
                                      </FormGroup>
                                    </Col>

                                  </Row>


                                </CardBody>

                              </Card>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <Col sm="12">
                              <DatatableContainer
                                columns={beneficiariesColumns}
                                data={attorneyconatctDetail?.beneficiaries || []}
                                isGlobalFilter={true}
                                isUserFilter={true}
                                defaultAttorneyFilter={'Active'}
                                customPageSize={10}
                                isCreateBeneficiary={true}
                                isFilterDisable={true}
                                createBeneficiaryClick={createBeneficiaryClick}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="3">
                          <Row>
                            <Col sm="12">
                              <DatatableContainer
                                columns={caseColumns}
                                data={attorneyconatctDetail?.cases || []}
                                isGlobalFilter={true}
                                isUserFilter={true}
                                defaultAttorneyFilter={'Active'}
                                isAddUserList={false}
                                customPageSize={10}
                                isFilterDisable={true}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>

                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>


          </Container>
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

            <Row>
              <Col xs={12}>
                <Card>

                  <CardBody className="p-4">
                    <Form className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (_.size(validation.errors) > 0) {
                          const errorObj = { "response": { "status": "400", "data": { "message": "Error - Please fill required form fields" } } }
                          dispatch(setAlertMessage({ message: errorObj, type: "danger" }));
                        }
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* import TextualInputs */}
                      <div className="mt-4 mt-lg-0">



                        <Row>

                          <Col sm={6}>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="horizontal-firstname-input"
                              >
                                Clients Business Name *
                              </Label>
                              <Input
                                name="company_name"
                                placeholder="Enter Company Name"
                                type="text"
                                autoComplete="off"
                                className="form-control"
                                id="validationCustom01"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation?.values?.company_name || ""}
                                invalid={
                                  validation.touched.company_name && validation.errors.company_name ? true : false
                                }
                              />
                              {validation.touched.company_name && validation.errors.company_name ? (
                                <FormFeedback type="invalid">{validation.errors.company_name}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>


                          <Col sm={6}>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="horizontal-firstname-input"
                              >
                                Email *
                              </Label>
                              <Input
                                name="email"
                                placeholder="Enter Company Email"
                                type="text"
                                autoComplete="off"
                                className="form-control"
                                id="validationCustom01"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation?.values?.email || ""}
                                invalid={
                                  (validation.touched.email && validation.errors.email) || validateCompanyEmailID?.count >= 1 ? true : false
                                }
                              />
                              {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                              ) : validateCompanyEmailID?.count >= 1 ? (
                                <FormFeedback type="invalid">Employer email already exist.</FormFeedback>
                              ) : null}

                            </FormGroup>

                          </Col>
                        </Row>
                        <Row>
                          <Col sm={4}>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="horizontal-firstname-input"
                              >
                                First Name *
                              </Label>
                              <Input
                                name="first_name"
                                placeholder="Enter Representative First Name"
                                type="text"
                                autoComplete="off"
                                className="form-control"
                                id="validationCustom01"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation?.values?.first_name || ""}
                                invalid={
                                  validation.touched.first_name && validation.errors.first_name ? true : false
                                }
                              />
                              {validation.touched.first_name && validation.errors.first_name ? (
                                <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col sm={4}>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="horizontal-firstname-input"
                              >
                                Last Name *
                              </Label>
                              <Input
                                name="last_name"
                                placeholder="Enter Representative Last Name"
                                type="text"
                                autoComplete="off"
                                className="form-control"
                                id="validationCustom01"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation?.values?.last_name || ""}
                                invalid={
                                  validation.touched.last_name && validation.errors.last_name ? true : false
                                }
                              />
                              {validation.touched.last_name && validation.errors.last_name ? (
                                <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col sm={4}>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="horizontal-firstname-input"
                              >
                                Petitioner Portal Access *
                              </Label>
                              <Input
                                name="portal_visibility"
                                placeholder="Petitioner Portal Access"
                                type="select"
                                className="form-control"
                                id="portal_visibility"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.portal_visibility && validation.errors.portal_visibility ? true : false
                                }
                              >
                                <option value="">Select portal access</option>
                                <option selected={validation.values.portal_visibility == "1"} value="1">Yes</option>
                                <option selected={validation.values.portal_visibility == "0"} value="0">No</option>
                              </Input>
                              {validation.touched.portal_visibility && validation.errors.portal_visibility ? (
                                <FormFeedback type="invalid">{validation.errors.portal_visibility}</FormFeedback>
                              ) : null}

                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-7 col-form-label"
                          >
                            * The registration process will take upto 4 hours or sooner
                          </Label>
                          <Col sm={5}>
                            <div style={{ float: "right" }}>
                              <Button type="reset" color="secondary" onClick={() => navigate(-1)}>
                                Cancel
                              </Button>
                              {" "}
                              <Button type="submit" color="primary" className="ms-1">
                                Save
                              </Button>
                            </div>

                          </Col>
                        </Row>

                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>


          </Container>
        </div>
      ))}
    </React.Fragment>
  );
};

export default FormLayoutsBasic;