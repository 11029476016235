import {
  SHOW_ALERT_FLAG,
  DISABLE_ALERT_FLAG
 } from "./actionTypes"


export const setAlertFlagInfo = (data) => ({
  type: SHOW_ALERT_FLAG,
  payload: data,
})

export const disableAlertFlagInfo = error => ({
  type: DISABLE_ALERT_FLAG,
  payload: error,
})


