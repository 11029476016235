export const SAVE_CASE_FORM_TEMPLATE_DATA="SAVE_CASE_FORM_TEMPLATE_DATA"
export const SAVE_CASE_FORM_TEMPLATE_DATA_SUCCESS = "SAVE_CASE_FORM_TEMPLATE_DATA_SUCCESS"
export const SAVE_CASE_FORM_TEMPLATE_DATA_FAIL = "SAVE_CASE_FORM_TEMPLATE_DATA_FAIL"

export const GENERATE_FORM = "GENERATE_FORM"
export const GENERATE_FORM_FAIL = "GENERATE_FORM_FAIL"
export const GENERATE_FORM_SUCCESS = "GENERATE_FORM_SUCCESS"

export const GENERATE_FORM_DETAIL = "GENERATE_FORM_DETAIL"
export const GENERATE_FORM_DETAIL_FAIL = "GENERATE_FORM_DETAIL_FAIL"
export const GENERATE_FORM_DETAIL_SUCCESS = "GENERATE_FORM_DETAIL_SUCCESS"

/* ATTORNEYQNNRTYPES */
export const GET_ATTORNEYCASES = "GET_ATTORNEYCASES"
export const GET_ATTORNEYCASES_SUCCESS = "GET_ATTORNEYCASES_SUCCESS"
export const GET_ATTORNEYCASES_FAIL = "GET_ATTORNEYCASES_FAIL"

export const GET_ATTORNEYCASES_OVERVIEW = "GET_ATTORNEYCASES_OVERVIEW"
export const GET_ATTORNEYCASES_OVERVIEW_SUCCESS = "GET_ATTORNEYCASES_OVERVIEW_SUCCESS"
export const GET_ATTORNEYCASES_OVERVIEW_FAIL = "GET_ATTORNEYCASES_OVERVIEW_FAIL"

export const GET_ATTORNEYCASE_DETAIL = "GET_ATTORNEYCASE_DETAIL"
export const GET_ATTORNEYCASE_DETAIL_SUCCESS = "GET_ATTORNEYCASE_DETAIL_SUCCESS"
export const GET_ATTORNEYCASE_DETAIL_FAIL = "GET_ATTORNEYCASE_DETAIL_FAIL"

export const GET_ATTORNEYCASE_QNNR_DETAILS = "GET_ATTORNEYCASE_QNNR_DETAILS"
export const GET_ATTORNEYCASE_QNNR_DETAILS_SUCCESS = "GET_ATTORNEYCASE_QNNR_DETAILS_SUCCESS"
export const GET_ATTORNEYCASE_QNNR_DETAILS_FAIL = "GET_ATTORNEYCASE_QNNR_DETAILS_FAIL"


export const GET_CASE_TIMELINES = "GET_CASE_TIMELINES"
export const GET_CASE_TIMELINES_SUCCESS = "GET_CASE_TIMELINES_SUCCESS"
export const GET_CASE_TIMELINES_FAIL = "GET_CASE_TIMELINES_FAIL"

export const GET_ATTORNEYCASE_FORMS = "GET_ATTORNEYCASE_FORMS"
export const GET_ATTORNEYCASE_FORMS_SUCCESS = "GET_ATTORNEYCASE_FORMS_SUCCESS"
export const GET_ATTORNEYCASE_FORMS_FAIL = "GET_ATTORNEYCASE_FORMS_FAIL"

export const GET_ATTORNEYCASE_DOCUMENTS = "GET_ATTORNEYCASE_DOCUMENTS"
export const GET_ATTORNEYCASE_DOCUMENTS_SUCCESS = "GET_ATTORNEYCASE_DOCUMENTS_SUCCESS"
export const GET_ATTORNEYCASE_DOCUMENTS_FAIL = "GET_ATTORNEYCASE_DOCUMENTS_FAIL"

export const ADD_ATTORNEYCASE_DETAIL = "ADD_ATTORNEYCASE_DETAIL"
export const ADD_ATTORNEYCASE_DETAIL_SUCCESS = "ADD_ATTORNEYCASE_DETAIL_SUCCESS"
export const ADD_ATTORNEYCASE_DETAIL_FAIL = "ADD_ATTORNEYCASE_DETAIL_FAIL"

export const DELETE_ATTORNEYCASE_DETAIL = "DELETE_ATTORNEYCASE_DETAIL"
export const DELETE_ATTORNEYCASE_DETAIL_SUCCESS = "DELETE_ATTORNEYCASE_DETAIL_SUCCESS"
export const DELETE_ATTORNEYCASE_DETAIL_FAIL = "DELETE_ATTORNEYCASE_DETAIL_FAIL"

export const UPDATE_ATTORNEYCASE_DETAIL = "UPDATE_ATTORNEYCASE_DETAIL"
export const UPDATE_ATTORNEYCASE_DETAIL_SUCCESS = "UPDATE_ATTORNEYCASE_DETAIL_SUCCESS"
export const UPDATE_ATTORNEYCASE_DETAIL_FAIL = "UPDATE_ATTORNEYCASE_DETAIL_FAIL"


export const GET_ATTORNEYCASETYPES = "GET_ATTORNEYCASETYPES"
export const GET_ATTORNEYCASETYPES_SUCCESS = "GET_ATTORNEYCASETYPES_SUCCESS"
export const GET_ATTORNEYCASETYPES_FAIL = "GET_ATTORNEYCASETYPES_FAIL"

export const GET_ATTORNEYQNNRTYPES = "GET_ATTORNEYQNNRTYPES"
export const GET_ATTORNEYQNNRTYPES_SUCCESS = "GET_ATTORNEYQNNRTYPES_SUCCESS"
export const GET_ATTORNEYQNNRTYPES_FAIL = "GET_ATTORNEYQNNRTYPES_FAIL"


export const UDATE_ATTORNEY_FORM_SHARE = "UDATE_ATTORNEY_FORM_SHARE"
export const UDATE_ATTORNEY_FORM_SHARE_SUCCESS = "UDATE_ATTORNEY_FORM_SHARE_SUCCESS"
export const UDATE_ATTORNEY_FORM_SHARE_FAIL = "UDATE_ATTORNEY_FORM_SHARE_FAIL"

export const REVOKE_ATTORNEY_FORM_SHARE_REVIEW = "REVOKE_ATTORNEY_FORM_SHARE_REVIEW"
export const REVOKE_ATTORNEY_FORM_SHARE_REVIEW_SUCCESS = "REVOKE_ATTORNEY_FORM_SHARE_REVIEW_SUCCESS"
export const REVOKE_ATTORNEY_FORM_SHARE_REVIEW_FAIL = "REVOKE_ATTORNEY_FORM_SHARE_REVIEW_FAIL"

export const ADD_ATTORNEY_DOCUMENT_UPLOAD = "ADD_ATTORNEY_DOCUMENT_UPLOAD"
export const ADD_ATTORNEY_DOCUMENT_UPLOAD_SUCCESS = "ADD_ATTORNEY_DOCUMENT_UPLOAD_SUCCESS"
export const ADD_ATTORNEY_DOCUMENT_UPLOAD_FAIL = "ADD_ATTORNEY_DOCUMENT_UPLOAD_FAIL"

export const REQUEST_ATTORNEY_DOCUMENT = "REQUEST_ATTORNEY_DOCUMENT"
export const REQUEST_ATTORNEY_DOCUMENT_SUCCESS = "REQUEST_ATTORNEY_DOCUMENT_SUCCESS"
export const REQUEST_ATTORNEY_DOCUMENT_FAIL = "REQUEST_ATTORNEY_DOCUMENT_FAIL"

export const UDATE_ATTORNEY_UNLOCK_QUNNR = "UDATE_ATTORNEY_UNLOCK_QUNNR"
export const UDATE_ATTORNEY_UNLOCK_QUNNR_SUCCESS = "UDATE_ATTORNEY_UNLOCK_QUNNR_SUCCESS"
export const UDATE_ATTORNEY_UNLOCK_QUNNR_FAIL = "UDATE_ATTORNEY_UNLOCK_QUNNR_FAIL"

export const UDATE_ATTORNEY_CASEDETAILS = "UDATE_ATTORNEY_CASEDETAILS"
export const UDATE_ATTORNEY_CASEDETAILS_SUCCESS = "UDATE_ATTORNEY_CASEDETAILS_SUCCESS"
export const UDATE_ATTORNEY_CASEDETAILS_FAIL = "UDATE_ATTORNEY_CASEDETAILS_FAIL"

export const UDATE_ATTORNEY_LCADETAILS = "UDATE_ATTORNEY_LCADETAILS"
export const UDATE_ATTORNEY_LCADETAILS_SUCCESS = "UDATE_ATTORNEY_LCADETAILS_SUCCESS"
export const UDATE_ATTORNEY_LCADETAILS_FAIL = "UDATE_ATTORNEY_LCADETAILS_FAIL"

export const BUNDLE_CASEFORMS = "BUNDLE_CASEFORMS"
export const BUNDLE_CASEFORMS_SUCCESS = "BUNDLE_CASEFORMS_SUCCESS"
export const BUNDLE_CASEFORMS_FAIL = "BUNDLE_CASEFORMS_FAIL"

export const BUNDLE_CASE_DOCUMENTS = "BUNDLE_CASE_DOCUMENTS"
export const BUNDLE_CASE_DOCUMENTS_SUCCESS = "BUNDLE_CASE_DOCUMENTS_SUCCESS"
export const BUNDLE_CASE_DOCUMENTS_FAIL = "BUNDLE_CASE_DOCUMENTS_FAIL"

export const SAVE_CASEFORMS = "SAVE_CASEFORMS"
export const SAVE_CASEFORMS_SUCCESS = "SAVE_CASEFORMS_SUCCESS"
export const SAVE_CASEFORMS_FAIL = "SAVE_CASEFORMS_FAIL"

export const SORT_CASEFORMS = "SORT_CASEFORMS"
export const SORT_CASEFORMS_SUCCESS = "SORT_CASEFORMS_SUCCESS"
export const SORT_CASEFORMS_FAIL = "SORT_CASEFORMS_FAIL"



export const GET_NOTE = "GET_NOTE"
export const GET_NOTE_SUCCESS = "GET_NOTE_SUCCESS"
export const GET_NOTE_FAIL = "GET_NOTE_FAIL"


export const GET_REMINDER = "GET_REMINDER"
export const GET_REMINDER_SUCCESS = "GET_REMINDER_SUCCESS"
export const GET_REMINDER_FAIL = "GET_REMINDER_FAIL"


export const GET_MESSAGE = "GET_MESSAGE"
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS"
export const GET_MESSAGE_FAIL = "GET_MESSAGE_FAIL"

export const SAVE_NOTE = "SAVE_NOTE"
export const SAVE_NOTE_SUCCESS = "SAVE_NOTE_SUCCESS"
export const SAVE_NOTE_FAIL = "SAVE_NOTE_FAIL"


export const SAVE_REMINDER = "SAVE_REMINDER"
export const SAVE_REMINDER_SUCCESS = "SAVE_REMINDER_SUCCESS"
export const SAVE_REMINDER_FAIL = "SAVE_REMINDER_FAIL"


export const SAVE_MESSAGE = "SAVE_MESSAGE"
export const SAVE_MESSAGE_SUCCESS = "SAVE_MESSAGE_SUCCESS"
export const SAVE_MESSAGE_FAIL = "SAVE_MESSAGE_FAIL"

export const SHARE_UPLOADED_DOCUMENT_ATTORNEY = "SHARE_UPLOADED_DOCUMENT_ATTORNEY"
export const SHARE_UPLOADED_DOCUMENT_ATTORNEY_SUCCESS = "SHARE_UPLOADED_DOCUMENT_ATTORNEY_SUCCESS"
export const SHARE_UPLOADED_DOCUMENT_ATTORNEY_FAIL = "SHARE_UPLOADED_DOCUMENT_ATTORNEY_FAIL"


export const SEND_REMINDER_DOCUMENT_ATTORNEY = "SEND_REMINDER_DOCUMENT_ATTORNEY"
export const SEND_REMINDER_DOCUMENT_ATTORNEY_SUCCESS = "SEND_REMINDER_DOCUMENT_ATTORNEY_SUCCESS"
export const SEND_REMINDER_DOCUMENT_ATTORNEY_FAIL = "SEND_REMINDER_DOCUMENT_ATTORNEY_FAIL"

export const GET_CASE_DEPENDENTS_ATTORNEY = "GET_CASE_DEPENDENTS_ATTORNEY"
export const GET_CASE_DEPENDENTS_ATTORNEY_SUCCESS = "GET_CASE_DEPENDENTS_ATTORNEY_SUCCESS"
export const GET_CASE_DEPENDENTS_ATTORNEY_FAIL = "GET_CASE_DEPENDENTS_ATTORNEY_FAIL"

export const DELETE_CASE_FORM = "DELETE_CASE_FORM"
export const DELETE_CASE_FORM_SUCCESS = "DELETE_CASE_FORM_SUCCESS"
export const DELETE_CASE_FORM_FAIL = "DELETE_CASE_FORM_FAIL"

export const GET_EMPLOYER_EMPLOYEES = "GET_EMPLOYER_EMPLOYEES"
export const GET_EMPLOYER_EMPLOYEES_SUCCESS = "GET_EMPLOYER_EMPLOYEES_SUCCESS"
export const GET_EMPLOYER_EMPLOYEES_FAIL = "GET_EMPLOYER_EMPLOYEES_FAIL"
