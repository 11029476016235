import {
  GET_ATTRIBUTEGROUPS_FAIL,
  GET_ATTRIBUTEGROUPS_SUCCESS,
  GET_ATTRIBUTEGROUP_DETAIL_SUCCESS,
  GET_ATTRIBUTEGROUP_DETAIL_FAIL,
  UPDATE_ATTRIBUTEGROUP_DETAIL_FAIL,
  UPDATE_ATTRIBUTEGROUP_DETAIL_SUCCESS,
  UPDATE_ATTRIBUTEGROUP_DETAIL,
  DELETE_ATTRIBUTEGROUP_DETAIL_FAIL,
  DELETE_ATTRIBUTEGROUP_DETAIL_SUCCESS,
  DELETE_ATTRIBUTEGROUP_DETAIL,
  ADD_ATTRIBUTEGROUP_DETAIL_SUCCESS,
  ADD_ATTRIBUTEGROUP_DETAIL,
  ADD_ATTRIBUTEGROUP_DETAIL_FAIL
} from "./actionTypes"

const INIT_STATE = {
  attributegroups: [],
  attributeGroupsFilter:[],
  attributeGroupDetail: {},
  error: {},
}

const Attributegroups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ATTRIBUTEGROUPS_SUCCESS:
      return {
        ...state,
        attributegroups: action.payload,
        attributeGroupsFilter: state.attributegroups.filter((item) => item.id !== action.payload),
      }

    case GET_ATTRIBUTEGROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ATTRIBUTEGROUP_DETAIL_SUCCESS:
      return {
        ...state,
        attributeGroupDetail: action.payload,
      }

    case GET_ATTRIBUTEGROUP_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ATTRIBUTEGROUP_DETAIL_SUCCESS:
      return {
        ...state,
        attributegroups: action.payload,
      }

    case ADD_ATTRIBUTEGROUP_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ATTRIBUTEGROUP_DETAIL_SUCCESS:
      return {
        ...state,
        attributegroups: state.attributegroups.filter((item) => item.id !== action.payload),
      };

    case DELETE_ATTRIBUTEGROUP_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_ATTRIBUTEGROUP_DETAIL_SUCCESS:
      return {
        ...state,
        attributegroups: action.payload
      };

    case UPDATE_ATTRIBUTEGROUP_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state
  }
}

export default Attributegroups
