import {
  UPDATE_NOTIFICATION_EMAILS_FAIL,
  UPDATE_NOTIFICATION_EMAILS_SUCCESS,
  UPDATE_LCA_EMPLOYEE_ACCESS_FAIL,
  UPDATE_LCA_EMPLOYEE_ACCESS_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  EMPLOYEE_RESEND_PASSWORD_FAIL,
  EMPLOYEE_RESEND_PASSWORD_SUCCESS,
  EMPLOYER_RESEND_PASSWORD_FAIL,
  EMPLOYER_RESEND_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  UPDATE_QUESTIONAIRE_REVIEW_FAIL,
  UPDATE_QUESTIONAIRE_REVIEW_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  updatenotificationemail: {},
  updatelcaemployeeaccess: {},
  updatequestionariereview: {},
  changepassword: {},
  error: {},
  resetpasswordResponse: [],
  resetPasswordErrorResponse: [],
  forgotpasswordResponse: [],
  forgotPasswordErrorResponse: [],
}

const CommonProfile = (state = INIT_STATE, action) => {
  switch (action.type) {

    case UPDATE_NOTIFICATION_EMAILS_SUCCESS:
      return {
        ...state,
        updatenotificationemail: action.payload,
      }

    case UPDATE_NOTIFICATION_EMAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_LCA_EMPLOYEE_ACCESS_SUCCESS:
      return {
        ...state,
        updatelcaemployeeaccess: action.payload,
      }

    case UPDATE_LCA_EMPLOYEE_ACCESS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_QUESTIONAIRE_REVIEW_SUCCESS:
      return {
        ...state,
        updatequestionariereview: action.payload,
      }

    case UPDATE_QUESTIONAIRE_REVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changepassword: action.payload,
      };

    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordResponse: action.payload,
      };

    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        forgotPasswordErrorResponse: action.payload,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetpasswordResponse: action.payload,
      };

    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetPasswordErrorResponse: action.payload,
      };

    case EMPLOYER_RESEND_PASSWORD_SUCCESS:
      return {
        ...state,
        employerresendpassword: action.payload,
      };

    case EMPLOYER_RESEND_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case EMPLOYEE_RESEND_PASSWORD_SUCCESS:
      return {
        ...state,
        employeeresendpassword: action.payload,
      };

    case EMPLOYEE_RESEND_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state
  }
}

export default CommonProfile
