import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ATTORNEY_DASHBOARD } from "./actionTypes"
import {
  getAttorneyDashboardSuccess,
  getAttorneyDashboardFail

} from "./actions"
import { changePreloader } from "../../../store/actions"

//Include Both Helper File with needed methods
import { getAttorneyDashboard } from "../../../helpers/apibackend_helper"

function* fetchDashboards() {
  try {
    const response = yield call(getAttorneyDashboard)
    yield put(getAttorneyDashboardSuccess(response.data))
  } catch (error) {
    yield put(getAttorneyDashboardFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}



function* Dashboardsaga() {
  yield takeEvery(GET_ATTORNEY_DASHBOARD, fetchDashboards)
}

export default Dashboardsaga
