import React, { useEffect, useMemo, useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import DatatableContainer from "../../../components/Common/DatatableContainer";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { changePreloader, customerDelete, getCustomerDetail, getCustomers } from "../../../store/actions";
import {
  Amount,
  CommonCell
} from "./listCol";

//redux
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";



const CustomersList = () => {

  //meta title
  document.title = "Customers List | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openTab, setopenTab] = useState("Active");
  const [customerdata, setCustomerdata] = useState(null);

  // for delete customer
  const [deleteModal, setDeleteModal] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);


  const { customerDetail, customers } = useSelector((state) => ({
    customers: state.Customers.customers,
    customerDetail: state.Customers.customerDetail,

  }));

  const [contactList, setContactList] = useState({ 'status': 'Active' });


  const [activeTab, setactiveTab] = useState("Active");

  useEffect(() => {
    dispatch(getCustomers({ status: activeTab }));
    dispatch(changePreloader(true));
    setApiSuccess(true)
  }, [dispatch]);

  useEffect(() => {
    if (apiSuccess) {
      dispatch(changePreloader(false));
      setApiSuccess(false)
    }
  }, [customers]);

  const onReloadData = (event) => {
    var tabInfo = event.target.value;
    setactiveTab(tabInfo);
    dispatch(getCustomers({ status: tabInfo }));
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setCustomerdata(null);
    } else {
      setModal(true);
    }
  };
  var userType = window.localStorage.getItem("user_type")

  const handleCustomerClicks = () => {
    navigate(`/${userType}/create-customer`);
  };

  const onViewcustomer = (data) => {
    navigate(`/${userType}/view-customer?customerId=${data.id}`);
  };

  const onEditData = (data) => {
    dispatch(getCustomerDetail({ customer_id: data.id }));
    navigate(`/${userType}/edit-customer?customerId=${data.id}`);
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setCustomerdata(data);
  };

  const handleDeleteCustomer = () => {
    if (customerdata.id) {
      dispatch(customerDelete({ customer_id: customerdata.id }));
      setDeleteModal(false);
      dispatch(getCustomers({ 'status': 'Active' }));
    }
  };


  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" />;
      //   },
      // },
      {
        Header: "Company",
        accessor: "cmp_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Phone",
        accessor: "cmp_phone",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "City",
        accessor: "cmp_city",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "State",
        accessor: "cmp_state",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "Country",
        accessor: "cmp_country",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },

      {
        Header: "Zipcode",
        accessor: "cmp_zipcode",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Amount Due",
        accessor: "invoices_due",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Amount {...cellProps} />;
        },
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {/* <DropdownItem onClick={() => onViewcustomer(cellProps.row.original)}>View</DropdownItem> */}
                <DropdownItem onClick={() => onEditData(cellProps.row.original)}>Edit</DropdownItem>
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  let title = 'Customers';
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/employer/dashboard' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={customers}
                    isGlobalFilter={true}
                    isCustomerFilter={true}
                    defaultAttorneyFilter={'Active'}
                    isCustomerFilterChange={onReloadData}
                    isAddCustomerList={true}
                    customPageSize={10}
                    handleCustomerClick={handleCustomerClicks}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CustomersList;
