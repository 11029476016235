import {
  GET_EMPLOYERCONTACTS,
  GET_EMPLOYERCONTACTS_FAIL,
  GET_EMPLOYERCONTACTS_SUCCESS,
  GET_EMPLOYERCONTACT_DETAIL,
  GET_EMPLOYERCONTACT_DETAIL_FAIL,
  GET_EMPLOYERCONTACT_DETAIL_SUCCESS,
  UPDATE_EMPLOYERCONTACT_DETAIL_FAIL,
  UPDATE_EMPLOYERCONTACT_DETAIL_SUCCESS,
  UPDATE_EMPLOYERCONTACT_DETAIL,
  DELETE_EMPLOYERCONTACT_DETAIL_FAIL,
  DELETE_EMPLOYERCONTACT_DETAIL_SUCCESS,
  DELETE_EMPLOYERCONTACT_DETAIL,
  ADD_EMPLOYERCONTACT_DETAIL_SUCCESS,
  ADD_EMPLOYERCONTACT_DETAIL,
  ADD_EMPLOYERCONTACT_DETAIL_FAIL,
  UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL,
  UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL_SUCCESS,
  UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL_FAIL,
  UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL,
  UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL_SUCCESS,
  UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL_FAIL,
  UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL,
  UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL_SUCCESS,
  UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL_FAIL,
  GET_EMPLOYEREMPLOYEE_COUNTRIES,
  GET_EMPLOYEREMPLOYEE_COUNTRIES_SUCCESS,
  GET_EMPLOYEREMPLOYEE_COUNTRIES_FAIL,
  GET_EMPLOYEREMPLOYEE_STATES,
  GET_EMPLOYEREMPLOYEE_STATES_SUCCESS,
  GET_EMPLOYEREMPLOYEE_STATES_FAIL,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAIL,
  ADD_EMPLOYEREMPLOYEE_DOC,
  ADD_EMPLOYEREMPLOYEE_DOC_SUCCESS,
  ADD_EMPLOYEREMPLOYEE_DOC_FAIL,
  SHARE_EMPLOYEREMPLOYEE_DOC,
  SHARE_EMPLOYEREMPLOYEE_DOC_SUCCESS,
  SHARE_EMPLOYEREMPLOYEE_DOC_FAIL,
  ASSIGN_DOC_TO_CASE,
  ASSIGN_DOC_TO_CASE_SUCCESS,
  ASSIGN_DOC_TO_CASE_FAIL,
  GET_DOCUMENTS_BYCASE,
  GET_DOCUMENTS_BYCASE_SUCCESS,
  GET_DOCUMENTS_BYCASE_FAIL,
  VALIDATE_EMPLOYEE_EMAILID,
  VALIDATE_EMPLOYEE_EMAILID_SUCCESS,
  VALIDATE_EMPLOYEE_EMAILID_FAIL
} from "./actionTypes"

export const getEmployerContacts = (data) => ({
  type: GET_EMPLOYERCONTACTS,
  payload: data,
})

export const getEmployerContactsSuccess = EmployerContact => ({
  type: GET_EMPLOYERCONTACTS_SUCCESS,
  payload: EmployerContact,
})

export const getEmployerContactsFail = error => ({
  type: GET_EMPLOYERCONTACTS_FAIL,
  payload: error,
})

export const getEmployerContactDetail = (data) => ({
  type: GET_EMPLOYERCONTACT_DETAIL,
  payload: data
})

export const getEmployerContactDetailSuccess = EmployerContact => ({
  type: GET_EMPLOYERCONTACT_DETAIL_SUCCESS,
  payload: EmployerContact,
})

export const getEmployerContactDetailFail = error => ({
  type: GET_EMPLOYERCONTACT_DETAIL_FAIL,
  payload: error,
})

export const addEmployerContactDetail = (data) => ({
  type: ADD_EMPLOYERCONTACT_DETAIL,
  payload: data
})

export const addEmployerContactdetailSuccess = (data) => ({
  type: ADD_EMPLOYERCONTACT_DETAIL_SUCCESS,
  payload: data
})

export const addEmployerContactdetailFail = (error) => ({
  type: ADD_EMPLOYERCONTACT_DETAIL_FAIL,
  payload: error
})

export const EmployerContactDelete = (data) => ({
  type: DELETE_EMPLOYERCONTACT_DETAIL,
  payload: data
})

export const EmployerContactDeleteSuccess = (data) => ({
  type: DELETE_EMPLOYERCONTACT_DETAIL_SUCCESS,
  payload: data
})

export const EmployerContactDeleteFail = (error) => ({
  type: DELETE_EMPLOYERCONTACT_DETAIL_FAIL,
  payload: error
})

export const EmployerContactUpdate = (data) => ({
  type: UPDATE_EMPLOYERCONTACT_DETAIL,
  payload: data
})

export const EmployerContactUpdateSuccess = (data) => ({
  type: UPDATE_EMPLOYERCONTACT_DETAIL_SUCCESS,
  payload: data
})

export const EmployerContactUpdateFail = (error) => ({
  type: UPDATE_EMPLOYERCONTACT_DETAIL_FAIL,
  payload: error
})

export const updateEmployeeJobDetails = (data) => ({
  type: UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL,
  payload: data
})

export const updateEmployeeJobDetailsSuccess = (data) => ({
  type: UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL_SUCCESS,
  payload: data
})

export const updateEmployeeJobDetailsFail = (error) => ({
  type: UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL_FAIL,
  payload: error
})

export const updateEmployeePayDetails = (data) => ({
  type: UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL,
  payload: data
})

export const updateEmployeePayDetailsSuccess = (data) => ({
  type: UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL_SUCCESS,
  payload: data
})

export const updateEmployeePayDetailsFail = (error) => ({
  type: UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL_FAIL,
  payload: error
})

export const validateDuplicateEmailId = (data) => ({
  type: VALIDATE_EMPLOYEE_EMAILID,
  payload: data
})

export const validateDuplicateEmailIdSuccess = (data) => ({
  type: VALIDATE_EMPLOYEE_EMAILID_SUCCESS,
  payload: data
})

export const validateDuplicateEmailIdFail = error => ({
  type: VALIDATE_EMPLOYEE_EMAILID_FAIL,
  payload: error,
})

export const updateEmployeePersonalDetails = (data) => ({
  type: UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL,
  payload: data
})

export const updateEmployeePersonalDetailsSuccess = (data) => ({
  type: UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL_SUCCESS,
  payload: data
})

export const updateEmployeePersonalDetailsFail = error => ({
  type: UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL_FAIL,
  payload: error,
})

export const getEmployerEmployeeCountries = () => ({
  type: GET_EMPLOYEREMPLOYEE_COUNTRIES,
  // payload:data
})

export const getEmployerEmployeeCountriessSuccess = countries => ({
  type: GET_EMPLOYEREMPLOYEE_COUNTRIES_SUCCESS,
  payload: countries,
})

export const getEmployerEmployeeCountriesFail = error => ({
  type: GET_EMPLOYEREMPLOYEE_COUNTRIES_FAIL,
  payload: error,
})

export const getEmployerEmployeeStates = (stateID) => ({
  type: GET_EMPLOYEREMPLOYEE_STATES,
  payload: stateID
})

export const getEmployerEmployeeStatesSuccess = states => ({
  type: GET_EMPLOYEREMPLOYEE_STATES_SUCCESS,
  payload: states,
})

export const getEmployerEmployeeStatesFail = error => ({
  type: GET_EMPLOYEREMPLOYEE_STATES_FAIL,
  payload: error,
})

export const createCustomer = (customerName) => ({
  type: CREATE_CUSTOMER,
  payload: customerName
})

export const createCustomerSuccess = data => ({
  type: CREATE_CUSTOMER_SUCCESS,
  payload: data,
})

export const createCustomerFail = error => ({
  type: CREATE_CUSTOMER_FAIL,
  payload: error,
})

export const addEmployerEmployeeDocument = (docValues) => ({
  type: ADD_EMPLOYEREMPLOYEE_DOC,
  payload: docValues
})

export const addEmployerEmployeeDocumentSuccess = data => ({
  type: ADD_EMPLOYEREMPLOYEE_DOC_SUCCESS,
  payload: data,
})

export const addEmployerEmployeeDocumentFail = error => ({
  type: ADD_EMPLOYEREMPLOYEE_DOC_FAIL,
  payload: error,
})

export const shareEmployeeEmployerDoc = (data) => ({
  type: SHARE_EMPLOYEREMPLOYEE_DOC,
  payload: data
})

export const shareEmployeeEmployerDocSuccess = data => ({
  type: SHARE_EMPLOYEREMPLOYEE_DOC_SUCCESS,
  payload: data,
})

export const shareEmployeeEmployerDocFail = error => ({
  type: SHARE_EMPLOYEREMPLOYEE_DOC_FAIL,
  payload: error,
})

export const assignDocToCase = (data) => ({
  type: ASSIGN_DOC_TO_CASE,
  payload: data
})

export const assignDocToCaseSuccess = data => ({
  type: ASSIGN_DOC_TO_CASE_SUCCESS,
  payload: data,
})

export const assignDocToCaseFail = error => ({
  type: ASSIGN_DOC_TO_CASE_FAIL,
  payload: error,
})

export const getDcoumentsByCase = (data) => ({
  type: GET_DOCUMENTS_BYCASE,
  payload: data
})

export const getDcoumentsByCaseSuccess = Documents => ({
  type: GET_DOCUMENTS_BYCASE_SUCCESS,
  payload: Documents,
})

export const getDcoumentsByCaseFail = error => ({
  type: GET_DOCUMENTS_BYCASE_FAIL,
  payload: error,
})





