
import { put, takeEvery } from 'redux-saga/effects';
// Login Redux States
import { SHOW_ALERT_MESSAGE } from "./actionTypes";
import { setAlertMessage, setAlertMessageInfo } from "./actions";


function* displaySuccessMessage({payload:alertInfo}) {
  yield put(setAlertMessageInfo(alertInfo));
}

function* CommonAlertSaga() {
  yield takeEvery(SHOW_ALERT_MESSAGE, displaySuccessMessage);
}

export default CommonAlertSaga;
