import React, { useMemo, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  UncontrolledDropdown
} from "reactstrap";

import { addInvoiceDetail, invoiceDelete, invoiceUpdate } from "../../../../store/actions";

import {
  Amount,
  BillingName,
  Date,
  InvoiceId
} from "./invoicelistCol";

//redux
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

import moment from "moment";
import * as Yup from "yup";

import BeneficiaryDetails from "./BeneficiaryDetails";


const Questionnaire = () => {

  //meta title
  document.title = "Questionnarie | Immidock ";

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [order, setOrder] = useState(null);

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);

  // const { invoices } = useSelector((state) => ({
  //   invoices: state.Invoices.invoices,
  // }));


  const [customActiveTab, setcustomActiveTab] = useState("questionnairebeneficiary");

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const beneficiary_details = [
    {
      id: 1,
      invoiceId: "H1B CAP - F1 To H1B",
      founder: "N/A",
      founderAt: "N/A",
      invoiceID: "14251",
      company: "N/A",
      invoicePrice: "1455",
      Amount: "N/A",
      status: "N/A",
      qnnrprocess: "25"
    },

  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Amount: (order && order.Amount) || "",
      date: (order && order.date) || "",
      founder: (order && order.founder) || "",
      id: (order && order.id) || "",
      invoiceId: (order && order.invoiceId) || "",
      status: (order && order.status) || "Paid",
      color: (order && order.color) || "Success",
    },
    validationSchema: Yup.object({
      Amount: Yup.string().required("Please Enter Amount"),
      founder: Yup.string().required("Please Enter Billing Name"),
      invoiceId: Yup.string().required("Please Enter Invoice Id"),
      status: Yup.string().required("Please Enter Status"),
      date: Yup.date().required("Please Enter Date"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateUser = {
          id: values.id ? values.id : 0,
          Amount: values.Amount,
          founder: values.founder,
          date: moment(values.date).format("DD MMM , YYYY"),
          invoiceId: values.invoiceId,
          status: values.status,
          color: values.color,
        };

        // update user
        dispatch(invoiceUpdate(updateUser));
        validation.resetForm();
        // setIsEdit(false);
      } else {
        const invoice = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          Amount: values["Amount"],
          founder: values["founder"],
          date: moment(values["date"]).format("DD MMM, YYYY"),
          invoiceId: values["invoiceId"],
          status: values["status"],
          color: "success",
        };
        // save new user
        dispatch(addInvoiceDetail(invoice));
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const onDeleteData = (data) => {
    setDeleteModal(true);
    setOrder(data);
  };

  const handleDeleteInvoice = () => {
    if (order.id) {
      dispatch(invoiceDelete(order.id));
      setDeleteModal(false);
    }
  };

  const onUpdateData = (data) => {
    setOrder({
      id: data.id,
      Amount: data.Amount,
      color: data.color,
      date: moment(data.date).format("YYYY-MM-DD"),
      founder: data.founder,
      invoiceId: data.invoiceId,
      status: data.status,
    });

    setIsEdit(true);

    toggle();
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" />;
      //   },
      // },
      {
        Header: "CASE TYPE",
        accessor: "invoiceId",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <InvoiceId {...cellProps} />;
        },
      },
      {
        Header: "Submitted By",
        accessor: "company",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Submitted",
        accessor: "founder",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Updated By",
        accessor: "Amount",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Amount {...cellProps} />;
        },
      },
      {
        Header: "Updated",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Qnnr Progress",
        accessor: "qnnrprocess",
        filterable: true,
        disableFilters: true,
        Cell: (qnnrprocess) => {
          return (
            <div className="animated-progess mb-4">
              <Progress
                value={qnnrprocess.row.original.qnnrprocess}
                color="success"
              >{qnnrprocess.row.original.qnnrprocess}%</Progress>
            </div>
          )
        },
      },
      {
        Header: "ACTIONS",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onUpdateData(cellProps.row.original)}>Edit</DropdownItem>
                <DropdownItem>Print</DropdownItem>
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <BeneficiaryDetails></BeneficiaryDetails>
      {/* <Nav tabs className="nav-tabs-custom nav-justified">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "questionnairebeneficiary",
            })}
            onClick={() => {
              toggleCustom("questionnairebeneficiary");
            }}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home"></i>
            </span>
            <span className="d-none d-sm-block">Beneficiary Details</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "questionnaiarelca",
            })}
            onClick={() => {
              toggleCustom("questionnaiarelca");
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user"></i>
            </span>
            <span className="d-none d-sm-block">LCA Details</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent
        activeTab={customActiveTab}
        className="p-3 text-muted"
      >
        <TabPane tabId="questionnairebeneficiary">
          <BeneficiaryDetails></BeneficiaryDetails>
        </TabPane>
        <TabPane tabId="questionnaiarelca">
          <LCADetails></LCADetails>
        </TabPane>
      </TabContent> */}


    </React.Fragment>
  );
};

export default Questionnaire;
