import {
  ADD_TIMESHEET,
  ADD_TIMESHEET_SUCCESS,
  ADD_TIMESHEET_FAIL,
  GET_EMPLOYEETIMESHEETS,
  GET_EMPLOYEETIMESHEETS_SUCCESS,
  GET_EMPLOYEETIMESHEETS_FAIL,
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAIL,
  VALIDATE_TIMESHEET_BY_PERIOD,
  VALIDATE_TIMESHEET_BY_PERIOD_SUCCESS,
  VALIDATE_TIMESHEET_BY_PERIOD_FAIL
} from "./actionTypes"

export const fetchEmployeeTimesheets = (data) => ({ 
  type: GET_EMPLOYEETIMESHEETS,
  payload: data
})

export const fetchEmployeeTimesheetsSuccess = (data) => ({
  type: GET_EMPLOYEETIMESHEETS_SUCCESS,
  payload: data
})

export const fetchEmployeeTimesheetsFail = (error) => ({
  type: GET_EMPLOYEETIMESHEETS_FAIL,
  payload: error
})

export const addTimesheet = (data) => ({ 
  type: ADD_TIMESHEET,
  payload: data
})

export const addTimesheetSuccess = (data) => ({
  type: ADD_TIMESHEET_SUCCESS,
  payload: data
})

export const addTimesheetFail = (error) => ({
  type: ADD_TIMESHEET_FAIL,
  payload: error
})

export const validateTimesheetByPeriod = (data) => ({ 
  type: VALIDATE_TIMESHEET_BY_PERIOD,
  payload: data
})

export const validateTimesheetByPeriodSuccess = (data) => ({
  type: VALIDATE_TIMESHEET_BY_PERIOD_SUCCESS,
  payload: data
})

export const validateTimesheetByPeriodFail = (error) => ({
  type: VALIDATE_TIMESHEET_BY_PERIOD_FAIL,
  payload: error
})

export const getProjectsSuccess = (data) => ({
  type: GET_PROJECTS_SUCCESS,
  payload: data
})

export const getProjectsFail = (error) => ({
  type: GET_PROJECTS_FAIL,
  payload: error
})

export const getProjects = (data) => ({
  type: GET_PROJECTS,
  payload: data
})

export const createProjectSuccess = (data) => ({
  type: CREATE_PROJECT_SUCCESS,
  payload: data
})

export const createProjectFail = (error) => ({
  type: CREATE_PROJECT_FAIL,
  payload: error
})

export const createProject = (data) => ({
  type: CREATE_PROJECT,
  payload: data
})




