import React, { useRef, useState } from "react";
import { FormGroup, Input, Label, Row } from "reactstrap";

//Import Breadcrumb
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useDispatch } from "react-redux";
import { uploadEmployerEsign } from "../../../store/employer/esign/actions";
import html2canvas from "html2canvas";

const Profile = () => {
  //meta title
  document.title = "Employer | Immidock ";
  const [text, setText] = useState("");
  const [fontSize, setFontSize] = useState(24);
  const [fontColor, setFontColor] = useState("#000000");
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(40);
  const previewRef = useRef(null);
  const dispatch = useDispatch();

  const fontOptions = [
    { label: "IBM Plex Sans", value: "IBM Plex Sans" },
    { label: "Material Design Icons", value: "Material Design Icons" },
    // { label: "boxicons", value: "boxicons" },
    // { label: "Font Awesome 5 Free", value: "Font Awesome 5 Free" },
    { label: "cursive", value: "cursive" },
    // { label: "emoji", value: "emoji" },
    // { label: "fantasy", value: "fantasy" },
    { label: "monospace", value: "monospace" },
    { label: "sans-serif", value: "sans-serif" },
    { label: "system-ui", value: "system-ui" },
  ];
  const [selectedFont, setSelectedFont] = useState(fontOptions[6]);
  function createBlob(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(',');
      var contentType = parts[0].split(':')[1];
      var raw = decodeURIComponent(parts[1]);
      return new Blob([raw], { type: contentType });
    }
    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
  
    var uInt8Array = new Uint8Array(rawLength);
  
    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
  
    return new Blob([uInt8Array], { type: contentType });
  }
  const generateImage = () => {
    html2canvas(previewRef.current).then((canvas) => {
      var dataURL = canvas.toDataURL();
      // const imgData = canvas.toDataURL("image/png");
      // const link = document.createElement("a");
      // link.href = imgData;
      // link.download = `${text}.png`;
      // link.click();
      const reqBody = {
        sign_text: `${text}`,
        font_type: `${selectedFont}`,
        esignature_file: createBlob(dataURL),
      };
      dispatch(uploadEmployerEsign(reqBody));
    });
  };
  let title = 'Profile';
  const BreadcrumbData = [
    {name:'Dashboard', url:'/employer/dashboard'},
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
        <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

          <Row
            className="d-flex align-items-center w-50"
            style={{ margin: "0px auto" }}
          >
            <FormGroup className="mb-3">
              <div className="mb-3">
                <Label className="form-label">{"Signature Text *"}</Label>
                <Input
                  maxLength={50}
                  name={"text"}
                  className={"form-control"}
                  placeholder={"Enter input"}
                  required={true}
                  aria-invalid="true"
                  onChange={(e) => setText(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">{"Select Font family*"}</Label>
                <Select
                  defaultValue={[fontOptions[0]]}
                  options={fontOptions}
                  classNamePrefix="select2-selection"
                  onChange={(option) => {
                    setSelectedFont(option.value);
                  }}
                />
              </div>
              <div className="mb-3">
                <div
                  ref={previewRef}
                  style={{
                    display: "inline-block",
                    background: "rgb(228, 227, 227)",
                    fontFamily: `${selectedFont}`,
                    width: text.length > 5 ? (text.length * 10) + 50 : 100 + "px",
                    height: height + "px",
                    fontSize,
                    color: fontColor,
                    textAlign: "left",
                    lineHeight: height + "px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    padding: "0px 10px",
                    cursor: "none",
                    pointerEvents: "none",
                    userSelect: "none",
                  }}
                >
                  {text}
                </div>
              </div>
              <div className="mb-3">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={generateImage}
                >
                  Submit
                </button>
              </div>
            </FormGroup>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
