import React from 'react';

const Label = ({ label, name, isRequired }) => {
  if (label) {
    label = label.slice(0,1).toUpperCase() + label.slice(1, label.length);
    return (
      <label htmlFor={name} className="form-label mb-0" style={{ fontWeight: "bold"}}>
        <span dangerouslySetInnerHTML={{__html: label}}></span>
        {isRequired ? <span className='text-danger'>*</span> : null}
      </label>
    );
  }

  return null;
};

export default Label;
