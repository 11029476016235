import React from "react";

import EmployerTimesheets from "../../Timesheets";
import EmployerBasicSuite from "../../Basic";

const MainTimesheetComponent = () => {

const getLoggedInPlanType = () => {
    return localStorage.getItem("plan_type");
  }

  if(getLoggedInPlanType()==1) {
    return <EmployerTimesheets/>
  } else {
    return <EmployerBasicSuite componentName="Timesheets"/>
  }
};

export default MainTimesheetComponent;
