import {
  GET_EMPLOYER_DASHBOARD_FAIL,
  GET_EMPLOYER_DASHBOARD_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  employerdashboard: [],
  employerdashboardDetail: {},
  error: {},
}

const EmployerDashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYER_DASHBOARD_SUCCESS:
      return {
        ...state,
        employerdashboards: action.payload,
      }

    case GET_EMPLOYER_DASHBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    default:
      return state
  }
}

export default EmployerDashboard
