import {
  GET_EMPLOYEECASES,
  GET_EMPLOYEECASES_FAIL,
  GET_EMPLOYEECASES_SUCCESS,
  GET_EMPLOYEECASE_DETAIL,
  GET_EMPLOYEECASE_DETAIL_FAIL,
  GET_EMPLOYEECASE_DETAIL_SUCCESS,
  UPDATE_EMPLOYEECASE_DETAIL_FAIL,
  UPDATE_EMPLOYEECASE_DETAIL_SUCCESS,
  UPDATE_EMPLOYEECASE_DETAIL,
  DELETE_EMPLOYEECASE_DETAIL_FAIL,
  DELETE_EMPLOYEECASE_DETAIL_SUCCESS,
  DELETE_EMPLOYEECASE_DETAIL,
  ADD_EMPLOYEECASE_DETAIL_SUCCESS,
  ADD_EMPLOYEECASE_DETAIL,
  ADD_EMPLOYEECASE_DETAIL_FAIL,
  GET_EMPLOYERQNNRTYPES_SUCCESS,
  GET_EMPLOYEECASETYPES,
  GET_EMPLOYERQNNRTYPES_FAIL,
  GET_EMPLOYERQNNRTYPES,
  GET_EMPLOYEECASETYPES_SUCCESS,
  GET_EMPLOYEECASETYPES_FAIL,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO_SUCCESS,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO_FAIL,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_SUCCESS,
  GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_FAIL,
  ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO,
  ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_SUCCESS,
  ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_FAIL,
  GET_EMPLOYEE_STATES,
  GET_EMPLOYEE_STATES_SUCCESS,
  GET_EMPLOYEE_STATES_FAIL,
  GET_EMPLOYEE_COUNTRYS,
  GET_EMPLOYEE_COUNTRYS_SUCCESS,
  GET_EMPLOYEE_COUNTRYS_FAIL,
  GET_EMPLOYEE_NOTIFICATIONS,
  GET_EMPLOYEE_NOTIFICATIONS_SUCCESS,
  GET_EMPLOYEE_NOTIFICATIONS_FAIL,
  GET_EMPLOYEE_PAF_ESIGN_FORM,
  GET_EMPLOYEE_PAF_ESIGN_FORM_SUCCESS,
  GET_EMPLOYEE_PAF_ESIGN_FORM_FAIL,
  UPDATE_EMPLOYEE_PAF_ESIGN_FORM_FAIL,
  UPDATE_EMPLOYEE_PAF_ESIGN_FORM_SUCCESS,
  UPDATE_EMPLOYEE_PAF_ESIGN_FORM,
  UPLOAD_QNNR_DOCUMENT,
  UPLOAD_QNNR_DOCUMENT_SUCCESS,
  UPLOAD_QNNR_DOCUMENT_FAIL,
  READ_NOTIFICATION,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAIL,
  DELETE_QNNR_DOCUMENT,
  DELETE_QNNR_DOCUMENT_SUCCESS,
  DELETE_QNNR_DOCUMENT_FAIL

} from "./actionTypes"

export const getEmployeeCases = (data) => ({
  type: GET_EMPLOYEECASES,
  payload: data
})

export const getEmployeeCasesSuccess = EmployeeCases => ({
  type: GET_EMPLOYEECASES_SUCCESS,
  payload: EmployeeCases,
})



export const getEmployeeCasesFail = error => ({
  type: GET_EMPLOYEECASES_FAIL,
  payload: error,
})

export const getEmployeeCaseDetail = EmployeeCaseId => ({
  type: GET_EMPLOYEECASE_DETAIL,
  EmployeeCaseId,
})

export const getEmployeeCaseDetailSuccess = EmployeeCases => ({
  type: GET_EMPLOYEECASE_DETAIL_SUCCESS,
  payload: EmployeeCases,
})

export const getEmployeeCaseDetailFail = error => ({
  type: GET_EMPLOYEECASE_DETAIL_FAIL,
  payload: error,
})

export const addEmployeeCaseDetail = (data) => ({
  type: ADD_EMPLOYEECASE_DETAIL,
  payload: data
})

export const addEmployeeCasedetailSuccess = (data) => ({
  type: ADD_EMPLOYEECASE_DETAIL_SUCCESS,
  payload: data
})

export const addEmployeeCasedetailFail = (error) => ({
  type: ADD_EMPLOYEECASE_DETAIL_FAIL,
  payload: error
})

export const EmployeeCaseDelete = (data) => ({
  type: DELETE_EMPLOYEECASE_DETAIL,
  payload: data
})

export const EmployeeCaseDeleteSuccess = (data) => ({
  type: DELETE_EMPLOYEECASE_DETAIL_SUCCESS,
  payload: data
})

export const EmployeeCaseDeleteFail = (error) => ({
  type: DELETE_EMPLOYEECASE_DETAIL_FAIL,
  payload: error
})

export const EmployeeCaseUpdate = (data) => ({
  type: UPDATE_EMPLOYEECASE_DETAIL,
  payload: data
})

export const EmployeeCaseUpdateSuccess = (data) => ({
  type: UPDATE_EMPLOYEECASE_DETAIL_SUCCESS,
  payload: data
})

export const EmployeeCaseUpdateFail = (error) => ({
  type: UPDATE_EMPLOYEECASE_DETAIL_FAIL,
  payload: error
})


export const getEmployeeCaseTypes = () => ({
  type: GET_EMPLOYEECASETYPES,
})

export const getEmployeeCaseTypesSuccess = EmployeeCaseTypes => ({
  type: GET_EMPLOYEECASETYPES_SUCCESS,
  payload: EmployeeCaseTypes,
})

export const getEmployeeCaseTypesFail = error => ({
  type: GET_EMPLOYEECASETYPES_FAIL,
  payload: error,
})


export const getEmployeeCaseQnnrTemplateInfo = (data) => ({
  type: GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO,
  payload: data
})

export const getEmployeeCaseQnnrTemplateInfoSucess = EmployeeCases => ({
  type: GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO_SUCCESS,
  payload: EmployeeCases,
})

export const getEmployeeCaseQnnrTemplateInfoFail = error => ({
  type: GET_EMPLOYEE_CASE_QNNR_TEMPLATE_INFO_FAIL,
  payload: error,
})


export const getEmployeeCaseQnnrTemplateGroupInfo = (data) => ({
  type: GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO,
  payload: data
})

export const getEmployeeCaseQnnrTemplateGroupInfoSucess = EmployeeCases => ({
  type: GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_SUCCESS,
  payload: EmployeeCases,
})



export const getEmployeeCaseQnnrTemplateGroupInfoFail = error => ({
  type: GET_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_FAIL,
  payload: error,
})

export const addEmployeeCaseQnnrTemplateGroupInfo = (data) => ({
  type: ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO,
  payload: data
})
export const addEmployeeCaseQnnrTemplateGroupInfoSucess = EmployeeCases => ({
  type: ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_SUCCESS,
  payload: EmployeeCases,
})

export const addEmployeeCaseQnnrTemplateGroupInfoFail = error => ({
  type: ADD_EMPLOYEE_CASE_QNNR_TEMPLATE_GROUP_INFO_FAIL,
  payload: error,
})

export const uploadQnnrDocument = (data) => ({
  type: UPLOAD_QNNR_DOCUMENT,
  payload: data
})
export const uploadQnnrDocumentSucess = EmployeeCases => ({
  type: UPLOAD_QNNR_DOCUMENT_SUCCESS,
  payload: EmployeeCases,
})

export const uploadQnnrDocumentFail = error => ({
  type: UPLOAD_QNNR_DOCUMENT_FAIL,
  payload: error,
})

export const deleteQnnrDocument = (data) => ({
  type: DELETE_QNNR_DOCUMENT,
  payload: data
})

export const deleteQnnrDocumentSucess = EmployeeCases => ({
  type: DELETE_QNNR_DOCUMENT_SUCCESS,
  payload: EmployeeCases,
})

export const deleteQnnrDocumentFail = error => ({
  type: DELETE_QNNR_DOCUMENT_FAIL,
  payload: error,
})

export const getEmployeeStates = (data) => ({
  type: GET_EMPLOYEE_STATES,
  payload: data
})

export const getEmployeeStatesSuccess = EmployeeCases => ({
  type: GET_EMPLOYEE_STATES_SUCCESS,
  payload: EmployeeCases,
})

export const getEmployeeStatesFail = error => ({
  type: GET_EMPLOYEE_STATES_FAIL,
  payload: error,
})

export const getEmployeeCountrys = (data) => ({
  type: GET_EMPLOYEE_COUNTRYS,
  payload: data
})

export const getEmployeeCountrysSuccess = EmployeeCases => ({
  type: GET_EMPLOYEE_COUNTRYS_SUCCESS,
  payload: EmployeeCases,
})

export const getEmployeeCountrysFail = error => ({
  type: GET_EMPLOYEE_COUNTRYS_FAIL,
  payload: error,
})


export const getEmployeeNotifications = (data) => ({
  type: GET_EMPLOYEE_NOTIFICATIONS,
  payload: data
})

export const getEmployeeNotificationsSuccess = EmployeeCases => ({
  type: GET_EMPLOYEE_NOTIFICATIONS_SUCCESS,
  payload: EmployeeCases,
})

export const getEmployeeNotificationsFail = error => ({
  type: GET_EMPLOYEE_NOTIFICATIONS_FAIL,
  payload: error,
})


export const readNotification = (data) => ({
  type: READ_NOTIFICATION,
  payload: data
})

export const readNotificationSuccess = notification => ({
  type: READ_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const readNotificationFail = error => ({
  type: READ_NOTIFICATION_FAIL,
  payload: error,
})



export const getEmployeePAFEsignForm = (data) => ({
  type: GET_EMPLOYEE_PAF_ESIGN_FORM,
  payload: data
})

export const getEmployeePAFEsignFormSuccess = EmployeeCases => ({
  type: GET_EMPLOYEE_PAF_ESIGN_FORM_SUCCESS,
  payload: EmployeeCases,
})

export const getEmployeePAFEsignFormFail = error => ({
  type: GET_EMPLOYEE_PAF_ESIGN_FORM_FAIL,
  payload: error,
})


export const updateEmployeePafEsignForm = (data) => ({
  type: UPDATE_EMPLOYEE_PAF_ESIGN_FORM,
  payload: data
})

export const updateEmployeePafEsignFormSuccess = EmployeeCases => ({
  type: UPDATE_EMPLOYEE_PAF_ESIGN_FORM_SUCCESS,
  payload: EmployeeCases,
})

export const updateEmployeePafEsignFormFail = error => ({
  type: UPDATE_EMPLOYEE_PAF_ESIGN_FORM_FAIL,
  payload: error,
})
