/* QNNRTYPES */
export const GET_ATTORNEY_CONTACTS = "GET_ATTORNEY_CONTACTS"
export const GET_ATTORNEY_CONTACTS_SUCCESS = "GET_ATTORNEY_CONTACTS_SUCCESS"
export const GET_ATTORNEY_CONTACTS_FAIL = "GET_ATTORNEY_CONTACTS_FAIL"

export const GET_ATTORNEY_CONTACT_DETAIL = "GET_ATTORNEY_CONTACT_DETAIL"
export const GET_ATTORNEY_CONTACT_DETAIL_SUCCESS = "GET_ATTORNEY_CONTACT_DETAIL_SUCCESS"
export const GET_ATTORNEY_CONTACT_DETAIL_FAIL = "GET_ATTORNEY_CONTACT_DETAIL_FAIL"

export const ADD_ATTORNEY_CONTACT_DETAIL = "ADD_ATTORNEY_CONTACT_DETAIL"
export const ADD_ATTORNEY_CONTACT_DETAIL_SUCCESS = "ADD_ATTORNEY_CONTACT_DETAIL_SUCCESS"
export const ADD_ATTORNEY_CONTACT_DETAIL_FAIL = "ADD_ATTORNEY_CONTACT_DETAIL_FAIL"

export const DELETE_ATTORNEY_CONTACT_DETAIL = "DELETE_ATTORNEY_CONTACT_DETAIL"
export const DELETE_ATTORNEY_CONTACT_DETAIL_SUCCESS = "DELETE_ATTORNEY_CONTACT_DETAIL_SUCCESS"
export const DELETE_ATTORNEY_CONTACT_DETAIL_FAIL = "DELETE_ATTORNEY_CONTACT_DETAIL_FAIL"

export const UPDATE_ATTORNEY_CONTACT_DETAIL = "UPDATE_ATTORNEY_CONTACT_DETAIL"
export const UPDATE_ATTORNEY_CONTACT_DETAIL_SUCCESS = "UPDATE_ATTORNEY_CONTACT_DETAIL_SUCCESS"
export const UPDATE_ATTORNEY_CONTACT_DETAIL_FAIL = "UPDATE_ATTORNEY_CONTACT_DETAIL_FAIL"

export const VALIDATE_COMPANY_EMAILID = "VALIDATE_COMPANY_EMAILID"
export const VALIDATE_COMPANY_EMAILID_SUCCESS = "VALIDATE_COMPANY_EMAILID_SUCCESS"
export const VALIDATE_COMPANY_EMAILID_FAIL = "VALIDATE_COMPANY_EMAILID_FAIL"


