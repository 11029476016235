/* QNNRTYPES */
export const GET_CASEFORMS = "GET_CASEFORMS"
export const GET_CASEFORMS_SUCCESS = "GET_CASEFORMS_SUCCESS"
export const GET_CASEFORMS_FAIL = "GET_CASEFORMS_FAIL"

export const GET_CASE_FORM = "GET_CASE_FORM"
export const GET_CASE_FORM_SUCCESS = "GET_CASE_FORM_SUCCESS"
export const GET_CASE_FORM_FAIL = "GET_CASE_FORM_FAIL"

export const ADD_CASEFORMS = "ADD_CASEFORMS"
export const ADD_CASEFORMS_SUCCESS = "ADD_CASEFORMS_SUCCESS"
export const ADD_CASEFORMS_FAIL = "ADD_CASEFORMS_FAIL"

export const UPDATE_CASE_FORM = "UPDATE_CASE_FORM"
export const UPDATE_CASE_FORM_SUCCESS = "UPDATE_CASE_FORM_SUCCESS"
export const UPDATE_CASE_FORM_FAIL = "UPDATE_CASE_FORM_FAIL"