import React, { useState } from "react";
import Label from "./Label";
import {
    FormGroup, Input
} from "reactstrap";
import { checkValidityClass } from "../Shared/utility";

// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const InputTextarea = ({ name, label, type, placeholder, className, required, invalidMessage, value, content, onChanged }) => {
    const [editorDescription, seteditorDescription] = useState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
            convertFromHTML(value)
        )
    ));

    const onEditorDescriptionStateChange = (editorDescription) => {
        seteditorDescription(editorDescription);
        var event = {}
        event.target = { value: draftToHtml(convertToRaw(editorDescription.getCurrentContent())) };
        onChanged(event, content.name);
    };

    return (
        <FormGroup className="mb-3">
            <Label
                label={content.label}
                name={content.name}
                isRequired={content.required}
            />
            {((content?.select_field_type == "richEditor")
                ? (<>
                    <Editor
                        defaultContentState={convertToRaw(ContentState.createFromBlockArray(htmlToDraft("")))}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        editorState={editorDescription}
                        onEditorStateChange={onEditorDescriptionStateChange}
                        type="textarea"
                        id={content.name}
                        editorStyle={{ height: (content?.rows)? content?.rows+'rem': '10rem' }}
                    />

                </>
                ) : (
                    <Input
                        name={content.name}
                        type={content.type}
                        className={`${checkValidityClass(invalidMessage)} ${className ? 'form-control is-invalid' : 'form-control'}`}
                        id={content.name}
                        placeholder={content.placeholder}
                        required={content.required}
                        value={value}
                        aria-invalid="true"
                        aria-describedby={`invalid_${content.name}`}
                        onChange={onChanged}
                        minLength={content?.minlength ?? ''}
                        maxLength={content?.maxlength ?? ''}
                        rows={content?.rows ?? '4'}
                    />
                ))}
            <div type="invalid" className="invalid-feedback">{invalidMessage}</div>
        </FormGroup>
    );
};


export default InputTextarea