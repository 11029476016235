import {
  GET_EMPLOYER_COMPANY_FAIL,
  GET_EMPLOYER_COMPANY_SUCCESS,
  GET_EMPLOYER_COMPANY_DETAIL_SUCCESS,
  GET_EMPLOYER_COMPANY_DETAIL_FAIL,
  UPDATE_EMPLOYER_COMPANY_DETAIL_FAIL,
  UPDATE_EMPLOYER_COMPANY_DETAIL_SUCCESS,
  DELETE_EMPLOYER_COMPANY_DETAIL_FAIL,
  DELETE_EMPLOYER_COMPANY_DETAIL_SUCCESS,
  ADD_EMPLOYER_COMPANY_DETAIL_SUCCESS,
  ADD_EMPLOYER_COMPANY_DETAIL_FAIL
} from "./actionTypes"

const INIT_STATE = {
  employercompany: [],
  employercompanyDetail: {},
  error: {},
}

const EmployerCompany = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYER_COMPANY_SUCCESS:
      return {
        ...state,
        employercompany: action.payload,
      }

    case GET_EMPLOYER_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYER_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        employercompanyDetail: action.payload,
      }

    case GET_EMPLOYER_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_EMPLOYER_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        employercompany: [...state.employercompany, action.payload],
      }

    case ADD_EMPLOYER_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_EMPLOYER_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        employercompany: state.employercompany.filter((item) => item.id !== action.payload),
      };

    case DELETE_EMPLOYER_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_EMPLOYER_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        // employercompany: state.contacts.map((row) =>
        //   row.id.toString() === action.payload.id.toString()
        //     ? { row, ...action.payload }
        //     : row
        // ),
      };

    case UPDATE_EMPLOYER_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state
  }
}

export default EmployerCompany
