import React, { useEffect, useState } from "react";
import {
    Button,
    Card, CardBody, CardHeader, Col, Container,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";



import { setAlertMessage } from "../../../store/common/actions";

import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
// Form Editor
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';


import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import { addCustomerDetail, customerUpdate, getCustomerDetail, getCustomers, getCustomerSettings, getEmployerContacts, uploadCustomerAttachment } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
// Form Mask
import InputMask from "react-input-mask";



const CreateCustomer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const customerId = queryParams.get('customerId'); // Read the 'id' query parameter from the URL

    const createCustomerFrom = queryParams.get('createCustomer');
    const employeeId = queryParams.get('employeeId');

    const [sendCustomer, setSendCustomer] = useState(true)
    const [activeTab1, setactiveTab1] = useState("customer");
    const toggle1 = (tab) => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };

    const [subTotal, setSubTotal] = useState("0.00");
    const [discount, setDiscount] = useState("0.00");
    const [grandTotal, setGrandTotal] = useState("0.00");

    let total = () => {
        let val = 0;
        validation.values.contactDetails.map((item, index) => (
            item.hours * item.rate ? val += parseFloat((item.hours * item.rate)) : ""
        ))
        return validation.values.customer_amount = parseFloat(val).toFixed(2);
    };

    let calculateddiscount = () => {
        let calculated_discount = 0;
        if (validation.values.is_discount_amount_enable) {
            calculated_discount = parseFloat(validation.values.discount_amount);
        }
        if (validation.values.is_discount_percentage_enable) {
            calculated_discount = (validation.values.discount_percentage / 100) * total();;
        }
        return validation.values.total_discount_amount = parseFloat(calculated_discount).toFixed(2);
    };

    let grandtotal = () => {
        let grand_total = parseFloat(total() - calculateddiscount()).toFixed(2);
        return validation.values.total_amount = grand_total;
    }

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState("");

    function handleAcceptedFiles(files) {
        files.map((file, index) => {

            // console.log("file", file)
            // console.log("file", URL.createObjectURL(file))
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            }
            )
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onloadend = () => {
                const binaryStr = reader.result
                // console.log(binaryStr)
                dispatch(uploadCustomerAttachment({ customer_attach_file: binaryStr }));
                setTimeout(() => {
                    files[index]["attachment_url"] = typeof uploadedFiles === 'string' ? uploadedFiles : "";
                    files[index]["checked"] = true;
                }, 12000);

                files[index]['attachment'] = { 'attachment_name': file.name, 'attachment_url': (typeof uploadedFiles === 'string' ? uploadedFiles : "") };
            }
            reader.readAsArrayBuffer(file)

        });

        // console.log("files", files)
        // console.log("uploadedcustomerattachments", uploadedcustomerattachments)
        // setUploadedFiles(uploadedfile => [...uploadedfile, ...uploadedcustomerattachments]);
        // console.log("uploadedfile", uploadedFiles)

        setSelectedFiles(currentfiles => [...currentfiles, ...files]);

    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const [contactList, setContactList] = useState({ "status": "All", "emp_type": "All" });
    const [billedTo, setBilledTo] = useState([]);
    const [candidate, setCandidate] = useState([]);
    const [editorState, seteditorState] = useState({});
    const [editorFooterDescription, seteditorFooterDescription] = useState({});
    const [initialValues, setInitialValues] = useState(false);
    const moreactions = [{ 'label': 'Save', 'action': 'save' }, { 'label': 'Save and send', 'action': 'saveandsend' }]
    const handleActionSubmit = (e) => {
        // setSendCustomer(true)
        validation.handleSubmit();
    };

    const onEditorFooterStateChange = (editorFooterDescription) => {
        seteditorFooterDescription(editorFooterDescription)
        validation.values.footer_description = draftToHtml(convertToRaw(editorFooterDescription.getCurrentContent()));
    };

    const { customerDetail, createCustomerDetail, customers, employercontactDetail, employercontacts, customersettings, uploadedcustomerattachments } = useSelector((state) => ({
        // employercontactDetail: state.EmployerContacts.employercontactDetail,

        employercontacts: state.EmployerContacts.employercontacts || [],
        customerDetail: state.Customers.customerDetail || {},
        customersettings: state.Customers.customersettings || {},
        customers: state.Customers.customers || {},
        createCustomerDetail: state.Customers.createCustomerDetail || {},
        uploadedcustomerattachments: state.Customers.uploadedcustomerattachments || "",
    }));

    useEffect(() => {
        dispatch(getEmployerContacts(contactList));
        dispatch(getCustomerSettings({}));
        // dispatch(getCustomerSettings(values));
        setInitialValues(customersettings?.customer_settings)
        // dispatch(getEmployerContactDetail(customerId));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getCustomerDetail({ customer_id: customerId }));
        // dispatch(getEmployerContacts(contactList));
        // dispatch(getCustomerSettings({}));
        // dispatch(getCustomerSettings(values));
        // setInitialValues(customersettings?.customer_settings)
    }, [customerId, dispatch]);


    // useEffect(() => {
    //     // console.log("selectedFiles", selectedFiles)
    //     // // customer_attachments: [{ 'attachment_name': '', 'attachment_url': '' }],
    //     // const attachfile_list = selectedFiles.map((selectedfile, index) => {
    //     //     return { 'attachment_name': selectedfile.name, 'attachment_url': selectedfile.attachment_url }
    //     // });
    //     // validation.values.customer_attachments = selectedFiles;
    //     // console.log("customer_attachments", validation.values.customer_attachments)
    // }, [selectedFiles])

    useEffect(() => {
        setUploadedFiles(uploadedcustomerattachments)
    }, [uploadedcustomerattachments])


    const iniValues = {
        customer_id: customerId,
        cmp_name: "",
        cmp_phone: "",
        cmp_fax: "",
        cmp_street: "",
        cmp_state: "",
        cmp_apt_ste_flr: "",
        cmp_number: "",
        cmp_country: "",
        cmp_city: "",
        cmp_country: "",
        cmp_zipcode: "",
        inv_first_name: "",
        inv_last_name: "",
        inv_email: "",
        inv_phone: "",
        payment_terms: "",
        contactDetails: [{ contact_first_name: '', contact_last_name: '', contact_email: '', contact_phone: '' }],
    };
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: (customerId && (Object.keys(customerDetail).length > 0)) ? customerDetail : iniValues,

        validationSchema: Yup.object({
            cmp_name: Yup.string().required("Please Enter Company Name").nullable(),
            cmp_phone: Yup.string().required("Please Enter Phone").nullable(),
            cmp_fax: Yup.string().required("Please Enter fax").nullable(),
            cmp_street: Yup.string().required("Please Enter Street Number And Name").nullable(),
            cmp_apt_ste_flr: Yup.string().required("Please Select Apt/Ste/Flr").nullable(),
            cmp_number: Yup.string().required("Please Enter Number").nullable(),
            cmp_city: Yup.string().required("Please Enter City").nullable(),
            cmp_state: Yup.string().required("Please Select State").nullable(),
            cmp_country: Yup.string().required("Please Select Country").nullable(),
            cmp_zipcode: Yup.string().required("Please Enter Zipcode").nullable(),

            inv_first_name: Yup.string().required("Please Enter First Name").nullable(),
            inv_last_name: Yup.string().required("Please Enter Last Name").nullable(),
            inv_email: Yup.string().required("Please Enter Email address").nullable(),
            inv_phone: Yup.string().required("Please Enter Phone").nullable(),
            payment_terms: Yup.string().required("Please Select Payment Terms").nullable(),


            contactDetails: Yup.array()
                .of(
                    Yup.object().shape({
                        contact_first_name: Yup.string().required("Please Enter First Name").nullable(),
                        contact_last_name: Yup.string().required("Please Enter Last Name").nullable(),
                        contact_email: Yup.string().required("Please Enter Email address").nullable(),
                        contact_phone: Yup.string().required("Please Enter Phone").nullable(),
                    })
                )
                .required('Must filled the item details'),

        }),
        onSubmit: (values) => {
            if (Number(customerId) > 0) {
                values.customer_id = values.id;
                dispatch(customerUpdate(values));
            } else {
                dispatch(addCustomerDetail(values));
            }
            setTimeout(() => {
                dispatch(getCustomers({ 'status': 'Active' }));
                if (_.isEmpty(employeeId)) {
                    var userType = window.localStorage.getItem("user_type")
                    navigate(`/${userType}/customers`, { replace: true });
                } else {
                    navigate("/employer/add-employer-contact?id=" + employeeId);
                }
            }, 500)

        }
    });


    let title = customerId ? 'Update Customer' : 'Create Customer';

    const BreadcrumbData = [
        { name: 'Customers', url: '/employer/customers' },
        { name: title, url: '#' }
    ]
    //meta title
    document.title = title + " | Immidock ";

    return (
        <React.Fragment>
            <>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                        <Col xs={12}>
                            <Card>

                                <CardHeader>
                                    <h4 className="card-title">Customer Details</h4>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <FormikProvider value={validation}>
                                        <Form className="needs-validation"
                                            onSubmit={(values) => {
                                                values.preventDefault();
                                                if (_.size(validation.errors) > 0) {
                                                    const errorObj = { "response": { "status": "400", "data": { "message": "Error - Please fill required form fields" } } }
                                                    dispatch(setAlertMessage({ message: errorObj, type: "danger" }));
                                                }
                                                // console.log(validate(values));
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="example-date-input" className="form-Label">Company Details</Label>
                                                    </div></Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom01">Company Name *</Label>
                                                        <Input
                                                            name="cmp_name"
                                                            placeholder="Company Name"
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom01"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.cmp_name || ""}
                                                            invalid={
                                                                validation.touched.cmp_name && validation.errors.cmp_name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.cmp_name && validation.errors.cmp_name ? (
                                                            <FormFeedback type="invalid">{validation.errors.cmp_name}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">Phone Number *</Label>

                                                        <InputMask
                                                            name="cmp_phone"
                                                            mask="(999)-999-9999"
                                                            className={` ${validation.touched.cmp_phone && validation.errors.cmp_phone ? 'form-control is-invalid' : 'form-control'}`}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.cmp_phone || ""}
                                                            invalid={
                                                                validation.touched.cmp_phone && validation.errors.cmp_phone ? true : false
                                                            }
                                                        >
                                                            {(inputProps) => (
                                                                // <Input  type="tel" className="" />
                                                                <InputMask
                                                                    {...inputProps}
                                                                    mask="(999)-999-9999"
                                                                    placeholder="Enter Phone"
                                                                    type="text"
                                                                />
                                                            )}
                                                        </InputMask>
                                                        {validation.touched.cmp_phone && validation.errors.cmp_phone ? (
                                                            <FormFeedback type="invalid">{validation.errors.cmp_phone}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">Fax Number</Label>
                                                        <Input
                                                            name="cmp_fax"
                                                            placeholder="Enter Fax"
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.cmp_fax || ""}
                                                            invalid={
                                                                validation.touched.cmp_fax && validation.errors.cmp_fax ? true : false
                                                            }
                                                        />
                                                        {validation.touched.cmp_fax && validation.errors.cmp_fax ? (
                                                            <FormFeedback type="invalid">{validation.errors.cmp_fax}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>

                                            </Row>

                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="example-date-input" className="form-Label">Address of Customer</Label>
                                                    </div></Col>
                                            </Row>
                                            <Row>

                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom01">Street Number and Name *</Label>
                                                        <Input
                                                            name="cmp_street"
                                                            placeholder="Street Number And Name"
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom01"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.cmp_street || ""}
                                                            invalid={
                                                                validation.touched.cmp_street && validation.errors.cmp_street ? true : false
                                                            }
                                                        />
                                                        {validation.touched.cmp_street && validation.errors.cmp_street ? (
                                                            <FormFeedback type="invalid">{validation.errors.cmp_street}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom01">Apt/Ste/Flr *</Label>
                                                        <div className="hstack gap-3">

                                                            <Input
                                                                name="cmp_apt_ste_flr"
                                                                type="radio"
                                                                className="form-control"
                                                                id="validationCustom02"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value="Apt"
                                                                // invalid={
                                                                //     validation.touched.cmp_apt_ste_flr && validation.errors.cmp_apt_ste_flr ? true : false
                                                                // }
                                                                checked={validation.values.cmp_apt_ste_flr == "Apt" ? true : false}
                                                                defaultChecked={validation.values.cmp_apt_ste_flr == "Apt" ? true : false}
                                                            />

                                                            Apt
                                                            <Input
                                                                name="cmp_apt_ste_flr"
                                                                type="radio"
                                                                className="form-control"
                                                                id="validationCustom02"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value="Ste"
                                                                // invalid={
                                                                //     validation.touched.cmp_apt_ste_flr && validation.errors.cmp_apt_ste_flr ? true : false
                                                                // }
                                                                checked={validation.values.cmp_apt_ste_flr == "Ste" ? true : false}
                                                                defaultChecked={validation.values.cmp_apt_ste_flr == "Ste" ? true : false}
                                                            />
                                                            Ste

                                                            <Input
                                                                name="cmp_apt_ste_flr"
                                                                type="radio"
                                                                className="form-control"
                                                                id="validationCustom02"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value="Flr"
                                                                // invalid={
                                                                //     validation.touched.cmp_apt_ste_flr && validation.errors.cmp_apt_ste_flr ? true : false
                                                                // }
                                                                checked={validation.values.cmp_apt_ste_flr == "Flr" ? true : false}
                                                                defaultChecked={validation.values.cmp_apt_ste_flr == "Flr" ? true : false}
                                                            />Flr

                                                        </div>

                                                        {validation.touched.cmp_apt_ste_flr && validation.errors.cmp_apt_ste_flr ? (
                                                            <FormFeedback type="invalid">{validation.errors.cmp_apt_ste_flr}</FormFeedback>
                                                        ) : null}


                                                    </FormGroup>

                                                </Col>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">Number *</Label>
                                                        <Input
                                                            name="cmp_number"
                                                            placeholder="Number"
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.cmp_number || ""}
                                                            invalid={
                                                                validation.touched.cmp_number && validation.errors.cmp_number ? true : false
                                                            }
                                                        />
                                                        {validation.touched.cmp_number && validation.errors.cmp_number ? (
                                                            <FormFeedback type="invalid">{validation.errors.cmp_number}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">City or Town*</Label>
                                                        <Input
                                                            name="cmp_city"
                                                            placeholder="City"
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.cmp_city || ""}
                                                            invalid={
                                                                validation.touched.cmp_city && validation.errors.cmp_city ? true : false
                                                            }
                                                        />
                                                        {validation.touched.cmp_city && validation.errors.cmp_city ? (
                                                            <FormFeedback type="invalid">{validation.errors.cmp_city}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">State*</Label>
                                                        <Input
                                                            name="cmp_state"
                                                            placeholder="State"
                                                            type="select"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.cmp_state || ""}
                                                            invalid={
                                                                validation.touched.cmp_state && validation.errors.cmp_state ? true : false
                                                            }
                                                        >

                                                            <option value="">Choose State</option>

                                                            <option value="AL">Alabama</option>

                                                            <option value="AK">Alaska</option>

                                                            <option value="AS">American Samoa</option>

                                                            <option value="AZ">Arizona</option>

                                                            <option value="AR">Arkansas</option>

                                                            <option value="UM-81">Baker Island</option>

                                                            <option value="CA">California</option>

                                                            <option value="CO">Colorado</option>

                                                            <option value="CT">Connecticut</option>

                                                            <option value="DE" selected="">Delaware</option>

                                                            <option value="DC">District of Columbia</option>

                                                            <option value="FL">Florida</option>

                                                            <option value="GA">Georgia</option>

                                                            <option value="GU">Guam</option>

                                                            <option value="HI">Hawaii</option>

                                                            <option value="UM-84">Howland Island</option>

                                                            <option value="ID">Idaho</option>

                                                            <option value="IL">Illinois</option>

                                                            <option value="IN">Indiana</option>

                                                            <option value="IA">Iowa</option>

                                                            <option value="UM-86">Jarvis Island</option>

                                                            <option value="UM-67">Johnston Atoll</option>

                                                            <option value="KS">Kansas</option>

                                                            <option value="KY">Kentucky</option>

                                                            <option value="UM-89">Kingman Reef</option>

                                                            <option value="LA">Louisiana</option>

                                                            <option value="ME">Maine</option>

                                                            <option value="MD">Maryland</option>

                                                            <option value="MA">Massachusetts</option>

                                                            <option value="MI">Michigan</option>

                                                            <option value="UM-71">Midway Atoll</option>

                                                            <option value="MN">Minnesota</option>

                                                            <option value="MS">Mississippi</option>

                                                            <option value="MO">Missouri</option>

                                                            <option value="MT">Montana</option>

                                                            <option value="UM-76">Navassa Island</option>

                                                            <option value="NE">Nebraska</option>

                                                            <option value="NV">Nevada</option>

                                                            <option value="NH">New Hampshire</option>

                                                            <option value="NJ">New Jersey</option>

                                                            <option value="NM">New Mexico</option>

                                                            <option value="NY">New York</option>

                                                            <option value="NC">North Carolina</option>

                                                            <option value="ND">North Dakota</option>

                                                            <option value="MP">Northern Mariana Islands</option>

                                                            <option value="OH">Ohio</option>

                                                            <option value="OK">Oklahoma</option>

                                                            <option value="OR">Oregon</option>

                                                            <option value="UM-95">Palmyra Atoll</option>

                                                            <option value="PA">Pennsylvania</option>

                                                            <option value="PR">Puerto Rico</option>

                                                            <option value="RI">Rhode Island</option>

                                                            <option value="SC">South Carolina</option>

                                                            <option value="SD">South Dakota</option>

                                                            <option value="TN">Tennessee</option>

                                                            <option value="TX">Texas</option>

                                                            <option value="UM">United States Minor Outlying Islands</option>

                                                            <option value="VI">United States Virgin Islands</option>

                                                            <option value="UT">Utah</option>

                                                            <option value="VT">Vermont</option>

                                                            <option value="VA">Virginia</option>

                                                            <option value="UM-79">Wake Island</option>

                                                            <option value="WA">Washington</option>

                                                            <option value="WV">West Virginia</option>

                                                            <option value="WI">Wisconsin</option>

                                                            <option value="WY">Wyoming</option>

                                                        </Input>
                                                        {validation.touched.cmp_state && validation.errors.cmp_state ? (
                                                            <FormFeedback type="invalid">{validation.errors.cmp_state}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="cmp_country">Country *</Label>
                                                        <Input
                                                            name="cmp_country"
                                                            placeholder="Country"
                                                            type="select"
                                                            className="form-control"
                                                            id="validationCustom01"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.cmp_country || ""}
                                                            invalid={
                                                                validation.touched.cmp_country && validation.errors.cmp_country ? true : false
                                                            }
                                                        >
                                                            <option value="">Choose Country</option>
                                                            <option value="USA" selected="">USA</option>

                                                        </Input>
                                                        {validation.touched.cmp_country && validation.errors.cmp_country ? (
                                                            <FormFeedback type="invalid">{validation.errors.cmp_country}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>


                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">Zipcode *</Label>
                                                        <Input
                                                            name="cmp_zipcode"
                                                            placeholder="Zipcode"
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.cmp_zipcode || ""}
                                                            invalid={
                                                                validation.touched.cmp_zipcode && validation.errors.cmp_zipcode ? true : false
                                                            }
                                                        />
                                                        {validation.touched.cmp_zipcode && validation.errors.cmp_zipcode ? (
                                                            <FormFeedback type="invalid">{validation.errors.cmp_zipcode}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>

                                            </Row>



                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="example-date-input" className="form-Label">Contact Person</Label>
                                                    </div></Col>
                                            </Row>
                                            <FieldArray
                                                name="contactDetails"
                                                render={arrayHelpers => (
                                                    <div>
                                                        {(Object.keys(validation.values.contactDetails).length > 0) && validation.values.contactDetails.map((item, index) => (
                                                            <div key={index + "item"} className="more-item-box">
                                                                <div className="remove-item">
                                                                    <Col sm={1}>
                                                                        {validation.values.contactDetails.length > 1 ? (
                                                                            <>
                                                                                <i className="bx bx-x bx-sm" onClick={() => arrayHelpers.remove(index)} ></i>
                                                                                {/* <i className="bx bx-trash bx-sm" style={{ "cursor": "pointer", "margin-top": "30px" }}></i> */}

                                                                            </>
                                                                        ) : ''}


                                                                    </Col>

                                                                </div>
                                                                <div className="row more-items">
                                                                    <Col md="3">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor={`contactDetails.${index}.contact_first_name`}>Contact First name *</Label>

                                                                            <Field type="text"
                                                                                className="form-control"
                                                                                placeholder="First name"
                                                                                name={`contactDetails.${index}.contact_first_name`} />

                                                                            <ErrorMessage
                                                                                name={`contactDetails.${index}.contact_first_name`}
                                                                                component="div"
                                                                                className="text-danger"
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md="3">

                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor={`contactDetails.${index}.contact_last_name`}>Contact Last name *</Label>

                                                                            <Field type="text"
                                                                                className="form-control"
                                                                                placeholder="Last name"
                                                                                name={`contactDetails.${index}.contact_last_name`} />

                                                                            <ErrorMessage
                                                                                name={`contactDetails.${index}.contact_last_name`}
                                                                                component="div"
                                                                                className="text-danger"
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col md="3">

                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor={`contactDetails.${index}.contact_email`}>Email Address *</Label>

                                                                            <Field type="text"
                                                                                className="form-control"
                                                                                placeholder="Email Address"
                                                                                name={`contactDetails.${index}.contact_email`} />

                                                                            <ErrorMessage
                                                                                name={`contactDetails.${index}.contact_email`}
                                                                                component="div"
                                                                                className="text-danger"
                                                                            />
                                                                        </FormGroup>

                                                                    </Col>

                                                                    <Col md="3">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor={`contactDetails.${index}.contact_phone`}>Phone *</Label>
                                                                            <Field
                                                                                type="text"
                                                                                name={`contactDetails.${index}.contact_phone`}
                                                                                mask="(999)-999-9999"
                                                                                className="form-control"
                                                                                placeholder="Phone"
                                                                            >
                                                                                {/* {(inputProps) => (
                                                                                    // <Input  type="tel" className="" />
                                                                                    <InputMask
                                                                                        {...inputProps}
                                                                                        placeholder="Phone"
                                                                                        mask="(999)-999-9999"
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="validationCustom01"
                                                                                    />
                                                                                )} */}
                                                                            </Field>

                                                                            <ErrorMessage
                                                                                name={`contactDetails.${index}.contact_phone`}
                                                                                component="div"
                                                                                className="text-danger"
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Row className="justify-content-middle">
                                                                        <Col sm={10}></Col>
                                                                        <Col sm={2} style={{ padding: "0 0 16px 0" }}>
                                                                            {validation.values.contactDetails.length === index + 1 ? (
                                                                                <Button
                                                                                    color="primary add-more-button-right" className="ms-1"
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.push({ contact_first_name: '', contact_last_name: '', contact_email: '', contact_phone: '' })}
                                                                                >
                                                                                    Add more
                                                                                </Button>
                                                                            ) : ''}
                                                                        </Col>

                                                                    </Row>
                                                                </div>

                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            />
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="example-date-input" className="form-Label"> Invoice Details</Label>
                                                    </div></Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom01">First name *</Label>
                                                        <Input
                                                            name="inv_first_name"
                                                            placeholder="First name"
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom01"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.inv_first_name || ""}
                                                            invalid={
                                                                validation.touched.inv_first_name && validation.errors.inv_first_name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.inv_first_name && validation.errors.inv_first_name ? (
                                                            <FormFeedback type="invalid">{validation.errors.inv_first_name}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">Last name *</Label>
                                                        <Input
                                                            name="inv_last_name"
                                                            placeholder="Last name"
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.inv_last_name || ""}
                                                            invalid={
                                                                validation.touched.inv_last_name && validation.errors.inv_last_name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.inv_last_name && validation.errors.inv_last_name ? (
                                                            <FormFeedback type="invalid">{validation.errors.inv_last_name}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">Email Address *</Label>
                                                        <Input
                                                            name="inv_email"
                                                            placeholder="Email Address"
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.inv_email || ""}
                                                            invalid={
                                                                validation.touched.inv_email && validation.errors.inv_email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.inv_email && validation.errors.inv_email ? (
                                                            <FormFeedback type="invalid">{validation.errors.inv_email}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>


                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom01">Phone *</Label>
                                                        <InputMask
                                                            name="inv_phone"
                                                            mask="(999)-999-9999"
                                                            className={` ${validation.touched.inv_phone && validation.errors.inv_phone ? 'form-control is-invalid' : 'form-control'}`}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.inv_phone || ""}
                                                            invalid={
                                                                validation.touched.inv_phone && validation.errors.inv_phone ? true : false
                                                            }

                                                        >
                                                            {(inputProps) => (
                                                                // <Input  type="tel" className="" />
                                                                <InputMask
                                                                    {...inputProps}
                                                                    placeholder="Phone"
                                                                    mask="(999)-999-9999"
                                                                    type="text"
                                                                    id="validationCustom01"
                                                                />
                                                            )}
                                                        </InputMask>

                                                        {validation.touched.inv_phone && validation.errors.inv_phone ? (
                                                            <FormFeedback type="invalid">{validation.errors.inv_phone}</FormFeedback>
                                                        ) : null}

                                                        <span className="font-13 text-muted">
                                                            (999) 999-9999
                                                        </span>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="payment_terms">Payment Terms *</Label>
                                                        <Input
                                                            name="payment_terms"
                                                            placeholder="Payment Terms"
                                                            type="select"
                                                            className="form-control"
                                                            id="payment_terms"
                                                            // onChange={validation.handleChange}
                                                            onChange={(e) => {
                                                                validation.handleChange(e);
                                                            }}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.payment_terms || ""}
                                                            invalid={
                                                                validation.touched.payment_terms && validation.errors.payment_terms ? true : false
                                                            }
                                                        >
                                                            <option value="">Select Payment Terms</option>
                                                            <option value="Net 7">Net 7</option>
                                                            <option value="Net 15">Net 15</option>
                                                            <option value="Net 30">Net 30</option>
                                                            <option value="Net 45">Net 45</option>
                                                            <option value="Net 60">Net 60</option>
                                                            <option value="Net 90">Net 90</option>
                                                            <option value="Other">Other</option>
                                                        </Input>
                                                        {validation.touched.payment_terms && validation.errors.payment_terms ? (
                                                            <FormFeedback type="invalid">{validation.errors.payment_terms}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Row style={{ float: "right" }}>
                                                <Col >
                                                    <span className="mt-4">
                                                        <button type="reset" className="btn btn-secondary" color="secondary" onClick={() => navigate(-1)}>
                                                            Cancel
                                                        </button>
                                                    </span>
                                                    &nbsp;&nbsp;
                                                    <span className="mt-4">
                                                        <button type="submit" className="btn btn-primary">
                                                            Save
                                                        </button>
                                                    </span>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </FormikProvider>
                                </CardBody>

                            </Card>
                        </Col>
                    </Container>
                </div>
            </>
        </React.Fragment >
    );
};

export default CreateCustomer;