
export const GET_ATTORNEY_MASTER_DOCS = "GET_ATTORNEY_MASTER_DOCS";
export const GET_ATTORNEY_MASTER_DOCS_SUCCESS =
    "GET_ATTORNEY_MASTER_DOCS_SUCCESS";
export const GET_ATTORNEY_MASTER_DOCS_FAIL = "GET_ATTORNEY_MASTER_DOCS_FAIL";

export const UPDATE_ATTORNEY_MASTER_DOCS = "UPDATE_ATTORNEY_MASTER_DOCS";
export const UPDATE_ATTORNEY_MASTER_DOCS_SUCCESS =
    "UPDATE_ATTORNEY_MASTER_DOCS_SUCCESS";
export const UPDATE_ATTORNEY_MASTER_DOCS_FAIL =
    "UPDATE_ATTORNEY_MASTER_DOCS_FAIL";

export const DELETE_ATTORNEY_MASTER_DOCS = "DELETE_ATTORNEY_MASTER_DOCS";
export const DELETE_ATTORNEY_MASTER_DOCS_SUCCESS =
    "DELETE_ATTORNEY_MASTER_DOCS_SUCCESS";
export const DELETE_ATTORNEY_MASTER_DOCS_FAIL =
    "DELETE_ATTORNEY_MASTER_DOCS_FAIL";
