import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { addCaseFormAttribute, getCaseFormsList } from "../../../store/actions";
getCaseFormsList
//redux
import { useDispatch, useSelector } from "react-redux";
import { caseAttributeUpdate, getCaseFormAttributeDetail } from "../../../store/admin/caseformattributes/actions";
import { MemorizedFormBuilderDataUpdate } from "./dynamicFormBuilder";
import { useAlert } from "../../../components/Common/Alert";

const FormLayoutsBasic = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL
  const [formData, setFormData] = useState({
    "id": editId,
    "content": "",
    "status": ""
  });


  const { CaseFormsList } = useSelector((state) => ({
    CaseFormsList: state.CaseFormsList.CaseFormsList,
  }));
  const { caseformattributeDetail } = useSelector((state) => ({
    caseformattributeDetail: state.CaseFormAttributes.caseformattributeDetail,
  }));

  const [caseformattributeDetailInfo, setCaseformattributeDetailInfo] = useState(caseformattributeDetail);


  useEffect(() => {
    if (caseformattributeDetail && caseformattributeDetail.id) {
      setCaseformattributeDetailInfo(caseformattributeDetail);
    }
  }, [caseformattributeDetail]);

  useEffect(() => {
    dispatch(getCaseFormsList());
  }, [dispatch]);

  useEffect(() => {
    if (editId) {
      dispatch(getCaseFormAttributeDetail(editId));
    }
  }, [editId]);

  const handleSubmit = (data, form_id) => {
    let postBody = {};
    postBody.content = data;
    postBody.form_id = form_id;
    if (postBody.content) {
      if (Number(formData.id) > 0) {
        dispatch(caseAttributeUpdate({ ...postBody, id: formData.id }));
      } else {
        dispatch(addCaseFormAttribute(postBody));
      }
      navigate("/admin/case-form-attributes");
    }
  }



  var newAttributes = {
    className: {
      label: 'Attribute Custom name',
      style: 'border: 1px solid red',
      type: 'text',
      label: 'Show in Group Desc.?'
    },
    class_name: {
      label: 'Class',
      value: 'col-md-4',
    },
    is_any_dependent_yes: {
      label: 'Any dependent?',
      value: false,
      type: 'checkbox',
    },
    dependent_ids_yes: {
      label: 'Dependent ids',
      value: '',
    }
  };
  var newUserEventAttr = {
    onadd: function (fld) {
      var $patternField = $('.fld-dependent_ids_yes', fld);
      $patternField.parent().parent().hide();
      $('.fld-is_any_dependent_yes', fld)
        .change(function (e) {
          $patternField.parent().parent().toggle(e.currentTarget.checked);
        });
    }
  }
  var userAttrs = {};
  var userEventAttrs = {};
  const fields = [
    'text',
    'number',
    'date',
    'radio-group',
    'checkbox-group',
    'select',
    'textarea',
    'file',
  ];
  fields.forEach(function (item, index) {
    if (item === 'select') {
      userAttrs[item] = {
        ...newAttributes, select_field: {
          label: 'Select field',
          options: {
            'default': 'Default',
            'countryus': 'Country US',
            'country': 'Country',
            'state': 'State',
            'dependent': 'Dependent'
          }
        }
      }
    }
    else if (item === 'text') {
      userAttrs[item] = {
        ...newAttributes, select_field_type: {
          label: 'Select field type',
          options: {
            'default': 'Default',
            'label': 'Label',
            'MaskPhone': 'MaskPhone',
            'MaskSSN': 'MaskSSN'
          }
        }
      }
    } else if (item === 'textarea') {
      userAttrs[item] = {
        ...newAttributes, select_field_type: {
          label: 'Select field type',
          options: {
            'default': 'Default',
            'richEditor': 'RichEditor',
          }
        }
      }
    }
    else {
      userAttrs[item] = newAttributes
    }
  });

  fields.forEach(function (item, index) {
    userEventAttrs[item] = newUserEventAttr;
  });

  var options = {
    showActionButtons: false, // defaults: `true`

    disabledAttrs: ["access", 'className'],
    disableFields: ['autocomplete', 'header', 'paragraph', 'button', 'hidden'],
    controlPosition: 'right',
    controlOrder: [
      'text',
      'number',
      'date',
      'radio-group',
      'checkbox-group',
      'select',
      'textarea',
      'file',
    ],
    typeUserAttrs: userAttrs,
    typeUserEvents: userEventAttrs,
    onSave: function (formData) {
    },
  };

  const BreadcrumbData = [
    { name: 'Case Form Attributes', url: '/admin/case-form-attributes' },
    { name: 'Create Case Form Attribute', url: '/admin/add-case-form-attributes' }
  ]
  let title = editId ? 'Update Case Form Attribute' : 'Add Case Form Attribute';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

          <Row>
            <Col xs={12}>
              <Card>

                <CardBody className="p-4">
                  {/* import TextualInputs */}

                  <div className="mt-4 mt-lg-0">
                    <MemorizedFormBuilderDataUpdate
                      getChildFormData={handleSubmit}
                      CaseFormsList={CaseFormsList}
                      caseData={caseformattributeDetailInfo?.content}
                      caseForm_id={caseformattributeDetailInfo?.form_id}
                      caseId={caseformattributeDetailInfo?.id}
                      options={options} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};
export default FormLayoutsBasic
