import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  Label,
  Row
} from "reactstrap";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";


// Form Editor
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { getNote, saveNote } from "../../../../store/actions";

//redux
import { useDispatch, useSelector } from "react-redux";


const Notes = () => {
  //meta title
  document.title = "Form Editors | Immidock ";

  const queryParams = new URLSearchParams(location.search);

  const caseId = queryParams.get('caseId'); // Read the 'id' query parameter from the URL

  var userType = window.localStorage.getItem("user_type")

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [editorDescription, seteditorDescription] = useState(EditorState.createEmpty());

  const onEditorDescriptionStateChange = (editorDescription) => {
    seteditorDescription(editorDescription);
  };
  const { casenote, savenote } = useSelector((state) => ({
    casenote: state.AttorneyCases.casenote || {},
    savenote: state.AttorneyCases.savenote || {},
  }));

  useEffect(() => {
    if (caseId) {
      dispatch(getNote({
        case_id: caseId
      }));
    }
  }, [caseId, savenote]);

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      case_id: caseId,
      description: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      dispatch(saveNote({
        case_id: caseId,
        description: draftToHtml(convertToRaw(editorDescription.getCurrentContent()))
      }))
      seteditorDescription(EditorState.createEmpty())
      navigate(`/${userType}/view-case?caseId=${caseId}`);
    }
  });

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Form className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Col>
            <Row>
              <Col lg="12">
                <Editor
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  editorState={editorDescription}
                  onEditorStateChange={onEditorDescriptionStateChange}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Label
                htmlFor="horizontal-firstname-input"
                className="col-sm-2 col-form-label"
              >
              </Label>
              <Col md={12}>
                <div className="mt-3" style={{ float: "right" }}>
                  <Button type="reset" color="secondary" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                  {" "}
                  <Button type="submit" color="primary" className="ms-1">
                    Save
                  </Button>
                </div>

              </Col>
            </Row>
          </Col>
        </Form>
        <Row>
          <Col lg="12 mt-4">
            {casenote.length > 0 && casenote.map((note) => (
              <>
                <div className="card">
                  <div className="card-body">
                    <p className="text-muted mb-2">{note?.name}, {note?.created_at}</p>
                    <span className="margin-bottom-0" dangerouslySetInnerHTML={{ __html: note?.description }}></span>

                  </div>
                </div>
              </>
            ))}
          </Col>
        </Row>
      </Container>

    </React.Fragment>
  );
};

export default Notes;
