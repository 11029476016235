import React, { useEffect, useMemo, useState } from "react";
import DatatableContainer from "../../../components/Common/DatatableContainer";
import {
  Link, useNavigate
} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

import { getAttorneyCases as getAttorneyCases, deleteAttorneyCase, setAlertFlagInfo } from "../../../store/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  CommonCell, Date, CaseTypeName, Amount
} from "./listCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../Calendar/DeleteModal";
import { deleteEmployerPayroll, getEmployerPayroll, getEmployerPayrolls, refreshEmployeePayroll, refreshEmployerPayroll } from "../../../store/employer/payroll/actions";
import { changePreloader } from "../../../store/actions";
// import { useAlert } from "../../../components/Common/Alert";


const PayrollList = () => {

  //meta title
  document.title = "Payroll List | Immidock ";

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openTab, setopenTab] = useState("Open");
  const [order, setOrder] = useState(null);

  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);



  const { payroll } = useSelector((state) => ({
    payroll: state.employerPayrolls.employerPayrolls || [],
  }));
  useEffect(() => {
    dispatch(getEmployerPayrolls({
      "status": "All"
    }));
    dispatch(changePreloader(true));
    setApiSuccess(true)
  }, [dispatch]);

  useEffect(() => {
    if (apiSuccess) {
      dispatch(changePreloader(false));
      setApiSuccess(false)
    }
  }, [payroll]);


  const [contactList, setContactList] = useState({ "status": "Open" });


  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };
  var userType = window.localStorage.getItem("user_type")

  const handleUserClicks = () => {
    navigate(`/${userType}/create-payroll`);
  };

  const onUpdateData = (data) => {
    navigate(`/${userType}/payroll-employees-view?payrollId=${data.id}`);
  };

  const onDeleteData = (data) => {
    setOrder(data)
    setDeleteModal(true);
  };

  const handleDeleteInvoice = () => {
    if (order.id) {
      dispatch(deleteEmployerPayroll(order.id));
      setDeleteModal(false);
    }
  };

  const onReloadData = (tabInfo) => {
    dispatch(refreshEmployerPayroll({
      "payroll_id": tabInfo.id
    }));

  }
  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" />;
      //   },
      // },
      {
        Header: "Payroll Period",
        accessor: "payroll_period_format",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Date",
        accessor: "created_at",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Created By",
        accessor: "created_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CommonCell {...cellProps} />;
        },
      },
      {
        Header: "Total Beneficiaries",
        accessor: "employees_count",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CaseTypeName {...cellProps} />;
        },
      },
      {
        Header: "Amount",
        accessor: "total_amount",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Amount {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => onUpdateData(cellProps.row.original)}>View Payroll</DropdownItem>
                <DropdownItem onClick={() => onReloadData(cellProps.row.original)}>Refresh Payroll</DropdownItem>
                <DropdownItem onClick={() => onDeleteData(cellProps.row.original)}>Delete Payroll</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  let title = 'Payrolls';
  const BreadcrumbData = [
    { name: 'Dashboard', url: '/attorney/dashboard' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteInvoice}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DatatableContainer
                    columns={columns}
                    data={payroll}
                    isGlobalFilter={true}
                    isAddPayrollList={true}
                    isAdd2ColumnSpace={true}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PayrollList;
