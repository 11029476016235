import React, { useEffect, useRef, useState } from "react";
import {
    Col,
    Form,
    Row
} from "reactstrap";

// import fs from 'fs';
import "react-datepicker/dist/react-datepicker.css";


import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { useLocation, useNavigate } from 'react-router-dom';
import {
    Card, CardBody, CardHeader, Container
} from "reactstrap"
// Formik validation
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
// Form Editor
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
// Form Editor
import { ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//redux
import { useDispatch, useSelector } from "react-redux";


import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import { getPafTemplateVariables, getTemplateDetail, getTemplateSettings, getTemplates, recordPayment as recordPaymentApi, updatePafTemplate } from "../../../store/actions";
//redux


const ViewTemplate = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const templateId = queryParams.get('templateId'); // Read the 'templateId' query parameter from the URL


    const [recordPaymentModal, setRecordPaymentModal] = useState(false)

    const [editorState, seteditorState] = useState({});

    const [activeTab1, setactiveTab1] = useState("template");
    const toggle1 = (tab) => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };

    const moreactions = [{ 'label': 'Record Payment', 'action': 'recordpayment' }, { 'label': 'Export as PDF', 'action': 'pdf' }, { 'label': 'Print PDF', 'action': 'printpdf' }, { 'label': 'Resend Template', 'action': 'saveandsend' }]
    const handleActionSubmit = (e) => {
        validation.handleSubmit();
    };
    const handleRecordPaymentActionSubmit = (e) => {
        record_payment_validation.handleSubmit();
    };
    const onEditorStateChange = (editorState) => {
        seteditorState(editorState)
        validation.values.message = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };
    const onEditorFooterStateChange = (editorFooterDescription) => {
        seteditorFooterDescription(editorFooterDescription)
        validation.values.footer_description = draftToHtml(convertToRaw(editorFooterDescription.getCurrentContent()));
    };
    const {
        recordPaymentDetail, templateDetail, employercontacts, templatesettings, uploadedtemplateattachments, paftemplatevariables } = useSelector((state) => ({
            templateDetail: state.Templates.templateDetail,
            recordPaymentDetail: state.Templates.recordPaymentDetail,
            employercontacts: state.EmployerContacts.employercontacts || [],
            templatesettings: state.Templates.templatesettings || {},
            uploadedtemplateattachments: state.Templates.uploadedtemplateattachments || "",
            paftemplatevariables: state.EmployerCases.paftemplatevariables || {},
        }));

    const pdfRef = useRef(null);

    useEffect(() => {
        dispatch(getTemplateDetail({ id: templateId }));
        dispatch(getTemplates({ 'status': 'All' }));
        dispatch(getPafTemplateVariables({}));
    }, [dispatch]);

    // Form Send Template validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: templateDetail,
        onSubmit: (values) => {
            if (values) {
                dispatch(updatePafTemplate({ id: templateId, is_active: "1", html_content: draftToHtml(convertToRaw(editorHtmlContent.getCurrentContent())) }))
                dispatch(getTemplates({ 'status': 'All' }));
                setTimeout(() => {
                    navigate("/employer/templates", { replace: true });
                }, 1000);
            }
        }
    });

    const [editorHtmlContent, seteditorHtmlContent] = useState(EditorState.createEmpty());

    const onEditorHtmlContentStateChange = (editorHtmlContent) => {
        seteditorHtmlContent(editorHtmlContent)
        validation.values.html_content = draftToHtml(convertToRaw(editorHtmlContent.getCurrentContent()));
    };
    let title = templateId ? 'View Template' : 'Template';
    //meta title
    document.title = title + " | Immidock ";
    const BreadcrumbData = [
        { name: 'PAF Templates', url: '/employer/templates' },
        { name: 'Edit templates', url: '/employer/edit-template?templateId=' + templateId },
        { name: title, url: '#' }
    ]
    return (
        <React.Fragment>
            <>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                        <Col md={12}>
                            <Card>

                                <CardHeader>
                                    <h4 className="card-title">{validation?.values?.form_name}</h4>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <Row>
                                        <Col md={12}>
                                            {(Object.keys(paftemplatevariables).length > 0) && Object.keys(paftemplatevariables).map((templateGroup, index) => (
                                                <div className="col-md-4" key={"templatevariables"+index} style={{"float":"left"}}>
                                                    <div className="mt-3" key={templateGroup}>
                                                        <h4>{templateGroup}</h4>
                                                    </div>
                                                    {Object.keys(paftemplatevariables[templateGroup]).map((variable, index) => (
                                                        <Row className="mt-2" key={templateGroup + variable}>
                                                            <Col>
                                                                <div >
                                                                    <label onClick={() => { navigator.clipboard.writeText("{{" + variable + "}}") }} style={{ "word-break": "break-all" }}>
                                                                        <i className="fa fa-copy cursor-pointer" style={{ color: "#012A69" }} ></i> {"{{"}{variable}{"}}"} - {paftemplatevariables[templateGroup][variable]}
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    ))}
                                                </div>
                                            ))}
                                        </Col>
                                        <Col md={12}>
                                            <FormikProvider value={validation}>
                                                <Form className="needs-validation"
                                                    onSubmit={(values) => {
                                                        values.preventDefault();
                                                        // console.log(validate(values));
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                >

                                                    <div className="mt-4 mt-lg-0">
                                                        <Row>
                                                            <Col md="12">
                                                                <div className="mb-3">
                                                                    {/* <Label htmlFor="example-text-input" className="form-Label">Html Content Description</Label> */}
                                                                    {validation.values.html_content && (
                                                                        <Editor
                                                                            editorStyle={{ height: '30rem' }}
                                                                            toolbarClassName="toolbarClassName"
                                                                            wrapperClassName="wrapperClassName"
                                                                            editorClassName="editorClassName"
                                                                            // editorState={editorHtmlContent}
                                                                            onEditorStateChange={onEditorHtmlContentStateChange}
                                                                            defaultContentState={convertToRaw(ContentState.createFromBlockArray(htmlToDraft(validation.values.html_content)))}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row style={{ float: "right" }}>
                                                            <Col>
                                                                <span className="mt-4">
                                                                    <button type="submit" className="btn btn-primary">
                                                                        Save
                                                                    </button>
                                                                </span>
                                                            </Col>
                                                        </Row>

                                                    </div>

                                                </Form>
                                            </FormikProvider>
                                        </Col>
                                    </Row>
                                </CardBody>

                            </Card>
                        </Col>
                    </Container>
                </div>
            </>
            {/* {(Object.keys(templateDetail).length > 0) && (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0 font-size-18">{title} Template</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a href="javascript: void(0);">Templates</a></li>
                                                <li className="breadcrumb-item active">Template Detail</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div className="col-12">
                                <div className="row">
                                    <Col md="2">
                                        <p className="">Status: {templateDetail.status}</p>
                                    </Col>
                                    <Col md="4">
                                        <p className="">Template Due Date: {(templateDetail.template_due_date != '') && (
                                            <>
                                                {(new Date(templateDetail.template_due_date)).getDay()} {(new Date(templateDetail.template_due_date)).toLocaleString('default', { month: 'short' })}, {(new Date(templateDetail.template_due_date)).getFullYear()}
                                            </>)}</p>
                                    </Col>
                                    <Col md="3">
                                        <p className="">Balance Due: ${templateDetail?.balance_amount}</p>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Input
                                                name="template_action"
                                                placeholder="More Actions"
                                                type="select"
                                                className="form-control"
                                                id="template_action"
                                                onChange={(e) => {
                                                    if (e.target.value == "recordpayment") {
                                                        setRecordPaymentModal(true);
                                                    } else {
                                                        printDocument(e.target.value);
                                                    }
                                                }}
                                            >
                                                <option value="">More Actions</option>
                                                {moreactions.map((moreaction, index) => (
                                                    <option key={moreaction.action + index} value={moreaction.action}>
                                                        {moreaction.label}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                </div>
                                <div className="row" ref={pdfRef}>
                                    <div className="col-lg-12">

                                        <div className="card">
                                            <div className="card-body">
                                                <div className="template-title">
                                                    <div className="d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <div className="mb-4">
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="mb-4">
                                                                <h4 className="font-size-16">Template # {templateDetail.template_number}</h4>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <p className="mb-1">1874 County Line Road City, FL 33566</p>
                                                    <p className="mb-1"><i className="mdi mdi-email align-middle me-1"></i> abc@123.com</p>
                                                    <p><i className="mdi mdi-phone align-middle me-1"></i> 012-345-6789</p>
                                                </div>
                                                <hr className="my-4"></hr>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div>
                                                            <h5 className="font-size-15 mb-3">Billed To:</h5>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div>
                                                            <div>
                                                                <h5 className="font-size-15">Template Date  : </h5>
                                                                {(templateDetail.template_date != '') && (
                                                                    <p>{(new Date(templateDetail.template_date)).getDay()} {(new Date(templateDetail.template_date)).toLocaleString('default', { month: 'short' })}, {(new Date(templateDetail.template_date)).getFullYear()}</p>
                                                                )}
                                                            </div>

                                                            {templateDetail.is_payment_due_enable && (
                                                                <div className="mt-4">
                                                                    <h5 className="font-size-15">Payment Due:</h5>
                                                                    {(templateDetail.template_due_date != '') && (
                                                                        <p>{(new Date(templateDetail.template_due_date)).getDay()} {(new Date(templateDetail.template_due_date)).toLocaleString('default', { month: 'short' })}, {(new Date(templateDetail.template_due_date)).getFullYear()}</p>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {templateDetail.is_template_period_enable && (
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div>
                                                                <div>
                                                                    <h5 className="font-size-15">Template Period : </h5>
                                                                    {(templateDetail.template_from_date != '' && templateDetail.template_to_date != '') && (
                                                                        <p>{(new Date(templateDetail.template_from_date)).getDay()} {(new Date(templateDetail.template_from_date)).toLocaleString('default', { month: 'short' })}, {(new Date(templateDetail.template_from_date)).getFullYear()} - {(new Date(templateDetail.template_to_date)).getDay()} {(new Date(templateDetail.template_to_date)).toLocaleString('default', { month: 'short' })}, {(new Date(templateDetail.template_to_date)).getFullYear()}</p>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {templateDetail.is_candidate_name_enable && (
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div>
                                                                <div>
                                                                    <h5 className="font-size-15">Candidate Name: </h5>
                                                                    <p>{templateDetail.emp_name}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                )}
                                                {templateDetail.is_job_title_enable && (
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div>
                                                                <div>
                                                                    <h5 className="font-size-15">Job Title: </h5>
                                                                    <p>{templateDetail.service_name}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                )}
                                                {templateDetail.is_service_enable && (
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div>
                                                                <div>
                                                                    <h5 className="font-size-15">Service : </h5>
                                                                    <p>{templateDetail.service_name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}



                                                <div className="py-2 mt-3">
                                                    <h5 className="font-size-15">Order summary</h5>
                                                </div>
                                                <div className="p-4 border rounded">
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap align-middle mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "70px" }}>S.No.</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Description</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Hours</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Rate</th>
                                                                    <th className="text-end" style={{ width: "120px" }}>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(Object.keys(templateDetail.template_items).length > 0) && templateDetail.template_items.map((item, index) => (
                                                                    <tr key={index + "itemdisplay"}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>
                                                                            <h5 className="font-size-15 mb-1">{item.description}</h5>
                                                                        </td>
                                                                        <td className="text-end">{item.hours}</td>
                                                                        <td className="text-end">${item.rate}</td>
                                                                        <td className="text-end">{(item.hours && item.rate) ? "$" + parseFloat(item.hours * item.rate).toFixed(2) : ""}</td>
                                                                    </tr>
                                                                ))}


                                                                <tr>
                                                                    <th scope="row" colSpan="4" className="text-end">Sub Total</th>
                                                                    <td className="text-end">
                                                                        ${templateDetail.template_amount}
                                                                    </td>
                                                                </tr>
                                                                {(templateDetail.is_discount_percentage_enable || templateDetail.is_discount_amount_enable) && (
                                                                    <tr>
                                                                        <td scope="row" colSpan="4" className="text-end">Discount {templateDetail.is_discount_percentage_enable ? "(" + templateDetail.discount_percentage + "%)" : ""}</td>
                                                                        < td className="text-end">
                                                                            ${templateDetail.total_discount_amount}
                                                                        </td>
                                                                    </tr>
                                                                )}

                                                                <tr>
                                                                    <th scope="row" colSpan="4" className="border-0 text-end">Total</th>
                                                                    <td className="border-0 text-end"><h4 className="m-0">${templateDetail.total_amount}</h4></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                {templateDetail.is_footer_enable && (
                                                    <div className="py-2 mt-3">
                                                        <h5 className="font-size-15">Notes:</h5>
                                                        <p dangerouslySetInnerHTML={{ __html: templateDetail.footer_description }}></p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <FormikProvider value={record_payment_validation}>
                        <Form className="needs-validation"
                            onSubmit={(values, { validate }) => {
                                values.preventDefault();
                                // console.log(validate(values));
                                record_payment_validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Modal
                                isOpen={recordPaymentModal}
                                toggle={() => {
                                    // tog_varyingmodal('@mdo')
                                }}
                                scrollable={true}
                                id="staticBackdrop"
                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">Record Payment</h5>
                                    <button type="button" className="btn-close"
                                        onClick={() => {
                                            setRecordPaymentModal(false)

                                        }} aria-label="Send Template"></button>
                                </div>
                                <div className="modal-body">


                                    <FieldArray
                                        name="payment_items"
                                        render={arrayHelpers => (
                                            <div>
                                                {record_payment_validation.values.payment_items.map((item, index) => (
                                                    <div key={index + "item"}>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor={`payment_items.${index}.payment_amount`}>Payment Amount</Label>

                                                                <Field type="text"
                                                                    className="form-control"
                                                                    placeholder="Payment Amount"
                                                                    name={`payment_items.${index}.payment_amount`} />

                                                                <ErrorMessage
                                                                    name={`payment_items.${index}.payment_amount`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor={`payment_items.${index}.receive_date`}>Receive Date</Label>

                                                               <ReactDatePicker
                                              showYearDropdown={true}
	showIcon
    placeholderText="MM/dd/yyyy"
    className="form-control"
	id={`payment_items.${index}.receive_date`}
	name={`payment_items.${index}.receive_date`}
	dateFormat="MM/dd/yyyy"
	onChange={(date) =>{
        record_payment_validation.setFieldValue(`payment_items.${index}.receive_date`, date)
	}
	}
	selected={record_payment_validation?.values?.[`payment_items.${index}.receive_date`] ? new Date(record_payment_validation.values[`payment_items.${index}.receive_date`]):''}                       
/>
                                                                <ErrorMessage
                                                                    name={`payment_items.${index}.receive_date`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor={`payment_items.${index}.reference`}>Reference #</Label>
                                                                <Field type="text"
                                                                    className="form-control"
                                                                    placeholder="Reference #"
                                                                    name={`payment_items.${index}.reference`} />

                                                                <ErrorMessage
                                                                    name={`payment_items.${index}.reference`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor={`payment_items.${index}.payment_type`}>Payment Type</Label>
                                                                <Field as="select"
                                                                    className="form-control"
                                                                    placeholder="Payment Type"
                                                                    name={`payment_items.${index}.payment_type`} >
                                                                    <option value="">Select Payment Type</option>
                                                                    <option value="Check">Check</option>
                                                                    <option value="ACH">ACH</option>
                                                                    <option value="DirectDeposit">DirectDeposit</option>
                                                                    <option value="Cash">Cash</option>
                                                                </Field>

                                                                <ErrorMessage
                                                                    name={`payment_items.${index}.payment_type`}
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="12">
                                                            <div className="mb-3">
                                                                <div
                                                                    className="form-check form-switch form-switch-md mb-3"
                                                                    dir="ltr"
                                                                >
                                                                    <Label htmlFor={`payment_items.${index}.is_discount_amount_enable`}>Discount</Label>
                                                                    <Field type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizelg"
                                                                        name={`payment_items.${index}.is_discount_amount_enable`} />

                                                                    <ErrorMessage
                                                                        name={`payment_items.${index}.is_discount_amount_enable`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />

                                                                </div>
                                                            </div>

                                                            {record_payment_validation.values.payment_items[index].is_discount_amount_enable && (
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor={`payment_items.${index}.discount_amount`}>Discount Amount</Label>
                                                                    <Field type="text"
                                                                        className="form-control"
                                                                        placeholder="Discount Amount"
                                                                        name={`payment_items.${index}.discount_amount`} />

                                                                    <ErrorMessage
                                                                        name={`payment_items.${index}.discount_amount`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />
                                                                </FormGroup>
                                                            )}
                                                        </Col>

                                                        {record_payment_validation.values.payment_items.length > 1 ? (
                                                            <>
                                                                <Col md="12 text-end">
                                                                    <button className="mb-3" type="button" onClick={() => arrayHelpers.remove(index)}>
                                                                        - Remove Item
                                                                    </button>
                                                                </Col>
                                                            </>
                                                        ) : ''}
                                                    </div>
                                                ))}

                                                <Row className="justify-content-middle">
                                                    <Col md="8"></Col>
                                                    <Col md="4">
                                                        <button
                                                            className="mb-3"
                                                            type="button"
                                                            onClick={() => arrayHelpers.push({ name: '', age: '' })}
                                                        >
                                                            + Add Payment
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    />


                                    {validation.values.is_discount_amount_enable && (
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="discount_amount">Discount Amount</Label>
                                            <Input
                                                name="discount_amount"
                                                placeholder="Discount Amount"
                                                type="text"
                                                className="form-control"
                                                id="discount_amount"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.discount_amount || 0}
                                                invalid={
                                                    validation.touched.discount_amount && validation.errors.discount_amount ? true : false
                                                }
                                            />
                                            {validation.touched.discount_amount && validation.errors.discount_amount ? (
                                                <FormFeedback type="invalid">{validation.errors.discount_amount}</FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    )}

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={(e) => {
                                        handleRecordPaymentActionSubmit(e);
                                    }}>Record Payment</button>

                                </div>
                            </Modal>
                        </Form>
                    </FormikProvider>

                </>
            )} */}
        </React.Fragment >
    );
};

export default ViewTemplate;