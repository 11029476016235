import {
  DELETE_ATTORNEY_MASTER_DOCS_FAIL,
  DELETE_ATTORNEY_MASTER_DOCS_SUCCESS,
  GET_ATTORNEY_MASTER_DOCS_FAIL,
  GET_ATTORNEY_MASTER_DOCS_SUCCESS,
  UPDATE_ATTORNEY_MASTER_DOCS_FAIL,
  UPDATE_ATTORNEY_MASTER_DOCS_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  getMasterDocs: [],
  error: {},
};

const AttorneyMasterDocs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ATTORNEY_MASTER_DOCS_SUCCESS:
      return {
        ...state,
        getMasterDocs: action.payload,
      };

    case GET_ATTORNEY_MASTER_DOCS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_ATTORNEY_MASTER_DOCS_SUCCESS:
      return {
        ...state,
        getMasterDocs: action.payload,
      };

    case UPDATE_ATTORNEY_MASTER_DOCS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_ATTORNEY_MASTER_DOCS_SUCCESS:
      return {
        ...state,
        getMasterDocs: action.payload,
      };

    case DELETE_ATTORNEY_MASTER_DOCS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default AttorneyMasterDocs;
