import React, { useEffect } from 'react';

import ReactApexChart from "react-apexcharts"

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";

import CountUp from "react-countup";
import { useDispatch } from 'react-redux';

/** import Mini Widget data */
import { getCaseFormAttributes, getCaseFormAttributeTemplates,getCaseFormAttributeGroups, getcaseformAttributesets, getCasetypes, getQnnrtypes} from "../../store/actions"
import { getAttributes as onGetAttributes, getAttributesets as onGetAttributesets, getAttributegroups as onGetAttributegroups, getAttributetemplates as onGetAttributeTemplates } from "../../store/actions";

const options = {
    chart: {
        height: 50,
        type: "line",
        toolbar: { show: false },
    },
    colors: ["#5156be"],
    stroke: {
        curve: 'smooth',
        width: 2,
    },
    xaxis: {
        labels: {
            show: false
        },
        axisTicks: {
            show: false
        },
        axisBorder: {
            show: false
        }
    },
    yaxis: {
        labels: {
            show: false
        }
    },
    tooltip: {
        fixed: {
            enabled: false
        },
        x: {
            show: false
        },
        y: {
            title: {
                formatter: function (seriesName) {
                    return ''
                }
            }
        },
        marker: {
            show: false
        }
    }
};
 

const Dashboard = () => {

     const dispatch = useDispatch();


  useEffect(() => {
    dispatch(onGetAttributes());
    dispatch(onGetAttributesets());
    dispatch(onGetAttributegroups());
    dispatch(onGetAttributeTemplates());
    dispatch(getCaseFormAttributes());
    dispatch(getcaseformAttributesets());
    dispatch(getCaseFormAttributeGroups());
    dispatch(getCaseFormAttributeTemplates());
    dispatch(getCasetypes());
    dispatch(getQnnrtypes());    

  }, []);
    //meta title
    document.title="Dashboard | Immidock ";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

                    <Row>
                    <Card className="card-h-100">
                                    <CardBody>
                                        <Row className="align-items-center">
                                            <Col xs={6}>
                                                <span className="text-muted mb-3 lh-1 d-block text-truncate">Wellcome to Dashboard</span>
                                               
                                            </Col>
                                        </Row>
                                     
                                    </CardBody>
                                </Card>
                    </Row>
                   {/* <Row>
                        <WalletBalance />
                        <Col>
                            <Row>
                                <InvestedOverview />
                                <NewSlider />
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <MarketOverview />
                        <Locations />
                    </Row>
                    <Row>
                        <Trading />
                        <Transactions />
                        <RecentActivity />
                        </Row> */}
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;