import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_EMPLOYERTIMESHEETS, GET_EMPLOYERTIMESHEETDETAIL,
  UPDATE_EMPLOYERTIMESHEETSTATUS,
  DELETE_TIMESHEET
} from "./actionTypes"
import {

  getEmployerTimesheetsSuccess,
  getEmployerTimesheetsFail,
  getEmployerTimesheetDetailSuccess,
  getEmployerTimesheetDetailFail,
  updateEmployerTimesheetStatusSuccess,
  updateEmployerTimesheetStatusFail,
  deleteTimesheetSuccess,
  deleteTimesheetFail

} from "./actions"
import { changePreloader } from "../../../store/actions"

//Include Both Helper File with needed methods
import {
  deleteTimesheetApiHelper, getEmployerTimesheetApiHelper, getEmployerTimesheetDetailApiHelper,
  updateEmployerViewTimesheet
} from "../../../helpers/apibackend_helper"
import { setAlertMessage } from "../../common/actions"

function* fetchTimesheetDetail({ timesheetId }) {
  try {
    const response = yield call(getEmployerTimesheetDetailApiHelper, timesheetId)
    yield put(getEmployerTimesheetDetailSuccess(response.data))
  } catch (error) {
    yield put(getEmployerTimesheetDetailFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}


function* deleteTimesheetDetail({ payload: timesheetId }) {
  try {
    const response = yield call(deleteTimesheetApiHelper, timesheetId)
    yield put(deleteTimesheetSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(deleteTimesheetFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* updateViewTimesheetStatus({ payload: timesheetStatusData }) {
  try {
    const response = yield call(updateEmployerViewTimesheet, timesheetStatusData)
    yield put(updateEmployerTimesheetStatusSuccess(response.data))
    yield put(setAlertMessage({ message: response, type: "success" }));
  } catch (error) {
    yield put(updateEmployerTimesheetStatusFail(error))
    yield put(setAlertMessage({ message: error, type: "danger" }))
  } finally {
    yield put(changePreloader(false));
  }
}

function* getEmployerTimesheets({ payload: timesheetData }) {
  try {
    const response = yield call(getEmployerTimesheetApiHelper, timesheetData)
    yield put(getEmployerTimesheetsSuccess(response.data))
  } catch (error) {
    yield put(getEmployerTimesheetsFail(error))
  } finally {
    yield put(changePreloader(false));
  }
}

function* employerTimesheetSaga() {
  // yield takeEvery(GET_INVOICES, fetchInvoices)
  yield takeEvery(GET_EMPLOYERTIMESHEETDETAIL, fetchTimesheetDetail)
  yield takeEvery(DELETE_TIMESHEET, deleteTimesheetDetail)
  // yield takeEvery(ADD_INVOICE_DETAIL, addInvoiceDetail)
  // yield takeEvery(GET_INVOICE_SETTINGS, fetchInvoiceSettings)
  yield takeEvery(GET_EMPLOYERTIMESHEETS, getEmployerTimesheets)
  yield takeEvery(UPDATE_EMPLOYERTIMESHEETSTATUS, updateViewTimesheetStatus)
  // yield takeEvery(UPDATE_INVOICE_DETAIL, onUpdateinvoice)
}

export default employerTimesheetSaga
