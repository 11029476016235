import React, { useEffect, useState } from "react";
import {
    Card, CardBody, CardHeader, Col, Container, Row, Form, Label, Input,
    FormGroup, FormFeedback,
} from "reactstrap"

import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Formik validation
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { getProfile, updateLcaEmployeeAccess, updateQuestionaireReview, updateNotificationEmails } from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";

const Settings = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId'); // Read the 'id' query parameter from the URL

    const [initialValues, setInitialValues] = useState(false);


    const { profile, changepassword } = useSelector((state) => ({
        profile: state.Profile.profile || {},
        changepassword: state.Profile.changepassword || {},
    }));

    const [lcaAccess, setLcaAccess] = useState(false);
    const [isReviewQuestionnaire, setIsReviewQuestionnaire] = useState(false);


    useEffect(() => {
        dispatch(getProfile());
    }, [userId, dispatch]);

    useEffect(() => {
        if (!_.isEmpty(profile)) {
            setLcaAccess(profile?.lca_access == 0 ? false : true)
            setIsReviewQuestionnaire(profile?.is_review_questionnaire == 0 ? false : true)
        }
    }, [profile]);


    const iniValues = {
        alert_email: "",
        alert_timesheet_email: "",
        alert_invoice_email: "",
    };
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: (userId && (Object.keys(userDetail).length > 0)) ? userDetail : iniValues,

        validationSchema: Yup.object({
            alert_email: Yup.string().email('Enter value must be a valid email').required("Please Enter Notification email for immigration cases").nullable(),
            alert_timesheet_email: Yup.string().email('Enter value must be a valid email').required("Please Enter Notification email for timesheets").nullable(),
            alert_invoice_email: Yup.string().email('Enter value must be a valid email').required("Please Enter Email address").nullable(),
        }),
        onSubmit: (values) => {
            dispatch(updateNotificationEmails(values));
        }
    });


    let title = 'Settings';
    //meta title
    document.title = title + " | Immidock ";
    var userType = window.localStorage.getItem("user_type")
    const BreadcrumbData = [
        { name: 'Dashboard', url: '/' + userType + '/dashboard' },
        { name: title, url: '#' }
    ]
    return (
        <React.Fragment>
            <>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                        <Col xs={12}>
                            <Card>

                                <CardHeader>
                                    <h4 className="card-title">{title}</h4>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <Row>
                                        <Col md="4">
                                            <FormGroup className="mb-3">
                                                <div className="mb-3">
                                                    <div
                                                        className="form-check form-switch form-switch-md mb-3"
                                                        dir="ltr"
                                                    >
                                                        <input
                                                            name="lcaAccess"
                                                            onChange={(e) => {
                                                                dispatch(updateLcaEmployeeAccess({ lca_access: !lcaAccess }))
                                                                setLcaAccess(!lcaAccess);
                                                            }}
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customSwitchsizelg"
                                                            checked={lcaAccess}
                                                        />
                                                        <span className="font-size-16">LCA Employee Access</span>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup className="mb-3">
                                                <div className="mb-3">
                                                    <div
                                                        className="form-check form-switch form-switch-md mb-3"
                                                        dir="ltr"
                                                    >
                                                        <input
                                                            name="isReviewQuestionnaire"
                                                            onChange={(e) => {
                                                                dispatch(updateQuestionaireReview({ is_review_questionnaire: !isReviewQuestionnaire }))
                                                                setIsReviewQuestionnaire(!isReviewQuestionnaire);
                                                            }}
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customSwitchsizelg"
                                                            checked={isReviewQuestionnaire}
                                                        />
                                                        <span className="font-size-16">Review Questionnaire</span>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormikProvider value={validation}>
                                        <Form className="needs-validation"
                                            onSubmit={(values) => {
                                                values.preventDefault();
                                                // console.log(validate(values));
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="alert_email">Notification email for immigration cases*</Label>
                                                        <Input
                                                            name="alert_email"
                                                            placeholder="Notification email for immigration cases"
                                                            type="text"
                                                            className="form-control"
                                                            id="alert_email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.alert_email || ""}
                                                            invalid={
                                                                validation.touched.alert_email && validation.errors.alert_email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.alert_email && validation.errors.alert_email ? (
                                                            <FormFeedback type="invalid">{validation.errors.alert_email}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="alert_timesheet_email">Notification email for timesheets *</Label>
                                                        <Input
                                                            name="alert_timesheet_email"
                                                            placeholder="Notification email for timesheets"
                                                            type="text"
                                                            className="form-control"
                                                            id="alert_timesheet_email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.alert_timesheet_email || ""}
                                                            invalid={
                                                                validation.touched.alert_timesheet_email && validation.errors.alert_timesheet_email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.alert_timesheet_email && validation.errors.alert_timesheet_email ? (
                                                            <FormFeedback type="invalid">{validation.errors.alert_timesheet_email}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="alert_invoice_email">Notification email for invoices *</Label>
                                                        <Input
                                                            name="alert_invoice_email"
                                                            placeholder="Notification email for invoices *"
                                                            type="text"
                                                            className="form-control"
                                                            id="alert_invoice_email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.alert_invoice_email || ""}
                                                            invalid={
                                                                validation.touched.alert_invoice_email && validation.errors.alert_invoice_email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.alert_invoice_email && validation.errors.alert_invoice_email ? (
                                                            <FormFeedback type="invalid">{validation.errors.alert_invoice_email}</FormFeedback>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>


                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <Row style={{ float: "right" }}>
                                                        <Col >
                                                            <span className="mt-4">
                                                                <button type="reset" className="btn btn-secondary" color="secondary" onClick={() => navigate(-1)}>
                                                                    Cancel
                                                                </button>
                                                            </span>
                                                            &nbsp;&nbsp;
                                                            <span className="mt-4">
                                                                <button type="submit" className="btn btn-primary">
                                                                    Save
                                                                </button>
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </FormikProvider>

                                </CardBody>

                            </Card>
                        </Col>
                    </Container>
                </div>
            </>
        </React.Fragment >
    );
};

export default Settings;