import PropTypes from 'prop-types'
import React from "react"
import { Col, Modal, ModalBody, Row, Button, ModalHeader } from "reactstrap"

const DeleteModal = ({ show, onDeleteClick, onCloseClick, message, status}) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader>
        <h4>Confirmation Required</h4>
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <h3> <i
                className="mdi mdi-alert-circle-outline"
                style={{color: "orange" }}
              />Are you sure?</h3>
              <p>{message ? message : "You won't be able to revert this!"}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">

              <Button type="reset" onClick={onCloseClick} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary" onClick={onDeleteClick} className="ms-1">
              {status ? status : "Confirm"}
              </Button>

            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default DeleteModal
