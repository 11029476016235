/* INVOICES */
export const GET_INVOICES = "GET_INVOICES"
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS"
export const GET_INVOICES_FAIL = "GET_INVOICES_FAIL"

export const GET_INVOICE_DETAIL = "GET_INVOICE_DETAIL"
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS"
export const GET_INVOICE_DETAIL_FAIL = "GET_INVOICE_DETAIL_FAIL"

export const ADD_INVOICE_DETAIL = "ADD_INVOICE_DETAIL"
export const ADD_INVOICE_DETAIL_SUCCESS = "ADD_INVOICE_DETAIL_SUCCESS"
export const ADD_INVOICE_DETAIL_FAIL = "ADD_INVOICE_DETAIL_FAIL"

export const SEND_INVOICE = "SEND_INVOICE"
export const SEND_INVOICE_SUCCESS = "SEND_INVOICE_SUCCESS"
export const SEND_INVOICE_FAIL = "SEND_INVOICE_FAIL"


export const DUPLICATE_INVOICE = "DUPLICATE_INVOICE"
export const DUPLICATE_INVOICE_SUCCESS = "DUPLICATE_INVOICE_SUCCESS"
export const DUPLICATE_INVOICE_FAIL = "DUPLICATE_INVOICE_FAIL"

export const UPDATE_INVOICE_PDF = "UPDATE_INVOICE_PDF"
export const UPDATE_INVOICE_PDF_SUCCESS = "UPDATE_INVOICE_PDF_SUCCESS"
export const UPDATE_INVOICE_PDF_FAIL = "UPDATE_INVOICE_PDF_FAIL"

export const RECORD_PAYMENT = "RECORD_PAYMENT"
export const RECORD_PAYMENT_SUCCESS = "RECORD_PAYMENT_SUCCESS"
export const RECORD_PAYMENT_FAIL = "RECORD_PAYMENT_FAIL"

export const GET_QUICKBOOK_REFRESH = "GET_QUICKBOOK_REFRESH"
export const GET_QUICKBOOK_REFRESH_SUCCESS = "GET_QUICKBOOK_REFRESH_SUCCESS"
export const GET_QUICKBOOK_REFRESH_FAIL = "GET_QUICKBOOK_REFRESH_FAIL"

export const GET_QUICKBOOK_STATUS = "GET_QUICKBOOK_STATUS"
export const GET_QUICKBOOK_STATUS_SUCCESS = "GET_QUICKBOOK_STATUS_SUCCESS"
export const GET_QUICKBOOK_STATUS_FAIL = "GET_QUICKBOOK_STATUS_FAIL"

export const GET_QUICKBOOK_SYNC_INVOICE = "GET_QUICKBOOK_SYNC_INVOICE"
export const GET_QUICKBOOK_SYNC_INVOICE_SUCCESS = "GET_QUICKBOOK_SYNC_INVOICE_SUCCESS"
export const GET_QUICKBOOK_SYNC_INVOICE_FAIL = "GET_QUICKBOOK_SYNC_INVOICE_FAIL"

export const GET_QUICKBOOK_CALLBACK = "GET_QUICKBOOK_CALLBACK"
export const GET_QUICKBOOK_CALLBACK_SUCCESS = "GET_QUICKBOOK_CALLBACK_SUCCESS"
export const GET_QUICKBOOK_CALLBACK_FAIL = "GET_QUICKBOOK_CALLBACK_FAIL"

export const GET_CREATE_INVOICE_SETTINGS = "GET_CREATE_INVOICE_SETTINGS"
export const GET_CREATE_INVOICE_SETTINGS_SUCCESS = "GET_CREATE_INVOICE_SETTINGS_SUCCESS"
export const GET_CREATE_INVOICE_SETTINGS_FAIL = "GET_CREATE_INVOICE_SETTINGS_FAIL"

export const GET_INVOICE_SETTINGS = "GET_INVOICE_SETTINGS"
export const GET_INVOICE_SETTINGS_SUCCESS = "GET_INVOICE_SETTINGS_SUCCESS"
export const GET_INVOICE_SETTINGS_FAIL = "GET_INVOICE_SETTINGS_FAIL"

export const UPDATE_INVOICE_SETTINGS = "UPDATE_INVOICE_SETTINGS"
export const UPDATE_INVOICE_SETTINGS_SUCCESS = "UPDATE_INVOICE_SETTINGS_SUCCESS"
export const UPDATE_INVOICE_SETTINGS_FAIL = "UPDATE_INVOICE_SETTINGS_FAIL"

export const CONNECT_QUICKBOOKS = "CONNECT_QUICKBOOKS"
export const CONNECT_QUICKBOOKS_SUCCESS = "CONNECT_QUICKBOOKS_SUCCESS"
export const CONNECT_QUICKBOOKS_FAIL = "CONNECT_QUICKBOOKS_FAIL"

export const UPLOAD_INVOICE_ATTACHMENT = "UPLOAD_INVOICE_ATTACHMENT"
export const UPLOAD_INVOICE_ATTACHMENT_SUCCESS = "UPLOAD_INVOICE_ATTACHMENT_SUCCESS"
export const UPLOAD_INVOICE_ATTACHMENT_FAIL = "UPLOAD_INVOICE_ATTACHMENT_FAIL"

export const DELETE_INVOICE_DETAIL = "DELETE_INVOICE_DETAIL"
export const DELETE_INVOICE_DETAIL_SUCCESS = "DELETE_INVOICE_DETAIL_SUCCESS"
export const DELETE_INVOICE_DETAIL_FAIL = "DELETE_INVOICE_DETAIL_FAIL"

export const UPDATE_INVOICE_DETAIL = "UPDATE_INVOICE_DETAIL"
export const UPDATE_INVOICE_DETAIL_SUCCESS = "UPDATE_INVOICE_DETAIL_SUCCESS"
export const UPDATE_INVOICE_DETAIL_FAIL = "UPDATE_INVOICE_DETAIL_FAIL"

export const GET_INVOICE_TIMESHEET_DETAIL = "GET_INVOICE_TIMESHEET_DETAIL"
export const GET_INVOICE_TIMESHEET_DETAIL_SUCCESS = "GET_INVOICE_TIMESHEET_DETAIL_SUCCESS"
export const GET_INVOICE_TIMESHEET_DETAIL_FAIL = "GET_INVOICE_TIMESHEET_DETAIL_FAIL"
