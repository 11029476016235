import React from "react";

import EmployerInvoiceSettings from "../../Invoices/InvoiceSettings";
import EmployerBasicSuite from "../../Basic";

const MainInvoiceSettingsComponent = () => {

const getLoggedInPlanType = () => {
    return localStorage.getItem("plan_type");
  }

  if(getLoggedInPlanType()==1) {
    return <EmployerInvoiceSettings/>
  } else {
    return <EmployerBasicSuite componentName="Invoice Settings"/>
  }
};

export default MainInvoiceSettingsComponent;
