/* QNNRTYPES */
export const GET_ATTORNEY_COMPANY = "GET_ATTORNEY_COMPANY"
export const GET_ATTORNEY_COMPANY_SUCCESS = "GET_ATTORNEY_COMPANY_SUCCESS"
export const GET_ATTORNEY_COMPANY_FAIL = "GET_ATTORNEY_COMPANY_FAIL"

export const GET_ATTORNEY_COMPANY_DETAIL = "GET_ATTORNEY_COMPANY_DETAIL"
export const GET_ATTORNEY_COMPANY_DETAIL_SUCCESS = "GET_ATTORNEY_COMPANY_DETAIL_SUCCESS"
export const GET_ATTORNEY_COMPANY_DETAIL_FAIL = "GET_ATTORNEY_COMPANY_DETAIL_FAIL"

export const ADD_ATTORNEY_COMPANY_DETAIL = "ADD_ATTORNEY_COMPANY_DETAIL"
export const ADD_ATTORNEY_COMPANY_DETAIL_SUCCESS = "ADD_ATTORNEY_COMPANY_DETAIL_SUCCESS"
export const ADD_ATTORNEY_COMPANY_DETAIL_FAIL = "ADD_ATTORNEY_COMPANY_DETAIL_FAIL"

export const DELETE_ATTORNEY_COMPANY_DETAIL = "DELETE_ATTORNEY_COMPANY_DETAIL"
export const DELETE_ATTORNEY_COMPANY_DETAIL_SUCCESS = "DELETE_ATTORNEY_COMPANY_DETAIL_SUCCESS"
export const DELETE_ATTORNEY_COMPANY_DETAIL_FAIL = "DELETE_ATTORNEY_COMPANY_DETAIL_FAIL"

export const UPDATE_ATTORNEY_COMPANY_DETAIL = "UPDATE_ATTORNEY_COMPANY_DETAIL"
export const UPDATE_ATTORNEY_COMPANY_DETAIL_SUCCESS = "UPDATE_ATTORNEY_COMPANY_DETAIL_SUCCESS"
export const UPDATE_ATTORNEY_COMPANY_DETAIL_FAIL = "UPDATE_ATTORNEY_COMPANY_DETAIL_FAIL"

export const GET_ATTORNEY_ELIGIBILITY_STATUS = "GET_ATTORNEY_ELIGIBILITY_STATUS"
export const GET_ATTORNEY_ELIGIBILITY_STATUS_SUCCESS = "GET_ATTORNEY_ELIGIBILITY_STATUS_SUCCESS"
export const GET_ATTORNEY_ELIGIBILITY_STATUS_FAIL = "GET_ATTORNEY_ELIGIBILITY_STATUS_FAIL"

