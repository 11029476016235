// fake data generator
let attributeList=[{"id":1,"key":"First Name",},{"id":2,"key":"Last Name",},{"id":3,"key":"Middle Name",},{"id":4,"key":"Passport Number",},{"id":5,"key":"Country of Issuance",},{"id":6,"key":"Passport Issue Date",},{"id":7,"key":"Passport Expiration Date",},{"id":8,"key":"Street Number and Name",},{"id":9,"key":"Number",},{"id":10,"key":"City or Town",},{"id":11,"key":"State",},{"id":12,"key":"Country",},{"id":13,"key":"ZipCode",},{"id":14,"key":"Name of College/University",},{"id":15,"key":"Major/Primary Field Of Study",},{"id":16,"key":"Date of Degree Awarded",},{"id":17,"key":"Type of Degree",}];
const getItems = (count, offset = 0) =>
attributeList.slice(0,10).map(k => ({
    id: `${k.id}`,
    content: `${k.key}`
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
* Moves an item from one list to another list.
*/
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? '#00b5a4' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 500,
  height: 400,
  overflowY: 'auto',
  overflowX: 'hidden'
});

const getFirstListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'white' : 'white',
  padding: grid,
  width: 500,
  minHeight: 300
});


export {
  getItems,
  reorder,
  move,
  getItemStyle,
  getListStyle,
  getFirstListStyle
};