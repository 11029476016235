import { GET_CASEFORMATTRIBUTEGROUPS_SUCCESS,
         GET_CASEFORMATTRIBUTEGROUPS_FAIL,
         ADD_CASEFORMATTRIBUTEGROUP_SUCCESS,
         ADD_CASEFORMATTRIBUTEGROUP_FAIL, 
         GET_CASEFORMATTRGROUP_DETAIL_SUCCESS,
         GET_CASEFORMATTRGROUP_DETAIL_FAIL,
         UPDATE_CASEFORMATTRIBUTEGROUP_SUCCESS,
         UPDATE_CASEFORMATTRIBUTEGROUP_FAIL,
         DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL_SUCCESS,
         DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL_FAIL} from "./actionTypes"

const INIT_STATE = {
  caseformattributegroups: [],
  caseformattributesetDetail: {},
  error: {},
}

const Caseformattributetemplates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CASEFORMATTRIBUTEGROUPS_SUCCESS:
      return {
        ...state,
        caseformattributegroups: action.payload,
      }

    case GET_CASEFORMATTRIBUTEGROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    
    case ADD_CASEFORMATTRIBUTEGROUP_SUCCESS:
      return {
        ...state,
        caseformattributegroups: action.payload,
      }

    case ADD_CASEFORMATTRIBUTEGROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      case GET_CASEFORMATTRGROUP_DETAIL_SUCCESS:
        return {
          ...state,
          caseformattributeGroupDetail: action.payload,
        }
  
      case GET_CASEFORMATTRGROUP_DETAIL_FAIL:
        return {
          ...state,
          error: action.payload,
        }
        case UPDATE_CASEFORMATTRIBUTEGROUP_SUCCESS:
        return {
          ...state,
          caseformattributegroups:action.payload
        }
  
      case UPDATE_CASEFORMATTRIBUTEGROUP_FAIL:
        return {
          ...state,
          error: action.payload,
        }
        case DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL_SUCCESS:
        return {
          ...state,
          caseformattributegroups: state.caseformattributegroups.filter((item) => item.id !== action.payload)
        };
  
      case DELETE_CASEFORM_ATTRIBUTEGROUP_DETAIL_FAIL:
        return {
          ...state,
          error: action.payload,
        };
    default:
      return state
  }
}

export default Caseformattributetemplates
