/* QNNRTYPES */
export const GET_ATTRIBUTES = "GET_ATTRIBUTES"
export const GET_ATTRIBUTES_SUCCESS = "GET_ATTRIBUTES_SUCCESS"
export const GET_ATTRIBUTES_FAIL = "GET_ATTRIBUTES_FAIL"

export const GET_ATTRIBUTE_DETAIL = "GET_ATTRIBUTE_DETAIL"
export const GET_ATTRIBUTE_DETAIL_SUCCESS = "GET_ATTRIBUTE_DETAIL_SUCCESS"
export const GET_ATTRIBUTE_DETAIL_FAIL = "GET_ATTRIBUTE_DETAIL_FAIL"

export const ADD_ATTRIBUTE_DETAIL = "ADD_ATTRIBUTE_DETAIL"
export const ADD_ATTRIBUTE_DETAIL_SUCCESS = "ADD_ATTRIBUTE_DETAIL_SUCCESS"
export const ADD_ATTRIBUTE_DETAIL_FAIL = "ADD_ATTRIBUTE_DETAIL_FAIL"

export const DELETE_ATTRIBUTE_DETAIL = "DELETE_ATTRIBUTE_DETAIL"
export const DELETE_ATTRIBUTE_DETAIL_SUCCESS = "DELETE_ATTRIBUTE_DETAIL_SUCCESS"
export const DELETE_ATTRIBUTE_DETAIL_FAIL = "DELETE_ATTRIBUTE_DETAIL_FAIL"

export const UPDATE_ATTRIBUTE_DETAIL = "UPDATE_ATTRIBUTE_DETAIL"
export const UPDATE_ATTRIBUTE_DETAIL_SUCCESS = "UPDATE_ATTRIBUTE_DETAIL_SUCCESS"
export const UPDATE_ATTRIBUTE_DETAIL_FAIL = "UPDATE_ATTRIBUTE_DETAIL_FAIL"
