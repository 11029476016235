import {
  ADD_TEMPLATE_DETAIL_FAIL,
  ADD_TEMPLATE_DETAIL_SUCCESS,
  DELETE_TEMPLATE_DETAIL_FAIL,
  DELETE_TEMPLATE_DETAIL_SUCCESS,
  GET_TEMPLATES_FAIL,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATE_DETAIL_FAIL,
  GET_TEMPLATE_DETAIL_SUCCESS,
  GET_TEMPLATE_SETTINGS_FAIL,
  GET_TEMPLATE_SETTINGS_SUCCESS,
  UPDATE_TEMPLATE_DETAIL_FAIL,
  UPDATE_TEMPLATE_DETAIL_SUCCESS,
  UPDATE_TEMPLATE_PDF_FAIL,
  UPDATE_TEMPLATE_PDF_SUCCESS,
  UPLOAD_TEMPLATE_ATTACHMENT_FAIL,
  UPLOAD_TEMPLATE_ATTACHMENT_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  templates: [],
  templateDetail: {},
  createTemplateDetail: {},
  templatesettings: {},
  uploadedtemplateattachments: {},
  updatetemplatepdf: {},
  error: {},
}

const Templates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TEMPLATE_SETTINGS_SUCCESS:
      return {
        ...state,
        templatesettings: action.payload,
      }

    case GET_TEMPLATE_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.payload,
      }

    case GET_TEMPLATES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_TEMPLATE_DETAIL_SUCCESS:
      return {
        ...state,
        templateDetail: action.payload,
      }

    case GET_TEMPLATE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_TEMPLATE_DETAIL_SUCCESS:
      return {
        ...state,
        createTemplateDetail: action.payload,
      }

    case ADD_TEMPLATE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
   
    case UPDATE_TEMPLATE_PDF_SUCCESS:
      return {
        ...state,
        updatetemplatepdf: action.payload,
      }

    case UPDATE_TEMPLATE_PDF_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPLOAD_TEMPLATE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        updatetemplatepdf: action.payload,
      }

    case UPLOAD_TEMPLATE_ATTACHMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_TEMPLATE_DETAIL_SUCCESS:
      return {
        ...state,
        templates: state.Templates.filter((item) => item.id !== action.payload),
      };

    case DELETE_TEMPLATE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_TEMPLATE_DETAIL_SUCCESS:
      return {
        ...state,
        templates: state.Templates.map((order) =>
          order.id.toString() === action.payload.id.toString()
            ? { order, ...action.payload }
            : order
        ),
      };

    case UPDATE_TEMPLATE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state
  }
}

export default Templates
