import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { setAlertMessage } from "../../../store/common/actions";

// Formik validation


import { EmployerCaseUpdate, addEmployerCaseDetail, getEmployerCaseDetail, getEmployerCaseTypes, getEmployerCases, getEmployerContacts, getEmployerQnnrtypes } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";

// import { useAlert } from "../../../components/Common/Alert";


// Formik validation
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";


const FormLayoutsBasic = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL

  const [OptionGroup, setOptionGroup] = useState([]);

  const [selectedGroup, setselectedGroup] = useState(null)

  const { employercaseDetail } = useSelector((state) => ({
    employercaseDetail: state.EmployerCases.employercaseDetail,
  }));

  const { employerCaseTypes } = useSelector((state) => ({
    employerCaseTypes: state.EmployerCases.employerCaseTypes,
  }));

  const { employercontacts } = useSelector((state) => ({
    employercontacts: state.EmployerContacts.employercontacts || [],
  }));

  const { employerQnnrTypes } = useSelector((state) => ({
    employerQnnrTypes: state.EmployerCases.employerQnnrTypes,
  }));


  //GET EMPLOYEES
  useEffect(() => {
    dispatch(getEmployerContacts({ "status": "Active", "emp_type": "All" }));
    dispatch(getEmployerCaseTypes());
    if (editId)
      dispatch(getEmployerCaseDetail(editId));

  }, [dispatch, editId]);

  useEffect(() => {
    if (employercontacts && employercontacts.length > 0) {
      let arrEmployees = [];
      employercontacts.forEach(row => {
        if (row.first_name)
          arrEmployees.push({ label: row.first_name + " " + row.last_name, value: (row.id || row?.employee_id) })
      })
      setOptionGroup(arrEmployees);
    }
  }, [employercontacts]);


  useEffect(() => {
    if (selectedGroup) {
      validation.setFieldValue('emp_name', selectedGroup?.label)
      validation.setFieldValue('emp_id', selectedGroup?.value)
    }
  }, [selectedGroup]);


  // Form validation 
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: editId,
      case_type: "",
      qnnr_type: "",
      emp_id: "",
      emp_name: "",
      accept_terms: ""
    },
    validationSchema: Yup.object({
      emp_id: Yup.string().required("Please Select Beneficiary ").nullable(),
      case_type: Yup.string().required("Please Select Case Type").nullable(),
      qnnr_type: Yup.string().required("Please select Questionnaire").nullable(),
      accept_terms: Yup.boolean().required('Please accept checkbox for no changes in the company information')
        .oneOf([true], 'Please accept checkbox for no changes in the company information').nullable(),
    }),
    onSubmit: (values) => {
      if (values.emp_id) {
        if (Number(editId) > 0) {
          dispatch(EmployerCaseUpdate(values));
        } else {
          dispatch(addEmployerCaseDetail(values));
        }
        setTimeout(function () {
          dispatch(getEmployerCases({ "status": "All" }));
          validation.resetForm();
        }, 2000)

        navigate("/employer/cases");
      }
    }
  });

  const handleSelectGroup = (selectedData) => {
    setselectedGroup(selectedData)
  };

  const reloadQnnrTypes = (e) => {
    validation.setFieldValue(e.target.name, e.target.value)
    if (Number(e.target.value))
      dispatch(getEmployerQnnrtypes(Number(e.target.value)));
  }

  let title = editId ? 'Update Case' : 'Create Case';
  const BreadcrumbData = [
    { name: 'Cases', url: '/employer/cases' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody className="p-4">
                  <FormikProvider value={validation}>
                    <Form className="needs-validation"
                      onSubmit={(values) => {
                        values.preventDefault();
                        if (_.size(validation.errors) > 0) {
                          const errorObj = { "response": { "status": "400", "data": { "message": "Error - Please fill required form fields" } } }
                          dispatch(setAlertMessage({ message: errorObj, type: "danger" }));
                        }
                        // console.log(validate(values));
                        validation.handleSubmit();
                        return false;
                      }}
                    >

                      <div className="mt-4 mt-lg-0">

                        <Row className="mb-4">

                          <Col sm={6}>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="horizontal-firstname-input"
                              >
                                Select beneficiary Name for this case *
                              </Label>
                              <Select
                                name="emp_id"
                                value={selectedGroup}
                                onChange={event => handleSelectGroup(event)}
                                options={OptionGroup}
                                classNamePrefix={`${validation.touched.emp_id && validation.errors.emp_id ? 'is-invalid-select2 select2-selection' : 'select2-selection'}`}
                                invalid={
                                  validation.touched.emp_id && validation.errors.emp_id ? true : false
                                }
                              />
                              {validation.touched.emp_id && validation.errors.emp_id ? (
                                <FormFeedback type="invalid">{validation.errors.emp_id}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col sm={6}>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="horizontal-firstname-input"
                              >
                                Select Visa category *
                              </Label>
                              <Input
                                name="case_type"
                                type="select"
                                className="form-control"
                                id="validationCustom02"
                                onBlur={validation.handleBlur}
                                onChange={reloadQnnrTypes}
                                value={validation.values.case_type || ""}
                                invalid={
                                  validation.touched.case_type && validation.errors.case_type ? true : false
                                }
                              >
                                <option value="">Select visa category</option>
                                {employerCaseTypes.map(row => (
                                  <option key={row.id} value={row.id}>
                                    {row.case_type}
                                  </option>
                                ))}
                              </Input>
                              {validation.touched.case_type && validation.errors.case_type ? (
                                <FormFeedback type="invalid">{validation.errors.case_type}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col sm={6}>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="horizontal-firstname-input"
                              >
                                Select questionnaire to be used to file the petition*
                              </Label>
                              <Input
                                name="qnnr_type"
                                placeholder="Last name"
                                type="select"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.qnnr_type || ""}
                                invalid={
                                  validation.touched.qnnr_type && validation.errors.qnnr_type ? true : false
                                }

                              >
                                <option value="">Click to select</option>
                                {employerQnnrTypes.map(row => (
                                  <option key={row.case_type_id + row.id} value={row.id}>
                                    {row.qnnr_type}
                                  </option>
                                ))}
                              </Input>

                              {validation.touched.qnnr_type && validation.errors.qnnr_type ? (
                                <FormFeedback type="invalid">{validation.errors.qnnr_type}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col sm={10}>
                            <FormGroup className="mb-3">

                              <Label
                                htmlFor="horizontal-firstname-input"
                              >
                                <Input
                                  name="accept_terms"
                                  type="checkbox"
                                  className="form-control"
                                  id="validationCustom01"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.accept_terms}
                                  style={{ "float": "left", "margin-right": "5px" }}
                                />
                                There are no changes to company information *
                              </Label>
                              {validation.touched.accept_terms && validation.errors.accept_terms ? (
                                <FormFeedback type="invalid">{validation.errors.accept_terms}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col sm={2}>
                            <FormGroup className="mb-0">
                              <div style={{ "float": "right" }}>
                                <Button type="reset" color="secondary" onClick={() => navigate(-1)}>
                                  Cancel
                                </Button>{" "}
                                <Button type="submit" className="ms-1" color="primary">
                                  Save
                                </Button>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>

                      </div>
                    </Form>
                  </FormikProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormLayoutsBasic;