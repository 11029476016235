import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Col, Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal, ModalBody, ModalHeader,
  Row,
  Table
} from "reactstrap";
import "../../../assets/scss/pages/_timesheets.scss";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// Formik validation
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

import { addTimesheet, createProject, getCustomers, getProjects } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import { getDateFormatYMD } from "../../../common/data/dateFormat";
import AddTimesheetModal from "./AddTimesheetModal";



const FormLayoutsBasic = () => {
  let total = (index) => {
    let val = 0;
    validation.values.timesheet_items[index].enteredWorkhours.map((item, index) => (
      item ? val += parseInt(item) : ""
    ))
    return parseInt(val);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showaddTimesheetModal, setshowaddTimesheetModal] = useState(true);
  const [showCreateVendor, setShowCreateVendor] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get('id'); // Read the 'id' query parameter from the URL
  var timesheetNumOfDays, timesheetDataSumitObj = {};
  var subseqFullDates = [];

  const { projects } = useSelector((state) => ({
    projects: state.EmployeeTimesheets.projects,
  }));

  useEffect(() => {
    dispatch(getProjects({ status: 'All' }));
  }, [dispatch]);

  useEffect(() => {
    //dispatch(getCustomers({ status: activeTab }));
    dispatch(getCustomers({ status: 'All' }));
  }, [dispatch]);

  // const [rowInputFields, setNumOfDaysTimesheet] = useState([1,2,3,4,5,6,7]);
  // const [allTimesheetDates, setAllTimesheetDates] = useState(rowInputFields);
  const [timesheetInitialState, settimesheetInitialState] = useState({
    dates: [],
    weekDays: [],
    timesheetAllDates: [],
    timesheetItemsArr: { timesheet_items: [{ project: '', activity: '', total: '0.00', columns: [] }], totals: [{ columns: [], total: 0 }], timesheetAttachments: [] }
  });
  const [periodweekDay, setPeriodweekDay] = useState([]);
  const calweekDay = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  // console.log(allTimesheetDates,'=========== object values=====');
  const [timesheetData, settimesheetData] = useState({
    project: '',
    activity: '',
    weektotalHours: 0,
    weekWorkingHours: 0,
    totalWorkHours: 0,
    dayWorkingHours: [0],
    rowInputFields: [],
    footerRowTotals: [],
    allRowsFieldData: [[]],
    rowWeektotalHours0: 0,
    rowTimesheetFieldsArr: []
    //  daytotalenteredHours0:0,
    //  daytotalenteredHours1:0,
    //  daytotalenteredHours2:0,
    //  daytotalenteredHours3:0,
    //  daytotalenteredHours4:0,
    //  daytotalenteredHours5:0,
    //  daytotalenteredHours6:0
  })
  //const [weekWorkingHours, setweekWorkingHours] = useState(0);
  const [timeperiodInfo, setTimeperiodInfo] = useState({
    startDate: '',
    endDate: '',
    vendor: '',
    numOfPeriodDays: ''
  });
  const [calcDatesfromStartDate, setCalcDatesfromStartDate] = useState({
    displayDates: {
      date: '',
      weekDay: ''
    }
  });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      timesheet_items: [{ project: '', activity: '', enteredWorkhours: new Array(7).fill(0.00) }], //timesheetNumOfDays dynamic index
      file: '',
      project_id: '',
      activity_id: '',
      eachTimeEntry: 0,
      weekTotal: 0,
      dayTotal: 0,
      overAllToatl: 0,
      numOfDaysFieldsArray: [],
      rowIndex: 0
    },
    validationSchema: Yup.object({
      file: Yup.mixed().required('Please select document'),
      // customer_id: Yup.string().required("Please Select Customer"),
      // employee_id: Yup.string().required("Please Select Beneficiary"),

      timesheet_items: Yup.array()
        .of(
          Yup.object()
            .shape({
              project: Yup.string().required("Please select project"),
              activity: Yup.string().required("Please select activity"),
            })
            .test("check-activity", "${path} already selected", function (item) {
              var activityStatus = true;
              const index = parseInt(this.path.split("[")[1].split("]")[0], 10);
              if (index > 0) {
                const array = this.parent;
                const activity = item.activity;
                const project = item.project;
                if (activity && project) {
                  if (project == array[index - 1].project) {
                    activityStatus = !(activity == array[index - 1].activity);
                  }
                }
              }
              return activityStatus
                ? true
                : this.createError({ path: `${this.path}.activity`, message: "Project activity already selected" });
            })
        )
        .required('Must filled the item details')
    }),
    onSubmit: (values) => {
      onTimesheetSubmit();
      setTimeout(() => {
        navigate("/employee/timesheets");
      }, 2000)
    }
  });

  var rowFieldsArrayCalArr = [], tempFieldValArr = [[], []];

  // Form validation 
  const addVendorvalidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      create_vendor_name: '',
    },
    validationSchema: Yup.object({
      create_vendor_name: Yup.string().required("Please Enter Project name"),
    }),
    onSubmit: (values) => {
      dispatch(createProject({ project_name: addVendorvalidation.values.create_vendor_name }));
      setShowCreateVendor(false);
      addVendorvalidation.resetForm();

    }
  });

  const addNewTimesheetRow = (rowIndex) => {
    var timesheetTempArray = timesheetInitialState.timesheetItemsArr;
    timesheetTempArray.timesheet_items.push([rowIndex]);
    timesheetTempArray.timesheet_items[rowIndex] = { total: 0, columns: [] }
    timesheetInitialState.timesheetAllDates?.map((date, rowFieldIndex) => {

      timesheetTempArray.timesheet_items[rowIndex].columns[rowFieldIndex] = { hours: 0, date: date }
    })

    settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: timesheetTempArray });

  }
  const [file, setFile] = useState();
  const addTimesheetAttachments = (e) => {
    setFile(e.target.files[0]);

  }

  const removeTimesheetItem = (rowIndex) => {
    var updatedTimesheetItemsArr = timesheetInitialState.timesheetItemsArr;
    timesheetData.rowInputFields.forEach((val, colIndex) => {
      updatedTimesheetItemsArr.timesheet_items[rowIndex].columns[colIndex].hours = 0;

    })

    settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: updatedTimesheetItemsArr })
    calcTimesheetItems(rowIndex, '');
  }

  var totalsWorkedHoursVar = 0
  const setTimesheetItems = (e, rowIndex, colIndex) => {

    var updatedTimesheetItemsArr = timesheetInitialState.timesheetItemsArr;
    // console.log(updatedTimesheetItemsArr, '============ check arr data ==========');
    var timesheetFieldValue = e.target.value == '' ? 0 : parseInt(e.target.value);
    updatedTimesheetItemsArr.timesheet_items[rowIndex].columns[colIndex].hours = timesheetFieldValue;

    settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: updatedTimesheetItemsArr })

    calcTimesheetItems(rowIndex, colIndex)
  }
  const calcTimesheetItems = (rowIndex, colIndex) => {
    var updatedTimesheetItemsArr = timesheetInitialState.timesheetItemsArr;

    var currTotalTimesheetRowItems = updatedTimesheetItemsArr.timesheet_items[rowIndex].columns.reduce((total, item) => parseInt(item.hours) + total, 0);
    updatedTimesheetItemsArr.timesheet_items[rowIndex].total = currTotalTimesheetRowItems;

    var curreTotalTimesheetColumnItems = [], totalTimesheetHours = 0;

    updatedTimesheetItemsArr.timesheet_items.map((item, index) => {
      // console.log(index, '---- ff---', item, '--- test data----', updatedTimesheetItemsArr);
      item.columns?.map((data, index) => {
        var oldColumnHours = curreTotalTimesheetColumnItems[index] ?? 0;
        curreTotalTimesheetColumnItems[index] = data.hours + oldColumnHours;
        totalTimesheetHours += data.hours;
      })
    })
    updatedTimesheetItemsArr.totals.columns = curreTotalTimesheetColumnItems;
    updatedTimesheetItemsArr.totals.total = totalTimesheetHours;
    settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: updatedTimesheetItemsArr })

  }

  const handleAddTimesheetClick = (data) => {
    // console.log(data, '--- post form data ---');
    if (data.endDate !== "") {
      setshowaddTimesheetModal(false);
      var d1 = new Date(data.startDate);
      var d2 = new Date(data.endDate);
      var dateDiff = d2.getTime() - d1.getTime();
      dateDiff = dateDiff / (1000 * 60 * 60 * 24)
      timesheetNumOfDays = new Array(parseInt(dateDiff + 1));
      timesheetNumOfDays.fill(0.00);
      validation.initialValues.numOfDaysFieldsArray.concat([12])
      var timesheetTypeVal;
      if (data.time_period === '7')
        timesheetTypeVal = 'Weekly'
      else if (data.time_period === '14')
        timesheetTypeVal = 'BiWeekly'
      else if (data.time_period === '15')
        timesheetTypeVal = 'Semi Monthly'
      else if (data.time_period === '30')
        timesheetTypeVal = 'Monthly'
      settimesheetData({ ...timesheetData, rowInputFields: timesheetNumOfDays, footerRowTotals: timesheetNumOfDays });
      setTimeperiodInfo({
        ...timeperiodInfo, startDate: data.startDate, endDate: data.endDate,
        client_id: data.client_id, numOfPeriodDays: timesheetTypeVal, client_name: data.client_name
      });
      // console.log(timeperiodInfo, '============ timesheet period info =======');

      showTimesheetDates(data);
    }
  }

  const showTimesheetDates = (data) => {
    var temparr = [];
    var tempWeekDay = [];
    var j = 0, k = 0;
    var subseqstartDate = new Date(data.actualStartDate);
    //const updateddater = startDatesd.setDate(startDatesd.getDate() + 1);
    //var endDateYear = updateddater.getDate().toString();
    //const sdfbgbfc = startDatesd.setDate(startDatesd.getDate() + 1);
    var selectedMonthLastDate = new Date(subseqstartDate.getFullYear(), (subseqstartDate.getMonth() + 1), 0);
    selectedMonthLastDate = selectedMonthLastDate.getDate().toString();
    // console.log(typeof (subseqstartDate), '======= ghs ===', subseqstartDate.getDate() + 1, '===========', selectedMonthLastDate);

    timesheetNumOfDays.forEach((num, i) => {
      // console.log(num, '======= yes');
      var updateDate;
      //console.log(startDate.setDate(startDate.getDate() + (i+1)),'======');
      if (subseqstartDate.getDate() + i > selectedMonthLastDate) {
        var temp2 = 1 + k;
        temp2 = '0' + temp2;
        temparr.push(temp2);
        var setSeqDate = (subseqstartDate.getMonth() + 1) + '/' + temp2 + '/' + subseqstartDate.getFullYear();
        subseqFullDates.push(setSeqDate)
      } else {
        temparr.push(subseqstartDate.getDate() + i);
        var setDateDay = subseqstartDate.getDate() + i;
        var setSeqDate = (subseqstartDate.getMonth() + 1) + '/' + setDateDay + '/' + subseqstartDate.getFullYear();
        subseqFullDates.push(setSeqDate)
      }
      // console.log(subseqstartDate.getDay() + i, '------------each time value');
      if (subseqstartDate.getDay() + i > 7) {
        var tmpCount = 1 + j; tmpCount = tmpCount === 8 ? 1 : tmpCount;
        tempWeekDay.push(tmpCount);
        j + 1 >= 7 ? j = 0 : j = j + 1;
      } else {
        tempWeekDay.push(subseqstartDate.getDay() + i);
      }

      // if(subseqstartDate.getDay()+i > 7) {
      //   j=j+1;
      // }
      if (subseqstartDate.getDate() + i > selectedMonthLastDate) {
        k = k + 1;
      }

    });
    var timesheetTempArray = timesheetInitialState.timesheetItemsArr;

    //timesheetTempArray.push([0]);

    //timesheetTempArray[0] = { total: 2, columns: [] }
    subseqFullDates.map((date, rowFieldIndex) => {


      timesheetTempArray.timesheet_items[0].columns[rowFieldIndex] = { hours: 0, date: getDateFormatYMD(date) }

    })
    // console.log('--- index vall of array--', timesheetTempArray);
    settimesheetInitialState({ ...timesheetInitialState, dates: temparr, weekDays: tempWeekDay, timesheetItemsArr: timesheetTempArray, timesheetAllDates: subseqFullDates });


    //setPeriodweekDay(...periodweekDay, tempWeekDay);
    // console.log(subseqFullDates, '==============0000000000000000000======', tempWeekDay, '--------print array-----', timesheetInitialState)

  }

  const onValChange = (e, rowIndex) => {
    if (e.target.value === 'createNewProject') {
      setShowCreateVendor(true);
    } else {
      var updatedTimesheetItemsArr = timesheetInitialState.timesheetItemsArr;
      if (e.target.id == 'project') {
        var timesheetRowProject = e.target.value == '' ? 0 : e.target.value;
        updatedTimesheetItemsArr.timesheet_items[rowIndex].project = timesheetRowProject;
      } else {
        var timesheetRowActivity = e.target.value == '' ? 0 : e.target.value;
        updatedTimesheetItemsArr.timesheet_items[rowIndex].activity = timesheetRowActivity;
      }
      // console.log(updatedTimesheetItemsArr, '-------- data check -----');
      settimesheetInitialState({ ...timesheetInitialState, timesheetItemsArr: updatedTimesheetItemsArr })
    }
  }

  const onTimesheetSubmit = () => {
    var timesheet_items = timesheetInitialState.timesheetItemsArr.timesheet_items;
    timesheetDataSumitObj = {
      ...timesheetDataSumitObj,
      customer_id: timeperiodInfo.client_id,
      timesheet_type: timeperiodInfo.numOfPeriodDays,
      from_date: timeperiodInfo.startDate,
      to_date: timeperiodInfo.endDate,
      add_document_proof: validation.values.file,
      total_hours: timesheetInitialState.timesheetItemsArr.totals.total,
      timesheet_items,
    }
    // console.log(timesheetDataSumitObj, '---final object ----');
    dispatch(addTimesheet(timesheetDataSumitObj));
    setTimeout(() => {
      navigate("/employee/timesheets");
    }, 2000)

    //e.preventDefault();
  }

  let title = editId ? 'Update Timesheet' : 'Timesheet';
  const tableTHPadding = {
    padding: "0.1rem 0.75rem",
    textAlign: "center"
  };

  const tableInputPadding = {
    textAlign: "center"
  }

  const tableFootPadding = {
    textAlign: "center"
  }

  const tableBodyTd = {
    border: "none"
  }

  const routeTolIsting = () => {
    navigate("/employee/timesheets");
  }
  const BreadcrumbData = [
    { name: 'Timesheets', url: '/employee/timesheets' },
    { name: title, url: '#' }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Modal isOpen={showCreateVendor}>
          <ModalHeader toggle={() => { addVendorvalidation.resetForm(); setShowCreateVendor(false) }} tag="h4">
            Add Project
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                addVendorvalidation.handleSubmit();
                return false;
              }}
            >
              <Row form>
                <Col className="col-12 mb-3">
                  <Label className="form-label">Project Name *</Label>
                  <Input
                    name="create_vendor_name"
                    type="text"
                    placeholder="Enter Input"
                    onChange={addVendorvalidation.handleChange}
                    onBlur={addVendorvalidation.handleBlur}
                    //value={addVendorvalidation.values.create_vendor_name || ""}
                    invalid={
                      addVendorvalidation.touched.create_vendor_name &&
                        addVendorvalidation.errors.create_vendor_name
                        ? true
                        : false
                    }
                  />
                  {addVendorvalidation.touched.create_vendor_name &&
                    addVendorvalidation.errors.create_vendor_name ? (
                    <FormFeedback type="invalid">
                      {addVendorvalidation.errors.create_vendor_name}
                    </FormFeedback>
                  ) : null}
                </Col>

              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="ms1 btn btn-primary save-event"
                    >
                      Create
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <AddTimesheetModal
          show={showaddTimesheetModal}
          onAddSubmitClick={handleAddTimesheetClick}
        />
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />
          <Table>
            <th style={{ width: "16%" }}>Timesheet Period <br />{timeperiodInfo.startDate} - {timeperiodInfo.endDate}</th>
            <th>Client/ Vendor<br /> {timeperiodInfo.client_name}</th>
          </Table>
          <Row>

            {/* <Table responsive>
            
          </Table>
                        */}
            {/* {JSON.stringify(validation.values.timesheet_items)} */}
            <FormikProvider value={validation}>
              <Form
                onSubmit={(values) => {
                  values.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <FieldArray
                  name="timesheet_items"
                  render={arrayHelpers => (
                    <div>
                      {validation.values.timesheet_items && validation.values.timesheet_items.length > 0 ? (

                        <Table responsive className="addTimesheetContainer">
                          {validation.values.timesheet_items.map((friend, idx) => (
                            idx === 0 ? (
                              <thead key={idx}>
                                <tr style={{ border: "hidden" }}>
                                  <th scope="col" style={tableTHPadding}>
                                    Projects
                                  </th>
                                  <th scope="col" style={tableTHPadding}>
                                    Activity
                                  </th>
                                  {timesheetInitialState.dates.map((date, i) =>
                                    <th key={i} style={tableTHPadding} scope="col">
                                      <label>{date}</label>
                                    </th>
                                  )}
                                  <th scope="col" style={tableTHPadding}>
                                    <label>Total</label>
                                  </th>
                                  <th scope="col" style={tableTHPadding}>
                                  </th>

                                </tr>
                                <tr style={{ lineHeight: "0.3" }}>
                                  <th style={tableTHPadding}></th>
                                  <th style={tableTHPadding}></th>
                                  {timesheetInitialState.weekDays.map((Day, i) =>
                                    <th key={i} style={tableTHPadding}>
                                      <label>{(calweekDay[Day])?.substring(0, 3)}</label>
                                    </th>
                                  )}
                                  <th style={tableTHPadding}></th>
                                  <th></th>
                                </tr>
                              </thead>
                            ) : (<span key={idx}></span>)
                          ))}

                          <tbody>
                            {validation.values.timesheet_items.map((friend, index) => (
                              <tr key={index}>
                                <td style={tableBodyTd}>
                                  <Field
                                    id="project"
                                    component="select"
                                    className="form-control"
                                    name={`timesheet_items.${index}.project`}
                                    onChange={(e, rowIndex) => {
                                      validation.handleChange(e);
                                      onValChange(e, index);
                                    }}
                                  >
                                    <option value="">Select Project</option>
                                    <option value="createNewProject">Create New Project</option>
                                    {projects && projects.map((project, index) => (
                                      <option key={index} value={project.id}>{project.project_name}</option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name={`timesheet_items.${index}.project`}
                                    component="div"
                                    className="text-danger"
                                  />
                                </td>
                                <td style={tableBodyTd}>
                                  <Field
                                    id="activity"
                                    component="select"
                                    className="form-control"
                                    name={`timesheet_items.${index}.activity`}
                                    onChange={(e) => {
                                      validation.handleChange(e);
                                      onValChange(e, index);

                                    }}>
                                    <option value="">Select Activity</option>
                                    <option value="RegularHours">Regular Hours</option>
                                    <option value="OvertimeHours">Overtime Hours</option>
                                  </Field>
                                  <ErrorMessage
                                    name={`timesheet_items.${index}.activity`}
                                    component="div"
                                    className="text-danger"
                                  />
                                </td>

                                <React.Fragment>
                                  {timesheetInitialState.dates.map((item, rowFieldIndex) => (
                                    <td key={rowFieldIndex} style={tableBodyTd}>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        style={tableInputPadding}
                                        placeholder="0.00"
                                        onChange={(event) => {
                                          validation.handleChange(event);
                                          setTimesheetItems(event, index, rowFieldIndex)
                                        }}
                                        name={`timesheet_items.${index}.enteredWorkhours.${rowFieldIndex}`}
                                      />
                                    </td>
                                  ))}
                                </React.Fragment>

                                <td style={tableBodyTd}>
                                  <label className="col-form-label form-label" style={{ textAlign: "center" }} name={`rowWeektotalHours${index}`} >
                                    {total(index)}
                                    {/* {timesheetInitialState.timesheetItemsArr.timesheet_items?.[`${index}`]?.total ?? 0} */}
                                  </label>
                                </td>
                                <td style={tableBodyTd}>
                                  {validation.values.timesheet_items.length > 1 ? (
                                    <i style={{"font-size":"22px","cursor":"pointer"}} className="bx bx-trash" onClick={() => (arrayHelpers.remove(index), removeTimesheetItem(index))} ></i>
                                  ) : <span></span>}
                                </td>
                                <td style={tableBodyTd}>
                                  {validation.values.timesheet_items.length === index + 1 ? (
                                    <i style={{"font-size":"22px","cursor":"pointer"}} className="bx bx-plus-circle" onClick={(e) => (arrayHelpers.push({ project: '', activity: '', enteredWorkhours: [] }), addNewTimesheetRow(index + 1))}></i>
                                  ) : (<span></span>)}
                                </td>
                              </tr>

                            ))}

                          </tbody>


                          <tfoot>
                            {validation.values.timesheet_items.map((friend, index2) => (
                              index2 + 1 === validation.values.timesheet_items.length ? (

                                <tr className="table-light" key={index2}>
                                  <td colSpan={1}></td>
                                  <td colSpan={1}></td>
                                  {timesheetData.footerRowTotals.map((item, rowFieldIndex) => (
                                    <td key={rowFieldIndex}>
                                      <label className="col-form-label form-label" style={tableFootPadding} name={`daytotalenteredHours${rowFieldIndex}`} >
                                        {timesheetInitialState.timesheetItemsArr.totals.columns?.[`${rowFieldIndex}`] ?? 0}
                                      </label>
                                    </td>
                                  ))}
                                  <td>
                                    <label className="col-form-label form-label" style={tableFootPadding}>
                                      {timesheetInitialState.timesheetItemsArr.totals.total ?? 0}
                                    </label>
                                  </td>
                                  <td>

                                  </td>
                                </tr>

                              ) : (<span key={index2}></span>)
                            ))}
                          </tfoot>
                        </Table>

                      ) : (
                        <button type="button" onClick={() => arrayHelpers.push('')}>
                          {/* show this when user has removed all items from the list */}
                          Add
                        </button>
                      )}

                    </div>
                  )}
                />
                <br /><br />
                <Row className="mb-4">
                  <Label
                    htmlFor="horizontal-firstname-input"
                    className="col-sm-2 col-form-label"
                  >
                    Add Attachment:
                  </Label>
                  <Col sm={5}>
                    <Input
                      type="file"
                      className="form-control"
                      name="file"
                      multiple
                      //onChange={()=> {addTimesheetAttachments, validation.handleChange}}
                      onChange={(e) => validation.setFieldValue('file', e.target.files[0])}
                      accept=".pdf"
                      //onChange={() => addTimesheetAttachments}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.file &&
                          validation.errors.file
                          ? true
                          : false}
                    />
                    {validation.touched.file &&
                      validation.errors.file ? (
                      <FormFeedback type="invalid">
                        {validation.errors.file}
                      </FormFeedback>
                    ) : null}

                  </Col>
                </Row>
                <Row style={{ float: "right" }}>
                  <Col lg="6">
                    <div className="mt-4">
                      <button type="reset" onClick={routeTolIsting} className="btn btn-secondary">
                        Cancel
                      </button>
                    </div>
                  </Col>

                  <Col lg="2">
                    <div className="mt-4">
                      <button type="submit" onClick={
                        () => {
                          if (validation.errors) {
                            const el = document.querySelector('div.text-danger');
                            el?.parentElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                          }
                        }
                      } className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                    <Col>
                      <div className="text-center mt-3">
                        <button
                          type="submit"
                          className="ms-1 btn btn-primary btn-lg ms-2"
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row> */}
              </Form>

            </FormikProvider>
          </Row>


        </Container>
      </div>

    </React.Fragment>
  );
};

export default FormLayoutsBasic;