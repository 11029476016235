import React, { useEffect, useState } from "react";
import {
    Card, CardBody, CardHeader, Col, Container,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";

import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ListPafForms from "./ListPafForms";

// Formik validation
import classnames from "classnames";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
// Form Editor


import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import { changePreloader, getEmployerPafForms, getEmployerPafInfo, updateEmployerPafInfo } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage } from "../../../store/common/actions";

const ViewPafForms = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const caseId = queryParams.get('caseId'); // Read the 'caseId' query parameter from the URL


    const [activeTab1, setactiveTab1] = useState("application");
    const [apiSuccess, setApiSuccess] = useState(false);

    const toggle1 = (tab) => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };

    const [editorFooterDescription, seteditorFooterDescription] = useState({});
    const [initialValues, setInitialValues] = useState(false);
    const handleActionSubmit = (e) => {
        validation.handleSubmit();
    };

    const { employerpafinfo, updateemployerpafinfo, updateemployerpafinfoerror } = useSelector((state) => ({
        employerpafinfo: state.EmployerCases.employerpafinfo || {},
        updateemployerpafinfo: state.EmployerCases.updateemployerpafinfo || {},
        updateemployerpafinfoerror: state.EmployerCases.updateemployerpafinfoerror || {},
    }));

    useEffect(() => {
        dispatch(getEmployerPafInfo({ case_id: caseId }));
    }, [dispatch]);

    useEffect(() => {
        if (_.isEmpty(updateemployerpafinfo))
            dispatch(changePreloader(true));
        else
            dispatch(changePreloader(false));

        if (Object.keys(updateemployerpafinfo).length > 0 && apiSuccess) {
            dispatch(getEmployerPafInfo({ case_id: caseId }));
            setApiSuccess(false);
            var userType = window.localStorage.getItem("user_type")
            toggle1("pafforms");
            dispatch(getEmployerPafForms({ case_id: caseId }));
        }
    }, [updateemployerpafinfo]);

    useEffect(() => {
        if (!updateemployerpafinfo && _.isEmpty(updateemployerpafinfoerror))
            dispatch(changePreloader(true));
        else
            dispatch(changePreloader(false));

        if (!_.isEmpty(updateemployerpafinfoerror) || !_.isEmpty(updateemployerpafinfo)) {
            dispatch(changePreloader(false));
            if (!_.isEmpty(updateemployerpafinfoerror)) {
                // handleAlertOpen(updateemployerpafinfoerror.response.data.message, 'warning');
            } else if (!_.isEmpty(updateemployerpafinfo)) {
                // handleAlertOpen("PAF details updated successfully!", 'success');
            }
            setApiSuccess(false);
            dispatch(getEmployerPafInfo({ case_id: caseId }));
        }
    }, [updateemployerpafinfo, updateemployerpafinfoerror]);


    const iniValues = {
        case_id: caseId,
        sign_authority: null,
        display_location: null,
        display_date: null,
        removal_date: null,
        display_area_1: null,
        display_area_2: null,
        lca_number: null,
        soc_occupation_title: null,
        soc_code: null,
        wage_level: null,
        wages: null,
        wages_cycle: null,
        lca_job_title: null,
        lca_from_date: "",
        lca_to_date: ""
    };
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: (caseId && (Object.keys(employerpafinfo).length > 0)) ? employerpafinfo : iniValues,
        validationSchema: Yup.object({
            sign_authority: Yup.string().required("Please select Sign Authority").nullable(),
            display_location: Yup.string().required("Please select display area location ").nullable(),
            display_date: Yup.string().required("Please select display date").nullable(),
            removal_date: Yup.string().required("Please select removal date").nullable(),
            display_area_1: Yup.string().required("Please enter display area 1").nullable(),
            display_area_2: Yup.string().required("Please enter display area 2").nullable(),
            lca_number: Yup.string().required("Please enter LCA number ").nullable(),
            soc_occupation_title: Yup.string().required("Please enter SOC Occupation Title").nullable(),
            soc_code: Yup.string().required("Please enter SOC Code").nullable(),
            wage_level: Yup.string().required("Please Select wages level ").nullable(),
            wages: Yup.string().required("Please enter wage ").nullable(),
            wages_cycle: Yup.string().required("Please select wages cycle").nullable(),
            lca_job_title: Yup.string().required("Please enter job title ").nullable(),
            lca_from_date: Yup.string().required("Please Select LCA from date").nullable(),
            lca_to_date: Yup.string().required("Please Select LCA to date").nullable(),
        }),
        onSubmit: (values) => {
            if (Number(caseId) > 0) {
                values.case_id = caseId;
                dispatch(updateEmployerPafInfo(values));
                setApiSuccess(true);
            }
        }
    });

    let title = 'PAF Forms';
    //meta title
    document.title = title + " | Immidock ";
    const BreadcrumbData = [
        { name: 'Public Access Files', url: '/employer/paf' },
        { name: title, url: '#' }
    ]
    return (

        <React.Fragment>
            <>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem={title} BreadcrumbData={BreadcrumbData} />

                        <Col xs={12}>
                            <Card>

                                <CardHeader>
                                    <h4 className="card-title">PAF Forms</h4>
                                </CardHeader>
                                <Nav tabs className="nav-tabs-custom nav-justified">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab1 === "application",
                                            })}
                                            onClick={() => {
                                                toggle1("application");
                                                dispatch(getEmployerPafInfo({ case_id: caseId }));
                                            }}
                                        >
                                            Application
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab1 === "pafforms",
                                            })}
                                            onClick={() => {
                                                toggle1("pafforms");
                                                dispatch(getEmployerPafForms({ case_id: caseId }));
                                            }}
                                        >
                                            Forms
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab1} className="p-3 text-muted">
                                    <TabPane tabId="application">

                                        <CardBody className="p-4">
                                            <FormikProvider value={validation}>
                                                <Form className="needs-validation"
                                                    onSubmit={(values) => {
                                                        values.preventDefault();
                                                        // console.log(validate(values));
                                                        if (_.size(validation.errors) > 0) {
                                                            const errorObj = { "response": { "status": "400", "data": { "message": "Error - Please fill required form fields" } } }
                                                            dispatch(setAlertMessage({ message: errorObj, type: "danger" }));
                                                        }
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                >

                                                    <div className="mt-4 mt-lg-0">
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="example-date-input" className="form-Label">PAF Details</Label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="sign_authority">Signatory Authority *</Label>
                                                                    <Input
                                                                        name="sign_authority"
                                                                        placeholder="Signatory Authority"
                                                                        type="select"
                                                                        className="form-control"
                                                                        id="sign_authority"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.sign_authority || ""}
                                                                        invalid={
                                                                            validation.touched.sign_authority && validation.errors.sign_authority ? true : false
                                                                        }
                                                                    >
                                                                        <option value="">Choose Signatory Authority</option>
                                                                        <option value="202"> Testing Lakshman </option>

                                                                    </Input>
                                                                    {validation.touched.sign_authority && validation.errors.sign_authority ? (
                                                                        <FormFeedback type="invalid">{validation.errors.sign_authority}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="display_location">Display Location *</Label>
                                                                    <Input
                                                                        name="display_location"
                                                                        placeholder="Display Location"
                                                                        type="select"
                                                                        className="form-control"
                                                                        id="display_location"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.display_location || ""}
                                                                        invalid={
                                                                            validation.touched.display_location && validation.errors.display_location ? true : false
                                                                        }
                                                                    >
                                                                        <option value="">Choose Display Area Location</option>
                                                                        <option value="Client Location">Client Location</option>
                                                                        <option value="Employer Location">Employer Location</option>

                                                                    </Input>
                                                                    {validation.touched.display_location && validation.errors.display_location ? (
                                                                        <FormFeedback type="invalid">{validation.errors.display_location}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="display_date">Display Date *</Label>
                                                                    <ReactDatePicker
                                                                        showYearDropdown={true}
                                                                        showIcon
                                                                        placeholderText="MM/dd/yyyy"
                                                                        name="display_date"
                                                                        placeholder="Display Date"
                                                                        className={`form-control ${validation.touched.display_date && validation.errors.display_date ? 'is-invalid' : ''}`}
                                                                        id="display_date"
                                                                        dateFormat="MM/dd/yyyy"
                                                                        onChange={(date) => {
                                                                            validation.setFieldValue('display_date', date)
                                                                        }
                                                                        }
                                                                        selected={validation?.values?.display_date ? new Date(validation.values.display_date) : ''}
                                                                    />
                                                                    {validation.touched.display_date && validation.errors.display_date ? (
                                                                        <FormFeedback type="invalid">{validation.errors.display_date}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="removal_date">Suggested Removal Date *</Label>
                                                                    <ReactDatePicker
                                                                        showYearDropdown={true}
                                                                        showIcon
                                                                        placeholderText="MM/dd/yyyy"
                                                                        name="removal_date"
                                                                        placeholder="Suggested Removal Date"
                                                                        className={`form-control ${validation.touched.removal_date && validation.errors.removal_date ? 'is-invalid' : ''}`}
                                                                        id="removal_date"
                                                                        dateFormat="MM/dd/yyyy"
                                                                        onChange={(date) => {
                                                                            validation.setFieldValue('removal_date', date)
                                                                        }
                                                                        }
                                                                        selected={validation?.values?.removal_date ? new Date(validation.values.removal_date) : ''}
                                                                    />
                                                                    {validation.touched.removal_date && validation.errors.removal_date ? (
                                                                        <FormFeedback type="invalid">{validation.errors.removal_date}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="display_area_1">Display Area 1 *</Label>
                                                                    <Input
                                                                        name="display_area_1"
                                                                        placeholder="Display Area 1"
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="display_area_1"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.display_area_1 || ""}
                                                                        invalid={
                                                                            validation.touched.display_area_1 && validation.errors.display_area_1 ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.display_area_1 && validation.errors.display_area_1 ? (
                                                                        <FormFeedback type="invalid">{validation.errors.display_area_1}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="display_area_2">Display Area 2 *</Label>
                                                                    <Input
                                                                        name="display_area_2"
                                                                        placeholder="Display Area 2"
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="display_area_2"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.display_area_2 || ""}
                                                                        invalid={
                                                                            validation.touched.display_area_2 && validation.errors.display_area_2 ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.display_area_2 && validation.errors.display_area_2 ? (
                                                                        <FormFeedback type="invalid">{validation.errors.display_area_2}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>

                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="example-date-input" className="form-Label">LCA Details</Label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="lca_number">LCA Number *</Label>
                                                                    <Input
                                                                        name="lca_number"
                                                                        placeholder="LCA Number"
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="lca_number"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.lca_number || ""}
                                                                        invalid={
                                                                            validation.touched.lca_number && validation.errors.lca_number ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.lca_number && validation.errors.lca_number ? (
                                                                        <FormFeedback type="invalid">{validation.errors.lca_number}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="soc_occupation_title">SOC Occupation Title *</Label>
                                                                    <Input
                                                                        name="soc_occupation_title"
                                                                        placeholder="SOC Occupation Title"
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="soc_occupation_title"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.soc_occupation_title || ""}
                                                                        invalid={
                                                                            validation.touched.soc_occupation_title && validation.errors.soc_occupation_title ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.soc_occupation_title && validation.errors.soc_occupation_title ? (
                                                                        <FormFeedback type="invalid">{validation.errors.soc_occupation_title}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="soc_code">SOC Code *</Label>
                                                                    <Input
                                                                        name="soc_code"
                                                                        placeholder="SOC Code"
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="soc_code"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.soc_code || ""}
                                                                        invalid={
                                                                            validation.touched.soc_code && validation.errors.soc_code ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.soc_code && validation.errors.soc_code ? (
                                                                        <FormFeedback type="invalid">{validation.errors.soc_code}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="wage_level">Wage Level *</Label>
                                                                    <Input
                                                                        name="wage_level"
                                                                        placeholder="Wage Level"
                                                                        type="select"
                                                                        className="form-control"
                                                                        id="wage_level"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.wage_level || ""}
                                                                        invalid={
                                                                            validation.touched.wage_level && validation.errors.wage_level ? true : false
                                                                        }
                                                                    >
                                                                        <option value="">Select Wage Level</option>
                                                                        <option value="Level 1">Level 1</option>
                                                                        <option value="Level 2">Level 2</option>
                                                                        <option value="Level 3">Level 3</option>
                                                                        <option value="Level 4">Level 4</option>

                                                                    </Input>
                                                                    {validation.touched.wage_level && validation.errors.wage_level ? (
                                                                        <FormFeedback type="invalid">{validation.errors.wage_level}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="wages">Wage *</Label>
                                                                    <Input
                                                                        name="wages"
                                                                        placeholder="Wage"
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="wages"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.wages || ""}
                                                                        invalid={
                                                                            validation.touched.wages && validation.errors.wages ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.wages && validation.errors.wages ? (
                                                                        <FormFeedback type="invalid">{validation.errors.wages}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="wages_cycle">Wage Cycle *</Label>
                                                                    <Input
                                                                        name="wages_cycle"
                                                                        placeholder="Wage Cycle"
                                                                        type="select"
                                                                        className="form-control"
                                                                        id="wages_cycle"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.wages_cycle || ""}
                                                                        invalid={
                                                                            validation.touched.wages_cycle && validation.errors.wages_cycle ? true : false
                                                                        }
                                                                    >
                                                                        <option value="">Select Wage Cycle</option>
                                                                        <option value="Hour">Hour</option>
                                                                        <option value="Week">Week</option>
                                                                        <option value="Month">Month</option>
                                                                        <option value="Year">Year</option>

                                                                    </Input>
                                                                    {validation.touched.wages_cycle && validation.errors.wages_cycle ? (
                                                                        <FormFeedback type="invalid">{validation.errors.wages_cycle}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="lca_job_title">Job Title *</Label>
                                                                    <Input
                                                                        name="lca_job_title"
                                                                        placeholder="Job Title"
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="lca_job_title"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.lca_job_title || ""}
                                                                        invalid={
                                                                            validation.touched.lca_job_title && validation.errors.lca_job_title ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.lca_job_title && validation.errors.lca_job_title ? (
                                                                        <FormFeedback type="invalid">{validation.errors.lca_job_title}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="lca_from_date">From Date *</Label>
                                                                    <ReactDatePicker
                                                                        showYearDropdown={true}
                                                                        showIcon
                                                                        placeholderText="MM/dd/yyyy"
                                                                        name="lca_from_date"
                                                                        placeholder="From Date"
                                                                        className={`form-control ${validation.touched.lca_from_date && validation.errors.lca_from_date ? 'is-invalid' : ''}`}
                                                                        id="lca_from_date"
                                                                        dateFormat="MM/dd/yyyy"
                                                                        onChange={(date) => {
                                                                            validation.setFieldValue('lca_from_date', date)
                                                                        }
                                                                        }
                                                                        selected={validation?.values?.lca_from_date ? new Date(validation.values.lca_from_date) : ''}
                                                                    />
                                                                    {validation.touched.lca_from_date && validation.errors.lca_from_date ? (
                                                                        <FormFeedback type="invalid">{validation.errors.lca_from_date}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md="4">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="lca_to_date">To Date *</Label>
                                                                    <ReactDatePicker
                                                                        showYearDropdown={true}
                                                                        showIcon
                                                                        placeholderText="MM/dd/yyyy"
                                                                        name="lca_to_date"
                                                                        placeholder="To Date"
                                                                        className={`form-control ${validation.touched.lca_to_date && validation.errors.lca_to_date ? 'is-invalid' : ''}`}
                                                                        id="lca_to_date"
                                                                        dateFormat="MM/dd/yyyy"
                                                                        onChange={(date) => {
                                                                            validation.setFieldValue('lca_to_date', date)
                                                                        }
                                                                        }
                                                                        selected={validation?.values?.lca_to_date ? new Date(validation.values.lca_to_date) : ''}
                                                                    />
                                                                    {validation.touched.lca_to_date && validation.errors.lca_to_date ? (
                                                                        <FormFeedback type="invalid">{validation.errors.lca_to_date}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row style={{ float: "right" }}>
                                                            <Col>
                                                                <span className="mt-4">
                                                                    <button type="submit" className="btn btn-primary">
                                                                        Save & Next
                                                                    </button>
                                                                </span>
                                                            </Col>
                                                        </Row>

                                                    </div>

                                                </Form>
                                            </FormikProvider>
                                        </CardBody>
                                    </TabPane>
                                    <TabPane tabId="pafforms">
                                        {employerpafinfo?.qnnr_status == "Completed" && employerpafinfo?.sign_authority != '' ? (
                                            <ListPafForms employerpafinfo={employerpafinfo}></ListPafForms>
                                        ) : (
                                            <CardBody>
                                                <Row className="mb-2">
                                                    <Col sm="5">
                                                    </Col>
                                                    <Col sm="6">
                                                        <h4 className="card-title">Please Complete Questionnaire and Application information</h4>
                                                    </Col>
                                                </Row>

                                            </CardBody>
                                        )}
                                    </TabPane>
                                </TabContent>

                            </Card>
                        </Col>
                    </Container>
                </div>
            </>
        </React.Fragment >

    );
};

export default ViewPafForms;