import {
  ADD_TIMESHEET_SUCCESS,
  ADD_TIMESHEET_FAIL,
  GET_EMPLOYEETIMESHEETS_SUCCESS,
  GET_EMPLOYEETIMESHEETS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAIL,
  VALIDATE_TIMESHEET_BY_PERIOD_SUCCESS,
  VALIDATE_TIMESHEET_BY_PERIOD_FAIL
} from "./actionTypes"

const INIT_STATE = {
  // invoices: [],
  // invoiceDetail: {},
  // invoicesettings: {},
  addTimesheetData: [],
  employeeTimehseets: [],
  projects: [],
  createProject: [],
  validate_timesheet_by_period_check:{},
  error: {},
}

const EmployeeTimesheets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYEETIMESHEETS_SUCCESS:
      return {
        ...state,
        employeeTimehseets: action.payload,
      }

    case GET_EMPLOYEETIMESHEETS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_TIMESHEET_SUCCESS:
      return {
        ...state,
        // uploadedinvoiceattachments: [...state.Invoices, action.payload],
        addTimesheetData: action.payload,
      }

    case ADD_TIMESHEET_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case VALIDATE_TIMESHEET_BY_PERIOD_SUCCESS:
      return {
        ...state,
        validate_timesheet_by_period_check: action.payload,
      }

    case VALIDATE_TIMESHEET_BY_PERIOD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      }

    case GET_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      }

    case CREATE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default EmployeeTimesheets
